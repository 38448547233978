import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from "../../../components";
import { ButtonField, PasswordField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../../config/config";
import { postRequest, postRequest2 } from "../../../config/redux/request";
import {
    changeModalSize,
    clearAll,
    onCustomerEmailVerificationRequest,
    onCustomerLogin,
    setErrorMessage,
    setLoading
} from "../../../config/redux/slice/authData";
import { adminUrls } from "../../../config/urls/adminUrls";
import AlertWrapper from "../../_shared_component/AlertWrapper";
import { AuthWrapperAdmin } from "../new_account/pages";
import { onAdminLogin2 } from "../../../config/redux/slice/adminSlice";


const AdminPasscodeScreen = () => {
    const navigate = useNavigate();
    // Check LoginNew Component for reference
    const [togglePassword, setTogglePassword] = useState(false)
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        loading,
        showLoginModal,
        user_role,
        errorMsg,
        success_msg,
        tempEmail,
        token,
        userData
    } = useSelector((state) => state.authData)

    const {register, handleSubmit, getValues, watch, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [isEmailVerified, setEmailVerified] = useState(false)
    const dispatch = useDispatch()
    const [successMessage, setSuccessMessage] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)

    const passcodeValue = watch('passcode');

    useEffect(()=> {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
    },[])


  const {phone, admin_token, otp } = useSelector((state) => state?.administrator?.adminRegDetails)

    const closeErrorAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const closeSuccessAlert = () => {
        setSuccessMessage(false)
    }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        let newData = {...data, phone: phone, admin_token, otp}

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.auth_fourth_step}`,
            newData,
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400 ){

                    const loginRes = await postRequest2(
                    `${appConfig.auth_token}`,
                    `${endpointUrl.adminLogin}`,
                    {...data, phone: phone, admin_token},
                    dispatch,
                    setLoading,
                    "",
                    setErrorMessage
                )
                if(loginRes !== undefined && loginRes.status < 400 ){
                    dispatch(onCustomerLogin(loginRes.data.data))
                    navigate(`${adminUrls.adminDashboard}`)
                }
        }
    }
    //
    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }
    //

        return <AuthWrapperAdmin classTitle={'admin_login'} title='Create Passcode' >      
                <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)}>
                    { errorMsg === 'verify email first' ?
                        <AlertWrapper onClick={closeErrorAlert} type={'danger'}>
                            <div className="">
                                Your email needs to be verified before you can use access admin dashboard.
                                <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}> <u>Click here </u></Link>  to verify.
                            </div>
                        </AlertWrapper>
                        :  errorMsg && <AlertWrapper onClick={closeErrorAlert} type={'error'}>{errorMsg}</AlertWrapper>
                    }

                    <div className="mt-3 login-form">                      
                              <div className={'row mb-2'}>
                                <div className={'col-xl-12'}>
                                      <PasswordField
                                        fieldName={'passcode'}
                                        setValueFunc={setValue}
                                        error_msg={errors.passcode?.message}
                                        col={'col-xl-12'}
                                        label={'Enter your Passcode'}
                                        nextCompId={'password'}
                                        placeholder={''}
                                        isReq={true}
                                        minlength={6}
                                        maxlength={6}
                                        register={register}
                                        defaultVal={''}
                                        patternRules={'passcode'}
                                    />
                                </div>
                            </div>

                            <div className={'row mb-2'}>
                                <div className={'col-xl-12 mb-4'}>
                                      <PasswordField
                                        fieldName={'passcode_confirmation'}
                                        setValueFunc={setValue}
                                        error_msg={errors.passcode_confirmation?.message}
                                        col={'col-xl-12'}
                                        label={'Retype Passcode'}
                                        nextCompId={'password'}
                                        placeholder={''}
                                        isReq={true}
                                        minlength={6}
                                        maxlength={6}
                                        register={register}
                                        defaultVal={''}
                                        patternRules={'passcode'}
                                        isSameAs={passcodeValue}
                                    />
                                </div>
                            </div>


                        <div className="form-action mb-3">
                            {loading ? <div className="mt-3"><Loader/></div> :
                                <ButtonField
                                    btnStyle={'py-2'}
                                    btnTxt={'Sign In'}
                                    btnWidth={'100'}
                                    bgColor={colors.primary_color}
                                    txtColor={colors.white}
                                    id={'d'}
                                    // btnAdditionalStyle={}
                                />
                            }
                        </div>
                    </div>
                </form>
    </AuthWrapperAdmin>
}


export default AdminPasscodeScreen
