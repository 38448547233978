import moment from "moment";
import { useSelector } from "react-redux";
import { NumberFormatter } from "../../config/redux/actions";
import { strText } from "../../config/string";
import { pagesText } from "../../config/string/english";
/*
 @doc :: shared between customer and  admin
 AdminTransactionHome has
*/
const TransactionDetailsItemModal = ({show,transactionDetails,onClose}) => {
    const {user_role,userData} = useSelector((state) => state.authData)
    return <>
        <div className="modal  fade show" id="exampleModalCenter" style={{display:"block", paddingRight:"17px"}} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable " role="document">
                <div className="modal-content px-2 py-2">
                    <div className="modal-header" style={{border: "0px"}}>
                        <div className={'mx-auto w-100 text-center'}>
                            {user_role ==='customer' &&
                                <div className={'gap-2 d-flex justify-content-center'}>
                                    {Number(transactionDetails?.source_accountno) === Number(userData.account_number) ?
                                        <img src={'../assets/images/icon/outbound.svg'} alt={''} height={'25'}/>
                                         :
                                        <img src={'../assets/images/icon/incoming_transfer.svg'} alt={''} height={'25'}/>

                                    }
                                    {/*<h6 className={'text-center mt-1'}>{transactionDetails?.transaction_type}</h6>*/}
                                </div>
                            }
                        </div>

                        <button type="button" className="close" data-dismiss="modal"  aria-label="Close" onClick={() => onClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div>
                            <table className={'table table-striped'}>
                                <tbody>
                                        <tr>
                                            <td>Reference</td>
                                            <td> {transactionDetails?.transaction_ref} </td>
                                        </tr>
                                        {/*<tr>*/}
                                        {/*    <td>Sender Account</td>*/}
                                        {/*    <td> {transactionDetails?.source_account?? transactionDetails.source_accountno}  </td>*/}
                                        {/*</tr>*/}
                                        {/*<tr>*/}
                                        {/*    <td>Beneficiary Name</td>*/}
                                        {/*    <td>{transactionDetails?.user_email ?? transactionDetails?.beneficiary_name}  </td>*/}
                                        {/*</tr>*/}

                                        {/*<tr>*/}
                                        {/*    <td>Beneficiary Account</td>*/}
                                        {/*    <td>*/}
                                        {/*        /!*{transactionDetails?.receiving_bank ?? transactionDetails.beneficiary_acct}/{transactionDetails?.destination_account ?? transactionDetails.beneficiary_bank }*!/*/}
                                        {/*        {transactionDetails.beneficiary_acct ?? transactionDetails?.destination_account}*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}

                                        {/*<tr>*/}
                                        {/*    <td>Beneficiary Bank</td>*/}
                                        {/*    <td>*/}
                                        {/*        {transactionDetails?.receiving_bank ?? transactionDetails.beneficiary_bank}*/}
                                        {/*    </td>*/}
                                        {/*</tr>*/}

                                    <tr>
                                        <td>Amount</td>
                                        <td> {strText.naira} {NumberFormatter(transactionDetails?.amount.toFixed(2))} </td>
                                    </tr>



                                    <tr>
                                        <td>Narration</td>
                                        <td> <b>Transfer from:</b> {transactionDetails?.source_account?? transactionDetails.source_accountno}  <b>to</b> &nbsp;
                                            {transactionDetails.beneficiary_acct ?? transactionDetails?.destination_account}, &nbsp;
                                            <b>Beneficiary name: </b>{transactionDetails?.beneficiary_name}, &nbsp;
                                            <b>Beneficiary bank: </b> {transactionDetails?.receiving_bank ?? transactionDetails.beneficiary_bank}. <br/>
                                            <b>Narration:</b> {transactionDetails?.narration}
                                        </td>
                                    </tr>



                                    <tr>
                                        <td>Status</td>
                                        <td> {
                                            (transactionDetails?.transfer_status ?? transactionDetails?.status ==='approved') ?
                                                <span className={'badge bg-success text-white'}>approved</span> :
                                                (transactionDetails.transfer_status ?? transactionDetails?.status ==='pending')?
                                                    <span className={'badge bg-warning text-white'}>pending</span> :
                                                    <span className={'badge bg-danger text-white'}>failed</span>
                                        }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Transaction Date</td>
                                        <td>
                                            {moment(transactionDetails.created_on).format('MMMM DD, YYYY')} at &nbsp;
                                                {moment(transactionDetails.created_on).format('hh:mm:ss')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Transaction Charge</td>
                                        <td>
                                            { pagesText.admins_level_cu_only.includes(user_role) ?
                                               (userData.account_number === transactionDetails.source_accountno && userData.account_number) !==transactionDetails.beneficiary_acct
                                                ? transactionDetails?.charges?.toFixed(2).toLocaleString() :  '0.00'
                                                :
                                                `${strText.naira} ${NumberFormatter(transactionDetails?.charges_total?.toFixed(2) ?? transactionDetails?.charges?.toFixed(2)).toLocaleString()}`
                                            }

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {/*
                         <div className={'mx-auto w-50 my-4'}>
                            <div className={'d-flex'}>
                                <button className={'px-4 py-2 text-center mx-2 '} style={{border:`1px solid ${colors.secondary_color}`,color:colors.secondary_color,background:colors.background}}>Share</button>
                                <button className={'px-4 py-2 text-center'} style={{background:`${colors.secondary_color}`,border:0,color:colors.white}}>Download</button>
                            </div>
                        </div>
                        */}



                    </div>
                </div>
            </div>
        </div>

        {show &&  <div className="modal-backdrop fade show"></div>}

    </>
}

export default  TransactionDetailsItemModal