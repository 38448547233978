import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../../../../assets/css/formStyle.module.css";
import { ButtonField, TextInputField } from "../../../../components/form/components";
// import { colors } from "../../../../config/color";
import { appConfig, remoteUrl } from "../../../../config/config";
import { postRequest, postRequest2 } from "../../../../config/redux/request";
import { handleSearchBanks } from "../../../../config/redux/slice/authData";
import {
    getAvailableAccounts, setCustomerErrorMsg,
    setGeneralModal,
    setLoading, setTokenizedDepositForm
} from "../../../../config/redux/slice/customerSlice";
import { respMsg } from "../../../../config/response_messages";
import { urls } from "../../../../config/urls/urls";
import { SearchBankComponent, SearchBeneficiaryComponent } from "../../../layout";
import AvailableAccountsComponent from "../../../layout/AvailableAccountsComponent";
import CustomerWrapper from "../../CutomerWrapper";
import { ConfirmTokenizedDeposit } from "./component";
import TokenizedSuccessModal from "./component/TokenizedSuccessModal";

const  TokenizedDepositForm = () => {
    const {colors} = useSelector((state) => state.app)
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        clearErrors,
        formState: {errors},
        reset, watch,setError
    } = useForm({mode: 'onTouched'})
    const {
        token,
        userData,
        bank_accounts,
        types_of_transfers,
        banks,
        account_beneficiaries,
        showBeneficiaryModal,
        showBankModal
    } = useSelector((state) => state.authData)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {beneficiaries,available_accounts, generalModal, successModal,success_msg} = useSelector((state) => state.customer)


    const [realAmount,setRealAmount] =  useState()
    const [showAvailAccount,setShowAvailAccount] =  useState(false)


    const onContinue = (data) => {
        if(Number(realAmount) < 1){
            setError('temp_amount',{message:'invalid amount'})
        }
        else{
            data.amount  = realAmount
            dispatch(setGeneralModal({
                show: true,
                msg: '',
                backdrop: true
            }))
            dispatch(setTokenizedDepositForm({data, tempFunc: reset}))
        }
        // dispatch(setGeneralModal({show: true, msg: '', backdrop: true}))
        // dispatch(setTokenizedDepositForm({data, tempFunc: reset}))
    }

    const handleBankField = (data) => {
        dispatch(handleSearchBanks())

    }

    function formatNumber(e) {
        var rex = /(^\d{2})|(\d{1,3})(?=\d{1,3}|$)/g,
            val = this.value.replace(/^0+|\.|,/g, ""),
            res;

        if (val.length) {
            res = Array.prototype.reduce.call(val, (p, c) => c + p) // reverse the pure numbers string
                .match(rex) // get groups in array
                .reduce((p, c, i) => i - 1 ? p + "," + c : p + "." + c); // insert (.) and (,) accordingly
            res += /\.|,/.test(res) ? "" : ".0"; // test if res has (.) or (,) in it
            this.value = Array.prototype.reduce.call(res, (p, c) => c + p); // reverse the string and display
        }
    }

    const formatAmount = (data) => {
        let o = data.replace(".00",'')
        let temp_data = o.replaceAll(",",'').trim()
        setRealAmount(temp_data)
        // let x = NumberFormatter(Number(temp_data).toFixed(2))

    }

    const getAvailAccounts = () => {
        let postData = {
            "jwt" : token,
        }
        const res =  postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.getAvailAccounts}`,
            postData,
            dispatch,
            setLoading,
            getAvailableAccounts,
            setCustomerErrorMsg)
    }

    const handleAvailableAccount = () => {
        setShowAvailAccount(true)
    }

    useEffect(() => {
        var ni = document.getElementById("amount");
        ni.addEventListener("keyup", formatNumber);
        getAvailAccounts()
    },[])
    useEffect(()=>{
        if(success_msg === respMsg.tokenized_deposit_s){
            navigate(urls.tokenizedDeposit)

        }
    }, [success_msg])
    return (
        <CustomerWrapper>
            {/*tokenizedDepositFormData*/}
            <div className={'mx-auto col-sm-10 col-md-10 col-lg-8 col-xl-10 mt-2 py-1'}>
                {showAvailAccount &&  <AvailableAccountsComponent
                    updateFieldFunc={setValue}
                    fieldToUpdateArray={['account_number', 'beneficiary']}
                    backdrop={showAvailAccount}
                    accountList={available_accounts}
                    clearErrors={clearErrors} closeFunc={setShowAvailAccount}
                /> }
                {/**/}
                {showBeneficiaryModal && <SearchBeneficiaryComponent
                    updateFieldFunc={setValue}
                    fieldToUpdateArray={['beneficiary', 'bank_id', 'account_number', 'bank_name']}
                    beneficiaryList={beneficiaries}
                    backdrop={showBeneficiaryModal}
                    clearErrors={clearErrors}
                />}
                {
                    showBankModal && <SearchBankComponent
                        updateFieldFunc={setValue}
                        fieldToUpdateArray={['bank_id', 'bank_name']}
                        backdrop={showBankModal}
                        bankList={banks}
                        clearErrors={clearErrors}
                    />
                }


                {/**/}

                <form onSubmit={handleSubmit(onContinue)}>
                    <div className={'mx-auto w-100'}>
                        <div className={'card  px-sm-0 px-md-5 py-4 '}>
                            <div className={'card-header mb-2'}>
                                <div className={'d-flex justify-content-between'}>
                                    <h3 style={{color: colors.secondary_color}}><strong>New Tokenized Deposit</strong></h3>
                                </div>
                            </div>
                            <div className={'card-body'}>
                                <div className={''}>
                                    {/**/}
                                    <div className={'row mb-md-2'}>
                                        <div className={'col-sm-12 mb-sm-3 col-md-12 col-lg-12 col-xl-12'}>
                                            <div className={'form-group'}>
                                                <label>Deposit Account</label>
                                                <input type={'text'} readOnly={true}
                                                       className={`form-control ${styles.textField}`}
                                                       value={userData.account_number}/>
                                            </div>

                                        </div>
                                        {/*transfer type*/}
                                    </div>



                                    {/**/} {/*bank name and  ACCOUNT number*/}
                                    <div className={'row mb-md-2'}>
    
                                    </div>


                                    {/**/}
                                    <div className={'row'}>
                                        <div className={'col-sm-12 mb-sm-3 col-md-6 col-lg-12 col-xl-6'}>
                                            <TextInputField
                                                patternRules={'letters'}
                                                col={''}
                                                label={'Narration'}
                                                fieldName={'narration'}
                                                placeholder={''}
                                                register={register}
                                                // inputStyles={}
                                                error_msg={errors.narration?.message}
                                                isReq={false}
                                                defaultVal={''}
                                            />

                                        </div>
                                        <input type={'hidden'}
                                               defaultValue={realAmount}
                                               {...register('amount')}/>
                                        <div className={'col-sm-12 mb-sm-3 col-md-6 col-lg-6 col-xl-6'}>
                                            <div className={'form-group'}>
                                                <label>Amount </label>
                                                <input
                                                    type={'text'}
                                                    onKeyUp={(e)=> formatAmount(e.target?.value)}
                                                    id={"amount"}
                                                    className={`form-control ${styles.textField}  ${errors.temp_amount?.message && 'is-invalid'}`}
                                                    {...register('temp_amount', {
                                                        required: {
                                                            value: true,
                                                            message: 'this field is required'
                                                        }
                                                    })}/>
                                                {errors.temp_amount && <div className="invalid-feedback">{errors.temp_amount?.message}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={'col-12'}>
                                            <label>
                                                <input {...register('terms_and_condition',{
                                                    required:{
                                                        value:true,
                                                        message:'this field is required'
                                                    }
                                                })} type={"checkbox"}
                                                       className={`mr-2 ${errors.terms_and_condition?.message && 'is-invalid'}`}
                                                />
                                                I accept that all tokenized deposits are subject to count
                                            </label>
                                            {errors.terms_and_condition?.message && <div className="text-danger">{errors.terms_and_condition?.message}</div>}


                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/**/}
                            <div className={'mx-3'} style={{borderTop: '0px'}}>
                                <div className={'text-center mt-1'}>
                                    <ButtonField btnWidth={'50'} btnTxt={'Continue'} txtColor={colors.white}
                                                 bgColor={colors.secondary_color}
                                                 btnAdditionalStyle={{fontSize: '0.9rem', padding :`0.6rem 1rem`}}
                                                 />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        {/*    */}
            {generalModal.show &&  <ConfirmTokenizedDeposit backdrop={generalModal.backdrop} msg={generalModal.msg} /> }
            {successModal.show && <TokenizedSuccessModal  successModal={successModal.show} />}
        </CustomerWrapper>


    )
}

export default  TokenizedDepositForm