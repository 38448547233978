import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {postRequest, postRequest2} from "../../../../config/redux/request";
import {appConfig, remoteUrl} from "../../../../config/config";

import {strText} from "../../../../config/string";
import {NumberFormatter} from "../../../../config/redux/actions";
import {AmountInputField, ButtonField, SelectField} from "../../../../components/form/components";
import {Loader} from "../../../../components";
// import {colors} from "../../../../config/color";
import {adminDoTokenizedTransaction, setErrorMsg, setLoading_} from "../../../../config/redux/slice/adminSlice";
import moment from "moment";
import {pagesText} from "../../../../config/string/english";

const TokenizedDepositDetails = ({show,transactionDetails,onClose}) => {
    const {user_role,userData:{modules, permissions},token,permissionRoles} = useSelector((state) => state.authData)
    const {loading_} = useSelector((state) =>state.administrator)
    const {colors} = useSelector((state) => state.app)
    const {register, handleSubmit, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const dispatch = useDispatch()

    const updateTokenizedDeposit = async (data) => {
        //updateCustomerTokenizedTransactionc
        let amount = document.getElementById('amount').value

        const postData = {
            "jwt" : token,
            "token_id":transactionDetails.id,
            "tokenized_id":transactionDetails.id,
            "instruction": transactionDetails.instruction,
            "email":transactionDetails?.email,
            "account_number":transactionDetails.account,
            "narration":transactionDetails.narration, //narration
            // "beneficiary_accountno": transactionDetails.beneficiary_account,
            // "beneficiary_bank":transactionDetails.beneficiary_bank,
            // "beneficiary_name":transactionDetails.beneficiary_name,
            // "expected_amount": transactionDetails.expected_amount,
            "actual_amount":amount,
            "expected_amount":transactionDetails.expected_amount,
        }


        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.updateAdminTokenizedTransaction}`,
            postData,
            dispatch,
            setLoading_,
            adminDoTokenizedTransaction,
            setErrorMsg
        )

    }
    return <>
        <div className="modal  fade show" id="exampleModalCenter" style={{display:"block", paddingRight:"17px"}} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable " role="document">
                <div className="modal-content px-1 py-1" style={{maxHeight: '90vh', overflowY: 'auto'}} >
                    <div className="modal-header" style={{border: "0px"}}>
                        <h3 style={{color:colors.secondary_color}}>{pagesText.tokenized_transactions} Details</h3>
                        <button type="button" className="close" data-dismiss="modal"  aria-label="Close" onClick={() => onClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div>
                            <table className={'table table-striped'}>
                                <tbody>
                                <tr>
                                    <td>Token</td>
                                    <td> {transactionDetails?.token}  </td>
                                </tr>
                                <tr>
                                    <td>Deposit Account</td>
                                    <td>{transactionDetails?.account}  </td>
                                </tr>
                                <tr>
                                    <td>Narration </td>
                                    <td>{transactionDetails?.narration}  </td>
                                </tr>
                                <tr>
                                    <td>Expected Amount</td>
                                    <td> {strText.naira} {NumberFormatter(transactionDetails?.expected_amount?.toFixed(2))} </td>
                                </tr>
                                <tr>
                                    <td>Actual Amount</td>
                                    <td> {
                                        transactionDetails?.actual_amount ?   NumberFormatter(Number(transactionDetails?.actual_amount)?.toFixed(2))
                                            :
                                            'N/A'
                                    }
                                    </td>
                                </tr>


                                <tr>
                                    <td>Created on</td>
                                    <td> {moment(transactionDetails.created_on).format('YYYY-MM-DD, hh:mm:ss')} </td>
                                </tr>

                                <tr>
                                    <td>Transaction Status</td>
                                    <td> {
                                        (transactionDetails?.status ==='processed') ?
                                            <span className={'badge bg-success text-white'}>approved</span> :
                                            (transactionDetails?.status ?? transactionDetails?.status ==='pending')?
                                                <span className={'badge bg-warning text-white'}>pending</span> :
                                                <span className={'badge bg-danger text-white'}>failed</span>
                                    }
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        {permissions?.includes("CanUpdateTokenizedDepositDetails")
                            ?
                            transactionDetails.status!=='processed' &&
                            <>
                                <hr/>
                                <form onSubmit={handleSubmit(updateTokenizedDeposit)}>
                                    <div className={'row'}>
                                        <div className={'col-12 mb-2'}>

                                            <AmountInputField
                                                register={register}
                                                error_msg={errors.temp_amount?.message}
                                                defaultVal={NumberFormatter(Number(transactionDetails?.expected_amount).toFixed(2))}
                                                isReq={true} label={'Amount Presented '} defaultVal2={transactionDetails?.expected_amount}/>
                                        </div>


                                    </div>
                                    <div className={'row mt-2'}>
                                        <div className={'col-12 '}>
                                            {loading_ ? <Loader/> :
                                                <ButtonField id={'er'} btnWidth={'100'} btnTxt={'Process'} btnStyle={'py-2'}
                                                             bgColor={colors.secondary_color} txtColor={colors.white}/>
                                            }
                                        </div>
                                    </div>

                                </form>
                            </>

                            :
                            ""
                        }

                    </div>
                </div>
            </div>
        </div>

        {show &&  <div className="modal-backdrop fade show"></div>}

    </>
}

export default  TokenizedDepositDetails