import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from "../../components";
import { ButtonField, TextInputField } from "../../components/form/components";
// import { colors } from "../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../config/config";
import { postRequest, postRequest2 } from "../../config/redux/request";
import {
    changeModalSize,
    clearAll,
    logoutUser,
    onCustomerEmailVerificationRequest,
    onCustomerLogin2,
    setErrorMessage,
    setLoading
} from "../../config/redux/slice/authData";
import { urls as onBoardUrls, urls } from "../../config/urls/urls";
import AlertWrapper from "../_shared_component/AlertWrapper";
import AuthWrapperCustomer from "../v1/onboard/components/AuthWrapperCustomer";
import { logoutCustomer } from "../../config/redux/slice/customerSlice";
import { logoutAdmin } from "../../config/redux/slice/adminSlice";


const CreateAccountScreen = () => {
    const navigate = useNavigate();
    // Check LoginNew Component for reference
    const [togglePassword, setTogglePassword] = useState(false)
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        loading,
        showLoginModal,
        user_role,
        errorMsg,
        success_msg,
        tempEmail,
        token,
        userData
    } = useSelector((state) => state.authData)

    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [isEmailVerified, setEmailVerified] = useState(false)
    const dispatch = useDispatch()

    useEffect(()=> {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
    },[])

 const phone = useSelector((state) => state?.authData?.userRegDetails?.phone)
 
    const closeAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        let newData = {...data, phone: phone}
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.auth_second_step}`,
            newData,
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )

        if(res !== undefined && res.status < 400){
            dispatch(onCustomerLogin2({type: '', data:newData}))
            navigate(`${onBoardUrls.otp_screen}`)
        }

    }
    //
    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }

    // useEffect(()=>{
 
    //     if (token!=='' && user_role != 'customer') {
    //         dispatch(logoutUser())
    //         dispatch(logoutCustomer())
    //         dispatch(logoutAdmin())
    //         // dispatch(setErrorMessage({message:'Customer does not exist.'}))

    //         navigate(urls.login)
    //     }
    // }, [token, user_role])
    //
    return <AuthWrapperCustomer title="We'll need your name to setup your account">
            <fieldset>
                <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
                    {
                        errorMsg &&
                        <AlertWrapper onClick={closeAlert} type={'error'}>
                            <div className={'py-1 text-capitalize'}>
                                {errorMsg === "Cannot read properties of undefined (reading 'account_number')"
                                    ?
                                    "Your account request is still pending, you will get a email notification upon approval."
                                    :
                                    errorMsg === 'verify email first' ?
                                        <div className="">

                                            Your email needs to be verified before you can use online banking.
                                            <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}>
                                                <span className={'text-primary'}> <u> Click here </u> </span>
                                            </Link>to verify.
                                        </div>
                                        :
                                        errorMsg === 'Request failed with status code 429' ?
                                            'To many Request. Please wait for 15min' : errorMsg
                                }
                            </div>
                        </AlertWrapper>
                    }
                    <div className={'row mb-2'}>
                        <div className={'col-xl-12'}>
                            <TextInputField
                                fieldName={'firstname'}
                                setValueFunc={setValue}
                                error_msg={errors.firstname?.message}
                                col={'col-xl-12'}
                                label={'First Name*'}
                                nextCompId={'password'}
                                placeholder={''}
                                isReq={true}
                                register={register}
                                defaultVal={''}
                                patternRules={''}
                            />
                        </div>
                    </div>

                    <div className={'row mb-3'}>
                        <div className={'col-xl-12'}>
                            <TextInputField
                                fieldName={'lastname'}
                                setValueFunc={setValue}
                                error_msg={errors.lastname?.message}
                                col={'col-xl-12'}
                                label={'Last Name*'}
                                nextCompId={'password'}
                                placeholder={''}
                                isReq={true}
                                register={register}
                                defaultVal={''}
                                patternRules={''}
                            />
                        </div>
                    </div>

                    <div className={''}>
                        {loading ? <Loader/> :
                            <ButtonField
                                txtColor={colors.white}
                                bgColor={colors.primary_color}
                                btnTxt={'Continue'}
                                btnWidth={'100'}
                                btnStyle={'mx-auto col-xl-12 py-2 mt-2'}/>}
                    </div>
                </form>
            </fieldset>

            <p className={'text-center'}> Already have an Account?
                &nbsp;
                <Link
                    to={urls.createAccount}
                    style={{color: colors.primary_color}}
                >
                    Sign in
                </Link>
            </p>
    </AuthWrapperCustomer>
}


export default CreateAccountScreen
