import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { colors } from "../../../../config/color";

const QuickAccessWrapper = ({title,icon,link,bg,icon_type='icon',linkRef=''}) => {

    const {colors} = useSelector((state) => state.app)

    return (

            <div className="col-sm-2  col-md-2" >
                <div className="nav flex-column nav-pills nav-secondary nav-pills-no-bd nav-pills-icons" aria-orientation="vertical">
                    <Link className="nav-link" style={{background:bg,color:colors.white}} to={link} role="tab" >
                        {icon_type==='icon'
                            ?
                            <i className={icon}></i>
                            :
                            <i>
                                <img src={icon} alt={''} height={'45'} width={'40'}/>
                             </i>

                        }


                        {title}
                    </Link>
                </div>
            </div>

    )
}


export default  QuickAccessWrapper