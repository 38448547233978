import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {postRequest, postRequest2} from "../../../../config/redux/request";
import {appConfig, endpointUrl, remoteUrl} from "../../../../config/config";
import {
    clearMessages,
    onChangeSecurityQuestion,
    onUpdateEmail,
    setCustomerErrorMsg,
    setLoading
} from "../../../../config/redux/slice/customerSlice";
import {ButtonField, PasswordField, SelectField, TextInputField} from "../../../../components/form/components";
import {strText} from "../../../../config/string";
// import {colors} from "../../../../config/color";
import {Loader} from "../../../../components";
import { useEffect, useState } from "react";
import { clearAll, onCustomerLogin } from "../../../../config/redux/slice/authData";

const UpdateEmailAddress = () => {
    const {register, handleSubmit, watch, getValues,setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {token} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {loading,success_msg} = useSelector((state) => state.customer)
    const dispatch = useDispatch()
    const emailValue = watch('email');

    useEffect(()=>{
        dispatch(clearAll({}))
    }, [])

    const submitData = async (data) => {
        dispatch(clearMessages())

        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.setup_email}`,
            data,
            dispatch,
            setLoading,
            onUpdateEmail,
            setCustomerErrorMsg
        )
        if(res !== undefined && res.status < 400){
            dispatch(onCustomerLogin(res.data.data))
            reset()
        }
    }

    return (
        <div className={'mx-auto col-sm-10 col-md-10 col-lg-8 col-xl-10 mt-2 py-1'}>
            <form onSubmit={handleSubmit(submitData)}>
                <div className={'mx-auto w-100'}>
                    <div className={'card  px-sm-0 px-md-5 py-4 '}>
                        <div className={'card-header mb-2'}>
                            <div className={'d-flex justify-content-between'}>
                                <h3 style={{color: colors.secondary_color}}><strong>Update Email Address</strong></h3>
                            </div>
                        </div>
                        <div className={'card-body'}>
            
                <div className={'row mb-3 px-sm-0 px-md-5'}>
                    <div className={'col-12'}>
                        <TextInputField
                            fieldName={'email'}
                            setValueFunc={setValue}
                            error_msg={errors.email?.message}
                            col={'col-xl-12'}
                            label={'Email'}
                            placeholder={''}
                            isReq={true}
                            register={register}
                            defaultVal={''}
                            patternRules={'email'}
                            id={'email'}
                        />
                    </div>
                </div>

                <div className={'row mb-3 px-sm-0 px-md-5'}>
                    <div className={'col-12'}>
                        <TextInputField
                            fieldName={'email_confirmation'}
                            setValueFunc={setValue}
                            error_msg={errors.email_confirmation?.message}
                            col={'col-xl-12'}
                            label={'Confirm Email'}
                            placeholder={''}
                            isReq={true}
                            register={register}
                            defaultVal={''}
                            patternRules={'email'}
                            isSameAs={emailValue}
                        />
                    </div>
                </div>

                <div className={'text-center mt-4 px-sm-0 px-md-5'}>
                    {loading ? <Loader/> :
                        <>
                            <ButtonField
                                btnTxt={'Update'}
                                txtColor={colors.white}
                                bgColor={colors.secondary_color}
                                id={'kk'}
                                btnWidth={'100'}
                                btnAdditionalStyle={{padding :`0.6rem 1rem`}}
                            />
                        </>

                    }
                </div>
                        </div>
                        {/**/}
                    </div>
                </div>
            </form>
        </div>     
    )
}

export default UpdateEmailAddress