export const base = [
    {
        base: "http://localhost:3000",
        uuid: "BNK2022310042130148ORXGZVM8T3K1NH6SU8KQ16KIS3X5X"
    },
    {
        base: "https://www.bank-qa.qappworld.com",
        uuid: "BNK32824012818302456OWLLTA5GNTNJE86W20OWQYJ0TYAG"
    },
    {
        base: "http://localhost:3001",
        uuid: "BNK309231004213317IL2HBUPCXZNCBJM2FLFYYQX6VGOICY"
    },
    {
        base: "https://www.national-bank.qappworld.com",
        uuid: "BNK2022310042130148ORXGZVM8T3K1NH6SU8KQ16KIS3X5X"
    },
    {
        base: "https://www.qbank.qappworld.com",
        uuid: "BNK309231004213317IL2HBUPCXZNCBJM2FLFYYQX6VGOICY"
    },
]