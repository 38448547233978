import CustomerWrapper from "../CutomerWrapper";
import EmptyState from "../EmptyState";

const ChequeManagement = () => {
    return <CustomerWrapper
        // showNormalBreadcrumb={}
        title={'Cheque Management'}
        // mainAppStyle={}
    >
        <div className={' mt-5 py-1'}>
            <div className="row ">
                <EmptyState/>
                {/*
                        <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="card-head-row">
                                <h4 className="card-title">Confirm/Stop Cheque Requests</h4>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table table-hover table-sales">
                                        <table className="table">
                                            <tbody>
                                            <tr>
                                                <td>James Doe</td>
                                                <td>something goes here</td>
                                                <td>
                                                    <span className={'badge badge-success'}>approved</span>
                                                </td>
                                                <td>view</td>
                                            </tr>

                                            <tr>
                                                <td>James Doe</td>
                                                <td>something goes here</td>
                                                <td>
                                                    <span className={'badge badge-danger'}>disapproved</span>
                                                </td>
                                                <td>view</td>
                                            </tr>

                                            <tr>
                                                <td>James Doe</td>
                                                <td>something goes here</td>
                                                <td>
                                                    <span className={'badge badge-warning'}>pending</span>
                                                </td>
                                                <td>approve/disapprove</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                */}

            </div>
        </div>


    </CustomerWrapper>
}


export default ChequeManagement