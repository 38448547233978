
import styles from '../../../assets/css/formStyle.module.css'
import React from "react";
/**
 *
 * @param col
 * @param placeholder
 * @param bgColor
 * @param register
 * @param fieldName
 * @param errors
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
const PinField = ({col,placeholder,bgColor,register,fieldName,errors,onChange,onKeyDown}) => {

    //ensure the value is not empty
    const validateInput = (e, data) => {
        if(e.key == 'Backspace' || data!==''){
            // onChange(e)      
            onKeyDown(e)
        }
    }

    return <>
        <div className={col}>
            <div className="input-group transparent-append">
                <input
                    id={fieldName}
                    type="password"
                    className={`form-control ${styles.pinField} ${errors && 'is-invalid'}`}
                    defaultValue={''}
                    autoComplete={'off'}
                    placeholder={''}
                    maxLength={1}
                    // style={{background:bgColor,color:colors.primary_color,fontStyle:'30px'}}
                    {...register(`${fieldName}`, {
                        pattern : {
                            value : /\d+/,
                            message: ""
                    },
                        required: '*',
                        // onChange:(e) => validateInput(e, e.target.value)
                        // onKeyDown:(e) => validateInput(e, e.target.value)
                    })}
                    onKeyDown={(e) => validateInput(e, e.target.value)}
                />
                {errors && (<div className="invalid-feedback">{errors}</div>)}
            </div>
        </div>
    </>
}

export default PinField