import {
    handleSystemManagement,
    onAminStatusChange,
    setErrorMsg, setLoading
} from "../../../config/redux/slice/adminSlice";

// import {colors} from "../../../config/color";
import {ButtonField, SelectField} from "../../../components/form/components";
import {useDispatch, useSelector} from "react-redux";
import {postRequest, postRequest2} from "../../../config/redux/request";
import {appConfig, endpointUrl, remoteUrl} from "../../../config/config";

import {useForm} from "react-hook-form";
import {Loader} from "../../../components";
import {type} from "../../../config/redux/slice/adminSlice";
import {pagesText} from "../../../config/string/english";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";

const ActivateDeactivateAdminUserModal = ({backdrop}) => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {adminRoleManagement: {rolesData}, adminUserManagement: {adminDetails}, loading} = useSelector((state) => state.administrator)
    const {userData:{modules, permissions}, token, user_role, permissionRoles} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const [systemUserRoles, setSystemUserRoles] = useState([])
    const [roleName, setRoleName] = useState('')


    const updateUserStatus = async (data) => {
        const postData = {
            "jwt": token,
            "phone": adminDetails.phone,
            "status": data.status, //active,inactive
            "admin_type" : data.admin_type
        }
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.changeUserStatus}`,
            postData,
            dispatch,
            setLoading,
            onAminStatusChange,
            setErrorMsg
        )
    }

    // const getRoles = async () => {
    //     let res = await postRequest2(
    //         `${appConfig.auth_token}`,
    //         `${endpointUrl.getRoles}`,
    //         {},
    //         dispatch,
    //         '',
    //         '',
    //         '',
    //     )
    //     if(res !== undefined && res.status < 400){
    //         setSystemUserRoles(res.data.data)
    //     }
    // } 
    
    useEffect(()=>{
        let roles = rolesData.filter((role)=>role.id == adminDetails?.user_type)
        setRoleName(roles[0]?.role_name)

        // let roleOptions = rolesData.filter((role)=>(role.role_name).toLowerCase() != 'customer')
        // setSystemUserRoles(roleOptions)
        let superAdminOptions = rolesData.filter((role)=>(pagesText.superAdmin_users).includes((role.role_name).toLowerCase()))
        let adminOptions = rolesData.filter((role)=>(pagesText.admin_users).includes((role.role_name).toLowerCase()))
        
        if(user_role == 'super admin'){
            setSystemUserRoles(superAdminOptions)
        }else if(user_role == 'admin'){
            setSystemUserRoles(adminOptions)
        }
    }, [])

    return (
        <>
            <div className="modal fade show" id="basicModal" tabIndex="-1"
                 style={{
                     display: "block",
                     paddingRight: "17px"
                 }} aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header" style={{border: "0px"}}>
                            <h5 className="modal-title tableHeader"> Edit User </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => dispatch(handleSystemManagement({
                                    type:type.set_admin_details_system,
                                    data:'',
                                    modal : false
                                }))
                                }
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body p-5">
                            {/*<p>Change user status: {adminDetails.firstname} {adminDetails.lastname}</p>*/}
                            <table className={'table table-striped'}>
                                <thead></thead>
                                <tbody>

                                <tr>
                                    <td>Name</td>
                                    <td>{`${adminDetails.firstname} ${adminDetails.lastname} ${adminDetails.middlename ?? ''}`}</td>
                                </tr>

                                <tr>
                                    <td>Email</td>
                                    <td>{adminDetails.email}</td>
                                </tr>
                                <tr>
                                    <td>Phone Number</td>
                                    <td>{adminDetails.phone}</td>
                                </tr>

                                <tr>
                                    <td>Last Login</td>
                                    <td>{moment(adminDetails?.last_login).format(pagesText.full_date)}</td>
                                </tr>
                                {/* <tr>
                                    <td>Role</td>
                                    <td>{pagesText.user_roles_text[adminDetails.user_type]}</td>
                                </tr> */}
                                 <tr>
                                    <td>Role</td>
                                    <td className="text-capitalize">{roleName}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>
                                    <td><small className={`${adminDetails.status==='active' ? 'text-success':'text-danger'}`}>{adminDetails.status}</small></td>
                                </tr>
                                </tbody>

                            </table>


                            {/*checking if user is an admin or super admin*/}
                            {(permissions?.includes("CanUpdateSystemUserDetails") && ['admin', 'super admin'].includes(user_role))
                                &&

                                // user_role ==='admin' && 
                                // ((pagesText.user_roles.find(role => role.val == adminDetails.user_type))?.roles?.includes('admin'))  ?
                                // <form onSubmit={handleSubmit(updateUserStatus)}>
                                //     <p>Update Admin Record</p>
                                //     <div className={'form-group'}>
                                //         <SelectField
                                //             fieldName={'status'}
                                //             register={register}
                                //             isReq={true}
                                //             error_msg={errors.status?.message}
                                //             label={''}
                                //             defaultVal={adminDetails.status}>
                                //             <option value={''}>User Status</option>
                                //             <option value={'active'}>Active</option>
                                //             <option value={'inactive'}>Inactive</option>
                                //         </SelectField>
                                //     </div>
                                //     <div className={' mt-2'}>
                                //         <SelectField
                                //             fieldName={'admin_type'}
                                //             register={register}
                                //             isReq={true}
                                //             error_msg={errors.admin_type?.message}
                                //             label={''}
                                //             defaultVal={adminDetails.user_type}>
                                //             <option value={''}>Select User Role</option>
                                //             {systemUserRoles.map((role,i=0) =>
                                //                 <option className="text-capitalize" value={role.id} key={i+1}>{role.role_name}</option>
                                //             )}
                                //         </SelectField>
                                //     </div>
                                //     <div className={'form-group mt-2'}>
                                //         {loading ? <Loader/> :
                                //             <ButtonField
                                //                 id={''}
                                //                 // btnStyle={}
                                //                 btnTxt={'Update Status'}
                                //                 btnWidth={'100'}
                                //                 // btnAdditionalStyle={}
                                //                 bgColor={colors.secondary_color}
                                //                 txtColor={colors.white}/>}
                                //     </div>
                                // </form>
                                //     :
                                    // user_role ==='super admin' &&
                                    // ((pagesText.user_roles.find(role => role.val == adminDetails.user_type))?.roles?.includes('super admin'))  ?
                                        <form onSubmit={handleSubmit(updateUserStatus)}>
                                            <p>Update Admin Record</p>
                                            <div className={'form-group'}>
                                                <SelectField
                                                    fieldName={'status'}
                                                    register={register}
                                                    isReq={true}
                                                    error_msg={errors.status?.message}
                                                    label={''}
                                                    defaultVal={adminDetails.status}>
                                                    <option value={''}>User Status</option>
                                                    <option value={'active'}>Active</option>
                                                    <option value={'inactive'}>Inactive</option>
                                                </SelectField>
                                            </div>
                                            <div className={' mt-2'}>
                                                <SelectField
                                                    fieldName={'admin_type'}
                                                    register={register}
                                                    isReq={true}
                                                    error_msg={errors.admin_type?.message}
                                                    label={''}
                                                    defaultVal={adminDetails.user_type}>
                                                    <option value={''}>Select User Role</option>
                                                    {systemUserRoles.map((role,i=0) =>
                                                        <option className="text-capitalize" value={role.id} key={i+1}>{role.role_name}</option>
                                                    )}
                                                </SelectField>
                                            </div>
                                            <div className={'form-group mt-2'}>
                                                {loading ? <Loader/> :
                                                    <ButtonField
                                                        id={''}
                                                        // btnStyle={}
                                                        btnTxt={'Update Record'}
                                                        btnWidth={'100'}
                                                        // btnAdditionalStyle={}
                                                        btnAdditionalStyle={{padding :`0.6rem 1rem`}}
                                                        bgColor={colors.secondary_color}
                                                        txtColor={colors.white}/>}
                                            </div>
                                        </form>
                                        // :
                                        // ''
                                // :
                                // ''
                            }

                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className={`modal-backdrop fade show`}></div>}
        </>
    )
}
export default ActivateDeactivateAdminUserModal