// import {colors} from "../../../config/color";
import React, {useEffect, useState} from "react";
import { Loader } from "../../../components";
import { useSelector } from "react-redux";

const OtpComponent = ({register, isReq, label, isLoading, minlength, maxlength, onClick, error_msg}) => {
    const {colors} = useSelector((state) => state.app)
    const [resend, setResend] = useState(false)
    useEffect(() => {
    // Navigate to ComponentB after 3 seconds (3000 milliseconds)
    const timer = setTimeout(() => {
      setResend(true)
    }, 20000);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleResend = () => {
    setResend(false)
    const timer = setTimeout(() => {
      setResend(true)
    }, 20000);
    onClick()
  }

    return (
        <>
            <div className="form-group">
                <label style={{whiteSpace: 'wrap'}} className={'ml-2 fw-bold'}>{label}</label>
                <div className="input-group mb-3">
                    <input
                    id="otp"
                        style={{
                            background:colors.background,
                            borderTopLeftRadius:'50px',
                            borderBottomLeftRadius:'50px'}}
                        type="text"
                        className={`form-control ${error_msg && 'is-invalid'}`}
                        placeholder="Enter 6-digits"
                        aria-label="Enter 6-digits"
                        aria-describedby="basic-addon2"
                        maxLength={maxlength}
                        minLength={minlength}
                         {...register(`otp`, {
                                  required: {
                                      value: isReq,
                                      message: '*this field is required'
                                  },
                                  validate: (fieldValue)=>{
                                    return(
                                        !isNaN(fieldValue) || "Enter valid Otp"
                                    )
                                  }
                              })
                              }
                    />
                    <div
                        className="input-group-append"
                        style={{background:colors.background,
                            borderTopRightRadius:'50px',
                            borderBottomRightRadius:'50px',
                            cursor: resend ? 'pointer' : 'auto'
                        }}
                    >
                        <span
                        aria-label="button"
                            style={{
                                background:colors.background,
                                color: resend ? colors.primary_color : colors.gray,
                                fontWeight:'bold',
                                borderTopRightRadius:'50px',
                                borderBottomRightRadius:'50px',
                                cursor: resend ? 'pointer' : 'auto'
                            }
                            }
                            onClick={resend && !isLoading ? handleResend : ()=>{return}}
                            className="input-group-text"
                            id="basic-addon2">
                                {isLoading ? <div style={{display: 'flex', gap: '3.5px', alignItems: 'center', color: colors.gray}}>
                                <Loader 
                                parentStyles={{width: '16px', height: '16px'}}
                                childsStyles={{width: '16px', height: '16px', 
                                marginTop: '-8px', marginLeft: '-8px', 
                                borderWidth: '2px', 
                                borderColor: `${colors.gray} transparent transparent transparent`,
                            }}
                                /> 
                                <span>Sending...</span>
                                </div> : "Resend OTP"}
                        </span>
                    </div>
                     {error_msg && <div className="invalid-feedback">{error_msg}</div>}

                </div>
            </div>
        </>
    )
}

export default OtpComponent