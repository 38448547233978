import {strText} from "../../../config/string";
// import {colors} from "../../../config/color";
import {useDispatch, useSelector} from "react-redux";
import {setCustomerDetails, setOpenConfirmationModal} from "../../../config/redux/slice/adminSlice";
import { useEffect } from "react";

const CustomerDetailsModal = ({backdrop}) => {
    const {customerManagement:{customerDetails}} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const dispatch   = useDispatch()
    const onClose = () => {
       dispatch(setCustomerDetails({modal:false,details:''}))
    }
    return <>
        <div className="modal  fade show" id="exampleModalCenter" style={{display:"block", paddingRight:"17px", overflowY: 'auto'}} aria-modal="true" role="dialog">
            <div style={{overflowY: 'auto'}} className="modal-dialog modal-dialog-centered modal-dialog-scrollable " role="document">
                <div className="modal-content px-2 py-2">
                    <div className="modal-header" style={{border: "0px"}}>
                            <h3 className="modal-title"
                                style={{color: colors.secondary_color}}>
                                     {/* {actionText} |  */}
                                {customerDetails.firstname} {customerDetails.lastname}</h3>

                        <button type="button" className="close" data-dismiss="modal"  aria-label="Close" onClick={() => onClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div>
                                <table className={'table table-striped'}>
                                    <thead></thead>
                                    <tbody>
                                    <tr>
                                        <td>Phone Number</td>
                                        <td>{customerDetails?.phone ?? 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>Email Address</td>
                                        <td>{customerDetails?.email ?? 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>Account Number</td>
                                        <td>{customerDetails?.account_number ?? 'N/A'}</td>
                                    </tr> 
                                    <tr>
                                        <td>Tier</td>
                                        <td>Tier {customerDetails?.current_tier}</td>
                                    </tr>  
                                    {customerDetails?.tier_status &&
                                    <tr>
                                        <td>Tier Status</td>
                                        <td className={`text-capitalize ${customerDetails?.tier_status == 'approved' ? 'text-success' : 'text-danger'}`}>{customerDetails?.tier_status}</td>
                                    </tr>                                      
                                     }                                                                      
                                    <tr>
                                        <td>Street Address</td>
                                        <td>{customerDetails?.current_address_street ?? 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>House Number</td>
                                        <td>{customerDetails?.current_address_house_no ?? 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>State</td>
                                        <td>{customerDetails?.current_address_state ?? 'N/A'}</td>
                                    </tr> 
                                    <tr>
                                        <td>City</td>
                                        <td>{customerDetails?.current_address_city ?? 'N/A'}</td>
                                    </tr> 
                                    <tr>
                                        <td>Proof of Address</td>
                                        <td>{customerDetails?.proof_of_address && customerDetails?.proof_of_address != 'N/A' ? 
                                        <a style={{color: colors.secondary_color}} href={customerDetails?.proof_of_address} target="_blank" rel="noopener noreferrer">View Document</a> : 'N/A'}</td>
                                    </tr>                                     
                                    <tr>
                                        <td>Identification</td>
                                        <td>{customerDetails?.id_type ?? 'N/A'}</td>
                                    </tr> 

                                    <tr>
                                        <td>Identification Number</td>
                                        <td>{customerDetails?.id_number ?? 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>Identification Document</td>
                                        <td>{customerDetails?.id_file && customerDetails?.id_file != 'N/A' ? 
                                        <a style={{color: colors.secondary_color}} href={customerDetails?.id_file} target="_blank" rel="noopener noreferrer">View Document</a>
                                         : 'N/A'}</td>
                                    </tr>                                                                                                                                                                                                                                                             

                                    </tbody>
                                </table>
                                {(
                                    // customerDetails?.current_tier == 2                                    
                                    customerDetails?.tier_status == 'pending' && customerDetails?.current_tier == 2
                                    ) && 
                                <div className="">
                                    <button
                                        style={{color:colors.white,background:colors.secondary_color}}
                                        className="btn ml-auto mr-3"
                                        onClick={()=> dispatch(setOpenConfirmationModal({
                                            open:true,
                                            details: {
                                                heading: 'Approve User',
                                                body: 'Do you want to approve',
                                                buttonText: 'Approve',
                                                extraDetails: 'approved'
                                            }
                                        }))}
                                    >
                                        Approve User
                                    </button>  
                                    <button
                                        style={{color:colors.white,background:colors.primary_color}}
                                        className="btn  ml-auto"
                                        onClick={()=> dispatch(setOpenConfirmationModal({
                                            open:true,
                                            details: {
                                                heading: 'Disapprove User',
                                                body: 'Do you want to disapprove',
                                                buttonText: 'Disapprove',
                                                extraDetails: 'disapproved'
                                            }    
                                        }))}
                                    >
                                        Disapprove User
                                    </button>                                      
                                </div>                                
                                }                              
                        </div>



                    </div>
                </div>
            </div>
        </div>

        {backdrop &&  <div className="modal-backdrop fade show"></div>}

    </>
}

export default  CustomerDetailsModal