import AppWrapper from "../AppWrapper";
import {useEffect} from "react";
import {setActiveSidebar} from "../../config/redux/slice/appSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {urls} from "../../config/urls/urls";
import { strText } from "../../config/string";
import moment from "moment";
import {clearErrorMsg, logoutAdmin} from "../../config/redux/slice/adminSlice";
import { setErrorMessage } from "../../config/redux/slice/authData";

import {useNotifications} from "reapop";
import { logoutCustomer } from "../../config/redux/slice/customerSlice";
import { logoutUser } from "../../config/redux/slice/authData";
import { adminUrls } from "../../config/urls/adminUrls";
// import {colors} from "../../config/color";


const AdminWrapper = ({children,mainAppStyle,title,reload=false}) => {
    const {userData:{modules, permissions},user_role, token} = useSelector((state) => state.authData)
    const {success_msg,adminErrorMsg} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {notify,dismissNotifications} = useNotifications()

    useEffect(() => {
        dispatch(clearErrorMsg())
        let mainPath = ''
        let subPath = ''
        let activeModule = ''

        let path = window.location.pathname

        let fullPathArray = path.split("/")


        if (fullPathArray.length > 3) {
            mainPath = fullPathArray[2] //isActive
            subPath = path
        } else {
            mainPath = path
            subPath = ''
        }
        let activeSidebarObject = strText.adminSidebar.find(function(obj) {
            return obj.url === mainPath;
        });
        if (activeSidebarObject) {
            if(activeSidebarObject.has_children){
                let activeSidebarChild = activeSidebarObject.children.find(function(obj) {
                    return obj.url === subPath;
                });
                if(activeSidebarChild){
                    activeModule = activeSidebarChild.moduleName
                }
            } else{
                activeModule = activeSidebarObject.name
            }    
        }

        dispatch(setActiveSidebar({
            mainPath,
            activeModule,
            subPath
        }))
        if(user_role==='customer'){
            dispatch(logoutCustomer())
            dispatch(logoutUser())
            dispatch(logoutAdmin())
            dispatch(setErrorMessage({message: "Customer does not exist"}))
            navigate(`${urls.adminLogin}`)
        }
    }, [])

    useEffect(()=>{
    if(success_msg){
        let msg = success_msg.toLowerCase().charAt(0).toUpperCase() + success_msg.slice(1)

        dispatch(clearErrorMsg())

        const capitalized =
            msg.charAt(0).toUpperCase()
            + msg.slice(1)
        //
        notify(capitalized, 'success')
        setTimeout(() => {
            dismissNotifications()
        },3000)
    }

    if(adminErrorMsg){

        let err = adminErrorMsg.toLowerCase().charAt(0).toUpperCase()+adminErrorMsg.slice(1)
        dispatch(clearErrorMsg())

        const capitalized =
            err.charAt(0).toUpperCase()
            + err.slice(1)

        notify(capitalized, 'error')
        setTimeout(() => {
            dismissNotifications()
        },3000)
    }
    }, [success_msg, adminErrorMsg])

    useEffect(()=>{
        if (token=='') {
            dispatch(logoutUser())
            dispatch(logoutCustomer())
            dispatch(logoutAdmin())
            // dispatch(setErrorMessage({message:'Customer does not exist.'}))

            navigate(adminUrls.adminLogin)
        }
    }, [token])

    return (
        <AppWrapper showNormalBreadcrumb={false} role={'admin'} mainAppStyle={mainAppStyle} showNotificationIcon={false}>
            {title!=='' &&
            <div className={'d-flex justify-content-between  mx-xl-3 mx-xxl-1'}>
                 <div className={'mb-3 h1'} style={{color:colors.secondary_color}}>
                    {title}
                </div>

            </div>
            }
            {children}
        </AppWrapper>
    )
}

export  default  AdminWrapper