import AdminWrapper from "../AdminWrapper";
import {ButtonField, ReadonlyFormInput, TextInputField, ColorInputField} from "../../../components/form/components";
import formStyles from "../../../assets/css/formStyle.module.css";
import {useForm} from "react-hook-form";
// import {colors} from "../../../config/color";
import {TabWrapper} from "../_component";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {useEffect, useState} from "react";
import {postRequest, postRequest2} from "../../../config/redux/request";
import {useDispatch, useSelector} from "react-redux";
import {appConfig, endpointUrl, remoteUrl} from "../../../config/config";
import {
    approveSettings,
    clearErrorMsg,
    getSettings,
    onSubmitSettingsForApproval,
    setErrorMsg,
    setLoading, setReload
} from "../../../config/redux/slice/adminSlice";
import {Loader} from "../../../components";
import {clearAll} from "../../../config/redux/slice/authData";
import {Link} from "react-router-dom";
import AdminSuccessAlert from "../_component/AdminSuccessAlert";
import AdminErrorAlert from "../_component/AdminErrorAlert";
import styles from '../../../assets/css/formStyle.module.css'
import {pagesText} from "../../../config/string/english";
import parse from "html-react-parser";
import { setCustomisationValues, setDefaultColors, setDefaultIcon } from "../../../config/redux/slice/appSlice";
import ReadonlyFileInput from "../../../components/form/components/ReadonlyFileInput";

let mb = 'mb-3'
const PolicyTextEditor = () => {

}
const AdminSettingsHome = () => {
    const {userData:{modules, permissions}, token,user_role,permissionRoles} = useSelector((state) => state.authData)
    const {colors, appLogo} = useSelector((state) => state.app)
    const {
        loading,
        success_msg,
        adminErrorMsg,
        pageReload,
        settingsManagement: {settings},
    } = useSelector((state) => state.administrator)
    const {register, handleSubmit, control, getValues, setValue, formState: {errors}, reset} = useForm({
        defaultValues: settings,
        mode: 'onTouched'
    })
    const dispatch = useDispatch()
    //
    const [termsAndCondition, setTermsAndCondition] = useState(settings.terms_and_conditions ?? '')
    const [privacyPolicy, setPrivacyPolicy] = useState(settings.privacy_policy ?? '')
    // const [isUpdated, setIsUpdated] = useState(false)
    const [settingsObj, setSettingsObj] = useState({})
    const [formData, setFormData] = useState(new FormData());


    useEffect(() => {
        dispatch(clearErrorMsg())
        dispatch(clearAll())
        getSettingData()
    }, [])

    // useEffect(() => {
    //     if (JSON.stringify(settingsObj) !== JSON.stringify(settings)) {
    //         console.log('ddd')
    //         setIsUpdated(!isUpdated)
    //     }
    //     setSettingsObj(settings)
    // }, [settings])

    // useEffect(() => {
    //     if (JSON.stringify(settingsObj) !== JSON.stringify(settings)) {
    //         console.log('ddd')
    //         setIsUpdated(!isUpdated)
    //     }
    //     setSettingsObj(settings)
    // }, [])

    // useEffect(() => {

    //     setTermsAndCondition(termsAndCondition);
    //     setPrivacyPolicy(privacyPolicy)
    //     // window.location.reload();
    // }, [termsAndCondition, privacyPolicy])

    //
    const onSubmitSettingsData = async (data) => {
        dispatch(clearErrorMsg())
        dispatch(clearAll())
        data = {...data,
            terms_and_conditions: termsAndCondition,
            privacy_policy: privacyPolicy,
         }
        for (let key in data){  
            if(key == "logo"){
                if(data[key].length != 0){
                    formData.append(key, data[key][0])
                }
            } else{
                formData.append(key, data[key])
            }
        }
     
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.submitSettings}`,
            formData,
            dispatch,
            setLoading,
            "",
            setErrorMsg, 
            true
            )
        if(res !== undefined && res.status < 400){
            dispatch(onSubmitSettingsForApproval({message: res.data.message}))
            setDefaultValues(res.data.data)
            setFormData(new FormData())
        }else{
            setFormData(new FormData())
        }
    }

    //
    const approveSetting = async () => {
        //approveSettings
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.approveSettings}`,
            {"jwt": token},
            dispatch,
            setLoading,
            "",
            setErrorMsg)
        if(res !== undefined && res.status < 400){
            dispatch(approveSettings({message: res.data.message}))
            setDefaultValues(res.data.data)
            setAppSettings(res.data.data) 
        }    
    }

    //
    /*Get Settings Information*/
    const getSettingData = async () => {
        //getSettings
        // dispatch(clearSettingsUpdated())
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getSettings}`,
            {"jwt": token},
            dispatch,
            setLoading,
            "",
            setErrorMsg)

        if(res !== undefined && res.status < 400){
            setDefaultValues(res.data.data)   
        }    
    }

    // if(pageReload){
    //     dispatch(setReload({payload:!pageReload}))
    //     // window.location.reload()
    // }
    const setDefaultValues = (resData)=>{
        dispatch(getSettings(resData))
        // setIsUpdated(true)
        reset({
            ...resData,
            primary_color_colorpicker: resData?.primary_color,
            secondary_color_colorpicker: resData?.secondary_color,
            accent_color_colorpicker: resData?.accent_color,
            })
        setTermsAndCondition(resData?.terms_and_conditions ?? '')  
        setPrivacyPolicy(resData?.privacy_policy ?? '')
        setSettingsObj(resData)
    }

    const setAppSettings = (resData)=>{
        dispatch(setCustomisationValues(resData))
        dispatch(setDefaultColors())
        dispatch(setDefaultIcon())
    }

    return (
        <AdminWrapper mainAppStyle={'col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12  mx-auto mx-xl-1 mx-xxl-0 mt-5'}
                      title={''} reload={true}>
            {/**/}
            <form onSubmit={handleSubmit(onSubmitSettingsData)} id={'form'}>
            <div className="row">
                <div className="col-md-12">
                    <div className="card py-2">
                        <div className="card-body">
                            {success_msg && <AdminSuccessAlert msg={success_msg} toastId={'general_settings'}/>}
                            {adminErrorMsg && <AdminErrorAlert msg={adminErrorMsg} toastId={'general_settings'}/>}
                            <div className={'d-flex justify-content-between align-items-center'}>
                                <div style={{columnGap: '1rem'}} className="d-flex align-items-center">
                                    <ul className="nav nav-pills nav-secondary" id="pills-tab" role="tablist">
                                        <li className={`nav-item  submenu`}>
                                            <a
                                                className={`nav-link active`}
                                                id={'1'}
                                                data-toggle="pill"
                                                href={`#one`}
                                                role="tab"
                                                aria-controls={'one'}
                                                aria-selected="false">
                                                General Settings
                                            </a>
                                        </li>
                                        <li className={`nav-item  submenu`}>
                                            <a
                                                className={`nav-link`}
                                                id={'4'}
                                                data-toggle="pill"
                                                href={`#four`}
                                                role="tab"
                                                aria-controls={'four'}
                                                aria-selected="false">
                                                Visual Settings
                                            </a>
                                        </li>
                                        <li className={`nav-item  submenu`}>
                                            <a
                                                className={`nav-link`}
                                                id={'2'}
                                                data-toggle="pill"
                                                href={`#two`}
                                                role="tab"
                                                aria-controls={'two'}
                                                aria-selected="false">
                                                Terms and Conditions
                                            </a>
                                        </li>
                                        <li className={`nav-item  submenu`}>
                                            <a
                                                className={`nav-link`}
                                                id={'3'}
                                                data-toggle="pill"
                                                href={`#three`}
                                                role="tab"
                                                aria-controls={'three'}
                                                aria-selected="false">
                                                Privacy Policy
                                            </a>
                                        </li>
                                    </ul>
                                    <div className={`text-capitalize badge ${settingsObj?.request_status == 'pending' ? 'badge-warning' : 'badge-success'}`}>{settingsObj?.request_status}</div>
                                </div>    
                                {/**/}
                                <div className={''}>
                                    {loading ?
                                    <div className={'pull-right d-flex mr-5'}>
                                        <Loader/>
                                    </div>
                                        :
                                        <div className={'pull-right d-flex'}>
                                            {
                                                permissions?.includes("CanSaveSettings") &&
                                            <ButtonField
                                                id={'s'}
                                                txtColor={colors.white}
                                                bgColor={colors.secondary_color}
                                                btnWidth={'100'}
                                                btnStyle={'py-2'}
                                                btnTxt={'Save Settings'}
                                            />}
                                                {
                                                     (permissions?.includes("CanApproveSettings") && settingsObj?.request_status == 'pending') &&
                                                        <Link
                                                            className={`btn ml-2 py-2`}
                                                            style={{
                                                                borderColor: colors.primary_color,
                                                                borderRadius: `50px 50px`,
                                                                padding: `0.8rem 1.5rem`,
                                                                background: colors.primary_color,
                                                                color: colors.white
                                                            }}
                                                            onClick={() => approveSetting()} to={''}>Approve Settings</Link>
                                            }
                                        </div>

                                    }
                                </div>
                            </div>


                            {/**/}
                                <div className="tab-content mt-2 mb-3" id="pills-tabContent">
                                    <div
                                        className={`tab-pane fade active show`}
                                        id={'one'}
                                        role="tabpanel"
                                        aria-labelledby={'1'}
                                    >
                                        <div className={''}>
                                            {/*  7.bank_name*/}
                                            <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                    ?
                                                    <TextInputField
                                                        defaultVal={''}
                                                        disabled={true}
                                                        label={'Bank Name'}
                                                        error_msg={errors.bank_name?.message}
                                                        fieldName={'bank_name'}
                                                        isReq={true}
                                                        placeholder={''}
                                                        register={register}
                                                        patternRules={'letters'}
                                                    />
                                                    :
                                                    <ReadonlyFormInput label={'Bank Name'} defaultVal={settings?.bank_name} placeholder={''}/>
                                                }
                                            </div>
                                            <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                    ?
                                                <TextInputField
                                                    defaultVal={settings?.qapp_url}
                                                    label={'QApp url'}
                                                    error_msg={errors.qapp_url?.message}
                                                    fieldName={'qapp_url'}
                                                    isReq={true}
                                                    placeholder={''}
                                                    register={register}
                                                    // patternRules={'letters'}
                                                />
                                                    :
                                                    <ReadonlyFormInput label={'QApp url'} defaultVal={settings?.qapp_url} placeholder={''}/>
                                                }
                                            </div>
                                            {/*  8.bank_address*/}
                                            <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                        ?
                                                <TextInputField
                                                    // inputStyles={}
                                                    // onClickFunc={}
                                                    defaultVal={settings?.bank_address}
                                                    label={'Bank Address'}
                                                    error_msg={errors.bank_address?.message}
                                                    fieldName={'bank_address'}
                                                    isReq={true}
                                                    placeholder={''}
                                                    register={register}
                                                    // patternRules={'letters_numbers'}
                                                />
                                                        :
                                                        <ReadonlyFormInput label={'Bank Address'} defaultVal={settings?.bank_address} placeholder={''}/>
                                                }
                                            </div>
                                            {/*  9.contact_phone*/}
                                            <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                        ?
                                                <TextInputField
                                                    // inputStyles={}
                                                    // onClickFunc={}
                                                    defaultVal={settings?.contact_phone}
                                                    label={'Contact Phone'}
                                                    error_msg={errors.contact_phone?.message}
                                                    fieldName={'contact_phone'}
                                                    isReq={true}
                                                    placeholder={''}
                                                    register={register}
                                                    // patternRules={'numbers'}
                                                />
                                                        :
                                                        <ReadonlyFormInput label={'Contact Phone'} defaultVal={settings?.contact_phone} placeholder={''}/>
                                                }
                                            </div>
                                            {/*  10.contact_email*/}
                                            <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                        ?
                                                <TextInputField
                                                    defaultVal={settings?.contact_email}
                                                    label={'Contact Email'}
                                                    error_msg={errors.contact_email?.message}
                                                    fieldName={'contact_email'}
                                                    isReq={true}
                                                    placeholder={''}
                                                    register={register}
                                                    patternRules={'email'}
                                                /> :
                                                        <ReadonlyFormInput label={'Contact Email'} defaultVal={settings?.contact_email} placeholder={''}/>
                                                }

                                            </div>
                                            {/*  6.currency  */}
                                            <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                        ?
                                                <TextInputField
                                                    // inputStyles={}
                                                    // onClickFunc={}
                                                    defaultVal={settings?.currency}
                                                    label={'Currency'}
                                                    error_msg={errors.currency?.message}
                                                    fieldName={'currency'}
                                                    isReq={true}
                                                    placeholder={''}
                                                    register={register}
                                                    // patternRules={'letters'}
                                                />
                                                        :

                                                    <ReadonlyFormInput label={'Currency'} defaultVal={settings?.currency} placeholder={''}/>
                                                }
                                            </div>
                                            {/*  5.country_time_zone  */}
                                            <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                        ?
                                                <TextInputField
                                                    // inputStyles={}
                                                    // onClickFunc={}
                                                    defaultVal={settings?.country_time_zone}
                                                    label={'Country Time Zone'}
                                                    error_msg={errors.country_time_zone?.message}
                                                    fieldName={'country_time_zone'}
                                                    isReq={true}
                                                    placeholder={''}
                                                    register={register}
                                                    // patternRules={'letters'}
                                                />
                                                        :
                                                        <ReadonlyFormInput label={'Country Time Zone'} defaultVal={settings?.country_time_zone} placeholder={''}/>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`tab-pane fade`}
                                        id={'four'}
                                        role="tabpanel"
                                        aria-labelledby={'4'}
                                    >
                                        <div className={''}>
                                            {/*  7.bank_name*/}
                                            <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                    ?
                                                    <ColorInputField
                                                        // inputStyles={}
                                                        // onClickFunc={}
                                                        defaultVal={settings?.primary_color}
                                                        label={'Primary Color'}
                                                        setValueFunc={setValue}
                                                        getValueFunc={getValues}
                                                        error_msg={errors.primary_color?.message}
                                                        fieldName={'primary_color'}
                                                        isReq={true}
                                                        placeholder={''}
                                                        register={register}
                                                        // patternRules={'letters_numbers'}
                                                    />
                                                    :
                                                    <ReadonlyFormInput label={'Primary Color'} defaultVal={settings?.primary_color} placeholder={''}/>
                                                }
                                            </div>
                                            <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                    ?
                                                <ColorInputField
                                                    // inputStyles={}
                                                    // onClickFunc={}
                                                    defaultVal={settings?.secondary_color}
                                                    label={'Secondary Color'}
                                                    setValueFunc={setValue}
                                                    error_msg={errors.secondary_color?.message}
                                                    fieldName={'secondary_color'}
                                                    isReq={true}
                                                    placeholder={''}
                                                    register={register}
                                                    // patternRules={'letters_numbers'}
                                                />
                                                    :
                                                    <ReadonlyFormInput label={'Secondary Color'} defaultVal={settings?.secondary_color} placeholder={''}/>
                                                }
                                            </div>
                                            {/*  8.bank_address*/}
                                            {/* <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                        ?
                                                <TextInputField
                                                    // inputStyles={}
                                                    // onClickFunc={}
                                                    defaultVal={settings?.accent_color}
                                                    label={'Accent Color'}
                                                    error_msg={errors.accent_color?.message}
                                                    fieldName={'accent_color'}
                                                    isReq={true}
                                                    placeholder={''}
                                                    register={register}
                                                    // patternRules={'letters_numbers'}
                                                />
                                                        :
                                                        <ReadonlyFormInput label={'Accent Color'} defaultVal={settings?.accent_color} placeholder={''}/>
                                                }
                                            </div> */}
                                            <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                        ?
                                                <ColorInputField
                                                    // inputStyles={}
                                                    // onClickFunc={}
                                                    defaultVal={settings?.accent_color}
                                                    label={'Accent Color'}
                                                    setValueFunc={setValue}
                                                    error_msg={errors.accent_color?.message}
                                                    fieldName={'accent_color'}
                                                    isReq={true}
                                                    placeholder={''}
                                                    register={register}
                                                    // patternRules={'letters_numbers'}
                                                />
                                                        :
                                                        <ReadonlyFormInput label={'Accent Color'} defaultVal={settings?.accent_color} placeholder={''}/>
                                                }
                                            </div>
                                            {/*  9.contact_phone*/}
                                            <div className={` ${mb}`}>
                                                {
                                                    permissions?.includes("CanEditBankInfo")
                                                        ?
                                                <div className={'form-group'}>
                                                    <label className="fw-bold">App Logo</label>
                                                    <input
                                                    className={`form-control ${formStyles.textField} ${errors.logo?.message && 'is-invalid'}`}
                                                    {...register(`logo`, {
                                                        required: {
                                                            value: appLogo != "" ? false : true,
                                                            message: '*this field is required'
                                                        },
                                                        })}                                       
                                                    type={'file'} style={{borderRadius:'50px'}}/>
                                                    {errors.logo?.message && <div className="invalid-feedback ml-3">{errors.logo?.message}</div>}
                                                </div>
                                                        :
                                                        <ReadonlyFileInput label={'Logo'} defaultVal={settings?.logo_url} placeholder={''}/>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/**/}
                                    <div
                                        className={`tab-pane fade`}
                                        id={'two'}
                                        role="tabpanel"
                                        aria-labelledby={'2'}
                                    >
                                        {
                                            permissions?.includes("CanEditTermsAndConditions")
                                                ?
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={termsAndCondition}
                                            onReady={editor => {
                                                editor.setData(settings?.terms_and_conditions ?? '')
                                                setTermsAndCondition(settings?.terms_and_conditions ?? '')
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setTermsAndCondition(data)
                                            }}
                                        />
                                                :
                                                parse(settings?.terms_and_conditions ?? '')
                                        }
                                    </div>
                                    {/*Privacy Policy*/}
                                    <div
                                        className={`tab-pane fade`}
                                        id={'three'}
                                        role="tabpanel"
                                        aria-labelledby={'3'}
                                    >

                                        {
                                            permissions?.includes("CanEditPrivacyPolicy")
                                                ?
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={privacyPolicy}
                                            initData={privacyPolicy}
                                            onReady={editor => {
                                                editor.setData(settings?.privacy_policy ?? '')
                                                setPrivacyPolicy(settings?.privacy_policy ?? '')
                                            }}
                                            onChange={(event, editor) => {
                                                const data = editor.getData();
                                                setPrivacyPolicy(data)
                                            }}
                                        />
                                                :
                                                parse(settings?.privacy_policy ?? '')
                                        }
                                    </div>


                                </div> 


                        </div>
                    </div>
                </div>
            </div>
            </form>
        </AdminWrapper>
    )
}


export default AdminSettingsHome