import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
// import { colors } from "../../../config/color";
import { handleAgent, type } from "../../../config/redux/slice/adminSlice";
import AdminWrapper from "../AdminWrapper";
import { LinkWrapper } from "../_component";
import AgentItemDetail from "./AgentItemDetail";

const ViewProfileAgent = () => {
    const location = useLocation()
    const {agentsManagement:{agentDetail}} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(handleAgent({type:type.agents_details,data:location.state.data}))
    },[])
    return <AdminWrapper title={'Agent Profile'}>
        <div className={'d-flex justify-content-between mx-4 mb-5'}>
            <img src={agentDetail.profile_image} alt={''} height={'80'}/>
            <div className={'d-flex flex-column gap-2'}>
                <Link to={''} className={'btn'}
                      style={{color: colors.white, background: `${colors.secondary_color}`}}>Credit Agent</Link>
                <Link to={''} className={'btn'}
                      style={{color: colors.secondary_color, border: `1px solid ${colors.secondary_color}`}}>Edit Profile</Link>
            </div>
        </div>

        <div className={'mx-4'}>
            <h5 className={'mb-4'} style={{color:colors.secondary_color}}>Personal Information</h5>
            <div className={'d-flex justify-content-between'}>
                <div>
                    {/*first name*/}
                    <div>
                        <h6>First Name</h6>
                        <div>{agentDetail.first_name}</div>
                    </div>
                    {/*phone number    */}
                    <div className={'mt-3'}>
                        <h6>Phone Number</h6>
                        <div>{agentDetail.phone_number}</div>
                    </div>
                </div>
                {/* middle */}
                <div>
                    {/*last name    */}
                    <div>
                        <h6>Last  Name</h6>
                        <div>{agentDetail.last_name}</div>
                    </div>
                    {/* */}
                    <div className={'mt-3'}>
                        <h6>Address</h6>
                        <div>{agentDetail.address}</div>
                    </div>

                </div>
                {/*last layer*/}
                <div>
                    {/*email*/}
                    <div>
                        <h6>Email Address</h6>
                        <div>{agentDetail.email}</div>
                    </div>
                    {/*phone number    */}
                    <div className={'mt-3'}>
                        <h6>Bank Verification Number</h6>
                        <div>{agentDetail.bvn}</div>
                    </div>

                </div>
            </div>
        </div>
       {/* account information*/}
        <div className={'mx-4 mt-5'}>
            <h5 className={'mb-4'} style={{color:colors.secondary_color}}>Account Information</h5>
            <div className={'d-flex justify-content-between'}>
                <div>
                    <AgentItemDetail title={'Account Name'} data={agentDetail?.account_information?.account_name}/>
                    <AgentItemDetail title={'Currency'} data={agentDetail?.account_information?.currency}/>
                    <AgentItemDetail title={'Primary Account'} data={agentDetail?.account_information?.is_primary_account}/>
                </div>
                {/* middle */}
                <div>
                    <AgentItemDetail title={'Account Class'} data={agentDetail?.account_information?.account_class}/>
                    <AgentItemDetail title={'Account Category'} data={agentDetail?.account_information?.account_category}/>
                </div>
                {/*last layer*/}
                <div>
                    <AgentItemDetail title={'Account Number'} data={agentDetail?.account_information?.account_number}/>
                    <AgentItemDetail title={'Opening Branch'} data={agentDetail?.account_information?.opening_branch_name}/>
                </div>
            </div>
        </div>
       {/*   devices */}
        <div className={'mx-4 my-5'}>
            <h5 className={'mb-4'} style={{color:colors.secondary_color}}>Devices</h5>
            <div>
                {agentDetail.accounts?.map((item,i=0) =>
                    <div key={i} className={'d-flex justify-content-between mb-4'}>
                        <AgentItemDetail  title={'Device'} data={item.device_name}/>
                        <AgentItemDetail  title={'IMEI Number'} data={item.imb_number}/>
                        <AgentItemDetail  title={'Registration Status'} data={item.registration_status ? 'Approved' : 'UnApproved'}/>
                        <AgentItemDetail  title={'Amount'} data={Number(item.account_balance).toLocaleString()}/>
                        <div>
                            <Link className={'btn'} style={{background:colors.secondary_color,color:colors.white}} to={''}>Deactivate</Link>
                        </div>
                    </div>
                )}
            </div>

        </div>
      {/* action button    */}
        <div className={'mx-auto w-75 text-center'}>
            <LinkWrapper btnTxt={'Add New Device'} btnStyle={{background:colors.secondary_color,color:colors.white}} urlPath={''} />
        </div>
    </AdminWrapper>
}

export default  ViewProfileAgent