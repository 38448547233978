import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { ButtonField, PasswordField } from "../../components/form/components";
// import { colors } from "../../config/color";
import { appConfig, endpointUrl } from "../../config/config";
import { postRequest2 } from "../../config/redux/request";
import { clearErrorMsg, logoutAdmin, setAdminReLogin } from "../../config/redux/slice/adminSlice";
import {
    clearAll, logoutUser,
    onCustomerLogin,
    setErrorMessage,
    setLoading, setReLoginModalForm,
} from "../../config/redux/slice/authData";
import { customerReLogin, logoutCustomer } from "../../config/redux/slice/customerSlice";
import AlertWrapper from "../_shared_component/AlertWrapper";
import { PhoneNumberComponent } from "../onboard_one/components";
import { urls } from "../../config/urls/urls";

const ReLoginModal = ({showLoginModal}) => {
    const [togglePassword, setTogglePassword] = useState(false)
    const {colors} = useSelector((state) => state.app)
    const {loading, user_role, errorMsg,success_msg} = useSelector((state) => state.authData)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const closeAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const onSubmit = async (data) => {
        //DrDre2023@3? , deresundaytoluwani+3@gmail.com
        dispatch(clearAll({}))
        dispatch(clearErrorMsg())
        let phone = data.code + data.phone;
        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.login}`,
            {...data, phone},
            dispatch,
            setLoading,
            onCustomerLogin,
            setErrorMessage
        )

        if(res !== undefined && res.status < 400){
            reLoginAction()
            // navigate(0)
        }
    }

    const reLoginAction = () => {
        dispatch(setAdminReLogin())
        dispatch(customerReLogin())
        dispatch(setReLoginModalForm({modal:false,error_message:'',success_message:''}))
    }

    const logout = () => {
        dispatch(logoutCustomer())
        dispatch(logoutUser())
        dispatch(logoutAdmin())
        if(user_role === 'customer'){
            navigate(urls.landingPage)
        }else{
            navigate(urls.adminLogin)
        }
    }

    return (
        <div className={''} >
            <div className="modal fade show"
                 id="basicModal"
                 tabIndex="-1" aria-hidden="true"
                 style={{
                     display: "block",
                     paddingRight: "17px",
                 }}
            >
                <div className="modal-dialog" style={{background:'white'}}>
                    <div className="modal-content">
                        <div className="modal-header mt-2" style={{border: "0px"}}>
                        <h2 className="mx-auto" style={{color:colors.secondary_color}}>Login to Your Account</h2>
                        </div>
                        <div className="modal-body">
                           
                            <div className={'px-5 py-3'}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {errorMsg ? 
                                    (<AlertWrapper onClick={closeAlert} type={'error'}>
                                        <div className={'py-1 text-capitalize'}>{errorMsg}</div>
                                    </AlertWrapper>)
                                    : null}

                                    <div className={'mb-3'}>
                                        <PhoneNumberComponent
                                            fieldName={'phone'}
                                            setValueFunc={setValue}
                                            error_msg={errors.phone?.message}
                                            col={'col-xl-12'}
                                            label={'Phone Number'}
                                            nextCompId={'passcode'}
                                            placeholder={''}
                                            isReq={true}
                                            register={register}
                                            defaultVal={''}
                                            id={'phone'}
                                            patternRules={''}
                                        />
                                    </div>
                                    {/*    */}
                                    <div className={'mb-3'}>
                                    <PasswordField
                                        fieldName={'passcode'}
                                        setValueFunc={setValue}
                                        error_msg={errors.passcode?.message}
                                        col={'col-xl-12'}
                                        label={'Enter your Passcode'}
                                        placeholder={''}
                                        isReq={true}
                                        minlength={6}
                                        maxlength={6}
                                        register={register}
                                        defaultVal={''}
                                        patternRules={'passcode'}
                                    />
                                    </div>

                                    <div className={'mt-4'}>
                                        {loading ? <Loader/> :
                                            <ButtonField
                                                txtColor={colors.white}
                                                bgColor={colors.primary_color}
                                                btnTxt={'Log In'}
                                                btnWidth={'100'}
                                                btnStyle={'mx-auto col-xl-12 mt-1 pt-2 pb-2'}/>}
                                    </div>
                                </form>
                                <p className={'text-center pt-3'}> Not your Account?
                                    &nbsp;
                                    <Link
                                        to={'#'}
                                        onClick={logout}
                                        style={{color: colors.primary_color}}
                                    >
                                        Logout
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {showLoginModal && <div className="modal-backdrop fade show"></div>}

        </div>
    )
}

export default ReLoginModal