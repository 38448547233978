import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appConfig, endpointUrl } from "../../../config/config";
import { NumberFormatter } from "../../../config/redux/actions";
import { postRequest2 } from "../../../config/redux/request";
import {
    getAllTiers,
    setCustomerErrorMsg,
    setLoading,
} from "../../../config/redux/slice/customerSlice";

const ProfileDetails  = () => {
    const {userData} = useSelector((state) => state.authData)
    const dispatch = useDispatch()

    const [currentTierInfo, setCurrentTierInfo] = useState({})

    const getTiers = async () => {
        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getTiers}`,
            {},
            dispatch,
            setLoading,
            getAllTiers,
            setCustomerErrorMsg,
        )
        if(res !== undefined && res.status < 400){
            let info = (res.data.data).filter((tierData)=>(tierData.name).toLowerCase() == `tier ${userData?.current_tier}`)
            setCurrentTierInfo(info[0])
        }
    }  
    
    useEffect(()=>{
        getTiers()
    }, [])
    return(
        <>
            <h3 className={'card-header'}>Personal Information</h3>
            <div className={'card-body'}>
                <table className={'table table-striped px-5 '}>
                    <thead>

                    </thead>

                    <tbody>
                    <tr>
                        <td>Fullname</td>
                        <td>{userData.full_name}</td>
                    </tr>

                    <tr>
                        <td>Phone Number</td>
                        <td>{userData.phone_no}</td>
                    </tr>
                    </tbody>

                </table>


            </div>

            {Number(userData?.current_tier) > 1 &&
                <>
                    <h3 className={'card-header'}>Bank Information</h3>
                    <div className={'card-body'}>
                        <table className={'table table-striped px-5 '}>
                            <thead>

                            </thead>

                            <tbody>
                            <tr>
                                <td>BVN</td>
                                <td>{userData?.bvn}</td>
                            </tr>

                            </tbody>

                        </table>


                    </div> 
                </> 
}         
            {Number(userData?.current_tier) > 2 &&
                <>        
                    <h3 className={'card-header'}>Identification Information</h3>
                    <div className={'card-body'}>
                        <table className={'table table-striped px-5 '}>
                            <thead>

                            </thead>

                            <tbody>
                            <tr>
                                <td>Street Address</td>
                                <td>{userData?.current_address_street}</td>
                            </tr>

                            <tr>
                                <td>House Number</td>
                                <td>{userData?.current_address_house_no}</td>
                            </tr>

                            <tr>
                                <td>State</td>
                                <td>{userData?.current_address_state}</td>
                            </tr>


                            <tr>
                                <td>City</td>
                                <td>{userData?.current_address_city}</td>
                            </tr>

                            <tr>
                                <td>Identification Type</td>
                                <td>{userData?.id_type}</td>
                            </tr>


                            <tr>
                                <td>Identification Number</td>
                                <td>{userData?.id_number}</td>
                            </tr>
                            </tbody>

                        </table>


                    </div>  
                </>
            }  

             <h3 className={'card-header'}>Tier Information</h3>
                    <div className={'card-body'}>
                        <table className={'table table-striped px-5 '}>
                            <thead>

                            </thead>

                            <tbody>
                            <tr>
                                <td>Current Tier</td>
                                <td className="text-capitalize">{currentTierInfo?.name}</td>
                            </tr>
                            <tr>
                                <td>Daily Transaction Limit</td>
                                {isNaN(currentTierInfo?.daily_transaction_limit) ? 
                                <td className="text-capitalize">{currentTierInfo?.daily_transaction_limit}</td> :
                                <td>{NumberFormatter(Number(currentTierInfo?.daily_transaction_limit).toFixed(2))}</td>                                
                                }
                            </tr>
                             <tr>
                                <td>Maximum Balance</td>
                                {isNaN(currentTierInfo?.maximum_balance) ? 
                                <td className="text-capitalize">{currentTierInfo?.maximum_balance}</td> :
                                <td>{NumberFormatter(Number(currentTierInfo?.maximum_balance).toFixed(2))}</td>                                
                                }
                            </tr>

                            </tbody>

                        </table>


                    </div>     

            {/* {userData.current_tier == 1 && <div className={'card-footer'}>
                <Link to={'/profile?tier=2'}  className={'btn btn-dark pull-right'}> Upgrade to Tier 2</Link>
            </div>} */}

        </>

    )
}

export default ProfileDetails