// import {colors} from "../../../../config/color";
import {useDispatch, useSelector} from "react-redux";
import {handleCardManagement, type} from "../../../../config/redux/slice/adminSlice";

const BlockCardModal = ({backdrop}) => {
    const {cardManagements:{current_tab}} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()

    const onSubmit = () => {
        dispatch(handleCardManagement({type:type.block_card,modal:false}))
    }


    return <>
        <div className="modal fade show" id="smallModal" tabIndex={-1} aria-modal="true" role="dialog" style={{display: 'block'}}>
            <div className="modal-dialog modal-dialog-centered modal-sm" style={{borderBottom:0}}>
                <div className="modal-content" style={{borderBottom:0}}>
                    <div className="modal-body" style={{borderBottom:`0 solid white`}}>
                        <h6>Are you Sure to block this card</h6>
                    </div>
                    <div className="modal-footer" style={{borderTop:0}}>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                style={{background:colors.white,color:colors.gray}} onClick={()=>dispatch(handleCardManagement({type:current_tab,modal:false}))}>No</button>
                        <button type="button" className="btn btn-primary" onClick={()=>onSubmit()}
                                style={{background:colors.secondary_color,color:colors.white,border:`1px solid ${colors.secondary_color}`}}>
                            Yes
                        </button>
                    </div>
                </div>
            </div>
        </div>
        {backdrop && <div className={`modal-backdrop fade show `}></div>}
    </>
}
export default  BlockCardModal