import AdminWrapper from "../../AdminWrapper";
import {AdminConfirmation, AdminSuccessModalWrapper, TabWrapper} from "../../_component";
import {useSelector} from "react-redux";
import {
    closeFormConfirmationModal,
    setActiveTabInfo,
    submitOperationData, type
} from "../../../../config/redux/slice/adminSlice";
import {strText} from "../../../../config/string";
import {useEffect} from "react";
import {initAdminDepositTable} from "../../../../config/datatableInit";
// import {colors} from "../../../../config/color";

const AdminWithdrawalHome = () => {
    const {colors} = useSelector((state) => state.app)
    const {formConfirmationModal,success_msg,successModal,operationManagement:{withdrawalTabArray,inBankWithdrawalFormData,tokenizedWithdrawalFormData}} = useSelector((state) => state.administrator)
    useEffect(() => {
        initAdminDepositTable([],setSingleItem)
    })

    const getDepositData = () => {
        //

    }

    const setSingleItem = () => {

    }


    return <AdminWrapper title={''}>
        <div className={'card'}>
            <div className={'card-header'}>
                <h2 className={'tableHeader'}>Withdrawal</h2>
            </div>
            <div className={'card-body'}>
                <table className={`${strText.datatable_class}`} id={'table_admin_trans'}>
                    <thead >
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Transaction Reference</th>
                        <th>Source Account</th>
                        <th>Destination Account</th>
                        <th>Amount</th>
                        <th>Receiving Bank</th>
                        <th>Transaction Date</th>
                        <th width={'5%'} data-orderable="false" style={{color:colors.secondary_color}}>Status</th>
                    </tr>
                    </thead>
                    <tbody></tbody>
                </table>
            </div>
        </div>

    {/*    */}
        {
            formConfirmationModal && inBankWithdrawalFormData.account_number !== '' &&
            <AdminConfirmation
                onClose={closeFormConfirmationModal}
                backdrop={formConfirmationModal}
                onSubmit={submitOperationData}
                type = {type.in_bank_withdrawal}
                title={'Confirm In-Bank Withdrawal'}
                dataArray={[
                    {
                        text: 'Account Name',
                        value: inBankWithdrawalFormData?.account_name
                    },
                    {
                        text: 'Account Number',
                        value: inBankWithdrawalFormData.account_number
                    },
                    {
                        text: 'Amount',
                        value: `${strText.naira} ${Number(inBankWithdrawalFormData.amount).toLocaleString()}`
                    },
                ]}
            />
        }
        {/**/}
        {
            formConfirmationModal && tokenizedWithdrawalFormData.account_number !== '' &&
            <AdminConfirmation
                onClose={closeFormConfirmationModal}
                backdrop={formConfirmationModal}
                onSubmit={submitOperationData}
                type = {type.tokenized_withdrawal}
                title={'Confirm Tokenized Withdrawal'}
                dataArray={[
                    {
                        text: 'Account Name',
                        value: tokenizedWithdrawalFormData?.account_name
                    },
                    {
                        text: 'Account Number',
                        value: tokenizedWithdrawalFormData.account_number
                    },
                    {
                        text: 'Amount',
                        value: `${strText.naira} ${Number(tokenizedWithdrawalFormData.amount).toLocaleString()}`
                    },
                ]}
            />
        }


        {
            successModal &&
            <AdminSuccessModalWrapper
                btnText={'Continue'}
                msg={success_msg}
                backdrop={successModal}
            />
        }
    </AdminWrapper>
}


export default  AdminWithdrawalHome