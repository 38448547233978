import { useState } from "react";
import { useDispatch } from "react-redux";
import { handleSearchBeneficiaries } from "../../config/redux/slice/authData";

const SearchBeneficiaryComponent = ({updateFieldFunc, fieldToUpdateArray, beneficiaryList,backdrop,clearErrors}) => {
    const dispatch = useDispatch()
    const [newBeneficiaryList, setNewBeneficiaryList] = useState(beneficiaryList)

    const searchData = (searchText) => {
        if(searchText.length>0){
            let NewData = beneficiaryList.filter((item) =>
                    `/${item.beneficiary_name.toLowerCase()}*/gi`.match(searchText.toLowerCase()) ||
                    `/${item.beneficiary_account_number}*/gi`.match(searchText) ||
                    `/${item.beneficiary_bank.toLowerCase()}*/gi`.match(searchText.toLowerCase())
                )
            setNewBeneficiaryList(NewData)
        }else{
            setNewBeneficiaryList(beneficiaryList)
        }
    }
    const onClose = () => {
        dispatch(handleSearchBeneficiaries())
    }

    const selectedData = (data) => {
        clearErrors(fieldToUpdateArray[0])
        clearErrors(fieldToUpdateArray[1])
        clearErrors(fieldToUpdateArray[2])
        clearErrors(fieldToUpdateArray[3]) //actual bank name

        updateFieldFunc(fieldToUpdateArray[0], data.beneficiary_name)
        updateFieldFunc(fieldToUpdateArray[1], data.bank_id)
        updateFieldFunc(fieldToUpdateArray[2], data.beneficiary_account_number)
        updateFieldFunc(fieldToUpdateArray[3], data.beneficiary_bank)
        dispatch(handleSearchBeneficiaries())
    }

    return (
            <>
                <div className="modal fade show"
                     id="exampleModalCenter"
                     style={{display: "block", paddingRight: "17px"}}
                     aria-modal="true" role="dialog">
                    <div className="modal-dialog card modal-dialog-centered modal-dialog-scrollable " role="document">
                        <div className="modal-content  p-lg-2">
                            <div className="modal-header" style={{border: "0px"}}>
                                {/*<button*/}
                                {/*    type="button" className="btn-close btn-lg " data-bs-dismiss="modal" */}
                                {/*    onClick={() => onClose()}></button>*/}

                                <button type="button" className="close" data-dismiss="modal"  aria-label="Close" onClick={() => onClose()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className={'mx-auto w-100 py-1'}>
                                        <div className={''}>
                                            <input
                                                type={'text'}
                                                className={'form-control input-square'}
                                                style={{width:'100%'}}
                                                placeholder={'search beneficiary'}
                                                onChange={(e) => searchData(e.target.value)}/>
                                            <div>
                                                <ul className="mt-5">
                                                    {/*{newBeneficiaryList?.map((item, i = 0) =>*/}
                                                    {/*    <li  key={i} className="d-flex gap-2 mt-4 text-left" >*/}
                                                    {/*      */}
                                                    {/*        <p className={'d-flex flex-column'}>*/}
                                                    {/*            <b></b>*/}
                                                    {/*            <span>- <b>{item.beneficiary_account_number}</b></span>*/}
                                                    {/*        </p>*/}
                                                    {/*    </li>*/}
                                                    {/*)}*/}
                                                    <table className={'table table-striped'}>
                                                        <thead></thead>
                                                        <tbody>
                                                        {newBeneficiaryList.map((item,i=0) =>
                                                          <tr key={i} onClick={() => selectedData(item)} style={{cursor:'pointer'}}>
                                                              <td> <img src={'../assets/images/icon/contact.svg'} alt={''} className={'h-25 mr-3'}/></td>
                                                              <td>{item?.beneficiary_name}</td>
                                                              <td>{item?.beneficiary_bank} </td>
                                                              <td>{item?.beneficiary_account_number} </td>
                                                          </tr>
                                                        )}
                                                        </tbody>
                                                    </table>
                                                </ul>
                                            </div>
                                        </div>



                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {backdrop && <div className="modal-backdrop fade show"></div>}
            </>

    )
}
export default SearchBeneficiaryComponent