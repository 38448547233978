import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from '../../../../../assets/css/formStyle.module.css';
import { ButtonField, SelectField, TextInputField } from "../../../../../components/form/components";
// import { colors } from "../../../../../config/color";
import { appConfig, crypto_secret_key, remoteUrl } from "../../../../../config/config";
import { postRequest2 } from "../../../../../config/redux/request";
import { handleSearchBanks, handleSearchBeneficiaries } from "../../../../../config/redux/slice/authData";
import {
    getAvailableAccounts, resetTransferFormData,
    setCustomerErrorMsg,
    setGeneralModal,
    setLoading,
    setTransferForm
} from "../../../../../config/redux/slice/customerSlice";
import { respMsg } from "../../../../../config/response_messages";
import { urls } from "../../../../../config/urls/urls";
import { SearchBankComponent, SearchBeneficiaryComponent } from "../../../../layout";
import AvailableAccountsComponent from "../../../../layout/AvailableAccountsComponent";
import { decryptAesCbc } from "../../../../../helpers/roleHelper";


const NewTransfer = () => {
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        clearErrors,
        formState: {errors},
        reset, watch,setError
    } = useForm({mode: 'onTouched'})
    const {colors} = useSelector((state) => state.app)
    const {
        userData,
        token,
        types_of_transfers,
        banks,
        showBeneficiaryModal,
        showBankModal
    } = useSelector((state) => state.authData)
    const dispatch = useDispatch()

    const {beneficiaries,available_accounts,success_msg} = useSelector((state) => state.customer)
    const [tempAmount, setTempAmount] = useState('0.00')
    const [realAmount,setRealAmount] =  useState()
    const [showAvailAccount,setShowAvailAccount] =  useState(false)
    const navigate = useNavigate()


    const onContinue = (data) => {
        //temp_amount
        if(Number(realAmount) < 1){
            setError('temp_amount',{message:'invalid amount'})
        }
        else{
            data.amount  = realAmount
            dispatch(setGeneralModal({
                show: true,
                msg: '',
                backdrop: true
            }))
            dispatch(setTransferForm({data, tempFunc: reset}))
        }
        // data.amount  = realAmount
        dispatch(setGeneralModal({
            show: true,
            msg: '',
            backdrop: true
        }))
        dispatch(setTransferForm({data, tempFunc: reset}))
    }

    const handleBankField = (data) => {
        dispatch(handleSearchBanks())

    }

    function formatNumber(e) {
        var rex = /(^\d{2})|(\d{1,3})(?=\d{1,3}|$)/g,
            val = this.value.replace(/^0+|\.|,/g, ""),
            res;

        if (val.length) {
            res = Array.prototype.reduce.call(val, (p, c) => c + p) // reverse the pure numbers string
                .match(rex) // get groups in array
                .reduce((p, c, i) => i - 1 ? p + "," + c : p + "." + c); // insert (.) and (,) accordingly
            res += /\.|,/.test(res) ? "" : ".0"; // test if res has (.) or (,) in it
            this.value = Array.prototype.reduce.call(res, (p, c) => c + p); // reverse the string and display
        }
    }

    const formatAmount = (data) => {
        let o = data.replace(".00",'')
        let temp_data = o.replaceAll(",",'').trim()
        setRealAmount(temp_data)
        // let x = NumberFormatter(Number(temp_data).toFixed(2))
    }

    const getAvailAccounts = () => {
        // let postData = {
        //     "jwt" : token,
        // }
        const res =  postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.getAvailAccounts}`,
            {},
            dispatch,
            setLoading,
            getAvailableAccounts,
            setCustomerErrorMsg)
    }

    const handleAvailableAccount = () => {
        setShowAvailAccount(true)
    }


    useEffect(() => {
        // console.log(decryptAesCbc(userData?.encryptKey, crypto_secret_key))
        let ni = document.getElementById("amount");
        ni.addEventListener("keyup", formatNumber);
        getAvailAccounts()
    },[])



    if( success_msg===respMsg.save_beneficiary){
        dispatch(resetTransferFormData())
        // setIsAliasForm(false)
        return navigate(`${urls.transactionHistory}`)
    }

    return (
        <div className={'mx-auto col-sm-10 col-md-10 col-lg-8 col-xl-10 mt-2 py-1'}>
            {/**/}
            {showAvailAccount &&  <AvailableAccountsComponent
                updateFieldFunc={setValue}
                fieldToUpdateArray={['destination_bank_id', 'account_number', 'beneficiary']}
                backdrop={showAvailAccount}
                accountList={available_accounts}
                clearErrors={clearErrors} closeFunc={setShowAvailAccount}
            /> }

            {showBeneficiaryModal && <SearchBeneficiaryComponent
                updateFieldFunc={setValue}
                fieldToUpdateArray={['beneficiary', 'bank_id', 'account_number', 'bank_name']}
                beneficiaryList={beneficiaries}
                backdrop={showBeneficiaryModal}
                clearErrors={clearErrors}
            />}
            {
                showBankModal && <SearchBankComponent
                    updateFieldFunc={setValue}
                    fieldToUpdateArray={['bank_id', 'bank_name']}
                    backdrop={showBankModal}
                    bankList={banks}
                    clearErrors={clearErrors}
                />
            }


            {/**/}

            <form onSubmit={handleSubmit(onContinue)}>
                <div className={'mx-auto w-100'}>
                    <div className={'card  px-sm-0 px-md-5 py-4 '}>
                        <div className={'card-header mb-2'}>
                            <div className={'d-flex justify-content-between'}>
                                <h3 style={{color: colors.secondary_color}}><strong>Transfers</strong></h3>
                                {beneficiaries?.length > 0 &&
                                    <div className={'row'}>
                                        <div className="btn-group dropdown show">
                                            <button type="button"
                                                    className="btn  btn-sm dropdown-toggle"
                                                    style={{background: colors.secondary_color, color: colors.white}}
                                                    onClick={() => dispatch(handleSearchBeneficiaries())}>
													<span className="btn-label">
														<i className="fa fa-user"></i> Choose Beneficiary
													</span>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={'card-body'}>
                            <div className={''}>
                                {/**/}
                                <div className={'row mb-md-2'}>
                                    <div className={'col-sm-12 mb-sm-3 col-md-6 col-lg-6 col-xl-6'}>
                                        <div className={'form-group'}>
                                            <label>Source Account</label>
                                            <input type={'text'} readOnly={true}
                                                   className={`form-control ${styles.textField}`}
                                                   value={userData.account_number}/>
                                        </div>

                                    </div>
                                    <div className={'col-sm-12 mb-sm-3 col-md-6 col-lg-6 col-xl-6'}>
                                        <SelectField
                                            fieldName={'type_of_transfer_id'}
                                            label={'Select Transfer Type'}
                                            col={'col-sm-12 mb-sm-3 col-md-6 col-lg-6 col-xl-6'}
                                            error_msg={errors?.type_of_transfer_id?.message}
                                            isReq={true}
                                            register={register}
                                            defaultVal={''}
                                        >
                                            {
                                                types_of_transfers?.length > 0 && types_of_transfers?.map((item, i = 0) =>
                                                    <option key={i} value={item.id}>{item.name} </option>)
                                            }
                                        </SelectField>
                                    </div>

                                    {/*transfer type*/}

                                </div>
                                {/**/} {/*bank name and  ACCOUNT number*/}
                                <div className={'row mb-md-2'}>
                                    <div className={'col-sm-12 mb-sm-3 col-md-6 col-lg-6 col-xl-6'}>
                                        <input type={'hidden'} {...register('bank_id')}/>
                                        <TextInputField
                                            onClickFunc={handleBankField}
                                            patternRules={'letters'}
                                            col={''}
                                            label={'Bank Name'}
                                            fieldName={'bank_name'}
                                            placeholder={''}
                                            register={register}
                                            // inputStyles={}
                                            error_msg={errors.bank_name?.message}
                                            isReq={true}
                                            defaultVal={''}
                                        />
                                    </div>

                                    <div className={'col-sm-12 mb-sm-3 col-md-6 col-lg-6 col-xl-6'}>
                                        {/*to account number*/}
                                        <TextInputField
                                            col={''}
                                            onClickFunc={handleAvailableAccount}
                                            label={'To Account'}
                                            patternRules={'numbers'}
                                            fieldName={'account_number'}
                                            placeholder={''}
                                            register={register}
                                            // inputStyles={}
                                            error_msg={errors.account_number?.message}
                                            isReq={true}
                                            defaultVal={''}
                                        />
                                    </div>
                                </div>

                                <div className={'row mb-md-2'}>
                                    {/*Beneficiary*/}
                                    <div className={'col-sm-12 mb-sm-3 col-md-6 col-lg-6 col-xl-6'}>
                                        <TextInputField
                                            col={''}
                                            patternRules={'letters'}
                                            label={'Beneficiary'}
                                            fieldName={'beneficiary'}
                                            placeholder={''}
                                            register={register}
                                            // inputStyles={}
                                            error_msg={errors.beneficiary?.message}
                                            isReq={true}
                                            defaultVal={''}
                                        />
                                    </div>
                                    {/*amount*/}
                                    <input type={'hidden'} id={'temp_amount'} defaultValue={realAmount} {...register('amount')}/>
                                    <div className={'col-sm-12 mb-sm-3 col-md-6 col-lg-6 col-xl-6'}>
                                        <div className={'form-group'}>
                                            <label>Amount </label>
                                            <input
                                                type={'text'}
                                                onKeyUp={(e)=> formatAmount(e.target?.value)}
                                                defaultValue={''}
                                                id={"amount"}
                                                className={`form-control ${styles.textField}  ${errors.temp_amount?.message && 'is-invalid'}`}
                                                {...register('temp_amount', {
                                                    required: {
                                                        value: true,
                                                        message: 'this field is required'
                                                    }
                                                })}/>
                                            {errors.temp_amount && <div className="invalid-feedback">{errors.temp_amount?.message}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className={'row mb-md-2'}>
                                    {/*description*/}
                                    <div className={'col-xl-12'}>
                                        <TextInputField
                                            col={''}
                                            patternRules={'letters_numbers'}
                                            label={'Narration'}
                                            fieldName={'description'}
                                            placeholder={''}
                                            register={register}
                                            // inputStyles={}
                                            error_msg={errors.description?.message}
                                            isReq={false}
                                            defaultVal={''}
                                            // col={'col-xl-12 mb-4'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/**/}
                        <div className={'mx-3'} style={{borderTop: '0px'}}>
                            <div className={'text-center mt-1'}>
                                <ButtonField btnWidth={'50'} btnTxt={'Continue'} txtColor={colors.white}
                                    bgColor={colors.secondary_color}
                                    btnAdditionalStyle={{fontSize: '0.9rem', padding :`0.6rem 1rem`}}
                                />
                                {/*<input type={'submit'} value={'continue'} className={'btn btn-block'}*/}
                                {/*       style={{background:colors.secondary_color,color:colors.white}}*/}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default NewTransfer