import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { agentsDatatable } from "../../../config/datatableInit";
import { clearResetBot, getAgentsForAdmin, handleAgent, setAgentDetails, setAgentShowActivateStatusModal, setErrorMsg, setLoading, type } from "../../../config/redux/slice/adminSlice";
import { strText } from "../../../config/string";
import AdminWrapper from "../AdminWrapper";
import AgentDetailsModal from "./AgentDetailsModal";
import { pagesText } from "../../../config/string/english";
import { postRequest2 } from "../../../config/redux/request";
import { appConfig, endpointUrl, remoteUrl } from "../../../config/config";
import { useForm } from "react-hook-form";
import { respMsg } from "../../../config/response_messages";
import ConfirmationModal from "./ConfirmationModal";

const AdminAgentHome = () => {
    const {success_msg, loading, openConfirmationAgentModal, agentsManagement:{agentsData,showAgentDetails, newData}} = useSelector((state) => state.administrator)
    const dispatch = useDispatch()
    const {userData:{modules, permissions}, token, user_role, permissionRoles} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        clearErrors,
        formState: {errors},
        reset
    } = useForm({mode: 'onTouched'})

    useEffect(() => {
        agentsDatatable(agentsData,setUserDetails)
        getAllAgents()
    },[])

     const getAllAgents = async () => {

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.getAgents}`,
            {},
            dispatch,
            setLoading,
            getAgentsForAdmin,
            setErrorMsg)
    }

    const setUserDetails = (data, dataType) => {
        if (dataType === 'settings') {
            dispatch(setAgentShowActivateStatusModal({modal: true, details: data}))
        } else {
            dispatch(setAgentDetails({details: data, modal: true}))
        }

    }

    useEffect(()=>{
        if (newData) {
            agentsDatatable(agentsData,setUserDetails)
            dispatch(clearResetBot())
        }
        // if(success_msg == respMsg.change_customer_status){
        //     getAllAgents()
        // }
    }, [newData, success_msg])

    return (
        <AdminWrapper>
            <div  className={''}>
                {/**/}
                <div className={'card px-3'}>
                    <div className={'card-header'}>
                        <div className="row d-flex align-items-center">
                            <div className="col-6">
                                <h2 className={'tableHeader'}>{pagesText.agents}</h2>
                            </div>
                        </div>
                    </div>
                    <table id={'table_id_agents'} className={'table table-condensed'}>
                        <thead>
                        <tr>
                            {/* <th>Customer</th> */}
                            <th>{strText.first_name_label}</th>
                            <th>{strText.last_name_label}</th>
                            <th>{strText.phone_number_label}</th>
                            <th>{strText.email_label}</th>
                            <th>Approval Status</th>
                            <th>{strText.address_label}</th>
                            <th>Status</th>
                            <th data-orderable="false" style={{color:colors.secondary_color}}>Action</th>
                        </tr>
                        </thead>
                    </table>
                </div>
            </div>
            {showAgentDetails && <AgentDetailsModal backdrop={showAgentDetails}/>}
            {openConfirmationAgentModal && <ConfirmationModal backdrop={openConfirmationAgentModal}/>}
        </AdminWrapper>
    )
}
export default  AdminAgentHome