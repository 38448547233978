
const ProfileCardWrapper = ({children,cardTitle}) => {
    return <>
        <div className={'col-xl-12'} >
            <div className={'card py-5 px-4'} >
                <h4 className={'px-2 pt-2 pb-4'} style={{color:"#E88749",fontSize:'18px'}} > {cardTitle} </h4>

                <div className={'row'}>
                    {children}
                </div>
            </div>
        </div>
    </>
}

export default  ProfileCardWrapper