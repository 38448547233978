import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { colors } from "../../../../config/color";
import {
    setSetupModal
} from "../../../../config/redux/slice/customerSlice";

const WelcomeMessage = ({backdrop,setup}) => {
    const {colors, appName} = useSelector((state) => state.app)
    const dispatch = useDispatch()


    return (
        <>
            <div className="modal fade show"
                 id="basicModal"
                 tabIndex="-1" aria-hidden="true"
                 style={{
                     display: "block",
                     paddingRight: "17px"
                 }}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header" style={{border: "0px"}}>
                            <h3 className=" w-100 py-1" style={{color:colors.secondary_color}}> Welcome to {appName} Online banking service</h3>
                        </div>

                        <div className="modal-body">
                            <div className={'px-2 pb-3'}>
                                <div className={'mb-1'}>
                                   <p className={''} style={{fontSize:'14px',lineHeight:'28px'}}>
                                       {
                                           setup==='pin' ?
                                               `To continue, you have to setup a 4 digit transaction PIN.`
                                           : `To continue,
                                       you have to setup a Security Question and Answer.
                                       The Question should be something unique to you that cannot easily be guessed.
                                       E.g. What hospital were you born in?. You will need the security answer
                                       to reset your PIN if you forget it or the PIN to reset the security question and answer of you forget that.`
                                       }
                                       <Link to={'#'} onClick={()=>dispatch(setSetupModal({type:setup}))}> Click here </Link> to set up
                                   </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default  WelcomeMessage