import { urls } from '../urls/urls'

import {
	TermsAndCondition
} from '../../app/onboard'
import AdminVerifyEmail from '../../app/onboard/new_account/AdminVerifyEmail'
import CustomerVerifyEmailPage from '../../app/onboard/new_account/pages/CustomerVerifyEmailPage'
import {
	AgencyAccountOpeningNew,
	ForgotPasswordNew,
	InternetBankingNew,
	PasswordResetNew
} from "../../app/onboard_one"
import OpenNewAccountScreen from "../../app/v1/onboard/OpenNewAccountScreen"
import {
	CreateAccountScreen,
	CreatePasscode,
	LoginScreen,
	OtpResetPasscode,
	OtpScreen,
	PassCodeScreen,
	PasscodeResetScreen,
	PasscodeResetScreenTwo
} from "../../app/v2"

export const onboardRoutes = [
	{
		url: urls.login,
		component:<LoginScreen/>
	},
	{
		url: urls.passcode,
		component: <PassCodeScreen/>
	},
	{
		url: urls.passcode_reset,
		component: <PasscodeResetScreen/>
	},
	{
		url: urls.passcode_reset_new,
		component: <PasscodeResetScreenTwo/>
	},
	{
		url: urls.otp_passcode_reset,
		component: <OtpResetPasscode/>
	},
	{
		url: urls.create_account,
		component: <CreateAccountScreen/>
	},
	{
		url: urls.otp_screen,
		component: <OtpScreen/>
	},
		{
		url: urls.create_passcode,
		component: <CreatePasscode/>
	},

	// {
	//     url: urls.getStarted,
	//     component: <CustomerGetStartedScreen/>
	// },
	// {
	// 	url: urls.landingPage,
	// 	component: <LandingPageTwo />,
	// },
	// {
	// 	url: urls.landingPage,
	// 	component: <OpenANewAccountRequest/>
	// },
	{
		url: urls.landingPage,
		component: <OpenNewAccountScreen/>
	},
	// {
	// 	url: urls.createAccount,
	// 	component: <OpenAccountPage />,
	// },
	{
		url : urls.createAccount,
		component: <OpenNewAccountScreen/>
	},
	// {
	// 	url: urls.createAccount,
	// 	component: <OpenANewAccountRequest />,
	// },

	// {
	// 	url: urls.onlineAccountOpening,
	// 	component: <InternetBankingRequestPage />,
	// },
	{
		url: urls.onlineAccountOpening,
		component: <InternetBankingNew />,
	},
	// {
	// 	url: urls.login,
	// 	component: <CustomerLoginPage />,
	// },
	// {
	// 	url : urls.login,
	// 	component: <LoginNew/>
	// },

	// {
	// 	url: urls.agencyAccountOpening,
	// 	component: <AgencyNewRequestForm />,
	// },
	{
		url : urls.agencyAccountOpening,
		component: <AgencyAccountOpeningNew/>
	},

	// {
	// 	url: urls.forgotPassword,
	// 	component: <ForgotPasswordPage />,
	// },

	{
		url: urls.forgotPassword,
		component: <ForgotPasswordNew />,
	},




	//
	// {
	// 	url: urls.passwordReset,
	// 	component: <PasswordResetPage />,
	// },
	{
		url: urls.passwordReset,
		component: <PasswordResetNew />,
	},
	{
		url: urls.onboard_terms_and_condition,
		component: <TermsAndCondition />,
	},
	{
		url: urls.emailAdminEmailVerification,
		component: <AdminVerifyEmail />,
	},
	{
		url: urls.customerEmailVerification,
		component: <CustomerVerifyEmailPage />,
	},
]
