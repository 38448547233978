import parse from "html-react-parser";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appConfig, endpointUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import { getBankSettingsForCustomer, setCustomerErrorMsg, setLoading } from "../../../config/redux/slice/customerSlice";
import CustomerWrapper from "../CutomerWrapper";

const TermsAndCondition = () => {
    const {token} = useSelector((state) => state.authData)
    const {bankingSettings} = useSelector((state) => state.customer)
    const dispatch = useDispatch()


    useEffect(() => {
        getSettings()
    }, [])


    const getSettings = () => {
        const postData = {
            "jwt": token
        }
        let res = postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getBankInfo}`,
            postData,
            dispatch,
            setLoading,
            getBankSettingsForCustomer,
            setCustomerErrorMsg
        )
    }

    return <CustomerWrapper showNotificationIcon={false} title={'Terms and Conditions'}>

        <div className={'card p-5'}>
            {parse(bankingSettings?.terms_and_conditions?? '')}
        </div>
    </CustomerWrapper>
}

export  default  TermsAndCondition