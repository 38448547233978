import { useSelector } from "react-redux";
// import {colors} from "../../../config/color";

const OtpComponent = ({phone_no}) => {
    const {colors} = useSelector((state) => state.app)
    return (
        <>
            <div className="form-group">
                <label className={'ml-2'}>Enter the 6 digit code that was sent to your phone ({phone_no})</label>
                <div className="input-group mb-3">
                    <input
                        style={{
                            background:colors.background,
                            borderTopLeftRadius:'50px',
                            borderBottomLeftRadius:'50px'}}
                        type="text"
                        className="form-control"
                        placeholder="Enter 6-digits"
                        aria-label="Enter 6-digits"
                        aria-describedby="basic-addon2"
                    />

                    <div
                        className="input-group-append"
                        style={{background:colors.background,
                        borderTopRightRadius:'50px',
                        borderBottomRightRadius:'50px'
                    }}
                    >
                        <span
                            style={{
                                background:colors.background,
                                color:`${colors.primary_color}`,
                                fontWeight:'bold',
                                borderTopRightRadius:'50px',
                                borderBottomRightRadius:'50px'
                        }
                            }
                            className="input-group-text"
                            id="basic-addon2">
                            Resend OTP
                        </span>
                    </div>

                </div>
            </div>
        </>
    )
}

export default OtpComponent