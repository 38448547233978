import { createSlice } from '@reduxjs/toolkit';
import moment from "moment";
import { respMsg } from "../../response_messages";
import { strText } from "../../string";
import { pagesText } from "../../string/english";
import { decryptToken } from "../actions";

export const auth_types = {
    show_login_form: 'show_login_form',
    clear_all: 'clear_all',
    request_internet_banking: 'request_internet_banking',
    set_your_new_password: 'set_your_new_password',
    show_create_account_form: 'show_create_account_form',
    show_email_verification_form: 'show_email_verification_form',
    show_forgot_password_form: 'show_forgot_password_form',
    show_reset_password_form: 'show_reset_password_form',
    show_agent_acc_form: 'show_agent_acc_form',
}

const initialState = {
    modalSize: '',
    loading: false,
    token: '',
    bank_token: '',
    tenant_id: '',
    ak: '',
    user_role: '', // customer  //admin
    errorMsg: null,
    success_msg: null,
    landing_page_terms_and_condition : null,
    moduleRoles: {},
    permissionRoles: {},
    newRole: false,
    userData: {
        email: '',
        full_name: '',
        address: '',
        identification: '',
        phone_no: '',
        profile_image: '',
        account_number: '',
        account_numbers: [],
        current_tier: '',
        encryptKey: ''
    },
    userRegDetails:{
        phone: '',
        otp: '',
        type: 'guest',
        passcode: '',
    },
    ssoUserDetails:{},
    //page size state ::inteternet req
    authSize: {
        'one'  : 'col-sm-12 col-md-4',
        'two'  : 'col-sm-12 col-md-4',
        'three': 'col-md-7 col-lg-5 col-xl-4 col-sm-8 col-11 authLoginWrapper'
    },

    isEmailVerificationModal: false,
    isForgotPasswordModal: false,
    isResetPasswordModal: false,
    tempEmail: '',
    reLoginModal: false,
    tempInternetUserDetails: '',
    tempAccountNo: '',
    showPreviewAccInternetPreviewForm: false,
    //


    isLoginNormal: false,
    showLoginModal: false,
    showRequestForInternetBanking: false,
    showAgentAccountOpeningForm : false,
    showAccountCreateForm: false,
    //new customer that just register successfully
    showSetPasswordModal: false,
    showVerifyAccountModal: false,
    showSetTransactionPinModal: false,
    //user accessing the application via another subdomain
    showEnterTransactionPinModal: false,
    //


    loginTime: 0,

    //
    showBeneficiaryModal: false,
    showBankModal: false,
    hiddenState: true, //

    bank_accounts: [
        {
            account_name: 'Adedamola Alausa james',
            id: 1,
            balance: 14500,
            account_number: '0221848879',
            spent: 500
        },
        {
            account_name: 'Second Alausa 2',
            id: 2,
            balance: 177000,
            account_number: '0221848874',
            spent: 1500
        },
    ],
    account_beneficiaries: [
        {
            account_name: 'Yemi Salem',
            bank_id: 1,
            bank_name: 'First Bank',
            account_number: '1000400090'
        },
        {
            account_name: 'Kola Salem',
            bank_id: 1,
            bank_name: 'First Bank',
            account_number: '0023455613'
        },
        {
            account_name: 'Joh Doe',
            bank_id: 2,
            bank_name: 'FCMB',
            account_number: '300234556'
        },
    ],
    current_user_bank_account: {
        account_name: 'Adedamola Alausa',
        id: 1,
        balance: 14500,
        account_number: '0221848879',
        spent: 500
    },
    networks: [
        {id: 1, name: 'MTN'},
        {id: 2, name: 'GLO'},
        {id: 3, name: 'AIRTEL'},
        {id: 4, name: '9MOBILE'},
    ],
    types_of_transfers: [
        {id: 1, name: 'To other Banks'},
        {id: 2, name: 'Same Bank'},
    ],
    banks: [
        {id: 1, bank_name: 'First Bank'},
        {id: 2, bank_name: 'FCMB'},
        {id: 3, bank_name: 'Wema Bank'},
        {id: 4, bank_name: 'Access Bank Plc'},
        {id: 5, bank_name: 'Eco Bank'},
        {id: 6, bank_name: 'Fidelity Bank Plc'},
        {id: 7, bank_name: 'Zenith Bank'},
    ],
    utility_type: [
        {id: 1, name: 'Cable Tv'},
        {id: 2, name: 'Electricity'},
        {id: 3, name: 'Water'},
        {id: 3, name: 'Food'},
        {id: 4, name: 'Food'},
        {id: 4, name: 'Food'},
        {id: 4, name: 'Food'},
        {id: 4, name: 'Food'},
        {id: 4, name: 'Food'},
    ],
    payment_methods: [
        {id: 1, name: 'Electronic Method'}
    ],
    providers: [
        {id: 1, name: 'GoTv'},
        {id: 2, name: 'DSTV'},
    ],
    packages: [
        {id: 1, name: 'jumbo'}
    ]
}

export const authDataSlice = createSlice({
    name: 'authDataApp',
    initialState,
    reducers: {
        clearAll: (state, action) => {
            state.loading = false
            state.errorMsg = null
            state.success_msg = null
        },
        setLoading: (state, action) => {
            state.loading = action?.payload?.loading
        },
        logoutUser: (state) => {
            state.loading = false
            state.reLoginModal = false
            sessionStorage.removeItem(`${strText.user_unique_id}`)
            // sessionStorage.removeItem(`qapp_tenant_id`)
            state.token = ''
            state.bank_token = ''
            // state.tenant_id = ''
            state.isLoginNormal = true
            state.userData = {
                //exp,iat,iss
                email: '',
                full_name: '',
                address: '',
                identification: '',
                phone_no: '',
                user_name: '',
                profile_image: '',
                email_notification: '',
                sms_notification: '',
                current_tier: '',
                current_address_state: '',
                current_address_city: '',
                current_address_street: '',
                current_address_house_no: '',
                id_type: '',
                bvn: '',
                id_number: '',
                security_question: '',
                modules: [],
                encryptKey: '',
                permissions: []
            }
            state.user_role = ''
        },
        setErrorMessage: (state, action) => {
            let msg = action.payload.message
            state.loading = false
            state.errorMsg = msg === 'Request failed with status code 401'
                ?
                'Invalid Credentials'
                :
                msg === 'Request failed with status code 429'
                    ?
                    'Too many failed attempts, Kindly try again in the next 15 min'
                    :
                    msg
        },
        onOpeningAccount: (state, action) => {
            state.success_msg = pagesText.on_open_account_one
        },
        setTempEmail: (state, action) => {
            state.tempEmail = action.payload
        },
        onCustomerEmailVerificationRequest: (state, action) => {
            state.success_msg = action.payload.message
            state.errorMsg = ''
            state.authSize = {
                'one'  : 'col-sm-12 col-md-4',
                'two'  : 'col-sm-12 col-md-4',
                'three': 'col-sm-12 col-md-8 authLoginWrapperThree'
            }
        },
        setModulesPermissionsRoles: (state, action) => {
            state.moduleRoles = action.payload.data[0]
            state.permissionRoles = action.payload.data[1]
        },
        onEmailVerification: (state, action) => {
            state.success_msg = action.payload.message
            state.authSize = {
                'one'  : 'col-sm-12 col-md-4',
                'two'  : 'col-sm-12 col-md-4',
                'three': 'col-sm-12 col-md-4 authLoginWrapperThree'
            }
        },
        onCustomerLogin2: (state, action) =>  {
                    state.loading = false
                    state.userRegDetails = {...state.userRegDetails, ...action.payload.data}       
        },
        onUpdateCustomerDetails: (state, action) => {
            state.userData = {...state.userData, ...action.payload}
        },
        onGetTenantId: (state, action) => {
            sessionStorage.setItem(`qapp_tenant_id`, action.payload.id);
            state.tenant_id = action.payload.id
        },
        onGetAppKey: (state, action) => {
            sessionStorage.setItem(`qapp_ak`, action.payload.id);
            state.ak = action.payload.id
        },
        onCustomerLogin: (state, action) => {
            state.loading = false
            let temp_token = action.payload.token
            sessionStorage.setItem(`${strText.user_unique_id}`, temp_token);
            state.token = temp_token
            state.bank_token = action.payload.bank_token
            state.isLoginNormal = true
            let user = decryptToken(temp_token)
            //
            let userDetails = action.payload
            state.userData = {
                email: userDetails?.user_data?.customer_email,
                full_name: `${userDetails?.first_name ?? ''} ${action?.payload?.last_name ?? ''}`,
                address: userDetails?.user_data?.customer_address,
                identification: userDetails?.user_data?.id_type,
                phone_no: userDetails?.user_data?.customer_phone,
                user_name: '',
                profile_image: '/assets/images/profile/2.jpg',
                account_numbers : [''],
                account_number: userDetails?.account_number,
                current_tier: userDetails?.current_tier,
                tier_status: userDetails?.user_data?.tier_status,
                bvn: userDetails?.bvn ? "*******" + ((userDetails?.bvn).substring(userDetails?.bvn?.length - 4)) : '',
                email_notification: Number(userDetails?.user_data?.email_notification) === 1,
                sms_notification: Number(userDetails?.user_data?.sms_notification) === 1,
                last_login : moment(userDetails?.last_login).format('YYYY-MM-DD hh:mm:ss'),
                id_type: userDetails?.user_data?.id_type,
                id_number: userDetails?.user_data?.id_number,
                current_address_state: userDetails?.user_data?.current_address_state,
                current_address_city: userDetails?.user_data?.current_address_city,
                current_address_street: userDetails?.user_data?.current_address_street,
                current_address_house_no: userDetails?.user_data?.current_address_house_no,
                security_question: userDetails?.user_data?.security_question,
                modules: [...userDetails?.modules],
                permissions: [...userDetails?.permissions],
                user_role: userDetails.user_role,
                encryptKey: userDetails?.encryptKey
            }
            //2:initiator,3:approval,4:admin,5:Super Admin
            state.user_role = (userDetails.user_role_text).toLowerCase()
            state.success_msg = action.payload.message
        },
        setUserRole: (state, action) => {
            state.user_role = (action.payload.name).toLowerCase()
        },
        onForgotPassword: (state, action) => {
            state.success_msg = action.payload.message
        },
        onPasswordReset: (state, action) => {
            state.success_msg = action.payload.message
        },
        setReLoginModalForm: (state, action) => {
            state.reLoginModal = action.payload.modal
            state.success_msg = action.payload.success_message
            state.errorMsg = action.payload.error_message
        },
        setAuthModalForm: (state, action) => {
            //showPreviewAccInternetPreviewForm
            let modalState = action.payload.modal
            state.success_msg = ''
            state.errorMsg = ''
            switch (action.payload.type) {
                case auth_types.clear_all:
                    state.showRequestForInternetBanking = false
                    state.showSetPasswordModal = false
                    state.showLoginModal = false
                    state.showAccountCreateForm = false
                    state.isEmailVerificationModal = false
                    state.isForgotPasswordModal = false
                    state.isResetPasswordModal = false
                    state.showAgentAccountOpeningForm = false
                    state.tempInternetUserDetails = ''
                    state.tempAccountNo = ''
                    break
                case auth_types.show_login_form:
                    state.showLoginModal = action.payload?.modal
                    state.showRequestForInternetBanking = false
                    state.showSetPasswordModal = false
                    state.showAccountCreateForm = false
                    state.isResetPasswordModal = false
                    state.showAgentAccountOpeningForm = false
                    break
                case auth_types.request_internet_banking:
                    state.tempAccountNo = ''
                    state.tempInternetUserDetails = ''
                    state.showPreviewAccInternetPreviewForm = false
                    state.showAgentAccountOpeningForm = false
                    state.showRequestForInternetBanking = modalState
                    state.modalSize = ''
                    break
                case auth_types.show_create_account_form:
                    state.showRequestForInternetBanking = false
                    state.showSetPasswordModal = false
                    state.showLoginModal = false
                    state.showAccountCreateForm = modalState
                    state.showAgentAccountOpeningForm = false
                    if (action.payload.clearMsg) {
                        state.errorMsg = ''
                        state.success_msg = ''
                    }
                    break
                case auth_types.show_email_verification_form:
                    state.showRequestForInternetBanking = false
                    state.showSetPasswordModal = false
                    state.showLoginModal = false
                    state.showAccountCreateForm = false
                    state.showAgentAccountOpeningForm = false
                    state.isEmailVerificationModal = modalState
                    if (action.payload.clearMsg) {
                        state.errorMsg = ''
                        state.success_msg = ''
                    }
                    break
                case auth_types.show_forgot_password_form:
                    state.showRequestForInternetBanking = false
                    state.showSetPasswordModal = false
                    state.showLoginModal = false
                    state.showAccountCreateForm = false
                    state.isEmailVerificationModal = false
                    state.showAgentAccountOpeningForm = false
                    state.isForgotPasswordModal = modalState
                    break
                case auth_types.show_reset_password_form:
                    state.showRequestForInternetBanking = false
                    state.showSetPasswordModal = false
                    state.showLoginModal = false
                    state.showAccountCreateForm = false
                    state.isEmailVerificationModal = false
                    state.isForgotPasswordModal = false
                    state.showAgentAccountOpeningForm = false
                    state.isResetPasswordModal = modalState
                    break
                case auth_types.show_agent_acc_form:
                    state.showRequestForInternetBanking = false
                    state.showSetPasswordModal = false
                    state.showLoginModal = false
                    state.showAccountCreateForm = false
                    state.isEmailVerificationModal = false
                    state.isForgotPasswordModal = false
                    state.isResetPasswordModal = false
                    state.showAgentAccountOpeningForm = modalState
                    state.showPreviewAccInternetPreviewForm = false
                    break
                //

                case auth_types.set_your_new_password:
                    state.showSetPasswordModal = modalState
                    break

            }
        },
        setInternetBanking: (state, action) => {
            state.tempInternetUserDetails = action?.payload[0]
            state.showPreviewAccInternetPreviewForm = true
            state.modalSize = 'modal-lg'
            //default value ==
            //col-md-8
            //authLoginWrapperTwo
           state.authSize = {
                'one'  : 'col-sm-12 col-md-4',
                'two'  : 'col-sm-12 col-md-4',
                'three': 'col-sm-12 col-md-8 authLoginWrapperTwo'
            }
        },
        setTemAccountNo: (state, action) => {
            state.tempAccountNo = action.payload.account_no
        },
        onSubmitInternetBankReq: (state, action) => {
            state.success_msg = action.payload.message
            state.modalSize = 'modal-lg'
            state.authSize = {
                'one'  : 'col-sm-12 col-md-4',
                'two'  : 'col-sm-12 col-md-4',
                'three': 'col-sm-12 col-md-8 authLoginWrapperTwo'
            }
        },
        changeModalSize: (state,action) => {
            state.modalSize = action?.payload?.type
            state.authSize = {
                'one'  : 'col-sm-12 col-md-4',
                'two'  : 'col-sm-12 col-md-4',
                'three': 'col-sm-12 col-md-4 authLoginWrapper'
            }
        },

        onLinkInternetBanking: (state, action) => {
            state.success_msg = respMsg.link_internet_banking
        },
        onLinkAccountOpening: (state, action) => {
            state.success_msg = action.payload.message
        },
        onAdminLinkAccount : (state,action) => {
            state.success_msg = action.payload.message
        },



        //end of used method
        setActivityTiming: (state, action) => {
            let newD = new Date();
            state.loginTime = newD.getTime()
        },
        changeHiddenDataState: (state) => {
            state.hiddenState = !state.hiddenState
        },
        handleSearchBeneficiaries: (state) => {
            state.showBeneficiaryModal = !state.showBeneficiaryModal
        },
        handleSearchBanks: (state) => {
            state.showBankModal = !state.showBankModal
        },
        switchActiveBankAccount: (state, action) => {
            state.userData.account_number = action.payload.data
        },
        onGetLandingPageTermAndCondition : (state,action) => {
            state.landing_page_terms_and_condition = action.payload

        }
    },
})

// Action creators are generated for each case reducer function
export const {
    onGetLandingPageTermAndCondition,
    onOpeningAccount,
    logoutUser,
    setTempEmail,
    onForgotPassword,
    setUserRole,
    onPasswordReset,
    onCustomerEmailVerificationRequest,
    onEmailVerification,
    setModulesPermissionsRoles,
    setReLoginModalForm,
    setInternetBanking,
    setTemAccountNo,
    onSubmitInternetBankReq,
    changeModalSize,
    onLinkInternetBanking,
    onLinkAccountOpening,
    onAdminLinkAccount,

    //
    setErrorMessage,
    setLoading,
    changeHiddenDataState,
    handleSearchBeneficiaries,
    handleSearchBanks,
    switchActiveBankAccount,
    onUpdateCustomerDetails,
    onCustomerLogin2,
    onCustomerLogin,
    onGetTenantId,
    onGetAppKey,
    setAuthModalForm,
    clearAll,
    setActivityTiming
} = authDataSlice.actions

export default authDataSlice.reducer