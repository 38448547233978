import CryptoJS from "crypto-js";

export function getUserRoles (moduleRoles, permissionRoles, data){
// Iterate through the data array
data.forEach(item => {
  const role = item.role_name.toLowerCase();
  const modules = item.modules ? item.modules.split(',') : [];
  const permissions = item._permissions ? item._permissions.split(',') : [];

  // Iterate through modules for each user
  modules.forEach(singleModule => {
    if (!moduleRoles[singleModule]) {
      moduleRoles[singleModule] = []; // Initialize an empty array if module doesn't exist
    }
    moduleRoles[singleModule].push(role);
  });

    permissions.forEach(permission => {
    if (!permissionRoles[permission]) {
      permissionRoles[permission] = []; // Initialize an empty array if module doesn't exist
    }
    permissionRoles[permission].push(role);
  });
});

return [moduleRoles, permissionRoles]
}

export function  decryptAesCbc(encryptedData, secretKey) {
		try {
			const ciphertext = CryptoJS.enc.Base64.parse(encryptedData);
			const key = CryptoJS.enc.Utf8.parse(secretKey);
			const iv = CryptoJS.enc.Utf8.parse('0000000000000000');
			// Decrypt the data using AES
			const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, { iv: iv, mode: CryptoJS.mode.CBC });
			// Convert the WordArray to a UTF-8 string
			return decrypted.toString(CryptoJS.enc.Utf8);
		} catch (error) {
			console.error('Decryption error:', error.message);
			throw new Error('Decryption failed');
		}
	}

	export function encryptAesCbc(data, secretKey) {
		try {
			// Convert the data to a WordArray (CryptoJS format)
			const dataWordArray = CryptoJS.enc.Utf8.parse(data);

			// Create a key and IV from the secretKey (assumes secretKey is a UTF-8 string)
			const key = CryptoJS.enc.Utf8.parse(secretKey);
			const iv = CryptoJS.enc.Utf8.parse('0000000000000000'); // Use the first 16 characters as the IV

			// Encrypt the data using AES
			const encrypted = CryptoJS.AES.encrypt(dataWordArray, key, { iv: iv, mode: CryptoJS.mode.CBC });

			// Return the base64-encoded string representation of the ciphertext
			return encrypted.toString();
		} catch (error) {
			console.error('Encryption error:', error.message);
			// Handle the error as needed, for example, throw a custom error
			throw new Error('Encryption failed');
		}
	}

export function getModulesData (modulesArray){
const moduleObject = {};

// Iterate through the module names and create an object with empty arrays
modulesArray.forEach(singleModule => {
  moduleObject[singleModule] = [];
});
return moduleObject
}

export function getPermissionsData (permissionsArray){
const permissionObject = {};

// Iterate through the module names and create an object with empty arrays
permissionsArray.forEach(permission => {
  permissionObject[permission] = [];
});
return permissionObject
}


