
import { useDispatch } from "react-redux";

const  GeneralModalWrapper = ({children,showModal,closeModalFunc,modal_title}) => {
    const dispatch = useDispatch()


    return <>
        <div className="modal fade show"
             id="basicModal"
             tabIndex="-1" aria-hidden="true"
             style={{
                 display: "block",
                 paddingRight: "17px"
             }}
        >
            <div className="modal-dialog">
                <div className="modal-content" style={{maxHeight: '90vh', overflowY: 'auto',marginLeft:'-0%'}}>
                    <div className="modal-header" style={{border: "0px"}}>
                        <h3>{modal_title}</h3>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => closeModalFunc() }
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">


                        {children}
                    </div>
                </div>
            </div>
        </div>
        {showModal && <div className="modal-backdrop fade show"></div>}
    </>
}


export default  GeneralModalWrapper