

import { useDispatch, useSelector } from "react-redux";
import { clearMsg, resetTopUpFormData } from "../../../../config/redux/slice/customerSlice";
import CustomerWrapper from "../../CutomerWrapper";
import EmptyState from "../../EmptyState";

const UtilitiesHome = () => {
    const {
        generalModal,
        successModal,
        formResetFunc,
    } = useSelector((state) => state.customer)


    const dispatch = useDispatch()



    const onSuccessModalClose = () => {
        formResetFunc()
        dispatch(resetTopUpFormData())
        dispatch(clearMsg())
    }

    return (
        <CustomerWrapper title={'Utility'}>
            <div className={'row'}>
                <EmptyState/>
            </div>
            {/*
            */}
            <div className="row">
                <div className={'row'}>
                    {/*{successModal.show &&*/}
                    {/*    <SuccessAlertModal*/}
                    {/*        imgUrl={'../assets/images/success.gif'}*/}
                    {/*        backdrop={successModal.backdrop}*/}
                    {/*        msg={successModal.msg}*/}
                    {/*        onClose={onSuccessModalClose}*/}
                    {/*    />*/}
                    {/*}*/}

                    {/*<Cable/>*/}
                    {/*<Electricity/>*/}

                    {/*<UtilityForm/>*/}
                    {/*{generalModal.show && <ConfirmUtilityForm msg={generalModal.msg} backdrop={generalModal.backdrop}/>}*/}
                </div>
            </div>
        </CustomerWrapper>
    )
}
export default  UtilitiesHome