import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { colors } from "../../../config/color";
import { appConfig, remoteUrl } from "../../../config/config";
import { initCustomerDashboardTransaction } from "../../../config/datatableInit";
import { NumberFormatter } from "../../../config/redux/actions";
import { postRequest2 } from "../../../config/redux/request";
import { changeHiddenDataState } from "../../../config/redux/slice/authData";
import {
    clearMessages,
    clearTransactionHistoryDetailsData,
    onGetCustomerDashboard,
    onGetTransactionData, resetCustomerTransactionHistory,
    resetDataTableBot,
    setCustomerErrorMsg,
    setGeneralModal,
    setLoading,
    setSetupModal,
    setSingleTransactionHistoryDetails
} from "../../../config/redux/slice/customerSlice";
import { respMsg } from "../../../config/response_messages";
import { urls } from "../../../config/urls/urls";
import { TransactionDetailsItemModal } from "../../_shared_component";
import CustomerWrapper from "../CutomerWrapper";
import UserAccountListing from "../UserAccountListing";
import { DashboardCardSummary, QuickAccessWrapper } from "./components";


const CustomerDashboard = () => {
    const {
        hiddenState,
        current_user_bank_account,
        bank_accounts,
        showSetPasswordModal,
        showVerifyAccountModal,
        bank_token,
        token,
        tenant_id,
        userData
    } = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)

    const {transaction_histories, generalModal, transactionHistoryDetail,customerDashData,newTransactionHistBot,showAccountListing,error_msg,success_msg} = useSelector((state) => state.customer)
    const dispatch = useDispatch()
    const graphInit = {
        series: [55, 50], //#DC362E debit, #12D778 credit\
        options: {
            chart: {type: 'donut', height: 200, width: 30},
            legend: {show: !1},
            plotOptions: {donut: {columnWidth: '50%'}},
            labels: ['Debit', 'Credit'],
            colors: ['#DC362E', '#12D778'],
            stroke: {colors: ["transparent"]},
            responsive: [{
                breakpoint: 40,
                options: {
                    chart: {
                        width: 5,
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
    }

    const mainText = {
        color: colors.secondary_color,
        fontSize: '25px',
        fontWeight: '700',
        padding: '9px 0px 0px 0px'
    }
    const subText = {
        color: colors.primary_color,
        fontSize: '22px',
        fontWeight: '550',
        padding: '0px 0px 0px 0px'
    }
    const cardBg = {
        background: "linear-gradient(90deg, #F46B45 0%, #EEA849 100%)",
        color: colors.white
    }

    useEffect(() => {
        dispatch(clearMessages())
        initCustomerDashboardTransaction(transaction_histories,onClickFunc,'all',userData.account_number)
        getCustomerDashData()
        getTransactionHistory()
    }, [])

    // getDashboardData
    const getCustomerDashData = async () => {
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.customerDash}`,
            {
                "bank_token": bank_token,
                "jwt": token,
                "account_number": userData.account_number,
                "email": userData.email,
                "limit": 4,
                "offset": 0,
            },
            dispatch,
            setLoading,
            onGetCustomerDashboard,
            onGetCustomerDashboard
        )
    }

    //handles the hidden and revealing of account details
    const onChangeState = () => {
        dispatch(changeHiddenDataState())
    }

    const onClickFunc = (data) => {
        dispatch(setGeneralModal({
            show: true,
            msg: '',
            backdrop: true
        }))
        dispatch(setSingleTransactionHistoryDetails(data))
    }

    const onClose = () => {
        dispatch(clearTransactionHistoryDetailsData())
    }

    const getTransactionHistory = async () => {
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.transactionHistory}`,
            {
                "bank_token": bank_token,
                "jwt": token,
                "accountno" : userData.account_number,
            },
            dispatch,
            setLoading,
            onGetTransactionData,
            setCustomerErrorMsg
        )
    }

    useEffect(()=>{
        if(newTransactionHistBot){
            initCustomerDashboardTransaction(transaction_histories,onClickFunc,5,userData.account_number)
            dispatch(resetDataTableBot())
        }
    }, [newTransactionHistBot])

    useEffect(()=>{
        if(error_msg==='not found'){
            dispatch(resetCustomerTransactionHistory())
        }
    }, [error_msg])

    useEffect(()=>{
        if(success_msg===respMsg.create_pin_s|| success_msg===respMsg.create_security_question_s|| success_msg===respMsg.create_email){
            getCustomerDashData()
            getTransactionHistory()
        }
    }, [success_msg])


    // mainAppStyle={'col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-5'}
    return(<CustomerWrapper title={''} showNormalBreadcrumb={false} showNotificationIcon={false}>
        {generalModal?.show && <TransactionDetailsItemModal onClose={onClose} show={generalModal?.show}
                                                            transactionDetails={transactionHistoryDetail}/>}
        <div className="row mt-2">
            <DashboardCardSummary
                sub_text={ NumberFormatter(customerDashData?.available_balance?.toFixed(2)??0.00,2)}
                lead_text={'Available Balance'}
                icon={'/_assets/new/wallet.png'}
                icon_bg={colors.secondary_color}
                icon_type={'image'}
                card_key={0}
                fontSize = {'16px'}
                canHide={true}
                isBold={true}
            />
            <DashboardCardSummary
                sub_text={NumberFormatter(customerDashData?.book_balance?.toFixed(2) ?? 0.00,2)}
                lead_text={'Book Balance'}
                icon={'/_assets/new/protected.png'}
                icon_bg={colors.primary_color}
                icon_type={'image'}
                card_key={1}
                canHide={true}
                fontSize = {'16px'}
                isBold={true}
            />

            <DashboardCardSummary
                sub_text={userData?.last_login}
                lead_text={'Last Login'}
                icon={'/_assets/new/login-.png'}
                icon_bg={'#EC7272'}
                icon_type={'image'}
                card_key={2}
                fontSize = {'13px'}
                isBold={false}
            />

            <DashboardCardSummary
                sub_text={''}
                lead_text={'Help & Support'}
                icon={'/_assets/new/customer-service.png'}
                icon_bg={'#219ebc'}
                icon_type={'image'}
                linkRef={urls.help_support(tenant_id, userData?.phone_no)}
                isInApp={false}
                card_key={3}
                fontSize = {'16px'}
                isBold={true}
            />
        {/* Head phone clickable --> account manager */}
        </div>
        {/*transaction_histories*/}
        {/*<TransactionWrapper title={'Recent Transaction(s)'} data={transaction_histories} onClickFunc={onClickFunc}/>*/}


            <div className={'card py-4 px-2'}>
                <div className={'card-header'}>
                    <div className="d-flex align-items-center">
                        <div>
                            <h4 className="card-title" style={{color:colors.secondary_color}}><strong>Transaction History</strong></h4>
                        </div>

                        {showAccountListing && <UserAccountListing getDataFunc={getCustomerDashData} getDataFunc2={getTransactionHistory}/> }

                        <div className={'ml-auto'}>

                            <Link className={'btn btn-sm pull-right ml-2'}
                                  style={{background:colors.white,color:colors.secondary_color,border:`1px solid ${colors.secondary_color}`}}
                                  to={urls.transactionHistory} > View all transactions </Link>
                        </div>


                    </div>
                </div>

                <div className={'card-body'}>
                    <table className={'table table-striped'} id={'transaction'}>
                        <thead>
                        <tr>
                            {/* <th data-orderable="false" style={{color:colors.secondary_color}} width={'7%'}> </th> */}
                            <th style={{color:colors.secondary_color,width:'15%'}}> Trans. Date </th>
                            <th style={{color:colors.secondary_color,width:'15%'}}> Value Date </th>
                            <th style={{color:colors.secondary_color}}> Reference </th>
                            <th style={{color:colors.secondary_color}}>Debit</th>
                            <th style={{color:colors.secondary_color}}> Credit </th>
                            <th style={{color:colors.secondary_color}}>Charge </th>
                            <th width={'9%'} data-orderable="false" style={{color:colors.secondary_color}}> Action </th>
                        </tr>
                        </thead>
                        <tbody></tbody>
                    </table>
                </div>

                <div className={'card-footer'}>

                </div>

            </div>


        <div className={'row'}>
            <QuickAccessWrapper
                title={'Transfer'}
                icon={'/_assets/new/credit-cards.png'}
                link={urls.transferHome}
                bg={colors.secondary_color}
                icon_type={"image"}
            />

            <QuickAccessWrapper
                title={'Top Up'}
                icon={'/_assets/new/smartphone.png'}

                link={urls.topUpHome}
                bg={colors.primary_color}
                icon_type={"image"}
            />

            <QuickAccessWrapper
                title={'Cards'}
                icon={'/_assets/new/credit-cards.png'}
                // link={'#'}
                link={urls.cardsHome}
                bg={'#ffb703'}
                icon_type={"image"}
            />

            <QuickAccessWrapper
                title={'Pay  for Utility'}
                icon={'/_assets/new/google.png'}
                // link={'#'}
                link={urls.utilityHome}
                bg={'#023047'}
                icon_type={"image"}
            />
            <QuickAccessWrapper
                title={'Loan'}
                // icon={'flaticon-agenda'}
                // link={'#'}
                link={urls.loansHome}
                icon={'/_assets/new/loan.png'}
                bg={'#8ecae6'}
                icon_type={"image"}
            />

            <QuickAccessWrapper
                title={'Deposit'}
                icon={'/_assets/new/deposit.png'}
                // link={'#'}
                link={urls.tokenizedDeposit}
                bg={'#219ebc'}
                icon_type={"image"}
            />
        </div>
    </CustomerWrapper>
    )
}

export default CustomerDashboard