import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Loader } from "../../../components";
import { ButtonField, PasswordField, TextInputField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, remoteUrl } from "../../../config/config";
import { formatMessages } from "../../../config/redux/actions";
import { postRequest, postRequest2 } from "../../../config/redux/request";
import {
    auth_types,
    clearAll, onAdminLinkAccount, onCustomerEmailVerificationRequest,
    setAuthModalForm,
    setErrorMessage,
    setLoading,
    setTempEmail
} from "../../../config/redux/slice/authData";
import { urls } from "../../../config/urls/urls";
import AlertWrapper from "../../_shared_component/AlertWrapper";
import { AuthPageWrapperTwo } from "../new_account/pages";

const AdminAccountActivation = () => {
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {loading,errorMsg,success_msg,user_role,token,tempEmail} = useSelector((state) => state.authData)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [isEmailVerified, setEmailVerified] = useState(false)
    const dispatch = useDispatch()
    //Admin Login
    const onSubmit = (data) => {
        dispatch(clearAll({}))
        dispatch(setTempEmail(data.email))
        let res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.adminLinkAccount}`,
            data,
            dispatch,
            setLoading,
            onAdminLinkAccount,
            setErrorMessage
        )

    }

    //
    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }

    if (success_msg !== "" && isEmailVerified) {
        return <Navigate to={urls.emailAdminEmailVerification}/>
    }
    //
    if (token!=='' && user_role === 'admin') {
        dispatch(setAuthModalForm({type:auth_types.show_login_form,modal:false}))
        return <Navigate to={urls.adminDashboard}/>
    }
    return <AuthPageWrapperTwo classTitle={'admin_login'}>
        <div className={`${authSize.three}`}>
            <div className="card shadow-lg bg-white pb-3 py-4 px-3 " style={{border:`0.05rem solid ${colors.primary_color}`}}>

            <form onSubmit={handleSubmit(onSubmit)}>

            <h3 className={'mb-3'} style={{color:colors.primary_color}}>
                <b>Admin Account Activation </b>
            </h3>

            {success_msg && <AlertWrapper type={'success'}>{formatMessages(success_msg)} <Link to={urls.landingPage}> Click here </Link>  to close window</AlertWrapper> }
            { errorMsg === 'verify email first' ?
                <AlertWrapper type={'danger'}>
                    <div className="">
                        Your email needs to be verified before you can use access admin dashboard,
                        <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}> Click here </Link>  to verify
                    </div>
                </AlertWrapper>
                :  errorMsg && <AlertWrapper type={'error'}>{errorMsg}</AlertWrapper>
            }

            <div className="login-form">
                <TextInputField
                    placeholder={''}
                    patternRules={'email'}
                    defaultVal={''}
                    error_msg={errors.email?.message}
                    isReq={true}
                    fieldName={'email'}
                    label={'Email'}
                    register={register}
                />

                <div className={'mt-2'}>
                    <PasswordField
                        register={register}
                        label={'Password'}
                        fieldName={'password'}
                        isReq={true}
                        error_msg={errors.password?.message}
                        defaultVal={''}
                        placeholder={''}
                        // inputStyles={} col={}
                    />
                    {/*<Link*/}

                    {/*    className={'pull-right  mb-4'}*/}
                    {/*    style={{color: colors.primary_color, fontSize: '12px'}}*/}
                    {/*    to={urls.adminForgotPassword}>*/}
                    {/*    Forgot*/}
                    {/*    Password?</Link>*/}
                </div>





                {/*<div className="row form-sub m-0">*/}
                {/*    <div className="custom-control custom-checkbox">*/}
                {/*        <input type="checkbox" className="custom-control-input" id="rememberme" />*/}
                {/*        <label className="custom-control-label" htmlFor="rememberme">Remember Me</label>*/}
                {/*    </div>*/}
                {/*    <a href="#" className="link float-right">Forget Password ?</a>*/}
                {/*</div>*/}

                <div className="form-action mb-3">
                    {loading ? <Loader/> :
                        <ButtonField
                            // btnStyle={}
                            btnTxt={'Activate Account'}
                            btnWidth={'100'}
                            bgColor={colors.primary_color}
                            txtColor={colors.white}
                            id={'d'}
                            // btnAdditionalStyle={}
                        />
                    }
                </div>

                <div className="text-center">
                    <Link to={urls.adminLogin}  style={{color:colors.primary_color}}>
                        Admin Login
                    </Link>
                </div>
            </div>
        </form>

            </div>
        </div>
    </AuthPageWrapperTwo>
}

export  default  AdminAccountActivation
