import {
    onRestUserPassword,
    onRestUserPin,
    onUserStatusChange,
    setCustomerShowActivateStatusModal,
    setErrorMsg, setLoading, setOpenConfirmationModal,
    onRefreshCustomers
} from "../../../config/redux/slice/adminSlice";

// import {colors} from "../../../config/color";
import {ButtonField, PasswordField} from "../../../components/form/components";
import {useDispatch, useSelector} from "react-redux";
import {postRequest, postRequest2} from "../../../config/redux/request";
import {appConfig, remoteUrl} from "../../../config/config";

import {useForm} from "react-hook-form";
import {Loader} from "../../../components";
import AdminSuccessAlert from "../_component/AdminSuccessAlert";
import {Link} from "react-router-dom";
import {useState} from "react";
import formStyles from "../../../assets/css/formStyle.module.css";
import {pagesText} from "../../../config/string/english";
import {formatMessages} from "../../../config/redux/actions";
import { useEffect } from "react";

const actionTextArray = ['Change Status', 'Reset Password', 'Reset Pin', 'Edit User']
const actionArray = ['status', 'password', 'pin', '']

const ActivateDeactivateUserModal = ({backdrop}) => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {customerManagement: {customerDetails}, loading} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const {userData:{modules, permissions}, token, user_role, permissionRoles} = useSelector((state) => state.authData)
    const dispatch = useDispatch()
    const [action, setAction] = useState('')
    const [actionText, setActionText] = useState('Edit User')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const onHandleAction = (str) => {
        setAction(actionArray[str])
        setActionText(actionTextArray[str])

    }

    const updateUserStatus = async (data) => {
        //,
        const postData = {
            "jwt": token,
            "phone": customerDetails.phone,
            "status": data.status //active,inactive
        }
        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.changeUserStatus}`,
            postData,
            dispatch,
            setLoading,
            onUserStatusChange,
            setErrorMsg
        )
      if(res != undefined && res.status < 400){
        dispatch(onRefreshCustomers())
      }        
    }
    //
    const resetPassword = async (data) => {
        const postData = {
            'passcode': data.passcode,
            'jwt': token,
            "phone": customerDetails.phone
        }

        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.adminResetUserPassword}`,
            postData,
            dispatch,
            setLoading,
            onRestUserPassword,
            setErrorMsg
        )
      if(res != undefined && res.status < 400){
        dispatch(onRefreshCustomers())
      }         
    }
    //
    const resetPin = async (data) => {
        const postData = {
            'pin': data.pin,
            'jwt': token,
            "phone": customerDetails.phone
        }
        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.adminResetUserPin}`,
            postData,
            dispatch,
            setLoading,
            onRestUserPin,
            setErrorMsg
        )
      if(res != undefined && res.status < 400){
        dispatch(onRefreshCustomers())
      }         
    }


    return (
        <>
            <div  className="modal fade show" id="basicModal" tabIndex="-1"
                 style={{
                    overflowY: 'auto',
                     display: "block",
                     paddingRight: "17px"
                 }} aria-hidden="true">
                <div style={{overflowY: 'auto'}} className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header" style={{border: "0px"}}>
                            <h3 className="modal-title"
                                style={{color: colors.secondary_color}}>
                                     {/* {actionText} |  */}
                                Settings | {customerDetails.firstname} {customerDetails.lastname}</h3>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => dispatch(setCustomerShowActivateStatusModal({
                                    modal: false,
                                    details: ''
                                }))}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div style={{overflowY: 'auto'}} className="modal-body px-5 pt-3 pb-4">
                            {action === '' &&
                            <>
                                <table className={'table table-striped'}>
                                    <thead></thead>
                                    <tbody>                                                                                                                                                                                                                                                          
                                    { 
                                    permissions?.includes("CanEditUserDetails") 
                                    ?
                                        <>
                                            <tr>
                                                <td>Status</td>
                                                <td><Link className={'btn p-0'} style={{color: colors.secondary_color}}
                                                          onClick={() => onHandleAction(0)} to={'#'}>Change Status</Link></td>
                                            </tr>
                                            <tr>
                                                <td>PIN</td>
                                                <td><Link className={'btn p-0'} style={{color: colors.secondary_color}}
                                                          onClick={() => onHandleAction(2)} to={'#'}>Change PIN</Link></td>
                                            </tr>
                                        </>
                                        :
                                        ''
                                    }

                                    </tbody>
                                </table>                             
                            </>    
                            }

                            {/*other forms start her*/}

                            {
                                 permissions?.includes("CanEditUserDetails") 
                                    ?
                                    <>
                                        {
                                            action === 'status' ?
                                                <>
                                                    <h4 id="emailHelp2" className="form-text text-muted ml-3 mb-4"
                                                           style={{color: colors.secondary_color}}>
                                                        Change User Status
                                                    </h4>
                                                    <form onSubmit={handleSubmit(updateUserStatus)}>
                                                        <div className={'form-group'}>
                                                            <select className={'form-control'} {...register('status')}
                                                                    defaultValue={customerDetails.status}>
                                                                <option value={'active'}>Active</option>
                                                                <option value={'inactive'}>Inactive</option>
                                                            </select>
                                                        </div>
                                                        <div className={'form-group mt-4'}>
                                                            {loading ? <Loader/> :
                                                                <div className={'d-flex'}>
                                                                    <ButtonField
                                                                        id={''}
                                                                        // btnStyle={}
                                                                        btnTxt={'Update Status'}
                                                                        btnWidth={'30'}
                                                                        btnAdditionalStyle={{padding: '0.5rem 1.5rem', width: '134px'}}
                                                                        bgColor={colors.secondary_color}
                                                                        txtColor={colors.white}/>
                                                                    <Link to={'#'} onClick={() => onHandleAction(3)}
                                                                          className={'btn w-30 ml-2'} style={{
                                                                        color: colors.secondary_color,
                                                                        borderColor: colors.secondary_color,
                                                                        borderRadius: '50px',
                                                                        width: '134px'
                                                                    }}>Go Back</Link>
                                                                </div>
                                                            }
                                                        </div>
                                                    </form>
                                                </>
                                                :
                                                action === 'password' ?
                                                    <>
                                                        <form onSubmit={handleSubmit(resetPassword)}>
                                                            <h4 id="emailHelp2"
                                                                   className="form-text text-muted ml-3 mb-3"
                                                                   style={{color: colors.secondary_color}}>
                                                                Change User Passcode
                                                            </h4>
                                                            <div className={'col-md-12 mb-3'}>
                                                                <div className="form-group">
                                                                    <label>Passcode*</label>
                                                                    <div className="input-group pr-3" style={{
                                                                        border: `0.05rem solid #ced4da`,
                                                                        borderRadius: "50px",
                                                                        backgroundColor: '#f8f8f8'
                                                                    }}>
                                                                        <input
                                                                            type={showPassword ? 'text' : 'password'}
                                                                            className={`form-control ${formStyles.passwordTextField}  ${errors.password?.message && 'is-invalid'}`}
                                                                            aria-label=""
                                                                            aria-describedby="basic-addon2"
                                                                            placeholder={''}
                                                                            defaultValue={''}
                                                                            autoComplete={'off'}
                                                                            autoCorrect={'off'}
                                                                            style={{border: 0}}
                                                                            {...register(`passcode`, {
                                                                                required: {
                                                                                    value: true,
                                                                                    message: '*this field is required',
                                                                                },
                                                                                minLength: {
                                                                                    value: 6,
                                                                                    message: 'passcode too short'
                                                                                }
                                                                            })
                                                                            }
                                                                        />
                                                                        <div className="input-group-append">
                                                                <span
                                                                    id="basic-addon2"
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                                                    style={{border: 0}}
                                                                >
                                                                    <img
                                                                        src={!showPassword ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`}
                                                                        alt={''}/>
                                                                </span>
                                                                        </div>
                                                                    </div>
                                                                    {errors.password && <div
                                                                        className="text-danger">{errors.password?.message}</div>}
                                                                </div>
                                                            </div>
                                                            {/*Confirm Password :: */}
                                                            <div className={'col-md-12 '}>
                                                                <div className="form-group">
                                                                    <label>Confirm passcode*</label>
                                                                    <div className={`input-group pr-3`}
                                                                         style={{
                                                                             border: `0.05rem solid #ced4da`,
                                                                             borderRadius: "50px 50px",
                                                                             backgroundColor: '#f8f8f8'
                                                                         }}>
                                                                        <input
                                                                            type={showConfirmPassword ? 'text' : 'password'}
                                                                            className={`form-control ${formStyles.passwordTextField}  ${errors.confirm_password?.message && 'is-invalid'}`}
                                                                            placeholder={''}
                                                                            defaultValue={''}
                                                                            autoComplete={'off'}
                                                                            autoCorrect={'off'}
                                                                            style={{border: 0}}
                                                                            {...register(`confirm_password`, {
                                                                                required: {
                                                                                    value: true,
                                                                                    message: '*this field is required',

                                                                                },
                                                                                minLength: {
                                                                                    value: 6,
                                                                                    message: 'passcode too short'
                                                                                },
                                                                                validate: value => value === getValues('passcode') || "The passcodes do not match"
                                                                            })
                                                                            }
                                                                        />


                                                                        <div className="input-group-append">
                                                                <span
                                                                    id="basic-addon2"
                                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                    className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                                                    style={{border: 0}}
                                                                >
                                                                    <img
                                                                        src={!showConfirmPassword ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`}
                                                                        alt={''}/>
                                                                </span>
                                                                        </div>
                                                                    </div>
                                                                    {errors.confirm_password && <div
                                                                        className="text-danger">{errors.confirm_password?.message}</div>}
                                                                </div>
                                                            </div>


                                                            {/**/}
                                                            <div className={'form-group mt-4'}>
                                                                {loading ? <Loader/> :
                                                                    <div className={'d-flex'}>
                                                                        <ButtonField
                                                                            id={'pass'}
                                                                            // btnStyle={}
                                                                            btnTxt={'Update Passcode'}
                                                                            btnWidth={'30'}
                                                                            btnAdditionalStyle={{padding: '0.5rem 1.5rem', width: '150px', textAlign: 'center'}}
                                                                            bgColor={colors.secondary_color}
                                                                            txtColor={colors.white}/>
                                                                        <Link to={'#'} onClick={() => onHandleAction(3)}
                                                                              className={'btn w-30 ml-2'} style={{
                                                                            color: colors.secondary_color,
                                                                            borderColor: colors.secondary_color,
                                                                            borderRadius: '50px',
                                                                            width: '150px'
                                                                        }}>Go Back</Link>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </form>
                                                    </>
                                                    :
                                                    action === 'pin' ?
                                                        <>
                                                            <form onSubmit={handleSubmit(resetPin)}>
                                                                <small id="emailHelp2"
                                                                       className="form-text text-muted ml-3 mb-3"
                                                                       style={{color: colors.secondary_color}}>
                                                                    Create a new PIN (4 digits) and inform the customer.
                                                                </small>
                                                                <div className={'col-md-12 mb-3'}>
                                                                    <div className="form-group">
                                                                        <label>PIN*</label>
                                                                        <div className="input-group pr-3" style={{
                                                                            border: `0.05rem solid #ced4da`,
                                                                            borderRadius: "50px",
                                                                            backgroundColor: '#f8f8f8'
                                                                        }}>
                                                                            <input
                                                                                type={showPassword ? 'text' : 'password'}
                                                                                className={`form-control ${formStyles.passwordTextField}  ${errors.pin?.message && 'is-invalid'}`}
                                                                                aria-label=""
                                                                                aria-describedby="basic-addon2"
                                                                                placeholder={''}
                                                                                defaultValue={''}
                                                                                autoComplete={'off'}
                                                                                autoCorrect={'off'}
                                                                                style={{border: 0}}
                                                                                {...register(`pin`, {
                                                                                    required: {
                                                                                        value: true,
                                                                                        message: '*this field is required',
                                                                                    },
                                                                                    minLength: {
                                                                                        value: 4,
                                                                                        message: 'PIN should 4 digits'
                                                                                    },
                                                                                    maxLength: {
                                                                                        value: 4,
                                                                                        message: 'PIN should 4 digits'
                                                                                    },
                                                                                })
                                                                                }
                                                                            />
                                                                            <div className="input-group-append">
                                                                <span
                                                                    id="basic-addon2"
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                                                    style={{border: 0}}
                                                                >
                                                                    <img
                                                                        src={!showPassword ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`}
                                                                        alt={''}/>
                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {errors.pin && <div
                                                                            className="text-danger">{errors.pin?.message}</div>}
                                                                    </div>
                                                                </div>
                                                                {/*Confirm Password :: */}
                                                                <div className={'col-md-12 '}>
                                                                    <div className="form-group">
                                                                        <label>Confirm PIN*</label>
                                                                        <div className={`input-group pr-3`}
                                                                             style={{
                                                                                 border: `0.05rem solid #ced4da`,
                                                                                 borderRadius: "50px 50px",
                                                                                 backgroundColor: '#f8f8f8'
                                                                             }}>
                                                                            <input
                                                                                type={showConfirmPassword ? 'text' : 'password'}
                                                                                className={`form-control ${formStyles.passwordTextField}  ${errors.confirm_pin?.message && 'is-invalid'}`}
                                                                                placeholder={''}
                                                                                defaultValue={''}
                                                                                autoComplete={'off'}
                                                                                autoCorrect={'off'}
                                                                                style={{border: 0}}
                                                                                {...register(`confirm_pin`, {
                                                                                    required: {
                                                                                        value: true,
                                                                                        message: '*this field is required',

                                                                                    },
                                                                                    minLength: {
                                                                                        value: 4,
                                                                                        message: 'PIN should 4 digits'
                                                                                    },
                                                                                    maxLength: {
                                                                                        value: 4,
                                                                                        message: 'PIN should 4 digits'
                                                                                    },
                                                                                    validate: value => value === getValues('pin') || "The pin do not match"
                                                                                })
                                                                                }
                                                                            />
                                                                            <div className="input-group-append">
                                                                <span
                                                                    id="basic-addon2"
                                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                    className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                                                    style={{border: 0}}
                                                                >
                                                                    <img
                                                                        src={!showConfirmPassword ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`}
                                                                        alt={''}/>
                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {errors.confirm_pin && <div
                                                                            className="text-danger">{errors.confirm_pin?.message}</div>}
                                                                    </div>
                                                                </div>


                                                                {/**/}
                                                                <div className={'form-group mt-4'}>
                                                                    {loading ? <Loader/> :
                                                                        <div className={'d-flex'}>
                                                                            <ButtonField
                                                                                id={''}
                                                                                // btnStyle={}
                                                                                btnTxt={'Update Pin'}
                                                                                btnWidth={'30'}
                                                                                btnAdditionalStyle={{padding: '0.5rem 1.5rem', width: '134px'}}
                                                                                bgColor={colors.secondary_color}
                                                                                txtColor={colors.white}/>
                                                                            <Link to={'#'}
                                                                                  onClick={() => onHandleAction(3)}
                                                                                  className={'btn w-30 ml-2'} style={{
                                                                                color: colors.secondary_color,
                                                                                borderColor: colors.secondary_color,
                                                                                borderRadius: '50px',
                                                                                width: '134px'
                                                                            }}>Go Back</Link>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </form>
                                                        </>
                                                        :
                                                        <></>
                                        }
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className={`modal-backdrop fade show`}></div>}
        </>
    )
}
export default ActivateDeactivateUserModal