
const Loader = ({parentStyles, childsStyles}) => {
    let containerStyles = {
        display: 'block',
        position: 'relative',
        width: '30px',
        height: '30px',
        margin: '0 auto',
        ...parentStyles
    }

    let childrenStyles = {
        boxSizing: 'border-box',
        display: 'block',
        position: 'absolute',
        width: '30px',
        height: '30px',
        top: '50%',
        left: '50%',
        marginTop: '-15px', 
        marginLeft: '-15px', 
        border: '4px solid var(--secondaryColor)',
        borderRadius: '50%',
        animation: 'lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
        borderColor: 'var(--secondaryColor) transparent transparent transparent',
        ...childsStyles
    }

    let child1Styles={
        animationDelay: '-0.45s',
    }
    let child2Styles={
        animationDelay: '-03s',
    }
    let child3Styles={
        animationDelay: '-0.15s',
    }

    return (
        <>
            {/*<div className="d-flex justify-content-center mb-5">*/}
            {/*    <div className="lds-facebook">*/}
            {/*        <div></div>*/}
            {/*        <div></div>*/}
            {/*        <div></div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div style={{...containerStyles}} className="lds-ring">
                <div style={{...childrenStyles, ...child1Styles}}></div>
                <div style={{...childrenStyles, ...child2Styles}}></div>
                <div style={{...childrenStyles, ...child3Styles}}></div>
                <div style={{...childrenStyles}}></div>
            </div>
            {/*<div style={{border : `1px solid ${colors.secondary_color}`,padding:'7px', color: colors.secondary_color}}>*/}
            {/*    <div className={'col-1 mx-auto justify-content-center'}>*/}
            {/*        <div className="spinner-border text-small" role="status"></div>*/}
            {/*    </div>*/}

            {/*</div>*/}
        </>

      )
}
export default  Loader