import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Slide, toast, ToastContainer } from "react-toastify";
import { clearAdminErrorMsg } from "../../../config/redux/slice/adminSlice";

const AdminErrorAlert = ({msg,toastId}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        showErrorNotification()
    },[])

    const closeTab = () => {
        dispatch(clearAdminErrorMsg())
    }

    const showErrorNotification = () => {
        //[Flip,Bounce,Slide]
        // [TOP_CENTER,TOP_LEFT,BOTTOM_CENTER,BOTTOM_LEFT]
        const finalMsg = typeof msg === 'string' ? msg : typeof msg==='object' ? 'Unable to Process Request' : typeof msg
        toast(finalMsg, {
            toastId : toastId,
                type: toast.TYPE.ERROR,
                transition: Slide,
                draggable: true,
                onClose:() => closeTab(),
                position : toast.POSITION.TOP_CENTER,
                theme :"colored" ,//light,dark,colored
                icon:() =><i className="mdi mdi-comment-eye"> </i>,
                style:{backgroundColor:'#222'}
            }
        )
    }

    return <>
        <ToastContainer style={{width:'100%',marginTop:0,top:0}} />
    </>
}
export default  AdminErrorAlert