import {NumberFormatter} from "./redux/actions";
import moment from "moment";
import {strText} from "./string";
import {pagesText} from "./string/english";
// import {colors} from "./color";

const $ = require('jquery') // 1
// $.DataTable  = require( 'datatables.net')
$.DataTable = require('datatables.net-dt') //2
$.DataTable = require('datatables.net-responsive-dt') //3
$.Database = require('datatables.net-responsive-dt/css/responsive.dataTables.css') //3
//
$.DataTable = require("datatables.net-buttons/js/buttons.html5.js") // 4:: loads the copy,pdf,csv buttons and makes it works
$.DataTable = require("datatables.net-buttons/js/buttons.print.js") // 4::loads the print btn and makes it work
//
$.DataTable = require('datatables.net-bs5'); //5


//
export const initAdminCardTable = (data, setItems) => {
    const cols = [
        {data: 'transaction_ref'},
        {data: 'source_account'},
        {data: 'destination_account'},

        {data: 'destination_account'},
        {data: 'destination_account'},
        {data: 'destination_account'},
    ]


    generalDatatableConfig(data, 'admin_card_table', cols, '')

    $(document).on("click", ".internet_banking_test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.t_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'details')
        }
    })
    //

}

export const accountDatatableAccountStatement = (data,myAccount,filterDate) => {

    const cols = [
        // {
        //     data: (item) => {
        //         return `<div  data-id="${item.transaction_ref}" class="transactionDetailsHover">
        //      ${ myAccount.account_number=== (item.source_accountno) ? `<img src='/assets/images/icon/outbound.svg' alt='' height='25'/>` :
        //             `<img src='/assets/images/icon/incoming_transfer.svg' alt={''} height={'25'}/>`
        //         }
        //      </div>`
        //     }
        // },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                    ${moment(item?.transaction_date).format(pagesText.full_date)} </div>
`
            }
        },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                        ${moment(item?.value_date).format(pagesText.full_date)} 
                        </div>`
            }
        },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                            <div class="d-flex">
                                <div class="flex-1 ml-3 pt-1">
                                    <span class="text-muted">
                                        <small class={'mx-2'}> ${item?.transaction_ref}
                                    </span>
                                </div>
                            </div>
                        </div>`
            }
        },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                       ${myAccount.account_number === item.source_accountno ? NumberFormatter(item?.amount?.toFixed(2)) : '-' }
                       </div>`}
        },

        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                       ${myAccount.account_number === item.beneficiary_acct ? NumberFormatter(item?.amount?.toFixed(2)) : '-' }
                       </div>`
            }
        },
        {
            data: (item) => {
                return `
                           <div class=" pt-1">
                                    <small class="text-muted">
                                        ${NumberFormatter(item.charges)}
                                    </small>
                                </div>`
            }
        }
    ]

    generalDatatableConfigAccountStatement(data, 'transaction_account_statements', cols, '', [0, 1, 2, 3, 4,5], [4, 'desc'],myAccount,filterDate)

}
export const initCustomerDashboardTransaction = (data, setItems, number = 'all',myAccount) => {
    const cols = [
        // {
        //     data: (item) => {
        //         return `<div  data-id="${item.transaction_ref}" class="transactionDetailsHover">
        //      ${Number(myAccount) === Number(item.source_accountno) && myAccount !==item.beneficiary_acct ? `<img src='/assets/images/icon/outbound.svg' alt='' height='25'/>` :
        //             `<img src='/assets/images/icon/incoming_transfer.svg' alt={''} height={'25'}/>`
        //         }
        //      </div>`
        //     }
        // },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                    ${moment(item?.transaction_date).format(pagesText.full_date)} </div>
`
            }
        },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                        ${moment(item?.value_date).format(pagesText.full_date)} 
                        </div>`
            }
        },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                            <div class="d-flex">
                                <div class="flex-1 ml-3 pt-1">
                                    <span class="text-muted">
                                        <small class={'mx-2'}> ${item?.transaction_ref}
                                    </span>
                                </div>
                            </div>
                        </div>`
            }
        },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                       ${Number(myAccount) === Number(item.source_accountno) && myAccount !==item.beneficiary_acct ? NumberFormatter(item?.amount?.toFixed(2)) : '-' }
                       </div>`}
        },

        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                       ${ (myAccount===item.beneficiary_acct) ? NumberFormatter(item?.amount?.toFixed(2)) : '-' }
                       </div>`
            }
        },
        {
            data: (item) => {
                return `
                           <div class=" pt-1">
                                    <small class="text-muted">
                                        ${Number(myAccount) === Number(item.source_accountno) && myAccount !==item.beneficiary_acct  ? NumberFormatter(item.charges) : "0.00"}
                                    </small>
                                </div>`
            }
        },
        {
            data: function (item) {
                return (`<a 
                                data-id="${item.transaction_ref}"
                                class="transactionDetailsHover"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails">
                               <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                                <i class="fa fa-eye"></i>
                               </button>
                </a>`)
            },
        }         
    ]
    let newData
    if (number !== 'all') {
        newData = data.length > 5 ? data.slice(-4) : data
    } else {
        newData = data
    }


    generalDatatableConfig(newData, 'transaction', cols, 'transactions', [0, 1, 2], [1, 'desc'])

    $(document).on("click", ".transactionDetailsHover", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.transaction_ref) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'details')
        }
    })

    //

}
//
export const initCustomerTransaction = (data, setItems, number = 'all', innerBtnFunc, myAccount) => {
    const cols = [
        // {
        //     data: (item) => {
        //         return `<div  data-id="${item.transaction_ref}" class="transactionDetailsHover">
        //      ${Number(myAccount) === Number(item.source_accountno) && myAccount !==item.beneficiary_acct? `<img src='/assets/images/icon/outbound.svg' alt='' height='25'/>` : 
        //             `<img src='/assets/images/icon/incoming_transfer.svg' alt={''} height={'25'}/>`
        //         }
        //      </div>`
        //     }
        // },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                    ${moment(item?.transaction_date).format(pagesText.full_date)} </div>
`
            }
        },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                        ${moment(item?.value_date).format(pagesText.full_date)} 
                        </div>`
            }
        },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                            <div class="d-flex">
                                <div class="flex-1 ml-3 pt-1">
                                    <span class="text-muted">
                                        <small class={'mx-2'}> ${item?.transaction_ref}
                                    </span>
                                </div>
                            </div>
                        </div>`
            }
        },
        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                       ${Number(myAccount) === Number(item.source_accountno) && myAccount !==item.beneficiary_acct
                    ? NumberFormatter(item?.amount?.toFixed(2)) : '-' }
                       </div>`}
        },

        {
            data: (item) => {
                return `<div  data-id="${item.transaction_ref}">
                       ${ (myAccount===item.beneficiary_acct)
                    ? NumberFormatter(item?.amount?.toFixed(2)) : '-' }
                       </div>`
            }
        },
        {
            data: (item) => {
                return `
                           <div class=" pt-1">
                                    <small class="text-muted">
                                        ${Number(myAccount) === Number(item.source_accountno) && myAccount !==item.beneficiary_acct  ? NumberFormatter(item.charges) : "0.00"}
                                    </small>
                                </div>`
            }
        },
        {
            data: function (item) {
                return (`<a 
                                data-id="${item.transaction_ref}"
                                class="transactionDetailsHover"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails">
                               <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                                <i class="fa fa-eye"></i>
                               </button>
                </a>`)
            },
        }        
    ]
    let newData
    if (number !== 'all') {
        newData = data.length > 5 ? data.slice(0, 4) : data
    } else {
        newData = data
    }


    generalDatatableConfigCustom(newData, 'transaction', cols, 'transactions', [0, 1, 2, 3, 4, 5, 6], [1, 'desc'], innerBtnFunc)

    $(document).on("click", ".transactionDetailsHover", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.transaction_ref) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'details')
        }
    })

    //

}
//
export const initCustomerTokenizedDeposit = (data, setItems, number = 'all',innerBtnFunc,additionalFunc,btnTxt) => {
    const cols = [
        {
            data: (item) => {
                return `${item.account}`
            }
        },
        {
            data: (item) => {
                return NumberFormatter(item.expected_amount)
            }
        },
        {
            data: (item) => {
                return item.actual_amount ? NumberFormatter(item.actual_amount) : 'N/A'
            }
        },
        {
            data: (item) => {
                return `${item.token} `
            }
        },
        {
            data: (item) => {
                return moment(item.created_on).format(pagesText.full_date)
            }
        },
        {
            data: (item) => {
                return item.status === 'active' ? `<span class="uk-text-success"> active</span>`: `<span class="uk-text-danger">item.status</span>`
            }
        },
        {
            data: function (a) {
                return (`<a href="#0"
                                data-id="${a.id}"
                                class="tokenized_deposit"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails">
                               <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                                <i class="fa ${a.status==='processed'? 'fa-eye' : 'fa-edit'}"></i>
                               </button>
                </a>`)
            },
        }
    ]
    let newData
    if (number !== 'all') {
        newData = data.length > 5 ? data.slice(0, 4) : data
    } else {
        newData = data
    }


    generalDatatableConfigCustomTokenized(
        newData,
        'tokenize_transaction',
        cols,
        '',
        [0, 1, 2, 3, 4],
        [4, 'desc'],
        innerBtnFunc,
        btnTxt,
        additionalFunc)

    $(document).on("click", ".tokenized_deposit", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'details')
        }
    })
    //

}

export const initCustomerTokenizedDepositAccountStatement = (data,userData,filterDate) => {

    const cols = [
        {
            data: (item) => {
                return `${item.account}`
            }
        },
        {
            data: (item) => {
                return NumberFormatter(item.expected_amount)
            }
        },
        {
            data: (item) => {
                return item.actual_amount ? NumberFormatter(item.actual_amount) : 'N/A'
            }
        },
        {
            data: (item) => {
                return `${item.token} `
            }
        },
        {
            data: (item) => {
                return moment(item.created_on).format(pagesText.full_date)
            }
        },
        {
            data: (item) => {
                return item.status === 'pending' ? `pending` : item.status === 'processed' ? 'processed' : ''
            }
        },
        {
            data:'narration'
        }
    ]

    generalDatatableConfigAccountStatement(data, 'tokenized_account_statements', cols, '', [0, 1, 2, 3, 4,5,6], [4, 'desc'],userData,filterDate)

}

//
export const initAdminDepositTable = (data, setItems,user_role) => {
    const cols = [
        {
            data: (item) => {
                return `${item.account}`
            }
        },
        {
            data: (item) => {
                return NumberFormatter(item.expected_amount)
            }
        },
        {
            data: (item) => {
                return item.actual_amount ? NumberFormatter(item.actual_amount) : 'N/A'
            }
        },
        {
            data: (item) => {
                return `${item.token} `
            }
        },
        {
            data: (item) => {
                return moment(item.created_on).format(pagesText.full_date)
            }
        },
        {
            data: (item) => {
                return item.status === 'pending' ? `<span class="uk-text-warning">pending</span>` : item.status === 'processed' ? `<span class="uk-text-success">processed</span>` : ''
            }
        },
        {
            data: function (a) {
                return `<a href="#0"
                                data-id="${a.id}"
                                class="tokenized_deposit"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails">
                               <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                                <i class="fa fa-eye"></i>
                               </button>
                </a>`
            },
        }
    ]
    //
    generalDatatableAdminConfig(data, 'table_admin_trans', cols, 'admin', [1, 2, 3, 4, 5], [4, 'desc'],
        'Tokenized Deposits',pagesText.customer_export_text)
    //
    $(document).on("click", ".tokenized_deposit", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'details')
        }
    })
}

//
export const initAdminTransaction = (data, setItems) => {
    const cols = [
        {data: 'transaction_ref'},
        // {
        //     data: (a) => {
        //         return `${a.transaction_ref}`
        //     }
        // },        
        {data: 'source_account'},
        {data: 'destination_account'},
        {
            data: (a) => {
                return `${a.beneficiary_name !== "" ? a.beneficiary_name : 'N/A'}`
            }
        },
        {data: 'receiving_bank'},

        {
            data: (a) => {
                return NumberFormatter(Number(a?.amount).toFixed(2))
            }
        },

        {
            data: (a) => {
                return moment(a.created_on).format(pagesText.full_date)

            }
        },
        /*
        {
            data: (a) => {
                return a.transfer_status === 'pending' ?
                    `<small class='text-warning pl-3'>pending</small>`
                    : a.transfer_status === 'approved' ?
                        `<small class='text-success pl-3'>approved</small>` :
                        `<small class='text-danger pl-3'>N/A</small>`
            }
        },
         */
        {
            data: function (a) {
                return (`
                            <a href="#0"
                               data-id="${a.t_id}"
                                class="internet_banking_test"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails">
                               <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                                <i class="fa fa-eye"></i>
                           </button>
                            </a>`
                )
            },
        }
    ]
    //
    generalDatatableAdminConfig(data, 'table_admin_trans', cols, 'admin', [0,1, 2, 3, 4, 5, 6], [6, 'desc'],
        'Transactions',pagesText.customer_export_text)
    //
    $(document).on("click", ".internet_banking_test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.t_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'details')
        }
    })
    //
}
//
export const additionalAccountOpeningRequestTable = (setItems, reqData) => {
    const cols = [
        {data: 'email'},
        {data: 'account_number'},
        {data: 'account_type'},
        {
            data: (a) => {
                return !a.initiated_by  ?
                    `<small class='text-warning'>pending</small>` :
                    a.initiated_by && a.request_status==='pending' ? `<small class='text-info'>processing</small>`
                        : a.request_status === 'approved' ?
                            `<small class='text-success'>approved</small>` :
                            `<small class='text-danger'>N/A</small>`
            }
        },
        {
            data : (a) => {
                return moment(a.created_on).format(pagesText.full_date)
            }
        },

        {
            data: function (a) {
                return (`
                       <a
                            href="#0"
                            data-id="${a.aaor_id}"
                            class="internet_banking_remark_test"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#savingsDetails"
                            aria-controls="savingsDetails"
                             data-toggle="tooltip" title="Approve request">
                           <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                                <i class="fa fa-eye"></i>
                           </button>
                       </a>
               `)
            },
        }
    ]
    const data = reqData

    generalDatatableAdminConfig(data, 'table_id_internet_banking_request', cols, 'internet banking', [0, 1, 2, 3, 4],[4,'desc'],pagesText.additional_account_request)

    $(document).on("click", ".internet_banking_remark_test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.aaor_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'details')
        }
    })
}
//Internet Banking Request
export const internetBankingRequestTable = (setItems, reqData) => {
    const cols = [
        {
            data: (a) => {
                return `${a.firstname ?? ''} ${a.middlename ?? ''} ${a.lastname ?? ''} `
            }
        },
        {data: 'account_number'},
        {data: 'customer_email'},
        {data: 'customer_phone'},
        // {
        //     data: (a) => {
        //         return a.initiated_by ?? 'N/A'
        //     }
        // },
        {
            data: (a) => {
                return !a.initiated_by  ?
                    `<small class='text-warning'>pending</small>` :
                    a.initiated_by && a.request_status==='pending' ? `<small class='text-info'>processing</small>`
                        : a.request_status === 'approved' ?
                            `<small class='text-success'>approved</small>` :
                            `<small class='text-danger'>N/A</small>`
            }
        },


        {
            data : (a) => {
                return moment(a.created_on).format(pagesText.full_date)
            }
        },
        /*
        {
            data: function (a) {
                return (a.request_status === 'pending' && a.initiated_by === null ?

                        `<a href="#" 
                              data-id="${a.ibr_id}"
                              class="internet_banking_test"
                              data-toggle="tooltip" 
                              title="Process request"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#savingsDetails"
                              aria-controls="savingsDetails"
                            >
                                 <button type="button" class="btn btn-icon btn-round btn-sm btn-primary">
                                     <i class="fa fa-edit"></i>
                                 </button>
                             </a> `
                        :
                        'N/A'
                )
            },
        },
        */

         {
            data: function (a) {
                return (`
                       <a
                            href="#0"
                            data-id="${a.ibr_id}"
                            class="internet_banking_remark_test"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#savingsDetails"
                            aria-controls="savingsDetails"
                             data-toggle="tooltip" title="Approve request">
                           <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                                <i class="fa fa-eye"></i>
                           </button>
                       </a>
               `)
            },
        },

        /*{
        //     data: function (a) {
        //         return (`<a
        //                     href="#0"
        //                     data-id="${a.ibr_id}"
        //                     class="internet_banking_remark_test_view"
        //                     data-bs-toggle="offcanvas"
        //                     data-bs-target="#savingsDetails"
        //                     aria-controls="savingsDetails"
        //                      data-toggle="tooltip" title="Approve request">
        //                    <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
        //                         <i class="fa fa-eye"></i>
        //                    </button>
        //                </a>`)
        //     },
         },*/

    ]
    const data = reqData

    generalDatatableAdminConfig(data, 'table_id_internet_banking_request', cols, 'internet banking', [0, 1, 2, 3, 4, 5],[5,'desc'],'Online Banking Activation')

    $(document).on("click", ".internet_banking_test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.ibr_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'details')
        }
    })
    //
    $(document).on("click", ".internet_banking_remark_test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.ibr_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'remark')
        }
    })
    //
    $(document).on("click", ".internet_banking_remark_test_view", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.ibr_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'view_details')
        }
    })
}
//Account Opening
export const accountOpeningRequestTable = (setItems, reqData) => {
    const cols = [
        // {data: 'aor_id'},
        {
            data: (a) => {
                return `${a.firstname ?? ''} ${a.middlename ?? ''} ${a.lastname ?? ''} `
            }
        },
        {data: 'account_type'},
        {data: 'customer_email'},
        {data: 'customer_phone'},
        // {
        //     data: (a) => {
        //         return a.initiated_by ?? 'N/A'
        //     }
        // },
        {
            data: (a) => {
                return !a.initiated_by  ?
                    `<small class='text-warning'>pending</small>` :
                    a.initiated_by && a.request_status==='pending' ? `<small class='text-info'>processing</small>`
                    : a.request_status === 'approved' ?
                        `<small class='text-success'>approved</small>` :
                        `<small class='text-danger'>N/A</small>`
            }
        },
        {
            data : (a)=> {
                return moment(a.created_on).format(pagesText.full_date)
            }
        },

        {
            data: function (a) {
                return ( `<a href="#0" 
                           data-toggle="tooltip" 
                           data-id="${a.aor_id}"
                            class="account_opening_test"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#savingsDetails"
                            aria-controls="savingsDetails"
                           title="Process request">
                           <button type="button" 
                           class="btn btn-icon btn-sm btn-round btn-primary">
                                  <i class="fa fa-eye"></i>
                           </button>
                           </a>
                           `)
            },
        },
        /*
        {
            data: function (a) {
                return (a.request_status === 'pending' && a.initiated_by !== null ? ` 
                            <a 
                                href="#0" 
                                data-id="${a.aor_id}"
                                class="account_opening_remark_test"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails"
                                data-toggle="tooltip" 
                                title="Approve request"
                            >
                            <button type="button" class="btn btn-icon btn-sm btn-round btn-info">
                            <i class="fa fa-check"></i>
                            </button>
                            </a>`
                        :
                        'N/A'
                )
            },
        },
        */
         /*
        {
            data: function (a) {
                return (` 
                            <a 
                                href="#0" 
                                data-id="${a.aor_id}"
                                class="account_opening_remark_test_view"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails"
                                data-toggle="tooltip" 
                                title="Approve request"
                            >
                            <button type="button" class="btn btn-icon btn-sm btn-round btn-info">
                            <i class="fa fa-eye"></i>
                            </button>
                            </a>`)
            },
        },
        */

    ]
    const data = reqData

    generalDatatableAdminConfig(data, 'table_id_account_opening_request', cols, 'account opening', [0, 1, 2, 3, 4, 5],
        [5,'desc'],
        'Account Opening Requests')

    $(document).on("click", ".account_opening_test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.aor_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'details')
        }
    })
    $(document).on("click", ".account_opening_remark_test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.aor_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'remark')
        }
    })
    $(document).on("click", ".account_opening_remark_test_view", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.aor_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'view_details')
        }
    })
}
//Agency Account Opening Request
export const agentAccountOpeningRequestTable = (setItems, reqData) => {
    const cols = [
        // {data: 'aor_id'},
        {
            data: (a) => {
                return `${a.firstname ?? ''} ${a.middlename ?? ''} ${a.lastname ?? ''} `
            }
        },
        {data: 'account_number'},
        {data: 'agent_email'},
        {data: 'agent_phone'},
        // {
        //     data: (a) => {
        //         return a.initiated_by ?? 'N/A'
        //     }
        // },
        {
            data: (a) => {
                return !a.initiated_by  ?
                    `<small class='text-warning'>pending</small>` :
                    a.initiated_by && a.request_status==='pending' ? `<small class='text-info'>processing</small>`
                        : a.request_status === 'approved' ?
                            `<small class='text-success'>approved</small>` :
                            `<small class='text-danger'>N/A</small>`
            }
        },
        {
            data : (a) => {
                return moment(a.created_on).format(pagesText.full_date)

            }
        },
        {
            data: function (a) {
                return ( `
                           <a href="#0" 
                           data-toggle="tooltip" 
                           data-id="${a.abr_id}"
                            class="account_opening_test"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#savingsDetails_"
                            aria-controls="savingsDetails_"
                           title="Process request">
                           <button type="button" 
                           class="btn btn-icon btn-sm btn-round btn-primary">
                                  <i class="fa fa-eye"></i>
                           </button>
                           </a>
                           `)
            },
        },
        /*
        {
            data: function (a) {
                return (a.request_status === 'pending' &&
                    (a.initiated_by !== null) ? ` 
                            <a 
                                href="#0" 
                                data-id="${a.abr_id}"
                                class="account_opening_remark_test"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails"
                                data-toggle="tooltip" 
                                title="Approve request"
                            >
                            <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                            <i class="fa fa-check"></i>
                            </button>
                            </a>`
                        :
                        'N/A'
                )
            },
        },
         */
        /*
        {
            data: function (a) {
                return (` <a 
                        href="#0" 
                        data-id="${a.abr_id}"
                        class="account_opening_remark_test_details"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#savingsDetails"
                        aria-controls="savingsDetails"
                        data-toggle="tooltip" 
                        title="Approve request"
                            >
                            <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                            <i class="fa fa-eye"></i>
                            </button>
                            </a>`)
            },
        }
         */
    ]
    const data = reqData

    generalDatatableAdminConfig(data, 'table_id_agent_account__request', cols, 'agency request', [0, 1, 2, 3, 4, 5],
        [5,'desc'],
        'Agency Banking Requests')

    $(document).on("click", ".account_opening_test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.abr_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'details')
        }
    })
    $(document).on("click", ".account_opening_remark_test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.abr_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'remark')
        }
    })

    $(document).on("click", ".account_opening_remark_test_details", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.abr_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'view_details')
        }
    })
}
//AuditLog
export const auditLogDatatable = (data) => {
    const cols = [
        {data: 'who'},
        {data: 'did_what'},
        {data: (item) => {return moment(item._when).format(pagesText.full_date)}},
    ]
    generalDatatableAdminConfig(data, 'table_id_audit_log', cols, 'customers', [0, 1, 2],[2,'desc'],pagesText.system_logs,pagesText.customer_export_text)
}
//Customers
export const customerDatatable = (data, setItems, canEditUserDetails) => {
    const cols = [
        {
            data: (a) => {
                return `${a.firstname} ${a.lastname}`
            }
        },
        {data: 'phone'},
        {
            data: (a) => {
                return `${a.email ?? 'N/A'}`
            }
        },        
      {
            data: (a) => {
                return a.status === 'active' ?
                    `<span class="text-success">active</span>`
                    :
                    `<span class="text-danger">inactive</span>`
            }
        },        
        // {data: 'branch'},
        // {data: (item) => {
        //     return moment(item.created_on).format(pagesText.full_date)
        //
        //     }},
        {
            data: function (a) {
                return (
                   canEditUserDetails ? (
                    `
                   <a href="#0" 
                        data-id="${a.customer_id}"
                        class=" test"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#savingsDetails"
                        aria-controls="savingsDetails"
                      data-toggle="tooltip" 
                      title="View User Details">
                          <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                          <i class="fa fa-eye"></i>
                          </button>
                   </a>
                    <a href="#0" 
                        data-id="${a.customer_id}"
                        class="userSettings"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#savingsDetails"
                        aria-controls="savingsDetails"
                      data-toggle="tooltip" 
                      title="User Settings">
                          <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                          <i class="fa fa-cog"></i>
                          </button>
                   </a>                   
                   `) : (
                            `
                        <a href="#0" 
                                data-id="${a.customer_id}"
                                class=" test"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails"
                            data-toggle="tooltip" 
                            title="View User Details">
                                <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                                <i class="fa fa-eye"></i>
                                </button>
                        </a>`
                   )
                )
            },
        }
    ]
    generalDatatableAdminConfig(data, 'table_id_customer', cols, 'customers', [0, 1, 2],[0,'asc'],pagesText.customers)
    $(document).on("click", ".test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.customer_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], '')
        }
    })
    $(document).on("click", ".userSettings", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.customer_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], 'activate_status')
        }
    })    
}
//account statement

//Agents
export const agentsDatatable = (data, setItems) => {
    const cols = [
        // {
        //     data: (x) => {
        //         return `<img src=${x.profile_image} className="avatar avatar-lg" height="40" alt={''}/>`
        //     }
        // },
        {data: 'firstname'},
        {data: 'lastname'},
        {data: 'phone'},
        {
            data: (a) => {
                return `${a.email == '' || !a.email ? 'N/A' : a.email}`
            }
        }, 
        {
            data: (a) => {
                return a.is_approved == 0 ?
                    `<span class="text-warning">pending</span>`
                    :
                    a.is_approved == 1 ?
                    `<span class="text-success">approved</span>`
                    : `<span class="text-danger">rejected</span>`
            }
        },

        {
            data: (a) => {
                return `${a.address ?? 'N/A'}`
            }
        }, 
        {
            data: (a) => {
                return a.status === 'active' ?
                    `<span class="text-success">active</span>`
                    :
                    `<span class="text-danger">inactive</span>`
            }
        },
        {
            data: function (a) {
                return (`
                           <a href="#0" 
                                data-id="${a.id}"
                                class=" test"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails"
                            data-toggle="tooltip" 
                            title="View User Details">
                                <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                                <i class="fa fa-eye"></i>
                                </button>
                        </a>`
                )
            },
        }
    ]

    generalDatatableAdminConfig(data, 'table_id_agents', cols, 'agents', [0, 1, 2],[0,'asc'],pagesText.agents)

    $(document).on("click", ".test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            setItems(x[0], '')
        }
    })


}
//Loans
export const loansDatatable = (data, setItems) => {
    const cols = [
        {
            data: (x) => {
                return `<img src=${x.profile_image} className="avatar avatar-lg" height="40" alt={''}/>`
            }
        },
        {data: 'first_name'},
        {data: 'last_name'},
        {data: 'loan_title'},
        {
            data: (a) => {
                return NumberFormatter(Number(a.amount).toFixed(2))
            }
        },
        {
            data: (a) => {
                return a.is_active ? `<span class="badge badge-success">active</span>` : `<span class="badge badge-danger">inactive</span>`
            }
        },
        {
            data: function (a) {
                return (`
                            <a href="#0"
                               data-id="${a.id}"
                                class=" test"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails">
                                <i class="fa fa-eye"></i>
                            </a>`
                )
            },
        }
    ]

    generalDatatableConfig(data, 'table_id_loans', cols, 'loans')

    $(document).on("click", ".test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            setItems(x[0])
        }
    })


}
//user admin  table_user_management
export const usersDatatable = (data, setItems ,rolesData) => {
    const cols = [
        {
            data: (a) => {
                return `${a.firstname} ${a.lastname} ${a.middlename ?? ''}`
            }
        },
        {data: 'phone'},
        {
            data:  (a) => {
                return `${a.email ?? 'N/A'}`
            }
        },

        // {
        //     data: (a) => {
        //         return pagesText.user_roles_text[a?.user_type]
        //     }
        // },
        {
            data: (a) => {
                let roles = rolesData.filter((role)=>role.id == a?.user_type)
                return `<span class='text-capitalize'>${roles[0]?.role_name}</span>`
            }
        },
        {
            data: (a) => {
                return moment(a?.created_on).format(pagesText.full_date)
            }
        },
        {
            data: (a) => {
                return a.status === 'active' ?
                    `<span class="text-success">active</span>`
                    :
                    `<span class="text-danger">inactive</span>`
            }
        },
        {
            data: function (a) {
                return (`
                         <a 
                               href="#0" 
                                data-id="${a.user_id}"
                                class="test"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#savingsDetails"
                                aria-controls="savingsDetails"
                                data-toggle="tooltip" 
                                title="Change admin status"
                            >
                            <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                            <i class="fa fa-eye"></i>
                            </button>
                            </a>
                       `
                )
            },
        }
    ]

    generalDatatableAdminConfig(data, 'table_user_management', cols, 'agents', [0, 1, 2, 3, 4,5], [4, 'desc'],'System Users')

    $(document).on("click", ".test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.user_id) === Number(r))
        if (x.length > 0) {
            setItems(x[0])
        }
    })


}
//Tiers  table_tier_management
export const tiersDatatable = (data, setItems) => {
    const cols = [
        {data: (a) => {
            let str = a?.name
            return str.replace(/^\w/, (c) => c.toUpperCase());
        }},
        {data: (a) => {
            if(isNaN(a?.daily_transaction_limit)){
                let str = a?.daily_transaction_limit
                return str.replace(/^\w/, (c) => c.toUpperCase());
            } else {
                return NumberFormatter(Number(a?.daily_transaction_limit).toFixed(2))                
            }
        }},
        {data: (a) => {
            if(isNaN(a?.maximum_balance)){
                let str = a?.maximum_balance
                // str.replace(/^\w/, (c) => c.toUpperCase());
                return str.replace(/^\w/, (c) => c.toUpperCase());
            } else {
                return NumberFormatter(Number(a?.maximum_balance).toFixed(2))                
            }    
        }}, 
        {data: 'created_by'},

        {data: 'approved_by' },
        {
            data: (a) => {
                return moment(a?.created_at).format(pagesText.full_date)
            }
        },
        {
            data: function (a) {
                return (`
                    <a href="#0" 
                        data-id="${a.id}"
                        class="tierItem"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#savingsDetails"
                        aria-controls="savingsDetails"
                      data-toggle="tooltip" 
                      title="Edit Tier">
                          <button type="button" class="btn btn-icon btn-sm btn-round btn-primary">
                          <i class="fa fa-edit"></i>
                          </button>
                   </a>`
                )
            },
        }          
    ]

    generalDatatableAdminConfig(data, 'table_id_tiers', cols, 'tiers', [0, 1, 2, 3, 4,5], [4, 'desc'],'Tiers Management')

    $(document).on("click", ".tierItem", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            setItems(x[0])
        }
    })


}

//Tiers  table_tier_management
export const rolesDatatable = (data, setItems, canUpdateRoles) => {
    // data = []
    const cols = [
        {data: (a) => {
            let str = a?.role_name
            return str.replace(/^\w/, (c) => c.toUpperCase());
        }},  
        //  {data: "modules"},
        {data: (a) => {
            // let str = a._permissions ?  a._permissions : ''
            let moduleArray = a.modules ? (a?.modules).split(',') : [];
            let moduleChildren = moduleArray.map((itemM)=>(
                `<span class="badge text-white bg-primary">${itemM}</span>`
            ))
            let moduleString = moduleChildren.join('')
            return (`
            <div>
                ${moduleString}
            </div>`)
        }},          
        // {data: (a) => {
        //     let str = a.modules
        //     return (str)
        // }},
        // {data: (a) => {
        //     // let str = a._permissions ?  a._permissions : ''
        //     let permissionArray = (a._permissions).split(',');
        //     return (`<div style="max-width: 400px">${str}</div>`)
        // }},       
        {data: (a) => {
            // let str = a._permissions ?  a._permissions : ''
            let permissionArray = a._permissions ? (a?._permissions).split(',') : [];
            let permissionChildren = permissionArray.map((itemP)=>(
                `<span class="badge text-white bg-primary">${itemP}</span>`
            ))
            let permissionString = permissionChildren.join('')
            return (`
            <div>
                ${permissionString}
            </div>`)
        }},        
       
        // {data: "_permissions"},
        {
            data: function (a) {
                return (`
                   <a href="#0" 
                        data-id="${a.id}"
                        class=" test"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#savingsDetails"
                        aria-controls="savingsDetails"
                      data-toggle="tooltip" 
                      title="Activate/Decative User">
                          <button type="button" class="btn fs-6 btn-sm btn-primary">
                          Update
                          </button>
                   </a>`
                )
            },
        }        
    ]

    generalDatatableRoleConfig(data, 'table_id_roles', cols, 'roles', [0, 1, 2], [1, 'desc'],'Roles Management', canUpdateRoles)

    $(document).on("click", ".test", function () {
        const r = $(this).attr("data-id")
        let x = data.filter((item) => Number(item.id) === Number(r))
        if (x.length > 0) {
            setItems(x[0])
        }
    })


}

//export with date range
const generalDatatableRoleConfig = (data, table_id, cols, table_name, exportRow = [0, 1, 2], ordering = [0, 'desc'],pageTitle,canUpdateRoles, btnName=`${pagesText.admin_export_text}`) => {
            let app_logo
            const serializedState = sessionStorage.getItem('persist:appData'); // Replace 'root' with your persist key
            if (serializedState) {
            // Deserialize the state
            const persistedState = JSON.parse(serializedState);
            app_logo = persistedState.appLogo ? JSON.parse(persistedState.appLogo) : ''

            }
    let isLandScape = true
    let exportTitle = `<img src="${app_logo}" alt="" height="30"/>`
    let exportOptionsIndex = exportRow

    let fromDate = ''
    let toDate = ''
    let total = 0
    let other_title = table_name ?? 'qapp'

    $(document).ready(function () {
        let idInit = $(`#${table_id}`)
        idInit.DataTable().clear().destroy()
        idInit.DataTable({
            language: {
                paginate: {
                    previous: "<i class='fa fa-arrow-left'>",
                    next: "<i class='fa fa-arrow-right'>",
                },
                info: `Showing ${table_name} _START_ to _END_ of _TOTAL_`,
            },
            pageLength: 5,
            data: data,
            dom: '<"container-fluid py-3"<"row"<"col"B><"col"f>>>rtip',
            buttons: [
                {
                    extend: "print",
                    text: btnName,
                    footer: false,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn btn-primary btn-sm mx-1",
                    header: true,
                    messageTop: `<table style="border-bottom:1px solid black;width:100% !important;text-align:center;margin-bottom: 30px;margin-top: 30px;">
                                        <tr>
                                            <th colspan="3" style="align-content: center; align-items:center;font-size: 2rem;"> ${pageTitle}</th>
                                            <th>  </th>
                                            <th>   </th>
                                        </tr>
                                        
                                    </table>`,
                    messageBottom: ``,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                //
            ],
            columns: cols,
            select: {style: 'multi'},
            order: [ordering],
            responsivePriority: 1,
            responsive: true,
            drawCallback: () => (
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded'),
                    $('#products-datatable_length label').addClass('form-label')
            ),
            initComplete: function () {
                var api = this.api();
                
                if ( !canUpdateRoles ) {
                    // Hide Office column
                    api.column(3).visible( false );
                }
            }
        })
        //
        // idInit.buttons().container()
        //     .appendTo( $('.col-sm-6:eq(0)', idInit.table().container() ) );
    })
}
const generalDatatableAdminConfig = (data, table_id, cols, table_name, exportRow = [0, 1, 2], ordering = [0, 'desc'],pageTitle,btnName=`${pagesText.admin_export_text}`) => {
    let app_logo
    const serializedState = sessionStorage.getItem('persist:appData'); // Replace 'root' with your persist key
    if (serializedState) {
        // Deserialize the state
        const persistedState = JSON.parse(serializedState);
        app_logo = persistedState.appLogo ? JSON.parse(persistedState.appLogo) : ''

    }
    
    let isLandScape = true
    let exportTitle = `<img src="${app_logo}" alt="" height="30"/>`
    let exportOptionsIndex = exportRow

    let fromDate = ''
    let toDate = ''
    let total = 0
    let other_title = table_name ?? 'qapp'

    $(document).ready(function () {
        let idInit = $(`#${table_id}`)
        idInit.DataTable().clear().destroy()
        idInit.DataTable({
            language: {
                paginate: {
                    previous: "<i class='fa fa-arrow-left'>",
                    next: "<i class='fa fa-arrow-right'>",
                },
                info: `Showing ${table_name} _START_ to _END_ of _TOTAL_`,
            },
            pageLength: 5,
            data: data,
            dom: '<"container-fluid py-3"<"row"<"col"B><"col"f>>>rtip',
            buttons: [
                {
                    extend: "print",
                    text: btnName,
                    footer: false,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn btn-primary btn-sm mx-1",
                    header: true,
                    messageTop: `<table style="border-bottom:1px solid black;width:100% !important;text-align:center;margin-bottom: 30px;margin-top: 30px;">
                                        <tr>
                                            <th colspan="3" style="align-content: center; align-items:center;font-size: 2rem;"> ${pageTitle}</th>
                                            <th>  </th>
                                            <th>   </th>
                                        </tr>
                                        
                                    </table>`,
                    messageBottom: ``,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                //
            ],
            columns: cols,
            select: {style: 'multi'},
            order: [ordering],
            responsivePriority: 1,
            responsive: true,
            drawCallback: () => (
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded'),
                    $('#products-datatable_length label').addClass('form-label')
            ),
        })
        //
        // idInit.buttons().container()
        //     .appendTo( $('.col-sm-6:eq(0)', idInit.table().container() ) );
    })
}
const generalDatatableConfig = (data, table_id, cols, table_name, exportRow = [0, 1, 2], ordering = [0, 'desc']) => {
    let isLandScape = true
    let exportOptionsIndex = exportRow
    let exportTitle = ''
    let fromDate = ''
    let toDate = ''
    let total = 0
    let other_title = table_name ?? 'qapp'

    $(document).ready(function () {
        let idInit = $(`#${table_id}`)
        idInit.DataTable().clear().destroy()
        idInit.DataTable({
            language: {
                paginate: {
                    previous: "<i class='fa fa-arrow-left'>",
                    next: "<i class='fa fa-arrow-right'>",
                    // first : "1",
                    // last: "2"
                },
                info: `Showing ${table_name} _START_ to _END_ of _TOTAL_`,
                /*
                lengthMenu:
                    `Display <select class=\'form-select form-select-sm ms-1 me-1\'>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="-1">All</option>
                    </select>
                    savings
                    `,
                 */
            },
            pageLength: 5,
            data: data,
            // dom: 'Bfrtip',
            // "dom": '<"top"<"left-col"B><"center-col"l><"right-col"f>>rtip',
            // dom: '<"top"<"left-col"B><"center-col"l><"right-col"f>>rtip',
            dom: '<"container-fluid py-3"<"row"<"col"B><"col"f>>>rtip',
            buttons: [
                /*
                {
                    extend: "copy",
                    text: 'COPY',
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn btn-primary btn-sm  mx-1",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                */
                /*
                {
                    extend: "excel",
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn-sm btn-info mx-1",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                 */
                /*
                {
                    extend: "pdf",
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn-sm btn-primary btn-sm mr-1",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                */
                /*{
                    extend: "csv",
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn btn-primary btn-sm mx-1",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                */
                // {
                //     extend: "print",
                //     text: "Export to PDF",
                //     footer: true,
                //     orientation: isLandScape ? "landscape" : "portrait",
                //     pageSize: isLandScape ? "LEGAL" : "A4",
                //     exportOptions: {
                //         columns: exportOptionsIndex,
                //     },
                //     title: exportTitle,
                //     className: "btn btn-info btn-sm mx-1",
                //     header: true,
                //     messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                //     messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                //     filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                // },
                //
            ],
            columns: cols,
            select: {style: 'multi'},
            order: [ordering],
            responsivePriority: 1,
            responsive: true,
            drawCallback: () => (
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded'),
                    $('#products-datatable_length label').addClass('form-label')
            ),
        })
        //
        // idInit.buttons().container()
        //     .appendTo( $('.col-sm-6:eq(0)', idInit.table().container() ) );
    })
}

// allows user to click on btn to perform a particular func
const generalDatatableConfigCustomTwo = (data, table_id, cols, table_name, exportRow = [0, 1, 2], ordering = [0, 'desc'], innerBtnFunc,btnName=[],additionalFunc) => {
    let isLandScape = true
    let exportOptionsIndex = exportRow
    let exportTitle = ''
    let fromDate = ''
    let toDate = ''
    let total = 0
    let other_title = table_name ?? 'qapp'

    $(document).ready(function () {
        let idInit = $(`#${table_id}`)
        idInit.DataTable().clear().destroy()
        idInit.DataTable({
            language: {
                paginate: {
                    previous: "<i class='fa fa-arrow-left'>",
                    next: "<i class='fa fa-arrow-right'>",
                },
                info: `Showing ${table_name} _START_ to _END_ of _TOTAL_`,
            },
            pageLength: 5,
            data: data,
            dom: '<"container-fluid py-3"<"row"<"col"B><"col"f>>>rtip',
            buttons: [
                {
                    text: btnName[0],
                    className: "btn btn-primary btn-sm mx-1 custom_button",
                    action: (e, dt, node, config) => {
                        innerBtnFunc()
                    }
                },
                {
                    text: btnName[1],
                    className: "btn btn-sm  mx-1 custom_button_two",

                    action: (e, dt, node, config) => {
                        additionalFunc()
                    }
                }
            ],
            columns: cols,
            select: {style: 'multi'},
            order: [ordering],
            responsivePriority: 1,
            responsive: true,
            drawCallback: () => (
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded'),
                    $('#products-datatable_length label').addClass('form-label')
            ),
        })
    })
}

const generalDatatableConfigCustomTokenized = (data, table_id, cols, table_name, exportRow = [0, 1, 2], ordering = [0, 'desc'], innerBtnFunc,btnName=[],additionalFunc) => {
    let isLandScape = true
    let exportOptionsIndex = exportRow
    let exportTitle = ''
    let fromDate = ''
    let toDate = ''
    let total = 0
    let other_title = table_name ?? 'qapp'

    $(document).ready(function () {
        let idInit = $(`#${table_id}`)
        idInit.DataTable().clear().destroy()
        idInit.DataTable({
            language: {
                paginate: {
                    previous: "<i class='fa fa-arrow-left'>",
                    next: "<i class='fa fa-arrow-right'>",
                },
                info: `Showing ${table_name} _START_ to _END_ of _TOTAL_`,
            },
            pageLength: 5,
            data: data,
            dom: '<"container-fluid py-3"<"row"<"col-9"B><"col-3"f>>>rtip',
            buttons: [
                {
                    text: btnName[0],
                    className: "btn btn-primary btn-sm mx-1 custom_button",
                    action: (e, dt, node, config) => {
                        innerBtnFunc()
                    }
                },
                {
                    text: btnName[1],
                    className: "btn btn-sm  mx-1 custom_button_two",

                    action: (e, dt, node, config) => {
                        additionalFunc()
                    }
                },
                {
                    text: btnName[2],
                    className: "btn btn-sm btn-rounded mx-1 custom_button_three",

                    action: (e, dt, node, config) => {

                    }
                },
                {
                    text: btnName[3],
                    className: "btn btn-sm btn-rounded mx-1 custom_button_four",
                    action: (e, dt, node, config) => {

                    }
                },
                {
                    text: btnName[4],
                    className: "btn btn-sm btn-rounded  mx-1 custom_button_five",
                    action: (e, dt, node, config) => {

                    }
                },
            ],
            columns: cols,
            select: {style: 'multi'},
            order: [ordering],
            responsivePriority: 1,
            responsive: true,
            drawCallback: () => (
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded'),
                    $('#products-datatable_length label').addClass('form-label')
            ),
        })
    })
}



const generalDatatableConfigCustom = (data, table_id, cols, table_name, exportRow = [0, 1, 2], ordering = [0, 'desc'], innerBtnFunc,btnName='ACCOUNT STATEMENT') => {
    let isLandScape = true
    let exportOptionsIndex = exportRow
    let exportTitle = ''
    let fromDate = ''
    let toDate = ''
    let total = 0
    let other_title = table_name ?? 'qapp'

    $(document).ready(function () {
        let idInit = $(`#${table_id}`)
        idInit.DataTable().clear().destroy()
        idInit.DataTable({
            language: {
                paginate: {
                    previous: "<i class='fa fa-arrow-left'>",
                    next: "<i class='fa fa-arrow-right'>",
                    // first : "1",
                    // last: "2"
                },
                info: `Showing ${table_name} _START_ to _END_ of _TOTAL_`,
                /*
                lengthMenu:
                    `Display <select class=\'form-select form-select-sm ms-1 me-1\'>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="-1">All</option>
                    </select>
                    savings
                    `,
                 */
            },
            pageLength: 5,
            data: data,
            // dom: 'Bfrtip',
            // "dom": '<"top"<"left-col"B><"center-col"l><"right-col"f>>rtip',
            // dom: '<"top"<"left-col"B><"center-col"l><"right-col"f>>rtip',
            dom: '<"container-fluid py-3"<"row"<"col"B><"col"f>>>rtip',
            buttons: [
                /*
                {
                    extend: "copy",
                    text: 'COPY',
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn btn-primary btn-sm  mx-1",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                */
                /*
                {
                    extend: "excel",
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn-sm btn-info mx-1",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                 */
                /*
                {
                    extend: "pdf",
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn-sm btn-primary btn-sm mr-1",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                */
                /*{
                    extend: "csv",
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex,
                    },
                    title: exportTitle,
                    className: "btn btn-primary btn-sm mx-1",
                    header: true,
                    messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                    messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                },
                */
                // {
                //     extend: "print",
                //     text: "Export to PDF",
                //     footer: true,
                //     orientation: isLandScape ? "landscape" : "portrait",
                //     pageSize: isLandScape ? "LEGAL" : "A4",
                //     exportOptions: {
                //         columns: exportOptionsIndex,
                //     },
                //     title: exportTitle,
                //     className: "btn btn-info btn-sm mx-1",
                //     header: true,
                //     messageTop: `Filter Result : ${fromDate} -  ${toDate}`,
                //     messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                //     filename: `${other_title}_data_for_${fromDate}_${toDate}_`,
                // },
                {
                    text: btnName,
                    className: "btn btn-primary btn-sm mx-1 custom_button",
                    action: (e, dt, node, config) => {
                        innerBtnFunc()
                    }
                }
            ],
            columns: cols,
            select: {style: 'multi'},
            order: [ordering],
            responsivePriority: 1,
            responsive: true,
            drawCallback: () => (
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded'),
                    $('#products-datatable_length label').addClass('form-label')
            ),
        })
        //
        // idInit.buttons().container()
        //     .appendTo( $('.col-sm-6:eq(0)', idInit.table().container() ) );
    })
}




//
const generalDatatableConfigAccountStatement = (data, table_id, cols, table_name, exportRow = [0, 1, 2], ordering = [0, 'desc'],userData,filterDate) => {
    let app_logo
    const serializedState = sessionStorage.getItem('persist:appData'); // Replace 'root' with your persist key
    if (serializedState) {
        // Deserialize the state
        const persistedState = JSON.parse(serializedState);
        app_logo = persistedState.appLogo ? JSON.parse(persistedState.appLogo) : ''

    }
    
    let isLandScape = true
    let exportOptionsIndex = exportRow
    let exportTitle = `<img src="${app_logo}" alt=""/>`
    let fromDate = ''
    let toDate = ''
    let total = 0
    let other_title = table_name ?? 'qapp'

    $(document).ready(function () {
        let idInit = $(`#${table_id}`)
        idInit.DataTable().clear().destroy()
        idInit.DataTable({
            language: {
                paginate: {
                    previous: "<i class='fa fa-arrow-left'>",
                    next: "<i class='fa fa-arrow-right'>",
                },
                info: `Showing ${table_name} _START_ to _END_ of _TOTAL_`,
            },
            pageLength: 5,
            data: data,
            dom: 'Bfrtip',
            buttons: [
                {
                    extend: "print",
                    text: "Print",
                    footer: true,
                    orientation: isLandScape ? "landscape" : "portrait",
                    pageSize: isLandScape ? "LEGAL" : "A4",
                    exportOptions: {
                        columns: exportOptionsIndex
                    },
                    title: exportTitle,
                    className: "btn btn-info btn-sm mx-1",
                    header: true,
                    messageTop: `
                                    <table style="border-bottom:1px solid black;width:100% !important;text-align:center;margin-bottom: 30px;margin-top: 30px;">
                                        <tr>
                                            <td style="align-content: flex-start; align-items:self-start;"> Fullname </td>
                                            <td> Account  Number </td>
                                            <td> Filtering Date  </td>
                                        </tr>
                                         <tr>
                                            <td> <b> ${userData.full_name} </b> </td>
                                            <td> <b> ${userData.account_number} </b> </td>
                                            <td> <b> ${filterDate.fromDate} - ${filterDate.toDate} </b> </td>
                                        </tr>
                                     
                                    </table>`,
                    // messageBottom: `\n\n Total :₦${Number(total).toLocaleString()} \n\n ${exportTitle}`,
                    // filename: `${other_title}_data_for_${fromDate}_${toDate}_`,

                },
                //
            ],
            columns: cols,
            select: {style: 'multi'},
            order: [ordering],
            responsivePriority: 1,
            responsive: true,
            drawCallback: () => (
                $('.dataTables_paginate > .pagination').addClass('pagination-rounded'),
                    $('#products-datatable_length label').addClass('form-label')
            ),
        })
        //
        // idInit.buttons().container()
        //     .appendTo( $('.col-sm-6:eq(0)', idInit.table().container() ) );
    })
}