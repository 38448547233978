import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {setOperationsForm, type} from "../../../../../config/redux/slice/adminSlice";
import {ButtonField, TextInputField} from "../../../../../components/form/components";
// import {colors} from "../../../../../config/color";
import PendingRequestWrapper from "../../_components/PendingRequestWrapper";

const TokenizedWithdrawalForm = () => {
    const {register, handleSubmit, getValues, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {operationManagement:{pendingTokenizedWithdrawal}} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    //handle continue function
    const onContinue = (data) => {
        dispatch(setOperationsForm({data:data,resetFunc:reset,type:type.tokenized_withdrawal}))
    }

    return <>
        <div className={'mt-5 mx-auto w-75'}>
            <form onSubmit={handleSubmit(onContinue)}>
                <div className={'row mb-4'}>
                    <div className={'col-6'}>
                        <TextInputField
                            inputStyles={{
                                background: colors.inputBg,
                                border: `1px solid ${colors.inputBg}`,
                                borderRadius: '4px'
                            }}
                            patternRules={'numbers'}
                            placeholder={''}
                            label={'Phone Number'}
                            register={register}
                            error_msg={errors.phone_number?.message}
                            isReq={true}
                            fieldName={'phone_number'}
                            defaultVal={''}
                            // col={}
                            // onClickFunc={}
                        />
                    </div>
                    <div className={'col-6'}>
                        <TextInputField
                            inputStyles={{background: colors.inputBg, border: `1px solid ${colors.inputBg}`}}
                            patternRules={'numbers'}
                            placeholder={''}
                            label={'Account Number'}
                            register={register}
                            error_msg={errors.account_number?.message}
                            isReq={true}
                            fieldName={'account_number'}
                            defaultVal={''}
                        />

                    </div>
                </div>
                {/*    */}
                <div className={'row mb-4'}>
                    <div className={'col-12'}>
                        <TextInputField
                            inputStyles={{background: colors.inputBg, border: `1px solid ${colors.inputBg}`}}
                            patternRules={'letters'}
                            placeholder={''}
                            label={'Account Name'}
                            register={register}
                            error_msg={errors.account_name?.message}
                            isReq={true}
                            fieldName={'account_name'}
                            defaultVal={''}
                            // col={}
                            // onClickFunc={}
                        />

                    </div>

                </div>

                {/*button*/}
                <div>
                    <ButtonField
                        txtColor={colors.white}
                        bgColor={colors.secondary_color}
                        btnTxt={'Continue'}
                        btnWidth={'100'}
                        // btnStyle={''}
                        id={'d'}/>
                </div>
            </form>
            <PendingRequestWrapper title={'Pending Withdrawal'} data={pendingTokenizedWithdrawal}/>
        </div>



    </>
}

export default  TokenizedWithdrawalForm