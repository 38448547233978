import { useNavigate } from "react-router-dom";
import AdminWrapper from "../AdminWrapper";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { urls } from "../../../config/urls/urls";

const MprReports = () => {
const {userData:{modules, permissions}, user_role,moduleRoles} = useSelector((state) => state.authData)
const navigate = useNavigate()    

    // useEffect(()=>{
    //     if(!moduleRoles["Reports MPR"]?.includes(user_role)){
    //         navigate(`${urls.adminDashboard}`)
    //     }        
    // }, [user_role])

return(
    <AdminWrapper>
        <div className={'card'}>
            <div className={'card-header'}>
                <h2 className={'tableHeader'}>Monthly Performance Reports</h2>
            </div>
            <div className={'card-body p-5'}></div>

        </div>
    </AdminWrapper>
)
}

export default  MprReports