import { useDispatch, useSelector } from "react-redux";
import { CardWrapper, ConfirmationWrapper, PinModule } from "../../../../../components";
// import { colors } from "../../../../../config/color";
import { appConfig, remoteUrl } from "../../../../../config/config";
import { NumberFormatter } from "../../../../../config/redux/actions";
import { postRequest2 } from "../../../../../config/redux/request";
import {
    setCustomerErrorMsg,
    setGeneralModal,
    setLoading, submitTokenizedDepositData
} from "../../../../../config/redux/slice/customerSlice";
import { strText } from "../../../../../config/string";


const ConfirmTokenizedDeposit = ({backdrop,msg}) => {
    const { token,userData } = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const { tokenizedDepositFormData,loading } = useSelector((state) => state.customer)
    const dispatch = useDispatch()

    const onClose = () => {
        dispatch(setGeneralModal({
            backdrop: false,
            msg: '',
            show: false
        }))
    }
    //
    // const onContinue = () => {
    //     dispatch(submitTransferData())
    // }
    const onContinue = async (data) => {
        let pin = `${data.pin_one}${data.pin_two}${data.pin_three}${data.pin_four}`
        let postData = {
            "jwt" : token,
            "account":userData.account_number,
            "amount":tokenizedDepositFormData.amount,
            "narration": tokenizedDepositFormData?.narration !=="" ? tokenizedDepositFormData?.narration: " ",
            "email": userData.email
        }
        const res =  await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.doTokenizedTransaction}`,
            postData,
            dispatch,
            setLoading,
            submitTokenizedDepositData,
            setCustomerErrorMsg)
    }

    return (
        <>
            <div className="modal fade show" id="exampleModalCenter" style={{display: "block", paddingRight: "17px"}}
                 aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content  p-2">
                        <div className="modal-header" style={{border: "0px"}}>
                            <h2 className={'tableHeader'}>Confirm Tokenized Transaction</h2>
                            <button type="button" className="close" data-dismiss="modal"  aria-label="Close" onClick={() => onClose()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body ">
                            <CardWrapper
                                cardTitle={''}
                                cardWidth={''}
                                cardWrapperStyle={'col-xl-12 py-3  w-100 '}
                                cardTitleColor={colors.secondary_color}
                            >
                                <div className={'px-1  mx-auto w-100'}>
                                    <div>
                                        <ConfirmationWrapper
                                            title={''}
                                            titleStyle = {' mb-3 h5'}
                                            dataArray={[
                                                {
                                                    text: 'Amount',
                                                    amount: `${strText.naira}${NumberFormatter(Number(tokenizedDepositFormData?.amount).toFixed(2))}`
                                                },
                                                {
                                                    text: 'Deposit Account',
                                                    amount: userData?.account_number
                                                }
                                            ]}
                                        />
                                    </div>

                                </div>
                                {/*    */}
                                <div className={'text-center mx-auto w-75 mt-5'}>
                                    <PinModule
                                        btnTxt={'Continue'}
                                        btnWidth={'100'}
                                        leadTxt={'Enter your transaction PIN below'}
                                        btnBgColor={colors.secondary_color}
                                        btnTxtColor={colors.white}
                                        processFunc={onContinue}
                                        loading={loading}
                                    />
                                </div>
                            </CardWrapper>


                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className="modal-backdrop fade show"></div>}
        </>

    )
}

export default  ConfirmTokenizedDeposit