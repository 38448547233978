import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styles from "../../assets/css/formStyle.module.css";
import { Loader } from "../../components";
import { ButtonField, PasswordField, TextInputField } from "../../components/form/components";
import CheckboxField from "../../components/form/components/CheckboxField";
// import { colors } from "../../config/color";
import { appConfig, remoteUrl } from "../../config/config";
import { formatMessages } from "../../config/redux/actions";
import { postRequest, postRequest2 } from "../../config/redux/request";
import {
    auth_types,
    changeModalSize,
    clearAll, onLinkInternetBanking, onSubmitInternetBankReq, setAuthModalForm,
    setErrorMessage,
    setInternetBanking,
    setLoading,
    setTemAccountNo
} from "../../config/redux/slice/authData";
import { respMsg } from "../../config/response_messages";
import { urls } from "../../config/urls/urls";
import AlertWrapper from "../_shared_component/AlertWrapper";
import AuthWrapper from "./AuthWrapper";

const InternetBankingNew = () => {
    const {authSize} = useSelector((state) => state.authData)
    const {
        loading,
        showRequestForInternetBanking,
        errorMsg,
        showPreviewAccInternetPreviewForm,
        tempInternetUserDetails,
        tempAccountNo,
        success_msg,
        modalSize
    } = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const [togglePassword, setTogglePassword] = useState(false)

    const [showLinkAccountForm, setShowLinkAccountForm] = useState(false)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const dispatch = useDispatch()

    const submitData = (data) => {
        dispatch(clearAll())
        dispatch(setTemAccountNo({account_no:data.account_number}))
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.getInternetBankAccountInfo}`,
            {
                "accountno": data.account_number
            },
            dispatch,
            setLoading,
            setInternetBanking,
            setErrorMessage)
    }

    const submitInternetBankingReq = (data) => {
        let res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.submitInternetOpeningReq}`,
            data,
            dispatch,
            setLoading,
            onSubmitInternetBankReq,
            setErrorMessage)
    }

    const linkMyAccount = (data) => {
        //
        dispatch(clearAll())

        const postData = {
            "email": tempInternetUserDetails.email,
            "password": data.password,
            "account_number":tempAccountNo
        }

        let res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.link_internet_banking}`,
            postData,
            dispatch,
            setLoading,
            onLinkInternetBanking,
            setErrorMessage
        )
    }


    const onClickLInkAccountBtn = () => {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
        setShowLinkAccountForm(true)
    }

    const onModalClose = () => {
        dispatch(setAuthModalForm({
            modal: false,
            type: auth_types.request_internet_banking
        }))
    }

    useEffect(()=> {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
        dispatch(setAuthModalForm({
            modal: false,
            type: auth_types.request_internet_banking
        }))
    },[])


    if(success_msg===respMsg.link_internet_banking){
        setValue('password','')
    }

    return (
        <AuthWrapper>

                <div className={`internet_banking_new`} style={{height:'100vh',overflowX:'hidden'}}>
                    <section className="mt-5 justify-content-center align-items-center " >
                        <div className={'card shadow-lg bg-white pb-3 pt-5 px-3 col-sm-1 col-md-5 mx-auto '} >

                            <div className="tittle text-center">
                                <h2 className={''} style={{color:colors.primary_color}}>
                                    <b>Online Banking Activation</b>
                                </h2>
                            </div>



                            <div>
                        {
                            errorMsg==='SSO account already exists' ?
                                <AlertWrapper type={'error'}>
                                    It looks like you already have a QApp profile. Kindly
                                    <Link onClick={()=>onClickLInkAccountBtn()} to={'#0'}>
                                        <u> Click here </u>
                                    </Link>
                                    to link your QApp profile.
                                </AlertWrapper>
                                :
                                errorMsg==='not found'? <AlertWrapper type={'error'}>
                                        Account number entered does not exist, please use a valid account number.
                                        <Link to={urls.landingPage}> Click here </Link> to close this window.
                                    </AlertWrapper> :
                                    errorMsg==='incorrect password' ?  <AlertWrapper type={'error'}>{formatMessages(errorMsg) } .
                                        </AlertWrapper>
                                        :
                                        errorMsg && <AlertWrapper type={'error'}>{formatMessages(errorMsg) } .
                                            <Link to={urls.landingPage}> <u>Click here</u>  </Link> to close window
                                        </AlertWrapper>
                        }

                        {success_msg && <AlertWrapper type={'success'}>{success_msg}
                            <Link to={urls.landingPage} > Click here </Link> to close this window

                        </AlertWrapper> }
                    </div>
                            <div className={'py-5 px-5'}>
                                {
                                    showLinkAccountForm ?
                                        <>
                                            <form onSubmit={handleSubmit(linkMyAccount)}>
                                                <div className={'mb-1'}>
                                                    <PasswordField
                                                        defaultVal={''}
                                                        // inputStyles={}
                                                        fieldName={'password'}
                                                        defaultValue ={''}
                                                        error_msg={errors.password?.message}
                                                        col={'col-xl-12'}
                                                        label={'Enter your QApp password'}
                                                        placeholder={''}
                                                        isReq={true}
                                                        register={register}
                                                        // patternRules={''}
                                                    />
                                                </div>
                                                <div className={'mt-2'}>
                                                    {loading ? <Loader/> :
                                                        <ButtonField
                                                            txtColor={colors.white}
                                                            bgColor={colors.primary_color}
                                                            btnTxt={'Link my QApp profile'}
                                                            btnWidth={'100'}
                                                            btnStyle={'mx-auto col-xl-12 mt-1 pb-2'}/>}
                                                </div>
                                            </form>
                                        </>
                                        :
                                        <>
                                            {showPreviewAccInternetPreviewForm
                                                ?
                                                <>
                                                    <form onSubmit={handleSubmit(submitInternetBankingReq)}>
                                                        <div className={'row mb-3'}>
                                                            <div className={'col-md-4'}>
                                                                <label>Firstname</label>
                                                                <input className={`form-control ${styles.textField}`} type={'text'} readOnly={true}
                                                                       defaultValue={tempInternetUserDetails.firstname} {...register('firstname')} />
                                                            </div>
                                                            <div className={'col-md-4'}>
                                                                <label>Middle name</label>
                                                                <input className={`form-control ${styles.textField}`} type={'text'} readOnly={true}
                                                                       defaultValue={tempInternetUserDetails?.middlename} {...register('middlename')} />
                                                            </div>
                                                            {/*Lastname*/}
                                                            <div className={'col-md-4'}>
                                                                <label>Lastname</label>
                                                                <input className={`form-control ${styles.textField}`} type={'text'} readOnly={true}
                                                                       defaultValue={tempInternetUserDetails.lasttname} {...register('lastname')} />
                                                            </div>
                                                            {/*    */}

                                                        </div>
                                                        <div className={'row mb-3'}>
                                                            {/*Email*/}
                                                            <div className={'col-md-6'}>
                                                                <label>Email</label>
                                                                <input className={`form-control ${styles.textField}`} type={'text'} readOnly={true}
                                                                       defaultValue={tempInternetUserDetails.email} {...register('email')} />
                                                            </div>
                                                            {/**/}
                                                            <div className={'col-md-6'}>
                                                                <label>Phone Number</label>
                                                                <input className={`form-control ${styles.textField}`} type={'text'} readOnly={true}
                                                                       defaultValue={tempInternetUserDetails.phone} {...register('phone')} />
                                                            </div>
                                                        </div>
                                                        <div className={'row mb-3'}>
                                                            {/**/}
                                                            <div className={'col-md-12'}>
                                                                <label>Account Number</label>
                                                                <input className={`form-control ${styles.textField}`} type={'text'} readOnly={true}
                                                                       defaultValue={tempAccountNo} {...register('accountno')} />
                                                            </div>
                                                        </div>
                                                        <div className={'row mb-3'}>
                                                            {/*Password*/}
                                                            <div className={'col-md-6'}>
                                                                <PasswordField
                                                                    defaultVal={''}
                                                                    // inputStyles={}
                                                                    fieldName={'password_new'}
                                                                    defaultValue ={''}
                                                                    error_msg={errors.password_new?.message}
                                                                    col={'col-xl-12'}
                                                                    label={'Enter your password'}
                                                                    placeholder={''}
                                                                    isReq={true}
                                                                    register={register}
                                                                    // patternRules={''}
                                                                />
                                                            </div>
                                                            {/*Confirm Password*/}
                                                            <div className={'col-md-6'}>
                                                                <PasswordField
                                                                    defaultVal={''}
                                                                    // inputStyles={}
                                                                    fieldName={'password'}
                                                                    defaultValue ={''}
                                                                    error_msg={errors.password?.message}
                                                                    col={'col-xl-12'}
                                                                    label={'Confirm password'}
                                                                    placeholder={''}
                                                                    isReq={true}
                                                                    register={register}
                                                                    // patternRules={''}
                                                                />
                                                            </div>
                                                        </div>

                                                        {/**/}
                                                        <div className={'mt-3'}>
                                                            {loading ? <Loader/> :
                                                                <ButtonField
                                                                    txtColor={colors.white}
                                                                    bgColor={colors.primary_color}
                                                                    btnTxt={'Activate Online Banking'}
                                                                    btnWidth={'100'}
                                                                    btnStyle={'mx-auto col-xl-12 mt-1 pb-2'}/>}
                                                        </div>
                                                    </form>
                                                </>
                                                :
                                                <>
                                                    <form onSubmit={handleSubmit(submitData)}>
                                                        {/*Account Number*/}
                                                        <div className={'mb-1'}>
                                                            <TextInputField
                                                                fieldName={'account_number'}
                                                                defaultValue={''}
                                                                error_msg={errors.account_number?.message}
                                                                col={'col-xl-12'}
                                                                label={'Enter your account number'}
                                                                placeholder={''}
                                                                isReq={true}
                                                                register={register}
                                                                patternRules={'numbers'}
                                                            />
                                                        </div>

                                                        {/**/}
                                                        <div className={'ml-4'}>
                                                            <CheckboxField
                                                                register={register}
                                                                label={'terms_and_condition'}
                                                                fieldName={'terms_and_condition'}
                                                                isReq={true}
                                                                error_msg={errors?.terms_and_condition?.message}
                                                                // defaultVal={}
                                                                // placeholder={}
                                                                // col={}
                                                            />
                                                        </div>
                                                        {/**/}
                                                        <div className={'mt-3'}>
                                                            {loading ? <Loader/> :
                                                                <ButtonField
                                                                    txtColor={colors.white}
                                                                    bgColor={colors.primary_color}
                                                                    btnTxt={'Activate Online Banking'}
                                                                    btnWidth={'100'}
                                                                    btnStyle={'mx-auto col-xl-12 mt-1  pb-2'}/>}
                                                        </div>
                                                    </form>
                                                </>
                                            }
                                        </>
                                }

                        <p className={'text-center pt-1'}>Already have online banking ?
                            <Link
                                to={urls.login} style={{color: colors.primary_color}}>
                                &nbsp;   Login
                            </Link>
                        </p>
                    </div>
                        </div>
                    </section>

                </div>

        </AuthWrapper>


    )
}

export default  InternetBankingNew
