/*
btnWidth : 25, 50, 75,100
 */
const ButtonField = ({btnTxt,bgColor,txtColor,btnWidth, btnadditionStyle, btnStyle,id='btn',btnAdditionalStyle}) => {
    const styles = {
        background: bgColor,
        borderColor: bgColor,
        borderRadius: `50px 50px`,
        color:txtColor,
        padding :`0.8rem 1.5rem`
    }
    return (
        <>
            <button id={id} type={'submit'} className={`btn w-${btnWidth} ${btnStyle}`} style={{...styles, ...btnAdditionalStyle}}>
                {btnTxt}
            </button>
        </>
    )
}
export  default  ButtonField