import { useState } from "react";
import formStyles from "../../../assets/css/formStyle.module.css";

const PasswordField = ({fieldName, isSameAs, maxlength, minlength, placeholder, register, patternRules, label,col,error_msg,isReq,defaultVal,inputStyles}) => {
    const [showPassword, setShowPassword] = useState(false)
    return <>
        <div className="form-group">
            <label className="fw-bold">{label}</label>
            <div className="input-group pr-3"
                 style={{
                    border:`0.05rem solid #ced4da`,
                 borderRadius:"50px 50px",
                 backgroundColor: '#f8f8f8'
                 }}>
                <input
                    type={showPassword ? 'text' :'password'}
                    className={`form-control ${formStyles.passwordTextField} ${error_msg && 'is-invalid'}`}
                    aria-label="passcode"
                    aria-describedby="basic-addon2"
                    id={fieldName}
                    maxLength={maxlength}
                    minLength={minlength}
                    placeholder={placeholder}
                    defaultValue={defaultVal}
                    autoComplete={'off'}
                    autoCorrect={'off'}
                    style={{border:0}}
                    {...register(`${fieldName}`, {
                        required: {
                            value: isReq,
                            message: '*this field is required'
                        },
                        maxLength:{
                            value: maxlength,
                            message: `${maxlength} digits required`
                        },
                        minLength:{
                            value: minlength,
                            message: `${minlength} digits required`
                        },
                         pattern: {
                            // value : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            //first_part+second_part+3rd_part
                            value: patternRules === 'numbers'
                                ?
                                /^[0-9]+$/i
                                : patternRules === 'amount' ?
                                    /^[0-9]+$/i
                                    :
                                    patternRules === 'letters' ?
                                        /^[a-z ]+$/gi :
                                        patternRules === 'letters_numbers' ?
                                            /^[a-zA-Z0-9 ]+$/gi :
                                            patternRules === 'email'?
                                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i :
                                                 patternRules === 'passcode' ? /^\d{6}$/i:
                                                '',
                            message: 'invalid input'
                        },
                        validate: (fieldValue)=>{
                            if (isSameAs){
                                return(
                                    fieldValue === isSameAs || "Passcodes Must Match"
                                )
                            }
                        }
                    })
                    }
                />


                    <div className="input-group-append">
                        <span
                            id="basic-addon2"
                            onClick={()=>setShowPassword(!showPassword)}
                            className={`input-group-text ${formStyles.passwordEyeIcon}`}
                            style={{border:0}}
                        >
                       <img src={!showPassword ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`} alt={''}/>
                    </span>
                    </div>

            </div>
            {error_msg && <div className="invalid-feedback ml-3">{error_msg}</div>}
        </div>

    </>
}

export default PasswordField