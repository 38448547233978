import AdminWrapper from "../AdminWrapper";
import {AdminSuccessModalWrapper, FilterTwo, LinkWrapper, TabWrapper} from "../_component";
import {useDispatch, useSelector} from "react-redux";
import {handleCardManagement, setActiveTabInfo, type} from "../../../config/redux/slice/adminSlice";
import {AddNewLoanType} from "./pages";
import {Link} from "react-router-dom";
// import {colors} from "../../../config/color";
import {useEffect} from "react";
import {loansDatatable} from "../../../config/datatableInit";
import {strText} from "../../../config/string";
import {pagesText} from "../../../config/string/english";

const AdminLoansHome = () => {
    const {
        loanManagement: {loansData, showFormModal, showSuccessModal},
        success_msg,
        successModal
    } = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()

    useEffect(() => {
        loansDatatable(loansData, setLoanDetails)
    }, [])

    const setLoanDetails = (data) => {

    }

    return (
        <AdminWrapper>
            {/*<FilterTwo*/}
            {/*    selectArray={[*/}
            {/*        {val:1, text:'All Loans'},*/}
            {/*        {val:2, text:'Active Loans'},*/}
            {/*        {val:3, text:'InActive Loans'},*/}
            {/*    ]}*/}
            {/*/>*/}

            <div className={'card'}>
                <div className={'card-header'}>
                    <h2 className={'tableHeader'}>Loan Management</h2>
                </div>
                <div className={'card-body'}>
                    <table className={`${strText.datatable_class}`} id={'table_id_loans'}>
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>First name</th>
                                <th>Last name</th>
                                <th>Loan Title</th>
                                <th>Amount</th>
                                <th>Loan Status</th>
                                <th width={'5%'}  data-orderable="false" style={{color:colors.secondary_color}}>Action</th>
                            </tr>
                        </thead>
                    </table>
                </div>

            </div>


            {showFormModal && <AddNewLoanType backdrop={showFormModal}/>}
            {successModal && <AdminSuccessModalWrapper backdrop={successModal} btnText={'Continue'} msg={success_msg}/>}
        </AdminWrapper>
    )
}

export default AdminLoansHome


{/*
                  <TabWrapper
               setTabFunc={handleCardManagement}
               // tabContentClassStyle={}
               // classStyle={}
               tabArray={loanTabArray}
               additionalTabContentStyle={'d-flex justify-content-between mx-3'}
           >
               <Link to={'#'}
                     className={'btn'}
                     style={{background:colors.secondary_color,color:colors.white}}
                   onClick={()=> dispatch(setActiveTabInfo({type:type.loan_type_form,modal:true}))}
               >
                   Add New Loans
               </Link>

           </TabWrapper>
            */}