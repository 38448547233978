import parse from "html-react-parser";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components";
// import { colors } from "../../../config/color";
import { appConfig, endpointUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import {
    onGetLandingPageTermAndCondition,
    setErrorMessage,
    setLoading
} from "../../../config/redux/slice/authData";
import { AuthPageWrapperTwo } from "./pages";


const TermsAndCondition = () => {
    const {colors} = useSelector((state) => state.app)
    const {landing_page_terms_and_condition,loading} = useSelector((state) => state.authData)
    const dispatch = useDispatch()

    const getData = () => {
        let postData = {

        }
        let res = postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getSettings}`,
            ``,
            dispatch,
            setLoading,
            onGetLandingPageTermAndCondition,
            setErrorMessage)
    }


    useEffect(() => {
        getData()
    },[])

    return <AuthPageWrapperTwo  classTitle={'internet_banking'} >
            <div className={'col-sm-12 col-md-8 authLoginWrapperTwo'}>
                <div className={'card p-5 shadow-lg'} style={{maxHeight: '90vh', overflowY: 'auto',marginLeft:'-0%'}}>
                    <div className={""}>
                        <h2 className={'mb-1'} style={{color:colors.primary_color}}> <b>Terms and Conditions</b></h2>
                    </div>
                    {
                        loading ? <Loader/>
                            :
                            <>
                                <div className={'card-header'}>
                                    <b>Email</b> : {landing_page_terms_and_condition?.contact_email} | <b>Phone Number:</b> {landing_page_terms_and_condition?.contact_phone}
                                </div>
                                <div className={'card-body'}>
                                    {  landing_page_terms_and_condition?.terms_and_conditions &&
                                        parse(landing_page_terms_and_condition?.terms_and_conditions)
                                    }
                                </div>
                            </>
                    }
                </div>
            </div>

    </AuthPageWrapperTwo>
}

export  default  TermsAndCondition