// import {colors} from "../../../../../config/color";
import {ButtonField, SelectField, TextInputField} from "../../../../../components/form/components";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {closeFormConfirmationModal, setOperationsForm, type} from "../../../../../config/redux/slice/adminSlice";


const AdminUtilityFormOne = ({register,handleSubmit,errors,reset,backdrop, formData}) => {
    const {providers,packages,utility_type }  = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()

    const onClose = () => {
        dispatch(closeFormConfirmationModal())
    }
    const onContinue = (data) => {
        dispatch(setOperationsForm({data,type:type.utility,resetFunc:reset,current_step: 2}))
    }

    return (
        <>
            <div className="modal fade show" id="basicModal" tabIndex="-1"
                 style={{
                     display: "block",
                     paddingRight: "17px",
                 }} aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content"  style={{ background : colors.inputBg}}>
                        <div className="modal-header" style={{border: "0px"}}>
                            <h5 className="modal-title text-center mx-auto w-100"> </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>onClose()}></button>
                        </div>
                        <div className="modal-body" >
                            <div className={'mx-auto w-75'}>

                                <h5 className={'text-center'}>Details</h5>

                                <div className={' mx-auto w-100'}>
                                    <form onSubmit={handleSubmit(onContinue)}>
                                        <div className={'mb-3'}>
                                            <SelectField
                                                // selectFunc={}
                                                selectStyle={{paddingLeft:0}}
                                                register={register}
                                                isReq={true}
                                                error_msg={errors.utility_type_id?.message}
                                                label={'Utility'}
                                                defaultVal={formData.utility_type_id}
                                                fieldName={'utility_type_id'}
                                            >
                                                <option value={''}>Select utility</option>
                                                {
                                                    utility_type?.map((item,i=0) =>
                                                    <option key={i} value={item?.id}>{item?.name}</option>)
                                                }
                                            </SelectField>
                                        </div>

                                        <div className={'mb-3'}>
                                            <SelectField
                                                // selectFunc={}
                                                selectStyle={{paddingLeft:0}}
                                                register={register}
                                                isReq={true}
                                                error_msg={errors.service_provider_id?.message}
                                                label={'Service Provider Id'}
                                                defaultVal={''}
                                                fieldName={'service_provider_id'}
                                            >
                                                <option value={''}>Select Service Provider</option>
                                                {providers.map((item,i=0) =>
                                                    <option key={i} value={item.id}> {item.name}</option>)}
                                            </SelectField>
                                        </div>
                                        {/*package*/}
                                        <div className={'mb-3'}>
                                            <SelectField
                                                selectStyle={{paddingLeft:0}}
                                                register={register}
                                                isReq={true}
                                                error_msg={errors.package_id?.message}
                                                label={'Package'}
                                                defaultVal={''}
                                                fieldName={'package_id'}
                                            >
                                                <option value={''}>Select Package</option>
                                                {
                                                    packages.map((item,i=0) =>
                                                    <option value={item.id} key={i}>{item.name}</option>)
                                                }
                                            </SelectField>
                                        </div>
                                        {/*amount*/}
                                        <div className={'mb-3'}>
                                            <TextInputField
                                                fieldName={'amount'}
                                                defaultVal={''}
                                                label={'Amount(NGN)'}
                                                error_msg={errors.amount?.message}
                                                isReq={true}
                                                register={register}
                                                placeholder={''}
                                                inputStyles={{paddingLeft:0}}
                                                patternRules={'numbers'}
                                                // onClickFunc={}
                                            />
                                        </div>
                                        {/*Smart card Number*/}
                                        <div className={'mb-3'}>
                                            <TextInputField
                                                fieldName={'smart_card_no'}
                                                defaultVal={''}
                                                label={'Smart Card Number'}
                                                error_msg={errors.smart_card_no?.message}
                                                isReq={true}
                                                register={register}
                                                placeholder={''}
                                                inputStyles={{paddingLeft:0}}
                                                patternRules={'numbers'}
                                                // onClickFunc={}
                                            />
                                        </div>
                                        {/*Account Name*/}
                                        <div className={'mb-5'}>
                                            <TextInputField
                                                fieldName={'account_name'}
                                                defaultVal={''}
                                                label={'Account Name'}
                                                error_msg={errors.account_name?.message}
                                                isReq={true}
                                                register={register}
                                                placeholder={''}
                                                inputStyles={{paddingLeft:0}}
                                                patternRules={'letters'}
                                                // onClickFunc={}
                                            />
                                        </div>

                                        <div className={' d-flex flex-column gap-2'}>
                                            <ButtonField
                                                id={'d'}
                                                // btnAdditionalStyle={}
                                                // btnStyle={}
                                                btnWidth={'100'}
                                                btnTxt={'Continue'}
                                                bgColor={colors.secondary_color}
                                                txtColor={colors.white}/>
                                            {/*    */}
                                            <Link to={'#'} className={'btn w-100'} style={{background:colors.white,color:colors.secondary_color,border:`1px solid ${colors.secondary_color}`}}>
                                                Cancel
                                            </Link>
                                        </div>




                                    </form>
                                </div>
                            </div>

                            <p></p>

                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className={`modal-backdrop fade show  `}></div>}


        </>
    )

}


export default  AdminUtilityFormOne