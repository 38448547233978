import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    clearErrorMsg,
    clearResetBot,
    getAccountOpeningRequest, getAgentAccountOpeningRequest,
    setAccountReqDetails, setAgentAccountReqDetails, setErrorMsg,
    setLoading
} from "../../../config/redux/slice/adminSlice";
import {accountOpeningRequestTable, agentAccountOpeningRequestTable} from "../../../config/datatableInit";
import {postRequest, postRequest2} from "../../../config/redux/request";
import {appConfig, remoteUrl} from "../../../config/config";
import AdminWrapper from "../AdminWrapper";
import AdminSuccessAlert from "../_component/AdminSuccessAlert";
import {RequestInternetBanking} from "./form";
import AccountOpeningDetails from "./details/AccountOpeningDetails";
import {Filter} from "../../_shared_component";
import AgentAccountRequestDetails from "./details/AgentAccountRequestDetails";
import {pageTitle, strText} from "../../../config/string";
import {pagesText} from "../../../config/string/english";
// import {colors} from "../../../config/color";
import AgencyAccountFullDetails from "./details/AgencyAccountFullDetails";


const AgentAccountRequestHome = () => {
    const [showDetailsModal, setShowModalDetails] = useState(false)
    const [detailsData, setDetailsData] = useState('')
    //1
    const {token} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {success_msg,adminErrorMsg} = useSelector((state) =>state.administrator)
    const {systemManagement:{agentRequest:{agentReqData,newAgentReqBot,showRequestForm,showDetails}}} = useSelector((state) =>state.administrator)
    const dispatch = useDispatch()
    //2
    useEffect(()=> {
        dispatch(setAgentAccountReqDetails({modal:false,data:'',isRemark:''}))
        agentAccountOpeningRequestTable(onClickDetailsButton,agentReqData)
        //
        getAccountOpeningReq()
    },[])
    //3
    const onClickDetailsButton = (selectedItem,actionType) => {
        dispatch(clearErrorMsg())
        if(actionType==='remark'){
            dispatch(setAgentAccountReqDetails({modal:true,data:selectedItem,isRemark:'show_remark_form'}))
        }
        else if(actionType==='view_details'){
            setShowModalDetails(true)
            setDetailsData(selectedItem)
        }
        else{
            dispatch(setAgentAccountReqDetails({modal:true,data:selectedItem,isRemark:'no_remark_yet'}))
        }
    }


    //4
    if(newAgentReqBot){
        agentAccountOpeningRequestTable(onClickDetailsButton,agentReqData)
        dispatch(clearResetBot())
    }

    //5
    const getAccountOpeningReq = () => {
        //getAgentAccountReq
        const res =  postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.getAgentAccountReq}`,
            {"jwt": token},
            dispatch,
            setLoading,
            getAgentAccountOpeningRequest,
            setErrorMsg
        )
    }

    const onCloseModal = () => {
        setShowModalDetails(false)
        setDetailsData('')
    }


    if(success_msg==="remark posted successfully" || success_msg==="agency banking approved"){
        getAccountOpeningReq()
    }


    return <AdminWrapper title={''}>
        {/*{ showRequestForm && <RequestInternetBanking backdrop={showRequestForm}/>}*/}

        {showDetailsModal && <AgencyAccountFullDetails agentDetails={detailsData} onClose={onCloseModal} showModal={showDetailsModal}/> }

        {showDetails && <AgentAccountRequestDetails showModal={showDetails}/>}

        <div className={'row'}>
            <div className="col-md-12">
                {/*<Filter/>*/}
                <div className="card px-3">
                    <div className={'card-header py-4'}>
                        <h2 className={'tableHeader'}>{pageTitle.agency_banking_request}</h2>
                    </div>
                    <div className="card-body">
                        {/* Modal */}
                        <table className={`${strText.datatable_class}`} id={'table_id_agent_account__request'}>
                            <thead>
                                <tr>
                                    {/*<th>S/N</th>*/}
                                    <th width={'20%'}>Name</th>
                                    <th width={'15%'}>Account Number</th>
                                    <th>Email Address</th>
                                    <th width={'15%'}>Phone Number</th>
                                    {/*<th>Requested By</th>*/}
                                    <th width={'8%'}>Status</th>
                                    <th width={'16%'}>Requested On</th>
                                    {/*<th width={'3%'}  data-orderable="false" style={{color:colors.secondary_color}}>Process Req.</th>*/}
                                    {/*<th width={'3%'}  data-orderable="false" style={{color:colors.secondary_color}}>Approve Req.</th>*/}
                                    <th width={'3%'}  data-orderable="false" style={{color:colors.secondary_color}}>Action</th>
                                </tr>
                            </thead>

                        </table>
                    </div>
                </div>
            </div>

        </div>
    </AdminWrapper>
}


export  default  AgentAccountRequestHome