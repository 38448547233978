export const onboardUrls = {
    'landingPage' : '/',
    // 'login' : '/onboard/sign-in',
    'login' : '/',
    'passcode' : '/onboard/passcode',
    'create_passcode' : '/onboard/create-passcode',
    'passcode_reset' : '/onboard/passcode/reset',
    'passcode_reset_new' : '/onboard/passcode/reset/new',
    'otp_passcode_reset' : '/onboard/passcode/reset/otp',
    'create_account' : '/onboard/create-account',
    'otp_screen' : '/onboard/otp',



    'getStarted' : '/onboard/getting-started',
    'createAccount' : '/onboard/create-account',

    'createAccountNew' : '/onboard/create-account/auth',

    'onlineAccountOpening' : '/onboard/online-banking',
    'agencyAccountOpening' : '/onboard/agency-banking',
    'emailAdminEmailVerification' : '/admin/email-verification',
    'customerEmailVerification' : '/onboard/auth/email-verification',

    'forgotPassword' : '/onboard/forgot-password',
    'passwordReset' : '/onboard/password-reset',
    'onboard_terms_and_condition' : '/terms-and-condition',

}