import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { colors } from "../config/color";
import { onLoadMinimize } from "../config/redux/slice/appSlice";
import { setActivityTiming, setUserRole } from "../config/redux/slice/authData";
import { urls } from "../config/urls/urls";
import { Header, Sidebar } from "./layout";
import ReloginModal from "./layout/ReloginModal";
import { appConfig, endpointUrl } from "../config/config";
import { postRequest2 } from "../config/redux/request";
import { getAllRoles } from "../config/redux/slice/adminSlice";
let x = 'col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-5'

const AppWrapper = ({children, title, mainAppStyle = x, showNormalBreadcrumb = true, role, showNotificationIcon}) => {

    const {token,errorMsg,reLoginModal, userData, bank_token} = useSelector((state) => state.authData)
    const {adminReLogin} = useSelector((state) => state.administrator)
    const {customerReLogin} = useSelector((state) => state.customer)
    const {colors} = useSelector((state) => state.app)
    //
    const [current_time, setCurrentTime] = useState()
    const {loginTime,currentTime} = useSelector((state) => state.authData)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(onLoadMinimize())
        window.addEventListener('click', (event) => {
            checkInActivity()
        })
        if(token===''){navigate(`${urls.landingPage}`)}

    },[])

    const calculateTime = () => {
        let t1 = new Date();
        let dif = Number(t1.getTime()) - Number(loginTime);

        let sec = Math.round(dif/1000)
        return Math.round(sec / 60)
    }

    const checkInActivity = () => {
        const min = calculateTime()
        //
        if (min <= 6) {
            setCurrentTime(min)
            dispatch(setActivityTiming({}))
        }
        //
        else {
            // setCurrentTime(min)
            // dispatch(setActivityTiming({}))
        }

    }


    useEffect(()=>{
        if(current_time >6){

        }       
    }, [current_time])

    useEffect(()=>{
        if(token===''){
            navigate(urls.landingPage)
        }
    }, [token])

    const getRoles = async () => {
        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getRoles}`,
            {"jwt": token, bank_token},
            dispatch,
            '',
            getAllRoles,
            '',
        )
        if(res != undefined && res.status < 400){
            let roles = (res.data.data).filter((role)=>role.id == userData?.user_role) 

            dispatch(setUserRole({name: roles[0]?.role_name}))
        }
    } 
    
    useEffect(()=>{
        getRoles()
    }, [])


    return <>
        <div className="wrapper">
            <Header showNotificationIcon={showNotificationIcon}/>
            <Sidebar/>

            <div className="main-panel">
                <div className="content">
                    <div className="page-inner">
                        <div className="page-header">
                            <h4 className="mt-4 page-title" style={{color:colors.secondary_color}}>{title}</h4>
                        </div>

                        {children}
                    </div>

                </div>
            </div>
            {reLoginModal && <ReloginModal showLoginModal={reLoginModal}/>}
            {adminReLogin && <ReloginModal showLoginModal={adminReLogin}/>}
            {customerReLogin && <ReloginModal showLoginModal={customerReLogin}/>}
            {/*<ApplicationCustomSettings/>*/}
        </div>

    </>
}

export default AppWrapper