//py-5 px-4
const CardWrapper = ({
                         children, cardTitle, cardWidth, cardWrapperStyle, cardTitleColor, showShadow = false,
                         cardStyle = {
                             background: '#fff',
                             borderRadius: '8px'
                         }
                     }) => {


    return (
        <div style={cardStyle} className={`${cardWrapperStyle} ${showShadow && 'shadow'}`}>
            <h5 style={{color: cardTitleColor}}> {cardTitle} </h5>
            <div className={cardWidth}>
                {children}
            </div>
        </div>

    )
}

export default CardWrapper

