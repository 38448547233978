
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { clearMsg, resetTopUpFormData } from "../../../../config/redux/slice/customerSlice";
import CustomerWrapper from "../../CutomerWrapper";
import EmptyState from "../../EmptyState";

const TopUpHome = () => {
    const {register, handleSubmit, getValues, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {
        generalModal,
        successModal,
        formResetFunc,
    } = useSelector((state) => state.customer)
    const dispatch = useDispatch()

    const onSuccessModalClose = () => {
        formResetFunc()
        dispatch(resetTopUpFormData())
        dispatch(clearMsg())
    }


    return (<CustomerWrapper title={'Top up'}>

                <div className={'row'}>
                    <EmptyState/>
                </div>
                {/*<TopUp/>*/}
                <div className={'row'}>


                    {/*{generalModal.show && <ConfirmTopUp backdrop={generalModal?.backdrop} msg={generalModal?.msg}/>}*/}
                    {/*{*/}
                    {/*    successModal.show &&*/}
                    {/*    <SuccessAlertModal*/}
                    {/*        imgUrl={'../assets/images/success.gif'}*/}
                    {/*        msg={successModal.msg}*/}
                    {/*        backdrop={successModal.backdrop}*/}
                    {/*        onClose={onSuccessModalClose}*/}

                    {/*    />*/}
                    {/*}*/}

                </div>


        </CustomerWrapper>)
}

export default TopUpHome