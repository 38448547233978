import { useDispatch, useSelector } from 'react-redux'
// import { colors } from '../../../../config/color'
import { DetailsItem } from '../../customers/components'
import styles from '../../../../assets/css/formStyle.module.css'
import { Link } from 'react-router-dom'
import { handleCardManagement } from '../../../../config/redux/slice/adminSlice'

const CardInActive = () => {
	const {
		cardManagements: { cardsData, current_tab },
	} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
	const dispatch = useDispatch()
	return (
		<>
			<div style={{ background: 'white' }}>
				{/*
             {
                cardsData.length > 0 && cardsData.map((item, i = 0) =>
                    <div key={i} className="accordion accordion-flush px-3 mb-3" id={`accordionFlushExample_${i}`}>
                        <div className="accordion-item">
                            <table className={'table table-bordered my-0'} style={{background: colors.white}}>
                                <tbody>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.name} </td>
                                    <td>{item.card_number}</td>
                                    <td>{item.expiry_date}</td>
                                    <td>{item.account_number}</td>
                                    <td>
                                        <h2 className="accordion-header" id={`flush-headingOne_${i}`}>
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target={`#flush-collapseOne_${i}`}
                                                    aria-expanded="true"
                                                    aria-controls={`flush-collapseOne_${i}`}>
                                            </button>
                                        </h2>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div style={{background: colors.background, marginTop: 0}} id={`flush-collapseOne_${i}`}
                                 className="accordion-collapse collapse px-3 py-2"
                                 aria-labelledby={`flush-headingOne_${i}`} data-bs-parent={`#accordionFlushExample_${i}`}>
                                <div className="accordion-body" style={{background: `1px solid ${colors.background}`}}>

                                    <div className={'d-flex justify-content-between py-2'}>

                                        <div className={'d-flex  gap-2'}>
                                            <img src={item.profile_image} alt={''} height={'70'}/>
                                            <div className={'d-flex flex-column justify-content-center'}>
                                                <DetailsItem keyData={'Account Name'} value={`${item.account_name} `}/>
                                                <DetailsItem keyData={'Account Number'} value={`${item.account_number}`}/>
                                                <DetailsItem keyData={'Phone Number'} value={`${item.phone_number}`}/>
                                                <DetailsItem keyData={'Email Address'} value={`${item.email_address}`}/>
                                            </div>
                                        </div>
                                    </div>


                                    <div className={`pt-3 d-flex justify-content-between ${styles.borderBottom}`}>
                                        <div className={'d-flex flex-column justify-content-between gap-1'}>
                                            <DetailsItem keyData={'Card Number'} value={`${item.card_number}`}/>
                                            <DetailsItem keyData={'Expiry Date'} value={`${item.expiry_date}`}/>
                                        </div>

                                        <div className={'d-flex gap-2'}>
                                            <div>
                                                <div>
                                                    <DetailsItem keyData={'Account Category'}
                                                                 value={`${item.account_category}`}/>
                                                    <DetailsItem keyData={'Account Class'} value={item.account_class}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={'mx-3'}>
                                            {
                                                item.is_blocked
                                                    ?
                                                    <Link to={''} className={'btn px-4'} style={{
                                                        background: colors.secondary_color,
                                                        color: colors.white
                                                    }}> Blocked </Link> :
                                                    <>
                                                        <Link to={''} className={'btn'} style={{
                                                            background: colors.background,
                                                            color: colors.secondary_color,
                                                            border: `1px solid ${colors.secondary_color}`,
                                                        }}>
                                                            Retrieve Pin
                                                        </Link>
                                                        <span> </span>
                                                        <Link onClick={()=>dispatch(handleCardManagement({modal:true,type:current_tab}))}
                                                              to={''} className={'btn px-4'} style={{
                                                            background: colors.secondary_color,
                                                            color: colors.white
                                                        }}> Block </Link>
                                                    </>
                                            }
                                        </div>

                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
            */}
			</div>
		</>
	)
}
export default CardInActive
