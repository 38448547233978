import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    loading: false,
    currentStep: 1,
    formOneData: {},
    formTwoData: {},
    formThreeData: {},
    formFourData: {},
    formFiveData: {},
}

export const authSlice = createSlice({
    name: 'authApp',
    initialState,
    reducers: {
        setLoading: (state,action) => {

            state.loading = true
        },
        stepOne: (state, action) => {
            state.formOneData = action.payload
            state.currentStep = 2
        },
        stepTwo: (state, action) => {
            //populate step one
            state.formTwoData = action.payload
            state.currentStep = 3
        },
        resetFormData : (state, action) => {
            state.currentStep = 1
            state.formOneData = {}
            state.formTwoData = {}
            state.formThreeData = {}
        }


    },
})

// Action creators are generated for each case reducer function
export const {stepOne, stepTwo, resetFormData

} = authSlice.actions

export default authSlice.reducer