
const Loader2 = () => {
    return (
        <>
            {/*<div className="d-flex justify-content-center mb-5">*/}
            {/*    <div className="lds-facebook">*/}
            {/*        <div></div>*/}
            {/*        <div></div>*/}
            {/*        <div></div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="lds-ring2">
                <div></div>
                {/* <div></div>
                <div></div>
                <div></div> */}
            </div>
            {/*<div style={{border : `1px solid ${colors.secondary_color}`,padding:'7px', color: colors.secondary_color}}>*/}
            {/*    <div className={'col-1 mx-auto justify-content-center'}>*/}
            {/*        <div className="spinner-border text-small" role="status"></div>*/}
            {/*    </div>*/}

            {/*</div>*/}
        </>

      )
}
export default  Loader2