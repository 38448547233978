import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
// import { colors } from "../../../../config/color";
import { strText } from "../../../../config/string";
import { urls } from "../../../../config/urls/urls";

const AuthPageWrapperTwo = ({children ,classTitle}) => {
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    return (
    <div style={{height:'100vh',overflowX:'hidden'}} className={`${classTitle}`}>
        <div className={"row admin_auth_row_container"}> 
                <div className={'mt-3 ml-3'}>
                    <Link to={urls.landingPage}>
                        <img src={strText.app_logo} width='200' alt={''}/>
                    </Link>
                </div>
            
                    <div className={`${authSize.three}`}>
            <div className="card shadow-lg bg-white pb-3 py-4 px-md-5 px-3 " style={{border:`0.05rem solid ${colors.primary_color}`}}>
                {children}
            </div>
        </div>

        </div>
      
       </div>
    )
}

export default AuthPageWrapperTwo