
const AdminDashboardCardTwo = ({bgColor,cardMainTitle,cardSubTitle,iconName,helperText=''}) => {
    return <>
        <div className="col-sm-6 col-md-3" title={helperText} style={{background:'#fafbfc'}}>
            <div className="card card-stats card-round" style={{background:'#fafbfc'}}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-2">
                            <div className="icon-big text-center">
                                <i className={iconName} style={{fontSize:'20px'}}></i>
                            </div>
                        </div>
                        <div className="col col-stats">
                            <div className="numbers">
                                <p className="card-category admin_dashboard_main_text">{cardMainTitle}</p>
                                <h4 className="card-title">{cardSubTitle}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export  default  AdminDashboardCardTwo