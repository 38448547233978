import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Loader } from "../../../../components";
import { ButtonField, TextInputField } from "../../../../components/form/components";
// import { colors } from "../../../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../../../config/config";
import { postRequest2 } from "../../../../config/redux/request";
import {
    changeModalSize,
    clearAll,
    onCustomerEmailVerificationRequest,
    onCustomerLogin,
    setErrorMessage,
    setLoading
} from "../../../../config/redux/slice/authData";
import { onCreateEmail, setSetupModal } from "../../../../config/redux/slice/customerSlice";
import AlertWrapper from "../../../_shared_component/AlertWrapper";
import { OtpComponent } from "../../../v2/components";


const SetEmailAddress = ({backdrop}) => {
    // Check LoginNew Component for reference
    const [togglePassword, setTogglePassword] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)
    const {
        loading,
        errorMsg,
        success_msg,
        tempEmail,
        userData
    } = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        setEmailAddress
    } = useSelector((state) => state.customer)

    const {register, handleSubmit, watch, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [isEmailVerified, setEmailVerified] = useState(false)
    const [userEmail, setUserEmail] = useState(userData?.email)
    const [successMessage, setSuccessMessage] = useState(false)
    const dispatch = useDispatch()
    const emailValue = watch('email');

    useEffect(()=> {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
        // if(userEmail && userEmail != ''){
        //     handleClick()
        // }
    },[])
 
    const closeAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const closeSuccessAlert = () => {
        setSuccessMessage(false)
    }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        let newData = {...data, phone: userData.phone_no}
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.setup_email}`,
            newData,
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )

        if(res !== undefined && res.status < 400){
            dispatch(onCustomerLogin(res.data.data))
            dispatch(setSetupModal({type: 'email', state: false}))
            dispatch(onCreateEmail())
        }

    }
    //
    return (
        <>
            <div className="modal fade show"
                 id="basicModal"
                 tabIndex="-1" aria-hidden="true"
                 style={{
                     display: "block",
                     paddingRight: "17px"
                 }}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header flex-column  pb-0" style={{border: "0px"}}>
                            <h4 className=" text-center mx-auto w-100 py-2"> Set Up your Email Address</h4>
                            <p className=" text-center mx-auto w-100 py-2">Please set up your email address to receive notifications via email</p>
                        </div>

                        <div className="modal-body pt-0" >
                    <fieldset>
                        <form className={'px-5 py-4'} onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
                            {
                                successMessage &&
                                <AlertWrapper onClick={closeSuccessAlert} type={'success'}>
                                    <div className={'py-1 text-capitalize'}>
                                        Email Address Updated Successfully
                                    </div>
                                </AlertWrapper>
                            }
                            {
                                errorMsg &&
                                <AlertWrapper onClick={closeAlert} type={'error'}>
                                    <div className={'py-1 text-capitalize'}>
                                        {errorMsg}
                                    </div>
                                </AlertWrapper>
                            }
                            <div className={'row mb-2'}>
                                <div className={'col-xl-12'}>
                                    <TextInputField
                                        fieldName={'email'}
                                        setValueFunc={setValue}
                                        error_msg={errors.email?.message}
                                        col={'col-xl-12'}
                                        label={'Email'}
                                        placeholder={''}
                                        isReq={true}
                                        register={register}
                                        defaultVal={''}
                                        patternRules={'email'}
                                        id={'email'}
                                    />
                                </div>
                            </div>

                            <div className={'row mb-3'}>
                                <div className={'col-xl-12'}>
                                    <TextInputField
                                        fieldName={'email_confirmation'}
                                        setValueFunc={setValue}
                                        error_msg={errors.email_confirmation?.message}
                                        col={'col-xl-12'}
                                        label={'Confirm Email'}
                                        placeholder={''}
                                        isReq={true}
                                        register={register}
                                        defaultVal={''}
                                        patternRules={'email'}
                                        isSameAs={emailValue}
                                    />
                                </div>
                            </div>

                            <div className={''}>
                                {loading ? <Loader/> :
                                    <ButtonField
                                        txtColor={colors.white}
                                        bgColor={colors.primary_color}
                                        btnTxt={'CONTINUE'}
                                        btnWidth={'100'}
                                        btnStyle={'mx-auto col-xl-12 py-2 mt-2'}/>}
                            </div>
                        </form>

                    </fieldset>
                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className="modal-backdrop fade show"></div>}
        </>
    )
}


export default SetEmailAddress
