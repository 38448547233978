import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Loader } from "../../components";
import { ButtonField } from "../../components/form/components";
// import { colors } from "../../config/color";
import { appConfig, remoteUrl } from "../../config/config";
import { postRequest2 } from "../../config/redux/request";
import {
    auth_types,
    clearAll,
    onForgotPassword,
    setAuthModalForm, setErrorMessage,
    setLoading,
    setTempEmail
} from "../../config/redux/slice/authData";
import { strText } from "../../config/string";
import { urls } from "../../config/urls/urls";
import AlertWrapper from "../_shared_component/AlertWrapper";
import AuthWrapper from "./AuthWrapper";
import { PhoneNumberComponent } from "./components";

const ForgotPasswordNew = () => {
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {loading,success_msg,errorMsg} = useSelector((state) => state.authData)
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(setAuthModalForm({type:auth_types.show_forgot_password_form,modal:false}))
    }
    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        dispatch(setTempEmail(data.email))

        const res = await  postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.forgotPass}`,
            data,
            dispatch,
            setLoading,
            onForgotPassword,
            setErrorMessage,
        )
    }
    //when success_message is set:: redirect to reset password page
    if(success_msg===strText.forgot_password_success_message){
        // dispatch(setAuthModalForm({modal:true,type:auth_types.show_reset_password_form}))
        return <Navigate to={urls.passwordReset}/>

    }

    return (
        <AuthWrapper  scrollable={'hidden'}>
            <div className={`internet_banking_new`} style={{height:'100vh',overflowX:'hidden'}}>
                <section className="mt-5 justify-content-center align-items-center " >
                    <div className={'card shadow-lg bg-white pb-3 pt-5 px-3 col-sm-1 col-md-5 mx-auto '} >
                        <div className="tittle text-center">
                            <h2 className={''} style={{color:colors.primary_color}}>
                                <b>Forgot Password </b>
                            </h2>
                        </div>
                        <form className={'px-3 py-5'} onSubmit={handleSubmit(onSubmit)}>

            {errorMsg &&
                <div className={'alert alert-danger text-danger mx-3 mb-5'}>{errorMsg }</div>}
            {success_msg && <AlertWrapper type={'success'}> {success_msg } </AlertWrapper> }
            <div className={''}>
                <small id="emailHelp2" className="form-text text-muted mb-2">Enter your online banking email and we will send a reset code to you.</small>
                <div className={''}>

                    <PhoneNumberComponent
                        isReq={true}
                        // selectStyle={}
                        error_msg={errors.email?.message}
                        register={register}
                        label={'Phone Number'}
                        defaultVal={''}
                        fieldName={'phone_number'}
                        nextCompId={'btn'}
                    />
                    <small>
                        Already have an account? <u><Link  style={{color:colors.primary_color}} to={urls.login}>Login</Link> </u>
                    </small>

                </div>


                <div className={'mt-4 text-center'}>
                    {loading ? <Loader/> :
                        <ButtonField id={'btn'} btnWidth={'100'} btnStyle={'h-100 py-2'} btnTxt={'Send Code'}
                                     bgColor={colors.primary_color} txtColor={colors.white}/>
                    }
                </div>
            </div>


        </form>
                    </div>
                </section>
            </div>
        </AuthWrapper>
    )

}

export  default  ForgotPasswordNew