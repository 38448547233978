import AdminWrapper from "../AdminWrapper";
import {useDispatch, useSelector} from "react-redux";
import {FilterTwo, LinkWrapper, TabWrapper} from "../_component";
import {urls} from "../../../config/urls/urls";
import {
    clearResetBot, getAllRoles,
    handleRoleManagement,
    setRoleUpdateModal,
    setActiveTabInfo, setErrorMsg, setLoading,
    showRoleFormFunc,
    type
} from "../../../config/redux/slice/adminSlice";
// import {colors} from "../../../config/color";
import {DetailsItem} from "../customers/components";
import {Link, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {rolesDatatable} from "../../../config/datatableInit";
// import AdminUserDetailsModal from "./AdminUserDetailsModal";
// import {AddNewUser} from "../index";
// import {AddAdmin} from "./form";
import {postRequest, postRequest2} from "../../../config/redux/request";
import {appConfig, endpointUrl, remoteUrl} from "../../../config/config";
import {strText} from "../../../config/string";
// import ActivateDeactivateAdminUserModal from "./ActivateDeactivateAdminUserModal";
import AdminSuccessAlert from "../_component/AdminSuccessAlert";
import {pagesText} from "../../../config/string/english";
import { AddNewRole, UpdateRoles } from "./form";


const AdminRoleManagement = () => {
    const {adminRoleManagement: {rolesData, refreshRoles, newData, showRoleUpdateModal, showRoleForm},success_msg} = useSelector((state) => state.administrator)
    const {userData:{modules, permissions}, token,user_role,permissionRoles,moduleRoles} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        //
        let canUpdateRoles = permissions?.includes("CanUpdateUserRole")
        rolesDatatable(rolesData,setRoleDetails, canUpdateRoles)
        getRoles()

    },[])    

    useEffect(() => {
        //
        if(refreshRoles){
            let canUpdateRoles = permissions?.includes("CanUpdateUserRole")
            rolesDatatable(rolesData,setRoleDetails, canUpdateRoles)
            getRoles()
        }
        dispatch(clearResetBot())

    },[refreshRoles])


    const getRoles = async () => {
        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getRoles}`,
            {"jwt" : token},
            dispatch,
            setLoading,
            getAllRoles,
            setErrorMsg,
        )
    }


    const setRoleDetails = (data) => {
        //:: :: :: :: ::  :: ::
        dispatch(setRoleUpdateModal({details: data, modal: true}))
        // dispatch(handleRoleManagement({
        //     type:type.set_role_details_system,
        //     data:data,
        //     modal : true
        // }))
    }

    // useEffect(()=>{
    //         if(!modules?.includes("User Role Management")){
    //         navigate(`${urls.adminDashboard}`)
    //     }      
          
    // }, [])    

    useEffect(()=>{
        if(newData){
            let canUpdateRoles = permissions?.includes("CanUpdateUserRole")
            rolesDatatable(rolesData,setRoleDetails, canUpdateRoles)
            dispatch(clearResetBot())
        }

        // if(success_msg===strText.update_admin_status){
        //     getAdmins()
        // }

        if(success_msg===strText.role_added_successfully){
            getRoles()
        }        
    }, [newData, success_msg])

    return (
        <AdminWrapper title={''}>

            {showRoleForm && <AddNewRole backdrop={showRoleForm}/> }
            {showRoleUpdateModal && <UpdateRoles backdrop={showRoleUpdateModal}/> }
            <div>
                {/*<FilterTwo selectArray={[]} />*/}
                <div className={'card'}>
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <h2 className={'tableHeader'}>{pagesText.role_management}</h2>
                            {
                               permissions?.includes("CanAddUserRole")
                                    ?
                                    <button
                                        style={{color:colors.white,background:colors.secondary_color}}
                                        className="btn  ml-auto"
                                        onClick={()=> dispatch(showRoleFormFunc({modal:true}))}
                                    >
                                        <i className="fa fa-plus mr-1" />
                                        {pagesText.admin_role_add}
                                    </button>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    {/* <div className={'card-body'}> */}
                        <table className={`${strText.datatable_class}`} id={'table_id_roles'}>
                            <thead>
                            <tr>
                                <th width={'12%'}>Role Name</th>
                                <th width={'36%'}>Available Menu</th>
                                <th>Permissions</th>
                                <th width={'9%'}  data-orderable="false" style={{color:colors.secondary_color}}> Action</th>

                            </tr>
                            </thead>



                        </table>
                    {/* </div> */}

                </div>

            </div>

            {/* :::: ::: */}
            {/*{showAminDetailsForm && <AdminUserDetailsModal backdrop={showAminDetailsForm}/>}*/}
            {/* {showAminDetailsForm && <ActivateDeactivateAdminUserModal backdrop={showAminDetailsForm}/>} */}

        </AdminWrapper>
    )
}
export default AdminRoleManagement