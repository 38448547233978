import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Loader } from "../../components";
import { ButtonField } from "../../components/form/components";
// import { colors } from "../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../config/config";
import { postRequest, postRequest2 } from "../../config/redux/request";
import { logoutAdmin, onAdminLogin } from "../../config/redux/slice/adminSlice";
import {
    auth_types,
    changeModalSize,
    clearAll, logoutUser, onCustomerEmailVerificationRequest,
    onCustomerLogin2,
    onGetTenantId,
    setAuthModalForm,
    setErrorMessage,
    setLoading
} from "../../config/redux/slice/authData";
import { logoutCustomer, onCustomerLoginMe } from "../../config/redux/slice/customerSlice";
import { pagesText } from "../../config/string/english";
import { urls as onBoardUrls, urls } from "../../config/urls/urls";
import AlertWrapper from "../_shared_component/AlertWrapper";
import { PhoneNumberComponent } from "../onboard_one/components";
import AuthWrapperCustomer from "../v1/onboard/components/AuthWrapperCustomer";


const LoginScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    // Check LoginNew Component for reference
    const [togglePassword, setTogglePassword] = useState(false)
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        loading,
        showLoginModal,
        user_role,
        errorMsg,
        success_msg,
        tempEmail,
        token,
        userData
    } = useSelector((state) => state.authData)

    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [isEmailVerified, setEmailVerified] = useState(false)
    const dispatch = useDispatch()

    useEffect(()=> {
        // dispatch(clearAll({}))
        dispatch(setErrorMessage({message: errorMsg}))
        dispatch(changeModalSize({type:''}))
    },[])

    const closeAlert = () => {
        dispatch(setErrorMessage({message: null}))
    } 

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        let phone = data.code + data.mobile;
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.auth_first_step}`,
            {phone: phone},
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )
        if(res !== undefined){

            if(res.data.message == "Account exists on SSO, kindly enter your Passcode" && res.data.data !== null){
                 dispatch(onCustomerLogin2({type: '', data:{phone: phone, type: 'sso', user: res.data.data}}))
                 navigate(`${onBoardUrls.passcode}`)
            }else if(res.data.message == "Enter your Passcode"){
                dispatch(onCustomerLogin2({type: '', data:{phone: phone, type: 'user'}}))
                navigate(`${onBoardUrls.passcode}`)
            }else if(res.data.message == "user does not exist"){
                dispatch(onCustomerLogin2({type: '', data:{phone: phone}}))
                navigate(`${onBoardUrls.createAccount}`)
            }
        }
    }
    //
    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }
    //

    useEffect(()=>{
        if (success_msg !== "" && isEmailVerified) {
            // dispatch(setAuthModalForm({modal: true, type: auth_types.show_email_verification_form}))
            navigate(urls.customerEmailVerification)
        }

        if (token!=='' && user_role === 'customer') {
            dispatch(onAdminLogin())
            dispatch(onCustomerLoginMe())
            dispatch(setAuthModalForm({type:auth_types.show_login_form,modal:false}))
            if(userData.account_numbers.length>0){
                navigate(urls.customerDashboard)
            }
            else{
                dispatch(logoutUser())
                dispatch(logoutAdmin())
                dispatch(logoutCustomer())
                dispatch(setErrorMessage({message:'Your account is yet to be activated. You will get an email notification once your account is activated.'}))
            }

        }

        if (token!=='' && user_role != 'customer') {
            dispatch(logoutUser())
            dispatch(logoutCustomer())
            dispatch(logoutAdmin())
            dispatch(setAuthModalForm({type:auth_types.show_login_form,modal:false}))
            // dispatch(setErrorMessage({message:'Customer does not exist.'}))

            navigate(urls.login)
        }
    }, [success_msg, token, user_role])

    //
    return <AuthWrapperCustomer title='Online Banking Login'>    
        <fieldset>
            <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
                {
                    (errorMsg == "Account exists on SSO, kindly enter your Passcode" || errorMsg == "user does not exist") ? 
                    (null) : errorMsg ?
                    (<AlertWrapper onClick={closeAlert} type={'error'}>
                        <div className={'py-1 text-capitalize'}>
                            {errorMsg === "Cannot read properties of undefined (reading 'account_number')"
                                ?
                                "Your account request is still pending, you will get a email notification upon approval."
                                :
                                errorMsg === 'verify email first' ?
                                    <div className="">

                                        Your email needs to be verified before you can use online banking.
                                        <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}>
                                            <span className={'text-primary'}> <u> Click here </u> </span>
                                        </Link>to verify.
                                    </div>
                                    :
                                    errorMsg === 'Request failed with status code 429' ?
                                        'To many Request. Please wait for 15min' : errorMsg
                            }
                        </div>
                    </AlertWrapper>) : null
                }
                <div className={'row mb-2'}>
                    <div className={'col-xl-12'}>
                        <PhoneNumberComponent
                            fieldName={'mobile'}
                            setValueFunc={setValue}
                            error_msg={errors.mobile?.message}
                            col={'col-xl-12'}
                            label={'Phone Number'}
                            nextCompId={'password'}
                            placeholder={''}
                            isReq={true}
                            register={register}
                            defaultVal={''}
                            patternRules={''}
                        />
                    </div>
                </div>

                <div className={''}>
                    {loading ? <Loader/> :
                        <ButtonField
                            txtColor={colors.white}
                            bgColor={colors.primary_color}
                            btnTxt={'Continue'}
                            btnWidth={'70'}
                            btnStyle={'mx-auto col-xl-12 py-2'}/>}
                </div>
            </form>
        </fieldset>
    </AuthWrapperCustomer>
}


export default LoginScreen
