import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../../assets/css/formStyle.module.css";
import { Loader } from "../../../../components";
import { ButtonField } from "../../../../components/form/components";
// import { colors } from "../../../../config/color";
import { appConfig, remoteUrl } from "../../../../config/config";
import { initCustomerTokenizedDepositAccountStatement } from "../../../../config/datatableInit";
import { returnFormattedDate } from "../../../../config/redux/actions";
import { postRequest, postRequest2 } from "../../../../config/redux/request";
import {
    onGetAccountStatementTransactionData,
    resetDataTableBot,
    setAccountStatementModal, setCustomerErrorMsg,
    setLoading
} from "../../../../config/redux/slice/customerSlice";
import { strText } from "../../../../config/string";

const TokenizedDepositAccountStatementModal = ({show}) => {
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const {register, handleSubmit, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {account_statement_data,loading,newAccountStatementHistBot,error_msg,showAccountStatementModal} = useSelector((state) => state.customer)
    const {userData,token} = useSelector((state) => state.authData)
    const [filterDate, setFilterDate] = useState({
        fromDate : '',
        toDate : ''
    })

    const onClose = () => {
        dispatch(setAccountStatementModal({modal:false}))
    }


    useEffect(() =>{
        initCustomerTokenizedDepositAccountStatement(account_statement_data,userData,filterDate)
    },[])


    const getAccountSettlement = async (data) => {
        setFilterDate({
            fromDate : data?.start_date,
            toDate : data?.end_date
        })
        let newDate = returnFormattedDate(data?.start_date,data?.end_date)
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.getCustomerTokenizedTransactionWithDate}`,
            {
                "jwt" : token,
                "email": userData.email,
                "account_number" : userData.account_number,
                "start_date": newDate.fromDate,
                "end_date": newDate.toDate,
            },
            dispatch,
            setLoading,
            onGetAccountStatementTransactionData,
            setCustomerErrorMsg
        )
    }

    const printPage = (el) => {
        let restore_page = document.body.innerHTML;
        let print_content = document.getElementById(el).innerHTML;
        document.body.innerHTML = print_content;
        window.print();
        document.body.innerHTML = restore_page;
    }

    useEffect(()=>{
        if(newAccountStatementHistBot){
            initCustomerTokenizedDepositAccountStatement(account_statement_data?.all_tokenized,userData,filterDate)
            dispatch(resetDataTableBot())
        }
    }, [newAccountStatementHistBot])

    return <>
        <div className="modal  fade show" id="exampleModalCenter" style={{display:"block"}} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content px-2 py-2" style={{maxHeight: '90vh', overflowY: 'auto'}}>
                    <div className="modal-header" style={{border: "0px"}}>
                        <h2 style={{color:colors.secondary_color}}>Tokenized Deposit</h2>
                        <button type="button" className="close" data-dismiss="modal"  aria-label="Close" onClick={() => onClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div>
                            <form onSubmit={handleSubmit(getAccountSettlement)}>
                                <div className={'row py-4'}>
                                    <div className={'col-md-4'}>
                                        <label>Start Date*</label>
                                        <input type={'date'}
                                               defaultValue={''}
                                               id={'start_date'}
                                               {...register('start_date',{
                                                   required:{
                                                       message:'',
                                                       value:true
                                                   }
                                               })}
                                               className={`form-control  ${styles.textField}`}
                                        />
                                    </div>

                                    <div className={'col-md-4'}>
                                        <label>End Date*</label>
                                        <input type={'date'}
                                               defaultValue={''}
                                               id={'end_date'}
                                               {...register('end_date',{
                                                   required:{
                                                       message:'',
                                                       value:true
                                                   }
                                               })}
                                               className={`form-control  ${styles.textField}`}
                                        />
                                    </div>

                                    <div className={'col-md-4'}>
                                        <label>&nbsp;</label>
                                        {loading && showAccountStatementModal ? <Loader/> :
                                            <ButtonField
                                                btnStyle={'py-2'}
                                                // btnAdditionalStyle={}
                                                id={'search'}
                                                txtColor={colors.white}
                                                bgColor={colors.secondary_color}
                                                btnWidth={'100'}
                                                btnTxt={'Apply'}
                                            />
                                        }
                                    </div>
                                </div>

                            </form>
                        </div>

                            <div>
                                <div id={"print_page"}>
                                    <table className={`${strText.datatable_class} mt-5`} id={'tokenized_account_statements'}>
                                        <thead>
                                        <tr>
                                            <th>Deposit Account</th>
                                            <th> Expected Amount </th>
                                            <th> Actual Amount </th>
                                            <th> Token </th>
                                            <th>Date   </th>
                                            <th> Status </th>
                                            <th>Narration  </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                    </div>
                </div>
            </div>
        </div>

        {show &&  <div className="modal-backdrop fade show"></div>}

    </>
}


export  default  TokenizedDepositAccountStatementModal