import AdminWrapper from "../../AdminWrapper";

const ActiveCustomer = () => {
    return (
        <div>
            <h1>Let go</h1>
        </div>
    )
}

export default  ActiveCustomer