const EmptyState = () => {
    return <>
        <div className={'mx-auto'}>
            <img
                alt={''}
                src={'/_assets/new/coming-soon.gif'}
                width={'350'}
            />
        </div>

    </>
}
export default  EmptyState