

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../../../../assets/css/formStyle.module.css";
import { Loader } from "../../../../components";
import { ButtonField } from "../../../../components/form/components";
// import { colors } from "../../../../config/color";
import { appConfig, remoteUrl } from "../../../../config/config";
import { initCustomerTokenizedDeposit } from "../../../../config/datatableInit";
import { returnFormattedDate } from "../../../../config/redux/actions";
import { postRequest2 } from "../../../../config/redux/request";
import {
    clearTransactionHistoryDetailsData,
    getTokenizedDepositTransaction,
    resetDataTableBot, setAccountStatementModal,
    setCustomerErrorMsg,
    setGeneralModal,
    setLoadingTwo, setSingleTokenizedDetails,

    setTransactionDate
} from "../../../../config/redux/slice/customerSlice";
import { respMsg } from "../../../../config/response_messages";
import { strText } from "../../../../config/string";
import { pagesText } from "../../../../config/string/english";
import { urls } from "../../../../config/urls/urls";
import { TokenizedDepositDetails } from "../../../_shared_component";
import CustomerWrapper from "../../CutomerWrapper";
import TokenizedDepositAccountStatementModal from "./TokenizedDepositAccountStatementModal";



const TokenizedDeposit = () => {
    const {colors} = useSelector((state) => state.app)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const navigate = useNavigate()

    const {
        tokenized_histories,
        generalModal,
        tokenized_details,
        newTransactionHistBot,
        tokenizedHistoryFilter,
        loading_,
        error_msg,success_msg,showAccountStatementModal,
        tokenized_total_expected,
        tokenized_total_actual,
        tokenized_difference
    } = useSelector((state) => state.customer)

    const {userData, token} = useSelector((state) => state.authData)
    const dispatch = useDispatch()


    const onClose = () => {
        dispatch(clearTransactionHistoryDetailsData())
    }

    const showTransactionDetails = (data) => {
        dispatch(setGeneralModal({
            show: true,
            msg: '',
            backdrop: true
        }))
        dispatch(setSingleTokenizedDetails(data))
    }

    const getTokenizeHistory = async (data) => {
        const filterDate = returnFormattedDate(data?.start_date,data?.end_date)

        dispatch(setTransactionDate({
            type: 'set_transaction_transfer_date',
            filterDate: {start_date:data.start_date , end_date: data.end_date}}))

        let url = data?.url ? data.url : remoteUrl.getCustomerTokenizedTransactionWithDate
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${url}`,
            {
                "account_number": userData.account_number,
                "email": userData.email,
                "start_date": filterDate.fromDate,
                "end_date": filterDate.toDate,
            },
            dispatch,
            setLoadingTwo,
            getTokenizedDepositTransaction,
            setCustomerErrorMsg
        )
    }

    const triggerAccountStatement = () => {
        dispatch(setAccountStatementModal({modal:true}))
    }


    useEffect(() => {
            dispatch(resetDataTableBot())
            initCustomerTokenizedDeposit(tokenized_histories, showTransactionDetails,'all',triggerAccountStatement,openNewTokenizedDepsoitPage,
                [pagesText.customer_export_text,'NEW DEPOSIT',tokenized_total_expected,tokenized_total_actual,tokenized_difference]
            )
            getTokenizeHistory({
                    start_date: tokenizedHistoryFilter?.start_date,
                    end_date: tokenizedHistoryFilter?.end_date,
                })
        }
        , [])


    const openNewTokenizedDepsoitPage = () => {
        navigate(`${urls.tokenizedDepositNew}`)
        // return <Navigate to={urls.tokenizedDepositNew}/>
    }

    useEffect(()=>{
        if (newTransactionHistBot) {
            initCustomerTokenizedDeposit(tokenized_histories, showTransactionDetails,'all',triggerAccountStatement,openNewTokenizedDepsoitPage,
                [pagesText.customer_export_text,'NEW DEPOSIT',tokenized_total_expected,tokenized_total_actual,tokenized_difference])
            dispatch(resetDataTableBot())
        }
    }, [newTransactionHistBot])

    useEffect(()=>{
        if (error_msg === 'not found') {
            // dispatch(resetCustomerTransactionHistory())
        }
    }, [error_msg])

    useEffect(()=>{
        if(success_msg=== respMsg.update_tokenized_deposit_s){
            getTokenizeHistory({
                start_date: tokenizedHistoryFilter?.start_date,
                end_date: tokenizedHistoryFilter?.end_date,
                url: remoteUrl.getCustomerTokenizedTransactionWithDate
            })
        }
    }, [success_msg])

    return <CustomerWrapper title={''} showNormalBreadcrumb={true}>
        {showAccountStatementModal && <TokenizedDepositAccountStatementModal show={showAccountStatementModal}/>}
        <div>

        </div>


        <div className="row">
            <div className={'mt-2 col-xl-12'}>

                <div className={'card py-1 px-2'}>
                    <div className={'card-header'}>
                        <div className={'row'}>
                            <div className={'col-4'}>
                                <h2 style={{color:colors.secondary_color}}>Tokenized Deposits</h2>
                            </div>

                            <div className={'col-8'}>
                                <form onSubmit={handleSubmit(getTokenizeHistory)}>
                                    <div className={'row'}>
                                        <div className={'col-md-4'}>
                                            <input type={'date'}
                                                   defaultValue={tokenizedHistoryFilter?.start_date ?? ''}
                                                   {...register('start_date')}
                                                   className={`form-control  ${styles.textField}`}
                                            />
                                        </div>

                                        <div className={'col-md-4'}>
                                            <input type={'date'}
                                                   defaultValue={tokenizedHistoryFilter?.end_date ?? ''}
                                                   {...register('end_date')}
                                                   className={`form-control  ${styles.textField}`}
                                            />
                                        </div>


                                        <div className={'col-md-4'}>
                                            {loading_ ? <Loader/> :
                                                <ButtonField
                                                    btnStyle={'py-2'}
                                                    // btnAdditionalStyle={}
                                                    id={'search'}
                                                    txtColor={colors.white}
                                                    bgColor={colors.secondary_color}
                                                    btnWidth={'100'}
                                                    btnTxt={'Apply'}
                                                />
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>


                    </div>
                    <table className={`${strText.datatable_class}`} id={'tokenize_transaction'}>
                        <thead>
                                <tr>
                                    <th > Deposit Account </th>
                                    <th> Expected Amount </th>
                                    <th> Actual Amount </th>
                                    <th> Token </th>
                                    <th width={'15%'} > Date </th>
                                    <th> Status </th>
                                    <th width={'9%'} data-orderable="false" style={{color:colors.secondary_color}}> Action </th>
                                </tr>
                        </thead>
                        <tbody></tbody>
                    </table>
                </div>

            </div>

            {generalModal?.show && <TokenizedDepositDetails onClose={onClose} show={generalModal?.show}
                                                                transactionDetails={tokenized_details}/>}
        </div>
    </CustomerWrapper>
}
export default TokenizedDeposit