import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import formStyles from "../../../assets/css/formStyle.module.css";
import { Loader } from "../../../components";
import { ButtonField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, endpointUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import {
    clearMessages,
    onChangePasscode,
    setCustomerErrorMsg,
    setLoading
} from "../../../config/redux/slice/customerSlice";
import { strText } from "../../../config/string";
import { customerUrls } from "../../../config/urls/customerUrls";
import CustomerWrapper from "../CutomerWrapper";

const CustomerChangePasscode = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const {register, handleSubmit, setValue, getValues, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {token, userData} = useSelector((state) => state.authData)
    const {success_msg,loading} = useSelector((state) => state.customer)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const submitData = async (data) => {
        dispatch(clearMessages())
        // const postData = {
        //     "new_pin": data.new_pin,
        //     "security_answer": data.security_answer
        // }
        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.changePasscode}`,
            {...data, phone: userData.phone_no},
            dispatch,
            setLoading,
            onChangePasscode,
            setCustomerErrorMsg
        )
        if(res !== undefined && res.status < 400){
            setValue('new_passcode', '')
            setValue('new_passcode_confirmation', '')
            setValue('old_psscode', '')
            reset()
            navigate(customerUrls.profileHome)
        }
    }

    return (
        <CustomerWrapper title={''}>
    <div className={'mx-auto col-sm-12 col-md-8 mt-5'}>           
        <div className={'mx-auto col-sm-10 col-md-10 col-lg-8 col-xl-10 mt-2 py-1'}>
            <form onSubmit={handleSubmit(submitData)}>
                <div className={'mx-auto w-100'}>
                    <div className={'card  px-sm-0 px-md-5 py-4 '}>
                        <div className={'card-header mb-2'}>
                            <div className={'d-flex justify-content-between'}>
                                <h3 style={{color: colors.secondary_color}}><strong>Change Passcode</strong></h3>
                            </div>
                        </div>
                        <div className={'card-body'}>
                    <div className={'row mb-3 px-sm-0 px-md-5'}>
                        <div className={'col-sm-12 col-xl-12'}>
                            <div className="form-group">
                                <label>Enter Old Passcode</label>
                                <div className="input-group pr-3" style={{border: `0.05rem solid #ced4da`,
                                backgroundColor: '#f8f8f8',
                                 borderRadius:'50px'}}>
                                    <input
                                        type={showOldPassword ? 'text' : 'password'}
                                        className={`form-control ${formStyles.textField} ${errors.new_pin?.message && 'is-invalid'}`}
                                        aria-label=""
                                        aria-describedby="basic-addon2"
                                        placeholder={''}
                                        defaultValue={''}
                                        autoComplete={'off'}
                                        maxLength={6}
                                        minLength={6}
                                        autoCorrect={'off'}
                                        style={{border: 0}}
                                        {...register(`${'old_passcode'}`, {
                                            required: {
                                                value: true,
                                                message: '*this field is required'
                                            },
                                            maxLength: {value: 6, message: 'Passcode should be 6 digit'},
                                            minLength: {value: 6, message: 'Passcode should be 6 digit'
                                            }
                                        })
                                        }
                                    />
                                    <div className="input-group-append">
                                        <span
                                            id="basic-addon2"
                                            onClick={() => setShowPassword(!showOldPassword)}
                                            className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                            style={{border: 0}}
                                        ><img src={!showOldPassword ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`} alt={''}/></span>
                                    </div>

                                </div>
                                {errors.old_passcode?.message && <div className="text-danger">{errors.old_passcode?.message}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={'row mb-3 px-sm-0 px-md-5'}>
                        <div className={'col-sm-12 col-xl-12'}>
                            <div className="form-group">
                                <label>Enter New Passcode</label>
                                <div className="input-group pr-3" style={{border: `0.05rem solid #ced4da`, 
                                backgroundColor: '#f8f8f8',
                                borderRadius:'50px'}}>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className={`form-control ${formStyles.textField} ${errors.new_pin?.message && 'is-invalid'}`}
                                        aria-label=""
                                        aria-describedby="basic-addon2"
                                        placeholder={''}
                                        defaultValue={''}
                                        autoComplete={'off'}
                                        maxLength={6}
                                        minLength={6}
                                        autoCorrect={'off'}
                                        style={{border: 0}}
                                        {...register(`${'new_passcode'}`, {
                                            required: {
                                                value: true,
                                                message: '*this field is required'
                                            },
                                            maxLength: {value: 6, message: 'Passcode should be 6 digit'},
                                            minLength: {value: 6, message: 'Passcode should be 6 digit'
                                            }
                                        })
                                        }
                                    />
                                    <div className="input-group-append">
                                        <span
                                            id="basic-addon2"
                                            onClick={() => setShowPassword(!showPassword)}
                                            className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                            style={{border: 0}}
                                        ><img src={!showPassword ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`} alt={''}/></span>
                                    </div>

                                </div>
                                {errors.new_passcode?.message && <div className="text-danger">{errors.new_passcode?.message}</div>}
                            </div>
                        </div>
                    </div>    

                    <div className={'row mb-3 px-sm-0 px-md-5'}>
                        <div className={'col-sm-12 col-xl-12'}>
                            <div className="form-group">
                                <label>Confirm Passcode</label>
                                <div className="input-group pr-3" style={{border: `0.05rem solid #ced4da`, 
                                backgroundColor: '#f8f8f8',
                                borderRadius:'50px'}}>
                                    <input
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        className={`form-control ${formStyles.textField} ${errors.new_pin?.message && 'is-invalid'}`}
                                        aria-label=""
                                        aria-describedby="basic-addon2"
                                        placeholder={''}
                                        defaultValue={''}
                                        autoComplete={'off'}
                                        maxLength={6}
                                        minLength={6}
                                        autoCorrect={'off'}
                                        style={{border: 0}}
                                        {...register(`${'new_passcode_confirmation'}`, {
                                            required: {
                                                value: true,
                                                message: '*this field is required'
                                            },
                                            validate: value => value === getValues('new_passcode') || "passcode does not match",
                                            maxLength: {value: 6, message: 'Passcode should be 6 digit'},
                                            minLength: {value: 6, message: 'Passcode should be 6 digit'
                                            }
                                        })
                                        }
                                    />
                                    <div className="input-group-append">
                                        <span
                                            id="basic-addon2"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                            style={{border: 0}}
                                        ><img src={!showConfirmPassword ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`} alt={''}/></span>
                                    </div>

                                </div>
                                {errors.new_passcode_confirmation?.message && <div className="text-danger">{errors.new_passcode_confirmation?.message}</div>}
                            </div>
                        </div>
                    </div>                                                        

                    <div className={'text-center mt-4 px-md-5'}>
                        {loading ?
                            <Loader />
                            :
                            <ButtonField
                            btnTxt={strText.update_label}
                            txtColor={colors.white}
                            bgColor={colors.secondary_color}
                            id={'kk'}
                            // btnAdditionalStyle={}
                            btnWidth={'100'}
                            btnAdditionalStyle={{padding :`0.6rem 1rem`}}
                            // btnStyle={}
                        />}

                    </div>
                        </div>
                        {/**/}
                    </div>
                </div>
            </form>
        </div> 
    </div>      
        </CustomerWrapper>
    )
}

export default  CustomerChangePasscode