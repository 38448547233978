import AdminWrapper from "../../AdminWrapper";
import {AdminUtilityFormOne, AdminUtilityFormTwo} from "./form";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {current} from "@reduxjs/toolkit";
// import {colors} from "../../../../config/color";
import {setActiveTabInfo, type} from "../../../../config/redux/slice/adminSlice";
import {AdminSuccessModalWrapper} from "../../_component";

const AdminUtilityHome = () => {
    const {register, handleSubmit, getValues, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {providers,packages,utility_type }  = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {operationManagement:{utilityFormTwoData,utilityFormOneData,utility_form_step},formConfirmationModal, success_msg ,successModal}  = useSelector((state) => state.administrator)
    const dispatch = useDispatch()
    return (
        <AdminWrapper>
            <div className={'row'}>
                {
                    utility_type.map((item,i=0) =>
                    <div onClick={() => dispatch(setActiveTabInfo({type:type.utility,current_step:1,utility_type_id:item.id}))} className={'col-2 gap-3 py-5  mb-5 mx-2'} style={{background:colors.background}} key={i}>
                        <div className={'d-flex gap-2'}>
                            <img src={'../../../assets/images/admin/icon_utility.svg'} alt={''} height={'20'}/>
                            <h6 className={'text-center text-muted'}>Cable Tv</h6>
                        </div>

                    </div> )}
            </div>

            {
                formConfirmationModal && utility_form_step === 1 && <AdminUtilityFormOne
                register={register}
                handleSubmit={handleSubmit}
                errors={errors}
                reset={reset}
                formData = {utilityFormOneData}
                backdrop={utility_form_step===1}
            />
            }

            {
                formConfirmationModal && utility_form_step===2 && <AdminUtilityFormTwo
                    register={register}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    reset={reset}
                    formData = {utilityFormOneData}
                    backdrop={utility_form_step===2}
                />
            }

            {
                successModal &&
                <AdminSuccessModalWrapper
                    msg={success_msg}
                    backdrop={successModal}
                    btnText={'Continue'}
                />
            }


        </AdminWrapper>
    )
}

export default AdminUtilityHome