import { Route, Routes, useLocation } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import '../src/assets/css/tableStyling.css'
import '../src/assets/css/customStyling.css'
import './App.css'
import { appRoutes } from './config/routes/appRoutes'

import { useEffect } from 'react'
import NotificationsSystem, {
	atalhoTheme,
	setUpNotifications,
	useNotifications
} from 'reapop'
import { appConfig, endpointUrl } from './config/config'
import { postRequest2 } from './config/redux/request'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomisationValues, setDefaultColors, setDefaultIcon } from './config/redux/slice/appSlice'
import { onGetAppKey, onGetTenantId } from './config/redux/slice/authData'
import { base } from './config/map_base'
import { useState } from 'react'
import loading_gif from '../src/assets/images/Spinner-loading.gif'
import { getAppKey } from './config/firebase_config'

const App = () => {
	const { notifications, dismissNotification } = useNotifications()
	const dispatch = useDispatch()
	const location = useLocation();
	const {tenant_id, token, ak} = useSelector((state) => state.authData)
	const [pageLoading, setPageLoading] =  useState(false)
	const [appKey, setAppKey] = useState('')

    const searchParams = new URLSearchParams(location.search);
	const {customisationExists, colorsExists} = useSelector((state) => state.app)

	useEffect(() => {
		const fetchData = async () => {
			if(token == ''){
				setPageLoading(true)
			}
			try {
				const result = await getAppKey();
				setAppKey(result?.token)
				// appConfig.auth_token = result?.token
				// console.log(appConfig.auth_token)
				dispatch(onGetAppKey({id: result?.token}))
				// console.log(result.token);
			} catch (error) {
				// appConfig.auth_token = ''
			}
  		};

  		fetchData();
		setUpNotifications({
			defaultProps: {
				position: 'top-right',
				dismissible: true,
				showDismissButton: true,
			},
		})
	}, [])

	useEffect(()=>{
		if(!tenant_id || tenant_id == ''){
			let baseUrl = window.location.origin
			let tenant = base.filter((option)=>option.base == baseUrl)
			if(tenant.length > 0){
				dispatch(onGetTenantId({id: tenant[0].uuid}))
			}
		}
	}, [tenant_id])

	useEffect(()=>{
		if(!token || token == ''){
			if(appKey != '' && ak != ''){
				onLoadValues()
			}
		}else{
			dispatch(setCustomisationValues())
			dispatch(setDefaultColors())
			dispatch(setDefaultIcon())
		}
	}, [token, appKey, ak])
	

	const onLoadValues = async () => {
		setPageLoading(true)
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getBankInfo}`,
            {},
            dispatch,
            "",
            "",
            "",
			false,
			ak
        )
        if(res !== undefined && res.status < 400){
            if(res.data.data && res.data.data.request_status != 'pending'){
				dispatch(setCustomisationValues(res.data.data))
				dispatch(setDefaultColors())
				dispatch(setDefaultIcon())
			} else{
				dispatch(setDefaultColors())
				dispatch(setDefaultIcon())
			}
			setPageLoading(false)
        }
    }

	return (
		<>
			<NotificationsSystem
				// 2. Pass the notifications you want Reapop to display.
				notifications={notifications}
				// 3. Pass the function used to dismiss a notification.
				dismissNotification={(id) => dismissNotification(id)}
				// 4. Pass a builtIn theme or a custom theme.
				theme={atalhoTheme}
			/>
			{tenant_id != '' && colorsExists ? 
				<Routes>
					{appRoutes.map((appRoute, j = 0) => {
						return <Route key={j} path={appRoute.url} element={appRoute.component} />
					})}
				</Routes> : null
			}
			{
				pageLoading && 
				<div style={{minHeight: '100vh', position: 'absolute', top: 0, zIndex: 1000, backgroundColor: 'white'}} className={`w-100 d-flex flex-column justify-content-center align-items-center`}>
					<img width={60} src={loading_gif} />
					<p className='start-page-loading'>LOADING...</p>
				</div>
			}	
		</>
	)
}

export default App
