import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../../components";
import { ButtonField, TextInputField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import { logoutAdmin, onAdminLogin2 } from "../../../config/redux/slice/adminSlice";
import {
    clearAll, logoutUser, onCustomerEmailVerificationRequest,
    setErrorMessage,
    setLoading, setTempEmail
} from "../../../config/redux/slice/authData";
import { adminUrls } from "../../../config/urls/adminUrls";
import { urls } from "../../../config/urls/urls";
import AlertWrapper from "../../_shared_component/AlertWrapper";
import { PhoneNumberComponent } from "../../onboard_one/components";
import { AuthWrapperAdmin } from "../new_account/pages";
import { useEffect } from "react";
import { logoutCustomer } from "../../../config/redux/slice/customerSlice";

const AdminLogin = () => {
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {loading,errorMsg,success_msg,user_role,token,tempEmail} = useSelector((state) => state.authData)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [isEmailVerified, setEmailVerified] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [mobileNo, setMobileNo] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

        const closeErrorAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const closeSuccessAlert = () => {
        setSuccessMessage(false)
    }

    //Admin Login
    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        dispatch(setTempEmail(data.email))
        let phone = data.code + data.mobile;
        setMobileNo(phone)


      let res = await postRequest2(
          `${appConfig.auth_token}`,
          `${endpointUrl.adminLogin}`,
          {...data, phone},
          dispatch,
          setLoading,
          "",
          setErrorMessage

          )
        if(res !== undefined){
        if(res.data.error_code == 12 || res.data.error_code == 13){
            dispatch(onAdminLogin2({type: '', data:{...data, phone, type: 'new_device'}}))
            navigate(`${adminUrls.adminOtp}`)
        }else if(res.data.error_code == 17){
            sendOtp({...data, phone})
        }
        else if(res.status < 400){
            dispatch(onAdminLogin2({type: '', data:{...data, phone}}))
            navigate(`${adminUrls.adminPasscode}`)
        }
        }
    }

        const sendOtp = async (data) => {
        let newData = {...data}
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.resendOtp}`,
            newData,
            dispatch,
            "",
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400){
            dispatch(onAdminLogin2({type: '', data:{...data, type: 'new_user'}}))
            navigate(`${adminUrls.adminOtp}`)
        }
    }

    //
    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }

    useEffect(()=>{
 
        if (token!=='' && user_role == 'customer') {
            dispatch(logoutUser())
            dispatch(logoutCustomer())
            dispatch(logoutAdmin())
            // dispatch(setErrorMessage({message:'Customer does not exist.'}))

            navigate(adminUrls.adminLogin)
        }
    }, [token, user_role])

    // //
    return <AuthWrapperAdmin classTitle={'admin_login'} title='Admin Login' >
                <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)}>
                    { errorMsg === 'verify email first' ?
                        <AlertWrapper onClick={closeErrorAlert} type={'danger'}>
                            <div className="">
                                Your email needs to be verified before you can use access admin dashboard.
                                <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}> <u>Click here </u></Link>  to verify.
                            </div>
                        </AlertWrapper>
                        : 
                        errorMsg === 'kindly setup your passcode' ? null :
                         errorMsg && <AlertWrapper onClick={closeErrorAlert} type={'error'}>{errorMsg}</AlertWrapper>
                    }

                    <div className="mt-3 login-form">
                         <PhoneNumberComponent
                            fieldName={'mobile'}
                            setValueFunc={setValue}
                            error_msg={errors.mobile?.message}
                            col={'col-xl-12'}
                            label={'Phone Number'}
                            nextCompId={'password'}
                            placeholder={''}
                            isReq={true}
                            register={register}
                            defaultVal={''}
                            patternRules={''}
                        />                       
                        <div>
                        <TextInputField
                            placeholder={''}
                            patternRules={'numbers'}
                            defaultVal={''}
                            error_msg={errors.admin_token?.message}
                            isReq={true}
                            fieldName={'admin_token'}
                            label={'Administrative Token'}
                            register={register}
                        />
                        </div>

                        <div className={'mt-2'}>
                            {/* Using the screens under customer */}
                            <Link
                                // onClick={() => dispatch(setAuthModalForm({
                                //     modal: true,
                                //     type: auth_types.show_forgot_password_form
                                // }))}
                                to={urls.adminForgotPassword}
                                className={'pull-right mb-2'}
                                style={{color: colors.primary_color, fontSize: '12px'}}>Forgot
                                Passcode?</Link>
                        </div>



                        <div className="form-action mb-3">
                            {loading ? <div className="mt-3"><Loader/></div> :
                                <ButtonField
                                    btnStyle={'py-2'}
                                    btnTxt={'Continue'}
                                    btnWidth={'100'}
                                    bgColor={colors.primary_color}
                                    txtColor={colors.white}
                                    id={'d'}
                                    // btnAdditionalStyle={}
                                />
                            }
                        </div>

                        {/* <div className="text-center">
                            <Link to={urls.adminActivationAccount}  style={{color:colors.primary_color}}>
                                Admin Account Activation
                            </Link>
                        </div> */}
                    </div>
                </form>

    </AuthWrapperAdmin>
}
export default AdminLogin