
import CustomerWrapper from "../CutomerWrapper";

const SupportHome = () => {
    return <CustomerWrapper title={'Support'}>
        <div className="row">

        </div>
    </CustomerWrapper>
}
export default SupportHome