import {GeneralModalWrapper} from "../../../_shared_component";
import {Loader} from "../../../../components";
import {ButtonField, TextInputField} from "../../../../components/form/components";
import { useSelector } from "react-redux";
// import {colors} from "../../../../config/color";

const AccountOpeningFullDetails = ({showModal,closeModal,accountRequestDetails}) => {
    const {colors} = useSelector((state) => state.app)
    return ( <GeneralModalWrapper
        modal_title={'Account Opening Request Details'}
        showModal={showModal}
        closeModalFunc={closeModal}>
        <div className={'px-3 py-4'}>
            <table className={'table table-striped'}>
                <thead></thead>
                <tbody>
                <tr>
                    <td>Account Number</td>
                    <td>{accountRequestDetails?.account_number ?? 'N/A'}</td>
                </tr>
                <tr>
                    <td>Firstname</td>
                    <td>{accountRequestDetails?.firstname}</td>
                </tr>
                <tr>
                    <td>Lastname</td>
                    <td>{accountRequestDetails?.lastname}</td>
                </tr>
                <tr>
                    <td>Middle name</td>
                    <td>{accountRequestDetails?.middlename}</td>
                </tr>
                <tr>
                    <td>Customer address</td>
                    <td>{accountRequestDetails?.customer_address}</td>
                </tr>
                <tr>
                    <td>Account type</td>
                    <td>{accountRequestDetails?.account_type}</td>
                </tr>
                <tr>
                    <td>Customer email</td>
                    <td>{accountRequestDetails?.customer_email}</td>
                </tr>
                <tr>
                    <td>Customer phone</td>
                    <td>{accountRequestDetails?.customer_phone}</td>
                </tr>
                <tr>
                    <td>Initiated by</td>
                    <td>{accountRequestDetails?.initiated_by}</td>
                </tr>
                <tr>
                    <td>Request status</td>
                    <td>
                        {accountRequestDetails.request_status === 'pending' ?
                            <small className='badge badge-warning'>pending</small>
                            : accountRequestDetails.request_status === 'approved' ?
                                <small className='badge badge-success'>approved</small> :
                                <small className='badge badge-danger'>N/A</small>}
                    </td>
                </tr>
                </tbody>
            </table>
            {/**/}
        </div>

    </GeneralModalWrapper>)
}

export default  AccountOpeningFullDetails