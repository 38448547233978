import { initializeApp } from "firebase/app";
import { getDatabase, set, ref, onValue } from "firebase/database"

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_APIKEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}`,
  databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASEURL}`,
  projectId: "qapp-world",
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGEBUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_SENDERID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APPID}`,
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app)

export function getAppKey (){  
    return new Promise((resolve, reject) => {
        let value;
        onValue(ref(db, 'appKeys'), snapshot => {
        const data = snapshot.val();
        if (data !== null) {
            value = data;
            resolve(value);
        } else {
            reject("Data is null");
        }
        });
  });
}
