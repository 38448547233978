import {useSelector} from "react-redux";
// import {colors} from "../../../../config/color";
import {DetailsItem} from "../../customers/components";
import {Link} from "react-router-dom";
import styles from '../../../../assets/css/formStyle.module.css'
import {urls} from "../../../../config/urls/urls";

const AgentActive = () => {
    const { agentsManagement:{agentsData} } = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    return <>

        <div style={{background:'white'}}>
            {
                agentsData.length > 0 && agentsData.map((item,i=0) =>
                    <div key={i} className="accordion accordion-flush px-3 mb-3" id={`accordionFlushExample_${i}`}>
                        <div className="accordion-item">
                            <table className={'table table-bordered my-0'} style={{background:colors.white}}>
                                <tbody>
                                <tr>
                                    <td>{i+1}</td>
                                    <td>{item.first_name} {item.last_name}</td>
                                    <td>{item.phone_number}</td>
                                    <td>{item.email}</td>
                                    <td>  <h2 className="accordion-header" id={`flush-headingOne_${i}`}>
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseOne_${i}`}
                                                aria-expanded="true"
                                                aria-controls={`flush-collapseOne_${i}`}>
                                        </button>
                                    </h2></td>
                                </tr>
                                </tbody>
                            </table>
                            {/**/}
                            <div style={{background:colors.background,marginTop:0}} id={`flush-collapseOne_${i}`} className="accordion-collapse collapse px-3 py-5"
                                 aria-labelledby={`flush-headingOne_${i}`} data-bs-parent={`#accordionFlushExample_${i}`}>
                                <div className="accordion-body" style={{background:`1px solid ${colors.background}`}}>
                                    {/*header*/}
                                    <div className={'d-flex justify-content-between py-2'}>
                                        {/**/}
                                        <div className={'d-flex  gap-2'}>
                                            <img src={item.profile_image} alt={''} height={'70'}/>
                                            <div className={'d-flex flex-column justify-content-center'}>
                                                <DetailsItem keyData={'Account Name'} value={`${item.account_name} `} />
                                                <DetailsItem keyData={'Account Number'} value={`${item.account_number}`} />
                                            </div>
                                        </div>
                                        {/**/}
                                        <div className={'d-flex flex-column justify-content-center'}>
                                            <DetailsItem keyData={'Address'} value={`${item.address}`} />
                                        </div>
                                    </div>
                                    {/* accounts details   */}
                                    {item.accounts?.map((items,i=0) =>
                                        <div key={i} className={`py-3 d-flex justify-content-between ${styles.borderBottom}`}>
                                            <div className={'d-flex flex-column justify-content-between gap-1'}>
                                                <DetailsItem keyData={'Device'} value={`${items.device_name}`} />
                                                <DetailsItem keyData={'IMEI Number'} value={`${items.imb_number}`} />
                                            </div>

                                            <div className={'d-flex gap-2'}>
                                                <div>
                                                    <div>
                                                        <DetailsItem keyData={'Registration Status'} value={`${items.registration_status}`} />
                                                        <DetailsItem keyData={'Account Balance'} value={`${Number(items.account_balance).toLocaleString()}`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        <Link
                                            to={urls.adminAgentView}
                                            state={{data:item}}
                                            className={'btn float-end '} style={{background:colors.secondary_color,color:colors.white}}>View Details</Link>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </div>

    </>
}

export  default  AgentActive