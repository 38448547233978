import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import formStyles from "../../../../assets/css/formStyle.module.css";
import { ButtonField } from "../../../../components/form/components";
// import { colors } from "../../../../config/color";

const Password = () => {
    const {formOneData, formTwoData} = useSelector((state) => state.auth)
    const {colors} = useSelector((state) => state.app)
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        formState: {errors},
        reset
    } = useForm({mode: 'onTouched'})

    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const onSubmit = (data) => {


    }


    return (
        <div className={'mx-auto w-50 mt-1 py-5 px-4 shadow-none'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={'row mt-3 mb-3'}>
                    {/* password */}
                    <div className={'col-md-12 mb-3'}>
                        <div className="form-group">
                            <label>Password*</label>
                            <div className="input-group"
                                 style={{border: `0.05rem solid #ced4da`, borderRadius: "50px"}}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    className={`form-control ${formStyles.passwordTextField}  ${errors.password?.message && 'is-invalid'}`}
                                    aria-label=""
                                    aria-describedby="basic-addon2"
                                    placeholder={''}
                                    defaultValue={formOneData?.password ?? ''}
                                    autoComplete={'off'}
                                    autoCorrect={'off'}
                                    style={{border: 0}}
                                    {...register(`password`, {
                                        required: {
                                            value: true,
                                            message: '*this field is required',
                                        },
                                        minLength: {
                                            value: 6,
                                            message: 'password too short'
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: 'password cant be greater than 6 digits'
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/i
                                        }
                                    })
                                    }
                                />
                                <div className="input-group-append">
                                                        <span
                                                            id="basic-addon2"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                                            style={{border: 0}}
                                                        >
                                                            <img src={'../../assets/images/admin/auth/open_wide.svg'}
                                                                 alt={''}/>
                                                        </span>
                                </div>
                            </div>
                            {errors.password && <div className="text-danger">{errors.password?.message}</div>}
                        </div>
                    </div>


                    {/*Confirm Password :: */}
                    <div className={'col-md-12 mb-3'}>
                        <div className="form-group">
                            <label>Confirm password*</label>
                            <div className={`input-group`}
                                 style={{border: `0.05rem solid #ced4da`, borderRadius: "50px 50px"}}>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    className={`form-control ${formStyles.passwordTextField}  ${errors.confirm_password?.message && 'is-invalid'}`}
                                    placeholder={''}
                                    defaultValue={formOneData?.confirm_password ?? ''}
                                    autoComplete={'off'}
                                    autoCorrect={'off'}
                                    style={{border: 0}}
                                    {...register(`confirm_password`, {
                                        required: {
                                            value: true,
                                            message: '*this field is required',

                                        },
                                        minLength: {
                                            value: 6,
                                            message: 'password too short'
                                        },
                                        maxLength: {
                                            value: 6,
                                            message: 'password cant be greater than 6 digits'
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/i
                                        },
                                        validate: value => value === getValues('password') || "The passwords do not match"
                                    })
                                    }
                                />


                                <div className="input-group-append">
                                                        <span
                                                            id="basic-addon2"
                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                            className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                                            style={{border: 0}}
                                                        >
                                                            <img src={'../../assets/images/admin/auth/open_wide.svg'}
                                                                 alt={''}/>
                                                        </span>
                                </div>
                            </div>
                            {errors.confirm_password &&
                                <div className="text-danger">{errors.confirm_password?.message}</div>}
                        </div>
                    </div>


                    <div className={'col-md-12'}>
                        <ButtonField
                            btnTxt={'Create Password'}
                            id={'create_btn_pin'}
                            txtColor={colors.white}
                            // btnAdditionalStyle={}
                            // btnStyle={}
                            bgColor={colors.primary_color}
                            btnWidth={'100'}
                        />
                    </div>

                </div>
            </form>
        </div>
    )
}

export default Password