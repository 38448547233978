import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../../components";
import { ButtonField, PasswordField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import {
    clearAll,
    onCustomerEmailVerificationRequest,
    setErrorMessage,
    setLoading
} from "../../../config/redux/slice/authData";
import { urls as onBoardUrls } from "../../../config/urls/urls";
import AlertWrapper from "../../_shared_component/AlertWrapper";
import { AuthWrapperAdmin } from "../new_account/pages";

const AdminPasswordReset = () => {
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {loading,errorMsg,success_msg,user_role,token,tempEmail} = useSelector((state) => state.authData)
    const {register, watch, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const [successMessage, setSuccessMessage] = useState(false)
    const [isEmailVerified, setEmailVerified] = useState(false)
    const passcodeValue = watch('passcode');

    const closeErrorAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const closeSuccessAlert = () => {
        setSuccessMessage(false)
    }

    useEffect(() => {
    //    const token =  decryptToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjo2LCJ1c2VyX25hbWUiOiJtaWtlQGdtYWlsLmNvbSIsInJvbGUiOiJub3JtYWxfdXNlciIsImVtYWlsIjoibWlrZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6MCwicGhvbmUiOiIwODAzNjE3NjEzMiIsImZpcnN0bmFtZSI6Ik1pa2UiLCJtaWRkbGVuYW1lIjpudWxsLCJsYXN0bmFtZSI6IkFkb2dhIiwiY3JlYXRlZF9hdCI6IjIwMjItMTEtMTNUMTk6MTE6NTYuMDAwWiJ9LCJpc3MiOiJRQXBwIFRlY2giLCJpYXQiOjE2Njg2NDE5ODIsImV4cCI6MTY2ODcyODM4Mn0.W8GLGNXmpV6l7qHbM13hZnamBhf1deh90jI7kXpkdUg')
    },[])

    const phone = useSelector((state) => state?.authData?.userRegDetails?.phone)
    const otp = useSelector((state) => state?.authData?.userRegDetails?.otp)

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        let newData = {...data, phone: phone, otp: otp}
        
        let passcodeUrl = `${endpointUrl.passcode_reset}`

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            passcodeUrl,
            newData,
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )

        if(res !== undefined && res.status < 400 ){
            setSuccessMessage(true)
            const timer = setTimeout(() => {
                setSuccessMessage(false)
                navigate(`${onBoardUrls.adminLogin}`);
            }, 4000);
        }

    }

    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }    


   return( 
    <AuthWrapperAdmin classTitle={'admin_login'} title='Reset Passcode' >
            <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
                {
                    successMessage &&
                    <AlertWrapper onClick={closeSuccessAlert} type={'success'}>
                        <div className={'py-1 text-capitalize'}>
                            Passcode Reset Successfully
                        </div>
                    </AlertWrapper>
                }
                {
                    errorMsg &&
                    <AlertWrapper onClick={closeErrorAlert} type={'error'}>
                        <div className={'py-1 text-capitalize'}>
                            {errorMsg === "Cannot read properties of undefined (reading 'account_number')"
                                ?
                                "Your account request is still pending, you will get a email notification upon approval."
                                :
                                errorMsg === 'verify email first' ?
                                    <div className="">

                                        Your email needs to be verified before you can use online banking.
                                        <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}>
                                            <span className={'text-primary'}> <u> Click here </u> </span>
                                        </Link>to verify.
                                    </div>
                                    :
                                    errorMsg === 'Request failed with status code 429' ?
                                        'To many Request. Please wait for 15min' : errorMsg
                            }
                        </div>
                    </AlertWrapper>
                }
        <div className="mt-3 login-form">                      
            <div>
                <PasswordField
                    fieldName={'passcode'}
                    setValueFunc={setValue}
                    error_msg={errors.passcode?.message}
                    col={'col-xl-12'}
                    label={'New Passcode'}
                    nextCompId={'password'}
                    placeholder={''}
                    isReq={true}
                    maxlength={6}
                    minlength={6}
                    register={register}
                    defaultVal={''}
                    patternRules={'passcode'}
                />
            </div>

            <div className="mt-3">
                <PasswordField
                    fieldName={'passcode_confirmation'}
                    setValueFunc={setValue}
                    error_msg={errors.passcode_confirmation?.message}
                    col={'col-xl-12'}
                    label={'Retype Passcode'}
                    nextCompId={'password'}
                    placeholder={''}
                    isReq={true}
                    maxlength={6}
                    minlength={6}
                    register={register}
                    defaultVal={''}
                    patternRules={'passcode'}
                    isSameAs={passcodeValue}
                />
            </div>


            <div className="form-action mt-3 mb-3">
                {loading ? <div className="mt-3"><Loader/></div> :
                    <ButtonField
                        btnStyle={'py-2'}
                        btnTxt={'Reset Passcode'}
                        btnWidth={'100'}
                        bgColor={colors.primary_color}
                        txtColor={colors.white}
                        id={'d'}
                        // btnAdditionalStyle={}
                    />
                }
            </div>
        </div>

            </form>
</AuthWrapperAdmin>    
   )
}
export  default  AdminPasswordReset