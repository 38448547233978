import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { ButtonField, PasswordField } from "../../components/form/components";
// import { colors } from "../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../config/config";
import { postRequest, postRequest2 } from "../../config/redux/request";
import {
    changeModalSize,
    clearAll,
    logoutUser,
    onCustomerEmailVerificationRequest,
    setErrorMessage,
    setLoading
} from "../../config/redux/slice/authData";
import { urls as onBoardUrls } from "../../config/urls/urls";
import AlertWrapper from "../_shared_component/AlertWrapper";
import AuthWrapperCustomer from "../v1/onboard/components/AuthWrapperCustomer";
import { logoutCustomer } from "../../config/redux/slice/customerSlice";
import { logoutAdmin } from "../../config/redux/slice/adminSlice";


const PasscodeResetScreenTwo = () => {
    const navigate = useNavigate()
    // Check LoginNew Component for reference
    const [togglePassword, setTogglePassword] = useState(false)
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        loading,
        showLoginModal,
        user_role,
        errorMsg,
        success_msg,
        tempEmail,
        token,
        userData
    } = useSelector((state) => state.authData)

    const {register, handleSubmit, getValues, watch, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [isEmailVerified, setEmailVerified] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const dispatch = useDispatch()
    const passcodeValue = watch('passcode');

    useEffect(()=> {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
    },[])

 const phone = useSelector((state) => state?.authData?.userRegDetails?.phone)
const otp = useSelector((state) => state?.authData?.userRegDetails?.otp)

   const closeErrorAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const closeSuccessAlert = () => {
        setSuccessMessage(false)
    }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        let newData = {...data, phone: phone, otp: otp}
        
        let passcodeUrl = `${endpointUrl.passcode_reset}`

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            passcodeUrl,
            newData,
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )

        if(res !== undefined && res.status < 400 ){
            setSuccessMessage(true)
            const timer = setTimeout(() => {
                setSuccessMessage(false)
                navigate(`${onBoardUrls.login}`);
            }, 4000);
        }

    }
    //
    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }

    // useEffect(()=>{
 
    //     if (token!=='' && user_role != 'customer') {
    //         dispatch(logoutUser())
    //         dispatch(logoutCustomer())
    //         dispatch(logoutAdmin())
    //         // dispatch(setErrorMessage({message:'Customer does not exist.'}))

    //         navigate(onBoardUrls.login)
    //     }
    // }, [token, user_role])
    //
    return <AuthWrapperCustomer title='Reset Passcode'>

                <fieldset>
                    <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
                        {
                            successMessage &&
                            <AlertWrapper onClick={closeSuccessAlert} type={'success'}>
                                <div className={'py-1 text-capitalize'}>
                                    Passcode Reset Successfully
                                </div>
                            </AlertWrapper>
                        }
                        {
                            errorMsg &&
                            <AlertWrapper onClick={closeErrorAlert} type={'error'}>
                                <div className={'py-1 text-capitalize'}>
                                    {errorMsg === "Cannot read properties of undefined (reading 'account_number')"
                                        ?
                                        "Your account request is still pending, you will get a email notification upon approval."
                                        :
                                        errorMsg === 'verify email first' ?
                                            <div className="">

                                                Your email needs to be verified before you can use online banking.
                                                <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}>
                                                    <span className={'text-primary'}> <u> Click here </u> </span>
                                                </Link>to verify.
                                            </div>
                                            :
                                            errorMsg === 'Request failed with status code 429' ?
                                                'To many Request. Please wait for 15min' : errorMsg
                                    }
                                </div>
                            </AlertWrapper>
                        }
                        {/*Reset Code*/}
                        <div className={'row mb-2'}>
                            <div className={'col-xl-12'}>
                                <PasswordField
                                    fieldName={'passcode'}
                                    setValueFunc={setValue}
                                    error_msg={errors.passcode?.message}
                                    col={'col-xl-12'}
                                    label={'New Passcode'}
                                    nextCompId={'password'}
                                    placeholder={''}
                                    isReq={true}
                                    maxlength={6}
                                    minlength={6}
                                    register={register}
                                    defaultVal={''}
                                    patternRules={'passcode'}
                                />
                            </div>
                        </div>

                        <div className={'row mb-2'}>
                            <div className={'col-xl-12'}>
                                <PasswordField
                                    fieldName={'passcode_confirmation'}
                                    setValueFunc={setValue}
                                    error_msg={errors.passcode_confirmation?.message}
                                    col={'col-xl-12'}
                                    label={'Retype Passcode'}
                                    nextCompId={'password'}
                                    placeholder={''}
                                    isReq={true}
                                    maxlength={6}
                                    minlength={6}
                                    register={register}
                                    defaultVal={''}
                                    patternRules={'passcode'}
                                    isSameAs={passcodeValue}
                                />
                            </div>
                        </div>

                        <div className={''}>
                            {loading ? <Loader/> :
                                <ButtonField
                                    txtColor={colors.white}
                                    bgColor={colors.primary_color}
                                    btnTxt={'Reset Passcode'}
                                    btnWidth={'100'}
                                    btnStyle={'mx-auto col-xl-12 py-2 mt-3'}/>}
                        </div>
                    </form>
                </fieldset>

                <p className={'text-center'}>
                    <Link
                        to={onBoardUrls.login}
                        style={{color: colors.primary_color}}
                    >
                        Back to Login
                    </Link>
                </p>
    </AuthWrapperCustomer>
}


export default PasscodeResetScreenTwo
