import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components";
import { ButtonField, TextInputField } from "../../../../components/form/components";
import { appConfig, endpointUrl } from "../../../../config/config";
import { postRequest2 } from "../../../../config/redux/request";
// import { colors } from "../../../../config/color";

import { clearAdminErrorMsg, onAddRole, setErrorMsg, setLoading, showRoleFormFunc } from "../../../../config/redux/slice/adminSlice";
import { pagesText } from "../../../../config/string/english";

const AddNewRole = ({ backdrop }) => {
  const { token, user_role } = useSelector((state) => state.authData);
  const {colors} = useSelector((state) => state.app)
  const { loading, temp_error_msg, success_msg } = useSelector((state) => state.administrator);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [realAmount,setRealAmount] =  useState()
  const [maxBalanceInp,setMaxBalanceInp] =  useState('d-block')
    const [realMaxAmount,setRealMaxAmount] =  useState()

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
    setError
  } = useForm({ mode: "onTouched" });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(clearAdminErrorMsg());
    // data.jwt = token
    let x = postRequest2(
      `${appConfig.auth_token}`, 
      `${endpointUrl.addRole}`, 
      data, 
      dispatch, setLoading, 
      onAddRole, 
      setErrorMsg);

    }
  const closeModal = () => {
    dispatch(clearAdminErrorMsg());
    dispatch(showRoleFormFunc({ modal: false }));
  };

  return (
    <>
      <div
        className="modal fade show"
        id="basicModal"
        tabIndex="-1"
        aria-hidden="true"
        style={{
          display: "block",
          paddingRight: "17px",
        }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header" style={{ border: "0px" }}>
              <h3 className=" " style={{ color: colors.secondary_color }}>
                {" "}
                {pagesText.admin_role_add}{" "}
              </h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className={"px-3 py-3"}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'} noValidate>
                        <div style={{columnGap: '1rem'}} className={'row mb-4 d-flex'}>
                            
                        <div className={'row col-md-7'}>

                            <div className={'col-md-12 p-0'}>
                                <TextInputField
                                    // inputStyles={}
                                    fieldName={'role_name'}
                                    setValueFunc={setValue}
                                    error_msg={errors.role_name?.message}
                                    label={'Role Name'}
                                    isReq={true}
                                    register={register}
                                    defaultVal={''}
                                    placeholder={''}
                                    patternRules={''}
                                    // onClickFunc={}
                                />
                            </div>

                        </div>
                             <div className={`row col-md-3 d-flex ${errors.role_name?.message ? 'align-items-center' : 'align-items-end'}`}>
                                <div className={'col-md-12 text-center p-0'}>
                                {loading ? <Loader/> :
                                <ButtonField
                                    id={''}
                                    btnWidth={'100'}
                                    txtColor={colors.white}
                                    bgColor={colors.secondary_color}
                                    btnTxt={pagesText.admin_role_add}
                                    btnAdditionalStyle={{paddingTop: '0.67rem', paddingBottom: '0.67rem'}}
                                    // btnStyle={"pt-2 pb-2"}
                                />}

                            </div>
                        </div>
                            </div>
                    </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {backdrop && <div className="modal-backdrop fade show"></div>}
    </>
  );
};
export default AddNewRole;
