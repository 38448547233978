import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { colors } from "../../../../config/color";

const DashboardCardSummary = ({icon,lead_text,sub_text,canHide,icon_bg,icon_type='icon',icon_color,card_key,linkRef='', isInApp=true,
                                  fontSize='14px', isBold=true}) => {
const {colors} = useSelector((state) => state.app)
const [showBalance, setShowBalance] = useState(false)
    return <>
        <div className="col-sm-12 col-md-6 col-lg-3" key={card_key} >
            {isInApp ? 
            <Link to={linkRef}>
                <div className="card card-stats card-round cardDesign" style={{backgroundImage:`url(/_assets/new/card-bg${card_key}.jpg)`}}>
                    <div className="card-body">
                        <div className="row align-items-center rounded">
                            {icon_type === 'icon' ?
                                <div className="col-icon">
                                    <div  className="icon-big text-center  bubble-shadow-small"
                                          style={{background:icon_bg,borderRadius:'50%',color:icon_color ? icon_color : colors.white}}>

                                        <i className={icon}/>
                                    </div>
                                </div>
                                :
                                <div className="col-icon">
                                    <div className="icon-big text-center  bubble-shadow-small">
                                        <img src={icon} alt={''} height={'31'} width={'31'}/>
                                    </div>
                                </div>
                            }

                            <div className="col col-stats ml-3 ml-sm-0">
                                <div className="numbers">
                                    <div style={{columnGap: '0.5rem', display: 'flex'}}>
                                        <h6 className="card-category" style={{color:colors.primary_color}}>{lead_text}</h6>
                                        {
                                        canHide && 
                                        <img role="button" onClick={()=>{setShowBalance(!showBalance)}} src={showBalance ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`} alt={''}/>
                                        }
                                    </div>
                                    {isBold?
                                        <>
                                        {(showBalance || !canHide) ?
                                        <h4 className="card-title" style={{fontSize:fontSize,color:colors.primary_color}}><strong>{sub_text}</strong>
                                        </h4> :
                                         <h4 className="card-title" style={{fontSize:'1.5rem'}}>******</h4>
                                        }</>
                                        :<>
                                        {(showBalance || !canHide) ?
                                         <h4 className="card-title" style={{fontSize:fontSize}}>{sub_text}</h4> :
                                         <h4 className="card-title" style={{fontSize:'1.5rem'}}>******</h4>
                                        }</>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link> :
            <a href={linkRef} target="_blank" rel="noreferrer">
                <div className="card card-stats card-round cardDesign" style={{backgroundImage:`url(/_assets/new/card-bg${card_key}.jpg)`}}>
                    <div className="card-body">
                        <div className="row align-items-start rounded">
                            {icon_type === 'icon' ?
                                <div className="col-icon">
                                    <div  className="icon-big text-center  bubble-shadow-small"
                                          style={{background:icon_bg,borderRadius:'50%',color:icon_color ? icon_color : colors.white}}>

                                        <i className={icon}/>
                                    </div>
                                </div>
                                :
                                <div className="col-icon">
                                    <div className="icon-big text-center  bubble-shadow-small">
                                        <img src={icon} alt={''} height={'31'} width={'31'}/>
                                    </div>
                                </div>
                            }

                            <div className="col ml-3 ml-sm-0">
                                <div className="numbers">
                                    <div style={{columnGap: '0.5rem', display: 'flex'}}>
                                        <h6 className="card-category" style={{color:colors.primary_color}}>{lead_text}</h6>
                                        {
                                        canHide && 
                                        <img role="button" onClick={()=>{setShowBalance(!showBalance)}} src={showBalance ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`} alt={''}/>
                                        }
                                    </div>
                                    {isBold?
                                        <>
                                        {(showBalance || !canHide) ?
                                        <h4 className="card-title" style={{fontSize:fontSize,color:colors.primary_color}}><strong>{sub_text}</strong>
                                        </h4> :
                                         <h4 className="card-title" style={{fontSize:'1.5rem'}}>******</h4>
                                        }</>
                                        :<>
                                        {(showBalance || !canHide) ?
                                         <h4 className="card-title" style={{fontSize:fontSize}}>{sub_text}</h4> :
                                         <h4 className="card-title" style={{fontSize:'1.5rem'}}>******</h4>
                                        }</>
                                    }
                                </div>
                                <button className="btn mt-2 btn-sm btn-secondary">Visit Help Desk</button>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            }

        </div>
    </>
}

export default  DashboardCardSummary
