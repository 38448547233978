import moment from "moment";
import { useEffect } from "react";
import { Chart } from "react-google-charts";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components";
import { ButtonField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, remoteUrl } from "../../../config/config";
import { NumberFormatter } from "../../../config/redux/actions";
import { postRequest2 } from "../../../config/redux/request";
import {
    clearErrorMsg,
    setAdminDashboardData,
    setDailyPosition,
    setErrorMsg,
    setLoading,
    setLoading_
} from "../../../config/redux/slice/adminSlice";
import { strText } from "../../../config/string";
import { DashboardCardSummary } from "../../customer/dashboard/components";
import AdminWrapper from "../AdminWrapper";
import { AdminDashboardCardTwo } from "./components";



export const options = {
    title: "Total no of Requests ",
    chartArea: { width: "100%" },
};



const AdminDashboard = () => {
    const {register, handleSubmit} = useForm({mode: 'onTouched'})
    const {token, user_role} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        usersSummary,
        dailyPosition,
        loading,pieChartData,barChartData
    } = useSelector((state) => state.administrator)
    const dispatch = useDispatch()



    useEffect(() => {
        getAdminDashboard()
        getDailyPosition()
    }, [])

    const getAdminDashboard = () => {
        //
        dispatch(clearErrorMsg())
        const data = {
            "jwt": token
        }
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.adminDash}`,
            data,
            dispatch,
            setLoading_,
            setAdminDashboardData,
            setErrorMsg
        )
        //

    }

    const getDailyPosition = (data) => {
        dispatch(clearErrorMsg())
        let postData = {
            "jwt": token,
            "date": data?.date ?? moment().format('YYYY-MM-DD')
        }
        let res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.dailyPosition}`,
            postData,
            dispatch,
            setLoading,
            setDailyPosition,
            setErrorMsg)

    }

    //Last updated: 5 minutes ago
    return (
        <AdminWrapper mainAppStyle={'col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12  mx-auto mx-xl-1 mx-xxl-0'} title={''}>

            <div className={'row'}>
                {usersSummary.map((item, i = 0) => (
                    <DashboardCardSummary
                        key={i}
                        card_key={i}
                        sub_text={Number(item?.total??0).toLocaleString()}
                        lead_text={item?.card_title}
                        icon={item.icon}
                        icon_bg={colors.secondary_color}
                        icon_type={'image'}
                        icon_color={'bg-success'}
                        linkRef = {item?.card_link}
                    />
                ))}
            </div>

           <div className={''}>
               <div className={'row'}>
                   <div className={'col-md-9'} style={{margin:0}}>
                       <div className={'row row-card-no-pd'}>
                           <AdminDashboardCardTwo
                               bgColor={'card-info'}
                               cardMainTitle={strText.qApp_charge}
                               cardSubTitle={NumberFormatter(dailyPosition?.qapp_charge_total_for_date?.toFixed(2) ?? 0,2)}
                               // iconName={'/_assets/new/wallet.png'}
                               iconName={'fa fa-credit-card text-dark'}
                               helperText={strText.total_qApp_charge}
                           />
                           <AdminDashboardCardTwo
                               bgColor={'card-primary'}
                               cardMainTitle={strText.transactions_text}
                               cardSubTitle={dailyPosition?.no_of_transactions_for_date??0}
                               // iconName={'/_assets/new/credit-cards.png'}
                               iconName={'fa a-database text-success'}
                               helperText={strText.total_number_of_transactions}
                           />

                           <AdminDashboardCardTwo
                               bgColor={'card-success'}
                               cardMainTitle={strText.total_charges}
                               cardSubTitle={NumberFormatter(dailyPosition?.other_charges_total_for_date?.toFixed(2) ?? 0,2)}
                               // iconName={'/_assets/new/money-bag.png'}
                               iconName={'fa fa-money text-dark'}
                               helperText={strText.total_transaction_others}
                           />

                           <AdminDashboardCardTwo
                               bgColor={'card-secondary'}
                               cardMainTitle={strText.value_text}
                               cardSubTitle={NumberFormatter(dailyPosition?.total_transaction_amount_for_date?.toFixed(2)?? 0,2)}
                               // iconName={'/_assets/new/smartphone.png'}
                               iconName={'fa fa-credit-card text-primary'}
                               helperText={strText.total_transaction_value}
                           />

                       </div>
                   </div>
                   {/*filter section*/}
                   <div 
                   className={'col-md-3 card pt-1 dashboard_filter'} 
                   style={{background:'#fafbfc',marginLeft:'-15px'}}
                   >
                       <div className={'row  '}>
                           <div className={'col-sm-12 col-md-12'}>
                               <div className="">
                                   <form onSubmit={handleSubmit(getDailyPosition)}>
                                       <div className={'row'}>
                                           <div className={'col-lg-12'}>
                                               <input type={'date'} 
                                               className={`form-control amin_dashboard_style mt-2 mb-1`} 
                                               {...register('date')}/>
                                           </div>
                                       </div>

                                       <div style={{width:"100%"}}>
                                           {loading ?
                                             <Loader/>
                                               :
                                               <ButtonField
                                                   id={''}
                                               txtColor={colors.white} 
                                               bgColor={colors.secondary_color} 
                                               btnWidth={'100'}
                                               btnTxt={strText.daily_Position_text} 
                                               btnStyle={'py-1'}
                                               />}
                                       </div>
                                   </form>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>



            {/*graph */}
            <div className={'card'}>
                <div className={'row'}>
                    <div className={'col-sm-6 col-md-6'}>
                        <Chart
                            chartType="ColumnChart"
                            width="100%"
                            height="400px"
                            data={barChartData}
                            options={{
                                title: strText.customer_distribution_by_tiers
                            }}
                        />
                    </div>

                    <div className={'col-sm-6 col-md-6'}>
                        <Chart
                            chartType="PieChart"
                            width={"100%"}
                            height={"400px"}
                            data={pieChartData}
                            options={ {
                                title: strText.customer_distribution_by_tiers,
                                is3D: true,
                            }}/>
                    </div>
                </div>
            </div>
        </AdminWrapper>
    )
}

export default AdminDashboard