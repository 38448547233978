import {useDispatch, useSelector} from "react-redux";
import {
    setTabDetails,
    type,
    setLoading,
    getTransferData,
    setErrorMsg,
    resetFilterDate,
    clearAdminErrorMsg, clearResetBot, setTransactionDate, setLoading_
} from "../../../../config/redux/slice/adminSlice";
import TransactionDetailsItem from "../../../_shared_component/TransactionDetailsItem";
import {TransactionWrapper} from "../../../customer/activity/transaction/component";
import {useEffect} from "react";
import {getRequest, postRequest, postRequest2} from "../../../../config/redux/request";
import {appConfig, endpointUrl, remoteUrl} from "../../../../config/config";
import {initAdminTransaction} from "../../../../config/datatableInit";
import {strText} from "../../../../config/string";
import {ButtonField} from "../../../../components/form/components";
// import {colors} from "../../../../config/color";
import moment from "moment";
import {useForm} from "react-hook-form";
import {Loader} from "../../../../components";
import styles from '../../../../assets/css/formStyle.module.css'
import {returnFormattedDate} from "../../../../config/redux/actions";

const TransactionTransfer = () => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {token} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        transactionsManagement: {transferData, newDataBot, transferHistoryFilter},
        loading
    } = useSelector((state) => state.administrator)
    const dispatch = useDispatch()

    const showTransactionDetails = (data) => {
        dispatch(setTabDetails({data, modal: true, type: type.transaction_transfer}))
    }

    // useEffect(()=>{
    //     dispatch(resetFilterDate())
    // },[])


    useEffect(() => {
        // :: :: ::: ::
        initAdminTransaction(transferData, setTransactionDetails)

        getTransaction({
            start_date: transferHistoryFilter.start_date,
            end_date: transferHistoryFilter.end_date,
        })
        dispatch(resetFilterDate())

    }, [])

    const setTransactionDetails = (data) => {
        showTransactionDetails(data)
    }

    useEffect(()=>{
            if (newDataBot) {
        initAdminTransaction(transferData, setTransactionDetails)
        dispatch(clearResetBot())
        dispatch(resetFilterDate())
    }

    },[newDataBot])

    const getTransaction = (data) => {
        let url = data.url ? data.url : endpointUrl.adminGetTransWithDates


        let filterDate = returnFormattedDate(data.start_date,data.end_date)

        const today = ''
        
        dispatch(setTransactionDate({
            type: type.set_transaction_transfer_date,
            filterDate: {
                start_date: data?.start_date !== '' ? moment(data?.start_date).format('YYYY-MM-DD') : today,
                end_date: data?.end_date !== '' ? moment(data?.end_date).format('YYYY-MM-DD') : today
            }
        }))

        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${url}`,
            {
                'jwt': token,
                "limit": 100,
                "offset": 0,
                "start_date": filterDate.fromDate,
                "end_date": filterDate.toDate
            },
            dispatch,
            setLoading,
            getTransferData,
            setErrorMsg
        )
    }



    return <>
        <div className={''}>

            <div className={'card px-2'}>
                <div className={'card-header'}>
                    <div className={'row'}>
                        <div className={'col-md-4'}>
                            <h2 className={'tableHeader'}>Transactions</h2>
                        </div>
                        <div className={'col-md-8'}>
                            <form onSubmit={handleSubmit(getTransaction)}>
                                <div className={'row d-flex align-items-center px-3'}>
                                    <div className={'col-sm-12 col-md-4'}>
                                        <input
                                            type={'date'}
                                            defaultValue={transferHistoryFilter?.start_date ?? ''}
                                            {...register('start_date')}
                                            className={`form-control ${styles.textField}`}
                                        />
                                    </div>

                                    <div className={`col-sm-12 col-md-4`}>
                                        <input type={'date'}
                                               defaultValue={transferHistoryFilter?.end_date ?? ''}
                                               {...register('end_date')}
                                               className={`form-control ${styles.textField} `}/>
                                    </div>


                                    <div className={' col-sm-12 col-md-4'}>
                                        {loading ? <Loader/> :
                                            <ButtonField
                                                btnStyle={'py-2'}
                                                // btnAdditionalStyle={}
                                                id={'search'}
                                                txtColor={colors.white}
                                                bgColor={colors.secondary_color}
                                                btnWidth={'100'}
                                                btnTxt={'Apply'}
                                            />
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                <div className={'card-body'}>
                    <table className={`${strText.datatable_class}`} id={'table_admin_trans'}>
                        <thead>
                         <tr>
                             <th> Reference</th>
                             <th> Sender Acct</th>
                             <th>Beneficiary Acct</th>
                             <th >Beneficiary Name</th>
                             <th > Beneficiary Bank</th>
                             <th> Amount</th>
                            <th style={{width:"20%"}}> Date</th>
                            <th data-orderable="false" width={'5%'} style={{color:colors.secondary_color}}> Action</th>
                        </tr>
                        </thead>

                        <tbody></tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
}

export default TransactionTransfer