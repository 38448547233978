import {useForm} from 'react-hook-form';
//

import {ButtonField, PinField} from "./form/components";
// import {colors} from "../config/color";
import {Loader} from "./index";
import { useSelector } from 'react-redux';


const PinModule = ({leadTxt,btnTxt,btnWidth,btnBgColor,btnTxtColor,processFunc,loading=false,hasBtn=false,pinWrapperSpacing='mt-3 mb-4'}) => {
    const {register, handleSubmit, formState: {errors}} = useForm({mode: 'onTouched'})
    const {colors} = useSelector((state) => state.app)

    const prevPin = (e,prev_id,next_id, current_id) => {
    if (e.key === 'Backspace' || e.keyCode === 8) {
        e.preventDefault()
            document.getElementById(`${current_id}`).value = '';
            document.getElementById(`${prev_id}`).focus();
    } else {
        // prev_item  :: ::: :::
        document.getElementById(`${current_id}`).type = 'password'
        if(next_id!==''){
            document.getElementById(`${next_id}`).focus();
        } 
    }
    }    

    const onSubmit = (data) => {
        processFunc(data)
    }

    return (
        <div className={''}>
            <h5 style={{fontSize:'16px'}}> {leadTxt} </h5>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`row ${pinWrapperSpacing}`}>
                    <PinField
                        id={'pin_one'}
                        placeholder={'.'}
                        col={'col-3 mt-2'}
                        bgColor={colors?.white}
                        register={register}
                        fieldName={'pin_one'}
                        errors={errors.pin_one?.message}
                        onKeyDown={(e)=>prevPin(e, 'pin_one','pin_two', 'pin_one')}
                    />
                    <PinField
                        id={'pin_two'}
                        placeholder={'.'}
                        col={'col-3 mt-2'}
                        bgColor={colors?.inputBg}
                        register={register}
                        fieldName={'pin_two'}
                        errors={errors.pin_two?.message}
                        onKeyDown={(e)=>prevPin(e, 'pin_one', 'pin_three', 'pin_two')}
                    />

                    <PinField
                        id={'pin_three'}
                        placeholder={'.'}
                        col={'col-3 mt-2'}
                        bgColor={colors?.inputBg}
                        register={register}
                        fieldName={'pin_three'}
                        errors={errors.pin_three?.message}
                        onKeyDown={(e)=>prevPin(e, 'pin_two','pin_four', 'pin_three')}
                    />

                    <PinField
                        id={'pin_four'}
                        placeholder={'.'}
                        col={'col-3 mt-2'}
                        bgColor={colors?.inputBg}
                        register={register}
                        fieldName={'pin_four'}
                        errors={errors.pin_four?.message}
                        onKeyDown={(e)=>prevPin(e, 'pin_three', 'pin_module_btn', 'pin_four')}
                    />
                </div>

                {hasBtn ? '' :

                loading ? <Loader/> :
                    <ButtonField
                    id={'pin_module_btn'}
                    btnWidth={btnWidth}
                    btnTxt={btnTxt}
                    bgColor={btnBgColor}
                    txtColor={btnTxtColor}
                /> }

            </form>
        </div>
    )
}

export default PinModule