import AdminWrapper from "../AdminWrapper";
import AdminSuccessAlert from "../_component/AdminSuccessAlert";
import AdminErrorAlert from "../_component/AdminErrorAlert";
import InternetBankingFullDetails from "./details/InternetBankingFullDetails";
import {RequestInternetBanking} from "./form";
import InternetBankingRequestDetails from "./details/InternetBankingRequestDetails";
import {pagesText} from "../../../config/string/english";
import {strText} from "../../../config/string";
// import {colors} from "../../../config/color";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
    clearErrorMsg,
    clearResetBot, getAdditionalAccountRequest, setAdditionalAccountReqDetails, setErrorMsg,
    setLoading, setNewData
} from "../../../config/redux/slice/adminSlice";
import {additionalAccountOpeningRequestTable} from "../../../config/datatableInit";
import {postRequest, postRequest2} from "../../../config/redux/request";
import {appConfig, remoteUrl} from "../../../config/config";
import AdditionalRequestDetails from "./details/AdditionalRequestDetails";

const AdditionalAccountRequest = () => {
    //1
    const dispatch = useDispatch()
    // dispatch(setNewData())
    const {token} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {success_msg,adminErrorMsg,systemManagement: {additionalAccountReq: {additionalAccReqData, additionalAccReqBot,
        showAdditionalAccountRequestDetails,additionalAccRequestDetails}}} =
        useSelector((state) => state.administrator)



    //2
    useEffect(() => {
        dispatch(setAdditionalAccountReqDetails({modal: false, data: '', isRemark: ''}))
        additionalAccountOpeningRequestTable(onClickDetailsButton, additionalAccReqData)
        //
        getAdditionalAccountReq()
    }, [])
    //3
    const onClickDetailsButton = (selectedItem, action_type) => {
        // dispatch(clearErrorMsg())
        dispatch(setAdditionalAccountReqDetails({modal: true, data: selectedItem, isRemark: ''}))
    }


    //4

    //5
    const getAdditionalAccountReq = () => {
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.getAdditionalAccReq}`,
            {"jwt": token},
            dispatch,
            setLoading,
            getAdditionalAccountRequest,
            setErrorMsg)
    }

    if (additionalAccReqBot) {
        additionalAccountOpeningRequestTable(onClickDetailsButton, additionalAccReqData)
        dispatch(clearResetBot())
    }


    if(success_msg === (strText.req_additional_account_approval|| strText.approve_additional_account)){
        getAdditionalAccountReq()
    }

    return (<AdminWrapper title={''}>

        {
            showAdditionalAccountRequestDetails && <AdditionalRequestDetails showModal={showAdditionalAccountRequestDetails}/>
        }


        <div className={'row'}>
            <div className="col-md-12">
                {/*<Filter/>*/}
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <h2 className={'tableHeader'}>{pagesText.additional_account_request}</h2>

                        </div>
                    </div>
                    <div className="card-body">
                        {/* Modal */}
                        <table className={`${strText.datatable_class}`} id={'table_id_internet_banking_request'}>
                            <thead>
                            <tr>
                                <th width={'15%'}>Email</th>
                                <th width={'15%'}>Account No.</th>
                                <th width={'15%'}>Account Type.</th>
                                <th width={'10%'}>Status</th>
                                <th  width={'12%'} style={{color:colors.secondary_color}}>Req. Date</th>
                                <th width={'7%'} data-orderable="false" style={{color:colors.secondary_color}}>Action</th>
                            </tr>
                            </thead>

                        </table>
                    </div>
                </div>
            </div>

        </div>
    </AdminWrapper>)
}

export  default AdditionalAccountRequest