
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { appConfig, remoteUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import { onGetCustomerDashboard, setCustomerErrorMsg, setLoading } from "../../../config/redux/slice/customerSlice";
import { strText } from "../../../config/string";
import CustomerWrapper from "../CutomerWrapper";
import { ProfileCardWrapper, ProfileItem } from "./components";
import { SmsNotification } from "./forms";
import EmailNotification from "./forms/EmailNotification";

const SettingsHome = () => {

    const {userData,token} = useSelector((state) => state.authData)
    const {customerDashData,success_msg} = useSelector((state) => state.customer)

    const dispatch= useDispatch()

    const getCustomerDashData = () => {
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.customerDash}`,
            {
                "jwt": token,
                "accountno": userData.account_number,
                "email": userData.email
            },
            dispatch,
            setLoading,
            onGetCustomerDashboard,
            setCustomerErrorMsg
        )
    }

    useEffect(()=>{
            if(success_msg ==='sms notification updated successfully' || success_msg==='email notification updated successfully'){
                getCustomerDashData()
            }
    }, [success_msg])

    return (<CustomerWrapper title={''}>

            <div className="row">
                {/* personal information  */}
                <ProfileCardWrapper cardTitle={strText.personal_info_label}>
                    <div className={'col-xl-3'}>
                        <div className="text-center">
                            <div className="profile-photo">
                                <Link to={'#'} className={'nav-link'}>
                                    <img src={userData.profile_image} alt="Profile" className="rounded-circle" width={'100'}/>
                                
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/**/}
                    <div className={'col-xl-3'}>
                        <ol>
                            <ProfileItem lead_text={strText.name_label} sub_text={userData?.full_name}/>
                            <ProfileItem lead_text={strText.email_label} sub_text={userData?.email}/>
                            <ProfileItem lead_text={strText.phone_number_label} sub_text={userData?.phone_no}/>
                        </ol>
                    </div>
                    {/**/}
                    <div className={'col-xl-6'}>
                        <ol>
                            <ProfileItem lead_text={strText.address_label} sub_text={customerDashData?.address ?? 'N/A'}/>
                            {/*<ProfileItem sub_text={strText.identification_label} lead_text={userData?.identification}/>*/}
                        </ol>
                    </div>
                </ProfileCardWrapper>



                <div className={'container-fluid'}>
                    <div className={'row'}>
                        <div className={'col-md-6 col-lg-6 '}>
                            <div className={'card p-5'}>
                                <SmsNotification/>
                            </div>

                        </div>
                        {/*<div className={'col-md-1'}></div>*/}
                        <div className={'col-md-6 col-lg-6'}>
                            <div className={'card p-5'}>
                                <EmailNotification />
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </CustomerWrapper>
    )
}

export default SettingsHome

