import AdminWrapper from "../../AdminWrapper";
import {useSelector} from "react-redux";
import {DetailsItem} from "../components";
// import {colors} from "../../../../config/color";
import {Link} from "react-router-dom";

const AllCustomer = () => {
    const {customerManagement:{customersData}}  = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    return (
        <div  className={''}>
            <table id={'table_id_customer'} className={'table table-condensed'}>
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Action</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>

        </div>
    )
}

export default  AllCustomer