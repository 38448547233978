import {useSelector} from "react-redux";
// import {colors} from "../../../../config/color";
import {DetailsItem} from "../../customers/components";
import {Link} from "react-router-dom";

const LoanActive = () => {
    const {loanManagement: {loansData}} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    return (<>
        <div style={{background: 'white'}}>
            {
                loansData.length > 0 && loansData.map((item, i = 0) =>
                    <div key={i} className="accordion accordion-flush px-3 mb-2" id={`accordionFlushExample_${i}`}>
                        <div className="accordion-item">
                            <table className={'table table-bordered my-0'} style={{background: colors.white}}>
                                <tbody>
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.first_name} {item.last_name}</td>
                                    <td>{item.loan_title}</td>
                                    <td>{item.amount}</td>
                                    <td>
                                        <h2 className="accordion-header" id={`flush-headingOne_${i}`}>
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target={`#flush-collapseOne_${i}`}
                                                    aria-expanded="true"
                                                    aria-controls={`flush-collapseOne_${i}`}>
                                            </button>
                                        </h2>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            {/**/}
                            <div style={{background: colors.background, marginTop: 0}}
                                 id={`flush-collapseOne_${i}`}
                                 className="accordion-collapse collapse px-3"
                                 aria-labelledby={`flush-headingOne_${i}`}
                                 data-bs-parent={`#accordionFlushExample_${i}`}>
                                <div className="accordion-body py-5" style={{background: `1px solid ${colors.background}`}}>
                                    {/*header*/}
                                    <div className={'d-flex justify-content-between py-2'}>
                                        {/**/}
                                        <div className={'d-flex gap-2'}>
                                            <img src={item.profile_image} alt={''} height={'70'}/>
                                            <div className={'d-flex flex-column justify-content-center'}>
                                                <DetailsItem keyData={'Account Name'} value={`${item.account_name}`}/>
                                                <DetailsItem keyData={'Account Number'} value={`${item.account_number}`}/>
                                                <DetailsItem keyData={'Loan Title'} value={`${item.loan_title}`}/>
                                                <DetailsItem keyData={'Amount'} value={`${item.amount}`}/>
                                                <DetailsItem keyData={'Status'}
                                                             value={`${item.is_active ? 'Active' : 'InActive'}`}/>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    {/* accounts details   */}
                                    <div className={'py-3 d-flex justify-content-between'}
                                         style={{borderBottom: `2px solid ${colors.background}`}}>
                                        <div className={'d-flex flex-column justify-content-between gap-2'}>
                                            <DetailsItem keyData={'Total Amount Payable'}
                                                         value={`${item.loan_details.total_amount_payable}`}/>
                                            <DetailsItem keyData={'Amount Paid So Far'}
                                                         value={`${item.loan_details.amount_paid_so_far}`}/>
                                            <DetailsItem keyData={'Balance'} value={`${item.loan_details.balance}`}/>
                                        </div>
                                        <div className={'d-flex gap-2'}>
                                            <div>
                                                <DetailsItem keyData={'Monthly Interest Rate'}
                                                             value={`${item.loan_details.monthly_interest_rate}`}/>
                                                <DetailsItem keyData={'Minimum Tenure'}
                                                             value={`${item.loan_details.minimum_tenure}`}/>
                                                <DetailsItem keyData={'Maximum Tenure'}
                                                             value={`${item.loan_details.maximum_tenure}`}/>
                                            </div>
                                        </div>
                                    </div>
                                    {/*    */}
                                    <div className={'d-flex gap-2 float-end'}>
                                        {item.is_active ?
                                            <>
                                                <Link to={'#'} className={'btn btn-sm'}
                                                      style={{background: colors.background, color: colors.secondary_color,border:`1px solid ${colors.secondary_color}`}}>
                                                    Contact Customer
                                                </Link>

                                                <Link to={'#'} className={'btn btn-sm'}
                                                      style={{background: colors.secondary_color, color: colors.white}}>
                                                    Settle
                                                </Link>
                                            </>
                                            :
                                            <>
                                                <Link to={'#'} className={'btn btn-sm'}
                                                      style={{background: colors.background, color: colors.secondary_color,border:`1px solid ${colors.secondary_color}`}}>
                                                    Decline
                                                </Link>

                                                <Link to={'#'} className={'btn btn-sm'}
                                                      style={{background: colors.secondary_color, color: colors.white}}>
                                                    Approve
                                                </Link>
                                            </>
                                        }


                                    </div>
                                    {/*    */}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>


    </>)
}

export default LoanActive