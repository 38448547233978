import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import medalOne from '../../../assets/images/tier-one-medal.svg';
import medalThree from '../../../assets/images/tier-three-medal.svg';
import medalTwo from '../../../assets/images/tier-two-medal.svg';
import { urls as customerUrls } from "../../../config/urls/urls";
import CustomerWrapper from "../CutomerWrapper";
import Compliance from "../complaince/Compliance";
import { ProfileDetails } from "./index";
import TierOneMedal from "../../_shared_component/TierOneMedal";
import TierTwoMedal from "../../_shared_component/TierTwoMedal";
import TierThreeMedal from "../../_shared_component/TierThreeMedal";

const ProfileHome = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);

     const {user_role,showEnterTransactionPinModal,userData} = useSelector((state) => state.authData)
     const [tierProps, setTierProps] = useState({
                imgLink: medalOne,
                altText: "Tier Icon"
            })

    let [activeTierId, setActiveTierId] = useState(searchParams.get('tier'));

    useEffect(() => {
         if(searchParams.get('tier')){
            setActiveTierId(+searchParams.get('tier'))
         } else if(!searchParams.get('tier')){
            setActiveTierId(null)
         }
    }, [searchParams.get('tier')]); // eslint-disable-line    


    return(
        <CustomerWrapper title={''} showNormalBreadcrumb={false} showNotificationIcon={false}>
            <>
                <div className="page-inner">
                    <h4 className="page-title">My Profile</h4>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card card-profile card-primary">
                                <div className="card-header" style={{backgroundImage: 'url("../assets/img/blogpost.jpg")'}}>
                                    <div className="profile-picture">
                                        <div className="avatar avatar-xl">
                                            <img src={userData.profile_image} alt="..." className="avatar-img rounded-circle" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="user-profile text-center">
                                        <div className="name">{userData?.full_name}</div>
                                        <div className="job">{userData.phone_no} {!userData.email || !userData.phone_no ? null : "|" } {userData.email}</div>
                                        <div className="desc">Account Number: <b>{userData.account_number}</b></div>

                                        <div className="mb-4 px-3">
                                        <div className="d-flex">
                                            <div className="my-2 fw-bold" style={{fontSize: '1rem'}}>You are currently on <span className="badge" style={{fontSize: '0.9rem'}}>
                                                {userData?.current_tier == 1 ?
                                                    <TierOneMedal/> : userData?.current_tier == 2 ?
                                                    <TierTwoMedal/> : userData?.current_tier == 3 ?
                                                    <TierThreeMedal/> : ''
                                                }
                                                Tier {userData?.current_tier}</span></div>
                                        </div>
                                    </div>
                                        {(userData?.current_tier == 2 && userData?.tier_status == 'pending') ||
                                            userData?.current_tier == 3
                                         ?
                                            <div className="view-profile">
                                                <Link to={customerUrls.customerChangePasscode} className="btn btn-primary btn-block">Change Passcode</Link>
                                            </div>                   
                                            :
                                        <div className="row">
                                            <div className="view-profile col-md-6 pr-md-1">
                                                <Link to={customerUrls.customerChangePasscode} className="btn btn-primary btn-block">Change Passcode</Link>
                                            {/* <a href="#" className="btn btn-primary btn-block">Change Password</a> */}
                                            </div>
                                            <div className={'col-md-6 pl-md-1'}>
                                                {userData?.current_tier == 1 ? 
                                                <Link to={'/profile?tier=2'}  className={'btn btn-primary btn-block'}> Upgrade to Tier 2</Link>
                                                :
                                                <Link to={'/profile?tier=3'}  className={'btn btn-primary btn-block'}> Upgrade to Tier 3</Link>
                                                }
                                            </div>
                                        </div> 
                                    }                                       
                                    </div>
                                </div>
                                <div className="card-footer">
                                </div>
                            </div>
                        </div>


                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    {activeTierId ? 
                                    <Compliance currentStep={activeTierId}/>
                                    :
                                    <ProfileDetails/>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </>

        </CustomerWrapper>
    )
}

export default  ProfileHome