import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {getRequest} from "../../../../config/redux/request";
import {remoteUrl} from "../../../../config/config";
import {
    auth_types,
    clearAll,
    setAuthModalForm,
    setErrorMessage,
    setLoading
} from "../../../../config/redux/slice/authData";
import {ButtonField, TextInputField} from "../../../../components/form/components";
import {Loader} from "../../../../components";
// import {colors} from "../../../../config/color";
import {Link} from "react-router-dom";
import {urls} from "../../../../config/urls/urls";
import {handleSystemManagement, type} from "../../../../config/redux/slice/adminSlice";
import {pagesText} from "../../../../config/string/english";

const RequestInternetBanking = ({backdrop}) => {
    const {loading, showRequestForInternetBanking,errorMsg,} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const [togglePassword, setTogglePassword] = useState(false)

    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const dispatch = useDispatch()

    const onSubmit = async (data) => {

        const res = await getRequest(`30333339313838342D313262642D346536352D383862322D303439353165306163623461`,
            `${remoteUrl.get_account_info}${data.account_number}`, dispatch, setLoading, false, setErrorMessage,clearAll)
    }
    return (
        <>
            <div className="modal fade show"
                 id="basicModal"
                 tabIndex="-1" aria-hidden="true"
                 style={{
                     display: "block",
                     paddingRight: "17px"
                 }}
            >
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header" style={{border: "0px"}}>
                            {/**/}
                            <button
                                type="button" className="close" data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => dispatch(handleSystemManagement({
                                    modal: false,
                                    type: type.admin_request_internet_banking
                                }))}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" >
                            <h6 className=" text-center mx-auto w-100"> Request for Online Banking </h6>

                            <div className={'px-5 py-3'}>
                                {errorMsg && <div className={'alert alert-danger  text-danger my-3'}>{errorMsg}</div>}
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className={'mb-3'}>
                                        <TextInputField
                                            fieldName={'email'}
                                            error_msg={errors.email?.message}
                                            col={'col-xl-12'}
                                            label={"Customer's Email Address"}
                                            placeholder={''}
                                            isReq={true}
                                            register={register}
                                            defaultVal={''}
                                            patternRules={'email'}
                                        />
                                    </div>
                                    {/*Phone Number*/}
                                    <div className={'mb-3'}>
                                        <TextInputField
                                            fieldName={'phone_number'}
                                            error_msg={errors.phone_number?.message}
                                            col={'col-xl-12'}
                                            label={'Customer\'s Phone Number'}
                                            placeholder={''}
                                            isReq={true}
                                            register={register}
                                            defaultValue ={'123344'}
                                            patternRules={'numbers'}
                                        />
                                    </div>
                                    {/*Account Number*/}
                                    <div className={'mb-3'}>
                                        <TextInputField
                                            fieldName={'account_number'}
                                            defaultValue ={''}
                                            error_msg={errors.account_number?.message}
                                            col={'col-xl-12'}
                                            label={'Customer\'s Account Number'}
                                            placeholder={''}
                                            isReq={true}
                                            register={register}
                                            patternRules={'numbers'}
                                        />
                                    </div>


                                    <div className={'mt-4'}>

                                        {loading ? <Loader/> :
                                            <ButtonField
                                                txtColor={colors.white}
                                                bgColor={colors.secondary_color}
                                                btnTxt={'Request for Internet Banking'}
                                                btnWidth={'100'}
                                                btnStyle={'mx-auto col-xl-12 mt-1'}/>}
                                    </div>
                                </form>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className="modal-backdrop fade show"></div>}
        </>
    )
}
export default  RequestInternetBanking