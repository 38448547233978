import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { colors } from "../../config/color";
import { strText } from "../../config/string";
import LogoutModal from "./LogoutModal";

const Sidebar = () => {
    const {isSidebarOpen, isActive, subPath, colors} = useSelector((state) => state.app)
    const {userData:{modules, permissions}, token, bank_token, user_role,newRole, moduleRoles, permissionRoles} = useSelector((state) => state.authData)
    const [showLogoutModal,setShowLogoutModal] = useState(false)
    const [sidebarData, setSidebarData] = useState(user_role === 'customer' ? strText.customerSidebar : strText.adminSidebar)
    const [bottomSidebarData, setBottomSidebarData] = useState(user_role === 'customer' ? strText.customerSidebarBottom : strText.adminSidebarBottom)
    const [isOpen, setIsOpen] = useState(false)
    const dispatch = useDispatch()
  

    return (
        <>
            {/* Sidebar */}
            <div className="sidebar" style={{overflow:'hidden',height:'100vh'}}>
                <div className="sidebar-wrapper scrollbar-inner">
                    <div className="sidebar-content">
                        {/* end of user */}
                        <ul className="nav">
                            {sidebarData.map((item,i=0) =>
                                item.has_children ?
                                    (user_role == 'customer' ?  
                                    item?.roles?.includes(user_role) :
                                    (modules)?.includes(item.moduleName)) &&
                                    <li key={i}
                                        className={`nav-item ${isActive === item.url ? 'active' :''}`}>
                                        <a
                                            data-toggle="collapse"
                                            href={`#item_${i}`}
                                        >
                                            {item.icon_url===''? <i style={{color:colors.white}}> <span uk-icon={item.uikit}></span></i>:
                                                <i className="" style={{color:colors.white}}>
                                                    <img className="sidebar-icon" src={item.icon_url} alt={''} width={'20'} height={'20'}/></i>}
                                            <p>{item.name}</p>
                                            <span className="caret"/>
                                        </a>
                                        {/*collapse show*/}
                                        <div className="collapse" id={`item_${i}`}>
                                            <ul className="nav nav-collapse">
                                                {item.children.map((sub_item,j=0) =>
                                                    (user_role == 'customer' ?  
                                                        sub_item?.roles?.includes(user_role) :
                                                    (modules)?.includes(sub_item.moduleName)) &&
                                                    <li key={j} className={subPath === sub_item.url ? 'sub_active' : ''}>
                                                        {sub_item?.defaultHref ?
                                                            <a href={sub_item.url}>
                                                                <span className="sub-item">{sub_item.name}</span>
                                                            </a>
                                                            :
                                                            <Link to={sub_item.url}>
                                                                <span className="sub-item">{sub_item.name}</span>
                                                            </Link>
                                                        }

                                                    </li>
                                                )
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                    :
                                    (user_role == 'customer' ? 
                                    item?.roles?.includes(user_role) :
                                    (modules)?.includes(item.moduleName))
                                    // item.roles.includes(user_role) 
                                    &&
                                    <li key={i} className={`nav-item ${isActive===item.url ? 'active' :''}`}>
                                        <Link to={item.url}>
                                            {item.icon_url===''? <i style={{color:colors.white}}> <span uk-icon={item.uikit}></span></i>:
                                                <i className="" style={{color:colors.white}}>
                                                    <img className="sidebar-icon" src={item.icon_url} alt={''} width={item.width ?? '20'} height={item.width ?? '20'}/></i>}
                                            <p>{item.name}</p>
                                        </Link>
                                    </li>
                            )}



                            <li className={`nav-item`}>
                                <Link onClick={()=>setShowLogoutModal(true)} to={'#'} className="dropdown-item" >
                                    <i className=""><img className="sidebar-icon" src={'/assets/images/sidebar/logout_icon.svg'} alt={''} height={'20'}/></i>
                                    <p> Logout </p>
                                </Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            {/* End Sidebar */}
            {showLogoutModal &&  <LogoutModal backdrop={showLogoutModal} modalFunc={setShowLogoutModal} /> }
        </>
    )
}

export default React.memo(Sidebar)