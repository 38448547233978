import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { colors } from "../../../config/color";

const LinkWrapper = ({urlPath,btnTxt,btnStyle}) => {
    const {colors} = useSelector((state) => state.app)
    return <>
        <Link

            to={urlPath}
            className={'btn'}
            style={{background:colors.secondary_color,color:colors.white, ...btnStyle}}

        >
            {btnTxt}
        </Link>
    </>
}
export default LinkWrapper