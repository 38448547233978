import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from "../../components";
import { ButtonField } from "../../components/form/components";
// import { colors } from "../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../config/config";
import { postRequest, postRequest2 } from "../../config/redux/request";
import {
    changeModalSize,
    clearAll,
    logoutUser,
    onCustomerEmailVerificationRequest,
    onCustomerLogin,
    onCustomerLogin2,
    setErrorMessage,
    setLoading
} from "../../config/redux/slice/authData";
import { urls as onBoardUrls } from "../../config/urls/urls";
import AlertWrapper from "../_shared_component/AlertWrapper";
import AuthWrapperCustomer from "../v1/onboard/components/AuthWrapperCustomer";
import { OtpComponent } from "./components";
import { logoutCustomer } from "../../config/redux/slice/customerSlice";
import { logoutAdmin } from "../../config/redux/slice/adminSlice";


const OtpScreen = () => {
    const navigate = useNavigate();
    // Check LoginNew Component for reference
    const [togglePassword, setTogglePassword] = useState(false)
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        loading,
        showLoginModal,
        user_role,
        errorMsg,
        success_msg,
        tempEmail,
        token,
        userData
    } = useSelector((state) => state.authData)

    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [isEmailVerified, setEmailVerified] = useState(false)
    const dispatch = useDispatch()
    const [successMessage, setSuccessMessage] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)

    useEffect(()=> {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
    },[])


 const {phone, passcode, type, user} = useSelector((state) => state?.authData?.userRegDetails)

    const closeErrorAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const closeSuccessAlert = () => {
        setSuccessMessage(false)
    }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        let newData = type == 'new_device' ? {...data, phone: phone, passcode: passcode} : type == 'sso' ? {...data, phone, passcode, user} : {...data, phone: phone} 

        let endPoint = type == 'new_device' || type == 'sso' ? `${endpointUrl.login}` : `${endpointUrl.auth_third_step}`

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            endPoint,
            newData,
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400){
            if(type == 'new_device' || type == 'sso'){
                if((res?.data?.data?.user_role_text).toLowerCase() != 'customer'){
                    dispatch(setErrorMessage({message:'Customer does not exist.'}))

                    navigate(onBoardUrls.login)

                }else{
                    dispatch(onCustomerLogin(res.data.data))
                    navigate(`${onBoardUrls.customerDashboard}`)
                }
            } else{
            dispatch(onCustomerLogin2({type: '', data:newData}))
            navigate(`${onBoardUrls.create_passcode}`)
            }
        }

    }

    const handleClick = async (data) => {
        let newData = {phone: phone}
        setResendLoading(true)
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.resendOtp}`,
            newData,
            dispatch,
            "",
            "",
            setErrorMessage
        ).then(setResendLoading(false))
        if(res !== undefined && res.status < 400){
            setSuccessMessage(true)
        }
    }
    //
    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }

    // useEffect(()=>{
 
    //     if (token!=='' && user_role != 'customer') {
    //         dispatch(logoutUser())
    //         dispatch(logoutCustomer())
    //         dispatch(logoutAdmin())
    //         // dispatch(setErrorMessage({message:'Customer does not exist.'}))

    //         navigate(onBoardUrls.login)
    //     }
    // }, [token, user_role])
    //
    return <AuthWrapperCustomer title='OTP Verification'>   
        <fieldset>
            <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)} autoComplete={'off'} noValidate>
                {
                    successMessage &&
                    <AlertWrapper onClick={closeSuccessAlert} type={'success'}>
                        <div className={'py-1 text-capitalize'}>
                            Otp Sent Successfully
                        </div>
                    </AlertWrapper>
                }
                {
                    errorMsg &&
                    <AlertWrapper onClick={closeErrorAlert} type={'error'}>
                        <div className={'py-1 text-capitalize'}>
                            {errorMsg === "Cannot read properties of undefined (reading 'account_number')"
                                ?
                                "Your account request is still pending, you will get a email notification upon approval."
                                :
                                errorMsg === 'verify email first' ?
                                    <div className="">

                                        Your email needs to be verified before you can use online banking.
                                        <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}>
                                            <span className={'text-primary'}> <u> Click here </u> </span>
                                        </Link>to verify.
                                    </div>
                                    :
                                    errorMsg === 'Request failed with status code 429' ?
                                        'To many Request. Please wait for 15min' : errorMsg
                            }
                        </div>
                    </AlertWrapper>
                }
                <OtpComponent
                error_msg={errors.otp?.message}
                label={`Enter the 6 digit code that was sent to your phone (${phone})`}
                placeholder={''}
                isReq={true}
                isLoading={resendLoading}
                register={register}
                defaultVal={''}
                patternRules={''}
                minlength={6}
                maxlength={6}
                onClick={handleClick}
                />
                <div className={'row'}>

                    <div className={'col-md-12'}>
                        {loading ? <Loader/> :
                            <ButtonField
                                txtColor={colors.white}
                                bgColor={colors.primary_color}
                                btnTxt={'Continue'}
                                btnWidth={'100'}
                                btnStyle={'mx-auto col-xl-12 py-2 mt-2'}/>}
                    </div>

                </div>
            </form>
        </fieldset>
    </AuthWrapperCustomer>
}


export default OtpScreen
