
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appConfig, remoteUrl } from "../../../../config/config";
import { postRequest, postRequest2 } from "../../../../config/redux/request";
import {
    getAccountBeneficiaries,
    resetTransferFormData,
    setCustomerErrorMsg,
    setLoading
} from "../../../../config/redux/slice/customerSlice";
import CustomerWrapper from "../../CutomerWrapper";
import { NewTransfer, TransferConfirmationPage, TransferSuccess } from "./components";


const TransferHome = () => {
    const {
        generalModal,
        successModal,
        formResetFunc,
    } = useSelector((state) => state.customer)
    const {token,userData} = useSelector((state) => state.authData)
    const dispatch = useDispatch()

    useEffect(()=>{
    dispatch(resetTransferFormData())
    getBeneficiariesData()
},[])

    const getBeneficiariesData = () => {
        let postData = {
            "jwt" : token,
            "email" : userData.email,
            "account_number" :userData.account_number
        }
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.getBeneficiaries}`,
            postData,
            dispatch,
            setLoading,
            getAccountBeneficiaries,
            setCustomerErrorMsg
        )
    }

    return (
        <CustomerWrapper title={''} showNormalBreadcrumb={false}>
            <div className={''}>
                <NewTransfer/>
            </div>
            {generalModal.show &&  <TransferConfirmationPage backdrop={generalModal.backdrop} msg={generalModal.msg} /> }
            {successModal.show && <TransferSuccess  successModal={successModal}  />}
        </CustomerWrapper>)
}

export default TransferHome