import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotifications } from 'reapop';
import { setActiveSidebar } from "../../config/redux/slice/appSlice";
import { clearMessages, logoutCustomer } from "../../config/redux/slice/customerSlice";
import { pagesText } from "../../config/string/english";
import { urls } from "../../config/urls/urls";
import AppWrapper from "../AppWrapper";
import { SetEmailAddress, SetSecurityQuestion, SetTransactionPin, WelcomeMessage } from "./dashboard/auth_modal";
import { logoutUser, setErrorMessage } from "../../config/redux/slice/authData";
import { logoutAdmin } from "../../config/redux/slice/adminSlice";

const CustomerWrapper = ({children, title, showNormalBreadcrumb = false, mainAppStyle,showNotificationIcon=false}) => {
     const {
        user_role,
        token
    } = useSelector((state) => state.authData)
    const {success_msg,error_msg,setTransactionPin,setEmailAddress,setSecurityQuestion,showWelcomeMessage,setup} = useSelector((state) => state.customer)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // 1. Retrieve the action to create/update a notification.
    const {notify,dismissNotifications} = useNotifications()

    useEffect(() => {
        dispatch(clearMessages())
        let mainPath = ''
        let subPath = ''
        let path = window.location.pathname

        let fullPathArray = path.split("/")
        if (fullPathArray.length > 2) {
            mainPath = fullPathArray[1]
            subPath = path
        }
        //
        else {
            mainPath = path
            subPath = ''
        }

        dispatch(setActiveSidebar({
            mainPath,
            subPath
        }))

        if(user_role != 'customer'){
            dispatch(logoutCustomer())
            dispatch(logoutUser())
            dispatch(logoutAdmin())
            // dispatch(setErrorMessage({message: "Customer does not exist"}))
            navigate(urls.login)
        }

    }, [])

    useEffect(() => {
        if(success_msg){
            let msg = success_msg.toLowerCase().charAt(0).toUpperCase() + success_msg.slice(1)
            dispatch(clearMessages())

            const capitalized =
                msg.charAt(0).toUpperCase()
                + msg.slice(1)

            notify(capitalized, 'success')
            setTimeout(() => {
                dismissNotifications()
            },3000)
        }

    }, [success_msg])

    useEffect(() => {
        if(error_msg){

            let err = error_msg.toLowerCase().charAt(0).toUpperCase() + error_msg.slice(1)
            dispatch(clearMessages())

            const capitalized =
                err.charAt(0).toUpperCase()
                + err.slice(1)

            notify(capitalized, 'error')
            setTimeout(() => {
                dismissNotifications()
            },3000)
        }

    }, [error_msg])    

    useEffect(()=>{
        if (token=='') {
            dispatch(logoutUser())
            dispatch(logoutCustomer())
            dispatch(logoutAdmin())
            // dispatch(setErrorMessage({message:'Customer does not exist.'}))

            navigate(urls.login)
        }
    }, [token])

    

    return (
        <AppWrapper showNotificationIcon={showNotificationIcon} showNormalBreadcrumb={showNormalBreadcrumb} title={title} mainAppStyle={mainAppStyle}>
            {/*{success_msg && <AdminSuccessAlert msg={success_msg} toastId={success_msg}/>}*/}
            {/*{error_msg && <AdminErrorAlert msg={error_msg} toastId={error_msg}/>}*/}
            {setSecurityQuestion && <SetSecurityQuestion backdrop={setSecurityQuestion}/>}
            {showWelcomeMessage && <WelcomeMessage backdrop={showWelcomeMessage} setup={setup}/>}
            {/*{showVerifyAccountModal && <VerifyAccount backdrop={showVerifyAccountModal}/>}*/}
            {setTransactionPin && <SetTransactionPin backdrop={setTransactionPin}/>}
             {setEmailAddress && <SetEmailAddress backdrop={setEmailAddress}/>}
            {children}
            {/*{showEnterTransactionPinModal &&  <EnterTransactionPinModal/> }*/}

            {/*<Compliance backdrop={false}/>*/}



        </AppWrapper>
    )
}


export default CustomerWrapper