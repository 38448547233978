import { appConfig } from "../../../config/config";

const PhoneNumberComponent = ({ fieldName,register, label, col, error_msg, isReq, defaultVal, selectStyle, setValueFunc,nextCompId,hasHelperTip=false,helperTipsMsg=''}) => {

    const formatPhoneNumber = (phoneNumber) => {
        if(nextCompId!=="" && phoneNumber.length===12) {
            document.getElementById(`${nextCompId}`)
        }
        else{
            const cleanedNumber_ = phoneNumber.replace(/^0+/, '');
            // Remove any non-digit characters from the phone number
            const cleanedNumber = cleanedNumber_.replace(/\D/g, '');
            // Add spaces between groups of digits
            const formattedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
            setValueFunc('mobile_new',formattedNumber)
            let x = removeWhitespace(phoneNumber,5)
            setValueFunc(`${fieldName}`,x)

            if(nextCompId!=="" && formattedNumber.length===12){
                document.getElementById(`${nextCompId}`)
            }
        }
        // return formattedNumber;
    }

    const removeWhitespace = (str, count) => {
        const regex = new RegExp(`\\s{${count}}`, 'g');
        const cleanedStr = str.replace(regex, '');
        return cleanedStr;
    }


    return (
        <>
            <div className="form-group">
                <label className="fw-bold">{label}</label>

                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        {/*<div className="input-group-text" id="basic-addon1">*/}
                          <select
                              className="input-group-text"
                              defaultValue={(appConfig.defaultCountryCode).slice(1)}
                              style={{
                                  background:'rgba(248, 248, 248, 1)',
                                  // flex:0.5,
                                  // width:'50%'
                                  // borderRadius:'40rem',
                                  borderTopLeftRadius:'50px',
                                  borderBottomLeftRadius:'50px'
                              }}
                              {...register(`code`, {
                                  required: {
                                      value: isReq,
                                      message: '*this field is required'
                                  },
                              })
                              }
                          >
                            <option value={''}>Select Country</option>
                              <option value={'234'}>&#x1F1F3;&#x1F1EC; Nigeria(+234)</option>
                              <option value={'233'}>&#x1F1EC;&#x1F1ED; Ghana(+233)</option>
                              <option value={'254'}>&#x1F1F0;&#x1F1EA; Kenya(+254)</option>
                              <option value={'91'}>&#x1F1EE;&#x1F1F3; India(+91)</option>
                              {/*<option value={'231'}>&#x1F1F1;&#x1F1F7; Liberia(+231)</option>
                              <option value={'221'}>&#x1F1F8;&#x1F1F3; Senegal(+221)</option>
                              <option value={'228'}>&#x1F1F9;&#x1F1EC; Togo(+228)</option>
                              <option value={'229'}>&#x1F1E7;&#x1F1EF; Benin(+229)</option>
                              <option value={'233'}>&#x1F1F2;&#x1F1F1; Mali(+223)</option> */}
                              {/*    */}
                            {/*  <option value={'+234'}>&#x1F1F3;&#x1F1EC; +234</option>*/}
                            {/*<option value={'+233'}>&#x1F1EC;&#x1F1ED; +233</option>*/}
                            {/*<option value={'+220'}>&#x1F1EC;&#x1F1F2; +220</option>*/}
                            {/*<option value={'+231'}>&#x1F1F1;&#x1F1F7; +231</option>*/}
                            {/*<option value={'+221'}>&#x1F1F8;&#x1F1F3; +221</option>*/}
                            {/*<option value={'+228'}>&#x1F1F9;&#x1F1EC; +228</option>*/}
                            {/*<option value={'+229'}>&#x1F1E7;&#x1F1EF; +229</option>*/}
                            {/*<option value={'+233'}>&#x1F1F2;&#x1F1F1; +223</option>*/}
                              {/*    */}
                        </select>
                        {/*</div>*/}
                    </div>

                    <input
                        type={'text'}
                        autoComplete={'off'}
                        autoCorrect={'off'}
                        aria-autocomplete={'none'}
                        defaultValue={defaultVal}
                        onKeyUp={(e)=> formatPhoneNumber(e.target.value)}
                        className={'form-control'}
                        style={{borderTopRightRadius:'50px',borderBottomRightRadius:'50px',background:'rgba(248, 248, 248, 1)',}}
                        {...register(`mobile_new`, {
                            required: {
                                value: isReq,
                                message: '*this field is required'
                            },
                        })
                        }
                    />
                    {hasHelperTip &&
                        <small className="form-text text-muted">{helperTipsMsg}</small>
                    }

                </div>

            </div>

            <input
                type={'hidden'}
                {...register(`${fieldName}`, {
                    required: {
                        value: false,
                        message: '*this field is required'
                    },
                })
                }
            />
        </>

    )
}

export default PhoneNumberComponent

/*



 */