import moment from "moment";

export function getFirstDayOfLastMonth(){
    const currentDate = moment();

    // Calculate the first day of the previous month
    const firstDayOfLastMonth = currentDate.clone().subtract(1, 'months').startOf('month');

    // Format the date as per your requirements
    const firstDayOfLastMonthFormattedDate = firstDayOfLastMonth.format('YYYY-MM-DD');  
    return firstDayOfLastMonthFormattedDate
}

export function getLastDayOfCurrentMonth(){
    const currentDate = moment();  
    
    const lastDayOfCurrentMonth = currentDate.endOf('month');

    // Format the date as per your requirements
    const lastDayOfCurrentMonthFormattedDate = lastDayOfCurrentMonth.format('YYYY-MM-DD');

    return lastDayOfCurrentMonthFormattedDate
}