import {GeneralModalWrapper} from "../../../_shared_component";
import moment from "moment/moment";
import {ButtonField, TextInputField} from "../../../../components/form/components";
import {Loader} from "../../../../components";
import { useSelector } from "react-redux";
// import {colors} from "../../../../config/color";

const AgencyAccountFullDetails = ({showModal,onClose,agentDetails}) => {
    const {colors} = useSelector((state) => state.app)
    return ( <>
        <GeneralModalWrapper
            showModal={showModal}
            closeModalFunc={onClose}
            modal_title={`Agency Banking Approval Details | ${agentDetails.firstname ?? ''}  ${agentDetails.middlename ?? ''} ${agentDetails.lastname ?? ''} `}>

            <div className={'px-3'}>
                <table className={'table table-striped'}>
                    <thead></thead>
                    <tbody>
                    {/*<tr>*/}
                    {/*    <td>Agent Name</td>*/}
                    {/*    <td>{`${agentDetails.firstname ?? ''}  ${agentDetails.middlename ?? ''} ${agentDetails.lastname ?? ''} `}</td>*/}
                    {/*</tr>*/}
                    <tr>
                        <td>account number</td>
                        <td>{agentDetails.account_number}</td>
                    </tr>

                    <tr>
                        <td>customer email</td>
                        <td>{agentDetails.agent_email}</td>
                    </tr>

                    <tr>
                        <td>customer phone</td>
                        <td>{agentDetails.agent_phone}</td>
                    </tr>

                    <tr>
                        <td>initiated by</td>
                        <td>{agentDetails.initiated_by ?? 'N/A'}</td>
                    </tr>

                    <tr>
                        <td>Request status</td>
                        <td>
                            {agentDetails.request_status === 'pending' ?
                                <small className='badge badge-warning'>pending</small>
                                : agentDetails.request_status === 'approved' ?
                                    <small className='badge badge-success'>approved</small> :
                                    <small className='badge badge-danger'>N/A</small>}
                        </td>
                    </tr>

                    <tr>
                        <td>created on</td>
                        <td>{moment(agentDetails.created_on).format('YYYY-MM-DD hh:mm:ss') }</td>
                    </tr>

                    {/*<tr>*/}
                    {/*    <td>remark</td>*/}
                    {/*    <td>{agentDetails.remark ?? 'N/A'}</td>*/}
                    {/*</tr>*/}

                    <tr>
                        <td>approved by</td>
                        <td>{agentDetails.approved_by ?? 'N/A'}</td>
                    </tr>

                    </tbody>
                </table>

            </div>
        </GeneralModalWrapper>
    </>)
}

export default  AgencyAccountFullDetails