import { createSlice } from '@reduxjs/toolkit';


import moment from "moment";
import { getFirstDayOfLastMonth, getLastDayOfCurrentMonth } from '../../../helpers/dateHelper';
import { AgentActive, AgentApproved, AgentInActive, AgentPending } from "../../../app/admin/agents/pages";
import { CardActive, CardInActive } from "../../../app/admin/cards/pages";
import {
    ActiveCustomer,
    AllCustomer,
    BlockCustomer,
    ClosedAccount,
    DormantAccount
} from "../../../app/admin/customers/pages";
import { LoanActive, LoanPending } from "../../../app/admin/loans/pages";
import { TokenizedDepositForm } from "../../../app/admin/operations/deposit";
import { TokenizedWithdrawalForm } from "../../../app/admin/operations/withdrawal";
import {
    TransactionTransfer
} from "../../../app/admin/transactions/tabs";
import { respMsg } from "../../response_messages";
import { strText } from "../../string";

export const tenureDataArray = () => {
    let tenureArray = [];
    for (let i = 0; i < 12; i++) {
        let x = i + 1;

        tenureArray.push({id: i, value: x})
    }
    return tenureArray
}

export const type = {
    in_bank_withdrawal: 'in_bank_withdrawal',
    tokenized_withdrawal: 'tokenized_withdrawal',
    transfer: 'transfer',
    utility: 'utility',
    customer: 'customer',
    transaction_transfer: 'transaction_transfer',
    transaction_withdrawal: 'transaction_withdrawal',
    transaction_deposit: 'transaction_deposit',
    transaction_utility: 'transaction_utility',
    agents_active: 'agents_active',
    agents_inactive: 'agents_inactive',
    agents_approved: 'agents_approved',
    agents_pending: 'agents_pending',
    agents_details: 'agents_details',
    card_active: 'card_active',
    card_inactive: 'card_inactive',
    block_card: 'block_card',
    //
    loans_active: 'loans_active',
    loans_pending: 'loans_pending',
    loan_type_form: 'loan_type_form',
    //
    active_user: 'active_user',
    inactive_users: 'inactive_users',
    add_new_user: 'add_new_user',
    show_agent_bulk_import: 'show_agent_bulk_import',
    agent_new_data: 'agent_new_data',
    admin_request_internet_banking: 'admin_request_internet_banking',
    //
    set_admin_details_system: 'set_admin_details_system',
    set_transaction_transfer_date : 'set_transaction_transfer_date',
    //
    set_tier_details_system: 'set_tier_details_system',
    set_role_details_system: 'set_role_details_system', 
}

export const operationsPermissions = [
    {
        title: 'Operations',
        data: [
            {
                text: 'Transfer',
                data: {create: false, read: false, update: false, delete: false},
                name: 'transfer'
            },
            {
                text: 'Deposit',
                data: {create: false, read: false, update: false, delete: false},
            },
            {
                text: 'Utilities',
                data: {create: false, read: false, update: false, delete: false},
            },
            {
                text: 'Withdrawal',
                data: {create: false, read: false, update: false, delete: false},
            },
        ]
    },
    {
        title: 'Operations',
        data: [
            {
                text: 'Agents',
                data: {create: false, read: false, update: false, delete: false}
            }
        ]
    },
    //customer
    {
        title: 'Operations',
        data: [
            {
                text: 'Customers',
                data: {create: false, read: false, update: false, delete: false}
            }
        ]
    },
    //transaction
    {
        title: 'Transactions',
        data: [
            {
                text: 'Transfer',
                data: {create: false, read: false, update: false, delete: false}
            },
            {
                text: 'Withdrawal',
                data: {create: false, read: false, update: false, delete: false},
            },
            {
                text: 'Deposit',
                data: {create: false, read: false, update: false, delete: false},
            },
            {
                text: 'Utilities',
                data: {create: false, read: false, update: false, delete: false},
            }
        ],
    },
    //card
    {
        title: 'Card',
        data: [{
            text: 'Cards',
            data: {create: false, read: false, update: false, delete: false}
        }]
    },
    //System
    {
        title: 'System',
        data: [{
            text: 'Users',
            data: {create: false, read: false, update: false, delete: false}
        }]
    },
    {
        title: 'Settings',
        data: [{
            text: 'Documents',
            data: {create: false, read: false, update: false, delete: false}
        }]
    }
]

const initialState = {
    pageReload : false,
    adminErrorMsg: '',
    temp_error_msg : '',
    success_msg: '',
    adminRegDetails:{
        phone: '',
        otp: '',
        admin_token: '',
        passcode: '',
    },
    //dashboard
    dailyPosition: {
        qapp_charge_total_for_date: 0,
        no_of_transactions_for_date: 0,
        other_charges_total_for_date: 0,
        total_transaction_amount_for_date: 0
    },
    openConfirmationModal: false,
    openConfirmationModalDetails: {
        heading: '',
        body: '',
        buttonText: '',
        extraDetails: ''
    },
    openConfirmationAgentModal: false,
    openConfirmationAgentModalDetails: {
        heading: '',
        body: '',
        buttonText: '',
        extraDetails: ''
    },
    userSummaryColors: ['#E46F24', '#0FDA48', '#DDC80E', '#14F4E7'],
    userSummaryIcons: ['people.svg', 'wallet.svg', 'profile.svg', 'group_user.svg'],
    usersSummary: [],
    pieChartData : [],
    barChartData : [],
    adminReLogin: false,
    //transactions
    transactionsManagement: {
        newDataBot: false,
        transferHistoryFilter : {
            start_date : getFirstDayOfLastMonth(),
            end_date :  getLastDayOfCurrentMonth()
        },
        current_tab: type.transaction_transfer,
        transactionsTabsArray: [
            {
                name: 'Transfer',
                id: 'transfer-tab',
                dataBsTarget: 'bordered-justified-transfer',
                ariaControls: type.transaction_transfer,
                isActive: true,
                component: <TransactionTransfer/>
            },
        ],
        //
        transferData: [],
        singleTransferDetails: null,
        //
        withdrawalData: [],
        singleWithdrawalData: null,
        //
        depositData: [],
        singleDepositData: null,
        //
        utilityData: [],
        singleUtilityData: null,
    },
    tokenizedDepositManagement : {
        tokenizedData : [],
        newData : false,
        showDetailsModal:false,
        tokenizedDetails: ''

    },
    defaultFilterDate: {
        start_date: getFirstDayOfLastMonth(),
        end_date: getLastDayOfCurrentMonth()
    },
    //adminUserManagement
    adminUserManagement: {
        adminUserData: [],
        newDataBot: false,
        showAdminForm: false,
        showAminDetailsForm: false,
        adminDetails: ''
    },
    systemManagement: {
        roles: [],
        branches: [],
        usersTab: [],
        userData: [],
        newUserForm: {
            profile_image: '',
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            address: '',
            branch_id: '',
            role_id: [],
            permissions: []
        },
        showUserDetailsForm: false,
        userDetails: null,
        internetBankingRequest: {
            newInternetReqBot: false,
            showRequestForm: false,
            internetReqData: [],
            showInternetRequestDetails: false,
            internetRequestDetails: '',
            isInternetReqRemark: false,
            remark: ''
        },
        accOpeningRequest: {
            accOpeningReqBot: false,
            showRequestForm: false,
            isAccountReqRemark: false,
            accOpeningReqData: [],
            showAccountRequestDetails: false,
            accountRequestDetails: '',
            remark: ''
        },
        additionalAccountReq: {
            additionalAccReqBot: false,
            showAdditionalRequestForm: false,

            isAdditionalAccountReqRemark: false,
            additionalAccReqData: [],
            showAdditionalAccountRequestDetails: false,
            additionalAccRequestDetails: '',
            remark: ''
        },
        agentRequest : {
            agentReqData : [],
            newAgentReqBot: false,
            showRequestForm : false,
            showDetails: false,
            agentDetails : '',
            isAccountReqRemark: false,
        }
    },
    settingsManagement: {
        settings: {}
    },
    customerManagement: {
        newData: false,
        customerDetails: '',
        current_tab: '',
        filter: 'all',
        refreshCustomers: true,
        showCustomerSuccessModal: false,
        showCustomerImportModal: false,
        showActivateStatusModal : false,

        customerTabArray: [
            {
                name: 'All',
                id: 'all-customer-tab',
                dataBsTarget: 'bordered-justified-all-customer',
                ariaControls: 'all-customer',
                isActive: true,
                component: <AllCustomer/>
            },
            {
                name: 'Active Account',
                id: 'active-account-tab',
                dataBsTarget: 'bordered-justified-active-account-tab',
                ariaControls: 'active-account',
                isActive: false,
                component: <ActiveCustomer/>
            },
            {
                name: 'Blocked Account',
                id: 'blocked-account-tab',
                dataBsTarget: 'bordered-justified-blocked-account-tab',
                ariaControls: 'blocked-account',
                isActive: false,
                component: <BlockCustomer/>
            },
            {
                name: 'Dormant Account',
                id: 'dormant-account-tab',
                dataBsTarget: 'bordered-justified-dormant-account-tab',
                ariaControls: 'dormant-account',
                isActive: false,
                component: <DormantAccount/>
            },
            {
                name: 'Closed account',
                id: 'closed-account-tab',
                dataBsTarget: 'bordered-justified-closed-account-tab',
                ariaControls: 'closed-account',
                isActive: false,
                component: <ClosedAccount/>
            }
        ],
        customersData: [],
        customersFormData: {
            currentStep: 1,
            stepOne: {
                first_name: '',
                last_name: '',
                email_address: '',
                phone_number: '',
                bvn: ''
            },
            stepTwo: {
                account_name: '',
                account_class_id: '',
                currency_id: '',
                account_category_id: '',
                opening_branch_id: '',
                is_primary_account: false
            },
            stepThree: {
                address: '',
                state_id: '',
                city_id: '',
                lga_id: '',
                landmark: ''
            },
            stepFour: {
                profile_image: '',
                temp_url: ''
            },
        }
    },
    adminTierManagement: {
        newData: false,
        refreshTiers: false,
        showTierForm: false,
        showTierUpdateModal: false,
        showTierImportModal: false,
        tiersData: [],
        tierDetails: '',
    },
    adminRoleManagement: {
        newData: false,
        refreshRoles: false,
        showRoleForm: false,
        showRoleUpdateModal: false,
        showRoleImportModal: false,
        rolesData: [],
        roleDetails: '',
    },    
    adminUsers: [],
    showAddAdminForm: false,
    loading: false,
    loading_: false,
    auditLogs : [],
    audit_log_bot : false,


    //

    temp_msg: '',
    //start of general data
    showModal: false,
    successModal: false,
    formConfirmationModal: false,

    tempFormResetFunc: '',
    temp_modal_type: '',
    //end of shared data
    //
    //dashboard




    //operation
    operationManagement: {
        //  ,transfer,utility
        current_tab: '',
        //deposit
        depositsTabArray: [
            /*
            {
                name: 'In-Bank Deposit',
                id: 'in-bank-tab',
                dataBsTarget: 'bordered-justified-in-bank-tab',
                ariaControls: 'in_bank',
                isActive: true,
                component: <InBankDepositForm/>
            },
            */
            {
                name: 'Tokenized Deposit',
                id: 'tokenized-deposit-tab',
                dataBsTarget: 'bordered-justified-tokenized-deposit-tab',
                ariaControls: 'tokenized_deposit',
                isActive: true,
                component: <TokenizedDepositForm/>
            },
        ],
        inBankDepositFormData: {
            account_number: '',
            account_name: '',
            amount: '',
            teller_number: '',
            depositor_name: '',
            description: ''
        },
        tokenizedDepositFormData: {
            phone_number: '',

            account_number: '',
            account_name: '',
            amount: '',
            depositor_name: '',
            transaction_date: ''

        },
        pendingTokenizedDeposit: [
            {id: 1, amount: 2000, title: 'Deposit', time: '09:12am'},
            {id: 2, amount: 2000, title: 'Deposit', time: '09:12am'}
        ],
        //withdrawal
        withdrawalTabArray: [
            /*
             {
                name: 'In-Bank Withdrawal',
                id: 'in-bank-withdrawal-tab',
                dataBsTarget: 'bordered-justified-in-bank-withdrawal-tab',
                ariaControls: type.in_bank_withdrawal,
                isActive: true,
                component: <InBankWithdrawalForm/>
              },
             */
            {
                name: 'Tokenized Withdrawal',
                id: 'tokenized-withdrawal-tab',
                dataBsTarget: 'bordered-justified-tokenized-withdrawal-tab',
                ariaControls: type.tokenized_withdrawal,
                isActive: true,
                component: <TokenizedWithdrawalForm/>
            },
        ],
        inBankWithdrawalFormData: {
            account_number: '',
            account_name: '',
            amount: '',
            account_balance: ''
        },
        tokenizedWithdrawalFormData: {
            phone_number: '',
            account_number: '',
            account_name: '',

            amount: '',
            depositor_name: '',
            transaction_date: ''
        },
        pendingTokenizedWithdrawal: [
            {id: 1, amount: 2000, title: 'Deposit', time: '09:12am'},
            {id: 2, amount: 2000, title: 'Deposit', time: '09:12am'}
        ],
        //transfer
        transferFormData: {
            phone_number: '',
            account_number: '',
            account_name: '',
            transfer_type_id: '',
            recipient_financial_institution_id: '',
            recipient_account_number: '',
            recipient_account_name: '',
            amount: '',
            description: '',
            bank_name: '',
            transfer_type_name: ''
        },
        //utility
        utility_form_step: 0,
        utilityFormOneData: {
            utility_type_id: '',
            service_provider_id: '',
            package_id: '',
            amount: '',
            smart_card_no: '',
            account_name: ''
        },
        utilityFormTwoData: {
            payment_method_id: '',
            customer_account_no: '',
            customer_account_name: '',
        },
    },
    //customer management
    accountClasses: [
        {id: 1, name: 'Current Account'},
        {id: 2, name: 'Savings Account'},
    ],
    accountCategories: [
        {id: 1, name: 'Current Account'},
        {id: 2, name: 'Savings Account'},
    ],
    accountBranches: [
        {id: 1, name: 'Branch A'},
        {id: 2, name: 'Branch B'},
    ],
    currencies: [
        {id: 1, name: 'NGN'}
    ],
    statesData: [
        {id: 1, name: 'Lagos'},
        {id: 2, name: 'Abuja'},
    ],
    cityData: [
        {id: 1, name: 'Gudu'},
        {id: 2, name: 'Apo'},
    ],
    lgaData: [
        {id: 1, name: 'Oyun'},
        {id: 2, name: 'Offa'},
    ],
    cardManagements: {
        current_tab: type.card_active,
        showBlockModal: false,
        cardTabArray: [
            {
                name: 'Active Cards',
                id: 'active-card-tab',
                dataBsTarget: 'bordered-justified-active-card-tab',
                ariaControls: type.card_active,
                isActive: true,
                component: <CardActive/>
            },
            {
                name: 'InActive Cards',
                id: 'inactive-card-tab',
                dataBsTarget: 'bordered-justified-inactive-card-tab',
                ariaControls: type.card_inactive,
                isActive: false,
                component: <CardInActive/>
            }
        ],
        cardsData: []
    },

    agentsManagement: {
        current_tab: type.agents_active,
        agentTabArray: [
            {
                name: 'Active Agents',
                id: 'agents_active-tab',
                dataBsTarget: 'bordered-justified-agents_active',
                ariaControls: type.agents_active,
                isActive: true,
                component: <AgentActive/>
            },
            {
                name: 'InActive Agents',
                id: 'agents_inactive-tab',
                dataBsTarget: 'bordered-justified-agents_inactive-tab',
                ariaControls: type.agents_inactive,
                isActive: false,
                component: <AgentInActive/>
            },
            {
                name: 'Approved Agents',
                id: 'agents_approved-tab',
                dataBsTarget: 'bordered-justified-agents_approved-tab',
                ariaControls: type.agents_approved,
                isActive: false,
                component: <AgentApproved/>
            },
            {
                name: 'Pending Agent',
                id: 'agents-pending-tab',
                dataBsTarget: 'bordered-justified-agents-pending-tab',
                ariaControls: type.agents_pending,
                isActive: false,
                component: <AgentPending/>
            }
        ],
        newData: false,
        agentsData: [],
        agentDetail: '',
        showAgentImportModal: false,
        showActivateStatusModal: false,
        showAgentDetails: false,
        agentsFormData: {
            currentStep: 1,
            stepOne: {
                first_name: '',
                last_name: '',
                email_address: '',
                phone_number: '',
                bvn: ''
            },
            stepTwo: {
                account_name: '',
                account_class_id: '',
                currency_id: '',
                account_category_id: '',
                opening_branch_id: '',
                is_primary_account: false
            },
            stepThree: {
                address: '',
                state_id: '',
                city_id: '',
                lga_id: '',
                landmark: ''
            },
            stepFour: {
                profile_image: '',
                temp_url: ''
            },
        }
    },
    loanManagement: {
        showFormModal: false,
        showSuccessModal: false,
        current_tab: '',
        loanTabArray: [
            {
                name: 'Active Loans',
                id: 'loans_active-tab',
                dataBsTarget: 'bordered-justified-loans_active',
                ariaControls: type.loans_active,
                isActive: true,
                component: <LoanActive/>
            },
            {
                name: 'Pending Loans',
                id: 'loans_pending-tab',
                dataBsTarget: 'bordered-justified-loans_pending-tab',
                ariaControls: type.loans_pending,
                isActive: false,
                component: <LoanPending/>
            }
        ],
        loansData: [],
        loanTypeFormData: {
            loan_title: '',
            minimum_amount: '',
            maximum_amount: '',
            interest_rate: '',
            minimum_tenure: '',
            maximum_tenure: '',
        }
    },

}

export const adminSlice = createSlice({
    name: 'adminApp',
    initialState,
    reducers: {
        onAdminLogin : (state,action) => {
            state.adminReLogin = false

        },
        onAdminLogin2: (state, action) =>  {
            state.loading = false
            state.adminRegDetails = {...state.adminRegDetails, ...action.payload.data}       
        },
        logoutAdmin: (state, action) => {
              return initialState
        },
        clearErrorMsg: (state, action) => {
            state.success_msg = ''
            state.adminErrorMsg = ''

        },
        setReload : (state,action) => {
            state.pageReload = action.payload
        },
        setOpenConfirmationModal: (state, action) => {
            state.success_msg = ''
            state.openConfirmationModal = action.payload.open
            state.openConfirmationModalDetails = {...state.openConfirmationModalDetails, ...action.payload.details}
        },
        setOpenConfirmationAgentModal: (state, action) => {
            state.success_msg = ''
            state.openConfirmationAgentModal = action.payload.open
            state.openConfirmationAgentModalDetails = {...state.openConfirmationAgentModalDetails, ...action.payload.details}
        },
        onApproveUser: (state, action) => {
            state.success_msg = action.payload.message
        },
        onApproveUserAgent: (state, action) => {
            state.success_msg = action.payload.message
        },
        clearAdminErrorMsg: (state, action) => {
            state.temp_error_msg = ''
        },
        setAdminDashboardData: (state, action) => {
            let dashboardData = action.payload
            state.pieChartData = [
                ["Request","Number"],
                ["No Of Customers (Tier 1)",dashboardData?.tier_one],
                ["No Of Customers (Tier 2)",dashboardData?.tier_two],
                ["No Of Customers (Tier 3)",dashboardData?.tier_three],
                ["No of Customers (Total)",dashboardData?.no_of_customers]
            ]
            state.barChartData =  [
                ["Customer Distribution by Tier","Customers"],
                ["No Of Customers (Tier 1)",dashboardData?.tier_one],
                ["No Of Customers (Tier 2)",dashboardData?.tier_two],
                ["No Of Customers (Tier 3)",dashboardData?.tier_three],
                ["No of Customers (Total)",dashboardData?.no_of_customers]
            ]

            state.usersSummary = [
                {
                    card_title: 'Number Of Customers (Tier 1)',
                    total: dashboardData?.tier_one,
                    icon: '/assets/images/admin/people.svg',
                    icon_type: 'image',
                    card_link: ''
                },
                {
                    card_title: 'Number Of Customers (Tier 2)',
                    total: dashboardData?.tier_two,
                    icon: '/assets/images/admin/people.svg',
                    icon_type: 'image',
                    card_link: ''
                },
                {
                    card_title: 'Number Of Customers (Tier 3)',
                    total: dashboardData?.tier_three,
                    icon: '/assets/images/admin/people.svg',
                    icon_type: 'image',
                    card_link: ''
                },
                {
                    card_title: 'Number of Customers (Total)',
                    total: dashboardData?.no_of_customers,
                    icon: '/assets/images/admin/people.svg',
                    icon_type: 'image',
                    card_link: ''
                },
            ]
        },
        setAdminReLogin: (state) => {
            state.adminReLogin = false
        },
        setDailyPosition: (state, action) => {
            state.dailyPosition = action.payload
        },
        setErrorMsg: (state, action) => {
            let msg  = action?.payload?.message
            if(typeof msg ==='string'){
                state.adminReLogin = msg === 'invalid jwt'
                state.adminErrorMsg = (msg === 'invalid jwt') ? strText.timeout_message : msg
                state.temp_error_msg = msg
            }
            //
            else{
                // let account_opening_req_end_msg  = msg?.includes("'account_opening_request.account_number'")
                 let msgArr = ['ER_BAD_NULL_ERROR','ER_DUP_ENTRY']
                let newMsg ;
                switch (msg) {
                    case 'ER_BAD_NULL_ERROR':
                        newMsg = 'Unable to process request'
                        break
                    case 'ER_DUP_ENTRY':
                        newMsg = 'Account number already exists'
                        break
                    default:
                        newMsg = 'Unable to process request'
                        break
                }
                state.adminErrorMsg = newMsg
            }

        },
        setLoading: (state, action) => {
            state.loading = action.payload.loading
        },
        setLoading_: (state, action) => {
            state.loading_ = action.payload.loading
        },
        //Transaction
        getTransferData: (state, action) => {
            state.transactionsManagement.transferData = action.payload
            state.transactionsManagement.newDataBot = true
        },
        setTransactionDate: (state,action) => {

            switch (action.payload.type){
                case type.set_transaction_transfer_date :
                    // state.transactionsManagement.transferHistoryFilter.start_date  = action.payload?.filterDate?.start_date
                    // state.transactionsManagement.transferHistoryFilter.end_date  = action.payload?.filterDate?.end_date
                    state.transactionsManagement.transferHistoryFilter = {
                        start_date  : action.payload?.filterDate?.start_date,
                        end_date  : action.payload?.filterDate?.end_date
                    }
                    break

            }
        },
        resetFilterDate: (state) => {
            state.transactionsManagement.transferHistoryFilter = {
                        start_date  : getFirstDayOfLastMonth(),
                        end_date  : getLastDayOfCurrentMonth()
                    }
            state.defaultFilterDate = {
                start_date: getFirstDayOfLastMonth(),
                end_date: getLastDayOfCurrentMonth()
            }        
        },
        //clearing bot
        clearResetBot: (state) => {
            state.systemManagement.internetBankingRequest.newInternetReqBot = false
            state.systemManagement.accOpeningRequest.accOpeningReqBot = false
            state.customerManagement.newData = false
            state.agentsManagement.newData = false
            state.customerManagement.refreshCustomers = false
            state.transactionsManagement.newDataBot = false
            state.adminUserManagement.newDataBot = false
            state.adminTierManagement.newData = false
            state.adminRoleManagement.newData = false
            state.adminRoleManagement.refreshRoles = false
            state.adminRoleManagement.refreshTiers = false
            state.systemManagement.accOpeningRequest.newAgentReqBot = false
            state.tokenizedDepositManagement.newData = false
            state.audit_log_bot = false
            state.systemManagement.additionalAccountReq.additionalAccReqBot = false
        },
        //get tokenized deposit
        onGetTokenizedDeposit : (state,action) => {
             state.tokenizedDepositManagement.tokenizedData = action?.payload
            state.tokenizedDepositManagement.newData = true
        },
        setTokenizedDepositModal : (state,action) => {
            state.tokenizedDepositManagement.showDetailsModal=action.payload.modal
                state.tokenizedDepositManagement.tokenizedDetails = action.payload.data
        },
        adminDoTokenizedTransaction : (state,action) => {
            state.success_msg = respMsg.admin_tokenized_deposit_s
            state.tokenizedDepositManagement.showDetailsModal=false
            state.tokenizedDepositManagement.tokenizedDetails = ''

        },
        //additionalAccountReq
        getAdditionalAccountRequest: (state, action) => {
            state.systemManagement.additionalAccountReq.additionalAccReqData = action.payload
            state.systemManagement.additionalAccountReq.additionalAccReqBot = true
        },
        setAdditionalAccountReqDetails: (state, action) => {
            state.systemManagement.additionalAccountReq.showAdditionalAccountRequestDetails = action.payload.modal
            state.systemManagement.additionalAccountReq.additionalAccRequestDetails = action.payload.data
            state.systemManagement.additionalAccountReq.isAdditionalAccountReqRemark = action.payload.isRemark
        },
        onAdditionalAccountOpeningRequestApprovalReq: (state, action) => {
            state.success_msg = strText.req_additional_account_approval
            state.systemManagement.additionalAccountReq.showAdditionalAccountRequestDetails = false
            state.systemManagement.additionalAccountReq.additionalAccRequestDetails = ''
            state.systemManagement.additionalAccountReq.isAdditionalAccountReqRemark = ''
        },
        onApproveAdditionalAccountOpeningRequestReq: (state, action) => {
            state.success_msg = strText.approve_additional_account
            state.systemManagement.additionalAccountReq.showAdditionalAccountRequestDetails = false
            state.systemManagement.additionalAccountReq.additionalAccRequestDetails = ''
            state.systemManagement.additionalAccountReq.isAdditionalAccountReqRemark = ''
        },



        //internet banking request
        getInternetBankingRequest: (state, action) => {
            state.systemManagement.internetBankingRequest.internetReqData = action.payload
            state.systemManagement.internetBankingRequest.newInternetReqBot = true
        },
        setInternetReqDetails: (state, action) => {
            state.systemManagement.internetBankingRequest.showInternetRequestDetails = action.payload.modal
            state.systemManagement.internetBankingRequest.internetRequestDetails = action.payload.data
            state.systemManagement.internetBankingRequest.isInternetReqRemark = action.payload.isRemark
        },
        onInternetBankingApprovalReq: (state, action) => {
            state.success_msg = action.payload?.message
        },
        onGetInternetBankingRemark: (state, action) => {
            let remark = action.payload.remark
            state.systemManagement.internetBankingRequest.remark = remark ? remark : ''
            state.systemManagement.internetBankingRequest.isInternetReqRemark = remark ? 'remark_available' : ''
        },
        onInternetBankingApprovalApprove: (state, action) => {
            state.success_msg = action.payload?.message
            state.systemManagement.internetBankingRequest.showInternetRequestDetails = false
            state.systemManagement.internetBankingRequest.internetRequestDetails = ''
            state.systemManagement.internetBankingRequest.isInternetReqRemark =''
        },
        //
        getAccountOpeningRequest: (state, action) => {
            state.systemManagement.accOpeningRequest.accOpeningReqData = action.payload
            state.systemManagement.accOpeningRequest.accOpeningReqBot = true
        },
        //
        getAgentAccountOpeningRequest: (state, action) => {
            state.systemManagement.agentRequest.agentReqData = action.payload
            state.systemManagement.agentRequest.newAgentReqBot = true
        },
        //setAgent
        setAgentAccountReqDetails: (state, action) => {
            state.systemManagement.agentRequest.showDetails = action.payload.modal
            state.systemManagement.agentRequest.agentDetails = action.payload.data
            state.systemManagement.agentRequest.isAccountReqRemark = action.payload.isRemark
        },
        onGetAgentBankingReqRemark: (state, action) => {
            let remark = action.payload.remark
            state.systemManagement.agentRequest.remark = remark ? remark : ''
            state.systemManagement.agentRequest.isAccountReqRemark = remark ? 'remark_available' : ''
        },
        onAgentBankingApprovalReq: (state, action) => {
            state.success_msg = action.payload?.message
            state.systemManagement.agentRequest.showDetails = false
            state.systemManagement.agentRequest.agentDetails = ''
            state.systemManagement.agentRequest.isAccountReqRemark = ''
        },
        onAgentBankingApprovalApprove: (state, action) => {
            state.success_msg = action.payload?.message
            state.systemManagement.agentRequest.showDetails = false
            state.systemManagement.agentRequest.agentDetails = ''
            state.systemManagement.agentRequest.isAccountReqRemark = ''
        },
        //end of agent req
        //Account Request :: setAccountReqDetails
        setAccountReqDetails: (state, action) => {
            state.systemManagement.accOpeningRequest.showAccountRequestDetails = action.payload.modal
            state.systemManagement.accOpeningRequest.accountRequestDetails = action.payload.data
            state.systemManagement.accOpeningRequest.isAccountReqRemark = action.payload.isRemark
        },

        onAccountOpeningApprovalReq: (state, action) => {
            state.success_msg = action.payload?.message
            state.systemManagement.accOpeningRequest.showAccountRequestDetails = false
            state.systemManagement.accOpeningRequest.accountRequestDetails = ''
            state.systemManagement.accOpeningRequest.isAccountReqRemark = ''
        },
        onGetAccountOpeningRemark: (state, action) => {
            let remark = action.payload.remark

            state.systemManagement.accOpeningRequest.remark = remark ? remark : ''
            state.systemManagement.accOpeningRequest.isAccountReqRemark = remark ? 'remark_available' : ''
        },
        onAccountOpeningApprovalApprove: (state, action) => {
            state.success_msg = action.payload?.message
            state.systemManagement.accOpeningRequest.showAccountRequestDetails = false
            state.systemManagement.accOpeningRequest.accountRequestDetails = ''
            state.systemManagement.accOpeningRequest.isAccountReqRemark = ''
        },
        getSettings: (state, action) => {
            state.settingsManagement.settings = action.payload
            state.adminErrorMsg = ''
        },

        onSubmitSettingsForApproval: (state, action) => {
            state.success_msg = action.payload?.message
            state.adminErrorMsg = ''
        },
        approveSettings: (state, action) => {
            state.success_msg = action.payload?.message
            state.adminErrorMsg = ''
        },
        //
        getCustomerForAdmin: (state, action) => {
            state.adminErrorMsg = ''
            state.loading = ''
            state.customerManagement.newData = true
            state.customerManagement.customersData = action.payload
        },
        getCustomerForAdminFilter: (state, action) => {
            state.customerManagement.filter= action.payload.tier
        },
        getAgentsForAdmin: (state, action) => {
            state.adminErrorMsg = ''
            state.loading = ''
            state.agentsManagement.newData = true
            state.agentsManagement.agentsData = action.payload
        },
        getAllTiers: (state, action) => {
            state.adminErrorMsg = ''
            state.loading = ''
            state.adminTierManagement.newData = true
            state.adminTierManagement.tiersData = action.payload
        },
        getAllRoles: (state, action) => {
            state.adminErrorMsg = ''
            state.loading = ''
            state.adminRoleManagement.newData = true
            state.adminRoleManagement.rolesData = action.payload
        }, 
        onRefreshRoles: (state, action) => {
            state.adminRoleManagement.refreshRoles = true
        }, 
        onRefreshTiers: (state, action) => {
            state.adminTierManagement.refreshTiers = true
        }, 
        onRefreshCustomers: (state, action) => {
            state.adminRoleManagement.refreshCustomers = true
        },
        // onRefreshAgents: (state, action) => {
        //     state.adminRoleManagement.refreshCustomers = true
        // },      
        onAddTier: (state, action) => {
            // state.adminUsers = []
            state.adminTierManagement.showTierForm = false
            state.adminErrorMsg = ''
            state.success_msg = strText.tier_added_successfully
        },
        onAddRole: (state, action) => {
            // state.adminUsers = []
            state.adminRoleManagement.showRoleForm = false
            state.adminErrorMsg = ''
            state.success_msg = strText.role_added_successfully
        }, 
        onUpdateRole: (state, action) => {
            state.success_msg = action.payload.message
        }, 
        onUpdateTier: (state, action) => {
            state.success_msg = strText.tier_updated_successfully
            // state.adminTierManagement.newData = true
        },       
        showTierFormFunc: (state, action) => {
            state.adminTierManagement.showTierForm = action.payload.modal
        },
        showRoleFormFunc: (state, action) => {
            state.adminRoleManagement.showRoleForm = action.payload.modal
        },
        getAllAdmins: (state, action) => {
            state.success_msg = ''
            state.adminUserManagement.adminUserData = action.payload
            state.adminUserManagement.newDataBot = true
        },
        onAddAdmin: (state, action) => {
            // state.adminUsers = []
            state.adminUserManagement.showAdminForm = false
            state.adminErrorMsg = ''
            state.success_msg = strText.admin_added_successfully
        },
        showAdminFormFunc: (state, action) => {
            state.adminUserManagement.showAdminForm = action.payload.modal
        },
        onAminStatusChange: (state,action) => {
            state.adminErrorMsg = ''
            state.success_msg = strText.update_admin_status
            state.adminUserManagement.showAminDetailsForm = false
            state.adminUserManagement.adminDetails = action.payload
        },
        onGetAuditLog : (state,action) => {
            state.auditLogs = action.payload
            state.audit_log_bot = true
        },
        //end of tested


        //
        closeFormConfirmationModal: (state) => {
            state.formConfirmationModal = false
        },
        closeSuccessModal: (state) => {
            state.successModal = false
        },
        //Transactions :: transfer, withdrawal, utility, deposit

        getWithdrawalData: () => {

        },
        getUtilityData: () => {

        },
        getDepositData: () => {

        },
        //general :: transaction/operations
        setActiveTabInfo: (state, action) => {
            // state.depositsTa
            let current_tab = action.payload.type

            switch (current_tab) {
                case type.utility:
                    //
                    state.formConfirmationModal = true
                    state.operationManagement.utility_form_step = action.payload?.current_step
                    state.operationManagement.utilityFormOneData.utility_type_id = action.payload.utility_type_id
                    break
                case type.customer:
                    state.customerManagement.current_tab = action.payload.current_step
                    break
                //transaction management :: set active tab and populate data for the new tab
                case type.transaction_transfer:
                    state.transactionsManagement.current_tab = current_tab
                    // adminSlice.caseReducers.getTransferData(state, [])
                    break
                case type.transaction_withdrawal :
                    state.transactionsManagement.current_tab = current_tab
                    break
                case type.transaction_deposit :
                    state.transactionsManagement.current_tab = current_tab
                    break;
                case type.transaction_utility:
                    state.transactionsManagement.current_tab = current_tab
                    break
                //end of transaction management
                // :::: //
                case type.card_inactive :
                    state.cardManagements.current_tab = action.payload.type
                    break
                case type.loans_active:
                    break
                case type.loan_type_form:
                    state.loanManagement.showFormModal = action.payload.modal
                    break
            }
        },
        setTabDetails: (state, action) => {
            let current_tab = action.payload.type
            switch (current_tab) {
                case type.transaction_transfer:
                    state.showModal = action.payload.modal
                    state.transactionsManagement.singleTransferDetails = action.payload.data
                    break;
            }

        },
        setOperationsForm: (state, action) => {
            let current_tab = action.payload.type

            state.tempFormResetFunc = action.payload?.resetFunc
            state.formConfirmationModal = true

            switch (current_tab) {
                case 'in_bank_deposit':
                    state.operationManagement.inBankDepositFormData = action.payload?.data
                    break;
                case 'tokenized_deposit':
                    state.operationManagement.tokenizedDepositFormData = action.payload?.data
                    break
                case type.in_bank_withdrawal :
                    state.operationManagement.inBankWithdrawalFormData = action.payload?.data
                    break
                case type.tokenized_withdrawal:
                    state.operationManagement.tokenizedWithdrawalFormData = action.payload?.data
                    break
                case type.transfer:
                    state.operationManagement.transferFormData = action.payload?.data
                    state.operationManagement.transferFormData.transfer_type_name = action.payload?.types_of_transfers.filter((item) => Number(item.id) === Number(action.payload?.data.transfer_type_id))[0].name
                    state.operationManagement.transferFormData.bank_name = action.payload?.banks.filter((item) => Number(item.id) === Number(action.payload?.data.recipient_financial_institution_id))[0].bank_name
                    break;
                case type.utility:
                    state.operationManagement.utilityFormOneData = action.payload?.data
                    state.operationManagement.utility_form_step = action.payload?.current_step
                    break
            }
        },
        submitOperationData: (state, action) => {
            let current_tab = action.payload.type
            state.formConfirmationModal = false
            state.successModal = true
            switch (current_tab) {
                case 'in_bank_deposit':
                    state.success_msg = 'Your money has been deposited successfully'
                    break
                case 'tokenized_deposit':
                    state.success_msg = 'Your money has been deposited successfully'
                    break
                case type.in_bank_withdrawal:
                    state.success_msg = 'Your withdrawal has been completed successfully'
                    break
                case type.tokenized_withdrawal:
                    state.success_msg = 'Your withdrawal has been completed successfully'
                    break
                case type.transfer:
                    state.success_msg = 'Your transfer has been completed successfully'
                    break;
                case type.utility:
                    state.success_msg = 'Your transfer has been completed successfully'
                    break
                default:
                    break
            }

            state?.tempFormResetFunc()
        },
        //customer ::
        setCustomerDetails: (state, action) => {
            state.customerManagement.customerDetails = action.payload.details
            state.customerManagement.showCustomerSuccessModal = action.payload.modal
        },
        setAgentDetails: (state, action) => {
            state.agentsManagement.agentDetail = action.payload.details
            state.agentsManagement.showAgentDetails = action.payload.modal
        },
        setCustomerShowActivateStatusModal : (state,action) => {
            state.customerManagement.customerDetails = action.payload.details
            state.customerManagement.showActivateStatusModal = action.payload.modal
        },
        setAgentShowActivateStatusModal : (state,action) => {
            state.agentsManagement.agentDetail = action.payload.details
            state.agentsManagement.showActivateStatusModal = action.payload.modal
        },
        setTierUpdateModal : (state,action) => {
            state.adminTierManagement.tierDetails = action.payload.details
            state.adminTierManagement.showTierUpdateModal = action.payload.modal
        },
        setRoleUpdateModal : (state,action) => {
            state.adminRoleManagement.roleDetails = action.payload.details
            state.adminRoleManagement.showRoleUpdateModal = action.payload.modal
        },        
        onUserStatusChange: (state,action) => {
            state.customerManagement.showActivateStatusModal = false
            state.success_msg = respMsg.change_customer_status
        },
        onRestUserPin: (state,action) => {
            state.customerManagement.showActivateStatusModal = false
            state.success_msg = respMsg.change_customer_pin
        },
        onRestUserPassword: (state,action) => {
            state.customerManagement.showActivateStatusModal = false
            state.success_msg = respMsg.change_customer_passcode
        },
        //agent
        handleAgent: (state, action) => {
            let type_ = action.payload.type
            switch (type_) {
                case type.agents_details:
                    state.agentsManagement.agentDetail = action.payload.data
                    state.agentsManagement.showAgentDetails = action.payload.modal
                    break
                case type.show_agent_bulk_import:
                    state.agentsManagement.showAgentImportModal = action.payload.modal
                    break
                case type.agent_new_data:
                    let current_step = action.payload.currentStep

                    if (Number(current_step) === 1) {
                        state.agentsManagement.agentsFormData.stepOne = action.payload.data
                    } else if (Number(current_step) === 2) {
                        const data = action.payload.data
                        state.agentsManagement.agentsFormData.stepTwo = data
                        state.agentsManagement.agentsFormData.stepTwo.account_class_name = state.accountClasses.filter((state) => Number(state.id) === Number(data.account_class_id))[0].name
                        state.agentsManagement.agentsFormData.stepTwo.currency_name = state.currencies.filter((state) => Number(state.id) === Number(data.currency_id))[0].name
                        state.agentsManagement.agentsFormData.stepTwo.account_category_name = state.accountCategories.filter((state) => Number(state.id) === Number(data.account_category_id))[0].name
                        state.agentsManagement.agentsFormData.stepTwo.opening_branch_name = state.accountBranches.filter((state) => Number(state.id) === Number(data.opening_branch_id))[0].name
                    } else if (Number(current_step) === 3) {
                        state.agentsManagement.agentsFormData.stepThree = action.payload.data
                    } else if (Number(current_step) === 4) {
                        state.agentsManagement.agentsFormData.stepFour = action.payload.data
                        state.agentsManagement.agentsFormData.stepFour.temp_url = action.payload.data.temp_url
                    } else if (Number(current_step) === 5) {
                        // state.tempFormResetFunc()
                        state.success_msg = 'Agent Added Successfully'
                        state.successModal = true
                    }
                    //
                    state.agentsManagement.agentsFormData.currentStep = action.payload.nextStep
                    break
            }

        },
        //card management
        handleCardManagement: (state, action) => {
            let card_operation_type = action.payload.type
            switch (card_operation_type) {
                case type.card_inactive :
                    state.cardManagements.showBlockModal = action.payload.modal
                    break
                case type.block_card:
                    //close block modal
                    state.cardManagements.showBlockModal = action.payload.modal
                    //properly show another modal for successful operation
                    break
            }

        },
        //loan management
        handleLoanSubmission: (state, action) => {
            const type_ = action.payload.type
            state.loanManagement.showFormModal = false
            state.successModal = true
            switch (type_) {
                case type.loan_type_form:
                    //handle loan type submission, set success modal to tru
                    //success msg
                    state.success_msg = 'Your loan has been created successfully'
                    break
            }

        },
        //system
        handleSystemManagement: (state, action) => {
            const type_ = action.payload.type
            switch (type_) {
                case type.add_new_user:
                    state.successModal = action.payload.modal
                    state.success_msg = 'User added Success fully'
                    break
                case type.admin_request_internet_banking:
                    state.systemManagement.internetBankingRequest.showRequestForm = action.payload.modal
                    break
                case type.set_admin_details_system :
                    state.adminUserManagement.showAminDetailsForm = action.payload.modal
                    state.adminUserManagement.adminDetails = action.payload.data
            }
        },

        //tier
        handleTierManagement: (state, action) => {
            const type_ = action.payload.type
            switch (type_) {
                case type.set_tier_details_system :
                    // state.adminUserManagement.showAminDetailsForm = action.payload.modal
                    state.adminTierManagement.tierDetails = action.payload.data
            }
        },

        //role
        handleRoleManagement: (state, action) => {
            const type_ = action.payload.type
            switch (type_) {
                case type.set_role_details_system :
                    // state.adminUserManagement.showAminDetailsForm = action.payload.modal
                    state.adminRoleManagement.roleDetails = action.payload.data
            }
        }        
    },
})

// Action creators are generated for each case reducer function
export const {
    logoutAdmin,
    setDailyPosition,
    setLoading,
    setLoading_,
    setErrorMsg,
    clearErrorMsg,
    setAdminDashboardData,
    setAdminReLogin,
    getTransferData,
    clearAdminErrorMsg,
    getInternetBankingRequest,
    getAccountOpeningRequest,
    clearResetBot,
    resetFilterDate,
    setAccountReqDetails,
    onAccountOpeningApprovalReq,
    onGetAccountOpeningRemark,
    onAccountOpeningApprovalApprove,
    setInternetReqDetails,
    onAdminLogin2,
    onInternetBankingApprovalReq,
    onGetInternetBankingRemark,
    onInternetBankingApprovalApprove,
    onAgentBankingApprovalApprove,
    getSettings,
    clearSettingsUpdated,
    onSubmitSettingsForApproval,
    approveSettings,
    getCustomerForAdmin,
    getAgentsForAdmin,
    getCustomerForAdminFilter,
    onAddAdmin,
    showAdminFormFunc,
    getAllAdmins,
    getAllTiers,
    getAllRoles,
    onRefreshRoles,
    onRefreshTiers,
    onRefreshCustomers,
    showTierFormFunc,
    showRoleFormFunc,
    onAddTier,
    onAddRole,
    onUpdateRole,
    onUpdateTier,
    setCustomerShowActivateStatusModal,
    setAgentShowActivateStatusModal,
    setRoleUpdateModal,
    setTierUpdateModal,
    onUserStatusChange,
    getAgentAccountOpeningRequest,
    setTransactionDate,
    onGetAgentBankingReqRemark,
    onAgentBankingApprovalReq,
    onAminStatusChange,
    setReload,
    onRestUserPassword,
    onRestUserPin,
    onGetTokenizedDeposit,
    setTokenizedDepositModal,
    adminDoTokenizedTransaction,
    onAdminLogin,
    onGetAuditLog,
    getAdditionalAccountRequest,
    setAdditionalAccountReqDetails,
    onAdditionalAccountOpeningRequestApprovalReq,
    onApproveAdditionalAccountOpeningRequestReq,
    //used  to force new data to work
    //ends of test









    closeSuccessModal,
    closeFormConfirmationModal,
    setAgentAccountReqDetails,
    //
    setOperationsForm,
    setActiveTabInfo,
    setTabDetails,
    //
    submitOperationData,
    //customer
    setCustomerDetails,
    setAgentDetails,
    //card
    handleCardManagement,
    //
    handleLoanSubmission,
    //
    handleSystemManagement,
    handleTierManagement,
    handleRoleManagement,
    handleAgent,
    setOpenConfirmationModal,
    setOpenConfirmationAgentModal,
    onApproveUser,
    onApproveUserAgent
} = adminSlice.actions

export default adminSlice.reducer