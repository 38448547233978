import {useDispatch, useSelector} from "react-redux";
import {
    closeFormConfirmationModal,
    setOperationsForm,
    submitOperationData
} from "../../../../../config/redux/slice/adminSlice";
import  {type} from "../../../../../config/redux/slice/adminSlice";
import {ButtonField, SelectField, TextInputField} from "../../../../../components/form/components";
// import {colors} from "../../../../../config/color";
import {Link} from "react-router-dom";


const AdminUtilityFormTwo = ({register,handleSubmit,errors,reset,backdrop, formData}) => {
    const {payment_methods }  = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()

    const onClose = () => {
        dispatch(closeFormConfirmationModal())
    }

    const onSubmit = (data) => {
        //update form two
        dispatch(setOperationsForm({data,type:type.utility,resetFunc:reset,current_step: 0}))
        //make request
        //success dis[
        dispatch(submitOperationData({data,type:type.utility,current_step: 0}))
    }
    return (
        <>
            <div className="modal fade show" id="basicModal" tabIndex="-1"
                 style={{
                     display: "block",
                     paddingRight: "17px"
                 }} aria-hidden="true">
                <div className="modal-dialog  modal-dialog-centered" style={{ background : colors.inputBg}}>
                    <div className="modal-content"  style={{ background : colors.inputBg}}>
                        <div className="modal-header" style={{border: "0px"}}>
                            {/*<h5 className="modal-title text-center mx-auto w-100"> </h5>*/}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>onClose()}></button>
                        </div>
                        <div className="modal-body" >
                            <div className={'mx-auto w-75'}>

                                <h5 className={'text-center'}>Details</h5>

                                <div className={' mx-auto w-100'}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className={'mb-3'}>
                                            <SelectField
                                                // selectFunc={}
                                                selectStyle={{paddingLeft:0}}
                                                register={register}
                                                isReq={true}
                                                error_msg={errors.utility_type_id?.message}
                                                label={'Payment Method'}
                                                defaultVal={formData.utility_type_id}
                                                fieldName={'utility_type_id'}
                                            >
                                                <option value={''}>Select Payment Method</option>
                                                {
                                                    payment_methods?.map((item,i=0) =>
                                                        <option key={i} value={item?.id}>{item?.name}</option>)
                                                }
                                            </SelectField>
                                        </div>
                                        <div className={'mb-5'}>
                                            <TextInputField
                                                fieldName={'customer_account_no'}
                                                defaultVal={''}
                                                label={'Customer Account Number'}
                                                error_msg={errors.customer_account_no?.message}
                                                isReq={true}
                                                register={register}
                                                placeholder={''}
                                                inputStyles={{paddingLeft:0}}
                                                patternRules={'numbers'}
                                                // onClickFunc={}
                                            />
                                        </div>

                                        {/*Account Name*/}
                                        <div className={'mb-5'}>
                                            <TextInputField
                                                fieldName={'customer_account_name'}
                                                defaultVal={''}
                                                label={'Customer Account Name'}
                                                error_msg={errors.customer_account_name?.message}
                                                isReq={true}
                                                register={register}
                                                placeholder={''}
                                                inputStyles={{paddingLeft:0}}
                                                patternRules={'letters'}
                                                // onClickFunc={}
                                            />
                                        </div>




                                        <div className={' d-flex flex-column gap-2'}>
                                            <ButtonField
                                                id={'d1'}
                                                btnWidth={'100'}
                                                btnTxt={'Make Payment'}
                                                bgColor={colors.secondary_color}
                                                txtColor={colors.white}/>
                                            {/*    */}
                                            <Link to={'#'} className={'btn w-100'} style={{background:colors.white,color:colors.secondary_color,border:`1px solid ${colors.secondary_color}`}}>
                                                Cancel
                                            </Link>
                                        </div>




                                    </form>
                                </div>
                            </div>

                            <p></p>

                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className={`modal-backdrop fade show  `}></div>}


        </>
    )
}

export  default  AdminUtilityFormTwo