
import { strText } from "../../../config/string";
import CustomerWrapper from "../CutomerWrapper";
import EmptyState from "../EmptyState";

const CardsHome = () => {
    return <CustomerWrapper title={'Cards'}>
                <div className={'row'}>
                    <EmptyState/>
                </div>
        {/* <div className="row">
            <div className={'col-sm-12  mx-auto text-center '}>
                <div className={'text-center px-5 py-0 my-auto'}>
                    <img src={'/assets/images/card_empty_state.svg'} alt={'card empty state'} />
                    <h4 className={'font-12 mt-3'}>{strText.card_empty_state}</h4>
                </div>
            </div>
            <div>
                <div className={'d-flex flex-column p-5 w-25'} style={{background:colors.primary_color}}>
                   <h6>logo</h6>

                   <h6>DEBIT CARD</h6>
                   <div className={'d-flex justify-content-between'}>
                       <small> Adedamola Alausa </small>
                       <small>12/24</small>
                       <small>
                           <img src={'../assets/images/master_card_icon.svg'} alt={''} height={'50'}/>
                       </small>
                   </div>

                </div>

            </div>

        </div> */}
    </CustomerWrapper>
}

export default CardsHome