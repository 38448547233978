import { TierOne, TierThree, TierTwo } from "./index";

const  Compliance = ({currentStep=3}) => {
    return(
        <>
            <div>
                <main className={''}>
                    <article>
                        <section className="multi_step_form">
                            <div id="msform">
                                <ul id="progressbar" className={'text-center'}>
                                    <li className={Number(currentStep)>=1 ? "active":""}>Tier 1</li>
                                    <li className={Number(currentStep)>=2 ? "active":""}>Tier 2</li>
                                    <li className={Number(currentStep)>=3 ? "active":""}>Tier 3</li>
                                </ul>

                                {Number(currentStep)===1 &&  <TierOne/> }
                                {Number(currentStep)===2 &&  <TierTwo/>}
                                {Number(currentStep)===3 &&   <TierThree/> }


                            </div>
                        </section>

                    </article>
                </main>
            </div>
        </>

    )
}


export default  Compliance