import {Link} from "react-router-dom";
import {urls} from "../../../config/urls/urls";

const CheckboxField = ({fieldName, placeholder, register, label, col, error_msg, isReq, defaultVal}) => {
    return (
        <>
            <input
                defaultChecked={defaultVal}
                id={fieldName}
                {...register(`${fieldName}`, {
                required: {
                    value: isReq,
                    message: '*this field is required'
                },
            })}
                className={`form-check-input ${error_msg && 'is-invalid'}`}
                type="checkbox"
            />
            {/*I agree with the Terms and Conditions*/}
            <label className="form-check-label mr-2" htmlFor="flexCheckChecked">
                &nbsp;  I agree with the <Link target={'_blank'} to={urls.onboard_terms_and_condition}> Terms and Conditions</Link>
            </label>
            {error_msg && <div className="invalid-feedback">{error_msg}</div>}
        </>
    )
}

export default CheckboxField