
const AlertWrapper = ({type,children, onClick}) => {

    return (
        <>
            {type==='success' ?
                  <div className="alert success">
                    <div style={{display: 'flex', alignItems: 'stretch', justifyContent: 'space-between'}} className="inner">
                        <div style={{display: 'flex', alignItems: 'center', gap: '0.6rem'}}>
                            <i style={{fontSize: '0.9rem'}}  className="fa fa-check-circle"></i>
                            {children}
                        </div>
                        <button type="button" onClick={onClick} style={{backgroundColor: 'transparent', fontSize: '0.9rem', color: '#65666d', outline: 'none', border: 'none'}}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </div>
                :
                <div className="alert error">
                    <div style={{display: 'flex', alignItems: 'stretch', justifyContent: 'space-between'}} className="inner">
                        <div style={{display: 'flex', alignItems: 'center', gap: '0.6rem'}}>
                            <i style={{fontSize: '0.9rem'}} className="fa fa-times-circle"></i>
                            {children}
                        </div>
                        <button type="button" onClick={onClick} style={{backgroundColor: 'transparent', fontSize: '0.9rem', color: '#65666d', height: 'fit-content', width: 'fit-content', outline: 'none', border: 'none'}}>
                            <i className="fa fa-times"></i>
                        </button>
                    </div>
                </div>

            }

        </>
    )
}

export default  AlertWrapper         