
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { clearAll } from "../../../../config/redux/slice/authData";

const AuthWrapper = ({children,scrollable=''}) => {
    const [showModal, setModal] = useState(false)
    //
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(clearAll({}))
    }, [])
    
    return(
        <div style={{overflowY:scrollable,height:"100vh"}}>

            <div>
                {children}
            </div>

        </div>
    )
}

export  default  AuthWrapper