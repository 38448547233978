import {createSlice} from '@reduxjs/toolkit'
import tinycolor from 'tinycolor2'

const initialState = {
    isSidebarOpen : true,
    isActive : 'dashboard',
    activeModule : 'Dashboard',
    isSubActive: '',
    isToggle : false,
    customisationExists: false,
    colorsExists: false,
    appLogo: '',  
    appName: '',
    colors : {
        primary_color: "",
        primary_color_light: "",
        primary_color_lighter: process.env.REACT_APP_PRIMARY_COLOR_LIGHTER,
        primary_color_landing_page: process.env.REACT_APP_PRIMARY_COLOR_LANDING_PAGE,
        primary_color_landing_page_one: process.env.REACT_APP_PRIMARY_COLOR_LANDING_PAGE_ONE,
        //secondary color
        secondary_color: "",
        //accent color
        accent_color: "",
        toggle_button_color: "",
        //
        inputBg: process.env.REACT_APP_INPUT_BG,
        background: process.env.REACT_APP_BACKGROUND,
        background_dark: process.env.REACT_APP_BACKGROUND_DARK,

        dark_color: process.env.REACT_APP_DARK_COLOR,
        form_text_color: process.env.REACT_APP_FORM_TEXT_COLOR,
        text_color: process.env.REACT_APP_TEXT_COLOR,
        // gray
        gray: process.env.REACT_APP_GRAY_COLOR,
        grayOne: process.env.REACT_APP_GRAY_ONE_COLOR,
        grayTwo: process.env.REACT_APP_GRAY_TWO_COLOR,
        // white
        white: process.env.REACT_APP_WHITE_COLOR,
        white_main: process.env.REACT_APP_WHITE_COLOR_MAIN,
    }
}

export const appSlice = createSlice({
    name: 'authApp',
    initialState,
    reducers: {
        onSidebarChange: (state) => {
            state.isSidebarOpen = !state.isSidebarOpen
            let x = document.getElementById('html_tag')
            //wf-active nav_open
            !state.isSidebarOpen ?   x.classList.add("sidebar_minimize") : x.classList.remove("sidebar_minimize")
            !state.isSidebarOpen ?   x.classList.add("nav_open") : x.classList.remove("nav_open")
        },
        onLoadMinimize : (state)=>{
            state.isSidebarOpen = false
            let x = document.getElementById('html_tag')
            //wf-active nav_open
            // !state.isSidebarOpen ?   x.classList.add("sidebar_minimize") : x.classList.remove("sidebar_minimize")
             x.classList.remove("nav_open")
        },
        onToggleClick : (state) => {
            state.isToggle  = !state.isToggle
            let x = document.getElementById('html_tag')
            !state.isToggle ? x.classList.add("wf-active") : x.classList.remove("wf-active")
            !state.isToggle ? x.classList.add("topbar_open") : x.classList.remove("topbar_open")
        },

        setActiveSidebar : (state,action) => {
            state.isActive = action.payload.mainPath
            state.activeModule = action.payload.moduleName
            state.subPath = action.payload.subPath

        },

        setCustomisationValues : (state, action) => {
            // let color = action.payload ? tinycolor(action.payload.accent_color) : {isDark: () => false}
            let color = tinycolor(action.payload?.accent_color ?? state.colors.accent_color)
            state.colors.primary_color = action.payload?.primary_color ?? state.colors.primary_color
            state.colors.secondary_color = action.payload?.secondary_color ?? state.colors.secondary_color
            state.colors.accent_color = action.payload?.accent_color ?? state.colors.accent_color
            state.colors.primary_color_light = action.payload?.accent_color ?? state.colors.primary_color_light
            state.colors.toggle_button_color = color.isDark() ? '#ffffff' : '#000000'
            state.appLogo = action.payload?.logo_url ?? state.appLogo
            state.appName = action.payload?.bank_name ?? state.appName
            document.title = action.payload?.bank_name ?? document.title
            state.customisationExists = true
        },
        setDefaultIcon: (state, action) => {
            const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.rel = 'icon';
            link.type = 'image/x-icon';
            link.href = state.appLogo;
            document.head.appendChild(link);
        },
        setDefaultColors : (state, action) => {
            document.documentElement.style.setProperty(
                '--primaryColor',
                state.colors.primary_color
            )
            document.documentElement.style.setProperty(
                '--primaryColorLight',
                state.colors.primary_color_light
            )
            document.documentElement.style.setProperty(
                '--secondaryColor',
                state.colors.secondary_color
            )
            document.documentElement.style.setProperty(
                '--primaryColorLighter',
                state.colors.primary_color_lighter
            )
            document.documentElement.style.setProperty(
                '--toggleButtonColor',
                state.colors.toggle_button_color
            )

            document.documentElement.style.setProperty(
                '--primaryColorLandingPage',
                state.colors.primary_color_landing_page
            )

            document.documentElement.style.setProperty(
                '--primaryColorLandingPageOne',
                state.colors.primary_color_landing_page_one
            )

            document.documentElement.style.setProperty('--formTextColor', `${state.colors.form_text_color}`);
            document.documentElement.style.setProperty('--darkColor', `${state.colors.dark_color}`);
            document.documentElement.style.setProperty('--textColor', `${state.colors.text_color}`);

            document.documentElement.style.setProperty('--background', `${state.colors.background}`);
            document.documentElement.style.setProperty('--backgroundDark', `${state.colors.background_dark}`);

            document.documentElement.style.setProperty('--grayColorOne', `${state.colors.grayOne}`);
            document.documentElement.style.setProperty('--grayColorTwo', `${state.colors.grayTwo}`);
            document.documentElement.style.setProperty('--whiteOne', `${state.colors.white_main}`);
            state.colorsExists = true
        }
} 
})

// Action creators are generated for each case reducer function
export const {
    onSidebarChange,
    setActiveSidebar,
    onLoadMinimize,
    onToggleClick,
    setCustomisationValues,
    setDefaultColors,
    setDefaultIcon
} = appSlice.actions

export default appSlice.reducer