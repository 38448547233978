import {useSelector} from "react-redux";
import BlockCardModal from "./BlockCardModal";

const CardActive = () => {
    const {cardManagements:{cardsData}} = useSelector((state) =>state.administrator)
    return <>

    </>
}

export  default  CardActive