import jwt_decode from "jwt-decode";
import moment from "moment";
import {colors} from "../color";


export const setDefaultColors = () => {
    document.documentElement.style.setProperty(
        '--primaryColor',
        colors.primary_color
    )
    document.documentElement.style.setProperty(
        '--primaryColorLight',
        colors.primary_color_light
    )
    document.documentElement.style.setProperty(
        '--secondaryColor',
        colors.secondary_color
    )
    document.documentElement.style.setProperty(
        '--primaryColorLighter',
        colors.primary_color_lighter
    )

    document.documentElement.style.setProperty(
        '--primaryColorLandingPage',
        colors.primary_color_landing_page
    )

    document.documentElement.style.setProperty(
        '--primaryColorLandingPageOne',
        colors.primary_color_landing_page_one
    )

    document.documentElement.style.setProperty('--formTextColor', `${colors.form_text_color}`);
    document.documentElement.style.setProperty('--darkColor', `${colors.dark_color}`);
    document.documentElement.style.setProperty('--textColor', `${colors.text_color}`);

    document.documentElement.style.setProperty('--background', `${colors.background}`);
    document.documentElement.style.setProperty('--backgroundDark', `${colors.background_dark}`);

    document.documentElement.style.setProperty('--grayColorOne', `${colors.grayOne}`);
    document.documentElement.style.setProperty('--grayColorTwo', `${colors.grayTwo}`);
    document.documentElement.style.setProperty('--whiteOne', `${colors.white_main}`);

}

export const returnFormattedDate = (start_date, end_date) => {
    let fromDate = start_date ? moment(start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')

    let toDate = end_date ?
        moment(end_date).add(1,'days').format('YYYY-MM-DD')
        :
        moment().add(1,'days').format('YYYY-MM-DD')

     return {
        fromDate ,
         toDate
     }
}

export const decryptToken = (token) => {
    return jwt_decode(token)
}

export const formatErrorMessage = (error) => {
    //error.response ,  error.response.data.errors,  error.response.data.message, error.response.data.errors
    // ||  error.response?.data.message || error.response?.data.errors
    return error.response !== undefined ?
        error.response.data.error !== undefined ?
            error.response.data.error : error.response.data.message
        :
        error.message
}

export const formatMessages = (msg) => {
    //

    let x  = msg.toLowerCase().charAt(0).toUpperCase() + msg.slice(1)
    return  x.replace("_"," ")
}

export const NumberFormatter = (value, decimal) => {
    return Number(value).toLocaleString() + ".00"
};

export const reverseNumber = (value) => {
    return value.split(',').join('')
}

