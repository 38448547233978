// import {colors} from "../../../config/color";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setOpenConfirmationModal, setLoading, getCustomerForAdmin, setErrorMsg, onApproveUser, onRefreshCustomers, setCustomerDetails, getCustomerForAdminFilter} from "../../../config/redux/slice/adminSlice";
import { appConfig, endpointUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import { Loader } from "../../../components";
import { useEffect } from "react";

const ConfirmationModal = ({backdrop,modalFunc}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {OpenConfirmationModal, openConfirmationModalDetails, loading, customerManagement: {customerDetails, filter}} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    //
    const onClose = () => {
        dispatch(setOpenConfirmationModal({open: false,
        details: {
            heading: '',
            body: '',
            buttonText: '',
            extraDetails: ''
        }}))
    }
    //
    const continueAction = async () => {
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.approveUserTier}`,
            {phone: customerDetails.phone, 
            status: openConfirmationModalDetails.extraDetails == 'approved' ? 'approved' : 'disapproved'},
            dispatch,
            setLoading,
            "",
            setErrorMsg
        )
        if(res != undefined && res.status < 400){
            getAllCustomer(res.data.message)
        }
    }

    const getAllCustomer = async (message) => {
        
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getAllUsers}`,
            {
                tier: 'all'
                
            },
            dispatch,
            setLoading,
            getCustomerForAdmin,
            setErrorMsg)
        if(res != undefined && res.status < 400){
            dispatch(getCustomerForAdminFilter({tier: 'all'})) 
            let data = (res.data.data).find(obj => obj.customer_id === customerDetails?.customer_id);
            dispatch(setOpenConfirmationModal({open: false,
                details: {
                heading: '',
                body: '',
                buttonText: '',
                extraDetails: ''
            }}))
            dispatch(onApproveUser({message: message}))
            dispatch(onRefreshCustomers())   
            dispatch(setCustomerDetails({details: data, modal: true})) 
        }
    }    

    return <>
        <div className="modal fade show" id="basicModal" tabIndex="-1"
             style={{display: "block"}} aria-hidden="true">
            <div className="modal-dialog  modal-sm modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header" style={{border: "0px"}}>
                        <h5 className="modal-title mx-auto w-100"> {openConfirmationModalDetails.heading} </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                                onClick={()=>onClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-0">
                        <div className={'px-sm-1 px-md-2 pb-2'}>
                            <p>{openConfirmationModalDetails.body} {customerDetails.firstname}? </p>
                            <div className="row justify-content-start align-items-center">
                            <button
                                disabled={loading}
                                className={'btn mr-2 btn-sm'}
                                onClick={()=>onClose()}
                                 style={{background:colors.white,color:colors.secondary_color,border:`1px solid ${colors.secondary_color}`}}
                            >
                               Cancel
                            </button>
                            {loading ? <Loader 
                                parentStyles={{margin: '0 0', width: '24px', height: '24px'}}
                                childsStyles={{width: '24px', height: '24px', 
                                    marginTop: '-12px', marginLeft: '-12px', 
                                    borderWidth: '3px'
                                }}
                            /> :
                            <button className={'btn btn-sm'} onClick={() => continueAction() }
                                 style={{background:colors.secondary_color,color:colors.white}}>
                               {openConfirmationModalDetails.buttonText}
                            </button> }                                
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        {backdrop && <div className={`modal-backdrop fade show  `}></div>}
    </>
}
export default  ConfirmationModal