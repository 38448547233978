import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { appConfig, remoteUrl } from "../../../../config/config";
import { postRequest2 } from "../../../../config/redux/request";

import { Loader } from "../../../../components";
import { ButtonField, SelectField, TextInputField } from "../../../../components/form/components";
// import {colors} from "../../../../config/color";

import {
    clearAdminErrorMsg,
    onAddAdmin,
    setErrorMsg,
    setLoading,
    showAdminFormFunc
} from "../../../../config/redux/slice/adminSlice";
import { pagesText } from "../../../../config/string/english";
import { PhoneNumberComponent } from "../../../onboard_one/components";
import { useEffect } from "react";

const AddAdmin = ({backdrop}) => {
    const {token,user_role} = useSelector((state) => state.authData)
    const {adminRoleManagement: {rolesData}, loading,temp_error_msg,success_msg} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [roleList, setRoleList] = useState([])

    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const dispatch = useDispatch()

    useEffect(()=>{
        let superAdminOptions = rolesData.filter((role)=>(pagesText.superAdmin_users).includes((role.role_name).toLowerCase()))
        let adminOptions = rolesData.filter((role)=>(pagesText.admin_users).includes((role.role_name).toLowerCase()))
        
        if(user_role == 'super admin'){
            setRoleList(superAdminOptions)
        }else if(user_role == 'admin'){
            setRoleList(adminOptions)
        }
    }, [])

    const submitData = (data) => {
        dispatch(clearAdminErrorMsg())
        let phone = data.code + data.mobile;
        // let phone = data.mobile
        // if(data.mobile.length == 11 || data.mobile.charAt(0) == '0'){
        //     phone = '234' + data.mobile.slice(1);
        // } else{
        //     phone = '234' + data.mobile
        // }
        // data.jwt = token
      let x  =  postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.addAdmin}`,
          {...data, phone},
            dispatch,
            setLoading,
            onAddAdmin,
            setErrorMsg
        )
    }

    const closeModal = () => {
        dispatch(clearAdminErrorMsg())
        dispatch(showAdminFormFunc({modal:false}))
    }


    return (
        <>

            <div className="modal fade show"
                 id="basicModal"
                 tabIndex="-1" aria-hidden="true"
                 style={{
                     display: "block",
                     paddingRight: "17px"
                 }}
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header" style={{border: "0px"}}>
                            <h3 className=" " style={{color:colors.secondary_color}}> {pagesText.admin_user_add} </h3>
                            <button
                                type="button" className="close" data-dismiss="modal"
                                aria-label="Close"
                                onClick={()=> closeModal()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" >



                            <div className={'px-3 py-3'}>
                                {temp_error_msg==='SSO account already exists'&&
                                <div className={'alert alert-danger mb-4'} style={{color:colors.secondary_color}}>
                                  QApp profile for this admin already exist, kindly notify the admin to activate the account on the platform
                                </div> }
                                <form onSubmit={handleSubmit(submitData)}>
                                    <div className={'row mb-4'}>
                                        <div className={'col-sm-12 col-md-6'}>
                                            <TextInputField
                                                fieldName={'firstname'}
                                                error_msg={errors.firstname?.message}
                                                col={'col-xl-12'}
                                                label={"Firstname*"}
                                                placeholder={''}
                                                isReq={true}
                                                register={register}
                                                defaultVal={''}
                                                patternRules={'letters'}
                                            />
                                        </div>
                                        {/* <div className={'col-sm-12 col-md-4'}>
                                            <TextInputField
                                                fieldName={'midllename'}
                                                error_msg={errors.midllename?.message}
                                                col={'col-xl-12'}
                                                label={"Middle Name*"}
                                                placeholder={''}
                                                isReq={false}
                                                register={register}
                                                defaultVal={''}
                                                patternRules={'letters'}
                                            />
                                        </div> */}
                                        <div className={'col-sm-12 col-md-6'}>
                                            <TextInputField
                                                fieldName={'lastname'}
                                                error_msg={errors.lastname?.message}
                                                col={'col-xl-12'}
                                                label={"Lastname*"}
                                                placeholder={''}
                                                isReq={true}
                                                register={register}
                                                defaultVal={''}
                                                patternRules={'letters'}
                                            />
                                        </div>

                                    </div>

                                    <div className={'row mb-4'}>
                                        {/*Phone Number*/}
                                        <div className={'col-sm-12 col-md-6'}>
                                            <PhoneNumberComponent
                                                fieldName={'mobile'}
                                                setValueFunc={setValue}
                                                error_msg={errors.mobile?.message}
                                                col={'col-xl-12'}
                                                label={'Phone Number'}
                                                nextCompId={'password'}
                                                placeholder={''}
                                                isReq={true}
                                                register={register}
                                                defaultVal={''}
                                                patternRules={''}
                                            />
                                        </div>
                                        {/*email*/}
                                        <div className={'col-sm-12 col-md-6'}>
                                            <TextInputField
                                                fieldName={'email'}
                                                defaultValue ={''}
                                                error_msg={errors.email?.message}
                                                col={'col-xl-12'}
                                                label={"Email*"}
                                                placeholder={''}
                                                isReq={true}
                                                register={register}
                                                patternRules={'email'}
                                            />
                                        </div>

                                        <div className={'col-sm-12 col-md-6'}>
                                            <SelectField
                                                fieldName={'admin_type'}
                                                register={register}
                                                isReq={true}
                                                error_msg={errors.admin_type?.message}
                                                label={'User Role'}
                                                defaultVal={''}
                                                // col={} selectStyle={} selectFunc={}
                                            >
                                                <option value={''}>Select User Role</option>
                                                {roleList?.map((role,i=0) =>
                                                    <option className="text-capitalize" value={role.id} key={i+1}>{role.role_name}</option>
                                                )}
                                            </SelectField>
                                        </div>
                                    </div>

                                    <div className={'row mb-2'}>
                                        {/* password */}
                                        {/* <div className={'col-md-6 mb-2'}>
                                            <div className="form-group">
                                                <label>Password*</label>
                                                <div className="input-group"
                                                     style={{border: `0.05rem solid #ced4da`,borderRadius:"50px"}}>
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        className={`form-control ${formStyles.passwordTextField} ${errors.password?.message && 'is-invalid'}`}
                                                        aria-label=""
                                                        aria-describedby="basic-addon2"
                                                        placeholder={''}
                                                        defaultValue={''}
                                                        autoComplete={'off'}
                                                        autoCorrect={'off'}
                                                        style={{border: 0}}
                                                        {...register(`password`, {
                                                            required: {
                                                                value: true,
                                                                message: '*this field is required',
                                                            },
                                                            minLength: {
                                                                value: 6,
                                                                message: 'password too short'
                                                            },
                                                            validate: value => value === getValues('password') || "The passwords do not match"
                                                        })
                                                        }
                                                    />
                                                    <div className="input-group-append">
                                                                <span
                                                                    id="basic-addon2"
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                                                    style={{border: 0}}
                                                                >
                                                                    <img src={'../../assets/images/admin/auth/open_wide.svg'} alt={''}/>
                                                                </span>
                                                    </div>
                                                </div>
                                                {errors.password && <div className="text-danger">{errors.password?.message}</div>}
                                            </div>
                                        </div> */}
                                        {/*Confirm Password :: */}
                                        {/* <div className={'col-md-6 mb-2'}>
                                            <div className="form-group">
                                                <label>Confirm Password*</label>
                                                <div className="input-group"
                                                     style={{border: `0.05rem solid #ced4da`,borderRadius:'50px'}}>
                                                    <input
                                                        type={showConfirmPassword ? 'text' : 'password'}
                                                        className={`form-control ${formStyles.textField} ${errors.confirm_password?.message && 'is-invalid'}`}
                                                        aria-label=""
                                                        aria-describedby="basic-addon2"
                                                        placeholder={''}
                                                        defaultValue={''}
                                                        autoComplete={'off'}
                                                        autoCorrect={'off'}
                                                        style={{border: 0}}
                                                        {...register(`confirm_password`, {
                                                            required: {
                                                                value: true,
                                                                message: '*this field is required',

                                                            },
                                                            minLength: {
                                                                value: 6,
                                                                message: 'password too short'
                                                            },
                                                            validate: value => value === getValues('password') || "The passwords do not match"
                                                        })
                                                        }
                                                    />


                                                    <div className="input-group-append">
                                                                <span
                                                                    id="basic-addon2"
                                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                    className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                                                    style={{border: 0}}
                                                                >
                                                                    <img src={'../../assets/images/admin/auth/open_wide.svg'} alt={''}/>
                                                                </span>
                                                    </div>
                                                </div>
                                                {errors.confirm_password && <div className="text-danger">{errors.confirm_password?.message}</div>}
                                            </div>
                                        </div> */}
                                    </div>




                                    <div className={'row d-flex justify-content-center mt-2'}>

                                        {loading ? <Loader/> :
                                            <ButtonField
                                                txtColor={colors.white}
                                                bgColor={colors.secondary_color}
                                                btnTxt={pagesText.admin_user_add}
                                                btnWidth={'100'}
                                                btnStyle={'mx-auto col-xl-7 mt-1 pt-2 pb-2'}/>}
                                    </div>
                                </form>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className="modal-backdrop fade show"></div>}
        </>
    )
}
export default  AddAdmin