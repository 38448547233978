import { useState } from "react";
import { useDispatch } from "react-redux";
import { CardWrapper } from "../../components";
// {"beneficiary_name":"DERE Toluwani","account_number":"9081708559"}
const AvailableAccountsComponent = ({updateFieldFunc, fieldToUpdateArray, accountList,backdrop,clearErrors,closeFunc}) => {
    const [newBankList, setNewAccountList] = useState(accountList)
    const dispatch = useDispatch()

    const searchData = (searchText) => {
        if(searchText.length>0){
            let NewData = accountList.filter((item) =>
                `/${item.beneficiary_name.toLowerCase()}*/gi`.match(searchText.toLowerCase()) ||
                `/${item.account_number}*/gi`.match(searchText)
            )
            setNewAccountList(NewData)
        }else{
            setNewAccountList(accountList)
        }
    }

    const onClose = () => {closeFunc(false)}

    const selectedData = (data) => {

        updateFieldFunc(fieldToUpdateArray[1], data.account_number)
        updateFieldFunc(fieldToUpdateArray[2], data.beneficiary_name)
        updateFieldFunc(fieldToUpdateArray[0], data.bank_id)
        clearErrors('account_number')
        clearErrors('beneficiary')
        closeFunc(false)
    }

    return (
        <>
            <div className="modal fade show" id="exampleModalCenter" style={{display: "block", paddingRight: "17px"}}
                 aria-modal="true" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable " role="document">
                    <div className="modal-content " style={{maxHeight: '90vh', overflowY: 'auto'}}>
                        <div className="modal-header" style={{border: "0px"}}>
                            <button type="button" className="close" data-dismiss="modal"  aria-label="Close" onClick={() => onClose()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className={'mx-auto w-100 py-1'}>
                                <CardWrapper
                                    cardWrapperStyle={''}
                                    cardWidth={'w-100 px-1 py-1 mx-auto'}
                                    cardTitle={''}
                                    cardTitleColor={''}
                                    showShadow={false}
                                >
                                    <div className={''}>
                                        <input
                                            type={'text'}
                                            className={'form-control input-square'}
                                            style={{width:'100%'}}
                                            placeholder={'search receiver account number'}
                                            onChange={(e) => searchData(e.target.value)}/>
                                        <div>
                                            <table className={'table table-striped mt-3'}>
                                                <tbody>
                                                {newBankList?.map((item,i=0) =>
                                                    <tr key={i} onClick={() => selectedData(item)}>
                                                        <td>{item?.beneficiary_name}</td>
                                                        <td>{item?.account_number}</td>
                                                    </tr>
                                                )}
                                                </tbody>

                                            </table>

                                        </div>
                                    </div>


                                </CardWrapper>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className="modal-backdrop fade show"></div>}
        </>

    )
}
export default  AvailableAccountsComponent