import { useDispatch, useSelector } from "react-redux";
import {
    clearResetBot,
    onGetTokenizedDeposit, setErrorMsg, setLoading, setTokenizedDepositModal
} from "../../../../config/redux/slice/adminSlice";
import AdminWrapper from "../../AdminWrapper";

import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styles from "../../../../assets/css/formStyle.module.css";
import { Loader } from "../../../../components";
import { ButtonField } from "../../../../components/form/components";
// import { colors } from "../../../../config/color";
import { appConfig, endpointUrl } from "../../../../config/config";
import { initAdminDepositTable } from "../../../../config/datatableInit";
import { returnFormattedDate } from "../../../../config/redux/actions";
import { postRequest2 } from "../../../../config/redux/request";
import { respMsg } from "../../../../config/response_messages";
import { strText } from "../../../../config/string";
import { pagesText } from "../../../../config/string/english";
import { TokenizedDepositDetails } from "./index";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 * @description
 * :: In-Bank Deposit
 * Deposit Form --> Confirmation Form -> SuccessModal
 * :: TokenizedDepositForm -->  Confirmation Form --> Success
 */

const AdminDepositHome = () => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {userData:{modules, permissions},token,user_role,moduleRoles} = useSelector((state) => state.authData)
    const {success_msg, loading, defaultFilterDate, tokenizedDepositManagement: {tokenizedData, newData,showDetailsModal,tokenizedDetails}} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        initAdminDepositTable(tokenizedData, setSingleItem,user_role)
        getDepositData({
            start_date: defaultFilterDate?.start_date,
            end_date: defaultFilterDate?.end_date
        })
    },[])

    const getDepositData = (data) => {
        //
        const filterDate = returnFormattedDate(data?.start_date ?? '',data?.end_date?? '')

        const postData = {
            "jwt": token,
            "start_date" : filterDate.fromDate,
            "end_date" : filterDate.toDate
        }

        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.adminGetTokenizedDeposit}`,
            postData,
            dispatch,
            setLoading,
            onGetTokenizedDeposit,
            setErrorMsg)
    }

    const setSingleItem = (data) => {
        dispatch(setTokenizedDepositModal({
            modal:true,
            data
        }))

    }

    const onClose = () => {
        dispatch(setTokenizedDepositModal({
            modal:false,
            data : ''
        }))
    }

    //
    useEffect(()=>{
        if (newData) {
            initAdminDepositTable(tokenizedData, setSingleItem,user_role)
            dispatch(clearResetBot())
        }
        if(success_msg===respMsg.admin_tokenized_deposit_s){
            getDepositData({
                start_date: document?.getElementById('start_date')?.value,
                end_date : document?.getElementById('end_date')?.value
            })
        }        
    }, [newData, success_msg])

    return <AdminWrapper title={''}>

        {showDetailsModal &&  <TokenizedDepositDetails show={showDetailsModal} onClose={onClose} transactionDetails={tokenizedDetails}/>}

        <div className={'card'}>
            <div className={'card-header'}>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <h2 className={'tableHeader mt-2'}>{pagesText.tokenized_transactions}</h2>
                    </div>
                    <div className={'col-8'}>
                        <form onSubmit={handleSubmit(getDepositData)}>
                            <div className={'row d-flex align-items-center pt-1 px-3'}>
                                <div className={'col-sm-12 col-md-4'}>
                                    <input
                                        type={'date'}
                                        id={'start_date'}
                                        defaultValue={defaultFilterDate?.start_date ?? ''}
                                        {...register('start_date')}
                                        className={`form-control ${styles.textField}`}
                                    />
                                </div>

                                <div className={`col-sm-12 col-md-4`}>
                                    <input type={'date'}
                                           defaultValue={defaultFilterDate?.end_date ?? ''}
                                           id={'end_date'}
                                           {...register('end_date')}
                                           className={`form-control ${styles.textField} `}/>
                                </div>


                                <div className={' col-sm-12 col-md-4'}>
                                    {loading ? <Loader/> :
                                        <ButtonField
                                            btnStyle={'py-2'}
                                            // btnAdditionalStyle={}
                                            id={'search'}
                                            txtColor={colors.white}
                                            bgColor={colors.secondary_color}
                                            btnWidth={'100'}
                                            btnTxt={'Apply'}
                                        />
                                    }
                                </div>
                            </div>
                        </form>
                    </div>







                </div>



            </div>
            <div className={'card-body'}>
                <table className={`${strText.datatable_class}`} id={'table_admin_trans'}>
                    <thead>
                    <tr>
                        <th > Deposit Account </th>
                        <th> Expected Amount </th>
                        <th> Actual Amount </th>
                        <th> Token </th>
                        <th width={'15%'} > Date </th>
                        <th> Status </th>
                        <th width={'5%'} data-orderable="false" style={{color:colors.secondary_color}}> Action </th>
                    </tr>
                    </thead>
                    <tbody></tbody>
                </table>

            </div>

        </div>






        {/*Step::OnSuccess*/}

    </AdminWrapper>;
}

export default AdminDepositHome