import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { ButtonField, PasswordField } from "../../components/form/components";
// import { colors } from "../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../config/config";
import { postRequest, postRequest2 } from "../../config/redux/request";
import { logoutAdmin, onAdminLogin } from "../../config/redux/slice/adminSlice";
import {
    auth_types,
    changeModalSize,
    clearAll, logoutUser, onCustomerEmailVerificationRequest,
    onCustomerLogin,
    onCustomerLogin2,
    setAuthModalForm,
    setErrorMessage,
    setLoading
} from "../../config/redux/slice/authData";
import { logoutCustomer, onCustomerLoginMe } from "../../config/redux/slice/customerSlice";
import { pagesText } from "../../config/string/english";
import { urls as onBoardUrls, urls } from "../../config/urls/urls";
import AlertWrapper from "../_shared_component/AlertWrapper";
import LoadingModal from "../_shared_component/LoadingModal";
import AuthWrapperCustomer from "../v1/onboard/components/AuthWrapperCustomer";


const PassCodeScreen = () => {
    const navigate = useNavigate();

    const modalRef = useRef(null);
    // Check LoginNew Component for reference
    const [togglePassword, setTogglePassword] = useState(false)
    const [modalType, setModalType] = useState('hide')
    const {authSize} = useSelector((state) => state.authData)
    const {
        loading,
        showLoginModal,
        user_role,
        errorMsg,
        success_msg,
        tempEmail,
        token,
        userData
    } = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)

    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [isEmailVerified, setEmailVerified] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const dispatch = useDispatch()

    useEffect(()=> {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
    },[])

 const phone = useSelector((state) => state?.authData?.userRegDetails?.phone)
  const user = useSelector((state) => state?.authData?.userRegDetails?.user)
  const type = useSelector((state) => state?.authData?.userRegDetails?.type)
//     const otp = useSelector((state) => state?.authData?.userRegDetails?.otp)

    const closeErrorAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    // const closeSuccessAlert = () => {
    //     setSuccessMessage(false)
    // }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        let newData;
        if(type == 'sso') {
            newData = {...data, phone:phone, user: user}
        } else{
           newData = {...data, phone: phone}
        }
        
        let passcodeUrl = `${endpointUrl.login}`

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            passcodeUrl,
            newData,
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )

        if(res !== undefined){
            if(res.status < 400){
                if((res?.data?.data?.user_role_text).toLowerCase() != 'customer'){
                    dispatch(setErrorMessage({message:'Customer does not exist.'}))

                    navigate(urls.login)

                }else{
                    dispatch(onCustomerLogin(res.data.data))
                    navigate(`${onBoardUrls.customerDashboard}`)
                }
            } else if(type == 'sso'){
                dispatch(onCustomerLogin2({type: '', data:{...newData, type: 'sso'}}))
                navigate(onBoardUrls.otp_screen)
            }
            else if(res.data.error_code == 12 || res.data.error_code == 13){
                dispatch(onCustomerLogin2({type: '', data:{...newData, type: 'new_device'}}))
                navigate(`${onBoardUrls.otp_screen}`)
            }
        }

    }
    //
    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }

        useEffect(()=>{
    
    if (success_msg !== "" && isEmailVerified) {
        // dispatch(setAuthModalForm({modal: true, type: auth_types.show_email_verification_form}))
        navigate(urls.customerEmailVerification)
    }

    if (token!=='' && user_role === 'customer') {
        dispatch(onAdminLogin())
        dispatch(onCustomerLoginMe())
        dispatch(setAuthModalForm({type:auth_types.show_login_form,modal:false}))
        if(userData.account_numbers.length>0){
            navigate(urls.customerDashboard)
        }
        else{
            dispatch(logoutUser())
            dispatch(logoutAdmin())
            dispatch(logoutCustomer())
            dispatch(setErrorMessage({message:'Your account is yet to be activated. You will get an email notification once your account is activated.'}))
        }

    }

    if (token!=='' && user_role != 'customer') {
            dispatch(logoutUser())
            dispatch(logoutCustomer())
            dispatch(logoutAdmin())
            dispatch(setAuthModalForm({type:auth_types.show_login_form,modal:false}))
            // dispatch(setErrorMessage({message:'Customer does not exist.'}))

            navigate(urls.login)
        }

    }, [success_msg, token, user_role])
    
    //
    return <AuthWrapperCustomer title='Online Banking Login'>  

        <fieldset>
            <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
                                            {
                    errorMsg &&
                    <AlertWrapper onClick={closeErrorAlert} type={'error'}>
                        <div className={'py-1'}>
                            {errorMsg === "Cannot read properties of undefined (reading 'account_number')"
                                ?
                                "Your account request is still pending, you will get a email notification upon approval."
                                :
                                errorMsg === 'verify email first' ?
                                    <div className="">

                                        Your email needs to be verified before you can use online banking.
                                        <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}>
                                            <span className={'text-primary'}> <u> Click here </u> </span>
                                        </Link>to verify.
                                    </div>
                                    :
                                    errorMsg === 'Request failed with status code 429' ?
                                        'To many Request. Please wait for 15min' : errorMsg
                            }
                        </div>
                    </AlertWrapper>
                }
                <div className={'row mb-2'}>
                    <div className={'col-xl-12'}>
                        <PasswordField
                            fieldName={'passcode'}
                            setValueFunc={setValue}
                            error_msg={errors.passcode?.message}
                            col={'col-xl-12'}
                            label={'Enter your Passcode'}
                            nextCompId={'password'}
                            placeholder={''}
                            isReq={true}
                            minlength={6}
                            maxlength={6}
                            register={register}
                            defaultVal={''}
                            patternRules={'passcode'}
                        />
                    </div>
                    <small id="emailHelp" className="form-text text-muted ml-4 mt-2 mb-2">
                        <Link to={onBoardUrls.passcode_reset} style={{color: colors.primary_color}}>Forgot your Passcode?</Link>
                        </small>
                </div>

                <div className={'row'}>
                    {loading ? <Loader/> :
                        <ButtonField
                            txtColor={colors.white}
                            bgColor={colors.primary_color}
                            btnTxt={'Sign In'}
                            btnWidth={'100'}
                            btnStyle={'mx-auto col-xl-12 py-2'}/>}
                </div>
            </form>
        </fieldset>
        <LoadingModal type={modalType}/>
    </AuthWrapperCustomer>
}


export default PassCodeScreen
