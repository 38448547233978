import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {postRequest, postRequest2} from "../../../../config/redux/request";
import {appConfig, endpointUrl, remoteUrl} from "../../../../config/config";
import {
    onEmailNotification,
    onToggleEmailNotification,
    setCustomerErrorMsg,
    setLoading,
    setSetupModal
} from "../../../../config/redux/slice/customerSlice";
import {
    onCustomerLogin,
    onUpdateCustomerDetails
} from "../../../../config/redux/slice/authData";
import {Loader} from "../../../../components";
import {ButtonField} from "../../../../components/form/components";
// import {colors} from "../../../../config/color";
import {useState} from "react";

const EmailNotification = () => {
    //emailNotification
    const {register, handleSubmit, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
       const {token, userData} = useSelector((state) => state.authData)
       const {colors} = useSelector((state) => state.app)
    const {loading,email_notification} = useSelector((state) => state.customer)
    const dispatch = useDispatch()


    const onSubmitData = async(data) => {
        if(userData?.email && userData?.email != ''){
            let emailState = data.turn_email_on_off ? 1 : 0
            const postData = {
                "turn_email_on_off" : emailState
            }

            let res = await postRequest2(
                `${appConfig.auth_token}`,
                `${endpointUrl.emailNotification}`,
                postData,
                dispatch,
                setLoading,
                onEmailNotification,
                setCustomerErrorMsg
            )
            if(res !== undefined){ 
                if(res.status < 400){
                    dispatch(onCustomerLogin(res.data.data))
                }else if(res.status == 400){
                    if(res?.data?.error_code == '04' && (res?.data?.message == 'you are yet to update your email,kindly submit your email to enable this settings')){
                        setValue('turn_email_on_off', userData?.email_notification)
                        dispatch(setSetupModal({type: 'email', state: true}))
                    }
                }
            }    
        } else{
            setValue('turn_email_on_off', userData?.email_notification)
            dispatch(setSetupModal({type: 'email', state: true}))
        }         
    }


    return <>

                <form onSubmit={handleSubmit(onSubmitData)}>
                    <div className={'row ml-3'}>
                        <div className={'col-sm-6 col-md-6 mt-2 sms_notification_left'}>
                            <label className="text-center notifications_no">
                                <input
                                    className="form-check-input mr-2"
                                    defaultChecked={userData?.email_notification}
                                    onChange={()=>dispatch(onToggleEmailNotification())}
                                    type="checkbox"
                                    {...register('turn_email_on_off')}/>
                                <span className="form-check-sign text-center">Email Notification</span>
                            </label>
                        </div>

                        <div className={'col-sm-6 col-md-5 sms_notification_right'}>
                            {
                                loading ?
                                    <Loader/>
                                    :
                                    <ButtonField
                                        btnTxt={'Save Setting'}
                                        btnWidth={'75'}
                                        bgColor={colors.secondary_color}
                                        btnStyle={'btn-sm py-2 px-3'}
                                        txtColor={colors.white}
                                        id={'email_notification'}/>
                            }
                        </div>

                    </div>
                </form>


    </>
}

export default  EmailNotification