import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import formStyles from "../../../assets/css/formStyle.module.css";
import { Loader } from "../../../components";
import { ButtonField, SelectField, TextInputField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, endpointUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import {
    onCustomerLogin
} from "../../../config/redux/slice/authData";
import {
    clearMessages,
    onCompleteTier,
    onUpgradeToTier3,
    setCustomerErrorMsg,
    setLoading
} from "../../../config/redux/slice/customerSlice";
// import localStorage from "redux-persist/es/storage";
import { urls } from "../../../config/urls/urls";

const TierThree = ()=> {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const {userData} = useSelector((state) => state.authData)
    const {
        loading,
        error_msg,
    } = useSelector((state) => state.customer)

    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [successMessage, setSuccessMessage] = useState(false)
    const {colors} = useSelector((state) => state.app)
    const [states, setStates] = useState([])
    const [idTypes, setIdTypes] = useState([])
    const [formData, setFormData] = useState(new FormData());

    const closeAlert = () => {
        dispatch(setCustomerErrorMsg({message: null}))
    }

    const closeSuccessAlert = () => {
        setSuccessMessage(false)
    }

    const getTierData = async () => {
        dispatch(clearMessages({}))
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.tier_data}`,
            {},
            dispatch,
            "",
            "",
            setCustomerErrorMsg,
        )
        if(res !== undefined && res.status < 400){
           setStates(res.data.data.states)
           setIdTypes(res.data.data.idTypes)
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tier = params.get('tier');
        if (+userData.current_tier == 1 ) {
           navigate(`/profile?tier=${userData.current_tier}`);
        }
        getTierData()
    }, []); // eslint-disable-line

    const onSubmit = async (data) => {
        dispatch(clearMessages({}))
        // let formData = new FormData();
        for (let key in data){  
            if(key == "proof_of_address" || key == "id_file"){
            formData.append(key, data[key][0])
            } else{
                formData.append(key, data[key])
            }
        }
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.upgrade_to_tier3}`,
            formData,
            dispatch,
            setLoading,
            "",
            setCustomerErrorMsg,
            true
        )
        if(res !== undefined && res.status < 400){
            // setSuccessMessage(true)
            dispatch(onCompleteTier({tier: '3'})) 
            dispatch(onUpgradeToTier3({}))
            dispatch(onCustomerLogin(res.data.data))
            // dispatch(onUpdateCustomerDetails({...data}));
            // navigate(`${customerUrls.profileHome}?tier=2`)
        }

        setFormData(new FormData())
    }

    return(<>{ userData.current_tier == 2 ?
            (
                <>
                    <div className={'card-header'}>

                    </div>
                    <div className={'card-body pt-3'}>
                     {userData.tier_status == 'pending' ?
                     <div className="text-center mt-3">
                        <p className="mx-5 mt-4">Your request is 
                            currently pending approval by our administrative team. We appreciate your 
                            patience and will review your information as soon as possible. If you have 
                            any urgent inquiries, please feel free to contact our support team. Thank 
                            you for choosing our services.</p>  
                            <button onClick={()=>{navigate(urls.profileHome)}} className="btn btn-primary w-50">View Profile Details</button>                 
                     </div> :  
                        <fieldset className={'mx-auto w-100 px-2 mt-2'}>
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'} encType='multipart/form-data' noValidate>
       
                            <div className="mb-4">
                                <h3 style={{marginLeft: '-5px'}} className="fw-bold mb-2">Address</h3>
                                <div className={'row mb-3'}>
                                    <div className={'col-md-9 p-0'}>
                                        <TextInputField
                                            // inputStyles={}
                                            fieldName={'current_address_street'}
                                            error_msg={errors.current_address_street?.message}
                                            label={'Street Address'}
                                            isReq={true}
                                            register={register}
                                            defaultVal={''}
                                            placeholder={''}
                                            id={'current_address_street'}
                                            // patternRules={}
                                            // onClickFunc={}
                                        />
                                    </div>
                                    <div className={'col-md-3 p-0'}>
                                        <TextInputField
                                            // inputStyles={}
                                            fieldName={'current_address_house_no'}
                                            error_msg={errors.current_address_house_no?.message}
                                            label={'House No.'}
                                            isReq={true}
                                            register={register}
                                            defaultVal={''}
                                            placeholder={''}
                                            id={'current_address_house_no'}
                                            // patternRules={}
                                            // onClickFunc={}
                                        />
                                    </div>
                                </div>
                                <div className={'row mb-3'}>
                                        <div className={'col-md-6 p-0'}>
                                            <SelectField
                                            defaultVal={''}
                                            register={register}
                                            isReq={true}
                                            label={'State'}
                                            fieldName={'current_address_state'}
                                            error_msg={errors.current_address_state?.message}
                                            id={'current_address_state'}
                                            // col={}
                                            // selectFunc={}
                                        >
                                            <option>Select State</option>
                                            {states.map((item, index)=>(
                                                 <option key={index}  value={item}>{item}</option>
                                            ))}
                                        </SelectField>
                                    </div>
                                    <div className={'col-md-6 p-0'}>
                                        <TextInputField
                                            // inputStyles={}
                                            fieldName={'current_address_city'}
                                            error_msg={errors.current_address_city?.message}
                                            label={'City'}
                                            isReq={true}
                                            register={register}
                                            defaultVal={''}
                                            placeholder={''}
                                            id={'current_address_city'}
                                            // patternRules={}
                                            // onClickFunc={}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mb-4">
                                <h3 style={{marginLeft: '-5px'}} className="fw-bold mb-2">Identification</h3>
                                <div className={'row mb-3'}>
                                    <div className={'col-md-6 p-0'}>
                                        <SelectField
                                            defaultVal={''}
                                            register={register}
                                            isReq={true}
                                            fieldName={'id_type'}
                                            label={'Identification Type'}
                                            id={'id_type'}
                                            error_msg={errors.id_type?.message}
                                            // col={}
                                            // selectFunc={}
                                        >
                                            <option value="">Select Identification</option>
                                            {idTypes.map((item, index)=>(
                                            <option key={index}  value={item}>{item}</option>
                                            ))}
                                        </SelectField>
                                    </div>
                                        <div className={'col-md-6 p-0'}>
                                        <TextInputField
                                            // inputStyles={}
                                             label={'Identification Number'}
                                            fieldName={'id_number'}
                                            isReq={true}
                                            register={register}
                                            defaultVal={''}
                                            placeholder={''}
                                            id={'id_number'}
                                            error_msg={errors.id_number?.message}
                                            // patternRules={}
                                            // onClickFunc={}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h3 style={{marginLeft: '-5px'}} className="fw-bold mb-2">Uploads</h3>
                                <div className={'row mb-3'}>
                                    <div className={'col-md-6 p-0'}>
                                    <div className={'form-group'}>
                                        <label className="fw-bold">Proof Of Address</label>
                                        <input
                                        className={`form-control ${formStyles.textField} ${errors.proof_of_address?.message && 'is-invalid'}`}
                                         {...register(`proof_of_address`, {
                                            required: {
                                                value: true,
                                                message: '*this field is required'
                                            },
                                                })}
                                         type={'file'} style={{borderRadius:'50px'}}/>
                                         {errors.proof_of_address?.message && <div className="invalid-feedback ml-3">{errors.proof_of_address?.message}</div>}
                                    </div>
                                    </div>
                                        <div className={'col-md-6 p-0'}>
                                    <div className={'form-group'}>
                                        <label className="fw-bold">Identification Document</label>
                                        <input
                                        className={`form-control ${formStyles.textField} ${errors.id_file?.message && 'is-invalid'}`}
                                          {...register(`id_file`, {
                                            required: {
                                                value: true,
                                                message: '*this field is required'
                                            },
                                            })}                                       
                                         type={'file'} style={{borderRadius:'50px'}}/>
                                         {errors.id_file?.message && <div className="invalid-feedback ml-3">{errors.id_file?.message}</div>}
                                    </div>
                                    </div>
                                </div>
                            </div>


                            <div className={'row mx-1 mt-5'}>
                                <div className={'col-md-12 text-center'}>
                                     {loading ? <Loader/> :
                                    <ButtonField
                                        id={''}
                                        btnWidth={'75'}
                                        bgColor={colors.primary_color}
                                        // btnStyle={}
                                        // btnAdditionalStyle={}
                                        txtColor={colors.white}
                                        btnTxt={'Submit to Upgrade'}
                                    />
                            }
                                </div>
                            </div>

                        </form>

                    </fieldset>
                }
                    </div>
                </>
            ) : (
                        <>
            <h3 className={'card-header'}>Personal Information</h3>
            <div className={'card-body'}>
                <table className={'table table-striped px-5 '}>
                    <thead>

                    </thead>

                    <tbody>
                    <tr>
                        <td>Street Address</td>
                        <td>{userData?.current_address_street}</td>
                    </tr>

                    <tr>
                        <td>House Number</td>
                        <td>{userData?.current_address_house_no}</td>
                    </tr>

                    <tr>
                        <td>State</td>
                        <td>{userData?.current_address_state}</td>
                    </tr>


                    <tr>
                        <td>City</td>
                        <td>{userData?.current_address_city}</td>
                    </tr>

                    <tr>
                        <td>Identification Type</td>
                        <td>{userData?.id_type}</td>
                    </tr>


                    <tr>
                        <td>Identification Number</td>
                        <td>{userData?.id_number}</td>
                    </tr>
                    </tbody>

                </table>


            </div>
        </>
            )
        }</>
    )
}
export default TierThree


/*
     Tier 3 :
     Select ID Card type ::
        NIN, Drvier

     ID Card NO
 */