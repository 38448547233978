import AdminWrapper from "../AdminWrapper";
import {Link, useLocation} from "react-router-dom";
// import {colors} from "../../../config/color";
import {useSelector} from "react-redux";
import {useEffect} from "react";


const UserProfile = () => {
    const {systemManagement: {userDetails}} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const location = useLocation()
    return (
        <AdminWrapper title={'User Profile'}>
            <div className={'d-flex justify-content-between mx-2 mb-5'}>
                <img src={userDetails.profile_image} alt={''} height={'80'}/>
                <div className={'d-flex flex-column gap-2'}>
                    <Link to={''} className={'btn'}
                          style={{color: colors.white, background: `${colors.secondary_color}`}}>Edit</Link>
                    <Link to={''} className={'btn'}
                          style={{color: colors.secondary_color, border: `1px solid ${colors.secondary_color}`}}>Remove User</Link>
                </div>
            </div>

            <div className={'mx-2'}>
                <h5 className={'mb-4'} style={{color:colors.secondary_color}}>Personal Information</h5>
                <div className={'d-flex justify-content-between'}>
                    <div>
                        {/*first name*/}
                        <div>
                            <h6>First Name</h6>
                            <div>{userDetails.first_name}</div>
                        </div>
                        {/*last name    */}
                        <div>
                            <h6>First Name</h6>
                            <div>{userDetails.first_name}</div>
                        </div>
                        {/*  Role Text  */}
                        <div>
                            <h6>Role</h6>
                            <div>{userDetails.role_text}</div>
                        </div>
                    </div>
                    {/*middle*/}
                    <div>
                        {/*email*/}
                        <div>
                            <h6>Email Address</h6>
                            <div>{userDetails.email_address}</div>
                        </div>
                        {/*phone number    */}
                        <div>
                            <h6>Phone Number</h6>
                            <div>{userDetails.phone_number}</div>
                        </div>
                    </div>
                    {/*last layer*/}
                    <div>
                        {/*email*/}
                        <div>
                            <h6>Branch</h6>
                            <div>{userDetails.branch}</div>
                        </div>
                        {/*phone number    */}
                        <div>
                            <h6>Address</h6>
                            <div>{userDetails.address}</div>
                        </div>
                    </div>

                </div>

            </div>

        </AdminWrapper>
    )
}

export default UserProfile