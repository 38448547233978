import { urls } from '../urls/urls'

import {
	AccountOpeningRequestHome,
	// AddNewAgent,
	// AddNewCustomer,
	AddNewUser,
	AdminAgentHome,
	AdminCardHome,
	AdminCustomerHome,
	AdminDashboard,
	AdminDepositHome,
	AdminLoansHome,
	AdminSettingsHome,
	AdminSettingsPrivacyPolicy,
	AdminSettingsTermsAndCondition,
	AdminSupportHome,
	AdminSystemHome,
	AdminTransactionHome,
	AdminTransferHome,
	AdminUtilityHome,
	AdminWithdrawalHome,
	RequestHome,
	AdminUserManagement,
	AdminTierManagement,
	UserProfile,
	ViewProfileAgent,
	AgentAccountRequestHome,
	CustomReports,
	MprReports,
	AdminRoleManagement
} from '../../app/admin'
import {
	AdminForgotPassword,
	AdminGetStarted,
	AdminLogin,
	AdminPasswordReset,
	AdminTokenScreen,
	AdminOtpScreen,
	AdminPasscodeScreen,
	AdminCreatePasscode
} from '../../app/onboard/admin'
import AdminAccountActivation from '../../app/onboard/admin/AdminAccountActivation'
import SystemLogs from '../../app/admin/logs/SystemLogs'
import AdditionalAccountRequest from '../../app/admin/system/AdditionalAccountRequest'

export const adminRoutes = [
	{
		url: urls.adminLandingPage,
		component: <AdminLogin />,
	},
	{
		url: urls.adminOtp,
		component: <AdminOtpScreen />,
	},
	{
		url: urls.adminPasscode,
		component: <AdminPasscodeScreen />,
	},
		{
		url: urls.adminCreatePasscode,
		component: <AdminCreatePasscode />,
	},
	{
		url: urls.adminGetStarted,
		component: <AdminGetStarted />,
	},
	{
		url: urls.adminActivationAccount,
		component: <AdminAccountActivation />,
	},
	{
		url: urls.adminLogin,
		component: <AdminLogin />,
	},
	{
		url: urls.adminToken,
		component: <AdminTokenScreen />,
	},
	{
		url: urls.adminForgotPassword,
		component: <AdminForgotPassword />,
	},
	{
		url: urls.adminPasswordReset,
		component: <AdminPasswordReset />,
	},
	{
		url: urls.adminDashboard,
		component: <AdminDashboard />,
	},
	{
		url: urls.adminTransactionDashboard,
		component: <AdminTransactionHome />,
	},
	{
		url: urls.adminCardsHome,
		component: <AdminCardHome />,
	},
	{
		url: urls.adminCustomersHome,
		component: <AdminCustomerHome />,
	},
	// {
	//     url: urls.adminAddNewCustomer,
	//     component: <AddNewCustomer/>
	// },

	{
		url: urls.adminAgentsHome,
		component: <AdminAgentHome />,
	},

	// {
	// 	url: urls.adminAgentAdd,
	// 	component: <AddNewAgent />,
	// },
	{
		url: urls.adminAgentView,
		component: <ViewProfileAgent />,
	},

	{
		url: urls.adminLoanHome,
		component: <AdminLoansHome />,
	},
	{
		url: urls.adminSystemHome,
		component: <AdminSystemHome />,
	},
	{
		url: urls.adminRequestHome,
		component: <RequestHome />,
	},
	{
		url: urls.adminAccOpeningRequestHome,
		component: <AccountOpeningRequestHome />,
	},
	{
		url: urls.adminAgentRequestHome,
		component: <AgentAccountRequestHome />,
	},

	{
		url: urls.adminSettingsHome,
		component: <AdminSettingsHome />,
	},
	{
		url: urls.adminSettingsPrivacy,
		component: <AdminSettingsPrivacyPolicy />,
	},
	{
		url: urls.adminSettingsTermsCondition,
		component: <AdminSettingsTermsAndCondition />,
	},
	{
		url: urls.adminSupportHome,
		component: <AdminSupportHome />,
	},
	//
	{
		url: urls.adminDepositHome,
		component: <AdminDepositHome />,
	},
	{
		url: urls.adminWithdrawalHome,
		component: <AdminWithdrawalHome />,
	},
	{
		url: urls.adminTransferHome,
		component: <AdminTransferHome />,
	},
	{
		url: urls.adminUtilityHome,
		component: <AdminUtilityHome />,
	},
	{
		url: urls.adminUserManagement,
		component: <AdminUserManagement />,
	},
	{
		url: urls.adminTierManagement,
		component: <AdminTierManagement />,
	},
	{
		url: urls.adminRoleManagement,
		component: <AdminRoleManagement />,
	},
	{
		url: urls.adminUserAdd,
		component: <AddNewUser />,
	},
	{
		url: urls.adminUserDetails,
		component: <UserProfile />,
	},
	{
		url: urls.adminSystemLogs,
		component: <SystemLogs />,
	},
	{
		url: urls.additionalAccountReq,
		component: <AdditionalAccountRequest />,
	},
	{
		url: urls.adminMprReport,
		component: <MprReports />,
	},
	{
		url: urls.adminCustomReport,
		component: <CustomReports />,
	},
]
