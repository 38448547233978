import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {postRequest, postRequest2} from "../../../../config/redux/request";
import {appConfig, endpointUrl, remoteUrl} from "../../../../config/config";
import {
    clearMessages,
    onChangeSecurityQuestion,
    setCustomerErrorMsg,
    setLoading
} from "../../../../config/redux/slice/customerSlice";
import {ButtonField, PasswordField, SelectField, TextInputField} from "../../../../components/form/components";
import {strText} from "../../../../config/string";
// import {colors} from "../../../../config/color";
import {Loader} from "../../../../components";
import { useEffect, useState } from "react";
import { onCustomerLogin } from "../../../../config/redux/slice/authData";

const ChangeSecurityQuestion = () => {
    const {register, handleSubmit, getValues,setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {token} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {loading,success_msg} = useSelector((state) => state.customer)
    const dispatch = useDispatch()

    const [questions, setQuestions] = useState([])

    useEffect(()=>{
        getQuestions()
    }, [])

    const submitData = async (data) => {
        dispatch(clearMessages())
        const postData = {
            "pin": data.pin,
            "security_answer": data.security_answer,
            "security_question": data.security_question
        }


        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.changeSecurityQuestion}`,
            postData,
            dispatch,
            setLoading,
            onChangeSecurityQuestion,
            setCustomerErrorMsg
        )
        if(res !== undefined && res.status < 400){
            dispatch(onCustomerLogin(res.data.data))
            reset()
        }
    }

    const getQuestions = async () => {
        dispatch(clearMessages())

        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getSecurityQuestions}`,
            {},
            dispatch,
            "",
            "",
            setCustomerErrorMsg
        )
        if(res !== undefined && res.status < 400){
            setQuestions(res.data.data)
        }
    }

    useEffect(()=>{
        if(success_msg===strText.change_security_question_success_message){
            setValue('pin','')
            setValue('security_answer','')
            setValue('security_question','')
        }
    }, [success_msg])

    return (
        <div className={'mx-auto col-sm-10 col-md-10 col-lg-8 col-xl-10 mt-2 py-1'}>
            <form onSubmit={handleSubmit(submitData)}>
                <div className={'mx-auto w-100'}>
                    <div className={'card  px-sm-0 px-md-5 py-4 '}>
                        <div className={'card-header mb-2'}>
                            <div className={'d-flex justify-content-between'}>
                                <h3 style={{color: colors.secondary_color}}><strong>Change Security Question</strong></h3>
                            </div>
                        </div>
                        <div className={'card-body'}>
            
                <div className={'row mb-3 px-sm-0 px-md-5'}>
                    <div className={'col-12'}>
                        <SelectField
                            defaultVal={''}
                            register={register}
                            isReq={true}
                            fieldName={'security_question'}
                            label={'Security Question'}
                            id={'security_question'}
                            error_msg={errors.security_question?.message}
                            // col={}
                            // selectFunc={}
                        >
                            <option value="">Select Security Question</option>
                            {questions.map((item, index)=>(
                            <option key={item.sq_id} value={item.question}>{item.question}</option>
                            ))}
                        </SelectField>
                    </div>
                </div>

                <div className={'row mb-3 px-sm-0 px-md-5'}>
                    <div className={'col-12'}>
                        <TextInputField
                            fieldName={'security_answer'}
                            register={register}
                            label={'Enter your Security Question Answer'}
                            isReq={true}
                            error_msg={errors.security_answer?.message}
                            defaultVal={''}
                            patternRules={'letters_numbers'}
                            placeholder={''}
                            // onClickFunc={}
                            // inputStyles={}
                        />
                    </div>
                </div>

                <div className={'row mb-3 px-sm-0 px-md-5'}>
                    <div className={'col-12'}>
                        <PasswordField
                            placeholder={'....'}
                            defaultVal={''}
                            register={register}
                            isReq={true}
                            maxlength={4}
                            label={strText.current_pin_label}
                            fieldName={'pin'}
                            errors={errors?.new_pin?.message}
                        />
                    </div>
                </div>


                <div className={'text-center mt-4 px-sm-0 px-md-5'}>
                    {loading ? <Loader/> :
                        <>
                            <ButtonField
                                btnTxt={'Update'}
                                txtColor={colors.white}
                                bgColor={colors.secondary_color}
                                id={'kk'}
                                btnWidth={'100'}
                                btnAdditionalStyle={{padding :`0.6rem 1rem`}}
                            />
                        </>

                    }
                </div>
                        </div>
                        {/**/}
                    </div>
                </div>
            </form>
        </div>     

    )
}

export default ChangeSecurityQuestion