import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Slide, toast, ToastContainer } from "react-toastify";
import { clearAdminErrorMsg } from "../../../config/redux/slice/adminSlice";

const AdminSuccessAlert = ({msg,toastId}) => {
    const dispatch = useDispatch()

    const closeTab = () => {
        dispatch(clearAdminErrorMsg())
    }

    useEffect(() => {
        showSuccessNotification()
    },[])

    const showSuccessNotification = () => {
        //[Flip,Bounce,Slide]
        // [TOP_CENTER,TOP_LEFT,BOTTOM_CENTER,BOTTOM_LEFT]
        toast(msg, {
            toastId : toastId,
                type: toast.TYPE.SUCCESS,
                transition: Slide,
                draggable: true,
                onClose:() => closeTab(),
                position : toast.POSITION.TOP_CENTER,
                theme :"colored" ,//light,dark,colored
                icon:() =><i className="mdi mdi-comment-eye"> </i>,
                style:{backgroundColor:'#0ACF97'}
            }
        )
    }


    return <>
        <ToastContainer style={{width:'100%',marginTop:0,top:0}} />

    </>
}
export default  AdminSuccessAlert