import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
// import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
//
import adminReducer from './slice/adminSlice';
import appReducer from './slice/appSlice';
import authDataReducer from './slice/authData';
import authReducer from './slice/authSlice';
import customerReducer from './slice/customerSlice';
// import hardSet from "redux-persist/es/stateReconciler/hardSet";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { appConfig } from "../config";



const rootPersistConfig = {
    key: 'root',
    storage:storageSession,
    whitelist: ['auth']
    //'app','auth','authData',,    whitelist: ['customer']
}

const appDataPersistConfig = {
    key : 'appData',
    storage : storageSession,
    whitelist: ['colors', 'appLogo', 'appName', 'customisationExists'],
    stateReconciler : autoMergeLevel2
}

const authDataPersistConfig = {
    key : 'authData',
    storage : storageSession,
    whitelist: ['token', 'bank_token', 'tenant_id', 'ak', 'isLoginNormal','user_role','userData','tempEmail'],
    stateReconciler : autoMergeLevel2
}

const customerPersistConfig = {
    key : 'customer',
    storage : storageSession,
    whitelist: ['customerDashData','transaction_histories','bankingSettings','sms_notification','email_notification','transactionHistoryFilter'],
    stateReconciler : autoMergeLevel2
}

const administratorPersistConfig = {
    key: 'administrator',
    storage: storageSession,
    whitelist: [
        //dashboard
        'pageReload',
        'usersSummary',
        'pieChartData',
        'barChartData',
        'userSummaryIcons',
        'userSummaryColors',
        'dailyPosition',
        //transaction
        'adminRoleManagement',
        'transactionsManagement',
        'settingsManagement',
        'systemManagement',
        'customerManagement',
        'agentManagement'
    ],
    stateReconciler : autoMergeLevel2
}

const rootReducer = combineReducers({
    app: persistReducer(appDataPersistConfig, appReducer),
    auth: authReducer,
    authData:persistReducer(authDataPersistConfig,authDataReducer) ,
    customer:persistReducer(customerPersistConfig,customerReducer),
    administrator:persistReducer(administratorPersistConfig, adminReducer),
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: appConfig.appEnv !== 'production',
    middleware : [thunk]
})

export const persist = persistStore(store)