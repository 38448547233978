import formStyles from "../../../assets/css/formStyle.module.css";


const TextInputField = ({
                            fieldName,
                            placeholder,
                            register,
                            label,
                            error_msg,
                            isReq,
                            isSameAs,
                            minlength,
                            maxlength,
                            defaultVal,
                            inputStyles,
                            onClickFunc,
                            patternRules,
                            id="",
                            disabled=false
                        }) => {
    return (
        <>
            <div className="form-group">
                <label htmlFor="" className="fw-bold">{label}</label>
                <input
                    id={id!==""?id :fieldName}
                    type="text"
                    // required
                    disabled={disabled}
                    maxLength={maxlength}
                    minLength={minlength}
                    autoComplete={'off'}
                    className={`form-control ${formStyles.textField} ${error_msg && 'is-invalid'}`}
                    onMouseDown={(e) => {
                        if (onClickFunc) {
                            return onClickFunc(e)
                        }
                    }}
                    style={{...inputStyles}}
                    placeholder={placeholder}
                    defaultValue={defaultVal}
                    {...register(`${fieldName}`, {
                        required: {
                            value: isReq,
                            message: '*this field is required'
                        },
                        maxLength:{
                            value: maxlength,
                            message: `${maxlength} digits required`
                        },
                        minLength:{
                            value: minlength,
                            message: `${minlength} digits required`
                        },
                        pattern: {
                            // value : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            //first_part+second_part+3rd_part
                            value: patternRules === 'numbers'
                                ?
                                /^[0-9]+$/i
                                : patternRules === 'amount' ?
                                    /^[0-9]+$/i
                                    :
                                    patternRules === 'letters' ?
                                        /^[a-z ]+$/gi :
                                        patternRules === 'letters_numbers' ?
                                            /^[a-zA-Z0-9 ]+$/gi :
                                            patternRules === 'email'?
                                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i :
                                                 patternRules === 'passcode' ? /^\d{6}$/i:
                                                '',
                            message: 'invalid input'
                        },
                        validate: (fieldValue)=>{
                            if (isSameAs){
                                    return(
                                        fieldValue === isSameAs || "Emails Must Match"
                                    )
                                  }
                                }
                    })
                    }
                />

                {error_msg && <div className="invalid-feedback ml-3">{error_msg}</div>}
            </div>

        </>
    )

}

export default TextInputField