import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTabDetails, type } from "../../../config/redux/slice/adminSlice";
import { TransactionDetailsItemModal } from "../../_shared_component";
import AdminWrapper from "../AdminWrapper";
import { TransactionTransfer } from "./tabs";

const AdminTransactionHome = () => {
    const {transactionsManagement: {current_tab,singleTransferDetails}, showModal} = useSelector((state) => state.administrator)
    const {userData:{modules, permissions}, user_role,moduleRoles} = useSelector((state) => state.authData)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const closeModal = () => {
        dispatch(setTabDetails({data: null, modal: false, type: current_tab}))
    }   


    return (
        <AdminWrapper mainAppStyle={'col-sm-12 col-md-12 col-lg-12 col-xl-12  mt-5 px-2'} title={``}>
            <TransactionTransfer  />


            {showModal && current_tab === type.transaction_transfer &&
                <TransactionDetailsItemModal
                    show={showModal}
                    transactionDetails={singleTransferDetails}
                    onClose={closeModal}/>
            }
            {/* withdrawal,utility ,deposit */}
        </AdminWrapper>
    )
}
export default AdminTransactionHome