import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { colors } from "../../config/color";
import { logoutAdmin } from "../../config/redux/slice/adminSlice";
import { logoutUser } from "../../config/redux/slice/authData";
import { logoutCustomer } from "../../config/redux/slice/customerSlice";
import { urls } from "../../config/urls/urls";

const LogoutModal = ({backdrop,modalFunc}) => {
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        user_role
    } = useSelector((state) => state.authData)
    //
    const onClose = () => {
        modalFunc(false)
    }
    //
    const logout = () => {
        dispatch(logoutCustomer())
        dispatch(logoutUser())
        dispatch(logoutAdmin())
        if(user_role === 'customer'){
            navigate(urls.landingPage)
        }else{
            navigate(urls.adminLogin)
        }
    }

    return <>
        <div className="modal fade show" id="basicModal" tabIndex="-1"
             style={{display: "block"}} aria-hidden="true">
            <div className="modal-dialog  modal-sm modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header" style={{border: "0px"}}>
                        <h5 className="modal-title mx-auto w-100"> Confirm Logout </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                                onClick={()=>onClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className={'px-sm-1 px-md-2 py-2'}>
                            <p>Do you want to Logout? </p>
                            <div
                                className={'btn mr-2 btn-sm'}
                                onClick={()=>onClose()}
                                 style={{background:colors.white,color:colors.secondary_color,border:`1px solid ${colors.secondary_color}`}}
                            >
                               No, Take me Back
                            </div>
                            <div className={'btn btn-sm'} onClick={() => logout() }
                                 style={{background:colors.secondary_color,color:colors.white}}>
                                Yes, Log me Out !
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        {backdrop && <div className={`modal-backdrop fade show  `}></div>}
    </>
}
export default  LogoutModal