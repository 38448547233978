import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import formStyles from "../../assets/css/formStyle.module.css";
import { Loader } from "../../components";
import { ButtonField, TextInputField } from "../../components/form/components";
// import { colors } from "../../config/color";
import { appConfig, remoteUrl } from "../../config/config";
import { formatMessages } from "../../config/redux/actions";
import { postRequest, postRequest2 } from "../../config/redux/request";
import {
    auth_types,
    changeModalSize,
    clearAll,
    onPasswordReset,
    setAuthModalForm, setErrorMessage,
    setLoading
} from "../../config/redux/slice/authData";
import { urls } from "../../config/urls/urls";
import AlertWrapper from "../_shared_component/AlertWrapper";
import AuthWrapper from "./AuthWrapper";

const PasswordResetNew = () => {
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {tempEmail,success_msg,errorMsg,loading} = useSelector((state) => state.authData)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const dispatch = useDispatch()



    const onClose = () => {
        // :: ::: ::: ::: ::
        dispatch(setAuthModalForm({type:auth_types.show_reset_password_form,modal:false}))
    }

    useEffect(()=> {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
        dispatch(setAuthModalForm({type:auth_types.show_reset_password_form,modal:false}))
    },[])

    const onSubmit = async (data) => {
        const postData = {
            "email":tempEmail,
            "resettoken":data.reset_token,
            "password":data.password,
            "pin" : data.pin
        }
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.resetPass}`,
            postData,
            dispatch,
            setLoading,
            onPasswordReset,
            setErrorMessage
        )
    }

    if(success_msg==='password reset successful'){
        setValue('password','')
        setValue('confirm_password','')
        setValue('reset_token','')


        // Swal.fire(
        //     'Success',
        //     `${success_msg}`,
        //     'success',
        // ).then(() => {
        //     dispatch(setAuthModalForm({type:auth_types.show_login_form,modal:true}))
        // })
    }

    return (
        <AuthWrapper scrollable={'hidden'}>
            <div className={`internet_banking_new`} style={{height:'100vh',overflowX:'hidden'}}>
                <section className="mt-5 justify-content-center align-items-center " >
                    <div className={'card shadow-lg bg-white pb-3 pt-5 px-3 col-sm-1 col-md-5 mx-auto '} >

                        <div className="tittle text-center">
                            <h2 className={''} style={{color:colors.primary_color}}>
                                <b>Password Reset</b>
                            </h2>
                            <div className={''}>Enter the reset token sent to this email : <u className={'text-primary'}>{tempEmail}</u>
                            </div>
                        </div>

                    <div className={'ml-1'}>

                        {errorMsg && <AlertWrapper type={'error'}>
                            {formatMessages(errorMsg)}
                        </AlertWrapper>}
                        {success_msg && <AlertWrapper type={'success'}>{formatMessages(success_msg)}.
                            <Link to={urls.landingPage}> Click here </Link>  to close this form  </AlertWrapper>}

                        {success_msg==='' &&
                            <form onSubmit={handleSubmit(onSubmit)} className={''}>
                                <div className={''}>

                                    <div className={'mt-2'}>
                                        <TextInputField
                                            fieldName={'reset_token'}
                                            register={register}
                                            label={'OTP/Reset token'}
                                            isReq={true}
                                            error_msg={errors.reset_token?.message}
                                            defaultVal={''}
                                            // patternRules={''}
                                            placeholder={''}
                                            // inputStyles={}
                                            // onClickFunc={}
                                        />
                                    </div>

                                    <div className={'mt-2'}>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <div className="input-group" style={{border:`0.05rem solid #ced4da`,padding:'2px',borderRadius:'50px'}}>
                                                <input
                                                    type={showPassword ? 'text' :'password'}
                                                    className={`form-control ${formStyles.textField} ${errors.password && 'is-invalid'}`}
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    id={'password'}
                                                    placeholder={''}
                                                    defaultValue={''}
                                                    autoComplete={'off'}
                                                    autoCorrect={'off'}
                                                    style={{border:0}}
                                                    {...register(`password`, {
                                                        required: {
                                                            value: true,
                                                            message: '*this field is required'
                                                        },
                                                    })
                                                    }
                                                />
                                                <div className="input-group-append">
                                <span
                                    id="basic-addon2"
                                    onClick={()=>setShowPassword(!showPassword)}
                                    className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                    style={{border:0}}
                                >
                               <img src={'../../assets/images/admin/auth/open_wide.svg'} alt={''}/>
                            </span>
                                                </div>
                                            </div>
                                            {errors.password && <div className="invalid-feedback">{errors.password?.message}</div>}
                                        </div>
                                    </div>

                                    <div className={'mt-2'}>
                                        <div className="form-group">
                                            <label>Confirm password</label>
                                            <div className="input-group" style={{border:`0.05rem solid #ced4da`,padding:'2px',borderRadius:'50px'}}>

                                                <input
                                                    type={showConfirmPassword ? 'text' :'password'}
                                                    className={`form-control ${formStyles.textField} ${errors.password && 'is-invalid'}`}
                                                    aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2"
                                                    id={'password_confirm'}
                                                    placeholder={''}
                                                    defaultValue={''}
                                                    autoComplete={'off'}
                                                    autoCorrect={'off'}
                                                    style={{border:0}}
                                                    {...register(`confirm_password`, {
                                                        required: {
                                                            value: true,
                                                            message: '*this field is required'
                                                        },
                                                        validate: value => value === getValues('password') || "The passwords do not match"
                                                    })
                                                    }
                                                />
                                                <div className="input-group-append">
                                <span
                                    id="basic-addon2"
                                    onClick={()=>setShowConfirmPassword(!showConfirmPassword)}
                                    className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                    style={{border:0}}
                                >
                               <img src={'../../assets/images/admin/auth/open_wide.svg'} alt={''}/>
                            </span>
                                                </div>
                                            </div>
                                            {errors.confirm_password && <div className="text-danger">{errors?.confirm_password?.message}</div>}
                                        </div>
                                    </div>

                                    <div className={'mt-2'}>
                                        <TextInputField
                                            fieldName={'pin'}
                                            register={register}
                                            label={'Transaction Pin'}
                                            isReq={true}
                                            error_msg={errors.pin?.message}
                                            defaultVal={''}
                                            // patternRules={''}
                                            placeholder={''}
                                            // inputStyles={}
                                            // onClickFunc={}
                                        />
                                    </div>




                                    <div className={'mb-3 mt-2 text-center'}>
                                        {loading ? <Loader/> :
                                            <ButtonField btnWidth={'100'} btnStyle={'h-100 py-2'} btnTxt={'Reset Password'}
                                                         bgColor={colors.primary_color} txtColor={colors.white}/>
                                        }
                                        <small>
                                            Already have an account? <u><Link  style={{color:colors.primary_color}} to={urls.login}>Login</Link> </u>
                                        </small>
                                    </div>
                                </div>
                            </form>
                        }
                    </div>


                    </div>
                </section>
            </div>




        </AuthWrapper>
    )

}
export default PasswordResetNew