// const lang = langArray[0]
export const appConfig = {
	auth_token: `${process.env.REACT_APP_AUTH_TOKEN}`,
	jwt_token: `${process.env.REACT_APP_AUTH_TOKEN}`,
	bank_token: `${process.env.REACT_APP_AUTH_TOKEN}`,
	core_banking_token: `${process.env.REACT_APP_CORE_BANKING_TOKEN}`,
	google_captcha_secret_key: `${process.env.REACT_APP_GOOGLE_CAPTCHA_SECRET_KEY}`,
	google_captcha_site_key: `${process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}`,
	appEnv: `${process.env.NODE_ENV ?? 'local'}`,
	defaultCountryCode: process.env.REACT_APP_DEFAULT_COUNTRY_CODE??'+234'
}
const core_banking_base = 'https://13.40.51.239:443/api/v1/'

const core_backend_url = `${process.env.REACT_APP_NODE_ENV}` == 'production' ? 
								`` : `${process.env.REACT_APP_NODE_ENV}` == 'qa' ?
								`${process.env.REACT_APP_QA_BACKEND_URL}` : `${process.env.REACT_APP_STAGING_BACKEND_URL}`

export const help_desk_url = `${process.env.REACT_APP_NODE_ENV}` == 'production' ? 
								`${process.env.REACT_APP_PROD_HELP_DESK_URL}` : `${process.env.REACT_APP_NODE_ENV}` == 'qa' ?
								`${process.env.REACT_APP_QA_HELP_DESK_URL}` : `${process.env.REACT_APP_STAGING_HELP_DESK_URL}` 	


export const crypto_secret_key = `${process.env.REACT_APP_MASTER_KEY}`

export const remoteUrl = {
	get_account_info: `${core_banking_base}enquiry/accountInfo/`,

	auth_url: `${process.env.REACT_APP_AUTH_URL}`,
	//
	open_acc: `${core_backend_url}activation_requests/open_account`,
	link_acc: `${core_backend_url}activation_requests/link_cas_account_for_account_opening`,
	link_internet_banking: `${core_backend_url}activation_requests/link_cas_account_for_internet_banking`,
	get_agent_account_from_cbs: `${core_backend_url}agent_requests/get_account_from_cbs_for_agency_banking`,
	create_agent_account: `${core_backend_url}agent_requests/agency_banking_request`,
	link_agent_account: `${core_backend_url}agent_requests/link_cas_account_for_agency_banking`,
	login: `${core_backend_url}login`,
	forgotPass: `${core_backend_url}customer/forgot_password`,
	resetPass: `${core_backend_url}customer/reset_password_with_token`,
	requestEmailVerification: `${core_backend_url}customer/request_verifyemail`,
	verifyEmail: `${core_backend_url}customer/verifyemail_with_token`,
	getInternetBankAccountInfo: `${core_backend_url}activation_requests/get_account_from_cbs_for_internet_banking`,
	submitInternetOpeningReq: `${core_backend_url}activation_requests/internet_banking_request`,
	getCustomerSettings: `${core_backend_url}customer/show-settings`,
	getBeneficiaries: `${core_backend_url}transaction_requests/customer/get_beneficiaries`,
	saveBeneficiaries: `${core_backend_url}transaction_requests/customer/add_beneficiary`,
	getAvailAccounts: `${core_backend_url}transaction_requests/customer/get_available_accounts_for_transfer`,
	//admin
	adminDash: `${core_backend_url}admin/dashboard`,
	dailyPosition: `${core_backend_url}admin/dailyposition`,
	getAgents: `${core_backend_url}admin/get-agents`,
	adminGetTrans: `${core_backend_url}transaction_requests/admin/get_local_transactions`,
	adminGetTransWithDates: `${core_backend_url}transaction_requests/admin/get_local_transactions_with_dates`,
	getInternetBankingReq: `${core_backend_url}admin/all_internet_banking_requests`,
	getAccountOpeningReq: `${core_backend_url}admin/all_account_opening_requests`,
	getAgentAccountReq: `${core_backend_url}admin/all_agency_banking_requests`,
	requestAccountOpeningApproval: `${core_backend_url}settings_requests/admin/request-account-opening-approval`,
	viewAccountOpeningApprovalRemark: `${core_backend_url}settings_requests/admin/view-account-opening-remark`,
	viewAgentBankingApprovalRemark: `${core_backend_url}settings_requests/admin/view-agency-banking-remark`,
	addReviewToAgentBankingApproval: `${core_backend_url}settings_requests/admin/request-agency-banking-approval`,
	approveAgentBankingReq: `${core_backend_url}settings_requests/admin/approve-agency-banking`,
	approveAccountOpeningReq: `${core_backend_url}settings_requests/admin/approve-account-opening`,
	getAdditionalAccReq: `${core_backend_url}admin/all_additional_account_opening_requests`,
	requestAdditionalAccountApproval: `${core_backend_url}settings_requests/admin/request-additional-account-opening-approval`,
	approveAdditionalAccountReq: `${core_backend_url}settings_requests/admin/approve-additional-account-opening`,

	requestInternetBankingApproval: `${core_backend_url}settings_requests/admin/request-internet-banking-approval`,
	viewInternetBankingApproval: `${core_backend_url}settings_requests/admin/view-internet-banking-remark`,
	approveInternetBankingApproval: `${core_backend_url}settings_requests/admin/approve-internet-banking`,
	getSettings: `${core_backend_url}admin/show-settings`,
	submitSettings: `${core_backend_url}admin/request-admin-setting-update`,
	approveSettings: `${core_backend_url}admin/approve-admin-setting-update`,
	getAllUsers: `${core_backend_url}admin/get-all-customers`,
	addAdmin: `${core_backend_url}admin/add-admin`,
	getAdmin: `${core_backend_url}admin/get-all-admins`,
	changeUserStatus: `${core_backend_url}admin/change-user-status`,
	adminResetUserPassword: `${core_backend_url}admin/reset-user-password`,
	adminResetUserPin: `${core_backend_url}admin/reset-user-pin`,
	adminLinkAccount: `${core_backend_url}activation_requests/link_cas_account_for_add_admin`,
	adminGetTokenizedDeposit: `${core_backend_url}transaction_requests/admin/get_all_tokenized_transactions`,
	adminLog: `${core_backend_url}admin/get-audit-trail`,
	//customer :: /customer/dashboard
	customerDash: `${core_backend_url}customer/dashboard`,
	createPin: `${core_backend_url}settings_requests/customer/create-pin`,
	createSecurityQuestion: `${core_backend_url}settings_requests/customer/create-security-question`,
	doTransaction: `${core_backend_url}transaction_requests/customer/do_transaction`,
	doTokenizedTransaction: `${core_backend_url}transaction_requests/customer/do_tokenized_transaction`,
	getCustomerTokenizedTransaction: `${core_backend_url}transaction_requests/customer/get_all_tokenized_transactions`,
	getCustomerTokenizedTransactionWithDate: `${core_backend_url}transaction_requests/customer/get_all_tokenized_transactions_with_dates`,
	updateCustomerTokenizedTransaction: `${core_backend_url}transaction_requests/customer/update_tokenized_transaction`,
	updateAdminTokenizedTransaction: `${core_backend_url}admin/do_tokenized_transaction`,
	transactionHistory: `${core_backend_url}transaction_requests/customer/transaction_history`,
	transactionHistoryWithDate: `${core_backend_url}transaction_requests/customer/transaction_history_with_dates`,
	changePin: `${core_backend_url}settings_requests/customer/change-pin`,
	changeSecurityQuestion: `${core_backend_url}settings_requests/customer/change-security-question`,
	smsNotification: `${core_backend_url}settings_requests/customer/toggle-sms-notification`,
	emailNotification: `${core_backend_url}settings_requests/customer/toggle-email-notification`,
	reqAdditionalAccount: `${core_backend_url}activation_requests/customer/open_additional_account`,
}

export const endpointUrl = {
	auth_first_step: `${core_backend_url}auth/login`,
	auth_second_step: `${core_backend_url}auth/create-account`,
	auth_third_step: `${core_backend_url}auth/otp-validation`,
	auth_fourth_step: `${core_backend_url}auth/create-passcode`,
	login: `${core_backend_url}auth/login`,
	resendOtp: `${core_backend_url}auth/resend-otp`,
	forgotPasscode: `${core_backend_url}auth/forgot-passcode`,
	passcode_reset: `${core_backend_url}auth/passcode-reset`,
	setup_email: `${core_backend_url}auth/update-email`,
	agent_approval_rejection: `${core_backend_url}admin/approval-disapprove-agent-account`,
	resend_email_otp: `${core_backend_url}auth/resend-email-verification`,
	verify_email: `${core_backend_url}auth/verify-email`,
	createPin: `${core_backend_url}customer/settings/create-pin`,
	changePin: `${core_backend_url}customer/settings/change-pin`,
	changePasscode: `${core_backend_url}customer/settings/change-passcode`,
	createSecurityQuestion: `${core_backend_url}customer/settings/create-security-question`,
	changeSecurityQuestion: `${core_backend_url}customer/settings/change-security-question`,
	smsNotification: `${core_backend_url}customer/settings/toggle-sms-notification`,
	emailNotification: `${core_backend_url}customer/settings/toggle-email-notification`,

	//admin
	adminLogin: `${core_backend_url}auth/admin/login`,
	adminGetTrans: `${core_backend_url}admin/get_local_transactions`,
	adminGetTransWithDates: `${core_backend_url}admin/get_local_transactions_with_dates`,
	adminGetTokenizedDeposit: `${core_backend_url}admin/get_all_tokenized_transactions`,
	getAllUsers: `${core_backend_url}admin/get-all-customers`,
	getSettings: `${core_backend_url}admin/show-settings`,

	upgrade_to_tier2: `${core_backend_url}customer/settings/upgrade-to-tier-2`,
	upgrade_to_tier3: `${core_backend_url}customer/settings/upgrade-to-tier-3`,
	tier_data: `${core_backend_url}customer/settings/tiers-update-info`,
	getTiersAdmin: `${core_backend_url}tiers/admin`,
	getTiers: `${core_backend_url}tiers`,
	addTier: `${core_backend_url}tiers/add`,
	updateTier: `${core_backend_url}tiers/update`,
	approveUserTier: `${core_backend_url}admin/approve-user-tier-3`,

	getRoles: `${core_backend_url}admin/roles`,
	addRole: `${core_backend_url}admin/roles/add-new-role`,
	updateRole: `${core_backend_url}admin/roles/update-role`,
	getAvailableModules: `${core_backend_url}admin/roles/app-modules`,

	getCustomerSettings: `${core_backend_url}customer/settings/show-settings`,

	getBankInfo: `${core_backend_url}auth/get-bank-info`,
	getSecurityQuestions: `${core_backend_url}customer/settings/get-security-questions`
}
