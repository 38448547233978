import AdminWrapper from "../../AdminWrapper";
import {ButtonField, SelectField, TextInputField} from "../../../../components/form/components";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {handleSystemManagement, operationsPermissions, type} from "../../../../config/redux/slice/adminSlice";
// import {colors} from "../../../../config/color";

import styles from '../../../../assets/css/onboarding.module.css'
import {useState} from "react";
import {AdminSuccessModalWrapper} from "../../_component";

const AddNewUser = () => {
    const {register, handleSubmit, getValues, setValue,setError, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {systemManagement:{roles,branches},success_msg,successModal} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const [imgPath,setImgPath] = useState()
    const dispatch = useDispatch()

    const handleFileUpload = (e) => {
        const size = e.target.files[0]['size']
        const type = e.target.files[0]['type'] //image/jpeg @image/png

        if (size <= 15000000) {
            if (type === 'image/jpeg' || type === 'image/png') {
                setImgPath(URL.createObjectURL(e.target.files[0]))
            } else {
                setError('profile_image','this file type is not allowed')
            }
        } else {
            setError('profile_image','image is too large')
        }
    }

    const onSubmit = (data) => {
        //
        dispatch(handleSystemManagement({type:type.add_new_user,modal:true}))
    }


    return <AdminWrapper title={'Add New'}>
        <div className={'mx-auto w-75'}>
            <form onSubmit={handleSubmit(onSubmit)} className={'w-75'}>
                <div className={`${styles['file_upload']} mb-5`}>
                    <div className={'d-flex flex-column gap-3'}>
                        {imgPath ?
                            <span className={'text-center'}><img  src={imgPath} alt={''}  width={'100'}/></span>
                            :
                            <img src={'../../../assets/images/admin/form_step/file_upload_icon.svg'} alt={''} height={'80'}/>
                        }
                        <label htmlFor={styles['inputTag']} style={{color: colors.primary_color}}>
                            Upload your picture from your storage <br/> or cloud or drive
                            <input id={styles['inputTag']} type="file"
                                   filename={imgPath}
                                   className={` ${errors.profile_image?.message && 'is-invalid'}`}
                                   {...register('profile_image',
                                       {
                                           // required:{value:true,message:'this field is required'},
                                           onChange : (e) => handleFileUpload(e)
                                       }
                                   )}
                            />
                            <br/>
                            <span id={styles["imageName"]}> </span>
                        </label>
                    </div>
                    {errors.profile_image?.message && <div className="invalid-feedback">{errors?.profile_image?.message}</div>}
                </div>
                {/*first name and last name wrapper */}
                <div className={'row mb-3'}>
                    {/*first_name */}
                    <div className={'col-6'}>
                        <TextInputField
                            fieldName={'first_name'}
                            // onClickFunc={}
                            register={register}
                            isReq={true}
                            error_msg={errors.first_name?.message}
                            label={'First Name'}
                            defaultVal={''}
                            placeholder={''}
                            // inputStyles={}
                            patternRules={'letters'}
                        />
                    </div>
                    {/*last_name    */}
                    <div className={'col-6'}>
                        <TextInputField
                            fieldName={'last_name'}
                            // onClickFunc={}
                            register={register}
                            isReq={true}
                            error_msg={errors.last_name?.message}
                            label={'Last Name'}
                            defaultVal={''}
                            placeholder={''}
                            // inputStyles={}
                            patternRules={'letters'}
                        />
                    </div>
                </div>
                {/* email and phone_number wrapper   */}
                <div className={'row mb-3'}>
                    {/*email*/}
                    <div className={'col-6'}>
                        <TextInputField
                            fieldName={'email'}
                            // onClickFunc={}
                            register={register}
                            isReq={true}
                            error_msg={errors.email?.message}
                            label={'Email Address'}
                            defaultVal={''}
                            placeholder={''}
                            // inputStyles={}
                            patternRules={'email'}
                        />
                    </div>
                    {/* phone_number*/}
                    <div className={'col-6'}>
                        <TextInputField
                            fieldName={'phone_number'}
                            // onClickFunc={}
                            register={register}
                            isReq={true}
                            error_msg={errors.phone_number?.message}
                            label={'Phone Number'}
                            defaultVal={''}
                            placeholder={''}
                            // inputStyles={}
                            patternRules={'numbers'}
                        />
                    </div>
                </div>
                {/*address and  branch*/}
                <div className={'row mb-3'}>
                    <div className={'col-6'}>
                        <TextInputField
                            fieldName={'address'}
                            // onClickFunc={}
                            register={register}
                            isReq={true}
                            error_msg={errors.address?.message}
                            label={'Address'}
                            defaultVal={''}
                            placeholder={''}
                            // inputStyles={}
                            patternRules={'letters_numbers'}
                        />
                    </div>
                    <div className={'col-6'}>
                        <SelectField
                            fieldName={'branch_id'}
                            defaultVal={''}
                            label={'Branch'}
                            error_msg={errors.branch_id?.message}
                            isReq={true}
                            register={register}
                            // selectFunc={}
                            // selectStyle={}
                            // col={}
                        >
                            <option value={''}>Select User Branch</option>
                            {
                                roles?.map((role, i = 0) =>
                                <option key={i} value={role.id}>{role?.name}</option>)
                            }
                        </SelectField>
                    </div>
                </div>
                {/*role */}
                <div className={'row'}>
                    <div className={'col-6'}>
                        <SelectField
                            fieldName={'role_id'}
                            defaultVal={''}
                            label={'Role'}
                            error_msg={errors.role_id?.message}
                            isReq={true}
                            register={register}
                            // selectFunc={}
                            // selectStyle={}
                            // col={}
                        >
                            <option value={''}>Select User Role </option>
                            {
                                roles?.map((role, i = 0) =>
                                    <option key={i} value={role.id}>{role?.name}</option>)
                            }
                        </SelectField>
                    </div>

                </div>
                {/**/}
                <div>
                    {operationsPermissions.map((operation,j=0) => <>
                        <div className={'d-flex justify-content-between mt-5'}>
                            <h6 style={{color:colors.secondary_color}}>{operation.title}</h6>
                            <div className={'d-flex gap-2'}>
                                <div style={{color:colors.secondary_color}}>Create</div>
                                <div style={{color:colors.secondary_color}}>Read</div>
                                <div style={{color:colors.secondary_color}}>Update</div>
                                <div style={{color:colors.secondary_color}}>Delete</div>
                            </div>
                        </div>
                        {operation.data.map((item,i=0) =>
                            <div className={'d-flex justify-content-between my-4'}>
                            <h6>{item.text}</h6>
                            <div className={'d-flex justify-content-between gap-5'}>
                                <div>
                                    <input
                                        className={''}
                                        type={'checkbox'}
                                        {...register(`${item.text.toLowerCase()}_create`)}
                                    />
                                </div>
                                <div>
                                    <input type={'checkbox'}
                                           {...register(`${item.text.toLowerCase()}_read`)}/>
                                </div>
                                <div>
                                    <input type={'checkbox'}
                                    {...register(`${item.text.toLowerCase()}_update`)}/>
                                </div>
                                <div>
                                    <input type={'checkbox'}
                                           {...register(`${item.text.toLowerCase()}_delete`)}/>
                                </div>
                            </div>
                        </div> )}

                    </>)}


                </div>
              {/* */}
                <div className={'row w-100'}>
                    <ButtonField
                        // btnStyle={}
                        btnTxt={'Continue'}
                        // btnAdditionalStyle={}
                        btnWidth={'w-100'}
                        id={'d'}
                        bgColor={colors.secondary_color}
                        txtColor={colors.white}
                    />
                </div>

            </form>
        </div>

        {successModal && <AdminSuccessModalWrapper btnText={'View'} backdrop={successModal} msg={success_msg} />}

    </AdminWrapper>
}
export default AddNewUser