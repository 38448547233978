import {GeneralModalWrapper} from "../../../_shared_component";
import {useDispatch, useSelector} from "react-redux";
import {
    clearErrorMsg, onGetInternetBankingRemark, onInternetBankingApprovalApprove,
    onInternetBankingApprovalReq,
    setErrorMsg,
    setInternetReqDetails,
    setLoading
} from "../../../../config/redux/slice/adminSlice";
import {clearAll} from "../../../../config/redux/slice/authData";
import {useForm} from "react-hook-form";
import {postRequest, postRequest2} from "../../../../config/redux/request";
import {appConfig, remoteUrl} from "../../../../config/config";
import {Loader} from "../../../../components";
import {ButtonField} from "../../../../components/form/components";
// import {colors} from "../../../../config/color";
import {Link} from "react-router-dom";
import moment from "moment";
import {pagesText} from "../../../../config/string/english";


const InternetBankingRequestDetails = ({showModal}) => {
    //1
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {token, user_role} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        loading,
        adminErrorMsg,
        success_msg,
        systemManagement: {internetBankingRequest: {internetRequestDetails, isInternetReqRemark, remark}}
    } = useSelector((state) => state.administrator)

    const dispatch = useDispatch()
    //2
    const onClose = () => {
        dispatch(clearErrorMsg())
        dispatch(clearAll())
        dispatch(setInternetReqDetails({modal: false, data: '', isRemark: ''}))
    }
    // 3 submitRequestForApproval
    const submitRequestForApproval = (data) => {
        dispatch(clearErrorMsg())
        dispatch(clearAll())
        const postData = {
            "jwt": token,
            "remark": data.remark,
            "ibr_id": internetRequestDetails.ibr_id
        }
        //requestInternetBankingApproval
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestInternetBankingApproval}`,
            postData,
            dispatch,
            setLoading,
            onInternetBankingApprovalReq,
            setErrorMsg
        )

    }
    /*method that approves internet banking request*/
    const approveInternetBankingReq = (data) => {
        const postData = {
            "jwt": token,
            "ibr_id": internetRequestDetails.ibr_id,
            "account_number": internetRequestDetails.account_number
        }
        //approveInternetBankingApproval
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.approveInternetBankingApproval}`,
            postData,
            dispatch,
            setLoading,
            onInternetBankingApprovalApprove,
            setErrorMsg,
        )

    }

    //
    if (isInternetReqRemark === 'show_remark_form' || isInternetReqRemark === 'no_remark_yet') {
        const postData = {
            "jwt": token,
            "ibr_id": internetRequestDetails.ibr_id
        }
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.viewInternetBankingApproval}`,
            postData,
            dispatch,
            setLoading,
            onGetInternetBankingRemark,
            setErrorMsg
        )
        dispatch(setInternetReqDetails({modal: true, data: internetRequestDetails, isRemark: 'hide_remark_form'}))
    }
    return (
        <>
            <GeneralModalWrapper showModal={showModal} closeModalFunc={onClose}
                                 modal_title={'Online Banking Request Approval Details'}>
                <div className={'px-3'}>
                    <table className={'table table-striped'}>
                        <thead></thead>
                        <tbody>
                        <tr>
                            <td>Name</td>
                            <td>{`${internetRequestDetails.firstname ?? ''} 
                    ${internetRequestDetails.middlename ?? ''} 
                    ${internetRequestDetails.lastname ?? ''} `}</td>
                        </tr>
                        <tr>
                            <td>Account Number</td>
                            <td>{internetRequestDetails.account_number}</td>
                        </tr>

                        <tr>
                            <td>Customer Email</td>
                            <td>{internetRequestDetails.customer_email}</td>
                        </tr>

                        <tr>
                            <td>Customer Phone</td>
                            <td>{internetRequestDetails.customer_phone}</td>
                        </tr>

                        <tr>
                            <td>Initiated By</td>
                            <td>{internetRequestDetails.initiated_by ?? 'N/A'}</td>
                        </tr>

                        <tr>
                            <td>Approved By</td>
                            <td>{internetRequestDetails.approved_by ?? 'N/A'}</td>
                        </tr>

                        <tr>
                            <td>Request Status</td>
                            <td>

                                {!internetRequestDetails.initiated_by
                                    ?
                                    <small className='uk-text-warning uk-text-bold'>pending</small>
                                    :
                                    internetRequestDetails.initiated_by && internetRequestDetails.request_status === 'pending'
                                        ?
                                        <small className='text-info uk-text-bold'>processing</small>
                                        :
                                        internetRequestDetails.request_status==='approved'
                                            ?
                                            <small className='uk-text-success uk-text-bold'>approved</small>
                                            :
                                            <small className='badge badge-danger uk-text-bold'>N/A</small>
                                }
                            </td>
                        </tr>

                        <tr>
                            <td>Request Date</td>
                            <td>{moment(internetRequestDetails.created_on).format(pagesText.full_date)}</td>
                        </tr>

                        <tr>
                            <td>Remark</td>
                            <td>{internetRequestDetails.remark ?? 'N/A'}</td>
                        </tr>

                        </tbody>

                    </table>
                    {/*        */}

                </div>

                {/* check if user is an initiator */}
                {
                    user_role === pagesText.user_roles_text_key[2]
                        ?
                        internetRequestDetails.request_status === 'pending' && remark === ""
                        &&
                        <>
                            <form onSubmit={handleSubmit(submitRequestForApproval)}>
                                <div className={'ml-3'}>
                                    <label>remark</label>
                                    <textarea {...register('remark', {
                                        required: true
                                    })} className={'form-control mb-2'}></textarea>
                                </div>

                                {
                                    loading
                                        ?
                                        <Loader/>
                                        :
                                        <ButtonField
                                            btnTxt={'Submit'}
                                            btnWidth={'100'} bgColor={colors.secondary_color}
                                            txtColor={colors.white} id={'submit'}
                                        />
                                }
                            </form>
                        </>
                        :
                        ''
                }

                {/* check if user is an approver */}
                {
                    user_role === pagesText.user_roles_text_key[3]
                        ?
                        internetRequestDetails.request_status === 'approved' ? " " :
                            (internetRequestDetails.request_status !== 'approved' && remark) &&
                            <>
                                <div className={''}>
                                    <div className={'ml-3'}>
                                        <div>
                                            {loading ? <Loader/> :
                                                <Link onClick={() => approveInternetBankingReq()} className={'btn'}
                                                      to={''}
                                                      style={{background: colors.secondary_color, color: colors.white}}
                                                >
                                                    Approve Request
                                                </Link>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </>
                        :
                        ''
                }
                {/*<InternetBankingApprovalFormModal/>*/}
            </GeneralModalWrapper>

        </>

    )
}

export default InternetBankingRequestDetails