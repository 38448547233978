import { useDispatch, useSelector } from "react-redux";
import CustomerWrapper from "../CutomerWrapper";

import { useEffect } from "react";
import { appConfig, endpointUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import { getBankSettingsForCustomer, setCustomerErrorMsg, setLoading } from "../../../config/redux/slice/customerSlice";

const parse = require('html-react-parser');

const PrivacyPolicy = () => {
    const {token} = useSelector((state) => state.authData)
    const {bankingSettings} = useSelector((state) => state.customer)
    const dispatch = useDispatch()

    useEffect(() => {
        getSettings()
    }, [])


    const getSettings = () => {
        const postData = {
            "jwt": token
        }
        let res = postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getBankInfo}`,
            postData,
            dispatch,
            setLoading,
            getBankSettingsForCustomer,
            setCustomerErrorMsg
        )
    }

    return (
        <CustomerWrapper showNotificationIcon={false} title={'Privacy Policy'}>
            <div className={'card p-5'}>
                {parse(bankingSettings?.privacy_policy ?? '')}
            </div>

        </CustomerWrapper>
    )
}

export default PrivacyPolicy