
import { ButtonField, TextInputField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import NonAuthWrapper from "../NonAuthWrapper";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import formStyles from "../../../assets/css/formStyle.module.css";
import { Loader } from "../../../components";
import { appConfig, remoteUrl } from "../../../config/config";
import { postRequest, postRequest2 } from "../../../config/redux/request";
import {
    auth_types,
    onPasswordReset,
    setAuthModalForm,
    setErrorMessage,
    setLoading
} from "../../../config/redux/slice/authData";
import ModalWrapper from "./ModalWrapper";

const MySwal = withReactContent(Swal)

const ResetPassword = ({showModal}) => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {tempEmail,success_msg,errorMsg,loading} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const dispatch = useDispatch()

    const onClose = () => {
        // :: ::: ::: ::: ::
        dispatch(setAuthModalForm({type:auth_types.show_reset_password_form,modal:false}))
    }

    const onSubmit = async (data) => {
        const postData = {
            "email":tempEmail,
            "resettoken":data.reset_token,
            "password":data.password,
            "pin" : data.pin
        }
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.resetPass}`,
            postData,
            dispatch,
            setLoading,
            onPasswordReset,
            setErrorMessage
        )
    }

    if(success_msg==='password reset successful'){
        setValue('password','')
        setValue('confirm_password','')
        setValue('reset_token','')


        Swal.fire(
            'Success',
            `${success_msg}`,
            'success',
        ).then(() => {
                dispatch(setAuthModalForm({type:auth_types.show_login_form,modal:true}))
        })
    }

    return <NonAuthWrapper title={'Reset Password'}>
        <ModalWrapper closeModalFunc={onClose} showModal={showModal} modal_title={'Password Reset'}>

        <form onSubmit={handleSubmit(onSubmit)} className={'px-5'}>
            <div className={''}>
                <div className={''}>Enter the reset token sent to this email : <u className={'text-primary'}>{tempEmail}</u>
                    {/*<input type={'text'} readOnly={true} value={tempEmail} className={'form-control'}/>*/}
                </div>
                <div className={'mt-2'}>
                    <TextInputField
                        fieldName={'reset_token'}
                        register={register}
                        label={'OTP/Reset token'}
                        isReq={true}
                        error_msg={errors.reset_token?.message}
                        defaultVal={''}
                        // patternRules={''}
                        placeholder={''}
                        // inputStyles={}
                        // onClickFunc={}
                    />
                </div>

                <div className={'mt-3'}>
                    <div className="form-group">
                        <label>Password</label>
                        <div className="input-group" style={{border:`0.05rem solid #ced4da`,padding:'5px'}}>
                            <input
                                type={showPassword ? 'text' :'password'}
                                className={`form-control ${formStyles.textField} ${errors.password && 'is-invalid'}`}
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                id={'password'}
                                placeholder={''}
                                defaultValue={''}
                                autoComplete={'off'}
                                autoCorrect={'off'}
                                style={{border:0}}
                                {...register(`password`, {
                                    required: {
                                        value: true,
                                        message: '*this field is required'
                                    },
                                })
                                }
                            />
                            <div className="input-group-append">
                                <span
                                    id="basic-addon2"
                                    onClick={()=>setShowPassword(!showPassword)}
                                    className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                    style={{border:0}}
                                >
                               <img src={'../../assets/images/admin/auth/open_wide.svg'} alt={''}/>
                            </span>
                            </div>
                        </div>
                        {errors.password && <div className="invalid-feedback">{errors.password?.message}</div>}
                    </div>
                </div>

                <div className={'mt-3'}>
                    <div className="form-group">
                        <label>Confirm password</label>
                        <div className="input-group" style={{border:`0.05rem solid #ced4da`,padding:'5px'}}>

                            <input
                                type={showConfirmPassword ? 'text' :'password'}
                                className={`form-control ${formStyles.textField} ${errors.password && 'is-invalid'}`}
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                id={'password_confirm'}
                                placeholder={''}
                                defaultValue={''}
                                autoComplete={'off'}
                                autoCorrect={'off'}
                                style={{border:0}}
                                {...register(`confirm_password`, {
                                    required: {
                                        value: true,
                                        message: '*this field is required'
                                    },
                                    validate: value => value === getValues('password') || "The passwords do not match"
                                })
                                }
                            />
                            <div className="input-group-append">
                                <span
                                    id="basic-addon2"
                                    onClick={()=>setShowConfirmPassword(!showConfirmPassword)}
                                    className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                    style={{border:0}}
                                >
                               <img src={'../../assets/images/admin/auth/open_wide.svg'} alt={''}/>
                            </span>
                            </div>
                        </div>
                        {errors.confirm_password && <div className="text-danger">{errors?.confirm_password?.message}</div>}
                    </div>
                </div>

                <div className={'mt-2'}>
                    <TextInputField
                        fieldName={'pin'}
                        register={register}
                        label={'Transaction PIN'}
                        isReq={true}
                        error_msg={errors.pin?.message}
                        defaultVal={''}
                        // patternRules={''}
                        placeholder={''}
                        // inputStyles={}
                        // onClickFunc={}
                    />
                </div>



                <div className={'mb-3 mt-4 text-center'}>
                    {loading ? <Loader/> :
                        <ButtonField btnWidth={'100'} btnStyle={'h-100 py-2'} btnTxt={'Reset Password'}
                                     bgColor={colors.secondary_color} txtColor={colors.white}/>
                    }
                </div>
            </div>
        </form>
        </ModalWrapper>
    </NonAuthWrapper>
}

export default  ResetPassword