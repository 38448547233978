import AdminWrapper from "../AdminWrapper";
import { urls } from "../../../config/urls/urls";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const CustomReports = () =>  {
const {userData:{modules, permissions}, user_role,moduleRoles} = useSelector((state) => state.authData)
const navigate = useNavigate()    

    // useEffect(()=>{
    //     if(!moduleRoles["Reports CUSTOM"]?.includes(user_role)){
    //         navigate(`${urls.adminDashboard}`)
    //     }        
    // }, [user_role])   

    return (
        <AdminWrapper>
            <div className={'card'}>
                <div className={'card-header'}>
                    <h2 className={'tableHeader'}>{'Custom Reports'}</h2>
                </div>
                <div className={'card-body'}></div>

            </div>
        </AdminWrapper>
    )
}

export default  CustomReports