import { useDispatch, useSelector } from "react-redux";
// import { colors } from "../../../config/color";
import { closeSuccessModal } from "../../../config/redux/slice/adminSlice";

const AdminSuccessModalWrapper = ({backdrop,msg,btnText}) => {
    const dispatch = useDispatch()
    const {colors} = useSelector((state) => state.app)

    const onClose = () => {
        dispatch(closeSuccessModal())
    }

    return <>
        <div className="modal fade show" id="basicModal" tabIndex="-1"
             style={{
                 display: "block",
                 paddingRight: "17px"
             }} aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header" style={{border: "0px"}}>
                        <h5 className="modal-title text-center mx-auto w-100"> </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={()=>onClose()}></button>
                    </div>
                    <div className="modal-body">
                        <div className={'text-center p-5'}>
                            <img src={'../../assets/images/admin/success.gif'} alt={''} className={'mb-3'} height={'100'}/>
                            <h4>Successful</h4>
                            <p>{msg}</p>
                            <div className={'btn w-75'} onClick={() => onClose() }
                                 style={{background:colors.secondary_color,color:colors.white}}>
                                {btnText}
                            </div>
                        </div>

                        <p></p>

                    </div>
                </div>
            </div>
        </div>
        {backdrop && <div className={`modal-backdrop fade show  `}></div>}
    </>
}

export default  AdminSuccessModalWrapper