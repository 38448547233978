import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { ButtonField } from "../../components/form/components";
// import { colors } from "../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../config/config";
import { postRequest, postRequest2 } from "../../config/redux/request";
import {
    changeModalSize,
    clearAll,
    logoutUser,
    onCustomerEmailVerificationRequest,
    onCustomerLogin2,
    setErrorMessage,
    setLoading
} from "../../config/redux/slice/authData";
import { urls as onBoardUrls } from "../../config/urls/urls";
import AlertWrapper from "../_shared_component/AlertWrapper";
import { PhoneNumberComponent } from "../onboard_one/components";
import AuthWrapperCustomer from "../v1/onboard/components/AuthWrapperCustomer";
import { logoutCustomer } from "../../config/redux/slice/customerSlice";
import { logoutAdmin } from "../../config/redux/slice/adminSlice";


const PasscodeResetScreen = () => {

    const navigate = useNavigate()
    // Check LoginNew Component for reference
    const [togglePassword, setTogglePassword] = useState(false)
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        loading,
        showLoginModal,
        user_role,
        errorMsg,
        success_msg,
        tempEmail,
        token,
        userData
    } = useSelector((state) => state.authData)

    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [isEmailVerified, setEmailVerified] = useState(false)
    const dispatch = useDispatch()

    useEffect(()=> {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
    },[])



    const closeAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        let phone = data.code + data.mobile;
        // let phone = data.mobile
        // if(data.mobile.length == 11 || data.mobile.charAt(0) == '0'){
        //     phone = '234' + data.mobile.slice(1);
        // } else{
        //     phone = '234' + data.mobile
        // }
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.forgotPasscode}`,
            {phone: phone},
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400){
            dispatch(onCustomerLogin2({type: '', data:{phone: phone, type: ''}}))
            navigate(`${onBoardUrls.otp_passcode_reset}`)
        }
    }
    //
    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }

    // useEffect(()=>{
 
    //     if (token!=='' && user_role != 'customer') {
    //         dispatch(logoutUser())
    //         dispatch(logoutCustomer())
    //         dispatch(logoutAdmin())
    //         // dispatch(setErrorMessage({message:'Customer does not exist.'}))

    //         navigate(onBoardUrls.login)
    //     }
    // }, [token, user_role])
    //
    return <AuthWrapperCustomer title='Reset Passcode'>
                <fieldset>
                    <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)} autoComplete={'off'}>
                            {
                            errorMsg &&
                            <AlertWrapper onClick={closeAlert} type={'error'}>
                                <div className={'py-1 text-capitalize'}>
                                    {errorMsg === "Cannot read properties of undefined (reading 'account_number')"
                                        ?
                                        "Your account request is still pending, you will get a email notification upon approval."
                                        :
                                        errorMsg === 'verify email first' ?
                                            <div className="">

                                                Your email needs to be verified before you can use online banking.
                                                <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}>
                                                    <span className={'text-primary'}> <u> Click here </u> </span>
                                                </Link>to verify.
                                            </div>
                                            :
                                            errorMsg === 'Request failed with status code 429' ?
                                                'To many Request. Please wait for 15min' : errorMsg
                                    }
                                </div>
                            </AlertWrapper>
                        }
                        <div className={'row mb-2'}>
                            <div className={'col-xl-12'}>
                                <PhoneNumberComponent
                                    fieldName={'mobile'}
                                    setValueFunc={setValue}
                                    error_msg={errors.mobile?.message}
                                    col={'col-xl-12'}
                                    label={'Phone Number'}
                                    nextCompId={'password'}
                                    placeholder={''}
                                    isReq={true}
                                    register={register}
                                    defaultVal={''}
                                    patternRules={''}
                                    hasHelperTip={true}
                                    helperTipsMsg={'Please enter your phone number to receive an OTP to reset your passcode.'}
                                />
                            </div>

                        </div>

                        <div className={''}>
                            {loading ? <Loader/> :
                                <ButtonField
                                    txtColor={colors.white}
                                    bgColor={colors.primary_color}
                                    btnTxt={'Send Reset Code'}
                                    btnWidth={'100'}
                                    btnStyle={'mx-auto col-xl-12 py-2'}/>}
                        </div>
                    </form>
                </fieldset>
                <p className={'text-center'}>
                    <Link
                        to={onBoardUrls.login}
                        style={{color: colors.primary_color}}
                    >
                        Back to Login
                    </Link>
                </p>
    </AuthWrapperCustomer>
}


export default PasscodeResetScreen
