import {useForm} from "react-hook-form";
import {ButtonField} from "../../../../components/form/components";
// import {colors} from "../../../../config/color";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {postRequest, postRequest2} from "../../../../config/redux/request";
import {appConfig, endpointUrl, remoteUrl} from "../../../../config/config";
import {
    onCustomerLogin,
    onUpdateCustomerDetails
} from "../../../../config/redux/slice/authData";
import {
    onSmsNotification,
    onToggleSmsNotification,
    setCustomerErrorMsg,
    setLoading, setLoadingTwo
} from "../../../../config/redux/slice/customerSlice";
import {Loader} from "../../../../components";

const SmsNotification = () => {
    const {register, handleSubmit, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {token} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {loading_, sms_notification} = useSelector((state) => state.customer)
    const {userData} = useSelector((state) => state.authData)
    const dispatch = useDispatch()
    //customerDashData?.sms_notification setLoadingTwo

    const onSubmitData = async(data) => {
        let smsState = data.turn_sms_on_off ? 1 : 0
        const postData = {
            "turn_sms_on_off": smsState
        }

        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.smsNotification}`,
            postData,
            dispatch,
            setLoadingTwo,
            onSmsNotification,
            setCustomerErrorMsg)
        if(res !== undefined && res.status < 400){
            dispatch(onCustomerLogin(res.data.data))
        }    
    }


    return <>
        <form onSubmit={handleSubmit(onSubmitData)}>
            <div className={'row ml-3 sms_notification_wrapper'}>
                <div className={'col-sm-4 col-md-5 mt-2 sms_notification_left'}>
                    <label className=" text-center notifications_no">
                        <input
                            className="form-check-input mr-2"
                            defaultChecked={userData?.sms_notification}
                            type="checkbox"
                            onChange={() => dispatch(onToggleSmsNotification())}
                            {...register('turn_sms_on_off')}/>
                        <span className="form-check-sign">SMS Notification</span>
                    </label>
                </div>


                <div className={'col-sm-4 col-md-5 sms_notification_right'}>
                    {
                        loading_ ?
                            <Loader/>
                            :
                            <ButtonField btnTxt={'Save Setting'}
                                         btnWidth={'75'}
                                         btnStyle={'btn-sm py-2 px-2'}
                                         bgColor={colors.secondary_color} txtColor={colors.white}
                                         id={'sms_notification'}/>
                    }
                </div>
            </div>


        </form>

    </>
}


export default SmsNotification