import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components";
import { ButtonField, SelectField, TextInputField } from "../../../../components/form/components";
// import { colors } from "../../../../config/color";
import { appConfig, endpointUrl } from "../../../../config/config";
import { postRequest, postRequest2 } from "../../../../config/redux/request";
import { clearMessages, onSecurityQuestion, setCustomerErrorMsg, setLoading } from "../../../../config/redux/slice/customerSlice";
import { useEffect, useState } from "react";
import { onUpdateCustomerDetails } from "../../../../config/redux/slice/authData";


const SetSecurityQuestion = ({backdrop}) => {
    const {token} = useSelector((state) => state.authData)
    const {loading} = useSelector((state) => state.customer)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const dispatch = useDispatch()
    const {colors} = useSelector((state) => state.app)
    const [questions, setQuestions] = useState([])

    useEffect(()=>{
        getQuestions()
    }, [])


    const onSubmit = async (data) => {
        let postData = {
            'security_question': data.security_question,
            'security_answer': data.security_answer
        }
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.createSecurityQuestion}`,
            postData,
            dispatch,
            setLoading,
            onSecurityQuestion,
            setCustomerErrorMsg
        )
        if(res !== undefined && res.status < 400){
            dispatch(onUpdateCustomerDetails({security_question: data.security_question}))
        }
    }

    const getQuestions = async () => {
        dispatch(clearMessages())

        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getSecurityQuestions}`,
            {},
            dispatch,
            "",
            "",
            setCustomerErrorMsg
        )
        if(res !== undefined && res.status < 400){
            setQuestions(res.data.data)
        }
    }

    return (
        <>
            <div className="modal fade show"
                 id="basicModal"
                 tabIndex="-1" aria-hidden="true"
                 style={{
                     display: "block",
                     paddingRight: "17px"
                 }}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header" style={{border: "0px"}}>
                            {/**/}
                            <h3 className={'text-center mx-auto w-100'}>Set your Security Question</h3>
                        </div>
                        <div className="modal-body">

                            <div className={'px-5 py-3'}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className={'mb-3'}>
                                        <SelectField
                                            defaultVal={''}
                                            register={register}
                                            isReq={true}
                                            fieldName={'security_question'}
                                            label={'Security Question'}
                                            id={'security_question'}
                                            error_msg={errors.security_question?.message}
                                            // col={}
                                            // selectFunc={}
                                        >
                                            <option value="">Select Security Question</option>
                                            {questions.map((item, index)=>(
                                            <option key={item.sq_id} value={item.question}>{item.question}</option>
                                            ))}
                                        </SelectField>
                                    </div> 
                                    {/*Phone Number*/}
                                    <div className={'mb-3'}>
                                        <TextInputField
                                            fieldName={'security_answer'}
                                            error_msg={errors.security_answer?.message}
                                            col={'col-xl-12'}
                                            label={'Security Answer'}
                                            placeholder={''}
                                            isReq={true}
                                            register={register}
                                            defaultVal={''}
                                            patternRules={'security_answer'}
                                        />
                                    </div>
                                    <div className={'mt-4'}>

                                        {loading ? <Loader/> :
                                            <ButtonField
                                                txtColor={colors.white}
                                                bgColor={colors.secondary_color}
                                                btnTxt={'Save Security Question'}
                                                btnWidth={'100'}
                                                btnStyle={'mx-auto col-xl-12 mt-1 pt-2 pb-2'}/>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className="modal-backdrop fade show"></div>}
        </>
    )
}
export default SetSecurityQuestion