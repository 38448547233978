import AdminWrapper from "../AdminWrapper";
import {useEffect, useState} from "react";
import {postRequest, postRequest2} from "../../../config/redux/request";
import {appConfig, remoteUrl} from "../../../config/config";
import {returnFormattedDate} from "../../../config/redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {clearResetBot, onGetAuditLog, setErrorMsg, setLoading} from "../../../config/redux/slice/adminSlice";
import {useForm} from "react-hook-form";
import styles from "../../../assets/css/formStyle.module.css";
import {Loader} from "../../../components";
import {ButtonField} from "../../../components/form/components";
// import {colors} from "../../../config/color";
import {auditLogDatatable} from "../../../config/datatableInit";
import {strText} from "../../../config/string";
import {pagesText} from "../../../config/string/english";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { urls } from "../../../config/urls/urls";

const SystemLogs = () => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {userData:{modules, permissions},token,user_role,moduleRoles} = useSelector((state) => state.authData)
    const {loading,auditLogs,audit_log_bot} = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const getAuditLog = async (data) => {
        let formattedDate = returnFormattedDate(data?.start_date,data?.end_date)

        let postData = {
            "jwt" :`${token}`,
            "start_date":formattedDate.fromDate,
            "end_date":formattedDate.toDate
        }
        let res  = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.adminLog}`,
            postData,
            dispatch,
            setLoading,
            onGetAuditLog,
            setErrorMsg
        )

    }

    // useEffect(()=>{
    //     if(!moduleRoles["Audit Trail"]?.includes(user_role)){
    //         navigate(`${urls.adminDashboard}`)
    //     }        
    // }, [user_role])        

    useEffect(() => {
        getAuditLog({
            start_date: moment().subtract(7,'days').format('YYYY-MM-DD'),
            end_date : moment().format('YYYY-MM-DD')
        })
        auditLogDatatable(auditLogs)
    },[])

    useEffect(()=>{
            if(audit_log_bot){
            auditLogDatatable(auditLogs)
            dispatch(clearResetBot())
        }
    }, [audit_log_bot])

    return (
        <AdminWrapper>
            <div className={'card px-2'}>
                <div className={'card-header'}>
                    <div className={'row'}>
                        <div className={'col-4'}>
                            <h2 className={'tableHeader'}>{pagesText.system_logs}</h2>
                        </div>
                        <div className={'col-8'}>
                            <form onSubmit={handleSubmit(getAuditLog)}>
                                <div className={'row d-flex justify-content-center align-items-center px-3'}>
                                    <div className={'col-sm-12 col-md-4'}>
                                        <input
                                            type={'date'}
                                            defaultValue={''}
                                            {...register('start_date')}
                                            className={`form-control ${styles.textField}`}
                                        />
                                    </div>

                                    <div className={`col-sm-12 col-md-4`}>
                                        <input type={'date'}
                                               defaultValue={''}
                                               {...register('end_date')}
                                               className={`form-control ${styles.textField} `}/>
                                    </div>


                                    <div className={' col-sm-12 col-md-4'}>
                                        {loading ? <Loader/> :
                                            <ButtonField
                                                btnStyle={'py-2'}
                                                // btnAdditionalStyle={}
                                                id={'search'}
                                                txtColor={colors.white}
                                                bgColor={colors.secondary_color}
                                                btnWidth={'100'}
                                                btnTxt={'Apply'}
                                            />
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                <div className={'card-body'}>
                    <table id={'table_id_audit_log'} className={strText.datatable_class}>
                        <thead>
                        <tr>
                            <th>Performer</th>
                            <th>Action</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                    </table>
                </div>
            </div>

        </AdminWrapper>
    )
}

export  default  SystemLogs