import { Link } from "react-router-dom";
// import { colors } from "../../../config/color";
import { urls } from "../../../config/urls/urls";
import AuthAdminWrapper from "./AuthAdminWrapper";
import { useSelector } from "react-redux";

const AdminGetStarted =() => {
    const {colors} = useSelector((state) => state.app)
    return (
        <AuthAdminWrapper>
            <div className={'d-flex flex-column gap-4 mx-auto w-75  p-5 text-center'}>
                <img className={'px-5  pt-5 pb-4  h-50 col-md-4 col-lg-7 mx-auto'}
                     src={'../../assets/images/admin/auth/admin_get_started.svg'} alt={''}/>

                <h4 className={''} style={{color:colors.secondary_color,fontSize:'25px',letterSpacing:'3px'}}>Welcome to Admin Portal</h4>

                <Link
                    className={'w-25 mx-auto px-3 py-2'}
                    to={urls.adminLogin}
                    style={{color:colors.white, background:colors.secondary_color}}
                >
                    Login
                </Link>
            </div>
        </AuthAdminWrapper>

    )
}

export default  AdminGetStarted
