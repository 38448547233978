import { help_desk_url } from "../config";

export const customerUrls = {
    //dashboard
    'customerDashboard' : '/dashboard',
    // activity
    'transactionHistory' : '/transaction-history',
    'topUpHome' : '/services/top-up',
    'transferHome' : '/transfers',
    'utilityHome' : '/services/utility',
    'tokenizedDeposit' : '/tokenized-deposit',
    'tokenizedDepositNew' : '/tokenized-deposit/new',
    'tokenizedWithdrawal' : '/tokenized-withdrawal',

    //My Services
    'cardsHome' : '/services/cards',
    'account_management' : '/services/account-management',
    'cheque_management' : '/services/cheque-management',
    'account_manager' : '/services/account-manager',
    //loans
    'loansHome' : '/loans',
    //profile
    'profileHome' : '/profile',
    //support
    'supportHome' : '/supports',
    //:: settings
    'settingsHome' : '/settings/my-settings',
    'transactionPinHome' : '/settings/transaction-pin-management',
    'customerChangePasscode' : '/settings/profile/passcode-management',
    'securityQuestionHome' : '/settings/security-question-home',
    'updateEmailAddress' : '/settings/update-email-address',
    //
    'privacy_policy' : '/general/privacy-policy',
    'general_settings' : '/general/general-settings',
    'terms_and_condition' : '/general/terms-and-condition',
    'user_profile' : '/settings/profile',

    //help and support
    'help_support': (tenantId, phone) => `${help_desk_url}?uuid=${tenantId}&phone=${phone}`
}