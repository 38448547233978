import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const TierOne  = () => {
    const {userData} = useSelector((state) => state.authData)
    return(
        <>
            <h3 className={'card-header'}>Personal Information</h3>
            <div className={'card-body'}>
                <table className={'table table-striped px-5 '}>
                    <thead>

                    </thead>

                    <tbody>
                    <tr>
                        <td>Fullname</td>
                        <td>{userData.full_name}</td>
                    </tr>

                    <tr>
                        <td>Phone Number</td>
                        <td>{userData.phone_no}</td>
                    </tr>

                    {/* <tr>
                        <td>Date of Birth</td>
                        <td>1960-12-12</td>
                    </tr>


                    <tr>
                        <td>Gender</td>
                        <td>Male</td>
                    </tr> */}
                    </tbody>

                </table>


            </div>

            {userData.current_tier == 1 && <div className={'card-footer'}>
                <Link to={'/profile?tier=2'}  className={'btn btn-dark pull-right'}> Upgrade to Tier 2</Link>
            </div>}

        </>

    )
}


export default TierOne