import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components";
import { ButtonField } from "../../../../components/form/components";
import { appConfig, endpointUrl } from "../../../../config/config";
import { postRequest2 } from "../../../../config/redux/request";
// import { colors } from "../../../../config/color";

import { clearAdminErrorMsg, onRefreshRoles, onUpdateRole, setErrorMsg, setLoading, setRoleUpdateModal } from "../../../../config/redux/slice/adminSlice";
import { pagesText } from "../../../../config/string/english";

const UpdateRoles = ({ backdrop }) => {
  const { token, user_role } = useSelector((state) => state.authData);
  const {colors} = useSelector((state) => state.app)
  const { loading, temp_error_msg, success_msg, adminRoleManagement: {roleDetails} } = useSelector((state) => state.administrator);

  const [availableModules, setAvailableModules] = useState([]);
  const [availablePermissions, setAvailablePermissions] = useState([]);
const [userModulesState, setUserModules] = useState({});
const [selectAllModules, setSelectAllModules] = useState(false);
const [selectAllPermissions, setSelectAllPermissions] = useState(false);
  const [userPermissionsState, setUserPermissions] = useState({});
let userModules = {}
let userPermissions = {}

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
    setError,
    watch
  } = useForm({ mode: "onTouched" });
  const dispatch = useDispatch();
//   const moduleValue = watch('modules', userModules);
//   const permissionValue = watch('permissions', userPermissions);

  const onSubmit = async (data) => {    
    let modulesString = Array.isArray(data.modules) ? data.modules.join(',') : data.modules
    let permissionsString = Array.isArray(data.permissions) ? data.permissions.join(',') : data.permissions
    // setValue('modules', modulesString)
    // setValue('permissions', permissionsString)
    dispatch(clearAdminErrorMsg());
    data.jwt = token
    let res = await postRequest2(
      `${appConfig.auth_token}`, 
      `${endpointUrl.updateRole}`, 
      {
        role_id: roleDetails.id,
        role_name: roleDetails.role_name,
        modules: modulesString,
        permissions: permissionsString
      }, 
      dispatch, setLoading, 
      "", 
      setErrorMsg);
      if(res != undefined && res.status < 400){
        dispatch(onUpdateRole({message:res.data.message}))
        dispatch(setRoleUpdateModal({ modal: false }));
        dispatch(onRefreshRoles())
      }

    }
  const closeModal = () => {
    dispatch(clearAdminErrorMsg());
    dispatch(setRoleUpdateModal({ modal: false }));
  };

  const getModules = async () => {
        let res = await postRequest2(
      `${appConfig.auth_token}`, 
      `${endpointUrl.getAvailableModules}`, 
      {}, 
      dispatch, 
      "", 
      "", 
      setErrorMsg);  
      if(res != undefined && res.status < 400){
        setAvailableModules(res.data.data.modules)
        setAvailablePermissions(res.data.data.permissions)
        const modulesString = roleDetails.modules;
        const arrayOfModules = modulesString.split(',');
        (res.data.data.modules).forEach((moduleItem) => {
            userModules = {
                ...userModules,
                [moduleItem]: arrayOfModules.includes(moduleItem)
            }
        })
        setUserModules(userModules)
        const permissionsString = roleDetails._permissions;
        const arrayOfPermissions = permissionsString.split(',');
        (res.data.data.permissions).forEach((permissionItem) => {
            userPermissions = {
                ...userPermissions,
                [permissionItem]: arrayOfPermissions.includes(permissionItem)
            }
        })
        setUserPermissions(userPermissions)
      }  
  }
   

    useEffect(()=>{
        getModules()
    }, [])

  const handleModuleChange = (event) => {
    if(event.target.checked){
        setValue('modules', availableModules)
        setSelectAllModules(true)
    } else {
        setValue('modules', Object.keys(userModulesState).filter(key => userModulesState[key]))   
        setSelectAllModules(false)     
    }
  }; 
  
    const handlePermissionChange = (event) => {
    if(event.target.checked){
        setValue('permissions', availablePermissions)
        setSelectAllPermissions(true)
    } else {
        setValue('permissions', Object.keys(userPermissionsState).filter(key => userPermissionsState[key]))   
        setSelectAllPermissions(false)     
    }
  }; 


  return (
    <>
      <div
        className="modal fade show"
        id="basicModal"
        tabIndex="-1"
        aria-hidden="true"
        style={{
          display: "block",
          paddingRight: "17px",
          overflowY: 'auto',
        }}
      >
        <div style={{maxWidth: '650px', overflowY: 'auto'}} className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header" style={{ border: "0px" }}>
              <h3 className="pt-2" style={{ color: colors.secondary_color }}>
                {" "}
                {pagesText.admin_role_update}{" "}
              </h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className={"px-3 py-3"}>
                {/* {temp_error_msg === "SSO account already exists" && (
                  <div className={"alert alert-danger mb-4"} style={{ color: colors.secondary_color }}>
                    QApp profile for this admin already exist, kindly notify the admin to activate the account on the platform
                  </div>
                )} */}
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'} noValidate>
                        <div className="card px-4 py-3">
                            <div className="d-flex mb-3 justify-content-between">
                            <h4 className="text-uppercase"> {roleDetails?.role_name} <span className="text-capitalize" style={{fontSize: '0.85rem', color: '#6b6767'}}>Available Menu</span></h4>
                                <label>
                                    <input
                                        className="form-check-input ml-1 mr-2"
                                        type="checkbox"
                                        value={'select all'}
                                        checked={selectAllModules}
                                        // {...register('selectAllModule')}
                                        onChange={handleModuleChange}
                                        />
                                    <span className="form-check-sign">Select all</span>
                                </label>                                   
                            </div>
                            <div className="row">
                               {availableModules.map((moduleItem, index)=>{
                                return (
                                    <label key={index} className="col-md-6">
                                        <input
                                            className="form-check-input ml-1 mr-2"
                                            defaultChecked={userModulesState[moduleItem]}
                                            type="checkbox"
                                            value={moduleItem}
                                            {...register('modules')}
                                            // onChange={handleModuleChange}
                                            />
                                        <span className="form-check-sign">{moduleItem}</span>
                                    </label>                                    
                                )
                               })}                                                               
                            </div>  
                            <button
                            type="button"
                            onClick={()=>{
                                setSelectAllModules(false) 
                                setValue('modules', Object.keys(userModulesState).filter(key => userModulesState[key]))
                                 
                            }}
                            className="btn mt-3 py-2 ml-auto mr-3"
                             style={{color:colors.white,background:colors.secondary_color}}>Reset</button>                          
                        </div>
                        <div className="card px-4 py-3">
                            <div className="d-flex mb-3 justify-content-between">
                           <h4 className="text-uppercase">{roleDetails?.role_name} <span className="text-capitalize" style={{fontSize: '0.85rem', color: '#6b6767'}}>Permissions</span></h4>
                                <label>
                                    <input
                                        className="form-check-input ml-1 mr-2"
                                        type="checkbox"
                                        value={'select all'}
                                        checked={selectAllPermissions}
                                        // {...register('selectAllModule')}
                                        onChange={handlePermissionChange}
                                        />
                                    <span className="form-check-sign">Select all</span>
                                </label>                                   
                            </div>                            
                            <div className="row">
                               {availablePermissions.map((permissionItem, index)=>{
                                return (
                                    <label key={index} className="col-md-6">
                                        <input
                                            className="form-check-input ml-1 mr-2"
                                            defaultChecked={userPermissionsState[permissionItem]}
                                            type="checkbox"
                                            value={permissionItem}
                                            {...register('permissions')}

                                            />
                                        <span className="form-check-sign">{permissionItem}</span>
                                    </label>                                    
                                )
                               })} 
                            </div>   
                            <button
                            type="button"
                            onClick={()=>{
                                setSelectAllPermissions(false)
                                setValue('permissions', Object.keys(userPermissionsState).filter(key => userPermissionsState[key]))
                                
                            }}
                            className="btn mt-3 py-2 ml-auto mr-3"
                             style={{color:colors.white,background:colors.secondary_color}}>Reset</button>                                                                               
                        </div>                        
                        <div style={{columnGap: '1rem'}} className={'row mb-4 d-flex'}>
                             <div className={`row col-md-3 d-flex ${errors.role_name?.message ? 'align-items-center' : 'align-items-end'}`}>
                                <div className={'col-md-12 text-center p-0'}>
                                {loading ? <Loader/> :
                                <ButtonField
                                    id={''}
                                    btnWidth={'100'}
                                    txtColor={colors.white}
                                    bgColor={colors.secondary_color}
                                    btnTxt={pagesText.admin_role_update}
                                    btnAdditionalStyle={{paddingTop: '0.67rem', paddingBottom: '0.67rem'}}
                                    // btnStyle={"pt-2 pb-2"}
                                />}

                            </div>
                        </div>
                            </div>
                    </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {backdrop && <div className="modal-backdrop fade show"></div>}
    </>
  );
};
export default UpdateRoles;
