export const respMsg = {
    'link_internet_banking' : 'Your account have been linked successfully.',
    'tokenized_deposit_s' : 'Tokenized deposit generated successfully',
    'update_tokenized_deposit_s' : 'Tokenized deposit updated successfully.',
    //
    'customer_transfer_successful' : 'Transfer Successfully.',
    'save_beneficiary' : 'Beneficiary added successfully.',
    'email_notification_s' : 'Email preference updated successfully',
    'sms_notification_s' : 'SMS preference updated successfully',

    'change_pin_s' : 'Transaction PIN changed successfully.',
    'change_passcode_s' : 'Passcode changed successfully.',
    'create_pin_s' : 'Transaction PIN created successfully.',
    'create_email' : 'Email Added successfully.',

    'change_security_question_s' : 'You have successfully change your security question.',
    'create_security_question_s' : 'Security question created successfully',

    'admin_tokenized_deposit_s' : 'Transaction processed successfully',

    'complete_tier2' : 'Tier 2 Completed Successfully',
    'complete_tier3' : 'Tier 3 Completed Successfully',

    'change_customer_status': 'User status changed successfully',
    'change_customer_pin': 'User pin changed successfully',
    'change_customer_passcode': 'User passcode changed successfully'
}