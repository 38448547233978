import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { colors } from "../../config/color";
import { formatMessages } from "../../config/redux/actions";
import { onSidebarChange, onToggleClick } from "../../config/redux/slice/appSlice";
import { strText } from "../../config/string";
import { pagesText } from "../../config/string/english";
import { urls } from "../../config/urls/urls";
import LogoutModal from "./LogoutModal";


const Header = ({showNotificationIcon}) => {
    const {userData, user_role} = useSelector((state) => state.authData)
    const {colors, appLogo} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const [showLogoutModal, setShowLogoutModal] = useState(false)

    const handleSidebarMinimize = () => {
        //id :: html_tag ""
        dispatch(onSidebarChange())
    }
    return <>
        {/*Tip 1: You can change the background color of the main header using: data-background-color="blue | purple | light-blue | green | orange | red"*/}
        <div className="main-header" data-background-color="purple">
            {/* Logo Header */}
            <div className="logo-header">
                <a href="" className="logo">
                    <img src={appLogo} alt="navbar brand" className="navbar-brand" width={'150'}/>
                </a>
                <button onClick={() => handleSidebarMinimize()}
                        className="navbar-toggler  ml-auto"
                        type="button"
                >
                            <span className="navbar-toggler-icon">
                              <i className="fa fa-bars"/>
                            </span>
                </button>

                <button className="topbar-toggler more" onClick={() => dispatch(onToggleClick())}>
                    <i className="fa fa-ellipsis-v"/>
                </button>

                <div className="navbar-minimize">
                    <button onClick={() => handleSidebarMinimize()}
                            className="btn btn-minimize btn-rounded">
                        <i className="fa fa-bars"/>
                    </button>
                </div>
            </div>
            {/* End Logo Header */}
            {/* Navbar Header */}
            <nav className="navbar navbar-header navbar-expand-lg">
                <div className="container-fluid">
                    <div className={'d-flex  align-items-center justify-content-center'}>
                        <h3 className={'mr-1'}>Welcome,</h3>
                        <h4 className={''}>{userData.full_name}</h4>
                    </div>


                    <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
                        {showNotificationIcon &&
                            <li className="nav-item dropdown hidden-caret">
                                <a className="nav-link dropdown-toggle" href="#" id="notifDropdown" role="button"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-bell" style={{color:colors.primary_color}}/>
                                    <span className="notification">4</span>
                                </a>
                                <ul className="dropdown-menu notif-box animated fadeIn" aria-labelledby="notifDropdown">
                                    <li>
                                        <div className="dropdown-title">You have 2 new notification(s)</div>
                                    </li>
                                    <li>
                                        <div className="notif-scroll scrollbar-outer">
                                            <div className="notif-center">
                                                <a href="#">
                                                    <div className="notif-icon notif-primary"><i
                                                        className="fa fa-user-plus"/></div>
                                                    <div className="notif-content">
                                                    <span className="block">
                                                      New Internet Banking Requests
                                                    </span>
                                                        <span className="time">5 minutes ago</span>
                                                    </div>
                                                </a>
                                                <a href="#">
                                                    <div className="notif-icon notif-success"><i
                                                        className="fa fa-user-plus"/></div>
                                                    <div className="notif-content">
                                                        <span className="block">New Account Opening Requests</span>
                                                        <span className="time">12 minutes ago</span>
                                                    </div>
                                                </a>

                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="see-all" href="#">See all notifications<i
                                            className="fa fa-angle-right"/> </a>
                                    </li>
                                </ul>
                            </li>
                        }
                        <li className="nav-item dropdown hidden-caret">
                            <a className="dropdown-toggle profile-pic" data-toggle="dropdown" href="#"
                               aria-expanded="false">
                                <div className={'d-flex align-items-center'}>
                                    <div className="avatar-sm">
                                        <img src="/_assets/profile_image.png" alt="..."
                                             className="avatar-img rounded-circle"/>
                                    </div>
                                    <div className={'text-white ml-1 align-items-center justify-content-center'}>
                                    </div>


                                </div>

                            </a>

                            <ul className="dropdown-menu dropdown-user animated fadeIn">
                                <li>
                                    {user_role === 'customer' && <>
                                        <Link className="dropdown-item" to={urls.settingsHome}>Account Settings</Link>
                                        <div className="dropdown-divider"/>
                                    </>
                                    }


                                    {

                                        user_role != 'customer' ?
                                            <>
                                                <Link className="dropdown-item" to={'#'}>
                                                    User Role: <b>{formatMessages(user_role)?.toUpperCase()}</b>
                                                </Link>
                                                <div className="dropdown-divider"/>
                                            </>

                                            :
                                            ''
                                    }

                                    <Link onClick={() => setShowLogoutModal(true)} to={'#'}
                                          className="dropdown-item"> Logout </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* End Navbar */}
        </div>
        {showLogoutModal && <LogoutModal backdrop={showLogoutModal} modalFunc={setShowLogoutModal}/>}

    </>
}

export default Header