import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../../components";
import { ButtonField, TextInputField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, endpointUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import {
    onCustomerLogin
} from "../../../config/redux/slice/authData";
import {
    clearMessages,
    onCompleteTier,
    onUpgradeToTier2,
    setCustomerErrorMsg,
    setLoading
} from "../../../config/redux/slice/customerSlice";
// import localStorage from "redux-persist/es/storage";

const TierTwo = ()=> {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const {userData} = useSelector((state) => state.authData)
    const {
        loading,
        error_msg,
    } = useSelector((state) => state.customer)
    const {colors} = useSelector((state) => state.app)

    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [successMessage, setSuccessMessage] = useState(false)

    const closeAlert = () => {
        dispatch(setCustomerErrorMsg({message: null}))
    }

    const closeSuccessAlert = () => {
        setSuccessMessage(false)
    }

    const onSubmit = async (data) => {
        dispatch(clearMessages({}))
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.upgrade_to_tier2}`,
            data,
            dispatch,
            setLoading,
            "",
            setCustomerErrorMsg
        )
        if(res !== undefined && res.status < 400){
            dispatch(onCompleteTier({tier: '2'}))
            dispatch(onUpgradeToTier2({}))
            setSuccessMessage(true)
            dispatch(onCustomerLogin(res.data.data))
            // dispatch(onUpdateCustomerDetails({current_tier: res.data.data.current_tier}));
                // navigate(0);
        }
    }

    return(
        <>
            { userData.current_tier == 1 ?
                (<><div className={'card-header'}>

            </div>
            <div className={'card-body'}>
                <fieldset className={'mx-auto w-100 px-2 mt-2'}>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete={'off'} noValidate>

                        <div className={'row mb-4'}>
                            
                        <div className={'row col-md-7'}>

                            <div className={'col-md-12 p-0'}>
                                <TextInputField
                                    // inputStyles={}
                                    fieldName={'bvn'}
                                    setValueFunc={setValue}
                                    error_msg={errors.bvn?.message}
                                    label={'Bank Verification Number(BVN)'}
                                    isReq={true}
                                    register={register}
                                    minlength={11}
                                    maxlength={11}
                                    defaultVal={''}
                                    placeholder={''}
                                    patternRules={'numbers'}
                                    // onClickFunc={}
                                />
                            </div>

                        </div>
                             <div className={`row col-md-5 d-flex ${errors.bvn?.message ? 'align-items-center' : 'align-items-end'}`}>
                                <div className={'col-md-12 text-center p-0'}>
                                {loading ? <Loader/> :
                                <ButtonField
                                    id={''}
                                    btnWidth={'100'}
                                    bgColor={colors.primary_color}
                                    txtColor={colors.white}
                                    btnTxt={'Submit to Upgrade'}
                                />}

                            </div>
                        </div>
                            </div>
                    </form>
                </fieldset>
            </div></>) :
            (<>
                <h3 className={'card-header'}>Bank Information</h3>
            <div className={'card-body'}>
                <table className={'table table-striped px-5 '}>
                    <thead>

                    </thead>

                    <tbody>
                    <tr>
                        <td>BVN</td>
                        <td>{userData?.bvn}</td>
                    </tr>

                    </tbody>

                </table>


            </div>

            {(userData.current_tier == 2 && userData.tier_status != 'pending') && <div className={'card-footer'}>
                <Link to={'/profile?tier=3'}  className={'btn btn-dark pull-right'}> Upgrade to Tier 3</Link>
            </div>}
            </>)
            }
        </>

    )
}

export default TierTwo

/*
     Tier 2 :
     BVN and Email Photo

     Tier 3 :
     ID Card
 */