import { useEffect, useState } from "react";
import formStyles from "../../../assets/css/formStyle.module.css";


const AmountInputField = ({register, label, error_msg, isReq, defaultVal,defaultVal2}) => {
    const [realAmount,setRealAmount] =  useState()
    const formatAmount = (data) => {
        let o = data.replace(".00",'')
        let temp_data = o.replaceAll(",",'').trim()
        setRealAmount(temp_data)
        // let x = NumberFormatter(Number(temp_data).toFixed(2))

    }
    function formatNumber(e) {
        var rex = /(^\d{2})|(\d{1,3})(?=\d{1,3}|$)/g,
            val = this.value.replace(/^0+|\.|,/g, ""),
            res;

        if (val.length) {
            res = Array.prototype.reduce.call(val, (p, c) => c + p) // reverse the pure numbers string
                .match(rex) // get groups in array
                .reduce((p, c, i) => i - 1 ? p + "," + c : p + "." + c); // insert (.) and (,) accordingly
            res += /\.|,/.test(res) ? "" : ".0"; // test if res has (.) or (,) in it
            this.value = Array.prototype.reduce.call(res, (p, c) => c + p); // reverse the string and display
        }
    }

    useEffect(() => {
        let ni = document.getElementById("temp_amount");
        setRealAmount(defaultVal2)
        // document.getElementById('amount').value = defaultVal2
        ni.addEventListener("keyup", formatNumber);
    },[])

    return (
        <>
            <div className="form-group">
                <input id={"amount"} type={'hidden'}
                       defaultValue={realAmount}
                       {...register('amount')}/>
                <label htmlFor="" className="">{label}</label>
                <input
                    id={"temp_amount"}
                    type="text"
                    onKeyUp={(e)=> formatAmount(e.target?.value)}
                    className={`form-control ${formStyles.textField} ${error_msg && 'is-invalid'}`}
                    autoComplete={'off'}
                    placeholder={''}
                    defaultValue={defaultVal}
                    {...register(`temp_amount`, {
                        required: {
                            value: isReq,
                            message: '*this field is required'
                        }
                    })
                    }
                />
                {error_msg && <div className="invalid-feedback">{error_msg}</div>}
            </div>

        </>
    )

}

export default AmountInputField