import AdminWrapper from "../AdminWrapper";
import {useEffect, useState} from "react";
import {internetBankingRequestTable} from "../../../config/datatableInit";
// import {colors} from "../../../config/color";
import {Filter} from "../../_shared_component";
import {InternetBankingApprovalFormModal, RequestInternetBanking} from "./form";
import {useDispatch, useSelector} from "react-redux";
import {
    handleSystemManagement,
    type,
    setLoading,
    getInternetBankingRequest,
    setErrorMsg,
    clearResetBot, setInternetReqDetails, clearErrorMsg
} from "../../../config/redux/slice/adminSlice";
import {postRequest, postRequest2} from "../../../config/redux/request";
import {appConfig, remoteUrl} from "../../../config/config";
import InternetBankingRequestDetails from "./details/InternetBankingRequestDetails";
import AdminSuccessAlert from "../_component/AdminSuccessAlert";
import AdminErrorAlert from "../_component/AdminErrorAlert";
import {pagesText} from "../../../config/string/english";
import {strText} from "../../../config/string";
import internetBankingFullDetails from "./details/InternetBankingFullDetails";
import InternetBankingFullDetails from "./details/InternetBankingFullDetails";

const RequestHome = () => {
    //1
    const {token} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {success_msg,adminErrorMsg,systemManagement: {internetBankingRequest: {showRequestForm,internetReqData, newInternetReqBot, showInternetRequestDetails}}} = useSelector((state) => state.administrator)
    const dispatch = useDispatch()

    const [showDetailsModal, setShowModalDetails] = useState(false)
    const [detailsData, setDetailsData] = useState('')
    //2
    useEffect(() => {
        dispatch(setInternetReqDetails({modal: false, data: '', isRemark: ''}))
        internetBankingRequestTable(onClickDetailsButton, internetReqData)
        //
        getInternetBankingReq()
    }, [])
    //3
    const onClickDetailsButton = (selectedItem, action_type) => {
        dispatch(clearErrorMsg())
        if (action_type === 'remark') {
            dispatch(setInternetReqDetails({modal: true, data: selectedItem, isRemark: 'show_remark_form'}))
        }

        else if(action_type==='view_details'){
            setShowModalDetails(true)
            setDetailsData(selectedItem)
        }

        else {
            dispatch(setInternetReqDetails({modal: true, data: selectedItem, isRemark: 'no_remark_yet'}))
        }
    }
    //4
    if (newInternetReqBot) {
        internetBankingRequestTable(onClickDetailsButton, internetReqData)
        dispatch(clearResetBot())
    }
    //5
    const getInternetBankingReq = () => {
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.getInternetBankingReq}`,
            {"jwt": token},
            dispatch,
            setLoading,
            getInternetBankingRequest,
            setErrorMsg)
    }

    const onCloseModal = () => {
        setShowModalDetails(false)
        setDetailsData('')
    }



    return (<AdminWrapper title={''}>

        {success_msg && <AdminSuccessAlert msg={success_msg} toastId={'internet_banking'}/>}
        {adminErrorMsg && <AdminErrorAlert msg={adminErrorMsg} toastId={'internet_error'}/>}
        {/*<_InternetBankingDetails*/}
        {showDetailsModal && <InternetBankingFullDetails showModal={showDetailsModal} internetRequestDetails={detailsData} onClose={onCloseModal} />}

        {showRequestForm && <RequestInternetBanking backdrop={showRequestForm}/>}
        {showInternetRequestDetails && <InternetBankingRequestDetails showModal={showInternetRequestDetails} />}
        <div className={'row'}>
            <div className="col-md-12">
                {/*<Filter/>*/}
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <h2 className={'tableHeader'}>{pagesText.internet_banking_requests}</h2>

                        </div>
                    </div>
                    <div className="card-body">
                        {/* Modal */}
                        <table className={`${strText.datatable_class}`} id={'table_id_internet_banking_request'}>
                            <thead>
                                <tr>
                                    {/*<th>S/N</th>*/}
                                    <th width={'15%'}>Name</th>
                                    <th>Account No.</th>
                                    <th>Email Addr.</th>
                                    <th>Phone No.</th>
                                    {/*<th>Requested By</th>*/}
                                    <th width={'10%'}>Status</th>
                                    <th  width={'17%'} style={{color:colors.secondary_color}}>Req. Date</th>
                                    {/*<th width={'5%'} data-orderable="false" style={{color:colors.secondary_color}}>Process Req.</th>*/}
                                    {/*<th width={'5%'} data-orderable="false" style={{color:colors.secondary_color}}>Approve Req.</th>*/}

                                    <th width={'5%'} data-orderable="false" style={{color:colors.secondary_color}}>Action</th>
                                </tr>
                            </thead>

                        </table>
                    </div>
                </div>
            </div>

        </div>
    </AdminWrapper>)
}

export default RequestHome