import { createSlice } from '@reduxjs/toolkit';
import moment from "moment";
import { respMsg } from "../../response_messages";
import { strText } from "../../string";
import { NumberFormatter } from "../actions";
import { getFirstDayOfLastMonth, getLastDayOfCurrentMonth } from '../../../helpers/dateHelper';


const initialState = {
    showAccountListing : false,
    transactionHistoryFilter : {
        start_date : getFirstDayOfLastMonth(),
        end_date :  getLastDayOfCurrentMonth()
    },
    tokenizedHistoryFilter : {
        start_date : getFirstDayOfLastMonth(),
        end_date :  getLastDayOfCurrentMonth()
    },
    success_msg: '',
    generalModal: {
        show: false,
        backdrop: false,
        msg: ''
    },
    sms_notification : false,
    email_notification: false,
    customerReLogin: false,
    loading: false,
    loading_: false,
    setTransactionPin : false,
    setEmailAddress : false,
    setSecurityQuestion : false,
    showWelcomeMessage : false,
    setup: '',
    error_msg : '',
    customerDashData : {
        available_balance : 0,
        book_balance : 0,
    },
    //transaction history
    available_accounts : [],
    transaction_histories : [],
    account_statement_data: [],
    newAccountStatementHistBot : false,
    showAccountStatementModal : false,
    //
    tokenized_histories : [],
    tokenized_details: '',
    tokenized_total_expected : `Total Expected: 0.00`,
    tokenized_total_actual : `Total Actual: 0.00`,
    tokenized_difference : `Difference: 0.00`,

    newTransactionHistBot : false,
    transactionHistoryDetail : null,
    bankingSettings : null,
    // current_tier:1,
    beneficiaries : [],
    ///



    //
    transaction_date: '',

    successModal: {
        show: false,
        backdrop: false,
        msg: ''
    },
    errorModal: {
        show: false,
        backdrop: false,
        msg: ''
    },

    loanAppFormData: {
        amount: '',
        interest_rate: '',
        loan_type: '',
        maximum_tenor: '',
        monthly_repayment: '',
        total_repayment: '',
        terms_and_condition: false,
    },
    //topUp
    topUpFormData: {
        account_id: '',
        amount: '',
        network_id: '',
        phone_number: '',
        transaction_date: ''
    },
    //transfer
    transferFormData: {
        account_id: '',
        type_of_transfer_id: '',
        bank_id: '',
        account_number: '',
        beneficiary: '',
        amount: '',
        description: '',
        transaction_date: ''
    },
    //utility
    utilityFormData : {
        account_id : '',
        provider_id : '',
        provider_name:'',
        package_id : '',
        package_name: '',
        smart_card_no : '',
        amount : '',
        transaction_date : ''
    },
    //
    formResetFunc: '',
    tempPinManagement: {
        pin_one: '',
        pin_two: '',
        pin_three: '',
        pin_four: ''
    },
    //
    tokenizedDepositFormData: {
        type_of_transfer_id: '',
        bank_id: '',
        account_number: '',
        amount: '',
        description: '',
        transaction_date: '',
        beneficiary: '',
        transaction_fee : '',
    },
    tokenizedWithdrawalFormData : {
        amount : 0,
        transaction_date : '',
        transaction_fee : 0
    },

}

export const customerSlice = createSlice({
    name: 'customerApp',
    initialState,
    reducers: {
        //individual functions
        onCustomerLoginMe : (state,action) => {
            state.customerReLogin = false
        },
        logoutCustomer: (state, action) => {
            return initialState
        },
        onUpgradeToTier2: (state, action) => {
            state.loading = false
        },
        onUpgradeToTier3: (state, action) => {
            state.loading = false
        },
        setAccountDetailsModal : (state,action) => {
            //shows and hide account listing for a user, and also when account switching is successful, sends a success message
            state.showAccountListing = action.payload.modal
            if(action?.payload?.message){
                state.success_msg = action?.payload?.message
            }
        },
        resetFilterDate: (state) => {
            state.transactionHistoryFilter = {
                        start_date  : getFirstDayOfLastMonth(),
                        end_date  : getLastDayOfCurrentMonth()
                    }
            state.tokenizedHistoryFilter = {
                start_date: getFirstDayOfLastMonth(),
                end_date: getLastDayOfCurrentMonth()
            }        
        },
        resetDataTableBot : (state) => {
            state.newTransactionHistBot = false
            state.newAccountStatementHistBot = false
            state.error_msg = ''
        },
        setTransactionDate: (state,action) => {
            switch (action.payload.type){
                case 'set_transaction_transfer_date' :
                    state.transferHistoryFilter = {
                        start_date  : action.payload?.filterDate?.start_date,
                        end_date  : action.payload?.filterDate?.end_date
                    }
                    break
            }
        },
        clearMessages : (state) => {
            state.loading = false
            state.success_msg = ''
            state.error_msg = ''
        },
        clearMsg: (state) => {
            state.loading = false
            customerSlice.caseReducers.closeSuccessModal(state)
            customerSlice.caseReducers.closeErrorModal(state)
            customerSlice.caseReducers.closeGeneralForm(state)
        },
        setLoading: (state,action) => {
            state.loading = action.payload.loading
        },
        setLoadingTwo : (state,action) => {
            state.loading_ = action.payload.loading
        },
        setCustomerErrorMsg : (state,action) => {
            let msg = action.payload.message
            state.error_msg =msg === 'invalid jwt' ? strText.timeout_message : msg
            state.customerReLogin =  msg === 'invalid jwt'
        },
        customerReLogin : (state, action)=> {
            state.customerReLogin= false
        },
        onToggleSmsNotification : (state,action) => {
            state.sms_notification =  !state.sms_notification
        },
        onToggleEmailNotification : (state,action) => {
            state.email_notification =  !state.email_notification
        },
        setSetupModal : (state,action) => {
            state.showWelcomeMessage = false
            if(action.payload.type === 'pin'){
                state.setTransactionPin = true
            }else if(action.payload.type==='security'){
                state.setSecurityQuestion = true
            }else if(action.payload.type==='email'){
                state.setEmailAddress = action.payload.state
            }
        },
        getAllTiers: (state, action) => {
            state.error_msg = ''
            state.loading = ''
        },
        onGetCustomerDashboard: (state,action)=> {
            //"sms_notification":0,"email_notification":0,"phone":"09023231212","address":"No 7 Ahman Pategi Street Ilorin"
            let msg =  action.payload?.message
                state.customerDashData = action.payload
                // state.email_notification = Number(action.payload.email_notification) === 1
                // state.sms_notification = Number(action.payload.sms_notification) === 1

                state.showWelcomeMessage = msg==='please setup transaction PIN' || msg==='please setup security question and answer'
                state.setup = msg ==='please setup transaction PIN'? 'pin' : 'security'
                if(msg==='not found'){
                    state.customerDashData.available_balance = 0
                    state.customerDashData.book_balance = 0
                }

        },
        onCreatePin: (state,action) => {
            state.success_msg = respMsg.create_pin_s
            state.setTransactionPin = false
        },
        onCompleteTier: (state,action) => {
            state.success_msg = action.payload.tier == '2' ? respMsg.complete_tier2 : respMsg.complete_tier3
        },
        onCreateEmail: (state,action)=>{
            state.success_msg = respMsg.create_email
            state.setEmailAddress = false
        },
        onChangePin : (state,action) => {
            state.success_msg = respMsg.change_pin_s
        },
        onChangePasscode : (state,action) => {
            state.success_msg = respMsg.change_passcode_s
        },
        //
        onSecurityQuestion : (state,action) => {
            state.success_msg = respMsg.create_security_question_s
            state.setSecurityQuestion = false
        },
        onChangeSecurityQuestion : (state,action) => {
            state.success_msg = respMsg.change_security_question_s
            state.setSecurityQuestion = false
        },
        onUpdateEmail : (state,action) => {
            state.success_msg = respMsg.update_email_s
        },
        onSmsNotification : (state,action) => {
            state.success_msg = respMsg.sms_notification_s
        },
        onEmailNotification : (state,action) => {
            state.success_msg = respMsg.email_notification_s
        },
        setGeneralModal: (state, action) => {
            state.generalModal = action.payload
        },
        getBankSettingsForCustomer : (state,action) => {
            state.bankingSettings = action.payload
        },
        // getCurrentTier : (state,action) => {
        //     state.current_tier = action.payload
        // },
        getAccountBeneficiaries : (state,action) => {
            state.beneficiaries = action.payload.beneficiaries

        },
        onNewAccountRequest : (state,action) => {
            state.success_msg =  strText.new_account_request_s
            state.showAccountListing = false

        },
        //tokenized
        //tokenized deposit
        setTokenizedDepositForm : (state,action) => {
            customerSlice.caseReducers.createTransactionDate(state)
            state.tokenizedDepositFormData = action.payload.data
            state.formResetFunc = action?.payload?.tempFunc
            state.tokenizedDepositFormData.transaction_date = moment().format('YYYY-MM-DD hh:mm:ss')
        },
        submitTokenizedDepositData: (state, action) => {
            state.success_msg = respMsg.tokenized_deposit_s
            customerSlice.caseReducers.clearMsg(state)
            //
            state.generalModal.show = false
            state.generalModal.msg =  ''
            state.generalModal.backdrop = false
            // state.
            // customerSlice.caseReducers.openSuccessModal(state)
        },
        getTokenizedDepositTransaction : (state,action) => {
            state.tokenized_histories = action?.payload?.all_tokenized
            state.newTransactionHistBot = true
            state.tokenized_total_expected = `Total Expected: ${NumberFormatter(action?.payload?.total_expected)}`
            state.tokenized_total_actual = `Total Actual: ${NumberFormatter(action?.payload?.total_actual)}`
            state.tokenized_difference =  `Difference: ${NumberFormatter(action?.payload?.difference)}`

            // (pin):5000
            // (pin):3000
        },
        setSingleTokenizedDetails : (state,action)=> {
            state.tokenized_details = action.payload
        },
        updateTokenizeDeposit : (state,action) => {
            state.success_msg = respMsg.update_tokenized_deposit_s
            state.generalModal.show = false
            state.generalModal.backdrop = false
            state.generalModal.msg = ''
        },


        //transfer
        setTransferForm: (state, action) => {
            customerSlice.caseReducers.createTransactionDate(state)
            state.transferFormData = action.payload.data
            state.formResetFunc = action?.payload?.tempFunc
            state.transferFormData.transaction_date = moment().format('YYYY-MM-DD hh:mm:ss')
        },
        //! transfer request successful
        submitTransferData: (state, action) => {
            state.transferFormData.transaction_ref = action?.payload?.transaction_ref
            state.transferFormData.charge = action?.payload?.charge
            state.success_msg = respMsg.customer_transfer_successful
            customerSlice.caseReducers.clearMsg(state)
            customerSlice.caseReducers.openSuccessModal(state)
        },
        //Transaction History
        setAccountStatementModal : (state,action)=> {
            state.showAccountStatementModal = action.payload.modal
            if(!action?.payload?.modal){
                state.account_statement_data = []
            }
        },
        setSingleTransactionHistoryDetails : (state,action)=> {
            state.transactionHistoryDetail = action.payload
        },
        clearTransactionHistoryDetailsData : (state,action) => {
            state.transactionHistoryDetail = null
            state.showAccountStatementModal = false
            // state.transactionHistoryDetail = action.payload
            customerSlice?.caseReducers?.closeGeneralForm(state)
        },
        onGetTransactionData : (state,action) => {
            state.transaction_histories  = action.payload
            state.newTransactionHistBot = true
        },
        getAvailableAccounts : (state,action) => {
            state.available_accounts = action.payload
        },
        onGetAccountStatementTransactionData : (state,action) => {
            // state.account_statement_data  = action.payload.reverse()
            state.account_statement_data  = action.payload
            state.newAccountStatementHistBot = true
        },
        resetCustomerTransactionHistory : (state) => {
            state.transaction_histories  = []
            state.newTransactionHistBot = true
        },
        resetTransferFormData: (state) => {
            state.transferFormData = {
                account_id: '',
                type_of_transfer_id: '',
                bank_id: '',
                account_number: '',
                beneficiary: '',
                amount: '',
                description: '',
                transaction_date: ''
            }
            customerSlice.caseReducers.clearMsg(state)
            if(state?.formResetFunc){
                state?.formResetFunc()
            }
        },
        saveBeneficiary : (state,action)=> {
            state.success_msg = respMsg.save_beneficiary
            customerSlice.caseReducers.resetTransferFormData(state)
            customerSlice?.caseReducers?.closeGeneralForm(state)
        },
        //:::: ::::::::::::::::::::::::::::::::::::::::::::
        //:::::::::::::tested and used function

        createTransactionDate: (state) => {
            let date = moment(); //24 July 2022, 13:05
            state.transaction_date = date.format('D MMMM YYYY, H:M')

        },
        closeSuccessModal: (state) => {
            state.successModal = {
                show: false,
                backdrop: false,
                msg: ''
            }
        },
        openSuccessModal: (state) => {
            state.successModal = {
                show: true,
                backdrop: true,
                msg: state.success_msg
            }
        },
        closeErrorModal: (state) => {
            state.errorModal = {
                show: false,
                backdrop: false,
                msg: ''
            }
        },
        closeGeneralForm: (state) => {
            state.generalModal = {
                show: false,
                backdrop: false,
                msg: ''
            }
        },
        //


        //loan
        resetLoanAppFormData: (state) => {
            state.loanAppFormData = {
                amount: '',
                interest_rate: '',
                loan_type: '',
                maximum_tenor: '',
                monthly_repayment: '',
                total_repayment: '',
                terms_and_condition: false
            }
        },
        //top up
        setTopUpForm: (state, action) => {
            let date = moment(); //24 July 2022, 13:05
            state.topUpFormData = action.payload.data
            state.topUpFormData.transaction_date = date.format('D MMMM YYYY, H:M')
            state.formResetFunc = action?.payload?.tempFunc
        },
        submitTopUpData: (state, action) => {
            state.success_msg = `The Top-up of ${strText.naira}${Number(state.topUpFormData.amount).toLocaleString()} was successfully transferred to 
            ${state.topUpFormData.phone_number}`
            customerSlice.caseReducers.clearMsg(state)
            customerSlice.caseReducers.openSuccessModal(state)
        },
        resetTopUpFormData: (state) => {
            state.topUpFormData = {
                account_id: '',
                amount: '',
                network_id: '',
                phone_number: '',
                transaction_date: ''
            }
        },

        //utilities
        setUtilitiesForm : (state, action) => {
            state.utilityFormData = action.payload.data
            customerSlice.caseReducers.createTransactionDate(state)
            state.formResetFunc = action?.payload?.tempFunc
            state.utilityFormData.transaction_date = state.transaction_date
            //
            state.utilityFormData.package_name =  action.payload.packages.filter((item) => Number(item.id) === Number(action.payload.data.package_id))[0].name
            state.utilityFormData.provider_name = action.payload.providers.filter((item) => Number(item.id) === Number(action.payload.data.provider_id))[0].name
        },
        submitUtilityData : (state,action) => {
            customerSlice.caseReducers.clearMsg(state)
            state.success_msg = `You have subscribed for the ${state.utilityFormData.provider_name}  ${state.utilityFormData.package_name} plan`
            customerSlice.caseReducers.openSuccessModal(state)
        },

    },
})

// Action creators are generated for each case reducer function
export const {
    resetDataTableBot,
    setTransactionDate,
    clearMessages,
    setLoading,
    setLoadingTwo,
    setCustomerErrorMsg,
    getAllTiers,
    onGetCustomerDashboard,
    onSecurityQuestion,
    onCreatePin,
    onCompleteTier,
     onCreateEmail,
    customerReLogin,
    onGetTransactionData,
    resetCustomerTransactionHistory,
    onChangePin,
    onChangePasscode,
    onChangeSecurityQuestion,
    onUpdateEmail,
    onSmsNotification,
    onEmailNotification,
    logoutCustomer,
    getBankSettingsForCustomer,
    getAccountBeneficiaries,
    saveBeneficiary,
    onToggleSmsNotification,
    onToggleEmailNotification,
    setAccountDetailsModal,
    setSetupModal,
    setAccountStatementModal,
    onGetAccountStatementTransactionData,
    getAvailableAccounts,
    submitTokenizedDepositData,
    getTokenizedDepositTransaction,
    setSingleTokenizedDetails,
    updateTokenizeDeposit,
    onCustomerLoginMe,
    onNewAccountRequest,
    //



    clearMsg,

    closeSuccessModal,
    closeErrorModal,
    closeGeneralForm,
    setGeneralModal,
    resetLoanAppFormData,
    //top up
    setTopUpForm,
    submitTopUpData,
    resetTopUpFormData,
    //transfer
    setTransferForm,
    submitTransferData,
    resetTransferFormData,
    //utility
    setUtilitiesForm,
    submitUtilityData,
    //
    setTokenizedDepositForm,
    //transaction history
    setSingleTransactionHistoryDetails,
    clearTransactionHistoryDetailsData,
    //Tier
    onUpgradeToTier2,
    onUpgradeToTier3


} = customerSlice.actions

export default customerSlice.reducer