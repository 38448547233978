import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { Loader } from "../../../components";
import { ButtonField, TextInputField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, remoteUrl } from "../../../config/config";
import { postRequest, postRequest2 } from "../../../config/redux/request";
import {
    onCustomerEmailVerificationRequest,
    onEmailVerification,
    setErrorMessage,
    setLoading
} from "../../../config/redux/slice/authData";
import { urls } from "../../../config/urls/urls";
import AlertWrapper from "../../_shared_component/AlertWrapper";
import { AuthPageWrapperTwo } from "./pages";

const AdminVerifyEmail = () => {
    const {colors} = useSelector((state) => state.app)
    const {loading, isEmailVerificationModal, errorMsg, success_msg,tempEmail} = useSelector((state) => state.authData)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const dispatch = useDispatch()

    const  verifyEmail = async (data) => {
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.verifyEmail}`,
            {
                email: tempEmail,
                resettoken : data.reset_token
            },
            dispatch,
            setLoading,
            onEmailVerification,
            setErrorMessage
        )
    }

    const sendVerificationEmail = async () => {
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }

    if(success_msg==='email verification successful'){
        return <Navigate to={urls.adminLogin}/>
    }

    return (
        <AuthPageWrapperTwo  classTitle={'admin_login'}className={'login_banking'}>
            <div className="col-sm-12 col-md-4 authLoginWrapperThree">
                <div className="card shadow-lg bg-white pb-3 py-4 px-3" style={{border:`0.05rem solid ${colors.primary_color}`}}>
                    <h3 className={' mt-2'} style={{color:colors.primary_color}}>
                        <b>Online Banking Verification</b>
                    </h3>
                {/*    */}
                    {errorMsg && <AlertWrapper type={'error'} className={'mx-5 mb-1'}>{errorMsg}</AlertWrapper>}
                    {success_msg ==='verification email sent' ?
                        <AlertWrapper type={'success'} className={' mx-3'}>
                            Verification email sent to {tempEmail}
                        </AlertWrapper> :
                        success_msg &&
                        <AlertWrapper type={'success'} className={' mx-3'}>{success_msg}</AlertWrapper>
                    }
                    <div className={'px-3 py-1'}>
                        <h6 className="">  </h6>
                        <form onSubmit={handleSubmit(verifyEmail)}>
                            <div className={'mb-2'}>
                                <TextInputField
                                    fieldName={'reset_token'}
                                    error_msg={errors.reset_token?.message}
                                    col={'col-xl-12'}
                                    label={'OTP token'}
                                    placeholder={''}
                                    isReq={true}
                                    register={register}
                                    defaultVal={''}
                                />
                            </div>
                            {/* :: ::  :: */}
                            <div className={'mt-2'}>
                                {loading ? <Loader/> :
                                    <ButtonField
                                        txtColor={colors.white}
                                        bgColor={colors.primary_color}
                                        btnTxt={'Verify'}
                                        btnWidth={'100'}
                                        btnStyle={'mx-auto col-xl-12 mt-1 pt-2 pb-2'}/>}
                            </div>
                        </form>
                        <p className={'text-center pt-3'}> Didnt receive Email ?
                            &nbsp;
                            <Link
                                to={'#'}
                                style={{color: colors.primary_color}}
                                onClick = {() => sendVerificationEmail()}
                            >
                                Resend Email
                            </Link>
                            &nbsp;  or   &nbsp;
                            <Link
                                to={urls.adminLogin}
                                style={{color: colors.primary_color}}
                            >
                              Login
                            </Link>
                        </p>
                    </div>

                </div>
            </div>
        </AuthPageWrapperTwo>
    )
}

export default  AdminVerifyEmail