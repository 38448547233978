import { useForm } from 'react-hook-form';
// import { colors } from "../../../../config/color";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import formStyles from "../../../../assets/css/formStyle.module.css";
import { Loader } from "../../../../components";
import { ButtonField, TextInputField } from "../../../../components/form/components";
import { appConfig, endpointUrl } from "../../../../config/config";
import { postRequest2 } from "../../../../config/redux/request";
import {
    clearMessages,
    onChangePin,
    setCustomerErrorMsg,
    setLoading
} from "../../../../config/redux/slice/customerSlice";
import { strText } from "../../../../config/string";


const ChangeTransactionPin = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const {colors} = useSelector((state) => state.app)
    const {register, handleSubmit, setValue, getValues, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {userData, token} = useSelector((state) => state.authData)
    const {success_msg,loading} = useSelector((state) => state.customer)
    const dispatch = useDispatch()

    const submitData = async (data) => {
        dispatch(clearMessages())
        const postData = {
            "new_pin": data.new_pin,
            "security_answer": data.security_answer
        }
        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.changePin}`,
            postData,
            dispatch,
            setLoading,
            onChangePin,
            setCustomerErrorMsg
        )
        if(res !== undefined && res.status < 400){
            reset()
        }
    }

    useEffect(()=>{
        if (success_msg === strText.change_pin_success_message) {
            setValue('new_pin', '')
            setValue('confirm_new_pin', '')
            setValue('security_answer', '')
        }        
    }, [success_msg])

    return (

        <div className={'mx-auto col-sm-10 col-md-10 col-lg-8 col-xl-10 mt-2 py-1'}>
            <form onSubmit={handleSubmit(submitData)}>
                <div className={'mx-auto w-100'}>
                    <div className={'card  px-sm-0 px-md-5 py-4 '}>
                        <div className={'card-header mb-2'}>
                            <div className={'d-flex justify-content-between'}>
                                <h3 style={{color: colors.secondary_color}}><strong>Change Transaction Pin</strong></h3>
                            </div>
                        </div>
                        <div className={'card-body'}>
                    <div className={'row mb-3 px-sm-0 px-md-5'}>
                        <div className={'col-sm-12 col-xl-12'}>
                            <TextInputField
                                fieldName={'security_answer'}
                                register={register}
                                label={userData?.security_question ?? 'Enter your Security Question Answer'}
                                isReq={true}
                                error_msg={errors.security_answer?.message}
                                defaultVal={''}
                                patternRules={'letters_numbers'}
                                placeholder={''}
                                // onClickFunc={}
                                // inputStyles={}
                            />
                        </div>
                    </div>

                    <div className={'row mb-3 px-sm-0 px-md-5'}>
                        <div className={'col-sm-12 col-xl-12'}>
                            <div className="form-group">
                                <label className="fw-bold">{strText.new_pin_label}</label>
                                <div className="input-group pr-3" style={{border: `0.05rem solid #ced4da`,
                                backgroundColor: '#f8f8f8',
                                borderRadius:'50px'}}>
                                    <input
                                        type={showPassword ? 'text' : 'password'}
                                        className={`form-control ${formStyles.textField} ${errors.new_pin?.message && 'is-invalid'}`}
                                        aria-label=""
                                        aria-describedby="basic-addon2"
                                        placeholder={''}
                                        defaultValue={''}
                                        autoComplete={'off'}
                                        maxLength={4}
                                        minLength={4}
                                        autoCorrect={'off'}
                                        style={{border: 0}}
                                        {...register(`${'new_pin'}`, {
                                            required: {
                                                value: true,
                                                message: '*this field is required'
                                            },
                                            maxLength: {value: 4, message: 'Transaction PIN should be 4 digit'},
                                            minLength: {value: 4, message: 'Transaction PIN should be 4 digit'
                                            }
                                        })
                                        }
                                    />
                                    <div className="input-group-append">
                                        <span
                                            id="basic-addon2"
                                            onClick={() => setShowPassword(!showPassword)}
                                            className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                            style={{border: 0}}
                                        ><img src={!showPassword ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`} alt={''}/></span>
                                    </div>

                                </div>
                                {errors.new_pin?.message && <div className="text-danger">{errors.new_pin?.message}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={'row mb-3 px-sm-0 px-md-5'}>
                        <div className={'col-sm-12 col-xl-12'}>
                            <div className="form-group">
                                <label className="fw-bold">{strText.confirm_new_pin_label}</label>
                                <div className="input-group pr-3" style={{border: `0.05rem solid #ced4da`,
                                backgroundColor: '#f8f8f8',
                                 borderRadius:'50px'}}>
                                    <input
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        className={`form-control ${formStyles.textField} ${errors.confirm_new_pin?.message && 'is-invalid'}`}
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        placeholder={''}
                                        defaultValue={''}
                                        maxLength={4}
                                        minLength={4}
                                        autoComplete={'off'}
                                        autoCorrect={'off'}
                                        style={{border: 0}}
                                        {...register(`${'confirm_new_pin'}`, {
                                            required: {
                                                value: true,
                                                message: '*this field is required'
                                            },
                                            validate: value => value === getValues('new_pin') || "pin does not match",
                                            maxLength: {value: 4, message: 'transaction pin should be 4 digit'},
                                            minLength: {value: 4, message: 'transaction pin should be 4 digit'
                                            }
                                        })
                                        }
                                    />
                                    <div className="input-group-append">
                                        <span
                                            id="basic-addon2"
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                            style={{border: 0}}
                                        >
                                            <img src={!showConfirmPassword ? `${process.env.PUBLIC_URL}/assets/images/admin/auth/open_wide.svg` : `${process.env.PUBLIC_URL}/assets/images/admin/auth/eyeSlash.svg`} alt={''}/>
                                        </span>
                                    </div>
                                </div>
                                {errors.confirm_new_pin?.message && <div className="text-danger">{errors.confirm_new_pin?.message}</div>}
                            </div>
                        </div>
                    </div>

                    <div className={'text-center mt-4 px-md-5'}>
                        {loading ?
                            <Loader />
                            :
                            <ButtonField
                            btnTxt={strText.update_label}
                            txtColor={colors.white}
                            bgColor={colors.secondary_color}
                            id={'kk'}
                            // btnAdditionalStyle={}
                            btnWidth={'100'}
                            btnAdditionalStyle={{padding :`0.6rem 1rem`}}
                            // btnStyle={}
                        />}

                    </div>
                        </div>
                        {/**/}
                    </div>
                </div>
            </form>
        </div>           
    )
}

export default ChangeTransactionPin