//normal request
import axios from "axios";
import {formatErrorMessage} from "./actions";
import { appConfig } from "../config";


//token, url, dispatch, loadingFunc, successFunc, errorFunc
export const getRequest = async  (token, url,dispatch, loadingFunc, successFunc, errorFunc,clearAllFunc,data)  => {
    dispatch(clearAllFunc())
    const options = {
        headers: {
            'Content-type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }
    //
    dispatch(loadingFunc({loading: true}))
    try {
        const res = await axios.get(`${url}`,
            {
                params: data,
                headers:{
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
            }})
        if (res.status >= 200 && res.status <= 299) {
            if(successFunc !== false){
                dispatch(successFunc(res.data))
            }else{
                dispatch(loadingFunc({loading: false}))
                return res.data
            }
        }
    }
    catch (error) {
        let msg = formatErrorMessage(error)
        dispatch(errorFunc({message: msg }))
    }

    dispatch(loadingFunc({loading: false}))
}



/**
 *
 * @param token
 * @param url
 * @param postData
 * @param dispatch
 * @param loadingFunc
 * @param successFunc
 * @param errorFunc
 * @returns {Promise<void>}
 */

function addJwtAndBankToken(formdata, fileInc){
       let jwt;
        let bank_token;
        let tenant_id;
        const serializedState = sessionStorage.getItem('persist:authData'); // Replace 'root' with your persist key
            if (serializedState) {
            // Deserialize the state
            const persistedState = JSON.parse(serializedState);
            jwt = JSON.parse(persistedState.token)
            bank_token = JSON.parse(persistedState.bank_token)
            tenant_id = JSON.parse(persistedState.tenant_id)

            }

            if(fileInc){
                formdata.append("jwt", jwt)
                formdata.append("bank_token", bank_token)
                return formdata
            } else{
                return {jwt, bank_token, ...formdata}
            }
}
export const postRequest = async (token, url, postData, dispatch, loadingFunc, successFunc, errorFunc) => {
    let newData = addJwtAndBankToken(postData);
    const options = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    //
    dispatch(loadingFunc({loading: true}))
    try {
        const res = await axios.post(url, newData, options)
        if (res.status >= 200 && res.status <= 299) {
            dispatch(loadingFunc({loading: false}))
            if(successFunc!==""){
                dispatch(successFunc(res.data))
            }else{
                return res.data
            }

        } else{
            return 
        }
    }
    catch (error) {
        let msg = formatErrorMessage(error)
        dispatch(errorFunc({message: msg }))
    }
    dispatch(loadingFunc({loading: false}))
}

export const postRequest2 = async (token, url, postData, dispatch, loadingFunc, successFunc, errorFunc, includeFile=false, appKey='') => {
    const serializedState = sessionStorage.getItem('persist:authData'); // Replace 'root' with your persist key
    let tenant_id;
    let auth_token; 
    if (serializedState) {
        // Deserialize the state
        const persistedState = JSON.parse(serializedState);
        tenant_id = persistedState.tenant_id ? JSON.parse(persistedState.tenant_id) : sessionStorage.getItem('qapp_tenant_id')
        auth_token = persistedState.ak ? JSON.parse(persistedState.ak) : sessionStorage.getItem('qapp_ak')

    }else{
        tenant_id = sessionStorage.getItem('qapp_tenant_id')
        auth_token = sessionStorage.getItem('qapp_ak')
    }
    // let tenant_id = sessionStorage.getItem('qapp_tenant_id')
    let newData = addJwtAndBankToken(postData, includeFile);
  
    let headers= includeFile ? {
        Authorization: `Bearer ${newData.get('jwt')}`,
        AppId: `${auth_token == '' ? appKey : auth_token}`,
        // AppId: 'QAPPJgJ293NajaJAjSdYkaPOiuw997jekASIKUli2290172AHJHSKHNaasjkUWSXCBVCWK',
        TenantId: `${tenant_id}`,
        'Content-Type': 'multipart/form-data',
    } : {
        Authorization: `Bearer ${newData.jwt}`,
        AppId: `${auth_token == '' ? appKey : auth_token}`,
        // AppId: 'QAPPJgJ293NajaJAjSdYkaPOiuw997jekASIKUli2290172AHJHSKHNaasjkUWSXCBVCWK',
        TenantId: `${tenant_id}`,
    }
    const options = {
        headers
    }
    //
    if(loadingFunc!==""){
        dispatch(loadingFunc({loading: true}))
    }
    try {
        const res = await axios.post(url, newData, options)
        if (res.status >= 200 && res.status <= 299) {
                if(loadingFunc!==""){
                    dispatch(loadingFunc({loading: false}))
                }
            if(successFunc!==""){
                dispatch(successFunc(res.data.data))
            }
            return {status: res.status, data: res.data}

        }
    }
    catch (error) {
        let msg = formatErrorMessage(error)
            if(errorFunc!==""){
                dispatch(errorFunc({message: msg }))
            }
        if(error?.response?.status == 400){
            if(loadingFunc!==""){
                dispatch(loadingFunc({loading: false}))
            }
            return {status: error.response.status, data: error.response.data}
        }
    }
    if(loadingFunc!==""){
        dispatch(loadingFunc({loading: false}))
     }
}
