
const ReadonlyFileInput = ({placeholder, label,defaultVal,inputStyles}) => {
    return (
        <>
            {/*{label && <label className={`form-label ${formStyles.label}`}>{label}</label>}*/}
            {/*<input*/}
            {/*    type="text"*/}
            {/*    readOnly={true}*/}
            {/*    autoComplete={'off'}*/}
            {/*    style={inputStyles}*/}
            {/*    className={`form-control ${formStyles.textField} ${formStyles.readonly}`}*/}

            {/*    placeholder={placeholder}*/}
            {/*    defaultValue={defaultVal}*/}
            {/*/>*/}

            <div className="form-group">
                {/*<label htmlFor="basic-url">Your vanity URL</label>*/}
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon3">
                            {label}
                        </span>
                    </div>
                    <img style={{maxHeight: '70px', minHeight: '55px'}} src={defaultVal} alt="app logo"/>
                </div>
            </div>
        </>
    )
}
export default  ReadonlyFileInput