import moment from "moment/moment";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Loader, PinModule } from "../../components";
import { AmountInputField, ButtonField, SelectField } from "../../components/form/components";
// import { colors } from "../../config/color";
import { appConfig, remoteUrl } from "../../config/config";
import { NumberFormatter } from "../../config/redux/actions";
import { postRequest, postRequest2 } from "../../config/redux/request";
import { setCustomerErrorMsg, setLoading, updateTokenizeDeposit } from "../../config/redux/slice/customerSlice";
import { strText } from "../../config/string";

const TokenizedDepositDetails = ({show,transactionDetails,onClose}) => {
    const {user_role,userData,token} = useSelector((state) => state.authData)
    const {loading, success_msg} = useSelector((state) =>state.customer)
    const {colors} = useSelector((state) => state.app)
    const {register, handleSubmit, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const dispatch = useDispatch()

    const updateTokenizedDeposit = (data) => {
        //updateCustomerTokenizedTransaction
        let amount = document.getElementById('amount').value

        const postData = {
            "jwt" : token,
            "token_id":transactionDetails.id,
            "amount":amount===""? transactionDetails.expected_amount : amount,
            "instruction": data.instruction
        }

        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.updateCustomerTokenizedTransaction}`,
            postData,
            dispatch,
            setLoading,
            updateTokenizeDeposit,
            setCustomerErrorMsg
        )

    }
    return <>
        <div className="modal  fade show" id="exampleModalCenter" style={{display:"block", paddingRight:"17px"}} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable " role="document">
                <div className="modal-content px-1 py-1" style={{maxHeight: '90vh', overflowY: 'auto'}} >
                    <div className="modal-header" style={{border: "0px"}}>
                        <h3>Tokenized Deposit Token: <u>{transactionDetails?.token}</u></h3>
                        <button type="button" className="close" data-dismiss="modal"  aria-label="Close" onClick={() => onClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div>
                            <table className={'table table-striped'}>
                                <tbody>
                                {/*<tr>*/}
                                {/*    <td>Token</td>*/}
                                {/*    <td> {transactionDetails?.token}  </td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td>Sender Account</td>*/}
                                {/*    <td>{transactionDetails?.sender_account}  </td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td>Beneficiary Account </td>*/}
                                {/*    <td>{transactionDetails?.beneficiary_account}  </td>*/}
                                {/*</tr>*/}
                                {/*<tr>*/}
                                {/*    <td>Beneficiary Name </td>*/}
                                {/*    <td>{transactionDetails?.beneficiary_name}  </td>*/}
                                {/*</tr>*/}


                                <tr>
                                    <td>Deposit Account</td>
                                    <td> {transactionDetails.account} </td>
                                </tr>

                                <tr>
                                    <td>Expected Amount</td>
                                    <td> {strText.naira} {NumberFormatter(transactionDetails?.expected_amount?.toFixed(2))} </td>
                                </tr>
                                <tr>
                                    <td>Actual Amount</td>
                                    <td> {transactionDetails?.actual_amount && strText.naira} {
                                        transactionDetails?.actual_amount ?   NumberFormatter(Number(transactionDetails?.actual_amount)?.toFixed(2))
                                            :
                                            'N/A'
                                    }
                                    </td>
                                </tr>

                                <tr>
                                    <td>Narration </td>
                                    <td>{transactionDetails?.narration}  </td>
                                </tr>
                                <tr>
                                    <td>Created on</td>
                                    <td> {moment(transactionDetails?.created_on).format('YYYY-MM-DD, hh:mm:ss')} </td>
                                </tr>

                                <tr>
                                    <td>Transaction Status</td>
                                    <td> {
                                        (transactionDetails?.status ==='processed') ?
                                            <span className={'badge bg-success text-white'}>processed</span> :
                                            (transactionDetails.status ?? transactionDetails?.status ==='pending')?
                                                <span className={'badge bg-warning text-white'}>pending</span> :
                                                <span className={'badge bg-danger text-white'}>failed</span>
                                    }
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        {
                            transactionDetails?.status==='processed' ? '' :
                        <form onSubmit={handleSubmit(updateTokenizedDeposit)}>


                            <div className={''}>

                                <div className={' mx-auto w-100 mt-1'}>
                                    <div className={'row mb-2'}>
                                        <div className={'col-6'}>
                                            <AmountInputField
                                                register={register}
                                                error_msg={errors.temp_amount?.message}
                                                defaultVal={NumberFormatter(Number(transactionDetails.expected_amount).toFixed(2))}
                                                isReq={true} label={'Amount'} defaultVal2={transactionDetails.expected_amount}/>
                                        </div>

                                        <div className={'col-6'}>
                                            <SelectField
                                                fieldName={'instruction'}
                                                register={register}
                                                error_msg={errors.instruction?.message}
                                                defaultVal={''}
                                                isReq={true}
                                                label={'Transaction Instruction'}
                                            >
                                                <option value={'process'}>Process</option>
                                                <option value={'do not process'}>Don't Process</option>
                                            </SelectField>
                                        </div>
                                    </div>
                                    <div className={'w-75 mx-auto text-center'}>
                                        <label>Enter your transaction PIN</label>
                                        <PinModule
                                            btnBgColor={colors.secondary_color}
                                            bgColor={colors.secondary_color}
                                            btnTxt={'Update'}
                                            btnTxtColor={colors.white}
                                            btnWidth={'100'}
                                            leadTxt={''}
                                            loading={loading}
                                            processFunc={''}
                                            hasBtn={true}
                                        />
                                        {loading ? <Loader/> :
                                            <ButtonField id={'er'} bgColor={colors.secondary_color} btnWidth={'100'} btnTxt={'Update'} btnStyle={'py-2'}
                                                         txtColor={colors.white}/>
                                        }
                                    </div>

                                </div>
                            </div>

                        </form>
                        }

                    </div>
                </div>
            </div>
        </div>

        {show &&  <div className="modal-backdrop fade show"></div>}

    </>
}

export default  TokenizedDepositDetails