import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { colors } from "../../../config/color";

const AdminConfirmation = ({onClose,backdrop,onSubmit,type,title,dataArray,}) => {
    const dispatch = useDispatch()
    const {colors} = useSelector((state) => state.app)

    const onCloseModal = () => {
        dispatch(onClose())
    }

    const handleSubmit =  () => {
        dispatch(onSubmit({type:type}))
    }

    return (
        <>
            <div className="modal fade show" id="basicModal" tabIndex="-1"
                 style={{
                     display: "block",
                     paddingRight: "17px"
                 }} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header" style={{border: "0px"}}>
                            <h5 className="modal-title text-center mx-auto w-100"> {title} </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"  onClick={() => onCloseModal()}></button>
                        </div>
                        <div className="modal-body">
                            <div className={'text-center mx-auto'}>
                                <div>
                                    {
                                        dataArray.map((item,i=0) => (
                                            <div className={''} key={i}>
                                                <small>{item.text}</small>
                                                <h6>{item.value}</h6>
                                            </div>)
                                        )}
                                </div>
                                {/*    */}
                                <div className={'mx-auto w-75 mb-3 mt-3'}>
                                    <div
                                        className={' justify-content-center align-content-center d-flex flex-column gap-3'}>
                                        <div className={'w-100'}>
                                            <Link className={'btn px-5 w-100 py-2'}
                                                  onClick={() => handleSubmit()}
                                                  to={'#'} style={{
                                                border: `1px solid ${colors.secondary_color}`,
                                                color: colors.white,
                                                background: colors.secondary_color
                                            }}>Confirm</Link>
                                        </div>
                                        <div className={'w-100'}>
                                            <Link className={'btn px-5 w-100 py-2'}
                                                  onClick={() => onCloseModal()}
                                                  to={'#'} style={{
                                                border: `1px solid ${colors.secondary_color}`,
                                                color: colors.secondary_color
                                            }}>Cancel</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {backdrop && <div className={`modal-backdrop fade show  `}></div>}
        </>

    )
}

export default AdminConfirmation