import AdminWrapper from "../AdminWrapper";
import {useDispatch, useSelector} from "react-redux";
import {FilterTwo, LinkWrapper, TabWrapper} from "../_component";
import {urls} from "../../../config/urls/urls";
import {
    clearResetBot, getAllTiers,
    handleTierManagement,
    setActiveTabInfo, setErrorMsg, setLoading,
    setTierUpdateModal,
    showTierFormFunc,
    type
} from "../../../config/redux/slice/adminSlice";
// import {colors} from "../../../config/color";
import {DetailsItem} from "../customers/components";
import {Link, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {tiersDatatable} from "../../../config/datatableInit";
// import AdminUserDetailsModal from "./AdminUserDetailsModal";
// import {AddNewUser} from "../index";
// import {AddAdmin} from "./form";
import {postRequest, postRequest2} from "../../../config/redux/request";
import {appConfig, endpointUrl, remoteUrl} from "../../../config/config";
import {strText} from "../../../config/string";
// import ActivateDeactivateAdminUserModal from "./ActivateDeactivateAdminUserModal";
import AdminSuccessAlert from "../_component/AdminSuccessAlert";
import {pagesText} from "../../../config/string/english";
import { AddNewTier, UpdateTier } from "./form";


const AdminTierManagement = () => {
    const {adminTierManagement: {tiersData, refreshTiers, newData, showTierForm, showTierUpdateModal},success_msg} = useSelector((state) => state.administrator)
    const {userData:{modules, permissions}, token,user_role,permissionRoles,moduleRoles} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    

    useEffect(() => {
        //
        tiersDatatable(tiersData,setTierDetails)
        getTiers()

    },[])

    useEffect(() => {
        //
        if(refreshTiers){
            tiersDatatable(tiersData,setTierDetails)
            getTiers()
            dispatch(clearResetBot())
        }

    },[refreshTiers])        



    const getTiers = async () => {

        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getTiersAdmin}`,
            {"jwt" : token},
            dispatch,
            setLoading,
            getAllTiers,
            setErrorMsg,
        )
    }

        const setTierDetails = (data) => {
        //:: :: :: :: ::  :: ::
        dispatch(setTierUpdateModal({details: data, modal: true}))
    }
        

    useEffect(()=>{
        if(newData){
            tiersDatatable(tiersData,setTierDetails)
            dispatch(clearResetBot())
        }

        if(success_msg===strText.tier_added_successfully || success_msg===strText.tier_updated_successfully){
            getTiers()
        }        
    }, [newData, success_msg])

    return (
        <AdminWrapper title={''}>

            {showTierForm && <AddNewTier backdrop={showTierForm}/> }
            {showTierUpdateModal && <UpdateTier backdrop={showTierUpdateModal}/> }
            <div>
                {/*<FilterTwo selectArray={[]} />*/}
                <div className={'card'}>
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <h2 className={'tableHeader'}>{pagesText.tier_management}</h2>
                            {
                               permissions?.includes("CanAddTier")
                                    ?
                                    <button
                                        style={{color:colors.white,background:colors.secondary_color}}
                                        className="btn  ml-auto"
                                        onClick={()=> dispatch(showTierFormFunc({modal:true}))}
                                    >
                                        <i className="fa fa-plus mr-1" />
                                        {pagesText.admin_tier_add}
                                    </button>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    <div className={'card-body'}>
                        <table className={strText.datatable_class} id={'table_id_tiers'}>
                            <thead>
                            <tr>
                                <th > Name</th>
                                <th width={'18%'}>Daily Limit</th>
                                <th width={'18%'}> Maximum Balance </th>

                                <th width={'12%'}> Approved By </th>
                                <th width={'17%'}> Created By </th>
                                <th width={'17%'}> Date Created </th>
                                <th width={'9%'}  data-orderable="false" style={{color:colors.secondary_color}}> Action</th>
                            </tr>
                            </thead>



                        </table>
                    </div>

                </div>

            </div>

            {/* :::: ::: */}

        </AdminWrapper>
    )
}
export default AdminTierManagement