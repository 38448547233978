import {ButtonField, SelectField, TextInputField} from "../../../../components/form/components";
import {useForm} from "react-hook-form";
import {useState} from "react";
import {handleLoanSubmission, setActiveTabInfo, tenureDataArray, type} from "../../../../config/redux/slice/adminSlice";
// import {colors} from "../../../../config/color";
import {LinkWrapper} from "../../_component";
import {useDispatch, useSelector} from "react-redux";

const AddNewLoanType = ({backdrop}) => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {colors} = useSelector((state) => state.app)
    const dispatch  = useDispatch()

    const addLoan = () => {
        dispatch(handleLoanSubmission({type:type.loan_type_form}))

    }
    return <>
        <div className="modal fade show" id="basicModal" tabIndex={-1} aria-modal="true" role="dialog" style={{display: 'block'}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header" style={{border:0}}>
                        {/*<h5 className="modal-title"></h5>*/}
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                onClick={()=>dispatch(setActiveTabInfo({modal:false,type:type.loan_type_form}))} />
                    </div>
                    {/**/}
                    <div className="modal-body">
                        <div className={'mx-auto w-75 px-2 py-5'}>
                            <form onSubmit={handleSubmit(addLoan)}>
                                {/**/}
                                <div className={'mb-3'}>
                                    <TextInputField
                                        // inputStyles={}
                                        // onClickFunc={}
                                        patternRules={'letters'}
                                        placeholder={''}
                                        register={register}
                                        isReq={true}
                                        error_msg={errors.loan_title?.message}
                                        label={'Loan Title'}
                                        fieldName={'loan_title'}
                                        defaultVal={''}
                                    />
                                </div>
                                {/**/}
                                <div className={'mb-3'}>
                                    <TextInputField
                                        // inputStyles={}
                                        // onClickFunc={}
                                        patternRules={'numbers'}
                                        placeholder={''}
                                        register={register}
                                        isReq={true}
                                        error_msg={errors.minimum_amount?.message}
                                        label={'Minimum Amount(NGN)'}
                                        fieldName={'minimum_amount'}
                                        defaultVal={''}
                                    />
                                </div>
                                {/**/}
                                <div className={'mb-3'}>
                                    <TextInputField
                                        // inputStyles={}
                                        // onClickFunc={}
                                        patternRules={'numbers'}
                                        placeholder={''}
                                        register={register}
                                        isReq={true}
                                        error_msg={errors.maximum_amount?.message}
                                        label={'Maximum Amount(NGN)'}
                                        fieldName={'maximum_amount'}
                                        defaultVal={''}
                                    />
                                </div>
                                {/*interest rate*/}
                                <div className={'mb-3'}>
                                    <TextInputField
                                        // inputStyles={}
                                        // onClickFunc={}
                                        patternRules={'numbers'}
                                        placeholder={''}
                                        register={register}
                                        isReq={true}
                                        error_msg={errors.interest_rate?.message}
                                        label={'Interest Rate/month'}
                                        fieldName={'interest_rate'}
                                        defaultVal={''}
                                    />
                                </div>
                                {/*    */}
                                <div className={'mb-3'}>
                                    <SelectField
                                        defaultVal={''}
                                        fieldName={'minimum_tenure'}
                                        label={'Minimum Tenure(Months)'}
                                        error_msg={errors.minimum_tenure?.message}
                                        isReq={true}
                                        register={register}
                                        // col={}
                                        // selectStyle={}
                                        // selectFunc={}
                                    >
                                        {tenureDataArray().map((item,i=0) =>
                                            <option key={i} value={item.value}> {item.value} </option>
                                        )
                                        }

                                    </SelectField>

                                </div>
                                {/*    */}
                                <div className={'mb-4'}>
                                    <SelectField
                                        defaultVal={''}
                                        fieldName={'maximum_tenure'}
                                        label={'Maximum Tenure(Months)'}
                                        error_msg={errors.maximum_tenure?.message}
                                        isReq={true}
                                        register={register}
                                        // col={}
                                        // selectStyle={}
                                        // selectFunc={}
                                    >
                                        {tenureDataArray().map((item,i=0) =>
                                            <option key={i} value={item.value}> {item.value} </option>
                                        )
                                        }
                                    </SelectField>

                                </div>
                                {/*    */}
                                <div>
                                    <ButtonField
                                        id={'k'}
                                        // btnStyle={}
                                        btnTxt={'Add Loan'}
                                        txtColor={colors.white}
                                        bgColor={colors.secondary_color}
                                        btnWidth={'100'}
                                        // btnAdditionalStyle={}
                                    />

                                    <LinkWrapper
                                        btnStyle={{background:colors.background,color:colors.secondary_color,border:`1px solid ${colors.secondary_color}`,width:'100%',marginTop:'5px'}}
                                        btnTxt={'Cancel'}
                                        urlPath={'#'}
                                    />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {backdrop && <div className={`modal-backdrop fade show `}></div>}
    </>
}
export default  AddNewLoanType