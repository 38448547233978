import { useDispatch, useSelector } from "react-redux";
import { handleAgent, setAgentDetails, setOpenConfirmationAgentModal, type } from "../../../config/redux/slice/adminSlice";
import { strText } from "../../../config/string";

const AgentDetailsModal = ({backdrop}) => {
    const {agentsManagement:{agentDetail}} = useSelector((state) => state.administrator)
    const dispatch   = useDispatch()
    const {colors} = useSelector((state) => state.app)
    const onClose = () => {
        dispatch(setAgentDetails({modal:false,details:''}))
    }
    return <>
        <div className="modal  fade show" id="exampleModalCenter" style={{display:"block", paddingRight:"17px"}} aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable " role="document">
                <div className="modal-content px-2 py-2">
                    <div className="modal-header" style={{border: "0px"}}>
                         <h3 className="modal-title"
                                style={{color: colors.secondary_color}}>
                                     {/* {actionText} |  */}
                                {agentDetail.firstname} {agentDetail.lastname}</h3>

                        <button type="button" className="close" data-dismiss="modal"  aria-label="Close" onClick={() => onClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div>
                            <table className={'table table-striped table-condensed'}>
                                <tbody>
                                    <tr>
                                        <td>{strText.full_name}</td>
                                        <td>{agentDetail?.firstname} {agentDetail?.lastname} </td>
                                    </tr>
                                    <tr>
                                        <td>{strText.email_label}</td>
                                        <td>{agentDetail?.email == '' ? 'N/A' : agentDetail?.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Approval Status</td>
                                        <td>{agentDetail?.is_approved == 0 ?
                                        <span className="text-warning">pending</span>
                                                :
                                                agentDetail?.is_approved == 1 ?
                                                <span className="text-success">approved</span>
                                                : <span className="text-danger">rejected</span>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{strText.phone_number_label}</td>
                                        <td>{agentDetail?.phone}</td>
                                    </tr>
                                    <tr>
                                        <td>Account Number</td>
                                        <td>{agentDetail?.account_number ?? 'N/A'}</td>
                                    </tr> 
                                    <tr>
                                        <td>Status</td>
                                        <td>{agentDetail?.status === 'active' ?
                                        <span className="text-success">active</span>
                                        :
                                        <span className="text-danger">inactive</span>}</td>
                                    </tr> 
                                    <tr>
                                        <td>{strText.address_label}</td>
                                        <td>{agentDetail?.address ?? 'N/A'}</td>
                                    </tr>
                                </tbody>
                            </table>
                                {(
                                    // customerDetails?.current_tier == 2                                    
                                    agentDetail?.is_approved == 0) && 
                                <div className="">
                                    <button
                                        style={{color:colors.white,background:colors.secondary_color}}
                                        className="btn ml-auto mr-3"
                                        onClick={()=> dispatch(setOpenConfirmationAgentModal({
                                            open:true,
                                            details: {
                                                heading: 'Approve Agent',
                                                body: 'Do you want to approve',
                                                buttonText: 'Approve',
                                                extraDetails: 'approved'
                                            }
                                        }))}
                                    >
                                        Approve
                                    </button>  
                                    <button
                                        style={{color:colors.white,background:colors.primary_color}}
                                        className="btn  ml-auto"
                                        onClick={()=> dispatch(setOpenConfirmationAgentModal({
                                            open:true,
                                            details: {
                                                heading: 'Reject Agent',
                                                body: 'Do you want to reject',
                                                buttonText: 'Reject',
                                                extraDetails: 'disapproved'
                                            }    
                                        }))}
                                    >
                                        Reject
                                    </button>                                      
                                </div>                                
                                }     
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {backdrop &&  <div className="modal-backdrop fade show"></div>}

    </>
}

export  default AgentDetailsModal