import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CardWrapper, SuccessAlertModal } from "../../../../../components";
// import { colors } from "../../../../../config/color";
import { closeSuccessModal } from "../../../../../config/redux/slice/customerSlice";
import { urls } from "../../../../../config/urls/urls";

const TokenizedSuccessModal = ({backdrop}) => {
    const dispatch = useDispatch()
    const {colors} = useSelector((state) => state.app)

    const onClose = () => {
        dispatch(closeSuccessModal())
    }
    const handleCopyYourLink = (val) => {
        navigator.clipboard.writeText(val).then(r =>  console.log(''))
    }
    return <SuccessAlertModal
        backdrop={true}
        msg={'Proceed to your bank to complete deposit'}
        onClose={onClose}
        imgUrl={'../assets/images/success.gif'}>
        <CardWrapper
            cardStyle={{padding:'2px'}}
            cardWidth={'50'}
            cardTitle={''}
            // cardTitleColor={}
            // cardWrapperStyle={}
            showShadow={false}
        >
            <h6>Token</h6>

            <small style={{fontSize:'12px'}}>Save this token and submit it at the counter in the financial bank</small>
            <div className={'d-flex gap-1 mx-auto w-100 text-center justify-content-center mt-2'} style={{color:colors.secondary_color}}>
                <span>1234455666</span>
                <span  className={'copy'} style={{cursor:"copy"}} onClick={() => handleCopyYourLink('code_val')}>
                    <img src={'../assets/images/copy-icon.svg'} alt={''} />
                </span>
            </div>
            <div className={'d-flex flex-column mt-3'}>
                <small>Transaction ID</small>
                <small><b>111111990000292929922929</b></small>
            </div>
        </CardWrapper>

       <div className={'mx-auto w-75'}>
           {/*<div className={'mt-5 mx-auto w-100   d-flex gap-2'}>*/}
           {/*    <Link className={'btn px-5'} style={{border:`1px solid ${colors.secondary_color}`, color:colors.secondary_color}} to={''} >Share</Link>*/}
           {/*    <Link className={'btn px-4'} style={{border:`1px solid ${colors.secondary_color}`, color:colors.white,background:colors.secondary_color}} to={''} >Download</Link>*/}
           {/*</div>*/}

           <div className={'mt-3 w-100'}>
               <Link to={urls.tokenizedDeposit} className={'btn  w-100'} style={{border:`1px solid ${colors.secondary_color}`,color:colors.white,background:colors.secondary_color}} >Return to Dashboard</Link>
           </div>

       </div>


    </SuccessAlertModal>
}

export default  TokenizedSuccessModal