import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../../components";
import { ButtonField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, endpointUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import {
    clearAll,
    onCustomerLogin2,
    setErrorMessage,
    setLoading
} from "../../../config/redux/slice/authData";
import { urls as onBoardUrls } from "../../../config/urls/urls";
import { PhoneNumberComponent } from "../../onboard_one/components";
import { AuthWrapperAdmin } from "../new_account/pages";

const AdminForgotPassword = () => {
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {loading,errorMsg,success_msg,user_role,token,tempEmail} = useSelector((state) => state.authData)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const dispatch = useDispatch()
    const navigate = useNavigate()
        const onSubmit = async (data) => {
        dispatch(clearAll({}))
        let phone = data.code + data.mobile;
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.forgotPasscode}`,
            {phone: phone},
            dispatch,
            setLoading,
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400){
            dispatch(onCustomerLogin2({type: '', data:{phone: phone, type: ''}}))
            navigate(`${onBoardUrls.adminToken}`)
        }
    }

    return (
    <AuthWrapperAdmin classTitle={'admin_login'} title='Forgot Passcode' >
            <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)} autoComplete="off">   
                    <div className="mt-3 login-form">
                        <PhoneNumberComponent
                            fieldName={'mobile'}
                            setValueFunc={setValue}
                            error_msg={errors.mobile?.message}
                            col={'col-xl-12'}
                            label={'Phone Number'}
                            nextCompId={'password'}
                            placeholder={''}
                            isReq={true}
                            register={register}
                            defaultVal={''}
                            patternRules={''}
                            hasHelperTip={true}
                            helperTipsMsg={'Please enter your phone number to receive an OTP to reset your passcode.'}
                        />                    
                        <div>
                        </div>

                        <div className="form-action mb-3">
                            {loading ? <div className="mt-3"><Loader/></div> :
                                <ButtonField
                                    btnStyle={'py-2'}
                                    btnTxt={'Send Reset Code'}
                                    btnWidth={'100'}
                                    bgColor={colors.primary_color}
                                    txtColor={colors.white}
                                    id={'d'}
                                    // btnAdditionalStyle={}
                                />
                            }
                        </div>

                    </div>           

            </form> 
    </AuthWrapperAdmin>        
    )
}

export default AdminForgotPassword