import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { colors } from "../../../config/color";
import { resetFormData } from "../../../config/redux/slice/authSlice";
import { OtpScreen, Password, PersonalDetails } from "./components";
import AuthWrapper from "./components/AuthWrapper";

const OpenNewAccountScreen = () => {
    const {currentStep} = useSelector((state) => state.auth)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(resetFormData({}))
    },[])

    return(
        <AuthWrapper>
            {/*style={{height:'100vh',overflowX:'hidden'}}*/}
            <div className={`internet_banking_new`} >
                <div>
                    <main className={''}>
                        <article>
                            {/*
                                position: relative;
                                padding-top: 50px;
                                min-height: 820px;
                                height: auto;
                                max-width: 820px;
                                margin: 0 auto;
                                background: #ffffff;
                                z-index: 1;


                            */}
                            <section className="multi_step_form  pb-3 py-4 px-sm-0 px-md-3"   >
                                <div
                                    id="msform"
                                     className={'card shadow-lg bg-white pb-3 py-4 px-3'}
                                     style={{minHeight:`600px`}}>
                                    <div className="tittle text-center">
                                        <h2 className={''} style={{color:colors.primary_color}}> <b>Open an Account</b></h2>
                                        <small id="emailHelp2" className="form-text text-muted">
                                            Please provide the information requested below to enable us open an account for you.
                                        </small>
                                    </div>

                                    <ul id="progressbar" className={'text-center'}>
                                        <li className={Number(currentStep)>=1 ? "active":""}>Personal Information</li>
                                        <li className={Number(currentStep)>=2 ? "active":""}>Verify Phone Number</li>
                                        <li className={Number(currentStep)>=3 ? "active":""}>Create Passcode</li>
                                    </ul>


                                    {Number(currentStep)===1 &&  <PersonalDetails/> }
                                    {Number(currentStep)=== 2 &&  <OtpScreen/>}
                                    {Number(currentStep)===3 &&  <Password/> }
                                    {/*{Number(currentStep)===4 &&   <FormStepFour/> }*/}

                                </div>
                            </section>

                        </article>
                    </main>
                </div>
            </div>
        </AuthWrapper>
    )
}

export default OpenNewAccountScreen




