const base  =  '/admin/'
const operation_base  =  'operations/'
const system_base  =  '/admin/systems/'


export const adminUrls = {
    'adminLandingPage' : base,
    'adminGetStarted' : `${base}auth/get-started`,
    'adminLogin' : `${base}auth/login`,
    'adminToken' : `${base}auth/token`,
    'adminOtp' : `${base}auth/otp`,
    'adminPasscode' : `${base}auth/passcode`,
    'adminCreatePasscode' : `${base}auth/create-passcode`,
    'adminForgotPassword' : `${base}auth/forgot-passcode`,
    'adminPasswordReset' : `${base}auth/passcode-reset`,
    'adminActivationAccount' : `${base}auth/admin-activation`,
    'adminDashboard' : `${base}dashboard`,
    'adminTransactionDashboard' : `${base}transactions`,
    'adminDepositHome' : `${base}deposit`,
    'adminWithdrawalHome' : `${base}${operation_base}withdrawal`,
    'adminTransferHome' : `${base}${operation_base}transfer`,
    'adminUtilityHome' : `${base}${operation_base}utility`,
    'adminCardsHome' : `${base}cards`,
    'adminCustomersHome' : `${base}customers`,
    'adminAddNewCustomer' : `${base}customers/add`,
    'adminAgentsHome' : `${base}agents`,
    'adminAgentAdd' : `${base}agents/add`,
    'adminAgentView' : `${base}agents/view`,
    'adminLoanHome' : `${base}loanHome`,
    'adminSystemHome' : `${base}system`,
    'adminRequestHome' : `${base}requests-management`,
    'adminAgentRequestHome' : `${base}agent-requests-management`,
    'adminAccOpeningRequestHome' : `${base}account-opening-request/main-accounts`,
    'adminUserManagement' :`${base}users`,
    'adminTierManagement' :`${base}tiers`,
    'adminRoleManagement' :`${base}roles`,
    'adminUserAdd' : `${base}systems/users/add`,
    'adminUserDetails' : `${base}systems/users/view`,
    'adminSettingsHome' : `${base}settings/general`,
    'adminSettingsPrivacy' : `${base}settings/privacy-policy`,
    'adminSettingsTermsCondition' : `${base}settings/terms-and-condition`,
    'adminSystemLogs' : `${base}system-logs`,
    'additionalAccountReq' : `${base}account-opening-request/additional-accounts`,


    'adminSupportHome' : `${base}support`,
    'adminMprReport' : `${base}reports/mpr`,
    'adminCustomReport' : `${base}reports/custom`,
}