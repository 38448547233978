import {
    TokenizedDeposit, TokenizedDepositForm,
    TopUpHome,
    TransactionHistory,
    TransferHome,
    UtilitiesHome
} from "../../app/customer/activity";
import { GeneralSettings, PrivacyPolicy, TermsAndCondition } from "../../app/customer/bank_settings";
import { CardsHome } from "../../app/customer/cards";
import { CustomerDashboard } from "../../app/customer/dashboard";
import { LoansHome } from "../../app/customer/loans";
import { CustomerChangePasscode, ProfileHome } from "../../app/customer/profile";
import { AccountStatementHome, ChequeManagement, MyAccountManagerHome } from "../../app/customer/services";
import { EmailAddressHome, SecurityQuestionHome, SettingsHome, TransactionPinHome } from "../../app/customer/settings";
import { SupportHome } from "../../app/customer/support";
import { urls } from "../urls/urls";

export const customerRoutes = [
    //dashboard
    {
        url : urls.customerDashboard,
        component: <CustomerDashboard/>
    },

    //activity
    {
        url : urls.transactionHistory,
        component: <TransactionHistory/>
    },
    {
        url : urls.topUpHome,
        component: <TopUpHome/>
    },
    {
        url : urls.transferHome,
        component: <TransferHome/>
    },
    {
        url : urls.utilityHome,
        component: <UtilitiesHome/>
    },
    {
        url : urls.tokenizedDeposit,
        component:  <TokenizedDeposit/>
    },
    {
        url : urls.tokenizedDepositNew,
        component: <TokenizedDepositForm/>
    },

    //cards
    {
        url : urls.cardsHome,
        component: <CardsHome/>
    },
    //services
    {
        url : urls.account_manager,
        component:  <MyAccountManagerHome/>
    },
    {
        url : urls.cheque_management,
        component:  <ChequeManagement/>
    },
    {
        url : urls.account_management,
        component:  <AccountStatementHome/>
    },
    //loans
    {
        url: urls.loansHome,
        component: <LoansHome/>
    },
    //profile
    {
        url: urls.profileHome,
        component: <ProfileHome/>
    },
    {
        url: urls.customerChangePasscode,
        component: <CustomerChangePasscode/>
    },
    {
        url: urls.supportHome,
        component: <SupportHome/>
    },
    {
        url: urls.settingsHome,
        component: <SettingsHome/>
    },
    //
    {
        url : urls.privacy_policy,
        component: <PrivacyPolicy/>
    },
    {
        url : urls.general_settings,
        component: <GeneralSettings/>
    },
    {
        url : urls.terms_and_condition,
        component: <TermsAndCondition/>
    },
    {
        url : urls.transactionPinHome,
        component:  <TransactionPinHome/>
    },
    {
        url : urls.securityQuestionHome,
        component:  <SecurityQuestionHome/>
    },
    {
        url : urls.updateEmailAddress,
        component:  <EmailAddressHome/>
    },
    {
        url: urls.profileHome,
        component: <ProfileHome/>
    },
]