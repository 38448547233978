import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { ButtonField, PasswordField, TextInputField } from "../../components/form/components";
import CheckboxField from "../../components/form/components/CheckboxField";
// import { colors } from "../../config/color";
import { appConfig, remoteUrl } from "../../config/config";
import { formatMessages } from "../../config/redux/actions";
import { postRequest, postRequest2 } from "../../config/redux/request";
import {
    auth_types, changeModalSize,
    clearAll, onLinkInternetBanking, onSubmitInternetBankReq,
    setAuthModalForm, setErrorMessage,
    setInternetBanking,
    setLoading,
    setTemAccountNo
} from "../../config/redux/slice/authData";
import { urls } from "../../config/urls/urls";
import AlertWrapper from "../_shared_component/AlertWrapper";
import AuthWrapper from "./AuthWrapper";

const AgencyAccountOpeningNew = () => {
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        loading,
        showAgentAccountOpeningForm,
        errorMsg,
        showPreviewAccInternetPreviewForm,
        tempInternetUserDetails,
        tempAccountNo,
        success_msg
    } = useSelector((state) => state.authData)
    const [togglePassword, setTogglePassword] = useState(false)

    const [showLinkAccountForm, setShowLinkAccountForm] = useState(false)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onModalClose = () => {
        // showLinkAccountForm(false)
        dispatch(setTemAccountNo({account_no: ''}))
        dispatch(setAuthModalForm({modal: false, type: auth_types.show_agent_acc_form}))
        return navigate(`${urls.landingPage}`)

    }
    //first level ::
    const submitData = (data) => {
        dispatch(clearAll())
        dispatch(setTemAccountNo({account_no: data.account_number}))

        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.get_agent_account_from_cbs}`,
            {"account_number": data.account_number},
            dispatch,
            setLoading,
            setInternetBanking,
            setErrorMessage)
    }
    //second level
    const submitInternetBankingReq = async (data) => {
        await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.create_agent_account}`,
            data,
            dispatch,
            setLoading,
            onSubmitInternetBankReq,
            setErrorMessage)
    }
    // 3rd level
    const onClickLInkAccountBtn = () => {
        dispatch(clearAll({}))
        setShowLinkAccountForm(true)
    }
    //
    const linkMyAccount = async (data) => {
        const postData = {
            "email": data.email,
            "password" :data.password,
            "phone": data.phone,
            "account_number": data.accountno,
            "address" : data.address
        }

        let res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.link_agent_account}`,
            postData,
            dispatch,
            setLoading,
            onLinkInternetBanking,
            setErrorMessage
        )
    }

    useEffect(()=> {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
        dispatch(setAuthModalForm({
            modal: false,
            type: auth_types.request_internet_banking
        }))
        dispatch(setTemAccountNo({account_no: ''}))
        dispatch(setAuthModalForm({modal: false, type: auth_types.show_agent_acc_form}))
    },[])

    if (success_msg === 'account linked successfully, request successfully submitted') {
        setValue('password', '')
    }
    //Agency Banking Activation

    return (
        <AuthWrapper  scrollable={'hidden'}>
        <div className={`internet_banking_new`} style={{height:'100vh',overflowX:'hidden'}} >
            <section className="mt-5 justify-content-center align-items-center">
                <div className={'card shadow-lg bg-white pb-5 pt-5 px-3 col-sm-1 col-md-5 mx-auto mt-5'} >
                    <div className=" tittle text-center">
                        <h2 className={''} style={{color:colors.primary_color}}>
                            <b>Agency Banking Activation</b>
                        </h2>
                    </div>

                    <fieldset>

                        {
                            errorMsg === 'SSO account already exists' ?
                                <AlertWrapper type={'error'}>
                                    It looks like you already have  a QApp profile. Kindly
                                    <Link onClick={() => onClickLInkAccountBtn()} to={'#0'}> <u> Click here </u> </Link> to link your QApp profile.
                                </AlertWrapper>
                                :
                                errorMsg==='not found'? <AlertWrapper type={'error'}>
                                        Account number entered does not exist, please use a valid account number.
                                        <Link to={urls.landingPage}> Click here </Link> to close this window.
                                    </AlertWrapper>
                                    : errorMsg ==='incorrect password' ?
                                        <AlertWrapper type={'error'}>{formatMessages(errorMsg)}.
                                        </AlertWrapper>
                                        :
                                        errorMsg && <AlertWrapper type={'error'}>{formatMessages(errorMsg)}.
                                            <Link to={urls.landingPage}> Click here </Link> to close this window.
                                        </AlertWrapper>

                        }

                        {success_msg && <AlertWrapper type={'success'}>
                            {formatMessages(success_msg)}
                            <Link to={urls.landingPage}> Click here </Link> to close this window.
                        </AlertWrapper>
                        }
                    </fieldset>

                    <div className={'px-2'}>
                        {showLinkAccountForm ?
                            <>
                                <form onSubmit={handleSubmit(linkMyAccount)}>
                                    {/**/}
                                    <div className={'row mb-2'}>
                                        {/*Email*/}
                                        <div className={'col-sm-12 col-md-4'}>
                                            <label>Email</label>
                                            <input className={'form-control'} type={'text'}
                                                   readOnly={true}
                                                   style={{borderRadius:'50px'}}
                                                   defaultValue={tempInternetUserDetails.email} {...register('email')} />
                                        </div>
                                        {/**/}
                                        <div className={'col-sm-12 col-md-4'}>
                                            <label>Phone Number</label>
                                            <input className={'form-control'} type={'text'}
                                                   readOnly={true}
                                                   style={{borderRadius:'50px'}}
                                                   defaultValue={tempInternetUserDetails.phone} {...register('phone')} />
                                        </div>
                                        {/**/}
                                        <div className={'col-sm-12 col-md-4'}>
                                            <label>Account Number</label>
                                            <input className={'form-control'} type={'text'}
                                                   readOnly={true}
                                                   style={{borderRadius:'50px'}}
                                                   defaultValue={tempAccountNo} {...register('accountno')} />
                                        </div>
                                    </div>
                                    {/**/}
                                    <div className={'row mb-2'}>
                                        <div className={'col-12'}>
                                            <TextInputField
                                                placeholder={''}
                                                defaultVal={''}
                                                error_msg={errors.address?.message}
                                                fieldName={'address'}
                                                isReq={true}
                                                label={'Address'}
                                                register={register}
                                                // onClickFunc={}
                                                // patternRules={'letters_numbers'}
                                                // inputStyles={}
                                            />
                                        </div>
                                    </div>
                                    {/**/}
                                    <div className={'mb-1'}>
                                        <PasswordField
                                            defaultVal={''}
                                            // inputStyles={}
                                            fieldName={'password'}
                                            defaultValue={''}
                                            error_msg={errors.password?.message}
                                            col={'col-xl-12'}
                                            label={'Enter your Qapp password'}
                                            placeholder={''}
                                            isReq={true}
                                            register={register}
                                            // patternRules={''}
                                        />
                                    </div>

                                    {/**/}
                                    <div className={'mt-3'}>
                                        {loading ? <Loader/> :
                                            <ButtonField
                                                txtColor={colors.white}
                                                bgColor={colors.primary_color}
                                                btnTxt={'Link my QApp profile'}
                                                btnWidth={'100'}
                                                btnStyle={'mx-auto col-xl-12'}/>}
                                    </div>
                                </form>
                            </>
                            :
                            <>
                                {
                                    showPreviewAccInternetPreviewForm
                                        ?
                                        <>
                                            {/*Step Two*/}
                                            <form onSubmit={handleSubmit(submitInternetBankingReq)}>
                                                <div className={'row mb-3'}>
                                                    <div className={'col-sm-12 col-md-4'}>
                                                        <label>Firstname</label>
                                                        <input className={'form-control'} type={'text'}  style={{borderRadius:'50px'}}
                                                               readOnly={true}

                                                               defaultValue={tempInternetUserDetails.firstname} {...register('firstname')} />
                                                    </div>
                                                    {/*middlename*/}
                                                    <div className={'col-sm-12 col-md-4'}>
                                                        <label>Middle Name</label>
                                                        <input className={'form-control'} type={'text'}  style={{borderRadius:'50px'}}
                                                               readOnly={true}
                                                               defaultValue={tempInternetUserDetails.middlename} {...register('middlename')} />
                                                    </div>

                                                    {/*Lastname*/}
                                                    <div className={'col-sm-12 col-md-4'}>
                                                        <label>Last Name</label>
                                                        <input className={'form-control'} type={'text'}  style={{borderRadius:'50px'}}
                                                               readOnly={true}
                                                               defaultValue={tempInternetUserDetails.lasttname} {...register('lastname')} />
                                                    </div>

                                                </div>
                                                {/**/}
                                                <div className={'row mb-2'}>
                                                    {/*Email*/}
                                                    <div className={'col-sm-12 col-md-4'}>
                                                        <label>Email</label>
                                                        <input className={'form-control'} type={'text'}
                                                               readOnly={true}  style={{borderRadius:'50px'}}
                                                               defaultValue={tempInternetUserDetails.email} {...register('email')} />
                                                    </div>
                                                    {/**/}
                                                    <div className={'col-sm-12 col-md-4'}>
                                                        <label>Phone Number</label>
                                                        <input className={'form-control'} type={'text'}
                                                               readOnly={true}  style={{borderRadius:'50px'}}
                                                               defaultValue={tempInternetUserDetails.phone} {...register('phone')} />
                                                    </div>
                                                    {/**/}
                                                    <div className={'col-sm-12 col-md-4'}>
                                                        <label>Account Number</label>
                                                        <input className={'form-control'} type={'text'}
                                                               readOnly={true}  style={{borderRadius:'50px'}}
                                                               defaultValue={tempAccountNo} {...register('accountno')} />
                                                    </div>
                                                </div>
                                                {/**/}
                                                <div className={'row mb-2'}>
                                                    <div className={'col-12'}>
                                                        <TextInputField
                                                            placeholder={''}
                                                            defaultVal={''}
                                                            error_msg={errors.address?.message}
                                                            fieldName={'address'}
                                                            isReq={true}
                                                            label={'Address'}
                                                            register={register}
                                                            // onClickFunc={}
                                                            // patternRules={'letters_numbers'}
                                                            // inputStyles={}
                                                        />
                                                    </div>
                                                </div>
                                                {/**/}
                                                <div className={'row'}>
                                                    {/*Password*/}
                                                    <div className={'col-sm-12 col-md-6'}>
                                                        <PasswordField
                                                            defaultVal={''}
                                                            // inputStyles={}
                                                            fieldName={'password_new'}
                                                            defaultValue={''}
                                                            error_msg={errors.password_new?.message}
                                                            col={'col-xl-12'}
                                                            label={'Enter your password'}
                                                            placeholder={''}
                                                            isReq={true}
                                                            register={register}
                                                            // patternRules={''}
                                                        />
                                                    </div>
                                                    {/*Confirm Password*/}
                                                    <div className={'col-sm-12 col-md-6'}>
                                                        <PasswordField
                                                            defaultVal={''}
                                                            // inputStyles={}
                                                            fieldName={'password'}
                                                            defaultValue={''}
                                                            error_msg={errors.password?.message}
                                                            col={'col-xl-12'}
                                                            label={'Confirm password'}
                                                            placeholder={''}
                                                            isReq={true}
                                                            register={register}
                                                            // patternRules={''}
                                                        />
                                                    </div>
                                                </div>
                                                {/**/}
                                                <div className={'mt-4'}>
                                                    {loading ? <Loader/> :
                                                        <ButtonField
                                                            txtColor={colors.white}
                                                            bgColor={colors.primary_color}
                                                            btnTxt={'Submit'}
                                                            btnWidth={'100'}
                                                            btnStyle={'mx-auto col-xl-12'}/>}
                                                </div>
                                            </form>
                                        </>
                                        :
                                        <>
                                            {/*first step*/}
                                            <form onSubmit={handleSubmit(submitData)}>
                                                {/*Account Number*/}
                                                <div className={'mb-1'}>
                                                    <TextInputField
                                                        fieldName={'account_number'}
                                                        defaultValue={''}
                                                        error_msg={errors.account_number?.message}
                                                        col={'col-xl-12'}
                                                        label={'Enter your account number'}
                                                        placeholder={''}
                                                        isReq={true}
                                                        register={register}
                                                        patternRules={'numbers'}
                                                    />
                                                </div>

                                                {/**/}
                                                <div className={'ml-4'}>
                                                    <CheckboxField
                                                        register={register}
                                                        label={'terms_and_condition'}
                                                        fieldName={'terms_and_condition'}
                                                        isReq={true}
                                                        error_msg={errors?.terms_and_condition?.message}
                                                        // defaultVal={}
                                                        // placeholder={}
                                                        // col={}
                                                    />
                                                </div>
                                                {/**/}
                                                <div className={'mt-2'}>
                                                    {loading ? <Loader/> :
                                                        <ButtonField
                                                            txtColor={colors.white}
                                                            bgColor={colors.primary_color}
                                                            btnTxt={'Request to be an Agent'}
                                                            btnWidth={'100'}
                                                            btnStyle={'mx-auto col-xl-12'}/>}
                                                </div>
                                            </form>
                                        </>
                                }
                            </>
                        }
                        <p className={'text-center pt-1'}>Already have an account ?
                            <Link
                                to={urls.login}
                                style={{color: colors.primary_color}}>
                                &nbsp;   Login
                            </Link>
                        </p>
                    </div>
                </div>
            </section>
        </div>

        </AuthWrapper>
    )
}

export  default AgencyAccountOpeningNew