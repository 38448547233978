import { urls } from '../urls/urls'

const userRoles = {}

export const pagesText = {
	// table data

	//pages title

	on_open_account_one: 'account opening request successfully submitted',
	agency_banking_request: 'Agency Banking Requests',
	reports: 'Reports',
	agents: 'Agents',
	additional_account_request: 'Additional Account Requests',
	internet_banking_requests: 'Online Banking Activation',
	account_opening_requests: 'Account Opening Requests',
	tokenized_transactions: 'Tokenized Deposits',
	customers: 'Online Banking Customers',
	admin_user: 'System Users Management',
	admin_roles_permission: 'User Role Management',
	tier_management: 'Tier Management',
	role_management: 'Role Management',
	admin_user_add: 'Add System User',
	admin_tier_add: 'Add New Tier',
	admin_tier_update: 'Update Tier',
	admin_role_update: 'Update Roles',
	admin_role_add: 'Add New Role',

	system_logs: 'Audit Trail',
	admin_export_text: 'PRINT',
	customer_export_text: 'EXPORT',

	admins_level_cu_only: ['customer'],
	admins_level_all: ['initiator', 'approver', 'admin', 'super admin'],
	admins_level_in_only: ['initiator'],
	admins_level_ap_only: ['approver'],
	admins_level_ad_only: ['admin'],
	admins_level_sa_only: ['super admin'],
	//
	admins_level_in_ap_only: ['initiator', 'approver'],
	admins_level_ad_sa_only: ['admin', 'super admin'],
	admins_level_ap_ad_sa_only: ['approver', 'admin', 'super admin'],
	user_roles_text: ['', '', 'Initiator', 'Approver', 'Admin', 'Super Admin'],
	user_roles_text_key: [
		'no',
		'customer',
		'initiator',
		'approver',
		'admin',
		'super admin',
	],

	admin_roles_text_key: [
		'no',
		'no',
		'initiator',
		'approver',
		'admin',
		'super admin',
	],

	accounts_type: [
		{
			val: 'savings',
			name: 'Savings',
		},
		{
			val: 'current',
			name: 'Current',
		},
		{
			val: 'credit',
			name: 'Credit',
		},
	],

	superAdmin_users: ['admin'], 

	admin_users: ['initiator', 'approver'],

	user_roles: [
		{
			val: 2,
			name: 'Initiator',
			key: 'initiator',
			roles: ['admin'],
		},
		{
			val: 3,
			name: 'Approver',
			key: 'approver',
			roles: ['admin'],
		},
		{
			val: 4,
			name: 'Admin',
			key: 'admin',
			roles: ['super admin'],
		},
		{
			val: 5,
			name: 'Super Admin',
			key: 'super_admin',
			roles: ['super admin'],
		},
	],
	short_date: 'YYYY-MM-DD',
	full_date: 'YYYY-MM-DD, HH:MM:SS',
}

export const englishString = {
	datatable_class: 'table table-striped',
	user_unique_id: 'qapp_user',
	naira: '₦',
	appName: 'National Bank',
	app_logo: '/_assets/logo_header.svg',
	app_logo_light: '/_assets/logo_header_white.svg',
	filter_btn_text: 'Apply',
	//
	first_name_label: 'First Name',
	last_name_label: 'Last Name',
	full_name: 'Full Name',
	email_label: 'Email',
	phone_number_label: 'Phone Number',
	address_label: 'Address',
	action_text_label: 'Action',
	//
	qApp_charge: 'QApp charge',
	transactions_text: 'Transactions',
	total_charges: 'Total Charges',
	value_text: 'Value',
	total_no_of_requests_text: 'Total Number of Requests',
	customer_distribution_by_tiers: 'Customer Distribution by Tier',
	daily_Position_text: 'Daily Position',
	total_transaction_value: 'Total Transaction Value (NGN)',
	total_transaction_others: 'Total Transaction Charges (Others)',
	total_number_of_transactions: 'Total Number of Transactions',
	total_qApp_charge: 'Total QApp charge (NGN)',

	//
	timeout_message: 'Your session has expired, kindly login again',
	login_success_message: 'login successful, redirect to dashboard',
	forgot_password_success_message: 'password reset email sent',
	change_security_question_success_message:
		'security question changed successfully',
	update_email_s: 'email updates successfully',	
	change_pin_success_message: 'pin change successful',
	update_admin_status: 'user status changed successfully',
	admin_added_successfully: 'admin added successfully',
	tier_added_successfully: 'Tier added successfully',
	tier_updated_successfully: 'Tier updated successfully',
	role_added_successfully: 'Role added successfully',
	new_account_request_s: 'New Account Request Submitted Successfully',
	req_additional_account_approval: 'Approval request submitted successfully',
	approve_additional_account: 'Additional account approved successfully',

	logo: '../../assets/images/logo_one.svg',
	name_label: 'Name',

	identification_label: 'Identification',
	change_label: 'Edit Profile',
	personal_info_label: 'Personal Information',
	account_settings_label: '',
	others_label: 'Others',
	change_password_label: 'Change Password',
	change_transaction_pin: 'Change Transaction pin',
	change_email_address: 'Change Email Address',
	security_label: 'Set Up / Change Security Question',
	sms_notification_label: 'Sms Notification Settings',
	email_notification_label: 'Email Notification Settings',
	privacy_policy_label: 'Privacy Policy',
	terms_and_condition_label:
		'By continuing you agree to the Terms and Conditions',
	terms_and_condition_text: 'Terms and Conditions',
	faq_label: 'FAQs',
	contact_us_label: 'Contact Us',
	current_pin_label: 'Current Pin',
	new_pin_label: 'New PIN',
	confirm_new_pin_label: 'Confirm New PIN',
	forgot_transaction_pin_label: 'Forgot transaction PIN?',
	reset_now_label: 'Reset Now',
	update_label: 'Update',
	change_security_questions: 'Change Security Question',
	loan_success_msg:
		'A loan of N1,500,000.00 has be granted to your \n' +
		'account and will be deducted automatically at the end\n' +
		'of each installment month.',
	loan_lead_text:
		'Please provide the following information to continue with the process',
	loan_monthly_repayment_label: 'Monthly Repayment',
	loan_total_repayment_label: 'Total Repayment',
	loan_max_tenor_label: 'Maximum Tenor (Months)',
	loan_amount_label: 'Amount',
	loan_select_loan_type_label: 'Select Loan',
	loan_interest_rate: 'Interest Rate',
	continue_text: 'Continue',
	loan_confirmation_lead_text: 'You are about to procure a loan',
	loan_next_repayment_date: 'Next Payment Date',
	enter_your_transfer_pin: 'Enter your transfer pin below',
	card_empty_state: 'No cards yet at this time',
	recent_transaction_label: 'Recent Transaction',
	view_all_label: 'View All',
	terms_and_condition: 'By continuing you agree to the Terms and Conditions',
	servicesArray: [
		{
			title: 'Open an Account',
			body:
				'Do you need an account? Open an account for yourself, your children or your businesses at no cost.',
			img: './assets/images/icon/card.svg',
			link: '',
			shadow: 'shadow-lg',
		},
		{
			title: 'Quick Loan',
			body:
				'Get up to N10 million in credit and pay back in 3-12 months and at 3% monthly interest rate. No hidden fees.',
			img: './assets/images/icon/card.svg',
			link: '',
			shadow: '',
		},
		{
			title: 'Utility Payment',
			body:
				'Don’t miss out on your favorite show. Pay your utility bill on the go and continue to enjoy your program.',
			img: './assets/images/icon/card.svg',
			link: '',
			shadow: '',
		},
		{
			title: 'Airtime Top-Up',
			body: 'Your go to place for instant Top-up.',
			img: './assets/images/icon/card.svg',
			link: '',
			shadow: '',
		},
	],
	footerLinks: [
		[
			{
				text: 'Home',
				url: '',
			},
			{
				text: 'Register',
				url: '',
			},
			{
				text: 'Login',
				url: '',
			},
			{
				text: 'Transfer',
				url: '',
			},
		],
		[
			{
				text: 'Home',
				url: '',
			},
			{
				text: 'Register',
				url: '',
			},
			{
				text: 'Login',
				url: '',
			},
			{
				text: 'Transfer',
				url: '',
			},
		],
	],
	customerSidebar: [
		{
			id: 1,
			has_children: false,
			name: 'Dashboard',
			moduleName: 'Dashboard',
			active: 'dashboard',
			url: urls.customerDashboard,
			icon_url: '/_assets/new/dashboard_icon.svg',
			position: 'top',
			roles: pagesText.admins_level_cu_only,
		},
		{
			id: 11,
			has_children: false,
			name: 'Transfers',
			moduleName: 'Transfers',
			active: 'transfers',
			url: urls.transferHome,
			icon_url: '/_assets/new/sendtransfer.svg',
			position: 'top',
			roles: pagesText.admins_level_cu_only,
		},
		//transaction-history
		{
			id: 100,
			has_children: false,
			name: 'Transaction History',
			moduleName: 'Transaction History',
			active: 'transaction-history',
			url: urls.transactionHistory,
			icon_url: '/_assets/new/transaction-history.svg',
			position: 'top',
			roles: pagesText.admins_level_cu_only,
		},
		//tokenized deposit
		{
			id: 500,
			has_children: false,
			name: 'Tokenized Deposit',
			moduleName: 'Tokenized Deposit',
			active: 'tokenized-deposit',
			// url: '#',
			url: urls.tokenizedDeposit,
			icon_url: '/_assets/new/tokenized_deposit.svg',
			position: 'top',
			roles: pagesText.admins_level_cu_only,
		},
		//tokenized withdrawal

		//my services
		// {
		// 	id: 3,
		// 	has_children: true,
		// 	name: 'My Services',
		// 	moduleName: 'My Services',
		// 	active: 'services',
		// 	url: 'services',
		// 	icon_url: '/_assets/new/activity_icon.svg',
		// 	position: 'top',
		// 	roles: pagesText.admins_level_cu_only,
		// 	children: [
		// 		{
		// 			id: 1,
		// 			name: 'Card Management',
		// 			moduleName: 'Card Management',
		// 			active: 'card',
		// 			url: urls.cardsHome,
		// 			roles: pagesText.admins_level_cu_only,
		// 			// url: '#',
		// 		},
		// 		{
		// 			id: 3,
		// 			name: 'Cheque Management',
		// 			moduleName: 'Cheque Management',
		// 			active: 'cheque-management',
		// 			// url: '#',
		// 			url: urls.cheque_management,
		// 			roles: pagesText.admins_level_cu_only,
		// 		},
		// 		{
		// 			id: 4,
		// 			name: 'Account Manager',
		// 			moduleName: 'Account Manager',
		// 			active: 'account-manager',
		// 			url: urls.account_manager,
		// 			roles: pagesText.admins_level_cu_only,
		// 		},
		// 		{
		// 			id: 5,
		// 			name: 'Top up',
		// 			moduleName: 'Top up',
		// 			active: 'top-up',
		// 			// url: '#',
		// 			url: urls.topUpHome,
		// 			roles: pagesText.admins_level_cu_only,
		// 		},
		// 		{
		// 			id: 6,
		// 			name: 'Pay for Utility',
		// 			moduleName: 'Pay for Utility',
		// 			active: 'utility',
		// 			// url: '#',
		// 			url: urls.utilityHome,
		// 			roles: pagesText.admins_level_cu_only,
		// 		},
		// 	],
		// },
		// {
		// 	id: 4,
		// 	has_children: false,
		// 	name: 'Loans',
		// 	moduleName: 'Loans',
		// 	active: 'loans',
		// 	url: urls.loansHome,
		// 	// url: '#',
		// 	icon_url: '/_assets/new/loan.svg',
		// 	position: 'top',
		// 	roles: pagesText.admins_level_cu_only,
		// },
		{
			id: 40,
			has_children: false,
			name: 'Profile',
			moduleName: 'Profile',
			active: 'profile',
			url: urls.profileHome,
			// url: '#',
			icon_url: '/_assets/new/loan.svg',
			position: 'top',
			roles: pagesText.admins_level_cu_only,
		},
		{
			id: 5,
			has_children: true,
			name: 'Settings',
			moduleName: 'Settings',
			active: 'settings',
			url: 'settings',
			icon_url: '/_assets/new/settings.svg',
			position: 'top',
			roles: pagesText.admins_level_cu_only,
			children: [
				{
					id: 1,
					name: 'My Settings',
					moduleName: 'My Settings',
					active: 'my-settings',
					url: urls.settingsHome,
					roles: pagesText.admins_level_cu_only,
				},
				{
					id: 2,
					name: 'Transaction Pin',
					moduleName: 'Transaction Pin',
					active: 'transaction-pin-management',
					url: urls.transactionPinHome,
					roles: pagesText.admins_level_cu_only,
				},
				{
					id: 3,
					name: 'Security Question',
					moduleName: 'Security Question',
					active: 'security-question-home',
					url: urls.securityQuestionHome,
					roles: pagesText.admins_level_cu_only,
				},
				// {
				// 	id: 4,
				// 	name: 'Email Address',
				// 	moduleName: 'Email Address',
				// 	active: 'update-email-address',
				// 	url: urls.updateEmailAddress,
				// 	roles: pagesText.admins_level_cu_only,
				// },
			],
		},
		{
			id: 6,
			has_children: true,
			name: 'Information',
			moduleName: 'Information',
			active: 'general',
			url: 'general',
			icon_url: '/_assets/new/info.svg',
			position: 'top',
			roles: pagesText.admins_level_cu_only,
			children: [
				{
					id: 1,
					name: 'Privacy Policy',
					moduleName: 'Privacy Policy',
					active: 'privacy-policy',
					url: urls.privacy_policy,
					roles: pagesText.admins_level_cu_only,
				},
				{
					id: 2,
					name: 'Terms and Conditions',
					moduleName: 'Terms and Conditions',
					active: 'terms-and-condition',
					url: urls.terms_and_condition,
					roles: pagesText.admins_level_cu_only,
				},
				{
					id: 3,
					name: 'Bank Info',
					moduleName: 'Bank Info',
					active: 'general-settings',
					url: urls.general_settings,
					roles: pagesText.admins_level_cu_only,
				},
			],
		},
	],
	adminSidebar: [
		{
			id: 1,
			has_children: false,
			name: 'Dashboard',
			moduleName: 'Dashboard',
			active: 'dashboard',
			url: urls.adminDashboard,
			icon_url: '../../assets/images/admin/icon/dashboard.svg',
			position: 'top',
			defaultHref: false,
			roles: pagesText.admins_level_all,
		},
		{
			id: 2,
			has_children: false,
			name: 'Transactions',
			moduleName: 'Transactions',
			active: '',
			url: urls.adminTransactionDashboard,
			icon_url: '../../assets/images/admin/icon/transaction.svg',
			position: 'top',
			defaultHref: false,
			roles: pagesText.admins_level_all,
		},
		{
			id: 3,
			has_children: false,
			name: pagesText.tokenized_transactions,
			moduleName: 'Tokenized Deposits',
			active: '',
			url: urls.adminDepositHome,
			icon_url: '../../assets/images/admin/icon/transaction.svg',
			position: 'top',
			defaultHref: false,
			roles: pagesText.admins_level_all,
		},
		// {
		// 	id: 4,
		// 	has_children: false,
		// 	name: 'Cards',
		// 	active: '',
		// 	url: urls.adminCardsHome,
		// 	moduleName: 'Cards',
		// 	icon_url: '../../assets/images/admin/icon/card.svg',
		// 	position: 'top',
		// 	defaultHref: false,
		// 	roles: pagesText.admins_level_all,
		// },
		{
			id: 5,
			has_children: false,
			name: pagesText.customers,
			moduleName: 'Online Banking Customers',
			active: '',
			url: urls.adminCustomersHome,
			// icon_url: '../../assets/images/admin/icon/people.svg',
			icon_url: '',
			uikit: 'users',
			position: 'top',
			defaultHref: false,
			roles: pagesText.admins_level_all,
		},
		{
            id: 6,
            has_children: false,
            name: 'Agents',
			moduleName: 'Agents',
            active: '',
            url: urls.adminAgentsHome,
            icon_url: '../../assets/images/admin/icon/agent.svg',
            position: 'top',
            defaultHref:false,
			roles: pagesText.admins_level_all,
        },

		// {
		// 	id: 7,
		// 	has_children: false,
		// 	name: 'Loan',
		// 	active: '',
		// 	url: urls.adminLoanHome,
		// 	moduleName: 'Loan',
		// 	// icon_url: '../../assets/images/admin/icon/loan.svg',
		// 	icon_url: '',
		// 	uikit: 'bolt',
		// 	position: 'top',
		// 	defaultHref: false,
		// 	roles: pagesText.admins_level_all,
		// },
		//
		{
			id: 100,
			has_children: false,
			name: pagesText.admin_user,
			moduleName: 'System Users Management',
			active: '',
			url: urls.adminUserManagement,
			icon_url: '',
			uikit: 'users',
			// icon_url: '/_assets/new/loan.svg',
			position: 'top',
			defaultHref: false,
			roles: pagesText.admins_level_all,
		},
		{
			id: 101,
			has_children: false,
			name: pagesText.tier_management,
			moduleName: 'Tier Management',
			active: '',
			url: urls.adminTierManagement,
			// icon_url: '',
			// uikit: 'users',
			icon_url: '../../assets/images/admin/icon/tier.svg',
			position: 'top',
			defaultHref: false,
			roles: pagesText.admins_level_all,
		},
		{
			id: 102,
			has_children: false,
			name: pagesText.admin_roles_permission,
			moduleName: 'User Role Management',
			active: '',
			url: urls.adminRoleManagement,
			icon_url: '../../assets/images/admin/icon/role.svg',
			// uikit: 'users',
			// icon_url: '/_assets/new/loan.svg',
			width: '24',
			position: 'top',
			defaultHref: false,
			roles: pagesText.admins_level_all,
		},					
		{
			id: 9,
			has_children: true,
			name: 'Settings',
			moduleName: 'Settings',
			active: 'settings',
			url: 'settings',
			icon_url: '/_assets/new/settings.svg',
			position: 'top',
			roles: pagesText.admins_level_all,
			children: [
				{
					id: 1,
					name: 'Bank Info',
					moduleName: 'Settings-Bank Info',
					active: '',
					url: urls.adminSettingsHome,
					defaultHref: false,
					roles: pagesText.admins_level_all,
				},
			],
		},
		// {
		// 	id: 303,
		// 	has_children: true,
		// 	name: pagesText.reports,
		// 	moduleName: 'Reports',
		// 	active: 'reports',
		// 	url: 'reports',
		// 	icon_url: '',
		// 	position: 'top',
		// 	uikit: 'server',
		// 	roles: pagesText.admins_level_all,
		// 	children: [
		// 		{
		// 			id: 1,
		// 			name: 'MPR',
		// 			moduleName: 'Reports MPR',
		// 			active: '',
		// 			uikit: 'grid',
		// 			url: urls.adminMprReport,
		// 			roles: pagesText.admins_level_all,
		// 		},
		// 		{
		// 			id: 2,
		// 			name: 'Custom',
		// 			moduleName: 'Reports CUSTOM',
		// 			active: '',
		// 			icon: 'table',
		// 			url: urls.adminCustomReport,
		// 			roles: pagesText.admins_level_all,
		// 		},
		// 	],
		// 	defaultHref: false,
		// },
		{
			id: 300,
			has_children: false,
			name: pagesText.system_logs,
			moduleName: 'Audit Trail',
			active: '',
			url: urls.adminSystemLogs,
			// icon_url: '/_assets/new/agent_request.svg',
			icon_url: '',
			uikit: 'world',
			position: 'top',
			defaultHref: false,
			roles: pagesText.admins_level_ad_sa_only,
		},
	],

	adminSidebarBottom: [
		{
			id: 1,
			has_children: false,
			name: 'Support',
			active: '',
			url: urls.adminSupportHome,
			icon_url: '../../assets/images/admin/icon/support.svg',
			position: 'top',
		},
		{
			id: 2,
			has_children: false,
			name: 'Logout',
			active: '',
			url: '',
			icon_url: '../../assets/images/sidebar/logout_icon.svg',
			position: 'top',
		},
	],
	customerSidebarBottom: [
		{
			id: 1,
			has_children: false,
			name: 'Support',
			active: '',
			url: urls.supportHome,
			icon_url: '.../assets/images/sidebar/support_icon.svg',
			position: 'top',
		},
		{
			id: 2,
			has_children: false,
			name: 'Logout',
			active: '',
			url: '',
			icon_url: './assets/images/sidebar/logout_icon.svg',
			position: 'top',
		},
	],
}
//#E88749
