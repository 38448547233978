
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import formStyles from "../../../../assets/css/formStyle.module.css";
import { Loader } from "../../../../components";
import { TextInputField } from "../../../../components/form/components";
import CheckboxField from "../../../../components/form/components/CheckboxField";
import { appConfig, remoteUrl } from "../../../../config/config";
import { formatMessages } from "../../../../config/redux/actions";
import { postRequest2 } from "../../../../config/redux/request";
import {
    auth_types,
    clearAll,
    onLinkAccountOpening,
    setAuthModalForm,
    setErrorMessage,
    setLoading
} from "../../../../config/redux/slice/authData";
import { stepOne } from "../../../../config/redux/slice/authSlice";
import { pagesText } from "../../../../config/string/english";
import { urls } from "../../../../config/urls/urls";
import AlertWrapper from "../../../_shared_component/AlertWrapper";

// import { colors } from "../../../../config/color";
import { PhoneNumberComponent } from "../../../onboard_one/components";

/*
removed
 email
 address
 */
const PersonalDetails = () => {
    const {formOneData} = useSelector((state) => state.auth )
    const {colors} = useSelector((state) => state.app)
    const {loading,errorMsg,success_msg,showAccountCreateForm,user_role,token,tempEmail,authSize} = useSelector((state) => state.authData)
    const [isEmailVerified, setEmailVerified] = useState(false)
    //Admin Login
    const [togglePassword, setTogglePassword] = useState(false)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [tempFormData, setTempFormData] = useState()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [isTempFormData, setIsTemFormData] = useState(false)
    const dispatch = useDispatch()
    //
    const onSubmit = async (data) => {
        dispatch(clearAll())
        setTempFormData(data)
        dispatch(stepOne(data))
        // const res = await postRequest(
        //     `${appConfig.auth_token}`,
        //     `${remoteUrl.open_acc}`,
        //     data,
        //     dispatch,
        //     setLoading,
        //     onOpeningAccount,
        //     setErrorMessage)
    }

    const linkWithCso = async () => {
        let postData = {
            'email': tempFormData?.email,
            'password': tempFormData?.password,
            'account_type': tempFormData?.account_type,
            'address': tempFormData?.address
        }
        //
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.link_acc}`,
            postData,
            dispatch,
            setLoading,
            onLinkAccountOpening,
            setErrorMessage
        )
    }

    const closeModal = () => {
        setIsTemFormData('')
        setTempFormData('')
        dispatch(setAuthModalForm({
            modal: false,
            type: auth_types.show_create_account_form,
            clearMsg: true
        }))
    }

    if (success_msg === "account opening request successfully submitted") {
        setValue('firstname', '')
        setValue('midllename', '')
        setValue('lastname', '')
        setValue('email', '')
        setValue('phone', '')
        setValue('address', '')
        setValue('account_type', '')
        setValue('password', '')
        setValue('confirm_password', '')
    }


    return(
        <>
            <fieldset>
                <div className={'row'}>
                    <div className={'col-md-8 offset-2'}>
                        {
                            !isTempFormData
                                ?
                                <>
                                    {
                                        success_msg===pagesText.on_open_account_one
                                        &&
                                        <AlertWrapper type={'success'}>
                                            Account opening request has been submitted successfully, you will be notified when your account is ready.
                                            <Link to={urls.landingPage} > Click here </Link> to close this form.
                                        </AlertWrapper>
                                    }
                                    {/**/}
                                    {errorMsg !== "" && errorMsg === 'SSO account already exists' ?
                                        <AlertWrapper type={'danger'}>
                                            It looks like you already have a QApp profile, Kindly
                                            <Link  onClick={() => setIsTemFormData(true)} to={'#'}> <u> Click here </u> </Link>
                                            to link your QApp profile.
                                        </AlertWrapper>
                                        :
                                        errorMsg && <AlertWrapper>{formatMessages(errorMsg)}.</AlertWrapper>
                                    }

                                    {/*new form/ first form page*/}
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className={'row mb-3'}>
                                            {/*firstname*/}
                                            <div className={'col-md-12'}>
                                                <TextInputField
                                                    fieldName={'firstname'}
                                                    error_msg={errors.firstname?.message}
                                                    col={'col-xl-12'}
                                                    label={'First name*'}
                                                    placeholder={''}
                                                    isReq={true}
                                                    register={register}
                                                    defaultVal={formOneData?.firstname??''}
                                                    patternRules={'letters'}
                                                />
                                            </div>
                                        </div>

                                        <div className={'row mb-3'}>
                                            {/*middle name*/}
                                            <div className={'col-md-12'}>
                                                <TextInputField
                                                    fieldName={'midllename'}
                                                    error_msg={errors.midllename?.message}
                                                    col={'col-xl-12'}
                                                    label={'Middle name'}
                                                    placeholder={''}
                                                    isReq={false}
                                                    register={register}
                                                    defaultVal={formOneData?.midllename??''}
                                                    patternRules={'letters'}
                                                />
                                            </div>
                                        </div>

                                        {/*phone*/}
                                        <div className={'row mb-3'}>
                                            {/*lastname*/}
                                            <div className={'col-md-12'}>
                                                <TextInputField
                                                    fieldName={'lastname'}
                                                    error_msg={errors.lastname?.message}
                                                    col={'col-xl-12'}
                                                    label={'Last name*'}
                                                    placeholder={''}
                                                    isReq={true}
                                                    register={register}
                                                    defaultVal={formOneData?.lastname??''}
                                                    patternRules={'letters'}
                                                />
                                            </div>
                                        </div>

                                        <div className={'row mb-3'}>
                                            <div className={'col-md-12'}>
                                                <PhoneNumberComponent
                                                    setValueFunc={setValue}
                                                    col={''}
                                                    nextCompId={'address'}
                                                    fieldName={'phone'}
                                                    defaultVal={formOneData?.mobile_new??''}
                                                    label={'Phone number*'}
                                                    register={register}
                                                    error_msg={errors.phone?.message}
                                                    // selectStyle={}
                                                    isReq={true}
                                                />
                                            </div>
                                        </div>


                                        {/*Gender*/}


                                        {/*Invitation Code (optional)*/}
                                        <div className={'ml-4'}>
                                            <CheckboxField
                                                register={register}
                                                label={'terms_and_condition'}
                                                fieldName={'terms_and_condition'}
                                                isReq={true}
                                                error_msg={errors?.terms_and_condition?.message}
                                                defaultVal={formOneData?.terms_and_condition??false}
                                            />
                                        </div>

                                        {/**/}
                                        <div className={'row mt-4 mb-3'}>
                                            <div className={'col-md-12'}>
                                                <div className={'float-right'}>
                                                    {/*<ButtonField*/}
                                                    {/*    txtColor={colors.white}*/}
                                                    {/*    bgColor={colors.primary_color}*/}
                                                    {/*    btnTxt={'Next'}*/}
                                                    {/*    btnWidth={'25'}*/}
                                                    {/*    btnStyle={'mx-auto col-xl-12'}/>*/}
                                                    <button type={'submit'} className={'btn btn-dark px-5'} style={{background:`${colors.primary_color}`}}>
                                                        Continue
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </form>


                                    <div className={'text-center mt-1'}>
                                        <hr/>
                                        Already have an account with online banking ?
                                        <Link
                                            to={urls.login} style={{color: colors.primary_color}}>
                                            &nbsp;   Login
                                        </Link>
                                    </div>
                                </>
                                :
                                // when user accounts already exists
                                <>
                                    { errorMsg && errorMsg !== 'SSO account already exists' && <AlertWrapper >{formatMessages(errorMsg)}.</AlertWrapper> }

                                    {
                                        success_msg && <AlertWrapper type={'success'} >
                                            {formatMessages(success_msg) }
                                            <Link to={urls.landingPage}> Click here </Link> to close this form.
                                        </AlertWrapper>
                                    }
                                    <div className={'row mb-3'}>
                                        <div className={' col-md-4 '}>
                                            <label>First Name</label>
                                            <input style={{borderRadius:'50px'}}
                                                   className={'form-control'} type={'text'} disabled={true}
                                                   defaultValue={tempFormData?.firstname}/>
                                        </div>
                                        {/* First name   */}
                                        <div className={'col-md-4 mb-1'}>
                                            <label>Middle Name</label>
                                            <input style={{borderRadius:'50px'}} className={'form-control'} type={'text'} disabled={true}
                                                   defaultValue={tempFormData?.midllename}/>
                                        </div>
                                        {/*lastname*/}
                                        <div className={'col-md-4 mb-1'}>
                                            <label>Last Name</label>
                                            <input style={{borderRadius:'50px'}} className={'form-control'} type={'text'} disabled={true}
                                                   defaultValue={tempFormData?.lastname}/>
                                        </div>
                                    </div>
                                    {/**/}
                                    <div className={'row mb-3'}>
                                        {/*email*/}

                                        <div className={'col-md-6 mb-1'}>
                                            <label>Email</label>
                                            <input style={{borderRadius:'50px'}} className={'form-control'} type={'text'} disabled={true}
                                                   defaultValue={tempFormData?.email}/>
                                        </div>
                                        {/*phone*/}

                                        <div className={'col-md-6  mb-1'}>
                                            <label>Phone Number</label>
                                            <input style={{borderRadius:'50px'}} className={'form-control'} type={'text'} disabled={true}
                                                   defaultValue={tempFormData?.phone}/>

                                        </div>
                                    </div>
                                    {/**/}
                                    <div className={'row mb-3'}>
                                        {/*address*/}
                                        <div className={'col-md-6'}>
                                            <label>Address</label>
                                            <input style={{borderRadius:'50px'}} className={'form-control'} type={'text'} disabled={true}
                                                   defaultValue={tempFormData?.address}/>
                                        </div>
                                        {/*account_type*/}

                                        <div className={'col-md-6'}>
                                            <label>Account Type</label>
                                            <select
                                                disabled={true}
                                                defaultValue={tempFormData?.account_type}
                                                className={'form-control'}
                                                style={{borderRadius:'50px'}}
                                            >
                                                <option value={''}>Select Account Type</option>
                                                {
                                                    pagesText.accounts_type.map((item,i=0) =>
                                                        <option value={item.value} key={i+1}>{item.name}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {/* Password */}
                                    <div className={'row mb-2'}>
                                        <div className={'col-md-12'}>
                                            <div className="form-group">
                                                <label>Password*</label>
                                                <div className="input-group" style={{border: `0.05rem solid #ced4da`,borderRadius:"50px"}}>
                                                    <input
                                                        type={showPassword ? 'text' : 'password'}
                                                        className={`form-control ${formStyles.passwordTextField}  ${errors.password?.message && 'is-invalid'}`}
                                                        aria-label=""
                                                        aria-describedby="basic-addon2"
                                                        placeholder={''}
                                                        defaultValue={''}
                                                        autoComplete={'off'}
                                                        autoCorrect={'off'}
                                                        style={{border: 0}}
                                                        {...register(`password`, {
                                                            required: {
                                                                value: true,
                                                                message: '*this field is required',
                                                            },
                                                            minLength: {
                                                                value: 6,
                                                                message: 'password too short'
                                                            }
                                                        })
                                                        }
                                                    />
                                                    <div className="input-group-append">
                                                        <span
                                                            id="basic-addon2"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            className={`input-group-text ${formStyles.passwordEyeIcon}`}
                                                            style={{border: 0}}
                                                        >
                                                            <img src={'../../assets/images/admin/auth/open_wide.svg'} alt={''}/>
                                                        </span>
                                                    </div>
                                                </div>
                                                {errors.password && <div className="text-danger">{errors.password?.message}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    {/**/}
                                    <div className={'row'}>
                                        <div className={'col-md-12 mt-1'}>
                                            {loading ? <Loader/> :
                                                <button
                                                    className={'mx-auto col-xl-12 mt-1 pt-2 pb-2 w-100'}
                                                    style={{
                                                        background: `${colors.primary_color}`,
                                                        color: colors.white,
                                                        border: `1px solid ${colors.primary_color}`,
                                                        borderRadius: '50px'
                                                    }}
                                                    onClick={() => linkWithCso()}>
                                                    Link my QApp Profile
                                                </button>
                                            }
                                            <p className={'text-center pt-2'}>
                                                Already have an account with online banking ?
                                                <Link
                                                    to={urls.login} style={{color: colors.primary_color}}>
                                                    &nbsp;   Login
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>

                </div>

            </fieldset>
        </>
    )
}

export default PersonalDetails