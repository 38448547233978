import formStyles from '../../../assets/css/formStyle.module.css'

const SelectField = ({
                         fieldName,
                         register,
                         label,
                         col,
                         error_msg,
                         isReq,
                         children,
                         defaultVal,
                         selectStyle,
                         selectFunc
                     }) => {
    return (


        <>
            {/*<label className={`form-label ${formStyles.label}`}>{label}</label>*/}
            <div className="form-group">
                {label && label !=='' &&  <label  className="placeholder fw-bold">{label}</label> }
                <select
                    id=""
                    defaultValue={defaultVal}
                    onMouseDownCapture={(e) => {
                        if (selectFunc) {
                            return selectFunc(e)
                        }
                    }}
                    // id={fieldName}
                    className={`form-control  ${formStyles.selectField} ${error_msg && 'is-invalid'}`}
                    style={selectStyle}
                    {...register(`${fieldName}`, {
                        required: {
                            value: isReq,
                            message: '*this field is required'
                        },
                        onChange: (e) => {
                            if (selectFunc) {
                                return selectFunc(e.target.value)
                            }
                        }
                    })
                    }
                >
                    {children}
                </select>

            </div>
            {error_msg && <div className="invalid-feedback">{error_msg}</div>
            }
        </>



    )
}

export default SelectField