import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CardWrapper, ConfirmationWrapper, Loader, SuccessAlertModal } from "../../../../../components";
import { ButtonField, TextInputField } from "../../../../../components/form/components";
// import { colors } from "../../../../../config/color";
import { appConfig, remoteUrl } from "../../../../../config/config";
import { NumberFormatter } from "../../../../../config/redux/actions";
import { postRequest, postRequest2 } from "../../../../../config/redux/request";
import {
    clearMessages,
    resetTransferFormData,
    saveBeneficiary, setCustomerErrorMsg,
    setLoading
} from "../../../../../config/redux/slice/customerSlice";
import { respMsg } from "../../../../../config/response_messages";
import { strText } from "../../../../../config/string";
import { urls } from "../../../../../config/urls/urls";

const TransferSuccess = ({successModal}) => {
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        clearErrors,
        formState: {errors},
        reset
    } = useForm({mode: 'onTouched'})
    const {colors} = useSelector((state) => state.app)
    const {loading, transferFormData,success_msg} = useSelector((state) => state.customer)
    const { userData,token  } = useSelector((state) => state.authData)
    const [isAliasForm,setIsAliasForm] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onSuccessModalClose = () => {
        dispatch(resetTransferFormData())
    }

    const saveBeneficiaries = (data) => {
        const postData = {
            "benefactor_email":userData.email,
            "benefactor_account_number":userData.account_number,
            "beneficiary_name":transferFormData.beneficiary,
            "beneficiary_alias":data?.beneficiary_alias,
            "beneficiary_account_number":transferFormData.account_number,
            "beneficiary_bank":transferFormData.bank_name,
            "jwt" : token
        }
        //saveBeneficiary
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.saveBeneficiaries}`,
            postData,
            dispatch,
            setLoading,
            saveBeneficiary,
            setCustomerErrorMsg
        )
    }
    const onClickSaveBeneficiary = () => {
        dispatch(clearMessages())
        setIsAliasForm(true)

    }


    if(success_msg===respMsg.save_beneficiary){
        dispatch(resetTransferFormData())
        setIsAliasForm(false)
       return navigate(`${urls.transactionHistory}`)
    }


    return <>
        <SuccessAlertModal
            backdrop={true}
            msg={isAliasForm ? '' : 'Your transaction is successful'}
            imgUrl={isAliasForm ? '' :  '../assets/images/success.gif'}
            onClose={onSuccessModalClose}
        >
            <CardWrapper cardTitle={''} cardWidth={' px-2'}>
                { isAliasForm ?
                    <form onSubmit={handleSubmit(saveBeneficiaries)}>
                        <TextInputField
                            // inputStyles={}
                            placeholder={''}
                            defaultVal={''}
                            error_msg={errors.beneficiary_alias?.message}
                            fieldName={'beneficiary_alias'}
                            isReq={false}
                            label={'Beneficiary Alias'}
                            register={register}
                            // patternRules={}
                            // onClickFunc={}
                        />
                        {
                            loading
                                ?
                            <div className="mt-3"><Loader/></div>
                            :
                            <ButtonField
                                id={'beneficiary'}
                                // btnAdditionalStyle={}
                                btnStyle={'mt-3'}
                                txtColor={colors.white}
                                bgColor={colors.primary_color}
                                btnWidth={'100'}
                                btnTxt={'Save Beneficiary'}
                            />
                        }
                    </form>
                    :
                    <>
                        <ConfirmationWrapper
                            title={''}
                            dataArray={[
                                // {
                                //     text : 'Transaction Reference',
                                //     amount: `${transferFormData?.transaction_ref}`
                                // },
                                {
                                    text : 'Amount',
                                    amount: `${strText.naira}${NumberFormatter(Number(transferFormData?.amount)?.toFixed(2))}`
                                },
                                {
                                    text : 'Beneficiary',
                                    amount: `${transferFormData?.beneficiary} / ${transferFormData?.account_number} / ${transferFormData?.bank_name}`
                                },
                                {
                                    text : 'From',
                                    amount: userData?.full_name
                                },
                                {
                                    text : 'Date',
                                    amount: transferFormData?.transaction_date
                                },
                                {
                                    text : 'Narration',
                                    amount: transferFormData?.description
                                }
                            ]}
                            titleStyle={''}
                        />
                        <div className={' mt-4 w-100'}>
                            <div className={'d-flex pull-right float-right'} >
                                <div className={''} onClick={() => onClickSaveBeneficiary()}>
                                    <ButtonField
                                        btnWidth={''}
                                        btnTxt={'Save Beneficiary'}
                                        bgColor={colors.primary_color}
                                        txtColor={colors.white}
                                        btnStyle={''}
                                    />
                                </div>
                                <div className={''}>
                                    <Link
                                        to ={urls.customerDashboard}
                                        style={{
                                            color:colors.primary_color,
                                            background:colors.white,borderColor:colors.primary_color,
                                            padding:'0.8rem 1.5rem',
                                            borderRadius:'50px' }}
                                        className={'btn  ml-2 px-5'}
                                    >
                                        Close
                                    </Link>
                                </div>


                            </div>


                        </div>
                    </>

                }
            </CardWrapper>


        </SuccessAlertModal>
    </>
}

export default  TransferSuccess