import React from 'react'
import formStyles from "../../../assets/css/formStyle.module.css";

const ColorInputField = ({
                            fieldName,
                            placeholder,
                            register,
                            label,
                            error_msg,
                            isReq,
                            isSameAs,
                            setValueFunc,
                            minlength,
                            maxlength,
                            defaultVal,
                            inputStyles,
                            onClickFunc,
                            patternRules,
                            id=""
                        }) => {
  return (

    <div className='form-group'>
        <label className="fw-bold">{label}</label>
        <div className="input-group">
            <div className="input-group-prepend">
                <div style={{borderRadius:'20px 0px 0px 20px'}} className="input-group-text">
                <input 
                    type="color" aria-label="Checkbox for following text input"
                    defaultValue={defaultVal}
                    {...register(`${fieldName}_colorpicker`)}
                     onChange={(e) => {
                        setValueFunc(`${fieldName}`, e.target.value); // This is optional, but it ensures React Hook Form is aware of changes
                    }}
                />
                </div>
            </div>
            <input 
                type="text" aria-label="Text input with checkbox"
                defaultValue={defaultVal}
                className={`form-control ${formStyles.colorTextField} ${error_msg && 'is-invalid'}`}
                {...register(`${fieldName}`, {
                    required: {
                        value: isReq,
                        message: '*this field is required'
                    },
                    pattern:{
                        value : /^#([0-9A-Fa-f]{6})$/i,
                        message: 'Invalid Input'
                    }
                })}
                onChange={(e) => {
                        setValueFunc(`${fieldName}_colorpicker`, e.target.value); // This is optional, but it ensures React Hook Form is aware of changes
                }}
            />
            </div>
            {error_msg && <div className="invalid-feedback ml-3">{error_msg}</div>}
        </div>
  )
}

export default ColorInputField
