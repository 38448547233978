import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from "../../../components";
import { ButtonField, PasswordField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../../config/config";
import { postRequest, postRequest2 } from "../../../config/redux/request";
import { onAdminLogin2 } from "../../../config/redux/slice/adminSlice";
import {
    auth_types,
    changeModalSize,
    clearAll, logoutUser, onCustomerEmailVerificationRequest,
    onCustomerLogin, setAuthModalForm,
    setErrorMessage,
    setLoading
} from "../../../config/redux/slice/authData";
import { logoutCustomer } from "../../../config/redux/slice/customerSlice";
import { adminUrls } from "../../../config/urls/adminUrls";
import { urls as onBoardUrls, urls } from "../../../config/urls/urls";
import AlertWrapper from "../../_shared_component/AlertWrapper";
import { AuthWrapperAdmin } from "../new_account/pages";


const AdminPasscodeScreen = () => {
    const navigate = useNavigate();
    // Check LoginNew Component for reference
    const [togglePassword, setTogglePassword] = useState(false)
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        loading,
        showLoginModal,
        user_role,
        errorMsg,
        success_msg,
        tempEmail,
        token,
        userData
    } = useSelector((state) => state.authData)

    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [isEmailVerified, setEmailVerified] = useState(false)
    const dispatch = useDispatch()
    const [successMessage, setSuccessMessage] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)

    useEffect(()=> {
        dispatch(clearAll({}))
        dispatch(changeModalSize({type:''}))
    },[])


  const {phone, admin_token, otp } = useSelector((state) => state?.administrator?.adminRegDetails)

    const closeErrorAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const closeSuccessAlert = () => {
        setSuccessMessage(false)
    }

    const onSubmit = async (data) => {
        dispatch(clearAll({}))

      let res = await postRequest2(
          `${appConfig.auth_token}`,
          `${endpointUrl.adminLogin}`,
          {...data, phone, admin_token, otp},
          dispatch,
          setLoading,
          "",
          setErrorMessage

          )
        if(res !== undefined){
        if(res.data.error_code == 12 || res.data.error_code == 13){
            dispatch(onAdminLogin2({type: '', data:{...data, phone, type: 'new_device'}}))
            navigate(`${adminUrls.adminOtp}`)
        }else if(res.status < 400){
            dispatch(onCustomerLogin(res.data.data))
            navigate(`${adminUrls.adminDashboard}`)
        }
        }
    }
    //
    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }
    useEffect(()=>{
    
    if (success_msg !== "" && isEmailVerified) {
        navigate(urls.customerEmailVerification)
    }

    if (token!=='' && user_role === 'customer') {
        dispatch(logoutUser())
        dispatch(logoutCustomer())
        dispatch(setAuthModalForm({type:auth_types.show_login_form,modal:false}))
        // dispatch(setErrorMessage({message:'Customer does not exist.'}))

        navigate(urls.adminLogin)
    }        
    }, [success_msg, user_role, token])

        return <AuthWrapperAdmin classTitle={'admin_login'} title='Admin Login' >
                <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)}>
                    { errorMsg === 'verify email first' ?
                        <AlertWrapper onClick={closeErrorAlert} type={'danger'}>
                            <div className="">
                                Your email needs to be verified before you can use access admin dashboard.
                                <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}> <u>Click here </u></Link>  to verify.
                            </div>
                        </AlertWrapper> :
                        errorMsg == 16 ? <AlertWrapper onClick={closeErrorAlert} type={'error'}>Invalid Credentials</AlertWrapper>
                        :  errorMsg && <AlertWrapper onClick={closeErrorAlert} type={'error'}>{errorMsg}</AlertWrapper>
                    }

                    <div className="mt-3 login-form">                      
                        <div>
                            <PasswordField
                                fieldName={'passcode'}
                                setValueFunc={setValue}
                                error_msg={errors.passcode?.message}
                                col={'col-xl-12'}
                                label={'Enter your Passcode'}
                                nextCompId={'password'}
                                placeholder={''}
                                isReq={true}
                                minlength={6}
                                maxlength={6}
                                register={register}
                                defaultVal={''}
                                patternRules={'passcode'}
                            />
                            <small id="emailHelp" className="form-text text-muted ml-4 mt-2 mb-2">
                                <Link to={onBoardUrls.adminForgotPassword} style={{color: colors.primary_color}}>Forgot your Passcode?</Link>
                                </small>                            
                        </div>


                        <div className="form-action mt-3 mb-3">
                            {loading ? <div className="mt-3"><Loader/></div> :
                                <ButtonField
                                    btnStyle={'py-2'}
                                    btnTxt={'Sign In'}
                                    btnWidth={'100'}
                                    bgColor={colors.primary_color}
                                    txtColor={colors.white}
                                    id={'d'}
                                    // btnAdditionalStyle={}
                                />
                            }
                        </div>
                    </div>
                </form>
    </AuthWrapperAdmin>
}


export default AdminPasscodeScreen
