import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../../components";
import { ButtonField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, endpointUrl, remoteUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import {
    clearAll, onCustomerEmailVerificationRequest,
    onCustomerLogin2,
    setErrorMessage,
    setLoading
} from "../../../config/redux/slice/authData";
import { urls as onBoardUrls } from "../../../config/urls/urls";
import AlertWrapper from "../../_shared_component/AlertWrapper";
import { OtpComponent } from "../../v2/components";
import { AuthWrapperAdmin } from "../new_account/pages";


const AdminTokenScreen = () => {
    const {authSize} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {loading,errorMsg,success_msg,user_role,token,tempEmail} = useSelector((state) => state.authData)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const [successMessage, setSuccessMessage] = useState(false)
    const [isEmailVerified, setEmailVerified] = useState(false)

    const closeErrorAlert = () => {
        dispatch(setErrorMessage({message: null}))
    }

    const closeSuccessAlert = () => {
        setSuccessMessage(false)
    }

    const handleClick = async (data) => {
        let newData = {phone: phone}
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.resendOtp}`,
            newData,
            dispatch,
            "",
            "",
            setErrorMessage
        )
        if(res !== undefined && res.status < 400){
            setSuccessMessage(true)
        }
    }    

    let phone = useSelector((state) => state?.authData?.userRegDetails?.phone)

    const onSubmit = async (data) => {
        dispatch(clearAll({}))
        let newData = {...data, phone: phone}
        

            dispatch(onCustomerLogin2({type: '', data:newData}))
            navigate(`${onBoardUrls.adminPasswordReset}`)

    } 
    
    const sendVerificationEmail = async () => {
        setEmailVerified(true)
        dispatch(clearAll({}))
        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestEmailVerification}`,
            {email: tempEmail},
            dispatch,
            setLoading,
            onCustomerEmailVerificationRequest,
            setErrorMessage
        )
    }    

    return (
    <AuthWrapperAdmin classTitle={'admin_login'} title='OTP Verification' >
        <form className={'px-5 pb-3'} onSubmit={handleSubmit(onSubmit)} autoComplete={'off'} noValidate>
            {
                successMessage &&
                <AlertWrapper onClick={closeSuccessAlert} type={'success'}>
                    <div className={'py-1 text-capitalize'}>
                        Otp Sent Successfully
                    </div>
                </AlertWrapper>
            }
            {
                errorMsg &&
                <AlertWrapper onClick={closeErrorAlert} type={'error'}>
                    <div className={'py-1 text-capitalize'}>
                        {errorMsg === "Cannot read properties of undefined (reading 'account_number')"
                            ?
                            "Your account request is still pending, you will get a email notification upon approval."
                            :
                            errorMsg === 'verify email first' ?
                                <div className="">

                                    Your email needs to be verified before you can use online banking.
                                    <Link to={''} className={'text-danger'} onClick={() => sendVerificationEmail()}>
                                        <span className={'text-primary'}> <u> Click here </u> </span>
                                    </Link>to verify.
                                </div>
                                :
                                errorMsg === 'Request failed with status code 429' ?
                                    'To many Request. Please wait for 15min' : errorMsg
                        }
                    </div>
                </AlertWrapper>
            }
    <div className="mt-3 login-form">
            <OtpComponent
            error_msg={errors.otp?.message}
            label={`Enter the 6 digit code that was sent to your phone (${phone})`}
            placeholder={''}
            isReq={true}
            register={register}
            defaultVal={''}
            patternRules={''}
            minlength={6}
            maxlength={6}
            onClick={handleClick}
            />                  
        <div>
        </div>

        <div className="form-action mb-3">
                    {loading ? <Loader/> :
                        <ButtonField
                            txtColor={colors.white}
                            bgColor={colors.primary_color}
                            btnTxt={'Continue'}
                            btnWidth={'100'}
                            btnStyle={'mx-auto col-xl-12 py-2 mt-2'}/>}
        </div>

    </div>                                
        </form>
    </AuthWrapperAdmin>    
    )
}
export default  AdminTokenScreen