import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
    clearErrorMsg, onAgentBankingApprovalApprove,
    onAgentBankingApprovalReq,
    onGetAgentBankingReqRemark,
    onGetInternetBankingRemark,
    onInternetBankingApprovalApprove,
    onInternetBankingApprovalReq,
    setAgentAccountReqDetails,
    setErrorMsg,
    setInternetReqDetails,
    setLoading
} from "../../../../config/redux/slice/adminSlice";
import {clearAll} from "../../../../config/redux/slice/authData";
import {postRequest, postRequest2} from "../../../../config/redux/request";
import {appConfig, remoteUrl} from "../../../../config/config";
import {GeneralModalWrapper} from "../../../_shared_component";
import {Loader} from "../../../../components";
import {ButtonField, TextInputField} from "../../../../components/form/components";
// import {colors} from "../../../../config/color";
import moment from "moment";
import {pagesText} from "../../../../config/string/english";


const AgentAccountRequestDetails = ({showModal}) => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {token, user_role} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {loading, adminErrorMsg, success_msg} = useSelector((state) => state.administrator)
    const {
        systemManagement: {
            agentRequest: {
                isAccountReqRemark,
                remark,
                showRequestForm,
                showDetails,
                agentDetails
            }
        }
    } = useSelector((state) => state.administrator)

    const dispatch = useDispatch()
    //2
    const onClose = () => {
        dispatch(clearErrorMsg())
        dispatch(clearAll())
        dispatch(setAgentAccountReqDetails({modal: false, data: '', isRemark: ''}))
    }
    // 3 submitRequestForApproval
    const submitRequestForApproval = (data) => {
        dispatch(clearErrorMsg())
        dispatch(clearAll())
        const postData = {
            "jwt": token,
            "abr_id": agentDetails.abr_id
        }

        if (data.remark !== "") {
            postData.remark = data.remark
        } else {
            postData.remark = " "
        }
        //requestInternetBankingApproval
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.addReviewToAgentBankingApproval}`,
            postData,
            dispatch,
            setLoading,
            onAgentBankingApprovalReq,
            setErrorMsg
        )

    }
    /*4 method that approves internet banking request*/
    const approveInternetBankingReq = (data) => {
        const postData = {
            "jwt": token,
            "abr_id": agentDetails.abr_id,
            "account_number": agentDetails.account_number
        }
        //approveInternetBankingApproval
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.approveAgentBankingReq}`,
            postData,
            dispatch,
            setLoading,
            onAgentBankingApprovalApprove,
            setErrorMsg,
        )
    }

    //first step
    if (isAccountReqRemark === 'show_remark_form' || isAccountReqRemark === 'no_remark_yet') {
        const postData = {
            "jwt": token,
            "abr_id": agentDetails.abr_id
        }
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.viewAgentBankingApprovalRemark}`,
            postData,
            dispatch,
            setLoading,
            onGetAgentBankingReqRemark,
            setErrorMsg
        )
        dispatch(setAgentAccountReqDetails({modal: true, data: agentDetails, isRemark: 'hide_remark_form'}))
    }


    return (
        <>
            <GeneralModalWrapper
                showModal={showModal}
                closeModalFunc={onClose}
                modal_title={`Agency Banking Approval Details | ${agentDetails.firstname ?? ''}  ${agentDetails.middlename ?? ''} ${agentDetails.lastname ?? ''} `}>

                <div className={'px-3'}>
                    <table className={'table table-striped'}>
                        <thead></thead>
                        <tbody>
                        {/*<tr>*/}
                        {/*    <td>Agent Name</td>*/}
                        {/*    <td>{`${agentDetails.firstname ?? ''}  ${agentDetails.middlename ?? ''} ${agentDetails.lastname ?? ''} `}</td>*/}
                        {/*</tr>*/}
                        <tr>
                            <td>Account Number</td>
                            <td>{agentDetails.account_number}</td>
                        </tr>

                        <tr>
                            <td>Customer Email</td>
                            <td>{agentDetails.agent_email}</td>
                        </tr>

                        <tr>
                            <td>Customer Phone</td>
                            <td>{agentDetails.agent_phone}</td>
                        </tr>

                        <tr>
                            <td>Initiated By</td>
                            <td>{agentDetails.initiated_by ?? 'N/A'}</td>
                        </tr>
                        <tr>
                            <td>Approved By</td>
                            <td>{agentDetails.approved_by ?? 'N/A'}</td>
                        </tr>

                        <tr>
                            <td>Request Status</td>
                            <td>

                                {!agentDetails.initiated_by
                                    ?
                                    <small className='uk-text-warning uk-text-bold'>pending</small>
                                    :
                                    agentDetails.initiated_by && agentDetails.request_status === 'pending'
                                        ?
                                        <small className='text-info uk-text-bold'>processing</small>
                                        :
                                        agentDetails.request_status==='approved'
                                            ?
                                            <small className='uk-text-success uk-text-bold'>approved</small>
                                            :
                                            <small className='badge badge-danger uk-text-bold'>N/A</small>
                                }
                            </td>
                        </tr>

                        <tr>
                            <td>Requested at</td>
                            <td>{moment(agentDetails.created_on).format('YYYY-MM-DD hh:mm:ss')}</td>
                        </tr>

                        <tr>
                            <td>Remark</td>
                            <td>{agentDetails.remark ?? 'N/A'}</td>
                        </tr>

                        </tbody>
                    </table>

                    {
                        user_role === pagesText.user_roles_text_key[2]
                            ?
                            agentDetails?.request_status === 'pending' && remark === '' ?
                                <>
                                    <form className={'py-3'} onSubmit={handleSubmit(submitRequestForApproval)}>
                                        <div className={'mb-2'}>
                                            <TextInputField
                                                placeholder={'enter account number'}
                                                patternRules={'numbers'}
                                                defaultVal={agentDetails.account_number}
                                                error_msg={errors.account_number?.message}
                                                isReq={true}
                                                fieldName={'account_number'}
                                                label={'Account Number'}
                                                register={register}/>
                                        </div>
                                        <div className={'mb-2'}>

                                            <TextInputField
                                                // inputStyles={}
                                                patternRules={'letters_numbers'}
                                                // onClickFunc={}
                                                register={register}
                                                label={'Remark (optional)'}
                                                fieldName={'remark'}
                                                isReq={false}
                                                error_msg={errors.remark?.message}
                                                defaultVal={''}
                                                placeholder={''}
                                            />
                                            {/*<textarea {...register('remark', {*/}
                                            {/*    required: true*/}
                                            {/*})} className={'form-control mb-2'}></textarea>*/}
                                        </div>

                                        {
                                            loading
                                                ?
                                                <Loader/>
                                                :
                                                <ButtonField
                                                    btnTxt={'Submit'}
                                                    btnWidth={'100'} bgColor={colors.secondary_color}
                                                    txtColor={colors.white} id={'submit'}
                                                />
                                        }
                                    </form>
                                </>
                                :
                                ''
                            :
                            ''
                    }

                    {

                        user_role === pagesText.user_roles_text_key[3]
                        ?
                        <>
                            {agentDetails?.request_status === 'approved' ? '' :
                                agentDetails?.request_status !== 'approved' && remark ?
                                    <>
                                        <div className={'px-3 py-4'}>
                                            <form className={''}
                                                  onSubmit={handleSubmit(approveInternetBankingReq)}>

                                                {
                                                    loading
                                                        ?
                                                        <Loader/>
                                                        :
                                                        <ButtonField
                                                            btnTxt={'Approve Request'}
                                                            btnWidth={'100'} bgColor={colors.secondary_color}
                                                            txtColor={colors.white} id={'submit'}
                                                        />
                                                }
                                            </form>
                                        </div>
                                    </>
                                    :
                                    ''
                            }

                        </>
                        :
                        ''}
                    {/*  */}
                    {


                    }
                </div>
            </GeneralModalWrapper>
        </>

    )
}

export default AgentAccountRequestDetails