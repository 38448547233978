import CustomerWrapper from "../CutomerWrapper";
import EmptyState from "../EmptyState";

const AccountStatementHome = () => {
    return <CustomerWrapper
        // mainAppStyle={}
        title={'Account Statement'}
        // showNormalBreadcrumb={}
    >
        <div className={'row'}>

        <EmptyState/>
        {/*<Filter btn_text={'Generate Bank Statement'} />*/}
        </div>
    </CustomerWrapper>
}

export default  AccountStatementHome