
const SuccessAlertModal = ({msg,backdrop,imgUrl="./assets/images/success.gif",children,onClose}) => {

    return <>
        <div className="modal fade show"
             id="exampleModalCenter"
             style={{
            display:"block"
        }}
             aria-modal="true" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content"   style={{maxHeight: '95vh', overflowY: 'auto',background:"white"}}>
                    <div className="modal-header" style={{border: "0px"}}>
                        <h5 className="modal-title"> {' '}</h5>
                        <button type="button" className="close" data-dismiss="modal"  aria-label="Close" onClick={() => onClose()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body text-center">
                        {imgUrl !== '' &&
                            <>
                                <div className={''}>
                                    <img src={imgUrl} alt={'success img'} width={'50px'}/>
                                </div>
                                <h3>Success</h3>
                            </>
                        }

                        <p>{msg}</p>
                        {children}
                    </div>
                </div>
            </div>
        </div>

        {backdrop &&  <div className="modal-backdrop fade show"></div>}
    </>
}
export default SuccessAlertModal