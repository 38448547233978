import AdminWrapper from "../../AdminWrapper";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
    closeFormConfirmationModal,
    setOperationsForm,
    type,
    submitOperationData
} from "../../../../config/redux/slice/adminSlice";

import {ButtonField, SelectField, TextInputField} from "../../../../components/form/components";
// import {colors} from "../../../../config/color";
import {AdminConfirmation, AdminSuccessModalWrapper} from "../../_component";
import {strText} from "../../../../config/string";

const AdminTransferHome = () => {
    const {register, handleSubmit, getValues, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()

    const {
        successModal,
        success_msg,
        formConfirmationModal,
        operationManagement: {transferFormData}
    } = useSelector((state) => state.administrator)
    const {types_of_transfers, banks} = useSelector((state) => state.authData)

    //handle continue function
    const onContinue = (data) => {
        dispatch(setOperationsForm({data: data, resetFunc: reset, type: type.transfer, banks, types_of_transfers}))
    }

    const onSubmit = () => {
        dispatch(submitOperationData({type: type.transfer}))
    }

    const onClose = () => {
        dispatch(closeFormConfirmationModal({}))

    }

    return (<AdminWrapper>
        <form onSubmit={handleSubmit(onContinue)}>
            <div className={'card p-5'}>
                <div className={'card-header'}>Transfer</div>
                <div className={'card-body pt-5'}>
                    <div className={'row mb-5'}>
                        {/*phone number*/}
                        <div className={'col-4'}>
                            <TextInputField
                                inputStyles={{
                                    background: colors.inputBg,
                                    border: `1px solid ${colors.inputBg}`,
                                    borderRadius: '4px'
                                }}
                                patternRules={'numbers'}
                                placeholder={''}
                                label={'Phone Number'}
                                register={register}
                                error_msg={errors.phone_number?.message}
                                isReq={true}
                                fieldName={'phone_number'}
                                defaultVal={''}
                                // col={}
                                // onClickFunc={}
                            />
                        </div>
                        {/*account number*/}
                        <div className={'col-4'}>
                            <TextInputField
                                inputStyles={{background: colors.inputBg, border: `1px solid ${colors.inputBg}`}}
                                patternRules={'numbers'}
                                placeholder={''}
                                label={'Account Number'}
                                register={register}
                                error_msg={errors.account_number?.message}
                                isReq={true}
                                fieldName={'account_number'}
                                defaultVal={''}
                            />

                        </div>
                        {/*account name*/}
                        <div className={'col-4'}>
                            <TextInputField
                                inputStyles={{background: colors.inputBg, border: `1px solid ${colors.inputBg}`}}
                                patternRules={'letters'}
                                placeholder={''}
                                label={'Account Name'}
                                register={register}
                                error_msg={errors.account_name?.message}
                                isReq={true}
                                fieldName={'account_name'}
                                defaultVal={''}
                            />
                        </div>
                    </div>
                    {/*    */}
                    <div className={'row mb-5'}>
                        {/*transfer type*/}
                        <div className={'col-4'}>
                            <SelectField
                                fieldName={'transfer_type_id'}
                                defaultVal={''}
                                label={'Transfer Type'}
                                register={register}
                                error_msg={errors.transfer_type_id?.message}
                                isReq={true}
                                // col={}
                                selectStyle={{background: colors.inputBg, border: `1px solid ${colors.inputBg}`}}
                                // selectFunc={}
                            >
                                <option value={''}> Select Transfer Type</option>
                                {types_of_transfers.map((item, i = 0) => <option key={i}
                                                                                 value={item.id}> {item.name}</option>)}
                            </SelectField>
                        </div>
                        {/**/}
                        <div className={'col-4'}>
                            <SelectField
                                fieldName={'recipient_financial_institution_id'}
                                defaultVal={''}
                                label={'Receipt Financial Institution'}
                                register={register}
                                error_msg={errors.recipient_financial_institution_id?.message}
                                isReq={true}
                                // col={}
                                selectStyle={{background: colors.inputBg, border: `1px solid ${colors.inputBg}`}}
                                // selectFunc={}
                            >
                                <option value={''}>Select Receipt Financial Institution</option>
                                {banks.map((item, i = 0) => <option key={i} value={item.id}>{item.bank_name}</option>)}
                            </SelectField>

                        </div>
                        {/**/}
                        <div className={'col-4'}>
                            <TextInputField
                                inputStyles={{background: colors.inputBg, border: `1px solid ${colors.inputBg}`}}
                                patternRules={'numbers'}
                                placeholder={''}
                                label={'Recipient Account Number'}
                                register={register}
                                error_msg={errors.recipient_account_number?.message}
                                isReq={true}
                                fieldName={'recipient_account_number'}
                                defaultVal={''}
                                // col={}
                                // onClickFunc={}
                            />

                        </div>
                    </div>
                    {/*    */}
                    <div className={'row mb-5'}>
                        <div className={'col-4'}>
                            <TextInputField
                                inputStyles={{background: colors.inputBg, border: `1px solid ${colors.inputBg}`}}
                                patternRules={'letters'}
                                placeholder={''}
                                label={'Recipient Account Name'}
                                register={register}
                                error_msg={errors.recipient_account_name?.message}
                                isReq={true}
                                fieldName={'recipient_account_name'}
                                defaultVal={''}
                                // col={}
                                // onClickFunc={}
                            />

                        </div>
                        <div className={'col-4'}>
                            <TextInputField
                                inputStyles={{background: colors.inputBg, border: `1px solid ${colors.inputBg}`}}
                                patternRules={'numbers'}
                                placeholder={''}
                                label={'Amount'}
                                register={register}
                                error_msg={errors.amount?.message}
                                isReq={true}
                                fieldName={'amount'}
                                defaultVal={''}
                                // col={}
                                // onClickFunc={}
                            />

                        </div>
                        <div className={'col-4'}>
                            <TextInputField
                                inputStyles={{background: colors.inputBg, border: `1px solid ${colors.inputBg}`}}
                                patternRules={'letters'}
                                placeholder={''}
                                label={'Description(optional)'}
                                register={register}
                                error_msg={errors.description?.message}
                                isReq={false}
                                fieldName={'description'}
                                defaultVal={''}
                                // col={}
                                // onClickFunc={}
                            />

                        </div>
                    </div>
                </div>
                <div className={'card-footer'}>
                    {/*button*/}
                    <div className={'mx-auto w-100'}>
                        <ButtonField
                            txtColor={colors.white}
                            bgColor={colors.secondary_color}
                            btnTxt={'Continue'}
                            btnWidth={'100'}
                            btnStyle={'mx-auto'}

                            id={'d'}/>
                    </div>
                </div>
            </div>
        </form>

        {
            formConfirmationModal && <AdminConfirmation
                type={type.transfer}
                dataArray={[
                    {
                        'text': 'Transfer Type',
                        'value': transferFormData.transfer_type_name
                    },
                    {
                        'text': 'Account Number',
                        'value': transferFormData.account_number
                    },
                    {
                        'text': 'Amount',
                        'value': `${strText.naira}${Number(transferFormData.amount).toLocaleString()}`
                    },
                    {
                        'text': 'Recipient Financial Institution',
                        'value': transferFormData.bank_name
                    },
                    {
                        'text': 'Description',
                        'value': transferFormData.description
                    },
                ]}
                title={'Confirm Transfer'}
                onSubmit={onSubmit}
                backdrop={formConfirmationModal}
                onClose={onClose}
            />}

        {successModal && <AdminSuccessModalWrapper msg={success_msg} backdrop={successModal} btnText={'Continue'}/>}
    </AdminWrapper>)
}
export default AdminTransferHome
