import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styles from '../../../../assets/css/formStyle.module.css';
import { Loader } from "../../../../components";
import { ButtonField, TextInputField } from "../../../../components/form/components";
import { appConfig, endpointUrl } from "../../../../config/config";
import { postRequest2 } from "../../../../config/redux/request";
// import { colors } from "../../../../config/color";

import { clearAdminErrorMsg, onAddTier, setErrorMsg, setLoading, showTierFormFunc } from "../../../../config/redux/slice/adminSlice";
import { pagesText } from "../../../../config/string/english";

const AddNewTier = ({ backdrop }) => {
  const { token, user_role } = useSelector((state) => state.authData);
  const { loading, temp_error_msg, success_msg } = useSelector((state) => state.administrator);
  const {colors} = useSelector((state) => state.app)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [realAmount,setRealAmount] =  useState()
  const [maxBalanceInp,setMaxBalanceInp] =  useState('d-block')
    const [realMaxAmount,setRealMaxAmount] =  useState()

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    reset,
    setError
  } = useForm({ mode: "onTouched" });
  const dispatch = useDispatch();

  const submitData = (data) => {
      if(isNaN(realAmount) || Number(realAmount) < 1){
          setError('temp_limit',{message:'invalid amount'})
      } 
        else if(isNaN(realMaxAmount)){
          setError('temp_balance',{message:'invalid amount'})
      }
      else if(data.is_maximum_balance_unlimited){
        // setValue('daily_transaction_limit', data.temp_limit)
        // setValue('maximum_balance', data.temp_balance)
        data.daily_transaction_limit  = realAmount
        data.maximum_balance = realMaxAmount.toString()
    dispatch(clearAdminErrorMsg());
    // data.jwt = token
    let x = postRequest2(
      `${appConfig.auth_token}`, 
      `${endpointUrl.addTier}`, 
      data, 
      dispatch, setLoading, 
      onAddTier, 
      setErrorMsg);
      }
      else if(Number(realMaxAmount) < 1){
          setError('temp_balance',{message:'invalid amount'})
      } else if(!data.is_maximum_balance_unlimited){
            data.daily_transaction_limit  = realAmount
            data.maximum_balance = realMaxAmount
          dispatch(clearAdminErrorMsg());
          // data.jwt = token
          let x = postRequest2(
            `${appConfig.auth_token}`, 
            `${endpointUrl.addTier}`, 
            data, 
            dispatch, setLoading, 
            onAddTier, 
            setErrorMsg);
      }

    }
    
  const hideMaxBalanceInp = (data) =>{
    let inpClass = data ? 'd-none' : 'd-block'
      if(data){
      setRealMaxAmount(0)
    }
    setMaxBalanceInp(inpClass)
    setValue('temp_balance', data ? 0 : "")
  }
  const closeModal = () => {
    dispatch(clearAdminErrorMsg());
    dispatch(showTierFormFunc({ modal: false }));
  };

    function formatNumber(e) {
        var rex = /(^\d{2})|(\d{1,3})(?=\d{1,3}|$)/g,
            val = this.value.replace(/^0+|\.|,/g, ""),
            res;

        if (val.length && !isNaN(val)) {
            res = Array.prototype.reduce.call(val, (p, c) => c + p) // reverse the pure numbers string
                .match(rex) // get groups in array
                .reduce((p, c, i) => i - 1 ? p + "," + c : p + "." + c); // insert (.) and (,) accordingly
            res += /\.|,/.test(res) ? "" : ".0"; // test if res has (.) or (,) in it
            this.value = Array.prototype.reduce.call(res, (p, c) => c + p); // reverse the string and display
        }
    }

    const formatAmount = (data) => {
        let o = data.replace(".00",'')
        let temp_data = o.replaceAll(",",'').trim()
        setRealAmount(temp_data)
        // let x = NumberFormatter(Number(temp_data).toFixed(2))
    }
    const formatMaxAmount = (data) => {
        let o = data.replace(".00",'')
        let temp_data = o.replaceAll(",",'').trim()
        setRealMaxAmount(temp_data)
        // let x = NumberFormatter(Number(temp_data).toFixed(2))
    }    
    useEffect(() => {
        let limit = document.getElementById("daily_transaction_limit");
        let balance = document.getElementById("maximum_balance");
        limit.addEventListener("keyup", formatNumber);
        balance.addEventListener("keyup", formatNumber);
    },[])

  return (
    <>
      <div
        className="modal fade show"
        id="basicModal"
        tabIndex="-1"
        aria-hidden="true"
        style={{
          display: "block",
          paddingRight: "17px",
        }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header" style={{ border: "0px" }}>
              <h3 className=" " style={{ color: colors.secondary_color }}>
                {" "}
                {pagesText.admin_tier_add}{" "}
              </h3>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className={"px-3 pb-3 pt-0"}>

                <form onSubmit={handleSubmit(submitData)}>
                  <div style={{rowGap: '1rem'}} className={"row mb-4 d-flex align-items-center"}>
                    <div className={"col-sm-12 col-md-6"}>
                      <TextInputField
                        fieldName={"name"}
                        error_msg={errors.name?.message}
                        col={"col-xl-12"}
                        label={"Name*"}
                        placeholder={""}
                        isReq={true}
                        register={register}
                        defaultVal={""}
                        patternRules={""}
                      />
                    </div>

                    <div className={`col-sm-12 col-md-6`}>
                        <input type={'hidden'} id={'temp_limit'} defaultValue={realAmount} {...register('daily_transaction_limit')}/>
                        <div className={''}>
                            <div className={'form-group'}>
                                <label className="fw-bold">Daily Transaction Limit</label>
                                <input
                                    type={'text'}
                                    onKeyUp={(e)=> formatAmount(e.target?.value)}
                                    defaultValue={''}
                                    id={"daily_transaction_limit"}
                                    className={`form-control ${styles.textField}  ${errors.temp_limit?.message && 'is-invalid'}`}
                                    {...register('temp_limit', {
                                        required: {
                                            value: true,
                                            message: 'this field is required'
                                        }
                                    })}/>
                                {errors.temp_limit && <div className="invalid-feedback">{errors.temp_limit?.message}</div>}
                            </div>
                        </div>
                    </div>
                    <div className={`col-sm-12 col-md-6 ${maxBalanceInp}`}>
                        <input type={'hidden'} id={'temp_balance'} defaultValue={realMaxAmount} {...register('maximum_balance')}/>
                        <div className={''}>
                            <div className={'form-group'}>
                                <label className="fw-bold">Maximum Balance</label>
                                <input
                                    type={'text'}
                                    onKeyUp={(e)=> formatMaxAmount(e.target?.value)}
                                    defaultValue={''}
                                    id={"maximum_balance"}
                                    className={`form-control ${styles.textField}  ${errors.temp_balance?.message && 'is-invalid'}`}
                                    {...register('temp_balance', {
                                        required: {
                                            value: true,
                                            message: 'this field is required'
                                        }
                                    })}/>
                                {errors.temp_balance && <div className="invalid-feedback">{errors.temp_balance?.message}</div>}
                            </div>
                        </div>
                    </div>                    
    
                    {/*Phone Number*/}
                    <div className={`col-sm-12 col-md-12`}>
                      <div className="pl-3 mb-0 w-100">
                    <label className=" text-center">
                        <input
                            className="form-check-input mr-2"
                            defaultChecked={false}
                            type="checkbox"
                            {...register('is_maximum_balance_unlimited')}
                            onChange={(e)=>hideMaxBalanceInp(e.target.checked)}
                            />
                        <span className="form-check-sign">Unlimited Maximum Balance</span>
                    </label>
                    </div>
                    </div>
                    </div>

                  <div className={"row d-flex justify-content-center mt-2"}>
                    {loading ? (
                      <Loader />
                    ) : (
                      <ButtonField
                        txtColor={colors.white}
                        bgColor={colors.secondary_color}
                        btnTxt={pagesText.admin_tier_add}
                        btnWidth={"100"}
                        btnStyle={"mx-auto col-xl-7 mt-1 pt-2 pb-2"}
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {backdrop && <div className="modal-backdrop fade show"></div>}
    </>
  );
};
export default AddNewTier;
