import CustomerWrapper from "../CutomerWrapper";
import {ChangeSecurityQuestion} from "./forms";

const SecurityQuestionHome = () => {
    return (
        <CustomerWrapper title={''}>
            <div className={'mx-auto col-sm-12 col-md-8 mt-5'}>
                <ChangeSecurityQuestion/>
            </div>

        </CustomerWrapper>
    )
}

export default  SecurityQuestionHome