import moment from "moment/moment";
import {Loader} from "../../../../components";
import {ButtonField} from "../../../../components/form/components";
import {colors} from "../../../../config/color";
import {Link} from "react-router-dom";
import {GeneralModalWrapper} from "../../../_shared_component";

const internetBankingFullDetails = ({onClose,showModal,internetRequestDetails}) => {


    return <GeneralModalWrapper showModal={showModal} closeModalFunc={onClose} modal_title={'Online Banking Request Details'}>
        <div className={'px-3'}>
            <table className={'table table-striped'}>
                <thead></thead>
                <tbody>
                <tr>
                    <td>Name</td>
                    <td>{`${internetRequestDetails.firstname ?? ''} 
                    ${internetRequestDetails.middlename ?? ''} 
                    ${internetRequestDetails.lastname ?? ''} `}</td>
                </tr>
                <tr>
                    <td>Account Number</td>
                    <td>{internetRequestDetails.account_number}</td>
                </tr>

                <tr>
                    <td>Customer Email</td>
                    <td>{internetRequestDetails.customer_email}</td>
                </tr>

                <tr>
                    <td>Customer Phone</td>
                    <td>{internetRequestDetails.customer_phone}</td>
                </tr>

                <tr>
                    <td>Initiated By</td>
                    <td>{internetRequestDetails.initiated_by ?? 'N/A'}</td>
                </tr>

                <tr>
                    <td>Request Status</td>
                    <td>
                        {internetRequestDetails.request_status === 'pending' ?
                            <small className='badge badge-warning'>pending</small>
                            : internetRequestDetails.request_status === 'approved' ?
                                <small className='badge badge-success'>approved</small> :
                                <small className='badge badge-danger'>N/A</small>}
                    </td>
                </tr>

                <tr>
                    <td>Created On</td>
                    <td>{moment(internetRequestDetails.created_on).format('YYYY-MM-DD hh:mm:ss') }</td>
                </tr>

                {/*<tr>*/}
                {/*    <td>remark</td>*/}
                {/*    <td>{internetRequestDetails.remark ?? 'N/A'}</td>*/}
                {/*</tr>*/}

                <tr>
                    <td>Approved By</td>
                    <td>{internetRequestDetails.approved_by ?? 'N/A'}</td>
                </tr>


                </tbody>

            </table>
            {/*        */}

        </div>

    </GeneralModalWrapper>
}


export default  internetBankingFullDetails