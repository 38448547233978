// import {colors} from "../../../../config/color";
import { useSelector } from "react-redux";
import {strText} from "../../../../config/string";

const PendingRequestWrapper = ({title, data}) => {
    const {colors} = useSelector((state) => state.app)
    return (
        <>
            <div className={'my-5 pb-5'} style={{background: colors.inputBg}}>
                <h6 className={'px-3 pt-3'}>{title}</h6>
                <div className={'p-5'}>
                    {
                        data.length === 0
                            ?
                            <div className={'text-center p-5'}>
                                Pending request appear here
                            </div>
                            :
                            <>
                                {data.map((item,i=0) =>
                                    <div key={i} className={'py-3 d-flex justify-content-between mb-3'} style={{borderBottom:`0.01rem solid ${colors.gray}`}}>
                                        <div className={'d-flex flex-column'}>
                                            {strText.naira} {Number(item.amount).toLocaleString()}
                                            <small className={'text-muted'}>{item.title}</small>
                                        </div>
                                        <div>
                                            {item.time}

                                        </div>

                                    </div>
                                )}
                            </>
                    }

                </div>
            </div>

        </>
    )
}

export default PendingRequestWrapper