import { useState } from "react";
import { Link } from "react-router-dom";
import { urls } from "../../config/urls/urls";

const AuthWrapper = ({children,scrollable=''}) => {
    const [showModal, setModal] = useState(false)
    //
    return(
        <div style={{overflowY:scrollable,height:"100vh"}}>
            <header>
                <div className="uk-container">
                    <div className="uk-grid">
                        <div className="uk-width-2-5@l uk-width-1-3@m uk-width-1-4@s">
                            {/* logo header begin */}
                            <div id="header-logo">
                                <Link to={urls.landingPage} className="uk-logo" href="">
                                    <img src="/landing_page/images/logo_header.svg" alt="QApp QAppnce"/>
                                </Link>
                            </div>
                            {/* logo header end */}
                        </div>
                        <div className="uk-width-3-5@l uk-width-2-3@m uk-width-3-4@s uk-visible@m">
                            <div className="uk-grid">
                                <div className="uk-width-1-2">
                                    <div className="idz-mini-nav uk-align-right">
                                        <form className="uk-display-inline">
                                            <div data-uk-form-custom="target: > * > span:last-child">
                                                <select>
                                                    <option value={1}>English</option>
                                                    <option value={2}>French</option>
                                                </select>
                                                <span>
                                                    <span className="idz-globe"><i className="fa fa-globe"/></span>
                                                <span/>
                                                </span>
                                            </div>
                                        </form>
                                        <Link className="uk-button uk-display-inline" to={urls.onlineAccountOpening}>
                                            <i className="fa fa-sign-in"/>&nbsp;
                                            Activate Account
                                        </Link>
                                    </div>
                                </div>
                                <div className="uk-width-1-2 phone-number">
                                    <h2 className="uk-align-right">1-800-123-4567</h2>
                                </div>
                            </div>
                        </div>
                        <div className="uk-width-1-1">
                            <hr/>
                            {/* Main navigation begin */}
                            <nav className="uk-navbar-container uk-visible@m" data-uk-navbar style={{zIndex: 980}}
                                 data-uk-sticky="animation: uk-animation-slide-top; cls-active: uk-sticky; cls-inactive: uk-navbar-container; bottom: #offset">
                                <div className="uk-navbar-left">
                                    <ul className="uk-navbar-nav">
                                        <li className="uk-active">
                                            <Link to={urls.landingPage}>Home</Link>
                                        </li>
                                        <li><Link to={'#'}>About Us</Link></li>
                                        <li><Link to={'#'}>Banking</Link></li>
                                        <li>
                                            <Link to={'#'}>Payments</Link>
                                        </li>
                                        <li><Link to={''}>Loans</Link></li>
                                        <li><Link to={urls.agencyAccountOpening}>Agents</Link></li>
                                        <li><Link to={''}>Contact Us</Link></li>
                                    </ul>
                                </div>
                                <div className="uk-navbar-right uk-visible@l">
                                    <div data-uk-margin>
                                        <Link className="uk-button uk-button-default mr-1 l_open_acc" to={urls.login}>Online
                                            Banking Login</Link>
                                        <Link  className="uk-button uk-button-primary " to={urls.landingPage}>Open an
                                            Account</Link>
                                    </div>
                                </div>
                            </nav>
                            {/* Main navigation end */}
                            {/* Mobile navigation begin */}
                            <a onClick={() => setModal(true)}
                               className="uk-button uk-align-center idz-mobile-nav uk-hidden@m uk-icon" href={"#modal-full"}
                               data-uk-icon="icon: menu" data-uk-toggle hidden={showModal} >
                                Menu

                            </a>
                            <div id="modal-full" className={`uk-modal-full ${showModal ? 'uk-modal uk-open' : 'uk-modal'} `}
                                 data-uk-modal style={{display: `${showModal ? 'block' : ''}`}}>
                                <div className="uk-modal-dialog">
                                    <button className="uk-modal-close-full uk-close-large" type="button" data-uk-close onClick={()=>setModal(false)}/>
                                    <div data-uk-height-viewport>
                                        <div
                                            className="uk-position-cover uk-overlay uk-overlay-primary uk-flex uk-flex-center uk-flex-middle">
                                            <ul className="uk-nav-primary uk-nav-parent-icon" data-uk-nav>
                                                {/*<li className="uk-active uk-parent">*/}
                                                {/*    <Link to={'#'}>Home</Link>*/}
                                                {/*    <ul className="uk-nav-sub">*/}
                                                {/*        <li><Link to={'#'}>Homepage 1</Link></li>*/}
                                                {/*        <li><Link to={'#'}>Homepage 2</Link></li>*/}
                                                {/*        <li><Link to={'#'}>Homepage 3</Link></li>*/}
                                                {/*        <li><Link to={'#'}>Homepage 4</Link></li>*/}
                                                {/*    </ul>*/}
                                                {/*</li>*/}
                                                <li><Link to={urls.landingPage} onClick={()=>setModal(false)}>Home</Link></li>
                                                <li><Link to={'#'} onClick={()=>setModal(false)}>ABOUT US</Link></li>
                                                {/*<li className="uk-parent">*/}
                                                {/*    <Link to={'#'}>Pages</Link>*/}
                                                {/*    <ul className="uk-nav-sub">*/}
                                                {/*        <li><Link to={'#'}>About Us</Link></li>*/}
                                                {/*        <li><Link to={'#'}>Our Team</Link></li>*/}
                                                {/*        <li><Link to={'#'}>Testimonials</Link></li>*/}
                                                {/*        <li><Link to={'#'}>FAQ</Link></li>*/}
                                                {/*        <li><Link to={'#'}>Sitemap</Link></li>*/}
                                                {/*        <li><Link to={'#'}>404 Error</Link></li>*/}
                                                {/*    </ul>*/}
                                                {/*</li>*/}
                                                <li><Link to={'#'} onClick={()=>setModal(false)}>BANKING</Link></li>
                                                <li><Link to={'#'} onClick={()=>setModal(false)}>PAYMENTS</Link></li>
                                                <li><Link to={'#'} onClick={()=>setModal(false)} >LOANS</Link></li>
                                                <li><Link to={urls.agencyAccountOpening}>AGENTS</Link></li>
                                                <li><Link to={'#'} onClick={()=>setModal(false)} >CONTACT US</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Mobile navigation end */}
                        </div>
                    </div>
                </div>
            </header>


                {children}
        </div>
    )
}

export  default  AuthWrapper