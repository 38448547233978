import AdminWrapper from "../AdminWrapper";
import {ButtonField, TextInputField} from "../../../components/form/components";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import {colors} from "../../../config/color";
import {useForm} from "react-hook-form";
import { useSelector } from "react-redux";
let mb = 'mb-3'
const AdminSettingsPrivacyPolicy = () => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const {colors} = useSelector((state) => state.app)

    return (
        <AdminWrapper mainAppStyle={'col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12  mx-auto mx-xl-1 mx-xxl-0 mt-5'} title={'Privacy Policy Settings'}>
            <form className={''}>
                <div className={'card-body'}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data=""
                                    onReady={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log( 'Editor is ready to use!');
                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        console.log( '' );
                                    } }
                                    onBlur={ ( event, editor ) => {
                                        console.log( '' );
                                    } }
                                    onFocus={ ( event, editor ) => {
                                        console.log( '');
                                    } }

                                />
                        </div>
                        <div className={'col-md-12'}>
                            <div style={{borderTop:0}} className={' py-3'}>
                                <ButtonField
                                    btnTxt={'Update Setting'}
                                    btnWidth={'100'}
                                    bgColor={colors.secondary_color}
                                    // btnAdditionalStyle={}
                                    id={''}
                                    txtColor={colors.white}
                                    // btnStyle={}
                                />
                                {/*  1.Settings id */}
                                {/*  2.bank_id  */}

                                {/*  3.privacy_policy  */}






                                {/*  11.update_on*/}
                                {/*  12.initiated_by*/}
                                {/*  13.request_status*/}
                                {/*  14.approved_by */}
                            </div>
                        </div>

                    </div>
                </div>



            </form>
        </AdminWrapper>
    )

}
export  default  AdminSettingsPrivacyPolicy