import AdminWrapper from "../AdminWrapper";
import {useSelector} from "react-redux";
import {TabWrapper} from "../_component";
import {setActiveTabInfo} from "../../../config/redux/slice/adminSlice";
import {Link} from "react-router-dom";
// import {colors} from "../../../config/color";
import BlockCardModal from "./pages/BlockCardModal";
import {useEffect} from "react";
import {initAdminCardTable} from "../../../config/datatableInit";
import {strText} from "../../../config/string";
import {ButtonField} from "../../../components/form/components";
import {Filter} from "../../_shared_component";

const AdminCardHome = () => {
    const {colors} = useSelector((state) => state.app)
    const {cardManagements:{cardTabArray,showBlockModal,cardsData}} = useSelector((state) => state.administrator )

    useEffect(() => {
        initAdminCardTable(cardsData,setDetails)
    })

    const setDetails = (data) => {

    }
    return (
        <AdminWrapper title={''}>
            {/*<Filter/>*/}


            {/**/}

            <div className={'card'}>
                <div className={'card-header'}>
                    <h2  className={'tableHeader'}>Card Management</h2>
                </div>
                <div className={'card-body'}>
                    <table className={`${strText.datatable_class}`} id={'admin_card_table'}>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Account Number</th>
                            <th>Card Number</th>
                            <th>Card Status</th>
                            <th width={'5%'} data-orderable="false" style={{color:colors.secondary_color}}>Action</th>
                        </tr>
                        </thead>
                        <tbody>

                        </tbody>
                    </table>
                </div>

            </div>

            { showBlockModal &&  <BlockCardModal backdrop={showBlockModal}/>}
        </AdminWrapper>

    )
}
export  default  AdminCardHome


{/*<TabWrapper*/}
{/*    tabArray={cardTabArray}*/}
{/*    // classStyle={}*/}
{/*    // tabContentClassStyle={}*/}
{/*    additionalTabContentStyle={'d-flex justify-content-between mx-3'}*/}
{/*    setTabFunc={setActiveTabInfo}*/}
{/*>*/}
{/*    <Link to={''} className={'btn'} style={{background:colors.secondary_color,color:colors.white}}> Add New Card</Link>*/}
{/*</TabWrapper>*/}