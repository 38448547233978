import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {accountOpeningRequestTable} from "../../../config/datatableInit";
import {
    clearErrorMsg,
    clearResetBot, getAccountOpeningRequest, handleSystemManagement, setAccountReqDetails, setErrorMsg, setLoading
} from "../../../config/redux/slice/adminSlice";
import {postRequest, postRequest2} from "../../../config/redux/request";
import {appConfig, remoteUrl} from "../../../config/config";
import AdminWrapper from "../AdminWrapper";
import {RequestInternetBanking} from "./form";
import {Filter} from "../../_shared_component";
// import {colors} from "../../../config/color";
import type {type} from "../../../config/redux/slice/adminSlice";
import AccountOpeningDetails from "./details/AccountOpeningDetails";
import AdminSuccessAlert from "../_component/AdminSuccessAlert";
import {pageTitle, strText} from "../../../config/string";
import AccountOpeningFullDetails from "./details/AccountOpeningFullDetails";
import AgencyAccountFullDetails from "./details/AgencyAccountFullDetails";

const AccountOpeningRequestHome = () => {
    const [showDetailsModal, setShowModalDetails] = useState(false)
    const [detailsData, setDetailsData] = useState('')
    //1
    const {token} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {success_msg,systemManagement:{accOpeningRequest:{showRequestForm,accOpeningReqData,accOpeningReqBot,showAccountRequestDetails}}} = useSelector((state) =>state.administrator)
    const dispatch = useDispatch()
    //
    //2
    useEffect(()=> {
        dispatch(setAccountReqDetails({modal:false,data:'',isRemark:''}))
        accountOpeningRequestTable(onClickDetailsButton,accOpeningReqData)
        //
        getAccountOpeningReq()
    },[])
    //3
    const onClickDetailsButton = (selectedItem,actionType) => {
        dispatch(clearErrorMsg())
        if(actionType==='remark'){
            dispatch(setAccountReqDetails({modal:true,data:selectedItem,isRemark:'show_remark_form'}))
        }
        else if(actionType==='view_details'){
            setShowModalDetails(true)
            setDetailsData(selectedItem)
        }
        else{
            dispatch(setAccountReqDetails({modal:true,data:selectedItem,isRemark:'no_remark_yet'}))
        }
    }
    //4
    if(accOpeningReqBot){
        accountOpeningRequestTable(onClickDetailsButton,accOpeningReqData)
        dispatch(clearResetBot())
    }

    //5
    const getAccountOpeningReq = () => {
        const res =  postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.getAccountOpeningReq}`,
            {"jwt": token},
            dispatch,
            setLoading,
            getAccountOpeningRequest,
            setErrorMsg)
    }

    const onCloseModal = () => {
        setShowModalDetails(false)
        setDetailsData('')
    }


    if(success_msg==='request posted successfully' || success_msg==='account opening approved'){
        getAccountOpeningReq()
    }




    return <AdminWrapper title={''}>
        {showDetailsModal && <AgencyAccountFullDetails showModal={showDetailsModal} onClose={onCloseModal} agentDetails={detailsData}/>}
        {success_msg && <AdminSuccessAlert msg={success_msg} toastId={'sss'}/>}
        { showRequestForm && <RequestInternetBanking backdrop={showRequestForm}/>}

        {showAccountRequestDetails && <AccountOpeningDetails showModal={showAccountRequestDetails}/>}
        {showDetailsModal && <AccountOpeningFullDetails showModal={showDetailsModal} accountRequestDetails={detailsData} closeModal={onCloseModal} />}

        <div className={'row'}>
            <div className="col-md-12">
                {/*<Filter/>*/}
                <div className="card">
                    <div className="card-header py-4">
                        <div className="d-flex align-items-center">
                            <h2 className={'tableHeader'}>{pageTitle.account_opening_requests}</h2>
                            {/*<button*/}
                            {/*    style={{color:colors.white,background:colors.secondary_color}}*/}
                            {/*    className="btn  ml-auto"*/}
                            {/*    onClick={()=> dispatch(handleSystemManagement({modal:true,type:type.admin_request_internet_banking}))}*/}

                            {/*>*/}
                            {/*    <i className="fa fa-plus mr-1" />*/}
                            {/*    Request Internet Banking*/}
                            {/*</button>*/}
                        </div>
                    </div>
                    <div className="card-body">
                        {/* Modal */}
                        <table className={`${strText.datatable_class}`} id={'table_id_account_opening_request'}>
                            <thead>
                                <tr>
                                    {/*<th>S/N</th>*/}
                                    <th>Name</th>
                                    <th width={'14%'}>Account Type</th>
                                    <th>Email Addr.</th>
                                    <th width={'10%'}>Phone No.</th>
                                    {/*<th>Requested By</th>*/}
                                    <th width={'8%'}>Status</th>
                                    <th width={'15%'}>Req. Date</th>
                                    {/*<th width={'5%'}  data-orderable="false" style={{color:colors.secondary_color}}>Process Req.</th>*/}
                                    {/*<th width={'5%'}  data-orderable="false" style={{color:colors.secondary_color}}>Approve Req.</th>*/}
                                    <th width={'5%'}  data-orderable="false" style={{color:colors.secondary_color}}>Action</th>
                                </tr>
                            </thead>

                        </table>
                    </div>
                </div>
            </div>

        </div>
    </AdminWrapper>
}

export default  AccountOpeningRequestHome