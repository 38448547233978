import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Loader } from "../../components";
import { ButtonField, SelectField } from "../../components/form/components";
// import { colors } from "../../config/color";
import { appConfig, remoteUrl } from "../../config/config";
import { postRequest, postRequest2 } from "../../config/redux/request";
import { switchActiveBankAccount } from "../../config/redux/slice/authData";
import {
    onNewAccountRequest,
    setAccountDetailsModal,
    setCustomerErrorMsg,
    setLoadingTwo
} from "../../config/redux/slice/customerSlice";
import { pagesText } from "../../config/string/english";
import { GeneralModalWrapper } from "../_shared_component";

const UserAccountListing = ({getDataFunc,getDataFunc2}) => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    //showAccountListing
    const {colors} = useSelector((state) => state.app)
    const [showAccountRequestForm, setAccountRequestForm] = useState(false)
    const {userData,token} = useSelector((state) => state.authData)
    const {showAccountListing,loading_,success_msg} = useSelector((state) => state.customer)
    const dispatch = useDispatch()

    const closeModal = () => {
        dispatch(setAccountDetailsModal({modal:false}))
    }

    const onClick = async (data) => {
        dispatch(switchActiveBankAccount({data}))
        dispatch(setAccountDetailsModal({modal:false, message:'Account switched successfully'}))
        setTimeout(() => {
            window.location.reload()
        },2000)
    }
    const openNewAccount = (data) => {
        const postData = {
            "jwt": `${token}`,
            "email":userData.email,
            "account_type": data.account_type
        }
        let res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.reqAdditionalAccount}`,
            postData,
            dispatch,
            setLoadingTwo,
            onNewAccountRequest,
            setCustomerErrorMsg)
    }




    return(
        <GeneralModalWrapper modal_title={''} showModal={showAccountListing} closeModalFunc={closeModal}>
            <div className={''}>
                <div className={'card-header'}>
                    <div className={'d-flex justify-content-between'}>
                        <h4>My Accounts</h4>
                        <Link to={'#'} onClick={()=>setAccountRequestForm(true)} className={'btn btn-sm'} style={{color:colors.secondary_color, background:colors.white, border:`1px solid ${colors.secondary_color}`}}>
                            Request a new Account
                        </Link>
                    </div>


                </div>
                <div className={'card-body'}>
                    {showAccountRequestForm
                        ?
                        <form onSubmit={handleSubmit(openNewAccount)}>
                            <div>
                                <SelectField
                                    // selectFunc={}
                                    // selectStyle={}
                                    // col={}
                                    defaultVal={''}
                                    label={'Select Account Type'}
                                    error_msg={errors.account_type}
                                    isReq={true}
                                    register={register}
                                    fieldName={'account_type'} >
                                    {pagesText.accounts_type.map((item,i=0) =>
                                            <option key={i} value={item.val}>{item.name}</option>)
                                    }

                                </SelectField>
                            </div>
                            {}
                            <div className={'d-flex mt-2'}>
                                {loading_ ? <Loader/> :
                                    <>
                                        <ButtonField
                                            bgColor={colors.secondary_color} btnWidth={'100'} btnTxt={'Submit Request'} btnStyle={'py-2'}
                                            // btnAdditionalStyle={}
                                            id={'df'}
                                            txtColor={colors.white}/>
                                        <Link to={''} className={'btn btn-sm w-100 ml-2'}
                                              style={{color:colors.secondary_color,background:colors.white,border:`1px solid ${colors.secondary_color}`, borderRadius:'50px'}}>
                                            Reset
                                        </Link>
                                    </>
                                }
                            </div>


                        </form>
                        :
                        <table className={'table table-striped'}>
                            <tbody>
                            {/*item.account_no !== userData.account_number &&*/}
                            {userData.account_numbers.length>0 ?
                                userData.account_numbers.map((item,i=0) =>  <tr className={'accountList'}  key={i} onClick={()=> onClick(item.account_no)}>
                                    <td >{item.account_no}</td>
                                    <td>{item.account_type}</td>
                                </tr>)
                                :
                                ''
                            }
                            </tbody>
                        </table>
                    }
                </div>

            </div>

            {/*<ul className="list-group">*/}
            {/*    <li className="list-group-item accountList" onClick={()=> onClick()}>{userData?.account_number}</li>*/}
            {/*</ul>*/}
        </GeneralModalWrapper>
    )
}

export default  UserAccountListing