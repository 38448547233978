import React, { useRef } from 'react';
import { Loader2 } from '../../components';

const LoadingModal = ({type}) => {
    const modalRef = useRef(null);

    // if(type == 'hide'){
    //     hideModal();
    // } else if(type == 'show'){
    //     showModal()
    // }

    const showModal = () => {
    if (modalRef.current) {
      // Using Bootstrap's jQuery-based method to show the modal
      window.$(modalRef.current).modal('show');
    }
  };

  // Function to hide the modal
  const hideModal = () => {
    if (modalRef.current) {
      // Using Bootstrap's jQuery-based method to hide the modal
      window.$(modalRef.current).modal('hide');
    }
  };


    return (<>
        {type == 'show' && <div className="modal fade" ref={modalRef} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sm">
                <div className="modal-content">
                <div className="modal-body">
                   <Loader2 />
                    <h4 style={{textAlign: 'center', fontWeight: 400}}>Signing in...</h4>
                </div>
                </div>
            </div>
        </div>}</>
    )
}

export default LoadingModal         