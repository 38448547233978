import moment from "moment/moment";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import styles from '../../../../assets/css/formStyle.module.css';
import { Loader } from "../../../../components";
import { ButtonField } from "../../../../components/form/components";
// import { colors } from "../../../../config/color";
import { appConfig, remoteUrl } from "../../../../config/config";
import { initCustomerTransaction } from "../../../../config/datatableInit";
import { returnFormattedDate } from "../../../../config/redux/actions";
import { postRequest2 } from "../../../../config/redux/request";
import {
    clearTransactionHistoryDetailsData,
    onGetTransactionData,
    resetCustomerTransactionHistory,
    resetDataTableBot, setAccountStatementModal,
    setCustomerErrorMsg,
    setGeneralModal,
    setLoading,
    setSingleTransactionHistoryDetails,
    setTransactionDate,
} from "../../../../config/redux/slice/customerSlice";
import { strText } from "../../../../config/string";
import { TransactionDetailsItemModal } from "../../../_shared_component";
import CustomerWrapper from "../../CutomerWrapper";
import { AccountStatementModal } from "./component";


const TransactionHistory = () => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {transaction_histories, generalModal, transactionHistoryDetail,newTransactionHistBot,transactionHistoryFilter,loading,error_msg,showAccountStatementModal} = useSelector((state) => state.customer)
    const {userData,token} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()


    const triggerAccountStatement = () => {
        dispatch(setAccountStatementModal({modal:true}))
        //showAccountStatementModal
    }



    useEffect(() => {
        dispatch(resetDataTableBot())
        initCustomerTransaction(transaction_histories,showTransactionDetails,'all',triggerAccountStatement,userData.account_number)
        getTransactionHistory({
            start_date:transactionHistoryFilter?.start_date,
            end_date:transactionHistoryFilter?.end_date
        }
        )
        }
        ,[])

    const onClose = () => {
        dispatch(clearTransactionHistoryDetailsData())
    }

    const showTransactionDetails = (data) => {
        dispatch(setGeneralModal({
            show: true,
            msg: '',
            backdrop: true
        }))
        dispatch(setSingleTransactionHistoryDetails(data))
    }

    const getTransactionHistory = (data) => {
        let myUrl  = data.url !== undefined ? data.url : remoteUrl.transactionHistoryWithDate

        let dateFilter = returnFormattedDate(data?.start_date,data.end_date)


        const today =''
        dispatch(setTransactionDate({
            type  : 'set_transaction_transfer_date',
            filterDate : {
                start_date : data?.start_date !== '' ? moment(data?.start_date).format('MM-DD-YYYY') : today ,
                end_date :  data?.end_date !==''? moment(data?.end_date).format('MM-DD-YYYY') : today
            }
        }))

       const res = postRequest2(
           `${appConfig.auth_token}`,
           `${myUrl}`,
           {
               "accountno" : userData.account_number,
               "start_date": dateFilter.fromDate,
               "end_date": dateFilter.toDate,

           },
           dispatch,
           setLoading,
           onGetTransactionData,
           setCustomerErrorMsg
       )
    }




    useEffect(()=>{
    if(newTransactionHistBot){
        initCustomerTransaction(transaction_histories,showTransactionDetails,'all',triggerAccountStatement,userData.account_number)
        dispatch(resetDataTableBot())
    }

    if(error_msg==='not found' && !showAccountStatementModal){
        dispatch(resetCustomerTransactionHistory())
    }
    }, [newTransactionHistBot, error_msg])

    return (
        <CustomerWrapper title={''} showNormalBreadcrumb={true}>
            <div className="row">
                <div className={'mt-2 col-xl-12'}>

                    <div className={'card py-1 px-2'}>
                        <div className={'card-header'}>
                            <div className={'row'}>
                                <div className={'col-4'}>
                                    <h2 className={'mt-1'} style={{color:colors.secondary_color}}>Transaction History</h2>
                                </div>

                                <div className={'col-8'}>
                                    <form onSubmit={handleSubmit(getTransactionHistory)}>
                                        <div className={'row d-flex align-items-center py-1'}>
                                            <div className={'col-md-4'}>
                                                <input type={'date'}
                                                       defaultValue={transactionHistoryFilter?.start_date ?? ''}
                                                       {...register('start_date')}
                                                       className={`form-control  ${styles.textField}`}
                                                />
                                            </div>

                                            <div className={'col-md-4'}>
                                                <input type={'date'}
                                                       defaultValue={transactionHistoryFilter?.end_date ?? ''}
                                                       {...register('end_date')}
                                                       className={`form-control  ${styles.textField}`}
                                                />
                                            </div>

                                            <div className={'col-md-4'}>
                                                {loading && !showAccountStatementModal ? <Loader/> :
                                                    <ButtonField
                                                        btnStyle={'py-2'}
                                                        // btnAdditionalStyle={}
                                                        id={'search'}
                                                        txtColor={colors.white}
                                                        bgColor={colors.secondary_color}
                                                        btnWidth={'100'}
                                                        btnTxt={'Apply'}
                                                    />
                                                }
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>


                        </div>
                        <table className={`${strText.datatable_class}`} id={'transaction'}>
                            <thead>
                            <tr>
                                <th>Trans. Date</th>
                                <th> Value Date </th>
                                <th> Reference </th>
                                <th>Debit  </th>
                                <th>Credit </th>
                                <th> Charge </th>
                                <th width={'9%'} data-orderable="false" style={{color:colors.secondary_color}}> Action </th>
                            </tr>
                            </thead>
                            <tbody>

                            </tbody>
                        </table>
                    </div>

                </div>

                {generalModal?.show && <TransactionDetailsItemModal onClose={onClose} show={generalModal?.show}
                                                                    transactionDetails={transactionHistoryDetail}/>}
                {showAccountStatementModal && <AccountStatementModal show={showAccountStatementModal}/>}
            </div>
        </CustomerWrapper>
    )
}

export default TransactionHistory