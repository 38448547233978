import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { PinModule } from "../../../../components";
// import { colors } from "../../../../config/color";
import { appConfig, endpointUrl } from "../../../../config/config";
import { postRequest2 } from "../../../../config/redux/request";
import { onCreatePin, setCustomerErrorMsg, setLoading } from "../../../../config/redux/slice/customerSlice";

const SetTransactionPin = ({backdrop}) => {
    const {token} = useSelector((state)=> state.authData)
    const {loading} = useSelector((state) => state.customer)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const dispatch = useDispatch()
    const {colors} = useSelector((state) => state.app)

    const onSubmit = async (data) => {
        let pin = `${data.pin_one}${data.pin_two}${data.pin_three}${data.pin_four}`
        const postData = {
            "jwt" : token,
            "new_pin" : pin
        }
        const  res = postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.createPin}`,
            postData,
            dispatch,
            setLoading,
            onCreatePin,
            setCustomerErrorMsg
        )
    }
    return (
        <>
            <div className="modal fade show"
                 id="basicModal"
                 tabIndex="-1" aria-hidden="true"
                 style={{
                     display: "block",
                     paddingRight: "17px"
                 }}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header" style={{border: "0px"}}>
                            <h4 className=" text-center mx-auto w-100"> Set your Transaction PIN </h4>
                        </div>

                        <div className="modal-body" >
                            <div className={'px-5'}>
                                    <div className={'mb-3'}>
                                     <PinModule
                                         btnWidth={'100'}
                                         btnTxt={'Save Transaction PIN'}
                                         btnTxtColor={colors.white}
                                         btnBgColor={colors.secondary_color}
                                         leadTxt={''}
                                         processFunc={onSubmit}
                                         loading={loading}
                                     />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {backdrop && <div className="modal-backdrop fade show"></div>}
        </>
    )
}

export default  SetTransactionPin