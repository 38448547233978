import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components";
import { ButtonField, SelectField } from "../../../components/form/components";
// import { colors } from "../../../config/color";
import { appConfig, endpointUrl } from "../../../config/config";
import { customerDatatable } from "../../../config/datatableInit";
import { postRequest2 } from "../../../config/redux/request";
import {
    clearResetBot,
    getCustomerForAdmin,
    getCustomerForAdminFilter,
    setCustomerDetails, setCustomerShowActivateStatusModal,
    setErrorMsg, setLoading
} from "../../../config/redux/slice/adminSlice";
import { respMsg } from "../../../config/response_messages";
import { strText } from "../../../config/string";
import { pagesText } from "../../../config/string/english";
import AdminWrapper from "../AdminWrapper";
import AdminSuccessAlert from "../_component/AdminSuccessAlert";
import ActivateDeactivateUserModal from "./ActivateDeactivateUserModal";
import ConfirmationModal from "./ConfirmationModal";
import CustomerDetailsModal from "./CustomerDetailsModal";

const AdminCustomerHome = () => {
    const dispatch = useDispatch()
    const {userData:{modules, permissions}, token, user_role, permissionRoles} = useSelector((state) => state.authData)
    const {
        customerManagement: {showActivateStatusModal, refreshCustomers, customersData, showCustomerSuccessModal, newData, filter},
        success_msg, loading, openConfirmationModal
    } = useSelector((state) => state.administrator)
    const {colors} = useSelector((state) => state.app)
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        clearErrors,
        formState: {errors},
        reset
    } = useForm({mode: 'onTouched'})

    const customerTypes = [{value: '1', text: 'Tier 1'},
     {value: '2', text: 'Tier 2'}, 
     {value: '3', text: 'Tier 3'}, 
     {value: 'pending_approval', text: 'Pending Approval'}]

    useEffect(() => {
        dispatch(setCustomerShowActivateStatusModal({
            modal: false,
            details: ''
        }))
        dispatch(setCustomerDetails({
            modal: false,
            details: ''
        }))
        let canEditUserDetails = permissions?.includes("CanEditUserDetails")
        customerDatatable(customersData, setUserDetails, canEditUserDetails)
        getAllCustomer({tier: 'all'})
    }, [])

    useEffect(() => {
        //
        if(refreshCustomers){
        dispatch(setCustomerShowActivateStatusModal({
                modal: false,
                details: ''
            }))
            getAllCustomer({tier: 'all'})
        }
        dispatch(clearResetBot())

    },[refreshCustomers])    


    const setUserDetails = (data, dataType) => {
        if (dataType === 'activate_status') {
            dispatch(setCustomerShowActivateStatusModal({modal: true, details: data}))
        } else {
            dispatch(setCustomerDetails({details: data, modal: true}))
        }

    }


    const getAllCustomer = async (data) => {

        const res = await postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getAllUsers}`,
            {
                "jwt": token,
                ...data
            },
            dispatch,
            setLoading,
            getCustomerForAdmin,
            setErrorMsg)
           dispatch(getCustomerForAdminFilter(data)) 
    }

    useEffect(()=>{
        if (newData) {
            let canEditUserDetails = permissions?.includes("CanEditUserDetails")
            customerDatatable(customersData, setUserDetails, canEditUserDetails)
            dispatch(clearResetBot())
        }
        if(success_msg == respMsg.change_customer_status){
            getAllCustomer({tier: filter})
        }
    }, [newData, success_msg])
    return (
        <AdminWrapper title={''}>
            {showActivateStatusModal && <ActivateDeactivateUserModal backdrop={showActivateStatusModal}/>}
            {showCustomerSuccessModal && <CustomerDetailsModal backdrop={showCustomerSuccessModal}/>}
             {openConfirmationModal && <ConfirmationModal backdrop={openConfirmationModal}/>}
            {success_msg && <AdminSuccessAlert msg={success_msg} toastId={'dhhd'}/>}


            <div className={''}>

                <div className={'card px-3'}>
                    <div className={'card-header'}>
                        <div className="row d-flex align-items-center">
                            <div className="col-6">
                                <h2 className={'tableHeader'}>{pagesText.customers}</h2>
                            </div>
                    <div className={'col-6'}>
                        <form onSubmit={handleSubmit(getAllCustomer)}>
                            <div className={'row pt-1 px-3 d-flex justify-content-end align-items-center'}>
                                <div className={'col-sm-12 col-md-6'}>
                                     <SelectField
                                            defaultVal={'all'}
                                            register={register}
                                            isReq={true}
                                            label={''}
                                            fieldName={'tier'}
                                            error_msg={errors.tier?.message}
                                            id={'tier'}
                                            // col={}
                                            // selectFunc={}
                                        >
                                            <option value='all'>All</option>
                                            {customerTypes.map((item, index)=>(
                                                 <option key={index}  value={item.value}>{item.text}</option>
                                            ))}
                                        </SelectField>
                                </div>

                                <div className={' col-sm-12 col-md-4'}>
                                    {loading ? <Loader/> :
                                        <ButtonField
                                            btnStyle={'py-2'}
                                            // btnAdditionalStyle={}
                                            id={'search'}
                                            txtColor={colors.white}
                                            bgColor={colors.secondary_color}
                                            btnWidth={'100'}
                                            btnTxt={'Apply'}
                                        />
                                    }
                                </div>
                            </div>
                        </form>
                    </div>                            
                        </div>
                    </div>
                    <table id={'table_id_customer'} className={`${strText.datatable_class}`}>
                        <thead>
                        <tr>
                            <th width={'20%'}>Customer Name</th>
                            <th width={'20%'}>Phone Number</th>
                            <th width={'25%'}>Email</th>
                            <th width={'12%'}>Status</th>
                            {/*<th width={'12%'}>Created At</th>*/}
                            <th data-orderable="false" style={{color:colors.secondary_color}}>Action</th>

                        </tr>
                        </thead>
                    </table>
                </div>
            </div>

        </AdminWrapper>
    )
}

export default AdminCustomerHome