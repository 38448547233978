import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
    clearErrorMsg,
    onAccountOpeningApprovalApprove,
    onAccountOpeningApprovalReq,
    onAdditionalAccountOpeningRequestApprovalReq,
    onApproveAdditionalAccountOpeningRequestReq,
    onGetAccountOpeningRemark,
    setAccountReqDetails,
    setAdditionalAccountReqDetails,
    setErrorMsg,
    setLoading
} from "../../../../config/redux/slice/adminSlice";
import {clearAll} from "../../../../config/redux/slice/authData";
import {postRequest, postRequest2} from "../../../../config/redux/request";
import {appConfig, remoteUrl} from "../../../../config/config";
import {GeneralModalWrapper} from "../../../_shared_component";
import {pagesText} from "../../../../config/string/english";
import {ButtonField, TextInputField} from "../../../../components/form/components";
import {Loader} from "../../../../components";
// import {colors} from "../../../../config/color";

const AdditionalRequestDetails = ({showModal}) => {
    //1
    const {colors} = useSelector((state) => state.app)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {token,user_role} = useSelector((state) => state.authData)
    const {
        loading,
        adminErrorMsg,
        success_msg,
        systemManagement: {additionalAccountReq: {additionalAccRequestDetails, isAdditionalAccountReqRemark, remark}}
    } = useSelector((state) => state.administrator)
    const dispatch = useDispatch()
//2
    const closeModal = () => {
        dispatch(setAdditionalAccountReqDetails({modal: false, data: '', isRemark: ''}))
        dispatch(clearErrorMsg())
        dispatch(clearAll())
    }

//3 :: Submit for Approval
    const onSubmit = (data) => {
        const postData = {
            "jwt": token,
            "account_number": data.account_number,
            "remark": data.remark!=="" ? data.remark :  ' ',
            "aaor_id": additionalAccRequestDetails.aaor_id
        }
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestAdditionalAccountApproval}`,
            postData,
            dispatch,
            setLoading,
            onAdditionalAccountOpeningRequestApprovalReq,
            setErrorMsg
        )
    }

    /*method that approves account opening request*/
    const approveAccountOpeningReq = (data) => {
        const postData = {
            "jwt": token,
            "aaor_id": additionalAccRequestDetails.aaor_id,
        }
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.approveAdditionalAccountReq}`,
            postData,
            dispatch,
            setLoading,
            onApproveAdditionalAccountOpeningRequestReq,
            setErrorMsg
        )
    }
    /*Get remark*/
    // if (isAccountReqRemark === 'show_remark_form' || isAccountReqRemark === 'no_remark_yet') {
    //     const postData = {
    //         "jwt": token,
    //         "aor_id": accountRequestDetails.aor_id
    //     }
    //     const res = postRequest(
    //         `${appConfig.auth_token}`,
    //         `${remoteUrl.viewAccountOpeningApprovalRemark}`,
    //         postData,
    //         dispatch,
    //         setLoading,
    //         onGetAccountOpeningRemark,
    //         setErrorMsg
    //     )
    //     dispatch(setAccountReqDetails({modal: true, data: accountRequestDetails, isRemark: 'hide_remark_form'}))
    // }
    //
    return (
        <GeneralModalWrapper
            modal_title={'Additional Account Opening Request Details'}
            showModal={showModal}
            closeModalFunc={closeModal}>
            <div className={'px-3 py-4'}>
                {/*{adminErrorMsg !== '' && <div className={'alert text-danger alert-danger'}>{adminErrorMsg}</div>}*/}
                {/*{success_msg !== '' && <div className={'alert text-success alert-success'}>{success_msg}</div>}*/}
                <table className={'table table-striped'}>
                    <thead></thead>
                    <tbody>
                    <tr>
                        <td>Account Number</td>
                        <td>{additionalAccRequestDetails?.account_number ?? 'N/A'}</td>
                    </tr>


                    <tr>
                        <td>Account type</td>
                        <td>{additionalAccRequestDetails?.account_type}</td>
                    </tr>
                    <tr>
                        <td>Customer email</td>
                        <td>{additionalAccRequestDetails?.email}</td>
                    </tr>

                    <tr>
                        <td>Initiated by</td>
                        <td>{additionalAccRequestDetails?.initiated_by?? 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Approved By</td>
                        <td>{additionalAccRequestDetails?.approved_by?? 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Request status</td>
                        <td>
                            {!additionalAccRequestDetails.initiated_by
                                ?
                                <small className='uk-text-warning uk-text-bold'>pending</small>
                                :
                                additionalAccRequestDetails.initiated_by && additionalAccRequestDetails.request_status === 'pending'
                                    ?
                                    <small className='text-info uk-text-bold'>processing</small>
                                    :
                                    additionalAccRequestDetails.request_status==='approved'
                                        ?
                                        <small className='uk-text-success uk-text-bold'>approved</small>
                                        :
                                        <small className='badge badge-danger uk-text-bold'>N/A</small>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>Remark</td>
                        <td>{additionalAccRequestDetails?.remark ?? 'N/A'}</td>
                    </tr>
                    </tbody>
                </table>
                {/**/}
                {/*{(isAccountReqRemark === 'show_remark_form' || isAccountReqRemark === 'no_remark_yet') && loading && <Loader/>}*/}
                {/*{(isAccountReqRemark === 'show_remark_form' || isAccountReqRemark === 'no_remark_yet') && loading && <Loader/>}*/}

                {
                    user_role === pagesText.user_roles_text_key[2]
                    ?
                    !additionalAccRequestDetails?.initiated_by && additionalAccRequestDetails.request_status==='pending'   ?
                        <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={'mb-2'}>
                                    <TextInputField
                                        patternRules={'numbers'}
                                        register={register}
                                        label={'Account Number'}
                                        fieldName={'account_number'}
                                        isReq={true}
                                        error_msg={errors.account_number?.message}
                                        defaultVal={additionalAccRequestDetails?.account_number}
                                        placeholder={'Enter account number'}
                                    />

                                </div>
                                <div className={'mt-3 mb-2'}>
                                    <label>Remark (optional)</label>
                                    <textarea {...register('remark', {
                                        required: false
                                    })} className={'form-control'} style={{borderRadius: '50px'}}></textarea>
                                </div>

                                {
                                    loading
                                        ?
                                        <Loader/>
                                        :
                                        <ButtonField
                                            btnTxt={'Submit'}
                                            btnWidth={'100'} bgColor={colors.secondary_color}
                                            txtColor={colors.white} id={'submit'}
                                        />
                                }
                            </form>
                        </>
                        :
                        ''
                    :
                    ''
                }

                {/* checking user_role === approval*/}
                {
                    user_role === pagesText.user_roles_text_key[3] ?
                        <>
                            {
                                additionalAccRequestDetails?.request_status === 'approved' ? ''
                                    :
                                    additionalAccRequestDetails?.request_status !== 'approved' && additionalAccRequestDetails?.account_number ?
                                        <>
                                            <div className={' px-3 py-4'}>
                                                <form className={'card-body'}
                                                      onSubmit={handleSubmit(approveAccountOpeningReq)}>
                                                    {
                                                        loading
                                                            ?
                                                            <Loader/>
                                                            :
                                                            <ButtonField
                                                                btnTxt={'Approve  additional account opening request'}
                                                                btnWidth={'100'} bgColor={colors.secondary_color}
                                                                txtColor={colors.white} id={'submit'}
                                                            />
                                                    }
                                                </form>
                                            </div>
                                        </>
                                        :
                                        ''
                            }

                        </>
                        :
                        ''
                }

            </div>

        </GeneralModalWrapper>
    )
}



export  default  AdditionalRequestDetails