const ConfirmationWrapper = ({dataArray,title,titleStyle}) => {

    return (
        <div className={''}>
            <h5 className={titleStyle}>{title}</h5>
            <table className={'table table-striped'}>
                <tbody>
                {dataArray.map((item,i=0) =>
                   <tr key={i}>
                       <td>{item?.text}</td>
                       <td>{item?.amount}</td>
                   </tr>
                )}
                </tbody>
            </table>
        </div>
    )

}

export  default  ConfirmationWrapper