import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// import { colors } from "../../../../config/color";
import { stepTwo } from "../../../../config/redux/slice/authSlice";
import { OtpComponent } from "../../../onboard_one/components";

const OtpScreen = ()=>{
    const dispatch = useDispatch()
    const {formOneData,formTwoData} = useSelector((state) => state.auth )
    const {colors} = useSelector((state) => state.app)
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})

    const onSubmit = (data)=> {
        dispatch(stepTwo(data))
    }

    return(
        <>
            <fieldset className={'mx-auto w-75 px-5 mt-5'}>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div  className={'row'}>
                        <div className={'col-md-12'}>
                           <OtpComponent phone_no={formOneData?.mobile_new??''}/>
                        </div>

                    </div>

                    <div className={'row mt-4 mb-3'}>
                        <div className={'col-md-12'}>
                                <button type={'submit'} className={'btn btn-dark px-5 w-100'} style={{background:`${colors.primary_color}`,borderRadius:'50px'}}>
                                    Confirm
                                </button>
                        </div>
                    </div>
                </form>

            </fieldset>
        </>
    )
}

export default OtpScreen