import { useDispatch, useSelector } from "react-redux";
import { clearMsg, resetLoanAppFormData } from "../../../config/redux/slice/customerSlice";
import CustomerWrapper from "../CutomerWrapper";
import EmptyState from "../EmptyState";

const LoansHome = () => {
    const {generalModal, successModal, loanAppFormData,formResetFunc} = useSelector((state) => state.customer)
    const dispatch = useDispatch()

    const onSuccessModalClose = () => {
        formResetFunc()
        dispatch(resetLoanAppFormData())
        dispatch(clearMsg())
    }

    return <CustomerWrapper title={'Loan'}>
        <div className="row">
            <EmptyState/>
            {/*<LoanForm/>*/}
        </div>

        {/*{successModal.show && <SuccessAlertModal*/}
        {/*    backdrop={successModal?.backdrop}*/}
        {/*    msg={`A loan of ${strText.naira}${Number(loanAppFormData?.total_repayment).toLocaleString()} has be granted to your account and will be debuted automatically at the end of each installment month.`}*/}
        {/*    imgUrl={'../assets/images/success.gif'}*/}
        {/*    onClose={onSuccessModalClose}*/}
        {/*/>*/}
        {/*}*/}
        {/*{generalModal?.show && <LoanConfirmationModal backdrop={generalModal?.backdrop} msg={generalModal?.msg}/>}*/}
    </CustomerWrapper>
}

export default LoansHome