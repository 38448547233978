import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appConfig, endpointUrl, remoteUrl } from "../../../config/config";
import { postRequest2 } from "../../../config/redux/request";
import { getBankSettingsForCustomer, setCustomerErrorMsg, setLoading } from "../../../config/redux/slice/customerSlice";
import CustomerWrapper from "../CutomerWrapper";

let mb = 'mb-3'
const GeneralSettings = () => {
    const {token} = useSelector((state) => state.authData)
    const {bankingSettings} = useSelector((state) => state.customer)
    const dispatch = useDispatch()

    useEffect(() => {
        getSettings()
    }, [])

    const getSettings = () => {
        const postData = {
            "jwt": token
        }
        let res = postRequest2(
            `${appConfig.auth_token}`,
            `${endpointUrl.getBankInfo}`,
            postData,
            dispatch,
            setLoading,
            getBankSettingsForCustomer,
            setCustomerErrorMsg
        )
    }

    return <CustomerWrapper>
        <div className={'card mx-auto w-75 p-5'}>
            {/*  7.bank_name*/}
            <div className={` ${mb}`}>
                <label>Bank name</label>
                <input type={'text'} readOnly={true} className={'form-control'} defaultValue={bankingSettings?.bank_name} />
            </div>

            <div className={` ${mb}`}>
                <label>QApp URL</label>
                <input type={'text'} readOnly={true} className={'form-control'}
                       defaultValue={bankingSettings?.qapp_url} />
            </div>
            {/*  8.bank_address*/}
            <div className={` ${mb}`}>
                <label>Bank Address</label>
                <input type={'text'} readOnly={true} className={'form-control'} defaultValue={bankingSettings?.bank_address} />
            </div>
            {/*  9.contact_phone*/}
            <div className={` ${mb}`}>
                <label>Contact Phone</label>
                <input type={'text'} readOnly={true} className={'form-control'} defaultValue={bankingSettings?.contact_phone} />
            </div>
            {/*  10.contact_email*/}
            <div className={` ${mb}`}>
                <label>Contact Email</label>
                <input type={'text'} readOnly={true} className={'form-control'} defaultValue={bankingSettings?.contact_email} />
            </div>
            {/*  6.currency  */}
            <div className={` ${mb}`}>
                <label>Currency</label>
                <input type={'text'} readOnly={true} className={'form-control'} defaultValue={bankingSettings?.currency} />
            </div>
            {/*  5.country_time_zone  */}
            <div className={` ${mb}`}>
                <label>Country Time Zone</label>
                <input type={'text'} readOnly={true} className={'form-control'} defaultValue={bankingSettings?.country_time_zone} />
            </div>
        </div>
    </CustomerWrapper>
}
export default  GeneralSettings