import {GeneralModalWrapper} from "../../../_shared_component";
import {useDispatch, useSelector} from "react-redux";
import {ButtonField, TextInputField} from "../../../../components/form/components";
// import {colors} from "../../../../config/color";
import {useForm} from "react-hook-form";
import {postRequest, postRequest2} from "../../../../config/redux/request";
import {appConfig, remoteUrl} from "../../../../config/config";
import {
    clearErrorMsg,
    onAccountOpeningApprovalApprove,
    onAccountOpeningApprovalReq,
    onGetAccountOpeningRemark,
    setAccountReqDetails,
    setErrorMsg,
    setLoading
} from "../../../../config/redux/slice/adminSlice";
import {Loader} from "../../../../components";
import {clearAll} from "../../../../config/redux/slice/authData";
import {pagesText} from "../../../../config/string/english";

const AccountOpeningDetails = ({showModal}) => {
    //1
    const {register, handleSubmit, getValues, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const {token,user_role} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const {
        loading,
        adminErrorMsg,
        success_msg,
        systemManagement: {accOpeningRequest: {accountRequestDetails, isAccountReqRemark, remark}}
    } = useSelector((state) => state.administrator)
    const dispatch = useDispatch()
//2
    const closeModal = () => {
        dispatch(setAccountReqDetails({modal: false, data: '', isRemark: ''}))
        dispatch(clearErrorMsg())
        dispatch(clearAll())
    }

//3 :: Submit for Approval
    const onSubmit = (data) => {
        const postData = {
            "jwt": token,
            "account_number": data.account_number,
            "remark": data.remark ?? '',
            "aor_id": accountRequestDetails.aor_id
        }
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.requestAccountOpeningApproval}`,
            postData,
            dispatch,
            setLoading,
            onAccountOpeningApprovalReq,
            setErrorMsg
        )
    }

    /*method that approves account opening request*/
    const approveAccountOpeningReq = (data) => {
        const postData = {
            "jwt": token,
            "aor_id": accountRequestDetails.aor_id,
            // "account_number": data.account_number
        }
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.approveAccountOpeningReq}`,
            postData,
            dispatch,
            setLoading,
            onAccountOpeningApprovalApprove,
            setErrorMsg
        )
    }
    /*Get remark*/
    if (isAccountReqRemark === 'show_remark_form' || isAccountReqRemark === 'no_remark_yet') {
        const postData = {
            "jwt": token,
            "aor_id": accountRequestDetails.aor_id
        }
        const res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.viewAccountOpeningApprovalRemark}`,
            postData,
            dispatch,
            setLoading,
            onGetAccountOpeningRemark,
            setErrorMsg
        )
        dispatch(setAccountReqDetails({modal: true, data: accountRequestDetails, isRemark: 'hide_remark_form'}))
    }
    //
    return (
        <GeneralModalWrapper
            modal_title={'Account Opening Request Details'}
            showModal={showModal}
                             closeModalFunc={closeModal}>
            <div className={'px-3 py-4'}>
                {/*{adminErrorMsg !== '' && <div className={'alert text-danger alert-danger'}>{adminErrorMsg}</div>}*/}
                {/*{success_msg !== '' && <div className={'alert text-success alert-success'}>{success_msg}</div>}*/}
                <table className={'table table-striped'}>
                    <thead></thead>
                    <tbody>
                    <tr>
                        <td>Account Number</td>
                        <td>{accountRequestDetails?.account_number ?? 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>First Name</td>
                        <td>{accountRequestDetails?.firstname}</td>
                    </tr>
                    <tr>
                        <td>Middle Name</td>
                        <td>{accountRequestDetails?.middlename ?? 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Last Name</td>
                        <td>{accountRequestDetails?.lastname}</td>
                    </tr>

                    <tr>
                        <td>Customer Address</td>
                        <td>{accountRequestDetails?.customer_address}</td>
                    </tr>
                    <tr>
                        <td>Account Type</td>
                        <td>{accountRequestDetails?.account_type}</td>
                    </tr>
                    <tr>
                        <td>Customer Email</td>
                        <td>{accountRequestDetails?.customer_email}</td>
                    </tr>
                    <tr>
                        <td>Customer Phone</td>
                        <td>{accountRequestDetails?.customer_phone}</td>
                    </tr>
                    <tr>
                        <td>Initiated By</td>
                        <td>{accountRequestDetails?.initiated_by ?? 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Approved By</td>
                        <td>{accountRequestDetails?.approved_by?? 'N/A'}</td>
                    </tr>
                    <tr>
                        <td>Request status</td>
                        <td>
                            {!accountRequestDetails.initiated_by
                                ?
                                <small className='uk-text-warning uk-text-bold'>pending</small>
                                :
                                accountRequestDetails.initiated_by && accountRequestDetails.request_status === 'pending'
                                    ?
                                    <small className='text-info uk-text-bold'>processing</small>
                                    :
                                    accountRequestDetails.request_status==='approved'
                                        ?
                                        <small className='uk-text-success uk-text-bold'>approved</small>
                                        :
                                        <small className='badge badge-danger uk-text-bold'>N/A</small>
                            }
                        </td>
                    </tr>
                    <tr>
                        <td>Remark</td>
                        <td>{accountRequestDetails?.remark ?? 'N/A'}</td>
                    </tr>
                    </tbody>
                </table>
                {/**/}
                {/*{(isAccountReqRemark === 'show_remark_form' || isAccountReqRemark === 'no_remark_yet') && loading && <Loader/>}*/}
                {/*{(isAccountReqRemark === 'show_remark_form' || isAccountReqRemark === 'no_remark_yet') && loading && <Loader/>}*/}

                { user_role === pagesText.user_roles_text_key[2]
                    ?
                    !accountRequestDetails?.initiated_by && accountRequestDetails.request_status==='pending'   ?
                        <>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className={'mb-2'}>
                                    <TextInputField
                                        patternRules={'numbers'}
                                        register={register}
                                        label={'Account Number'}
                                        fieldName={'account_number'}
                                        isReq={true}
                                        error_msg={errors.account_number?.message}
                                        defaultVal={accountRequestDetails?.account_number}
                                        placeholder={'Enter account number'}
                                    />

                                </div>
                                <div className={'mt-3 mb-2'}>
                                    <label>Remark (optional)</label>
                                    <textarea {...register('remark', {
                                        required: false
                                    })} className={'form-control'} style={{borderRadius: '50px'}}></textarea>
                                </div>

                                {
                                    loading
                                        ?
                                        <Loader/>
                                        :
                                        <ButtonField
                                            btnTxt={'Submit'}
                                            btnWidth={'100'} bgColor={colors.secondary_color}
                                            txtColor={colors.white} id={'submit'}
                                        />
                                }
                            </form>
                        </>
                        :
                        ''
                    :
                    ''
                }

                {/* checking user_role === approval*/}
                {
                    user_role === pagesText.user_roles_text_key[3] ?
                    <>
                        {
                            accountRequestDetails?.request_status === 'approved' ? ''
                                :
                                accountRequestDetails?.request_status !== 'approved' && accountRequestDetails?.account_number ?
                                    <>
                                        <div className={'card px-3 py-4'}>
                                            <form className={'card-body'}
                                                  onSubmit={handleSubmit(approveAccountOpeningReq)}>
                                                {/*<div className={'mb-2'}>*/}
                                                {/*    <TextInputField*/}
                                                {/*        placeholder={'enter account number'}*/}
                                                {/*        patternRules={'numbers'}*/}
                                                {/*        defaultVal={''}*/}
                                                {/*        error_msg={errors.account_number?.message}*/}
                                                {/*        isReq={true}*/}
                                                {/*        fieldName={'account_number'}*/}
                                                {/*        label={''}*/}
                                                {/*        register={register}/>*/}
                                                {/*</div>*/}
                                                {
                                                    loading
                                                        ?
                                                        <Loader/>
                                                        :
                                                        <ButtonField
                                                            btnTxt={'Approve account opening request'}
                                                            btnWidth={'100'} bgColor={colors.secondary_color}
                                                            txtColor={colors.white} id={'submit'}
                                                        />
                                                }
                                            </form>
                                        </div>
                                    </>
                                    :
                                    ''
                        }

                    </>
                    :
                    ''
                }

            </div>

        </GeneralModalWrapper>
    )
}

export default AccountOpeningDetails