import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { colors } from "../../../config/color";
import { appConfig, remoteUrl } from "../../../config/config";
import { usersDatatable } from "../../../config/datatableInit";
import { postRequest2 } from "../../../config/redux/request";
import {
    clearResetBot, getAllAdmins,
    handleSystemManagement,
    setErrorMsg, setLoading,
    showAdminFormFunc,
    type
} from "../../../config/redux/slice/adminSlice";
import { strText } from "../../../config/string";
import { pagesText } from "../../../config/string/english";
import AdminWrapper from "../AdminWrapper";
import ActivateDeactivateAdminUserModal from "./ActivateDeactivateAdminUserModal";
import { AddAdmin } from "./form";


const AdminUserManagement = () => {
    const {adminRoleManagement: {rolesData}, adminUserManagement: {adminUserData,newDataBot,showAdminForm,showAminDetailsForm},success_msg} = useSelector((state) => state.administrator)
    const {userData:{modules, permissions}, token,user_role,permissionRoles,moduleRoles} = useSelector((state) => state.authData)
    const {colors} = useSelector((state) => state.app)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        //
        usersDatatable(adminUserData,setUserDetails, rolesData)
        getAdmins()

    },[])



    const getAdmins = () => {
        let res = postRequest2(
            `${appConfig.auth_token}`,
            `${remoteUrl.getAdmin}`,
            {"jwt" : token},
            dispatch,
            setLoading,
            getAllAdmins,
            setErrorMsg,
        )
    }


    const setUserDetails = (data) => {
        //:: :: :: :: ::  :: ::
        dispatch(handleSystemManagement({
            type:type.set_admin_details_system,
            data:data,
            modal : true
        }))
    }      

    useEffect(()=>{
        if(newDataBot){
            usersDatatable(adminUserData,setUserDetails, rolesData)
            dispatch(clearResetBot())
        }

        if(success_msg===strText.update_admin_status){
            getAdmins()
        }

        if(success_msg===strText.admin_added_successfully){
            getAdmins()
        }        
    }, [newDataBot, success_msg])

    return (
        <AdminWrapper title={''}>

            {showAdminForm && <AddAdmin backdrop={showAdminForm}/> }
            <div>
                {/*<FilterTwo selectArray={[]} />*/}
                <div className={'card'}>
                    <div className="card-header">
                        <div className="d-flex align-items-center">
                            <h2 className={'tableHeader'}>{pagesText.admin_user}</h2>
                            {
                               permissions?.includes("CanAddSystemUser")
                                    ?
                                    <button
                                        style={{color:colors.white,background:colors.secondary_color}}
                                        className="btn  ml-auto"
                                        onClick={()=> dispatch(showAdminFormFunc({modal:true}))}
                                    >
                                        <i className="fa fa-plus mr-1" />
                                        {pagesText.admin_user_add}
                                    </button>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    <div className={'card-body'}>
                        <table className={strText.datatable_class} id={'table_user_management'}>
                            <thead>
                            <tr>
                                <th > Name</th>
                                <th width={'18%'}>Phone Number</th>
                                <th> Email </th>

                                <th width={'12%'}> User Role </th>
                                <th width={'17%'}> Date Created </th>
                                <th width={'9%'}> Status </th>
                                <th width={'9%'}  data-orderable="false" style={{color:colors.secondary_color}}> Action</th>
                            </tr>
                            </thead>



                        </table>
                    </div>

                </div>

            </div>

            {/* :::: ::: */}
            {showAminDetailsForm && <ActivateDeactivateAdminUserModal backdrop={showAminDetailsForm}/>}

        </AdminWrapper>
    )
}
export default AdminUserManagement