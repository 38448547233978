import { Link } from "react-router-dom";
// import { colors } from "../../../../config/color";
import { urls } from "../../../../config/urls/urls";
import AuthWrapper from "./AuthWrapper";
import { useDispatch, useSelector } from "react-redux";
import { postRequest2 } from "../../../../config/redux/request";
import { appConfig, endpointUrl } from "../../../../config/config";
import { useEffect } from "react";

const AuthWrapperCustomer = ({children, title}) => {
    const dispatch = useDispatch()
   const {colors, appLogo} = useSelector((state) => state.app)

    return <AuthWrapper scrollable={'hidden'}>
        <div className={`internet_banking_new`} style={{height:'100vh',overflowX:'hidden'}}>
            <section className="d-flex h-100 justify-content-center align-items-center">
                <div 
                    className={'card shadow-lg bg-white pb-4 pt-4 px-3 col-11 col-sm-9 col-md-7 col-lg-4 mx-auto'} 
                    style={{height: "fit-content"}}
                >
                    {/* logo header begin */}
                    <div id="header-logo" className="mx-auto">
                        <Link to={urls.landingPage} className="uk-logo" href="">
                            <img src={appLogo} alt="QApp QAppnce"/>
                        </Link>
                    </div>                    
                    <div className="tittle text-center mt-4">
                        <h2 className={''} style={{color:colors.primary_color, fontSize: "1.3rem"}}>
                            <b>{title}</b>
                        </h2>
                    </div>

                    {children}
                </div>
            </section>
        </div>
    </AuthWrapper>
}


export default AuthWrapperCustomer
