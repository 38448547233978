import AdminWrapper from "../AdminWrapper";

const AdminSystemHome = () => {
    return (
        <AdminWrapper>
            <h1>System</h1>
        </AdminWrapper>
    )
}

export default  AdminSystemHome