import CustomerWrapper from "../CutomerWrapper";
import EmptyState from "../EmptyState";

const MyAccountManagerHome = () => {
    return <CustomerWrapper
        title={'My Account Manager'}
        // mainAppStyle={}
        // showNormalBreadcrumb={}
    >
        <div className={'row'}>
        <EmptyState/>
        </div>
        {/*
        <div className="row">
            <div className="col-sm-6 col-md-3">
                <div className="card card-stats card-primary card-round">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-5">
                                <div className="icon-big text-center">
                                  <img src={'/_assets/talk_with_account_manager.png'} alt={''} height={'40'}/>
                                </div>
                            </div>
                            <div className="col col-stats">
                                <div className="numbers">
                                    <p className="card-category">Call Manager</p>
                                    <h4 className="card-title"></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 col-md-3">
                <div className="card card-stats card-info card-round">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-5">
                                <div className="icon-big text-center">
                                    <img src={'/_assets/mail.png'} alt={''} height={'40'}/>
                                </div>
                            </div>
                            <div className="col col-stats">
                                <div className="numbers">
                                    <p className="card-category">Send Mail</p>
                                    <h4 className="card-title"></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        */}

    </CustomerWrapper>
}

export default  MyAccountManagerHome